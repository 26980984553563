import { AuthorDto } from 'tdc-web-backend/authors/schemas';
import { Stack, Typography } from '@mui/material';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CustomInput from '../../../../components/custom-input/CustomInput';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';

type AuthorProps = {
  viewMode?: boolean;
  author?: string;
};

const Author = ({ viewMode, author }: AuthorProps) => {
  const { data: authors } = useGetMany<AuthorDto>({
    resource: '/authors?',
    enabled: !viewMode,
  });

  const mapa = new Map(
    viewMode
      ? []
      : authors?.data.results.map((author) => [
          author.id,
          `${author.firstName} ${author.lastName}`,
        ]),
  );

  return (
    <>
      {viewMode ? (
        <Stack spacing={0}>
          <BaseInputLabel text="Author" />
          <Typography
            variant="bodyMedium3"
            color="primaryDark.800"
            sx={{ lineHeight: '1.25rem', mt: 0 }}
          >
            {author}
          </Typography>
        </Stack>
      ) : (
        <CustomInput
          input={<CustomInput.Select />}
          name="author"
          placeholder="Select"
          choices={mapa}
          label="Author"
          sx={{
            width: '100%',
            '& .MuiInputBase-root': {
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            },
          }}
          required
        />
      )}
    </>
  );
};
export default Author;
