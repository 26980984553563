import { EnumCurrency } from 'tdc-web-backend/enums/enums';

export default {
  body: {
    projectDurationFrom: '',
    projectDurationTo: '',
    buyer: {
      companyName: '',
      registeredAddress: '',
      companyNumber: '',
      companyRepresentative: {
        name: '',
        jobTitle: '',
        email: '',
      },
    },
    seller: {
      companyName: '',
      registeredAddress: '',
      companyNumber: '',
      companyRepresentative: {
        name: '',
        jobTitle: '',
        email: '',
      },
    },
    scope: {
      services: [],
      industry: '',
      currency: EnumCurrency.Usd,
      projectType: 'one_time',
      description: '',
    },
    milestones:
      [
        {
          name: '',
          start: '',
          end: '',
          budget: 0,
          summary: '',
          duration: null,
          recurring: false,
        },
      ],
    jurisdiction: '',
  },
  start: '',
  end: '',
  initialAmount: 0,
  sellerApproved: false,
  buyerApproved: false,
};

export const companyNumberInfo = 'The unique number assigned by a Company register used to identify a business entity. For EU companies please use VAT number.';
export const jurisdictionInfo = 'Almost every Internet activity has an international aspect that could lead to multiple jurisdictions. By stipulating the governing law and jurisdiction, contracting parties avoid the uncertainty that might occur in case of a dispute.';
export const projectDurationInfo = 'Project duration data will be calculated from your project milestone start and end dates';
