import { FormControl, Select, MenuItem } from '@mui/material';
import { Editor } from '@tiptap/react';
import InputStyle from './InputStyle';

const fontFamilies = [
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Comic Sans MS, Comic Sans', value: 'Comic Sans MS, Comic Sans' },
  { label: 'Cursive', value: 'cursive' },
  { label: 'Monospace', value: 'monospace' },
];

interface FontSelectProps {
  editor: Editor;
}

const FontSelect = ({ editor }: FontSelectProps) => (
  <FormControl>
    <Select
      onChange={({ target }) => {
        editor.chain().focus().setFontFamily(target.value).run();
      }}
      label="Font Family"
      value={
        (() => fontFamilies.find((font) => editor.isActive('textStyle', { fontFamily: font.value }))?.value || fontFamilies[0].value)()
      }
      input={<InputStyle />}
    >
      {
        fontFamilies.map(
          (family) => (<MenuItem key={family.label} value={family.value}>{family.label}</MenuItem>),
        )
      }
    </Select>
  </FormControl>
);

export default FontSelect;
