import { Stack, SxProps, Theme, Box, Typography } from '@mui/material';

export interface TeamMemberCardProps {
  name?: string;
  title?: string;
  img?: string;
  borderRadius?: string;
  objectFit?: string;
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
  width?: string | number;
  alt?: string;
}

const TeamMemberCard = ({
  name,
  title,
  img,
  borderRadius,
  sx,
  imgSx,
  width,
  alt,
}: TeamMemberCardProps) => (
  <Stack spacing="2rem" width={width ?? '80%'} sx={{ ...sx }}>
    <Stack sx={{ width: '100%', position: 'relative' }}>
      <Box
        alt={alt}
        key={img}
        component="img"
        src={img}
        zIndex={2}
        borderRadius={borderRadius}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          overflow: 'hidden',
          ...imgSx,
        }}
      />
    </Stack>

    {name && title && (
      <Stack>
        <Typography sx={{ typography: { xs: 'publicBody3Regular', lg: 'publicBody4Regular' } }}>
          {name}
        </Typography>

        <Typography sx={{ typography: { xs: 'body2', lg: 'body3' } }} color="primaryDark.500">
          {title}
        </Typography>
      </Stack>
    )}
  </Stack>
);

export default TeamMemberCard;
