import { styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { ReactComponent as InfoAlertIcon } from '../../assets/icons/layout-icons/InfoAlertIcon.svg';
import { primaryDark } from '../../color';

type IconWithToolTipProps = {
  toolTipText: string;
  backgroundColor?: string;
  icon?: JSX.Element;
} & TooltipProps;

// used styled() func here because the sx override wouldn't work;
// we should avoid this approach but as a last resort when sx doesn't work,
// use styled instead
const IconWithToolTip = styled(
  ({
    toolTipText,
    backgroundColor = primaryDark[500],
    icon = <InfoAlertIcon fill={backgroundColor} style={{ width: 18, height: 18 }} />,
    placement = 'top',
    className,
    ...props
  }: IconWithToolTipProps) => (
    <Tooltip
      placement={placement}
      classes={{ popper: className }}
      arrow
      {...props}
      componentsProps={{ tooltip: { sx: { backgroundColor: primaryDark[600], px: 1.5, py: 0.5 } } }}
      title={
        <Typography variant="body3" color="primaryDark.200" fontWeight={600} textTransform="none">
          {toolTipText}
        </Typography>
      }
    >
      {icon}
    </Tooltip>
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 235,
  },
});

export default IconWithToolTip;
