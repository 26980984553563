import {
  Divider, Popover, Stack, Typography, Button,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import CustomChip from '../../../../components/custom-chip/CustomChip';

interface MultipleChipFieldsProps {
  label: string;
  enums: Map<string, string>;
  values: string[];
}

const MultipleChipFields = ({
  label,
  values,
  enums,
}: MultipleChipFieldsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Stack sx={{ padding: '0.5em 1em' }} spacing={1}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{
          fontSize: '.875em',
          fontWeight: 600,
        }}
        >
          {label}
        </Typography>
        <Divider />
      </Stack>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 0.5,
        }}
        justifyContent="space-between"
      >
        <CustomChip
          label={`${values.length} selected`}
        />
        <Button aria-describedby={id} onClick={handleClick} color="secondary" disabled={values.length === 0}>
          See all
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack spacing={1} sx={{ p: 3 }}>
            {
            values?.map((value: string) => (
              <CustomChip
                label={enums.get(value)}
              />
            ))
          }
          </Stack>
        </Popover>
      </Stack>
    </Stack>
  );
};

export default MultipleChipFields;
