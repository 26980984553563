import { Stack, Typography } from '@mui/material';
import { TermOfServiceI, fakeLinkStyles } from '../utils';
import CustomList from '../components/CustomList';

const privacyPolicy: TermOfServiceI[] = [
  {
    introduction:
      'Spona believes in being open and clear about how we collect and use data related to you. This Privacy Policy sets out how we collect and use data at Spona and the choices you have about how to interact with our services.',
  },
  {
    content: [
      {
        title: 'Your Privacy Matters',
        paragraphs: [
          {
            text: () =>
              'Central to this mission is our commitment to being transparent about the data we collect about you, how it is used and who we share it with.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                This Privacy Policy covers information we collect from users of and visitors to{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[4]()}
                >
                  www.spona.io
                </Typography>{' '}
                and our related research and review services (collectively, the “Services”). By
                accessing{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[4]()}
                >
                  www.spona.io
                </Typography>{' '}
                or any related sites, applications or using the Services, you acknowledge that you
                have read and understood this Privacy Policy and you are consenting to the
                collection, use, disclosure and handling of your information, as described below.
              </Typography>
            ),
          },
          {
            text: () =>
              'This policy covers only the personal data of natural persons and it does not apply to any other data relating to corporate clients, suppliers or business partners, or to public authorities, except where and if the applicable law determines otherwise.',
          },
          {
            text: () =>
              'If you have any concerns about providing information to us or having such information displayed on the Services or otherwise used in any manner permitted in this Privacy Policy, you should not use the Services.',
          },
          {
            text: () =>
              'We offer our users choices about the data we collect, use and share as described in this Privacy Policy and Cookie Policy. Users can contact us with specific inquiries via this contact form.',
          },
        ],
      },
      {
        title: 'Introduction',
        paragraphs: [
          {
            text: () =>
              'We are a global B2B platform that helps businesses streamline the process of managing digital service providers as well as managing their digital projects. Our Privacy Policy applies to any Member or Visitor to our Services.',
          },
          {
            text: () =>
              'Our registered users share information about their professional businesses and services, engage with other Members, exchange knowledge and professional insights, post and view relevant content and find business opportunities. Content and data on some of our Services can be viewed by non-members.',
          },
          {
            text: () =>
              'If you are an EU-, EEA- or UK-based Visitor or Member you are entitled to specific privacy rights as detailed below.',
          },
        ],
      },
      {
        title: 'Services',
        paragraphs: [
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                This Privacy Policy, including our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[3]()}
                >
                  Cookie Policy
                </Typography>{' '}
                applies to your use of our Services.
              </Typography>
            ),
          },
          {
            text: () =>
              'This Policy applies to www.spona.io and other Spona related sites, communications and services (“Services”), including off-site Services, such as our ad services and plugins, but excluding services that state that they are offered under a different privacy policy.',
          },
        ],
      },
      {
        title: 'Data Controller and Contracting Parties',
        paragraphs: [
          {
            text: () =>
              'This Privacy Policy applies to Top Digital Agency Limited, 69 Main Street, Dublin, Ireland and its affiliated entities, explicitly encompassing the company Top Digital d.o.o., Vladimira Nazora 23, Zabok, Croatia (together in the text “Spona”).',
          },
          {
            text: () =>
              'As a Visitor or Member of our Services, the collection, use and sharing of your personal data are subject to this Privacy Policy (which includes our Cookie Policy and other documents referenced in this Privacy Policy) and updates.',
          },
        ],
      },
      {
        title: 'Change',
        paragraphs: [
          {
            text: () =>
              'Changes to the Privacy Policy apply to your use of our Services after the “effective date.”',
          },
          {
            text: () =>
              'Spona (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you with the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.',
          },
          {
            text: () =>
              'You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy.',
          },
        ],
      },
    ],
  },
  {
    title: '1. Data We Collect',
    content: [
      {
        subtitle: '1.1 Data You Provide To Us',
        paragraphs: [
          {
            text: () => 'You provide data to create an account with us.',
          },
        ],
      },
      {
        subtitle: 'Registration',
        paragraphs: [
          {
            text: () =>
              'To create an account you need to provide certain personal data including your name, contact email address, job title.',
          },
          {
            text: () =>
              'If you use the Service to make or receive payments, we will also collect certain payment information, such as credit card or other financial account information, and the billing address. We may collect personal information, such as your date of birth or taxpayer identification number to validate your identity or as may be required by law, such as to complete tax filings. We may request documents to verify this information, such as a copy of your government-issued identification or photo or a billing statement.',
          },
          {
            text: () =>
              'You create your Spona profile (a complete profile helps you get the most from our Services).',
          },
        ],
      },
      {
        subtitle: 'Profile',
        paragraphs: [
          {
            text: () =>
              'When you register as a User, we ask you to provide certain personal information including admin name, and other important non-personal information. We will collect that information via a form that opens up in a modal once users are redirected to the dashboard after  successful registration. Filled-out fields from this modal are a requirement for companies registered with Spona, and may help you to get more from our Services, including helping other organizations and business opportunities find you. We will also ask you to provide or otherwise collect additional information, such as a telephone number or other contact details. It’s your choice whether to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available.',
          },
          {
            text: () =>
              'We may collect, process and store your user ID associated with any social media account (such as your LinkedIn account) that you use to sign in to the Services or connect with or use with the Services. When you sign in to your account with your social media account information, or otherwise connect your social media account with the Services, you consent to our collection, storage and use, in accordance with this Privacy Policy, of the information that you choose to make available to us through the social media interface. This could include, without limitation, any information that you have made public in connection with your social media account, information that the social media service shares with us, or information that is disclosed as being shared during the sign-in process. This information varies and is controlled by that service or as authorized by you via your privacy settings at that service. Please see your social media provider’s privacy policy and help center for more information about how they share information when you choose to connect your account.',
          },
          {
            text: () => 'You give other data to us.',
          },
        ],
      },
      {
        subtitle: 'Posting and Uploading',
        paragraphs: [
          {
            text: () =>
              'We collect data from you when you provide, post or upload it to our Services, such as when you fill out a form, publish or apply to a project on the Spona platform, or submit a case study review. You don’t have to post or upload data; though if you don’t, it may limit your ability to grow and use our Services.',
          },
          {
            text: () =>
              'When you publish a project on our platform it is important to be as accurate as possible and list as much relevant information as possible. We will need, at the minimum, your name, company name, project details, email contact details and preferred way for us to contact you. Please note that your email address, phone number, and other submitted information will be shared with other Users as well as affiliate networks and partners who may best fit your project needs.',
          },
          {
            text: () =>
              'You may also provide information about others (e.g. your clients), including their names, emails, phone numbers and companies to help us verify your references and feedback. You should acquire the consent of other individuals prior to providing Spona with their information. The information you provide about others is only used in the reference check and review process.',
          },
        ],
      },
      {
        subtitle: '1.2 Data From Others',
        paragraphs: [
          {
            text: () => 'Others may post or write about you.',
          },
        ],
      },
      {
        subtitle: 'Content and News',
        paragraphs: [
          {
            text: () =>
              'You and others may post content that includes information about you on our Services. We receive data about you when you create or respond to projects published on our platform. Also, we receive data about you when you submit a case study or reference reviews from your clients.',
          },
          {
            text: () =>
              'We may also obtain information about you if it is contained in user-generated content that other users post to our Services. For example, a user could include information about you in a review they post.',
          },
          {
            text: () =>
              'If you are invited to the Spona platform by another user (e.g. as part of our referral program) we will receive certain information about you such as your email address or other contact information, and we will use it for the same purpose it was provided and in accordance with the terms of this Privacy Policy.',
          },
        ],
      },
      {
        subtitle: '1.3 Service Use',
        paragraphs: [
          {
            text: () => 'We log your visits and use of our Services.',
          },
          {
            text: () =>
              'We log usage data when you visit or otherwise use our Services, including our sites and platform technology (e.g. our off-site plugins), such as when you view or click on content or ads (on or off our sites), perform a search or apply to a Spona project. We use and process the information relating to log-ins, cookies and internet protocol (“IP”) addresses to identify you and log your use.',
          },
          {
            text: () =>
              'We use that information to enhance our services,  improve user experience, personalize your browsing experience as well as prevent fraud and inappropriate content or behavior on our Site.',
          },
        ],
      },
      {
        subtitle: '1.4 Cookies, Web Beacons and Other Similar',
      },
      {
        subtitle: 'Technologies',
        paragraphs: [
          {
            text: () => 'We collect data through cookies and similar technologies.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                As further described in our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[3]()}
                >
                  Cookie Policy
                </Typography>
                , we use cookies and similar technologies (e.g. pixels, ad tags and device
                identifiers) to recognize you and/or your device(s) on, off, and across different
                Services and devices. We also allow some others to use cookies as described in our
                Cookie Policy. You can control cookies through your browser settings and other
                tools. You can also opt-out from our use of cookies and similar technologies that
                track your behavior on the sites of others for third-party advertising.
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '1.5 Your Device and Location',
        paragraphs: [
          {
            text: () => 'We receive data from your devices, including location data.',
          },
          {
            text: () =>
              'When you visit or leave our Services (including our plugins or cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to next. We also get information about your IP address, proxy server, operating system, web browser and add-ons, device identifier and features, and/or ISP or your mobile carrier. If you access the Services through a mobile device and you do not want your device to provide us with GPS location-tracking information, you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer’s instructions for further details.',
          },
        ],
      },
      {
        subtitle: '1.6 Sites and Services of Others',
        paragraphs: [
          {
            text: () =>
              'We and third parties may use automated means to collect information about you, your computer or another device, and your use of the Services. These automated means include technologies such as cookies, Web server logs, Web beacons, and JavaScript.',
          },
        ],
      },
      {
        subtitle: '1.7 Children’s Privacy',
        paragraphs: [
          {
            text: () =>
              'Our Services are not intended for use by children under the age of 18. We do not knowingly collect personal information from anyone younger than 18 years old. If we become aware that personal information has been collected from a person under the age of 18, we will delete this information as quickly as possible.',
          },
        ],
      },
      {
        subtitle: '1.8 Other',
        paragraphs: [
          {
            text: () =>
              'We are improving our Services, which means we get new data and create new ways to use data.',
          },
          {
            text: () =>
              'Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different data or materially change how we use your data, we will notify you and may also modify this Privacy Policy.',
          },
        ],
      },
    ],
  },
  {
    title: '2. How Do We Use Your Data',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'We use your data to provide, support, personalize and develop our Services.',
          },
          {
            text: () =>
              'How we use your personal data will depend on which Services you use, how you use those Services. We use the data that we have about you to provide and personalize, including with the help of automated systems and inferences we make, our Services (including ads) so that they can be more relevant and useful to you and others.',
          },
        ],
      },
      {
        subtitle: '2.1. Legal Basis for Using Your Personal Information',
        paragraphs: [
          {
            text: () =>
              'Where relevant under applicable laws, all processing of your personal information will be justified by a "lawful ground" for processing. In the majority of cases, processing will be justified on the basis that:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'number',
                  items: [
                    'You have given us your permission to use your personal information for a specific purpose;',
                    'Our data processing activity is necessary to perform a contract or take steps to enter into a contract with you (e.g. to provide you with services which you have purchased);',
                    'The processing is necessary to comply with a relevant legal obligation or regulatory obligation that we have (e.g. fraud prevention); or',
                    'The processing is necessary to support our legitimate interests as a business (e.g. to improve our services to you), subject to your interests and fundamental rights, and provided it is conducted at all times in a way that is proportionate.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object or submit a Data Request. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact our Data Protection Officer.',
          },
        ],
      },
      {
        subtitle: '2.2 Services',
        paragraphs: [
          {
            text: () =>
              'Our Services help you connect with other businesses, find business opportunities and solutions, as well as manage your projects. We use your data to authorize access to our Services.',
          },
        ],
      },
      {
        subtitle: 'Find Business Partners',
        paragraphs: [
          {
            text: () =>
              'Our Services allow you to find business partners, clients, and other professional contacts. We will use data about you (such as your profile) to help others find your profile, suggest projects for you and other Services.',
          },
          {
            text: () =>
              'It is your choice whether to approach an organization found through our Services or to create or apply to a project. When you apply or respond to a Pitch, this action will include such information as you provide.',
          },
        ],
      },
      {
        subtitle: 'Manage Your Projects',
        paragraphs: [
          {
            text: () =>
              'End to End Project Management is Spona’s all-in-one, comprehensive project management tool. It is designed to address challenges that appear during the management process, giving you the peace of mind that your project is progressing according to plan.',
          },
        ],
      },
      {
        subtitle: 'Stay Informed',
        paragraphs: [
          {
            text: () =>
              'Our Services allow you to stay informed about news, events and ideas regarding professional topics you care about, and from professionals you respect. We use the data we have about you (e.g. data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to provide relevant content on our Services and to make suggestions about how to find the best partner for your business on our platform.',
          },
        ],
      },
      {
        subtitle: '2.3 Premium Services',
        paragraphs: [
          {
            text: () =>
              'Our premium Services allow paying users to use our End-to-End project management tool for their ongoing projects, connect a Buyer or Seller of choice and enable smoother communication and collaboration.',
          },
          {
            text: () =>
              'We sell premium Services that provide our customers functionality tools, support, project and account management, as part of our business solutions. Customers can export the project reports from their profile and use them for various reporting needs.',
          },
          {
            text: () =>
              'A premium Services customer can store information they have about you in our premium Services, such as project materials or contact information.',
          },
        ],
      },
      {
        subtitle: '2.4 Communications',
        paragraphs: [
          {
            text: () =>
              'We may contact you and enable communications between Users through the Spona platform.',
          },
          {
            text: () =>
              'We will contact you through email, notices posted on our websites, push notifications as well as through our in-platform messaging service. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use the Services, network updates, reminders, and promotional messages from us and our partners. If you enter into projects with other Users of the Spona platform, those other Users will have the option to contact you using our in-platform messaging service.',
          },
          {
            text: () =>
              'You understand that Spona has unlimited access to all documents, correspondence and communications between Users made in any form through the platform and confirm that Spona might access it without prior notice and/or permission.',
          },
          {
            text: () =>
              'In addition, we automatically collect information when you access, browse, view, or otherwise use the platform. If you create or respond to a project, we track whether you have acted on it and will send you reminders.',
          },
          {
            text: () =>
              'Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.',
          },
        ],
      },
      {
        subtitle: '2.5 Advertising',
        paragraphs: [
          {
            text: () =>
              'We do not serve you tailored ads as part of our Services. But you cannot opt-out of seeing other ads.',
          },
          {
            text: () =>
              'We measure the performance of ads to Members, Visitors and others both on and off our Services directly or through a variety of partners, using the following data, whether separately or combined:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Data from advertising technologies on and off our Services, like pixels, ad tags, cookies, and device identifiers;',
                    'Member-provided information (e.g. profile, contact information and industry);',
                    'Data from your use of our Services (e.g. search history, content you read, who you reads your content, Spona projects or applications, page visits, videos you watch, clicking on an ad, etc.), including as described in Section 1.3;',
                    'Information from advertising partners and publishers; and',
                    'Information inferred from the data described above.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        subtitle: 'Info to Ad Providers',
        paragraphs: [
          {
            text: () =>
              'We do not share your personal data with any third-party advertisers or ad networks for their advertising except for (a) hashed or device identifiers (to the extent they are personal data in some countries); (b) with your separate permission or (c) data already visible to any users of the Services (e.g. your profile). However, if you view or click on an ad on or off our site, the ad provider will get a signal that someone visited the page that displayed the ad, and they may through the use of mechanisms such as cookies determine it is you.',
          },
          {
            text: () =>
              'Advertising partners can associate personal data collected by the advertiser directly from you with our cookies and similar technologies. In such instances, we seek to contractually require such advertising partners to obtain your explicit, opt-in consent before doing so.',
          },
        ],
      },
      {
        subtitle: '2.6 Marketing',
        paragraphs: [
          {
            text: () => 'We promote our Services to you and others.',
          },
          {
            text: () =>
              'We use data and content about Members for invitations and communications promoting membership and network growth, engagement and our Services.',
          },
        ],
      },
      {
        subtitle: '2.7 Developing Services and Research',
        paragraphs: [
          {
            text: () => 'We develop our Services and conduct research.',
          },
        ],
      },
      {
        subtitle: 'Service Development',
        paragraphs: [
          {
            text: () =>
              'We use data, including public feedback, to conduct research and development for the further development of our Services in order to provide you and others with a better, more intuitive experience, drive membership growth and engagement on our Services, and help connect organizations and digital service providers with each other and to other business opportunities.',
          },
        ],
      },
      {
        subtitle: 'Other Research',
        paragraphs: [
          {
            text: () =>
              'We seek to create business opportunities for Members of the global economy and to help them be more productive and successful. We use the data available to us to research industry trends and policies that help bridge the gap in various industries and geographic areas.',
          },
        ],
      },
      {
        subtitle: 'Surveys',
        paragraphs: [
          {
            text: () =>
              'Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys, and you have choices about the information you provide.',
          },
        ],
      },
      {
        subtitle: '2.8 Customer Support',
        paragraphs: [
          {
            text: () => 'We use data to help you and fix problems.',
          },
          {
            text: () =>
              'We use the data (which can include your communications) to investigate, respond to and resolve complaints and Service issues (e.g. bugs).',
          },
        ],
      },
      {
        subtitle: '2.9 Aggregate Insights',
        paragraphs: [
          {
            text: () => 'We use data to generate aggregate insights.',
          },
          {
            text: () =>
              'We use your data to produce and share aggregated insights that do not identify you. For example, we may use your data to generate statistics about our members, their industry or location, to calculate ad impressions served or clicked on, or to publish visitor demographics for a Service or industry insights.',
          },
        ],
      },
      {
        subtitle: '2.10 Security and Investigations',
        paragraphs: [
          {
            text: () => 'We use data for security, fraud prevention and investigations.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                We use your data (including your communications) if we think it’s necessary for
                security purposes or to investigate possible fraud or other violations of our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[0]()}
                >
                  User Agreement
                </Typography>{' '}
                or this Privacy Policy and/or attempts to harm our Members or Visitors.
              </Typography>
            ),
          },
        ],
      },
    ],
  },
  {
    title: '3. How We Share Information',
    content: [
      {
        subtitle: '3.1 Our Services',
        paragraphs: [
          {
            text: () =>
              'We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.',
          },
          {
            text: () =>
              'Any data that you include on your profile and any content you post or action you take on our Services will be seen by others.',
          },
        ],
      },
      {
        subtitle: 'Profile',
        paragraphs: [
          {
            text: () =>
              'Your profile is fully visible to all Members and customers of our Services. It can also be visible to others e.g. Visitors to our Services or users of third-party search engines.',
          },
        ],
      },
      {
        subtitle: 'Posts and creating projects',
        paragraphs: [
          {
            text: () =>
              'Our Services allow viewing and sharing information including through posts and creating projects.',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'When you create a project, Members that satisfy the criterion you have selected will see the project and all details that you publish in the specific project.',
                    'When you respond to a project, the project creator will see all details that you provide to them in your response.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        subtitle: '3.2 Communication Archival',
        paragraphs: [
          {
            text: () =>
              'Your profile is fully visible to all Members and customers of our Services. It can also be visible to others e.g. Visitors to our Services or users of third-party search engines.',
          },
          {
            text: () =>
              'Some Members (or their employers) need, for legal or professional compliance, to archive their communications (e.g. creation or responses to projects) and other activity, and will use the services of others to provide these archival services.',
          },
        ],
      },
      {
        subtitle: '3.2 Communication Archival',
        paragraphs: [
          {
            text: () =>
              'We share your data across our different Services and Spona-affiliated entities.',
          },
          {
            text: () =>
              'We will share your data with our affiliates (such as Spona Croatia) to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to you and others.',
          },
        ],
      },
      {
        subtitle: '3.4 Service Providers',
        paragraphs: [
          {
            text: () => 'We may use others to help us with our Services.',
          },
          {
            text: () =>
              'We use others to help us provide our Services (e.g. maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.',
          },
        ],
      },
      {
        subtitle: '3.5 Legal Disclosures',
        paragraphs: [
          {
            text: () =>
              'We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us, or others.',
          },
          {
            text: () =>
              'It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (a) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (b) enforce our agreements with you, (c) investigate and defend ourselves against any third-party claims or allegations, (d) protect the security or integrity of our Service (such as by sharing with companies facing similar threats); or (e) exercise or protect the rights and safety of Spona, our Members, personnel, or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, at our discretion, that the requests are overbroad, vague, or lack proper authority, but we do not promise to challenge every demand.',
          },
        ],
      },
      {
        subtitle: '3.6 Change in Control or Sale',
        paragraphs: [
          {
            text: () =>
              'We may share your data if our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.',
          },
          {
            text: () =>
              'We can also share your personal data as part of a sale, merger, or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.',
          },
        ],
      },
    ],
  },
  {
    title: 'Your Choices & Obligations',
    content: [
      {
        subtitle: '4.1 Data Retention',
        paragraphs: [
          {
            text: () => 'We keep most of your data for as long as your account is open.',
          },
          {
            text: () =>
              'We retain your data while your account is in existence or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services every few years, we will retain your information and keep your free profile open until you decide to close your account.',
          },
          {
            text: () =>
              'We will retain personal information as long as it is necessary or relevant for our services (e.g. holding an accurate record of your business with us in the event of any dispute or complaints). In some cases, we choose to retain certain information (e.g. visits to sites carrying our plugins without clicking on the plugin) in a depersonalized or aggregated form.',
          },
        ],
      },
    ],
  },
  {
    title: '4.2 Rights to Access and Control Your Personal Data',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'You can access or delete your data. You have many choices about how your data is collected, used and shared.',
          },
          {
            text: () =>
              'We provide many choices about the collection, use and sharing of your data -- from deleting or correcting data you include in your profile, to cookies opt-outs and other controls. We offer you settings to control and manage the personal data we have about you. For data that we hold about you:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    <>
                      <Typography variant="publicBody5" color="common.black">
                        Delete Data:{' '}
                        <Typography variant="publicBody5Regular" color="common.black">
                          You can ask us to erase or delete all or some of your personal data (e.g.
                          if it is no longer necessary to provide Services to you).
                        </Typography>
                      </Typography>
                      ,
                    </>,
                    <>
                      <Typography variant="publicBody5" color="common.black">
                        Change or Correct Data:{' '}
                        <Typography variant="publicBody5Regular" color="common.black">
                          You can edit some of your personal data through your account. You can also
                          ask us to change, update or fix your data in certain cases, particularly
                          if it’s inaccurate.
                        </Typography>
                      </Typography>
                      ,
                    </>,
                    <>
                      <Typography variant="publicBody5" color="common.black">
                        Object to, or Limit or Restrict, Use of Data:{' '}
                        <Typography variant="publicBody5Regular" color="common.black">
                          You can ask us to stop using all or some of your data (e.g. if we have no
                          legal right to keep using it) or to limit our use of it (e.g. if your data
                          is inaccurate or unlawfully held).
                        </Typography>
                      </Typography>
                      ,
                    </>,
                    <>
                      <Typography variant="publicBody5" color="common.black">
                        Right to Access and/or Take Your Data:{' '}
                        <Typography variant="publicBody5Regular" color="common.black">
                          You can ask us for a copy of your personal data and can ask for a copy of
                          the personal data you provided in a machine-readable form.
                        </Typography>
                      </Typography>
                      ,
                    </>,
                  ],
                })}
              </>
            ),
          },
          // {
          //   text: () =>
          //     'Visitors can learn more about how to make these requests here. You may also contact us using the contact information below, and we will consider your request in accordance with applicable laws.',
          // },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                Visitors can learn more about how to make these requests{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[6]()}
                >
                  here
                </Typography>
                . You may also contact us using the contact information below, and we will consider
                your request in accordance with applicable laws.
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: 'Rights of EU, EEA and UK Users',
        paragraphs: [
          {
            text: () =>
              'This section of the Policy applies to you if you are in the European Union (EU), the European Economic Area (EEA) or the United Kingdom (UK).',
          },
          {
            text: () =>
              'Under applicable EU regulation, you have the following rights in respect of your personal information:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'To obtain information about how and on what basis your personal information is processed and to obtain a copy;',
                    'To rectify inaccurate personal information;',
                    'To erase your personal information in limited circumstances where:',
                  ],
                })}

                <Stack sx={{ ml: '32px !important' }}>
                  {CustomList({
                    omitListType: true,
                    listType: 'dot',
                    items: [
                      <Typography variant="publicBody5Regular" color="common.black">
                        a. You believe that it is no longer necessary for us to hold your personal
                        information;
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        b. We are processing your personal information based on legitimate interests
                        and you object to such processing, and we cannot demonstrate an overriding
                        legitimate ground for the processing;
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        c. Where you have provided your personal information to us with your consent
                        and you wish to withdraw your consent and there is no other ground under
                        which we can process your personal information; and
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        d. Where you believe the personal information we hold about you is being
                        unlawfully processed by us;
                      </Typography>,
                    ],
                  })}
                </Stack>

                {CustomList({
                  listType: 'dot',
                  items: ['To restrict processing of your personal information where:'],
                })}

                <Stack sx={{ ml: '32px !important' }}>
                  {CustomList({
                    omitListType: true,
                    listType: 'dot',
                    items: [
                      <Typography variant="publicBody5Regular" color="common.black">
                        a. The accuracy of the personal information is contested;
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        b. The processing is unlawful but you object to the erasure of the personal
                        information;
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        c. We no longer require the personal information for the purposes for which
                        it was collected, but it is required for the establishment, exercise or
                        defense of a legal claim; or
                      </Typography>,

                      <Typography variant="publicBody5Regular" color="common.black">
                        d. You have objected to us processing your personal information based on our
                        legitimate interests and we are considering your objection;
                      </Typography>,
                    ],
                  })}
                </Stack>

                {CustomList({
                  listType: 'dot',
                  items: [
                    'To object to decisions that are based solely on automated processing or profiling;',
                    'Where you have provided your personal information to us with your consent, to ask us for a copy of this data in a structured, machine-readable format and to ask us to share (port) this data to another data controller; or',
                    'To obtain a copy of or access to safeguards under which your personal information is transferred outside of the EEA.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'Under certain circumstances, you may have the right to object, on grounds relating to your particular situation, to the processing of your personal data by us, and we may be required to no longer process your personal data. If your personal data is processed for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing. In this case, your personal data will no longer be processed for such purposes by us.',
          },
          {
            text: () =>
              'You have the right to file a complaint with a supervisory authority for data protection. Please note that the right of access and the right to erasure do not constitute absolute rights and the interests of other individuals may restrict your right of access or erase in accordance with local laws.',
          },
          {
            text: () =>
              'We will ask you for additional data to confirm your identity and for security purposes, before disclosing data requested by you. We will decline to process requests that jeopardize the privacy of others, are extremely impractical, or would cause us to take any action that is not permissible under applicable laws. Additionally, as permitted by applicable laws, we will retain where necessary certain personal information for a limited period of time for record-keeping, accounting and fraud prevention purposes.',
          },
        ],
      },
    ],
  },
  {
    title: '4.3 Account Closure',
    content: [
      {
        paragraphs: [
          {
            text: () => 'We keep some of your data even after you close your account.',
          },
          {
            text: () =>
              'If you choose to close your Spona account, your data will generally stop being visible to others on our Services within 36 hours. We generally delete closed account information within 30 days of account closure, except as noted in this Privacy Policy.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                We retain your data even after you have closed your account if reasonably necessary
                to comply with our legal obligations (including law enforcement requests), meet
                regulatory requirements, resolve disputes, maintain security, prevent fraud and
                abuse, enforce our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[0]()}
                >
                  User Agreement
                </Typography>
                , or fulfill your request to “unsubscribe” from further messages from us. We will
                retain de-personalized information after your account has been closed.
              </Typography>
            ),
          },
          {
            text: () =>
              'Information you have shared with others (e.g. through profile or actions related to publishing briefs on our platform) will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members copied out of our Services. Your profile may continue to be displayed in the services of others (e.g. search engine results) until they refresh their cache.',
          },
        ],
      },
    ],
  },
  {
    title: '5. Other Important Information',
    content: [
      {
        subtitle: '5.1 Security',
        paragraphs: [
          {
            text: () => 'We implement modern information security practices to protect your data.',
          },
          {
            text: () =>
              'We implement security safeguards designed to prevent unauthorized access, misuse, or loss of data, including but not limited to:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Encrypting data sent to and from our servers to keep your data private while in transit.',
                    'Keeping software up-to-date and in line with industry recommendations in order to prevent security breaches.',
                    'Making regular automated backups to prevent data loss.',
                    'Restricting access to sensitive data and services to Spona employees on a need-to-know basis.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        subtitle: '5.2. Cross-Border Data Transfers',
        paragraphs: [
          {
            text: () => 'We store and use your data outside your country.',
          },
          {
            text: () =>
              'We process data both inside and outside of Ireland and the Republic of Croatia and rely on legally provided mechanisms to lawfully transfer data across borders. Countries where we process data may have laws that are different, and potentially not as protective, as the laws of your own country.',
          },
        ],
      },
      {
        subtitle: '5.3. Direct Marketing and Do Not Track Signals',
        paragraphs: [
          {
            text: () => 'Our statements regarding direct marketing and “do not track” signals.',
          },
          {
            text: () =>
              'We currently do not share personal data with third parties for their direct marketing purposes without your permission. We undertake steps to limit direct marketing to a reasonable and proportionate level, and to send you communications we think may be of interest or relevance to you.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                Like many websites and online services, Spona does not alter its practices when it
                receives a “Do Not Track” signal from a visitor’s browser. For more information
                regarding “Do Not Track” signals please read our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[3]()}
                >
                  Cookie policy
                </Typography>
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '5.4. Contact Information',
        paragraphs: [
          {
            text: () => 'You can contact us or use other options to resolve any complaints.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                If you have questions or complaints regarding this Policy, please{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[5]()}
                >
                  contact
                </Typography>{' '}
                Spona online. If contacting us does not resolve your complaint, you may contact the
                relevant Data Protection Authority. Residents in the Designated Countries may also
                have the right to contact our Data Protection Officer at legal@spona.io.
              </Typography>
            ),
          },
        ],
      },
    ],
  },
];

export default privacyPolicy;
