import { useState } from 'react';
import CustomModal from '../../../../../components/modal/CustomModal';
import RejectFirstStep from './RejectFirstStep';
import RejectSecondStepFirstRound from './RejectSecondStepFirstRound';
import RejectSecondStepSecondRound from './RejectSecondStepSecondRound';
import { RejectModalType } from '../../interface';
import { EnumTimedProjectPitchStatus } from 'tdc-web-backend/enums/enums';

const RejectPitchModal = ({ isOpen, setIsOpen, pitch }: RejectModalType) => {
  const [sliderPage, setSliderPage] = useState<number>(0);

  if (
    [
      EnumTimedProjectPitchStatus.FirstRoundRejected,
      EnumTimedProjectPitchStatus.SecondRoundRejected,
    ].includes(pitch?.status) &&
    sliderPage === 0
  ) {
    setSliderPage(1);
  }

  const handleClose = () => {
    setIsOpen(false);
  };
  const modalPages = [
    <RejectFirstStep
      pitchId={pitch?.timedProjectPitch as string}
      setIsOpen={setIsOpen}
      setSliderPage={setSliderPage}
    />,
    [
      EnumTimedProjectPitchStatus.SecondRound,
      EnumTimedProjectPitchStatus.SecondRoundRejected,
    ].includes(pitch?.status) ? (
      <RejectSecondStepSecondRound
        pitchData={pitch}
        setIsOpen={setIsOpen}
        setSliderPage={setSliderPage}
      />
    ) : (
      <RejectSecondStepFirstRound
        pitchData={pitch}
        setIsOpen={setIsOpen}
        setSliderPage={setSliderPage}
      />
    ),
  ];

  const shouldPreventClose =
    pitch?.status === EnumTimedProjectPitchStatus.FirstRound && sliderPage === 1;
  return (
    <CustomModal open={isOpen} onClose={shouldPreventClose ? setIsOpen : () => setIsOpen(isOpen)}>
      {modalPages[sliderPage]}
    </CustomModal>
  );
};

export default RejectPitchModal;
