import { IconButton, Paper, Stack, SxProps, Theme, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ReactNode, useState, useEffect } from 'react';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { ReactComponent as InfoAlertIcon } from '../../../assets/icons/layout-icons/InfoAlertIcon.svg';
import { ReactComponent as SuccessAlertIcon } from '../../../assets/icons/layout-icons/SuccessAlertIcon.svg';
import { ReactComponent as WarningAlertIcon } from '../../../assets/icons/layout-icons/WarningAlertIcon.svg';
import { ReactComponent as ErrorAlertIcon } from '../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import FloatingNotificationColorEnum from '../../../utils/enums/floating-notification-color-type';
import { secondaryBlue, secondaryGreen, secondaryPink, secondaryYellow } from '../../../color';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';

export interface FloatingNotificationProps {
  notificationText: string;
  color?: keyof typeof FloatingNotificationColorEnum;
  id?: string;
  renderButton?: ({ onClick }: { onClick?: () => void }) => ReactNode;
  action?: {
    text: string;
    actionOnClick: () => void;
    position: 'beforeNotificationText' | 'afterNotificationText';
  };
  sx?: SxProps<Theme> | undefined;
}

const FloatingNotification = ({
  notificationText,
  color = 'blue',
  renderButton,
  action,
  sx,
  id,
}: FloatingNotificationProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const refresh = useRefresh();

  const { mutate: openNotification } = useUpdate<NotificationDto, unknown>({
    resource: 'notifications',
  });

  const handleOpenNofitication = () => {
    openNotification(
      {
        id: id as string,
        suffix: 'seen',
      },
      {
        onSuccess: () => {
          refresh();
        },
      },
    );
  };

  const notificationVariants = {
    green: {
      bgColor: FloatingNotificationColorEnum.green,
      icon: <SuccessAlertIcon fill={secondaryGreen[700]} style={{ width: 22, height: 22 }} />,
    },
    orange: {
      bgColor: FloatingNotificationColorEnum.orange,
      icon: (
        <WarningAlertIcon fill={secondaryYellow[700]} style={{ minWidth: 22, minHeight: 22 }} />
      ),
    },
    red: {
      bgColor: FloatingNotificationColorEnum.red,
      icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 22, height: 22 }} />,
    },
    blue: {
      bgColor: FloatingNotificationColorEnum.blue,
      icon: <InfoAlertIcon fill={secondaryBlue[700]} style={{ width: 22, height: 22 }} />,
    },
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleOpenNofitication();
    setOpen(false);
  };

  return open ? (
    <Paper
      elevation={0}
      sx={{
        // height: 50,
        height: 'fitContent',
        borderRadius: '10px',
        py: 1,
        px: 1.5,
        bgcolor: 'grey.200',
        backgroundColor: notificationVariants[color]?.bgColor,
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        height="100%"
      >
        <Stack alignItems="center" spacing={1.5} direction="row">
          {notificationVariants[color]?.icon}
          <Typography variant="heading4" sx={{ textTransform: 'unset' }} color="primaryDark.600">
            {action && action.position === 'beforeNotificationText' && (
              <Typography
                variant="heading4"
                component="span"
                color="primaryLight.500"
                onClick={() => {
                  handleOpenNofitication();
                  action.actionOnClick();
                }}
                sx={{ cursor: 'pointer', textTransform: 'unset' }}
              >
                {action.text}. &nbsp;
              </Typography>
            )}
            {notificationText}
            &nbsp;
            {action && action.position === 'afterNotificationText' && (
              <Typography
                variant="heading4"
                component="span"
                color="primaryLight.500"
                onClick={() => {
                  handleOpenNofitication();
                  action.actionOnClick();
                }}
                sx={{ cursor: 'pointer', textTransform: 'unset' }}
              >
                {action.text}.
              </Typography>
            )}
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row" alignItems="center" ml={6}>
          {renderButton && renderButton({})}
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CancelRoundedIcon sx={{ fontSize: '1.3rem', color: 'primaryDark.600' }} />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  ) : null;
};

export default FloatingNotification;
