import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { useMutation } from 'react-query';
import Button from '../../../../../components/button/Button';
import axios from '../../../../../api/axios';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

interface CancelContractModalProps {
  data: ContractDto;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  title: string;
  paragraph: string;
}

const CancelContractModal = ({
  data,
  isOpen,
  setIsOpen,
  title,
  paragraph,
}: CancelContractModalProps) => {
  const refresh = useRefresh();
  const { mutate: cancel } = useMutation({
    mutationFn: () => axios.post(`/contracts/${data.id}/cancel`),
    onSuccess: () => {
      refresh();
      setIsOpen(false);
    },
  });

  const handleCancel = () => {
    cancel();
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack
        sx={{
          width: '40%',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Paper
            sx={{
              padding: '2em',
            }}
          >
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: '1.25em',
                  fontWeight: 600,
                  mt: 2,
                  mb: 1,
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1em',
                  fontWeight: 500,
                  mt: 2,
                }}
              >
                {paragraph}
              </Typography>
              <Divider />
              <Stack spacing={2} direction="row" justifyContent="space-between">
                <Button
                  variant="text"
                  onButtonClick={() => setIsOpen(false)}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onButtonClick={handleCancel}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </LocalizationProvider>
      </Stack>
    </Modal>
  );
};

export default CancelContractModal;
