import React from 'react';
import { Stack } from '@mui/material';
import { ExtendedFile } from './ExtendedFile';
import ChatInputAttachment from './ChatInputAttachment';

type ChatInputAttachmentsProps = {
  attachments: ExtendedFile[];
};

export default ({ attachments }: ChatInputAttachmentsProps) => (
  <Stack
    width="100%"
    direction="row"
    flexWrap="wrap"
    gap={1}
    mt={attachments.length ? 2 : 0} // Don't take up margin space if there are no attachments.
  >
    {attachments.map((attachment) => (
      <ChatInputAttachment attachment={attachment} />
    ))}
  </Stack>
);
