import { Avatar, Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import DragAndDropImage from '../../../assets/icons/drag-and-drop.png';
import { getBase64 } from '../../../utils/helpers';

interface AvatarUploadProps {
  avatar?: string | null;
  onAvatarChange: (base64: string) => void;
  avatarWidth?: number;
  avatarHeight?: number;
}

const AvatarUpload = ({
  avatar,
  onAvatarChange,
  avatarWidth = 100,
  avatarHeight = 100,
}: AvatarUploadProps) => {
  const [avatarFile, setAvatarFile] = useState<string | null>(avatar || null);

  useEffect(() => {
    setAvatarFile(avatar || null);
  }, [avatar]);

  return (
    <label htmlFor="avatar-input">
      <Stack>
        <input
          id="avatar-input"
          type="file"
          accept="image/*"
          name="myImage"
          hidden
          onChange={async (e) => {
            const file = e.target.files?.[0];
            if (!file) return;

            const base64 = await getBase64(file);
            if (base64) {
              setAvatarFile(String(base64));
              onAvatarChange(String(base64));
            }
          }}
        />

        {avatarFile != null ? (
          <Avatar src={avatarFile} sx={{ width: avatarWidth, height: avatarHeight }} />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: '#F4EEFA',
              borderRadius: '50%',
              width: avatarWidth,
              height: avatarHeight,
              border: '1px dashed #CDBEFF',
              cursor: 'pointer',
            }}
          >
            <Box component="img" src={DragAndDropImage} />
          </Stack>
        )}
      </Stack>
    </label>
  );
};

export default AvatarUpload;
