import Box from '@mui/material/Box';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import MultiStep from '../../../components/multisteps/Multistep';
import useMultiStep from '../../../components/multisteps/useMultiStep';
import User from './steps/User';
import Overview from './steps/Overview';
import Admin from './steps/Admin';
import { printHelperTextForSpecificCurrentStep } from '../../../utils/helpers';
import { helperTexts, initialSellerState } from './utils';
import { TPostRegistration, TUserType } from './interface';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import DetailsSeller from './steps/DetailsSeller';
import DetailsBuyer from './steps/DetailsBuyer';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import { DASHBOARD_WRAPPER_PADDING } from '../../Dashboard/Main';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';

export const labels = ['User', 'Overview', 'Admin', 'Details'];

const PostRegistration = () => {
  const {
    mutate: create,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreate({ resource: 'companies' });
  const { mutate: update, isLoading: isUpdateLoading } = useUpdate({ resource: 'users' });

  const navigate = useNavigate();
  const user = useGetLocalStorageUserData();
  const membership = user?.membership;

  const { data: userData, isLoading: isUserDataLoading } = useReferenceOne<BaseUserDto>({
    resource: 'users',
    id: user?.id,
    enabled: !!isSuccess,
  });

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem(
        'userData',
        JSON.stringify({ ...user, membership: userData?.membership }),
      );

      navigate('../end-to-end');
    }
  }, [userData]);

  const { currentStep, steps, onStepChange, form } = useMultiStep<TPostRegistration>({
    labels,
    initialState: initialSellerState,
  });

  const userType: TUserType = form.watch('user');

  const detailsForUser =
    userType === CompanyRole.SellerService ? <DetailsSeller /> : <DetailsBuyer />;

  useEffect(() => {
    // if a user HAS company tied to him, don't let them get to create-company
    if (membership !== null) navigate('/dashboard');
  }, [membership, navigate]);

  const onSubmit = (data: any) => {
    const { tmpClients, ...companyDetails } = data.details;
    const { clients } = companyDetails;

    let clientsIds = [] as string[];
    if (userType === CompanyRole.SellerService)
      clientsIds = clients?.map(({ id }: { id: string }) => id);

    update({
      data: { jobTitle: data.admin.jobTitle },
      id: user?.id as string,
    });

    create({
      ...companyDetails,
      ...(userType === CompanyRole.SellerService && { clients: clientsIds }),
      ...data.overview,
      roles: [data.user],
    });
  };

  return (
    <Box sx={{ width: '70%', p: `${DASHBOARD_WRAPPER_PADDING}px` }}>
      <MultiStep
        form={form}
        steps={steps}
        currentStep={currentStep}
        onChange={onStepChange}
        onSubmit={onSubmit}
        helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
        disableCancelButton
        isSubmitLoading={
          !!(isCreateLoading === true || isUpdateLoading === true || isUserDataLoading === true)
        }
      >
        <>
          {currentStep === 1 && <User />}
          {currentStep === 2 && <Overview />}
          {currentStep === 3 && <Admin />}
          {currentStep === 4 && detailsForUser}
        </>
      </MultiStep>
    </Box>
  );
};

export default PostRegistration;
