import {
  Stack, SxProps, Theme, Typography,
} from '@mui/material';
import { primaryDark, secondaryBlue } from '../../color';
import ContractLockedChip from '../../views/EndToEnd/Contract/ContractLockedChip';

type Props = {
    title: string | JSX.Element;
    subtitle: string;
    children: JSX.Element;
    isLocked?: boolean;
    titleAdornment?:JSX.Element | null;
}

const Header = ({ text, sx }: {
    text: string | JSX.Element,
    sx?: SxProps<Theme> | undefined;
}) => (
  <Typography variant="heading4" fontSize="1.5rem" sx={{ ...sx }}>
    {text}
  </Typography>
);

const Subheader = ({
  text,
  sx,
}: {
    text: string;
    sx?: SxProps<Theme> | undefined;
}) => (
  <Typography fontSize="1rem" sx={{ ...sx }}>
    {text}
  </Typography>
);

const ContractEditorFormWrapper = ({
  isLocked, title, subtitle, children, titleAdornment,
}: Props) => (
  <Stack sx={{
    py: 2.5,
    position: 'relative',
    marginTop: '0 !important',
  }}
  >
    <Stack
      sx={{
        p: 3,
      }}
    >
      <Stack
        sx={{ pb: 5 }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Header
              sx={{
                textTransform: 'uppercase',
                fontWeight: '600',
                color: 'primaryDark.600',
                fontSize: 28,
              }}
              text={<Stack>{title}</Stack>}
            />
            {titleAdornment}
            {isLocked && (
            <ContractLockedChip />
            )}
          </Stack>

          <Subheader
            text={subtitle}
            sx={{
              marginTop: 1,
              color: 'primaryDark.500',
              fontSize: '12px',
              textTransform: 'uppercase',

            }}
          />
        </Stack>
      </Stack>

      <Stack>
        {children}
      </Stack>
    </Stack>
    <Stack sx={{
      position: 'absolute',
      bottom: 0,
      height: 40,
      width: '100%',
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${primaryDark[200]} 100%)`,

      borderBottom: `1px solid ${secondaryBlue[100]}`,
      transform: 'translateY(100%) rotate(-180deg)',
    }}
    />
  </Stack>
);

export default ContractEditorFormWrapper;
