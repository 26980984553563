import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { primaryLight } from '../../../../../../../color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/button-icons/PlusIcon.svg';
import CustomButton from '../../../../../../../components/button/CustomButton';

interface CompanyAwardsEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface FormValues extends Pick<CompanyDto, 'awards'> {
  newAward: string;
}

const CompanyAwardsEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyAwardsEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();
  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      awards: companyData?.awards || [],
      newAward: '',
    },
  });

  const { handleSubmit, setValue, getValues, watch } = formMethods;

  const awards = watch('awards') || [];
  const newAwardValue = watch('newAward');

  const handleAddAward = () => {
    const newAward = getValues('newAward') || '';
    const awards = getValues('awards') || [];
    if (awards.includes(newAward.trim())) {
      formMethods.setError('newAward', { type: 'manual', message: 'Award already added!' });
      return;
    }
    const updatedAwards = [...awards, newAward.trim()];
    setValue('awards', updatedAwards);
    setValue('newAward', '');
  };

  const handleRemoveAward = (index: number) => {
    const awards = getValues('awards') || [];
    const updatedAwards = awards.filter((_, i) => i !== index);
    setValue('awards', updatedAwards);
  };

  const { mutate, isLoading } = useUpdate<ResponseType, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: FormValues) => {
    mutate(
      {
        id: companyData?.id as string,
        data: { awards: data.awards },
      },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      confirmButtonText="Save"
      onCancel={() => setIsEditModalOpen(false)}
      title="Industry awards"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      onConfirm={handleSubmit(onSubmit)}
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      message={
        <FormProvider {...formMethods}>
          <Stack direction="column" gap={1}>
            <CustomInput
              name="newAward"
              input={<CustomInput.Text />}
              placeholder="Enter name"
              label="Award name"
              sx={{ minHeight: 'none' }}
            />
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {awards.map((award: string, index: number) => (
                <CustomChip
                  key={`${award}-${index}`}
                  label={award}
                  onDelete={() => handleRemoveAward(index)}
                />
              ))}
            </Stack>

            <CustomButton
              variant="primaryLight"
              sx={{
                alignSelf: 'flex-end',
              }}
              disabled={!newAwardValue || newAwardValue.trim() === ''}
              onClick={handleAddAward}
              startIcon={<PlusIcon />}
            >
              Add awards
            </CustomButton>
          </Stack>
        </FormProvider>
      }
    />
  );
};

export default CompanyAwardsEditModal;
