import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as Check } from '../../assets/icons/project-icons/check.svg';

export type CaseStudyCardBase = {
  id: string;
  companyName: string;
  companyLogo: string;
  caseStudyName: string;
  coverImg: string;
  topChip?: ReactNode;
  bottomComponent?: ReactNode;
};

type CaseStudyCardProps = {
  onClick?: (caseStudyObj: CaseStudyCardBase) => void;
  className?: string;
  sx?: SxProps<Theme> | undefined;
  toggleMode?: { condition: boolean };
} & CaseStudyCardBase;

const CaseStudyCard = ({
  id,
  companyName,
  companyLogo,
  caseStudyName,
  coverImg,
  onClick,
  className,
  sx,
  topChip,
  bottomComponent,
  toggleMode,
}: CaseStudyCardProps) => (
  <Card
    className={className}
    onClick={() =>
      onClick?.({
        id,
        companyName,
        companyLogo,
        caseStudyName,
        coverImg,
        topChip,
        bottomComponent,
      })
    }
    sx={{
      borderRadius: 2,
      boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
      ...sx,
    }}
  >
    <CardActionArea sx={{ width: '100%' }}>
      <Stack
        sx={{
          position: 'relative',
          paddingBottom: '75%',
          backgroundImage: `url(${coverImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        {topChip && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            {topChip}
          </Stack>
        )}

        {toggleMode &&
          (toggleMode.condition ? (
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 10,
                borderRadius: '4px',
                width: '18px',
                height: '18px',
                border: '2px solid',
                backgroundColor: 'secondaryGreen.500',
                borderColor: 'secondaryGreen.500',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Check />
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 10,
                borderRadius: '4px',
                width: '18px',
                height: '18px',
                border: '2px solid',
                borderColor: 'primaryDark.200',
              }}
            />
          ))}
      </Stack>

      <CardContent>
        <Stack direction="row" mb={2} alignItems="center">
          <Avatar alt="card logo" src={companyLogo} sx={{ width: 34, height: 34 }} />

          <Typography variant="heading4" ml={1} color="#000000">
            {companyName}
          </Typography>
        </Stack>

        <Typography variant="body3" color="primaryDark.600">
          {caseStudyName}
        </Typography>

        <Stack mt={2.5}>{bottomComponent}</Stack>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default CaseStudyCard;
