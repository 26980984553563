import { useForm, FormProvider } from 'react-hook-form';
import {
  mapCompanySize,
  mapProjectSize,
  mapHourlyRate,
  mapCountry,
  EnumProjectSize,
  EnumProjectHourlyRate,
  EnumCompanySize,
  EnumCountry,
} from 'tdc-web-backend/enums/enums';
import { Stack, Divider, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useEffect } from 'react';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import CustomSelectInput from '../../../../../../../components/custom-input/inputs/CustomInputSelect';
import { primaryLight } from '../../../../../../../color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { mapStates } from '../../../../../../../enums';

interface EditCompanyOverViewProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditCompanyOverView = ({ isModalOpen, setIsModalOpen }: EditCompanyOverViewProps) => {
  const refresh = useRefresh();
  const { companyData } = useSellerHomescreenContext();

  const formMethods = useForm<Partial<CompanyDto>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      averageProjectSize: companyData?.averageProjectSize || ('' as EnumProjectSize),
      hourlyRate: companyData?.hourlyRate || ('' as EnumProjectHourlyRate),
      companySize: companyData?.companySize || ('' as EnumCompanySize),
      // founded: founded || '',
      headquartersLocation: {
        country: companyData?.headquartersLocation?.country || ('' as EnumCountry),
        city: companyData?.headquartersLocation?.city || '',
        state: companyData?.headquartersLocation?.state || '',
      },
    },
  });

  const { mutate, isLoading } = useUpdate<ResponseType, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Partial<CompanyDto>) => {
    mutate(
      {
        id: companyData?.id as string,
        data,
      },
      {
        onSuccess: () => {
          refresh();
          setIsModalOpen(false);
        },
      },
    );
  };

  useEffect(() => {
    if (formMethods.getValues('headquartersLocation.country') !== EnumCountry.Us) {
      formMethods.setValue('headquartersLocation.state', '');
    }
  }, [formMethods.getValues('headquartersLocation.country')]);

  return (
    <ConfirmModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      confirmButtonText="Save"
      onCancel={() => setIsModalOpen(false)}
      onConfirm={formMethods.handleSubmit(onSubmit)}
      title="Company Overview"
      width="40%"
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      message={
        <FormProvider {...formMethods}>
          <Stack
            sx={{
              maxHeight: '398px',
              overflowY: 'auto',
              fontSize: '12px',
              fontFamily: 'Gellix-SemiBold',
            }}
            spacing={3}
            pr="10px"
          >
            <Stack spacing={3}>
              <Typography variant="heading4" color="primaryDark.500">
                {' '}
                Headquarters location
              </Typography>

              <Divider />

              <CustomInput
                rules={{ required: 'Country is required' }}
                name="headquartersLocation.country"
                label="Country"
                input={<CustomInput.Autocomplete />}
                choices={mapCountry}
                placeholder="Select country"
                sx={{ minHeight: 'none' }}
              />

              <CustomInput
                rules={{ required: 'City is required' }}
                input={<CustomInput.Text />}
                name="headquartersLocation.city"
                label="City"
                placeholder="Type city name"
                sx={{ minHeight: 'none' }}
              />

              <CustomInput
                input={<CustomInput.Autocomplete />}
                name="headquartersLocation.state"
                label="State"
                placeholder="Select state"
                choices={mapStates}
                disabled={formMethods.watch('headquartersLocation.country') !== EnumCountry.Us}
                required={formMethods.watch('headquartersLocation.country') === EnumCountry.Us}
              />
            </Stack>

            <Divider />

            <Stack spacing={3}>
              <CustomInput
                rules={{ required: 'Company size is required' }}
                input={<CustomInput.Autocomplete />}
                name="companySize"
                label="Company size"
                placeholder="Select size"
                choices={mapCompanySize}
                sx={{ minHeight: 'none' }}
              />

              <CustomInput
                input={<CustomInput.Text />}
                name="founded"
                label="Founding Year"
                placeholder="Type year"
                sx={{ minHeight: 'none' }}
                type="number"
              />

              <CustomInput
                input={<CustomInput.Select />}
                name="averageProjectSize"
                label="Average project size"
                placeholder="Select size"
                choices={mapProjectSize}
                sx={{ minHeight: 'none' }}
              />

              <CustomInput
                rules={{ required: 'Hourly rate is required' }}
                name="hourlyRate"
                label="Hourly rate"
                placeholder="Select rate"
                choices={mapHourlyRate}
                input={<CustomSelectInput />}
                sx={{ minHeight: 'none' }}
              />
            </Stack>
          </Stack>
        </FormProvider>
      }
    />
  );
};

export default EditCompanyOverView;
