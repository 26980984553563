import { SxProps, Theme } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const fakeLinkStyles: SxProps<Theme> = {
  color: 'primaryLight.500',
  bgcolor: 'transparent',
  minWidth: 0,
  width: 'fit-content',
  height: 'fit-content',
  cursor: 'pointer',
  p: 0,
  '&:hover': {
    bgcolor: 'transparent',
    textDecoration: 'underline',
  },
  '&:disabled': {
    bgcolor: 'transparent',
    textDecoration: 'underline',
    color: 'primaryLight.300',
  },
};

export interface TermOfServiceI {
  title?: string;
  introduction?: string | ReactNode;
  subtitle?: string;
  content?: {
    title?: string;
    subtitle?: string;
    paragraphs?: { text: (onClick?: Function[]) => string | ReactNode }[];
  }[];
}

// tabs:
// 0: Terms of Service
// 1: Privacy Policy
// 2: Spona Digital Contract Terms
// 3: Cookie Policy

// given the tabs explained above,
// each array element is a function that will on click trigger
// certain actions
//
// dictionary:
// array index 0: setValue(0) => switch to Terms of Service tab
// array index 1: setValue(1) => switch to Privacy Policy tab
// array index 2: setValue(2) => switch to Spona Digital Contract Terms tab
// array index 3: setValue(3) => switch to Cookie Policy tab
// array index 4: => navigate to '/'
// array index 5: => navigate to '/contact-us'
// array index 6: => navigate to '/legal@spona.io'
export const onLinkClickArray = (
  navigate: NavigateFunction,
  setValue: Dispatch<SetStateAction<number>>,
) => [
  () => {
    navigate('/terms-of-service');
    setValue(0);
  },
  () => {
    navigate('/terms-of-service/privacy-policy');
    setValue(1);
  },
  () => {
    navigate('/terms-of-service/spona-digital-contract-terms');
    setValue(2);
  },
  () => {
    navigate('/terms-of-service/cookie-policy');
    setValue(3);
  },
  () => {
    navigate('/');
  },
  () => {
    navigate('/contact-us');
  },
  () => {
    navigate('/legal@spona.io');
  },
];
