import { useEffect, useState } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import useGetLocalStorageUserData from './useGetLocalStorageUserData';
import useReferenceOne from './crud-hooks/useReferenceOne';

const useGetCooperationCompany = (projectId?: string) => {
  const userData = useGetLocalStorageUserData();
  const [cooperationCompanyId, setCooperationCompanyId] = useState<string | null>(null);

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: projectId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: cooperationCompanyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: cooperationCompanyId as string,
    enabled: !!cooperationCompanyId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // getting cooperation's id
    if (projectData !== null && companyData !== null) {
      // if you're seller, it means your cooperation company is buyer
      if (projectData?.seller === companyData?.id) {
        setCooperationCompanyId(projectData?.buyer);
      }

      // if you're buyer, it means your cooperation company is seller
      if (projectData?.buyer === companyData?.id) {
        setCooperationCompanyId(projectData?.seller);
      }
    }
  }, [projectData, companyData]);

  return cooperationCompanyData;
};

export default useGetCooperationCompany;
