import { Stack, Typography } from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { useParams } from 'react-router-dom';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import ProjectpaymentDetailsCard from './ProjectPaymentDetailsCard';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import ProjectPaymentsList from './ProjectPaymentsList';
import useGetCooperationCompany from '../../../../utils/hooks/useGetCooperationCompany';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';

const ProjectPaymentDetails = () => {
  const { paymentId } = useParams();
  const userData = useGetLocalStorageUserData();
  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: paymentId,
    enabled: !!paymentId,
  });

  const cooperationCompany = useGetCooperationCompany(projectData?.id);
  const { data: milestonesResponse } = useGetMany<MilestoneDto>({
    resource: `/milestones?company=${userData?.membership?.company}&project=${paymentId}`,
    enabled: !!userData,
  });

  const milestonesData = milestonesResponse?.data.results;

  return (
    <Stack spacing={5} sx={{ mt: 4 }}>
      <Stack spacing={8} direction="row" justifyContent="space-between">
        <Stack width="40%" className="react-joyride-step-1-individual-payment">
          <Typography variant="h2" sx={{ mb: 2 }}>
            {projectData?.name}
          </Typography>
          <Typography variant="subtitle1" fontSize="1rem" fontWeight="500" color="#757875">
            Collaboration with:
          </Typography>
          <Typography variant="body1" fontSize="1rem" fontWeight="500">
            {cooperationCompany?.name}
          </Typography>
        </Stack>
        <Stack width="45%">
          <ProjectpaymentDetailsCard
            projectData={projectData as ProjectDto}
            milestones={milestonesData as MilestoneDto[]}
          />
        </Stack>
      </Stack>
      <Stack>

        <ProjectPaymentsList milestones={milestonesData as MilestoneDto[]} />
      </Stack>
    </Stack>
  );
};

export default ProjectPaymentDetails;
