import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
  description: string;
  title?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ description, title }: MetaTagsProps) => (
  <Helmet>
    {title && <meta property="og:title" content={title} />}
    {title && <title>{title}</title>}
    <meta name="description" content={description} />
    <meta name="og:description" content={description} />
    <meta name="twitter:card" content={description} />
    <meta name="pinterest:description" content={description} />
  </Helmet>
);

export default MetaTags;
