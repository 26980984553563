import { Divider, Grid, Stack } from '@mui/material';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { useParams } from 'react-router-dom';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CardListPagination from '../../Archives/components/CardListPagination';
import CompanyProfileCsCard from './CompanyProfileCsCard';
import useQueryState from '../../../../utils/hooks/useQueryState';
import LoadingIndicator from '../../../../components/loading-indicator/LoadingIndicator';

const CompanyCaseStudies = () => {
  const params = useParams();

  const [queryState, , , searchParams] = useQueryState();

  const queryBuilder = () => {
    searchParams.delete('offset');
    return searchParams.toString();
  };

  const { data: response, isLoading } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?company=${
      params?.companyId
    }&status=published&status=verified&status=under_review&limit=${queryState.limit ?? 12}&offset=${
      queryState.offset ?? 0
    }&${queryBuilder()}`,
  });
  const caseStudies = response?.data.results;
  const count = response?.data.count;

  return (
    <Stack
      overflow="hidden"
      maxWidth={{ xs: '20rem', sm: '100%' }}
      alignSelf="center"
      mx={{ xs: 'auto', sm: 0 }}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={3} rowSpacing={5} pt={0} mt={-1.5} gridAutoRows="max-content">
          {caseStudies?.map((item: any, index) => (
            <Grid
              key={item?.id}
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              gridAutoRows="max-content"
            >
              <CompanyProfileCsCard data={item} showFocusArea />
            </Grid>
          ))}
        </Grid>
      )}
      <Divider
        sx={{
          mt: 5,
        }}
      />
      <CardListPagination count={count} />
    </Stack>
  );
};

export default CompanyCaseStudies;
