import { useFormContext, Controller } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { Rating, RatingProps, Theme } from '@mui/material';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { primaryDark, secondaryBlue } from '../../color';

type Props = {
  name: string;
  required?: boolean;
  labelAdornment?: JSX.Element | null;
  helperStyles?: SxProps<Theme>;
} & RatingProps;

const ControlledRatingInput = ({ name, required, labelAdornment, sx, ...props }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={required ? { required: 'This field is required' } : { required: false }}
      render={({ field }) => (
        <Rating
          name={field.name}
          value={field.value}
          onChange={(event, newValue) => {
            field.onChange(newValue);
          }}
          icon={<StarIcon style={{ margin: '0 2px' }} fill={secondaryBlue[700]} />}
          emptyIcon={
            <StarIcon stroke={primaryDark[300]} fill={'transparent'} style={{ margin: '0 2px' }} />
          }
          {...props}
        />
      )}
    />
  );
};

export default ControlledRatingInput;
