import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { EnumEventType } from 'tdc-web-backend/enums/enums';
import CustomModal from '../../../components/modal/CustomModal';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icons/CalendarDateIcon.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/calendar-icons/CancelIconRed.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/calendar-icons/CheckIconGreen.svg';
import { ReactComponent as EmptyDot } from '../../../assets/icons/calendar-icons/EmptyCircleIcon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/calendar-icons/HiperlinkIcon.svg';
import { ReactComponent as PenIcon } from '../../../assets/icons/project-icons/PenIcon.svg';
import { ReactComponent as TrashBinIcon } from '../../../assets/icons/project-icons/TrashBinIcon.svg';
import { primaryDark, secondaryBlue } from '../../../color';
import CustomButton from '../../../components/button/CustomButton';
import { EventParticipant } from './EventMeeting';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';

export interface EventDetailModalProps {
  open: boolean;
  event: CalendarEventDto;
  onClose: () => void;
  openEditModal: () => void;
  openDeleteModal: () => void;
}

interface EventModalContentProps {
  event: CalendarEventDto;
  openEditModal: () => void;
  openDeleteModal: () => void;
  isTooltipOpen: boolean;
  onClose: () => void;
}

interface DeadlineModalContentProps {
  event: CalendarEventDto;
  isTooltipOpen: boolean;
  onClose: () => void;
}

const iconMap = new Map([
  [true, <CheckIcon />],
  [false, <CancelIcon />],
  [null, <EmptyDot />],
]);

const deadlineTitleMap = new Map([
  [EnumEventType.Milestone, 'Milestone deadline'],
  [EnumEventType.Task, 'Task deadline'],
  [EnumEventType.Subtask, 'Subtask deadline'],
  [EnumEventType.Payment, 'Payment deadline'],
]);

const simpleDateFormat = (date: string | Date) => format(new Date(date), 'MMMM dd');
const simpleTimeFormat = (date: string | Date) => format(new Date(date), 'HH:mm b');
const simpleTimeZoneFormat = (date: string | Date) => format(new Date(date), 'O');

export function EventDetailModal({
  event,
  open,
  onClose,
  openEditModal,
  openDeleteModal,
}: EventDetailModalProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleLinkIconCliick = () => {
    navigator.clipboard.writeText('https://meet.jit.si');
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  const isEvent = event.type === EnumEventType.Event;

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      paperStyleProps={{
        position: 'relative',
      }}
    >
      {isEvent ? (
        <EventModalContent
          event={event}
          onClose={onClose}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          isTooltipOpen={isTooltipOpen}
        />
      ) : (
        <DeadlineModalContent event={event} onClose={onClose} isTooltipOpen={isTooltipOpen} />
      )}
    </CustomModal>
  );
}

const EventModalContent = ({
  event,
  openEditModal,
  openDeleteModal,
  isTooltipOpen,
  onClose,
}: EventModalContentProps) => (
  //
  <>
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: 'absolute',
        top: '1.3rem',
        right: '1.3rem',
      }}
    >
      <IconButton sx={{ p: 0 }} onClick={openEditModal}>
        <PenIcon fill={primaryDark[500]} />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={openDeleteModal}>
        <TrashBinIcon fill={primaryDark[500]} />
      </IconButton>
    </Stack>
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center">
        <Stack
          p={1.8}
          mr={2}
          bgcolor="secondaryBlue.100"
          borderRadius={1}
          justifyContent="center"
          alignItems="center"
        >
          <CalendarIcon width="20px" fill={secondaryBlue[700]} />
        </Stack>
        <Stack>
          <Typography variant="heading5">{event.name}</Typography>

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" color="primaryDark.600">
              {simpleDateFormat(event?.start)}
            </Typography>
            <Typography variant="body1" color="primaryDark.500" px={1}>
              •
            </Typography>
            <Typography variant="body2" color="primaryDark.600">
              {simpleTimeFormat(event?.start)} -{simpleTimeFormat(event?.end)}
            </Typography>
            <Typography
              component="div"
              variant="heading4"
              color="secondaryBlue.500"
              sx={{
                backgroundColor: 'primaryDark.200',
                p: '6px',
                borderRadius: '4px',
              }}
            >
              {simpleTimeZoneFormat(event?.start)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <Typography variant="heading5" color="primaryDark.500">
          {event.attendees?.length} participants
        </Typography>
        {event.attendees?.map((attendee: EventParticipant) => (
          <Stack direction="row" spacing={1.5} alignItems="center">
            {iconMap.get(attendee.attending as null | boolean)}
            <Typography variant="heading5" color="primaryDark.500">
              {`${attendee.user.firstName} ${attendee.user.lastName}`}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack spacing={2}>
        {/* <Typography variant="heading5" color="primaryDark.500">
              video meeting link
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: "4px",
                border: "1px solid",
                p: 1,
                px: 2,
                borderColor: "primaryDark.200",
              }}
            >
              <Typography variant="body3" color="primaryDark.600">
                https://meet.jit.si/topdigital/8f5s4f-f
              </Typography>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={isTooltipOpen}
                enterTouchDelay={1000}
                disableTouchListener
                title="Copied link"
                arrow
              >
                <IconButton onClick={handleLinkIconCliick}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Stack> */}
        <Stack spacing={1}>
          <Typography variant="heading5" color="primaryDark.500">
            Description
          </Typography>
          <Typography variant="body3" color="primaryDark.600">
            {event.description.length ? event.description : '---'}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="end">
          <CustomButton variant="secondary" onClick={onClose}>
            Close
          </CustomButton>
        </Stack>
      </Stack>
    </Stack>
  </>
);
const DeadlineModalContent = ({ event, onClose }: DeadlineModalContentProps) => (
  //
  <Stack spacing={3}>
    <Stack direction="row" alignItems="center">
      <Stack
        p={1.8}
        mr={2}
        bgcolor="secondaryBlue.100"
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
      >
        <CalendarIcon width="20px" fill={secondaryBlue[700]} />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="heading5">
          {deadlineTitleMap.get(event.type as EnumEventType)}
        </Typography>
        <Typography variant="body2" color="primaryDark.600">
          {simpleDateFormat(event?.end)}
        </Typography>
      </Stack>
    </Stack>
    <Divider />
    <Stack spacing={3}>
      <CustomReferenceField
        variant="heading5"
        color="primaryDark.600"
        resource="projects"
        id={event?.project}
        displayName="name"
      />
      <Typography
        variant="body2"
        color="primaryDark.500"
      >{`${event.name} is due for completion`}</Typography>
    </Stack>
    <Divider />
    <Stack direction="row" justifyContent="end">
      <CustomButton variant="secondary" onClick={onClose}>
        Close
      </CustomButton>
    </Stack>
  </Stack>
);
