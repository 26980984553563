import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const IndividualContractDetailsJoyride = (userBuyer: boolean): Step[] => [
  {
    content: (
      <Typography>
        The contracts screen shows all of your contracts on the platform.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-1-individual-contract',
    title: 'Individual contract screen',
  },
  {
    content: (
      <Typography>
        The contract overview screen shows your contract status,
        date of creation and signing. Once your contract has been fully activated,
        you will be able to download it by navigating to the Download PDF button and clicking it.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-2-individual-contract',
    title: 'Contract overview',
  },
  {
    content: userBuyer ? (
      <Typography>
        Navigate to the edit button and click it to create a contract annex.
        It will need to be reviewed and signed by your agency for the changes to apply.
      </Typography>
    )
      : (
        <Typography>
          Navigate to the edit button and click it to create a contract annex.
          It will need to be reviewed and signed by your client for the changes to apply.

        </Typography>
      ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-3-individual-contract',
    title: 'Edit contract',
  },
  {
    content: userBuyer ? (
      <Typography>
        Navigate to the cancel button and click it to cancel your contract.
        Be careful, as this will signal that you want to terminate your current progress and cooperation with your agency.

      </Typography>
    )
      : (
        <Typography>

          Navigate to the cancel button and click it to cancel your contract.
          Be careful, as this will signal that you want to terminate your current progress and cooperation with your client.

        </Typography>
      ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-4-individual-contract',
    title: 'Cancel contract',
  },
  {
    content: (
      <Typography>
        Modify your contract to create a contract annex.
        Here you can view all of your annexes, their statuses,
        date created and date signed, as well as download them.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-5-individual-contract',
    title: 'Annexes overview',
  },
];

export default IndividualContractDetailsJoyride;
