import { Stack, Typography } from '@mui/material';
import { CompanyRole, EnumCurrency, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { MilestoneDto, MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { Dispatch, SetStateAction, useState } from 'react';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { primaryDark, primaryLight, secondaryPink } from '../../../../../color';
import { ReactComponent as FlagIcon } from '../../../../../assets/icons/layout-icons/FlagIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/layout-icons/PencilIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/layout-icons/CalendarIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/layout-icons/ArrowRight.svg';
import { ReactComponent as CalendarCheckIcon } from '../../../../../assets/icons/project-icons/CalendarCheckIcon.svg';
import CustomDropdown from '../../../../../components/custom-dropdown/CustomDropdown';
import { formatCurrency, formatDateFns } from '../../../../../utils/helpers';
import DynamicDescriptionAndReadMoreWithModal from '../../../../../components/dynamic-description-and-read-more-with-modal/DynamicDescriptionAndReadMoreWithModal';
import useDetermineCompanyRoles from '../../../../../utils/hooks/useDetermineCompanyRoles';
import EndToEndDetailScreenTypeEnum from '../../../../../utils/enums/end-to-end-detail-screen-type';
import RecurringMilestoneDropdown from '../Recurring/RecurringMilestoneDropdown';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';
import AmendContractModal from '../../../Contracts/modals/AmendContractModal';

interface GeneralMilestoneDetailsProps {
  milestoneData?: MilestoneDto;
  recurringMilestonesParent?: MilestoneSnakeDto | undefined | null;
  setIsFlagAndUnflagModalOpen: Dispatch<SetStateAction<boolean>>;
  setEntityType: Dispatch<SetStateAction<EndToEndDetailScreenTypeEnum>>;
  setEntityId: Dispatch<SetStateAction<string | null>>;
  setTaskId: Dispatch<SetStateAction<string | null>>;
  determineWhichTaskToShow: (tasks: TaskDto[]) => string | null;
}

const GeneralMilestoneDetails = ({
  milestoneData,
  recurringMilestonesParent,
  setIsFlagAndUnflagModalOpen,
  setEntityType,
  setEntityId,
  setTaskId,
  determineWhichTaskToShow,
}: GeneralMilestoneDetailsProps) => {
  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const isMilestoneFlagged = milestoneData?.buyerFlagged || milestoneData?.sellerFlagged;
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const shouldShowButtonToUnflag = () => {
    if (isUserBuyer) {
      if (milestoneData?.buyerFlagged) return true;
      if (milestoneData?.sellerFlagged) return false;
    }

    if (!isUserBuyer) {
      if (milestoneData?.buyerFlagged) return false;
      if (milestoneData?.sellerFlagged) return true;
    }

    return true;
  };

  const customEditDropdownMenuItemContent = {
    key: 'edit',
    icon: <PencilIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
    text: 'Edit',
    disabled: (() => {
      if (
        milestoneData?.status === EnumProjectMilestoneStatus.UnderReview ||
        milestoneData?.status === EnumProjectMilestoneStatus.Approved ||
        milestoneData?.status === EnumProjectMilestoneStatus.Completed
      ) {
        return true;
      }

      return false;
    })(),
    onClick: () => setIsEditModalOpen(true),
  };

  const { data: projectData } = useReferenceOne<ProjectDto>({
    enabled: !!milestoneData?.project,
    resource: 'projects',
    id: milestoneData?.project,
  });

  return (
    <>
      {/* edit milestone modal */}
      <AmendContractModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        projectId={milestoneData?.project}
        contractId={projectData?.latestContract as string}
      />
      <Stack spacing={6}>
        {/* name, start, end date, flagged indicator */}
        <Stack spacing={1.5}>
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack spacing={1} direction="row" alignItems="center" height="fit-content">
              <CustomDropdown
                menuItemsContents={
                  shouldShowButtonToUnflag()
                    ? [
                        {
                          key: isMilestoneFlagged ? 'Un-Flag' : 'Flag',
                          icon: (
                            <FlagIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />
                          ),
                          text: isMilestoneFlagged ? 'Un-Flag' : 'Flag',
                          disabled: (() => {
                            // if milestone status is pending, disable the button
                            if (
                              milestoneData?.status === EnumProjectMilestoneStatus.Pending ||
                              milestoneData?.status === EnumProjectMilestoneStatus.Completed
                            ) {
                              return true;
                            }

                            // every else status enable the button so it can be flagged
                            return false;
                          })(),
                          onClick: () => {
                            setIsFlagAndUnflagModalOpen(true);
                            setEntityType(EndToEndDetailScreenTypeEnum.Milestone);
                            setEntityId(milestoneData?.id as string);
                          },
                        },

                        customEditDropdownMenuItemContent,
                      ]
                    : [customEditDropdownMenuItemContent]
                }
              />

              <Typography variant="heading2" color="primaryDark.600">
                {milestoneData?.name}
              </Typography>
            </Stack>

            {/* flagged indicator & dropdown for recurring milestones */}
            <Stack spacing={1} direction="row" alignItems="center" height="40px">
              {isMilestoneFlagged && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{
                    height: '100%',
                    bgcolor: 'secondaryPink.100',
                    px: 1.5,
                    py: 0.85,
                    borderRadius: '6px',
                  }}
                >
                  <FlagIcon fill={secondaryPink[700]} style={{ width: 20, height: 20 }} />

                  <Typography variant="heading4" color="secondaryPink.700">
                    flagged
                  </Typography>
                </Stack>
              )}

              {recurringMilestonesParent && (
                <RecurringMilestoneDropdown
                  milestone={recurringMilestonesParent}
                  setTaskId={setTaskId}
                  showText
                  determineWhichTaskToShow={determineWhichTaskToShow}
                />
              )}
            </Stack>
          </Stack>

          <Stack spacing={1.5} alignItems="center" direction="row">
            <CalendarIcon fill={primaryDark[500]} style={{ width: 20, height: 20 }} />

            {milestoneData?.start && (
              <Typography color="primaryDark.600" fontWeight={600}>
                {formatDateFns(milestoneData.start, '-')}
              </Typography>
            )}

            <ArrowRightIcon fill={primaryLight[500]} style={{ width: 16, height: 16 }} />

            <CalendarCheckIcon fill={primaryDark[500]} style={{ width: 20, height: 20 }} />

            {milestoneData?.end && (
              <Typography color="primaryDark.600" fontWeight={600}>
                {formatDateFns(milestoneData.end, '-')}
              </Typography>
            )}
          </Stack>
        </Stack>

        {/* description & budget */}
        <Stack direction="row" justifyContent="space-between">
          <DynamicDescriptionAndReadMoreWithModal
            description={milestoneData?.summary as string}
            sx={{ width: '60%' }}
            title="Milestone description"
          />

          <Stack spacing={2}>
            <Typography variant="heading4" color="primaryDark.500">
              budget
            </Typography>

            {milestoneData && milestoneData.budget && milestoneData.currency && (
              <Typography
                variant="heading4"
                color="primaryDark.600"
                fontSize={{ md: '1.45rem', lg: '1.75rem' }}
              >
                {formatCurrency(
                  milestoneData?.budget as number,
                  milestoneData?.currency as EnumCurrency,
                ) ?? '-'}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default GeneralMilestoneDetails;
