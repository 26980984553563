import { Box, Stack } from '@mui/material';
import { Ref, RefAttributes, RefObject, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LAYOUT_DEFAULTS from '../../../../../layout/utils';
import CustomInput from '../../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../../components/button/CustomButton';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import OnSubmitModal from './components/OnSubmitModal';
import Header from './components/Header';
import NameEmail from './components/NameEmail';
import CompanyPhone from './components/CompanyPhone';
import SpendAccounts from './components/SpendAccounts';

const defaultValues = {
  name: '',
  email: '',
  companyName: '',
  phone: '',
  accounts: {
    meta: false,
    google: false,
    tiktok: false,
    snapchat: false,
    twitter: false,
    taboola: false,
  },
  message: '',
  monthlySpend: '',
};

const accountsMap = {
  meta: 'Meta',
  google: 'Google',
  tiktok: 'TikTok',
  snapchat: 'Snapchat',
  twitter: 'Twitter',
  taboola: 'Taboola',
};

const SponaMediaContactForm = ({ formRef }: { formRef: RefObject<any> }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErroModalOpen, setIsErrorModalOpen] = useState(false);
  const form = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useCreate({ resource: 'spona-media-contact' });

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      const isSelected = Object.values(value.accounts as any).some((val) => val === true);

      if (isSelected) {
        form.clearErrors('accounts');
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const onSubmit = () => {
    const isSelected = Object.values(form.getValues('accounts')).some((value) => value === true);

    if (!isSelected) {
      form.setError('accounts', {
        types: {
          required: 'This is required',
          message: 'string',
        },
      });

      return;
    }
    form.clearErrors('accounts');

    const eventHandlers = {
      onSuccess: (e: any) => {
        setIsSuccessModalOpen(true);
        form.reset();
      },
      onError: (err: any) => {
        setIsErrorModalOpen(true);
      },
    };
    const formValues = form.getValues();
    mutate(
      {
        ...formValues,
        accounts: Object.keys(formValues.accounts)
          .filter((key) => formValues.accounts[key as keyof typeof formValues.accounts])
          .map((key) => accountsMap[key as keyof typeof accountsMap]),
      },
      eventHandlers,
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Box
            ref={formRef}
            sx={{
              ...LAYOUT_DEFAULTS,
              px: { xs: 2, md: 6 },
              py: 6,
              borderRadius: '20px',
              boxShadow: ' 1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
              scrollMarginTop: '120px',
            }}
          >
            <Stack sx={{ maxWidth: '960px' }} spacing={4}>
              <Header />

              <NameEmail isLoading={isLoading} />

              <CompanyPhone isLoading={isLoading} />

              <SpendAccounts isLoading={isLoading} />

              <CustomInput
                input={<CustomInput.Text />}
                type="text"
                name="message"
                label="Message"
                placeholder="Let us know how we can help you."
                sx={{ paddingBottom: '1.25rem' }}
                multiline
                rows={5}
                disabled={isLoading}
              />

              <CustomButton
                variant="primary"
                sx={{
                  height: '2.8125rem',
                  width: '100%',
                  maxWidth: '120px',
                  marginTop: '22px !important',
                }}
                onClick={form.handleSubmit(onSubmit)}
                loading={isLoading}
              >
                Submit
              </CustomButton>
            </Stack>
          </Box>
        </form>
      </FormProvider>
      <OnSubmitModal
        type="success"
        isOpen={isSuccessModalOpen}
        onConfirm={() => setIsSuccessModalOpen(false)}
        setIsOpen={setIsSuccessModalOpen}
      />
      <OnSubmitModal
        type="error"
        isOpen={isErroModalOpen}
        onConfirm={() => setIsErrorModalOpen(false)}
        setIsOpen={setIsErrorModalOpen}
      />
    </>
  );
};

export default SponaMediaContactForm;
