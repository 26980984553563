import { Box, Stack, Typography, Divider, Paper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CreateProjectDto, ProjectDto } from 'tdc-web-backend/projects/schemas';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import LoadingButton from '../../../../../components/button/LoadingButton';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import ControlledReferenceSelectInput from '../../../../../components/custom-inputs/ControlledReferenceSelectInput';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';

const AdminCreateProject = () => {
  const navigate = useNavigate();
  const formMethods = useForm<CreateProjectDto>();
  const { data: sellerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=seller_tool&role=seller_service&status=approved',
  });
  const { data: buyerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=buyer&status=approved',
  });
  const { mutate, isLoading } = useCreate<ProjectDto, CreateProjectDto>({ resource: '/projects' });

  const { handleSubmit } = formMethods;

  const submitForm = (data: CreateProjectDto) => {
    mutate(data, {
      onSuccess: (data) => {
        navigate('../projects');
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Stack spacing={2} direction="row" sx={{ py: 3 }}>
          <Stack spacing={3} width="100%">
            <Typography variant="h3">Create Project</Typography>
            <Stack spacing={3}>
              <Box>
                <Divider />
              </Box>
              <Paper
                sx={{
                  p: 3,
                  bgcolor: 'grey.100',
                }}
              >
                <Stack spacing={1}>
                  <Stack spacing={6}>
                    <Stack spacing={1} sx={{ px: 5 }}>
                      <Box sx={{ mb: 0 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Title
                        </Typography>
                        <Typography variant="caption">
                          The title should include the necessery keywords and be between 4-9 words
                          long.
                        </Typography>
                      </Box>
                      <ControlledTextInput name="name" placeholder="Title" required />
                    </Stack>
                    <Stack spacing={1} sx={{ px: 5 }}>
                      <Box sx={{ mb: 0 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Buyer
                        </Typography>
                      </Box>
                      <ControlledReferenceSelectInput
                        name="buyer"
                        choices={buyerData?.data.results}
                        displayName="name"
                        displayValue="id"
                        required
                      />
                    </Stack>
                    <Stack spacing={1} sx={{ px: 5 }}>
                      <Box sx={{ mb: 0 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Seller
                        </Typography>
                      </Box>
                      <ControlledReferenceSelectInput
                        name="seller"
                        choices={sellerData?.data.results}
                        displayName="name"
                        displayValue="id"
                        required
                      />
                    </Stack>
                    <Stack spacing={1} sx={{ px: 5 }}>
                      <Box sx={{ mb: 0 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Description
                        </Typography>
                      </Box>
                      <ControlledTextInput name="description" multiline minRows={4} required />
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>

              <Divider sx={{ pt: 5 }} />

              <LoadingButton
                onButtonClick={() => null}
                sx={{ alignSelf: 'end' }}
                variant="contained"
                size="medium"
                fontWeight="bold"
                minWidth={102}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AdminCreateProject;
