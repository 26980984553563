import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import inclineArrowIcon from '../../assets/icons/project-icons/InclineArrowIcon.svg';
import declineArrowIcon from '../../assets/icons/project-icons/DeclineArrowIcon.svg';

export enum DirectionEnum {
  Up = 'up',
  Down = 'down'
}

export interface PointerChipProps {
  direction: DirectionEnum;
  color: string;
  text?: string;
}

const PointerChip = ({
  direction, color, text,
}: PointerChipProps) => {
  const style = {
    background: direction === DirectionEnum.Up ? '#F5FED4' : '#FFF2F4',
    p: '8px',
    minWidth: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '3px',
    alignItems: 'center',
    gap: '4px',
  };

  return (
    <Box component="div" sx={style}>
      {text && (
      <Typography variant="heading5" color={direction === DirectionEnum.Up ? 'secondaryGreen.700' : 'secondaryPink.700'}>
        {text}
      </Typography>
      )}
      <Box
        component="img"
        src={direction === DirectionEnum.Up ? inclineArrowIcon : declineArrowIcon}
      />
    </Box>
  );
};

export default PointerChip;
