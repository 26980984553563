import { useEffect, useState } from 'react';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import usePagination from '../pagination/usePagination';
import useGetQueries from '../../utils/hooks/crud-hooks/useGetQueries';

type ChipFilterQueryParams = {
  title: string;
  search?: Record<string, string>;
};

export type ChipFilter = {
  label: string;
  isLoading: boolean;
  count: number | undefined;
};

type UseTableHookProps = {
  resourceUrl: string;
  resourceLimit?: number;
  queryParams?: string;
} & (WithTabsProps | WithoutTabsProps); // discriminated unions

type WithTabsProps = {
  withTabs: true; // discriminator
  chipFilterQueryParams: ChipFilterQueryParams[];
};

type WithoutTabsProps = {
  withTabs?: false;
};

const useTable = <T>({
  resourceUrl,
  resourceLimit = 30,
  queryParams,
  ...rest
}: UseTableHookProps) => {
  const { ...paginationProps } = usePagination();
  const [chipFilterQueryParamsCounts, setChipFilterQueryParamsCounts] = useState<
    (number | undefined)[]
  >([]);

  const [queryOptions, setQueryOptions] = useState('');
  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [activeChip, setActiveChip] = useState<number>(0);

  const limit = `limit=${resourceLimit}`;
  const offset = `offset=${paginationProps.offset}`;
  const queryParamsCondition = queryParams ? `&${queryParams}` : '';
  const queryOptionsCondition = queryOptions !== '' ? `&${queryOptions}` : '';

  const endpoint = `${resourceUrl}?${limit}&${offset}${queryParamsCondition}${queryOptionsCondition}`;

  const arrayOfEndpoints = rest.withTabs
    ? rest.chipFilterQueryParams.map(
        (item) =>
          `${resourceUrl}?${new URLSearchParams(
            item.search,
          ).toString()}&${limit}&${offset}${queryParamsCondition}${queryOptionsCondition}`,
      )
    : [];

  const { data: results, isLoading } = useGetMany<T[]>({
    resource: rest.withTabs ? arrayOfEndpoints[activeChip] : endpoint,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  // if withTabs, fetch this query only once
  // because it is only needed to get the count for each chipFilterQueryParam
  const arrayOfResults = useGetQueries({
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    endpoints: rest.withTabs ? arrayOfEndpoints : [],
    enabled: paginationProps.offset === 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const totalRowsCt = results?.data.count;
  const rows = results?.data.results ?? [];

  const arrayOfCounts = arrayOfResults.map((res) => res.data?.data.count);

  const chipFilters: { label: string; isLoading: boolean; count: number | undefined }[] =
    rest.withTabs
      ? rest.chipFilterQueryParams.map((item, index: number) => ({
          label: item.title,
          isLoading: arrayOfResults[index].isLoading,
          // not using arrayOfCounts here because we do useGetQueries only once
          // on initial render; instead storing the array of counts for each
          // chipFilterQueryParams in state which is not changing between re-renders
          // (when for example updating the offset state)
          count: chipFilterQueryParamsCounts[index], // not using arrayOfCounts
        }))
      : [];

  useEffect(() => {
    setTotalRowsCount((prevTotalRowsCount) =>
      totalRowsCt !== undefined ? totalRowsCt : prevTotalRowsCount,
    );
  }, [totalRowsCt]);

  // keep the initial chipFilterQueryParamsCounts, i.e. do not
  // re-set it; only gets called on initialRender; after that
  // chipFilterQueryParamsCounts never gets updated
  // TODO: refactor; get rid of useEffect
  useEffect(() => {
    if (rest.withTabs) {
      if (chipFilterQueryParamsCounts.length === 0) {
        if (arrayOfCounts.every((item) => item !== undefined)) {
          setChipFilterQueryParamsCounts(arrayOfCounts);
        } else if (arrayOfCounts.some((item) => item !== undefined)) {
          setChipFilterQueryParamsCounts((prevTest) => prevTest);
        }
      }
    }
  }, [chipFilterQueryParamsCounts, arrayOfCounts]);

  useEffect(() => {
    if (rest.withTabs) {
      paginationProps.setOffset(0);
      paginationProps.setCurrentPage(1);
    }
  }, [activeChip]);

  return {
    loading: isLoading,
    rows,
    totalRowsCount,
    activeChip,
    setActiveChip,
    ...(rest.withTabs && { chipFilters }),
    ...paginationProps,
    resourceLimit,
    setQueryOptions,
  };
};

export default useTable;
