import create from 'zustand';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';

interface ChatState {
  selectedAttachment: AttachmentDto | null;
  selectAttachment: (attachment: AttachmentDto | null) => void;
}

export const useChatStore = create<ChatState>((set) => ({
  selectedAttachment: null,
  selectAttachment: (attachment) => set({ selectedAttachment: attachment }),
}));
