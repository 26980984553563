/* eslint-disable no-use-before-define */
import { Box } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import MultiStep from '../../../components/multisteps/Multistep';
import useMultiStep from '../../../components/multisteps/useMultiStep';
import { getBase64, printHelperTextForSpecificCurrentStep } from '../../../utils/helpers';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import ContentStep from '../../Dashboard/CaseStudies/CaseStudiesCreate/ContentStep';
import DetailsStep from '../../Dashboard/CaseStudies/CaseStudiesCreate/DetailsStep';
import GeneralStep from '../../Dashboard/CaseStudies/CaseStudiesCreate/GeneralStep';
import PreviewStep from '../../Dashboard/CaseStudies/CaseStudiesCreate/PreviewStep';
import RightColumnRenderComponent from '../../Dashboard/CaseStudies/CaseStudiesCreate/RightColumnRenderComponent';
import CaseStudiesCreateModal from '../../Dashboard/CaseStudies/CaseStudiesCreate/components/CaseStudiesCreateModal';
import SelectSellerStep from './components/SelectSellerStep';
import { EnumCaseStudyStatus } from 'tdc-web-backend/enums/enums';

const initialState = {
  seller: '',
  file: undefined,
  fileName: '',
  title: '',
  description: {
    goals: '',
    challenges: '',
    solutions: '',
    results: '',
  },
  details: {
    services: [],
    languages: [],
    industries: [],
    projectSize: '',
    platforms: [],
    tools: [],
    geographyFocus: [],
  },
};

const labels = ['User', 'General', 'Content', 'Details', 'Preview'];

const helperTexts = [
  'The maximum character count for your case study title is 100 characters. The suggested dimensions for your featured image are 1920x900 pixels.',
  'Create and format the main body of your case study here. You can attach links and add visual media such as photos or videos to each subsection.',
  '',
  '',
];

const CaseStudiesAdminCreate = () => {
  const { currentStep, steps, onStepChange, form } = useMultiStep({
    labels,
    initialState,
  });
  const { caseStudyId } = useParams();
  const { mutate: create, isLoading: isCreateLoading } = useCreate({
    resource: 'case-studies/admin',
  });
  const { mutate: update, isLoading: isUpdateLoading } = useUpdate({ resource: 'case-studies' });
  const { data: caseStudy } = useReferenceOne<BaseCaseStudy>({
    id: caseStudyId,
    resource: 'case-studies',
    enabled: !!caseStudyId,
  });

  const { data: studyData } = useReferenceOne<CaseStudyContentDto>({
    id: caseStudy?.edit ? caseStudy?.edit : caseStudy?.content,
    resource: 'case-studies-content',
    enabled: !!caseStudy,
  });
  const [openModal, setIsOpenModal] = useState(false);
  const [openEditModal, setIsOpenEditModal] = useState(false);
  const [openDraftModal, setIsOpenDraftModal] = useState(false);
  const [openErrorModal, setIsOpenErrorModal] = useState(false);
  const [openCancelModal, setIsOpenCancelModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const navigate = useNavigate();

  const onSubmit = async (data: FieldValues, isDraft?: boolean) => {
    const {
      file,
      fileName,
      details: { projectSize, ...details },
      ...rest
    } = data;
    const base64 = await (file ? getBase64(file) : null);

    const formData = {
      ...rest,
      details: {
        ...details,
        ...(projectSize !== '' && { projectSize }),
      },
      ...(form.getFieldState('file').isDirty && file && { file: base64, fileName }),
      requestPublish: !isDraft,
    };

    const eventHandlers = {
      onSuccess: () => {
        navigate('../');
        if (isDraft) {
          setIsOpenDraftModal(false);
          navigate('../');
        } else if (caseStudyId && caseStudy?.status !== EnumCaseStudyStatus.Draft) {
          setIsOpenEditModal(true);
        } else {
          setIsOpenModal(true);
        }
      },
      onError: (err: any) => {
        setErrorMsg(err.response?.data.message);
        if (isDraft) {
          setIsOpenDraftModal(false);
        }
        setIsOpenErrorModal(true);
      },
    };

    if (caseStudyId) {
      update({ data: formData, id: caseStudyId, suffix: 'admin' }, eventHandlers);
    } else {
      create(formData, eventHandlers);
    }
  };

  useEffect(() => {
    const resetForm = async () => {
      if (studyData && caseStudyId) {
        let file;
        const response = await fetch(studyData.file);
        const blob = await response.blob();

        if (studyData.file) {
          file = new File([blob], studyData.fileName);
        }
        form.reset({
          ...studyData,
          // added reset to seller field for the first input on the first step
          seller: studyData.seller.id,
          file,
        });
        form.trigger();
      }
    };
    resetForm();
  }, [studyData]);

  return (
    <Box sx={{ width: currentStep === 4 ? '100%' : '70%' }}>
      <MultiStep
        form={form}
        steps={steps}
        currentStep={currentStep}
        onChange={onStepChange}
        onSubmit={(data) => onSubmit(data, false)}
        onSaveAsDraft={() => setIsOpenDraftModal(true)}
        layoutOrientation={currentStep === 4 ? 'row' : 'column'}
        onCancelClick={() => setIsOpenCancelModal(true)}
        helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
        rightColumnRenderProp={() => <RightColumnRenderComponent form={form} />}
        isSubmitLoading={isCreateLoading || isUpdateLoading}
      >
        <>
          {currentStep === 1 && <SelectSellerStep />}
          {currentStep === 2 && <GeneralStep />}
          {currentStep === 3 && <ContentStep />}
          {currentStep === 4 && <DetailsStep />}
          {currentStep === 5 && <PreviewStep />}
        </>
      </MultiStep>
      <CaseStudiesCreateModal type="create" isOpen={openModal} setIsOpen={setIsOpenModal} />
      <CaseStudiesCreateModal type="edit" isOpen={openEditModal} setIsOpen={setIsOpenEditModal} />
      <CaseStudiesCreateModal
        type="draft"
        isOpen={openDraftModal}
        setIsOpen={setIsOpenDraftModal}
        onConfirm={() => {
          onSubmit(form.getValues(), true);
        }}
      />
      <CaseStudiesCreateModal
        type="error"
        isOpen={openErrorModal}
        setIsOpen={setIsOpenErrorModal}
        errorMessage={errorMsg}
      />
      <CaseStudiesCreateModal
        type="cancel"
        isOpen={openCancelModal}
        setIsOpen={setIsOpenCancelModal}
      />
    </Box>
  );
};

export default CaseStudiesAdminCreate;
