import { Divider, Stack, Typography } from '@mui/material';
import CustomInput from '../../../../components/custom-input/CustomInput';

const Participant = ({ isLoading }: { isLoading: boolean }) => (
  <Stack sx={{ pl: '24px' }}>
    <Typography variant="heading5" color="primaryDark.500">
      Project partner information
    </Typography>
    <Divider sx={{ mt: '0.75rem', mb: '1rem' }} />

    <Stack
      direction="row"
      justifyContent="space-between"
      gap={2}
      sx={{ pr: '20px' }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Stack sx={{ width: '50%' }}>
        <CustomInput
          input={<CustomInput.Text />}
          name="participantCompanyName"
          type="text"
          placeholder="Enter company name"
          label="* Company name"
          required
          sx={{ paddingBottom: '1.25rem' }}
          disabled={isLoading}
        />

        <CustomInput
          input={<CustomInput.Text />}
          name="participantCompanyUrl"
          type="text"
          placeholder="Enter url"
          label="Company website"
          sx={{ paddingBottom: '1.25rem' }}
          disabled={isLoading}
        />
      </Stack>

      <Stack direction="column" sx={{ width: '50%' }}>
        <CustomInput
          input={<CustomInput.Text />}
          name="contactName"
          type="text"
          placeholder="Enter name"
          label="Contact name"
          sx={{ paddingBottom: '1.25rem' }}
          disabled={isLoading}
        />

        <CustomInput
          input={<CustomInput.Text />}
          name="contactEmail"
          type="text"
          placeholder="Enter e-mail"
          label="Personal business email"
          sx={{ paddingBottom: '1.25rem' }}
          disabled={isLoading}
        />
      </Stack>
    </Stack>
  </Stack>
);

export default Participant;
