import { IconButton, Stack, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ContractDto,
  CreateContractDto,
  UpdateContractDto,
} from 'tdc-web-backend/contracts/schemas';
import { EnumProjectContractStatus } from 'tdc-web-backend/enums/enums';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { ArrowBack, Launch } from '@mui/icons-material';
import { addMonths } from 'date-fns';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import ContractLockType from './ContractLockType';
import ContractDefaultValues, { jurisdictionInfo } from './ContractDefaultValues';
import ContractStepper from '../Sidebar/ContractStepper/ContractStepper';
import ContractCreateModal from './modals/ContractCreateModal';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import ContractSignModal from './modals/ContractSignModal';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import ContractUpdateModal from './modals/ContractUpdateModal';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';
import CompanyInformationForm from './form-sections/CompanyInformationForm';
import ScopeOfWorkForm from './form-sections/ScopeOfWorkForm';
import MilestonesForm from './form-sections/MilestonesForm';
import MandatoryLegalForm from './form-sections/MandatoryLegalForm';
import JurisdictionForm from './form-sections/JurisdictionForm';
import SignaturesForm from './form-sections/SignaturesForm';
import ContractEditorFormWrapper from '../../../components/custom-inputs/ContractFormWrapper';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import CustomButton from '../../../components/button/CustomButton';
import theme from '../../../theme';
import { simpleDateFormatter } from '../../../utils/helpers';
import CustomTooltip from '../../../components/tooltip/CustomTooltip';
import { primaryDark } from '../../../color';
import useGetScrollIndex from '../../../utils/hooks/useGetScrollIndex';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';

export interface ContractEditorProps {
  sx?: SxProps<Theme> | undefined;
  lockType?: ContractLockType;
}

type DisplayFieldProps = {
  initialAmount: number;
  expectedDuration: Date;
  description: string;
} & CreateContractDto;

type ContractEditorFormSection = {
  title: string;
  subtitle: string;
  content: JSX.Element;
  fieldNames?: string[];
  tooltipText?: string;
};

const ContractEditor = ({ sx }: ContractEditorProps) => {
  const companyRoles = useDetermineCompanyRoles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenSign, setIsOpenSign] = useState<boolean>(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState<boolean>(false);
  const [legalRead, setLegalRead] = useState<boolean>(false);
  const navigate = useNavigate();

  const userType = companyRoles?.[0];

  const buyerType = 'buyer';
  const sellerType = 'seller_service' || 'seller_tool';

  const { contractId, projectId } = useParams();

  const { data: projectResponse } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: projectId,
    enabled: !!projectId,
  });

  const formMethods = useForm<DisplayFieldProps | any>({
    defaultValues: ContractDefaultValues,
    mode: 'all',
  });

  const { setValue, formState, reset, getValues, control, getFieldState, trigger } = formMethods;

  const budgetSum = (milestones: any) =>
    milestones.map((a: any) => a.budget).reduce((a: number, b: number) => a + b);

  const { data } = useReferenceOne<ContractDto>({
    resource: 'contracts',
    id: contractId,
    onSuccess: (data) => {
      const milestones = data?.[0].body.milestones;
      const lastIndex = milestones.length - 1;

      reset(
        {
          ...data?.[0],
          initialAmount: budgetSum(milestones),
          body: {
            ...data?.[0].body,
            projectDurationFrom: simpleDateFormatter(milestones[0].start),
            projectDurationTo: milestones[lastIndex].recurring
              ? simpleDateFormatter(
                  addMonths(
                    new Date(milestones[lastIndex].start),
                    milestones[lastIndex].duration ?? 0,
                  ),
                )
              : simpleDateFormatter(milestones[lastIndex].end),
          },
        },
        { keepTouched: true },
      );
      trigger(
        Array.prototype.concat.apply(
          [],
          formSections.map((section) => section.fieldNames),
        ),
      );
    },
    enabled: contractId !== undefined,
    refetchOnWindowFocus: false,
  });

  const { mutate: create, isLoading } = useCreate<ContractDto, CreateContractDto>({
    resource: '/contracts',
  });

  const { mutate: update, isLoading: isLoadingUpdate } = useUpdate<ContractDto, UpdateContractDto>({
    resource: '/contracts',
  });

  const buyerAccepted = useWatch({
    control,
    name: 'buyerApproved',
  });

  const sellerAccepted = useWatch({
    control,
    name: 'sellerApproved',
  });

  const isLoadingState = isLoading || isLoadingUpdate;

  useEffect(() => {
    if (formState.dirtyFields.body) {
      buyerAccepted && userType === sellerType && setValue('buyerApproved', false);
      sellerAccepted && userType === buyerType && setValue('sellerApproved', false);
    }
  }, [formState]);

  const formRefs = useRef<HTMLElement[]>([]);

  const activeScrollIdx = useGetScrollIndex(formRefs.current, 600);

  useEffect(() => {
    if (activeScrollIdx >= 3) {
      setLegalRead(true);
    }
  }, [activeScrollIdx]);

  const handleSubmitCreate = () => {
    const data = getValues();
    contractId
      ? update(
          {
            data,
            id: contractId,
          },
          {
            onSuccess: (data) => {
              buyerAccepted && sellerAccepted && formState.dirtyFields.body === undefined
                ? setIsOpenSign(false)
                : setIsOpenUpdate(false);
              navigate(`../project/${projectId}`);
            },
          },
        )
      : create(
          {
            ...data,
            project: projectId as string,
          },
          {
            onSuccess: (data) => {
              setIsOpen(false);
              navigate(`../project/${projectId}`);
            },
          },
        );
  };

  const splitNameForIndex = () => {
    const arrayOfStrings = data?.name.split(' ');
    const annexNumber = arrayOfStrings?.[1];
    return `Annex ${parseInt(annexNumber as string) + 1}`;
  };

  const getFormTitle = () => {
    const name = data?.name;
    const status = data?.status;
    if (
      name === 'Contract' &&
      (status === EnumProjectContractStatus.Active || status === EnumProjectContractStatus.Accepted)
    ) {
      return 'Annex 1';
    }
    if (name !== 'Contract' && status === EnumProjectContractStatus.Accepted) {
      return splitNameForIndex();
    }
    return name;
  };

  const isAnnex = getFormTitle()?.includes('Annex') ?? false;

  const formSections: ContractEditorFormSection[] = [
    {
      title: 'Company details',
      subtitle: 'information relating to the parties involved in the upcoming project.',
      content: <CompanyInformationForm isAnnex={isAnnex} userType={userType} />,
      fieldNames: ['body.buyer', 'body.seller'],
    },
    {
      title: 'Project overview',
      subtitle: 'A SUMMARY OF KEY PROJECT DETAILS including GOALS, TIMELINE AND BUDGET.',
      content: <ScopeOfWorkForm />,
      fieldNames: ['body.scope'],
    },
    {
      title: 'Scope of work',
      subtitle: 'MILESTONES MARKING SPECIFIC PHASES OF WORK ALONG THE PROJECT TIMELINE.',
      content: (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MilestonesForm />
        </LocalizationProvider>
      ),
      fieldNames: ['body.milestones'],
    },
    {
      title: 'Legal',
      subtitle:
        'This section of the contract contains predetermined provisions. By signing this contract, you are accepting these terms.',
      content: <MandatoryLegalForm />,
    },
    {
      title: 'Jurisdiction',
      subtitle:
        'this contract and any dispute or claim arising out or in connection with it shall be governed by the laws and subject to the exclusive jurisdiction of the competent courts of:',
      content: <JurisdictionForm isAnnex={isAnnex} />,
      fieldNames: ['body.jurisdiction'],
      tooltipText: jurisdictionInfo,
    },
    {
      title: 'Signatures',
      subtitle:
        'this contract be approved and signed when all the conditions have been confirmed and approved by both parties.',
      content: (
        <SignaturesForm
          userType={userType}
          seller={projectResponse?.seller}
          buyer={projectResponse?.buyer}
        />
      ),
      fieldNames: ['buyerApproved', 'sellerApproved'],
    },
  ];

  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    trigger(
      Array.prototype.concat.apply(
        [],
        formSections.map((section) => section.fieldNames),
      ),
    );
  }, []);

  const SubmitButton = () => (
    <>
      {buyerAccepted &&
      sellerAccepted &&
      formState.dirtyFields.body === undefined &&
      data?.status !== EnumProjectContractStatus.Accepted ? (
        <CustomButton
          onClick={() => setIsOpenSign(true)}
          variant="primary"
          fullWidth
          loading={isLoadingState}
          endIcon={<PencilIcon fill="#fff" />}
        >
          Sign
        </CustomButton>
      ) : (
        <CustomButton
          variant="primary"
          disabled={!formState.isValid || !legalRead}
          fullWidth
          onClick={() => {
            contractId ? setIsOpenUpdate(true) : setIsOpen(true);
          }}
          endIcon={<Launch />}
          loading={isLoadingState}
        >
          Submit
        </CustomButton>
      )}
    </>
  );

  return (
    <Stack sx={{ ...sx, position: 'relative' }} spacing={15} direction="row">
      <Stack direction="column" spacing={3} width="100%">
        <Stack direction="row" sx={{ alignItems: 'center', pl: 3, pt: 3 }}>
          <IconButton
            sx={{
              border: '1px solid',
              borderColor: 'primaryDark.300',
              mr: 1.5,
              width: 45,
              height: 45,
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack sx={{ height: '0.75em' }} />
          </IconButton>
          <Typography variant="heading3" color="primaryDark.300">
            back
          </Typography>
        </Stack>
        <Stack direction="row" spacing={8} sx={{ justifyContent: 'space-between' }}>
          {/* Companies information */}
          <FormProvider {...formMethods}>
            <form>
              <Stack spacing={2}>
                {formSections.map(({ title, subtitle, content, tooltipText }, i) => (
                  <Stack ref={(element) => ((formRefs.current[i] as any) = element)}>
                    <ContractEditorFormWrapper
                      title={title}
                      subtitle={subtitle}
                      titleAdornment={
                        tooltipText ? (
                          <CustomTooltip title={tooltipText}>
                            <InfoIcon fill={primaryDark[500]} />
                          </CustomTooltip>
                        ) : null
                      }
                    >
                      {content}
                    </ContractEditorFormWrapper>
                  </Stack>
                ))}
                <Stack
                  direction="row"
                  sx={{
                    alignSelf: 'end',
                    pt: 3,
                  }}
                />
                <ContractCreateModal
                  handleSubmitCreate={handleSubmitCreate}
                  isOpen={isOpen}
                  isLoading={isLoadingState}
                  setIsOpen={setIsOpen}
                />
                <ContractSignModal
                  handleSubmitCreate={handleSubmitCreate}
                  isOpen={isOpenSign}
                  isLoading={isLoadingState}
                  setIsOpen={setIsOpenSign}
                />
                <ContractUpdateModal
                  isAnnex={isAnnex}
                  handleSubmitCreate={handleSubmitCreate}
                  isOpen={isOpenUpdate}
                  isLoading={isLoadingState}
                  setIsOpen={setIsOpenUpdate}
                />
                <Stack width="160px" alignSelf="end">
                  {isLessThanLarge && <SubmitButton />}
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
      {!isLessThanLarge && (
        <Stack
          sx={{
            p: 1,
            borderRadius: 0.875,
            bgcolor: 'transparent',
            border: 'none',
            position: 'fixed',
            left: '82%',
            width: '20%',
            ml: '0 !important',
          }}
        >
          <ContractStepper
            onClickStep={(i) => {
              formRefs.current[i].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }}
            stepperValue={formSections.map((section, i) => ({
              label: section.title,
              completed:
                i == 3
                  ? legalRead
                  : !section.fieldNames?.find((name) => getFieldState(name).invalid),
              active: activeScrollIdx === i,
            }))}
          />
          <Stack
            sx={{
              marginTop: 3.5,
              width: 160,
            }}
          >
            <SubmitButton />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ContractEditor;
