import {
  Button as MuiButton, ButtonProps, SxProps, Theme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface ButtonPropsI {
  setUpperCase?: boolean,
  setBorderRadius?: boolean,
  fontWeight?: string,
  linkTo?: string | null,
  minWidth?: string | number,
  // width?: string | number,
  onButtonClick: (linkTo?: string | null) => void;
  target?: any;
  href?: string,
  disabled?: boolean,
  color?: ButtonProps['color'] | string;
  sx?: SxProps<Theme> | undefined,
}

// TODO: refactor
const Button = ({
  variant,
  size,
  color,
  children,
  setUpperCase,
  setBorderRadius,
  fontWeight,
  linkTo,
  minWidth,
  // width,
  onButtonClick,
  target,
  href,
  startIcon,
  endIcon,
  disabled,
  sx,
  ...props
}: (Omit<ButtonProps, 'color'> & ButtonPropsI)) => {
  const navigate = useNavigate();

  return (
    <MuiButton
      variant={variant}
      size={size}
      // color={color}
      // eslint-disable-next-line no-nested-ternary
      onClick={() => (target
        ? target.scrollIntoView({ behavior: 'smooth', block: 'start' })
        : linkTo ? navigate(linkTo)
          : onButtonClick(linkTo))}
      href={href}
      startIcon={startIcon ?? null}
      endIcon={endIcon ?? null}
      disabled={disabled ?? false}
      sx={{
        textTransform: setUpperCase ? 'uppercase' : 'none',
        borderRadius: setBorderRadius ? 25 : 'none',
        fontWeight: fontWeight || 'normal',
        minWidth: minWidth ?? 64,
        borderWidth: variant === 'outlined' ? 2 : 1,
        '&:hover': {
          borderWidth: variant === 'outlined' ? 2 : 1,
        },
        // width: width ?? 64,
        color,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
