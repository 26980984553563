import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Stack } from '@mui/system';
import { Avatar } from '@mui/material';
import { ReactComponent as IconMedium } from '../../../../../../../assets/img/public-pages/medium-icon.svg';
import { ReactComponent as IconCodepen } from '../../../../../../../assets/img/public-pages/codepen-icon.svg';
import { ReactComponent as IconBehance } from '../../../../../../../assets/img/public-pages/behance-icon.svg';
import { ReactComponent as IconGithub } from '../../../../../../../assets/img/public-pages/github-icon.svg';
import { ReactComponent as IconDribbble } from '../../../../../../../assets/img/public-pages/dribbble-icon-seller-social.svg';
import { ReactComponent as IconFacebook } from '../../../../../../../assets/img/public-pages/facebook-icon-seller-social.svg';
import { ReactComponent as IconLinkedin } from '../../../../../../../assets/img/public-pages/linkedin-icon-seller-social.svg';
import { ReactComponent as IconTwitter } from '../../../../../../../assets/img/public-pages/x-icon-seller-social.svg';
import { ReactComponent as IconYoutube } from '../../../../../../../assets/img/public-pages/youtube-icon.svg';
import { ReactComponent as IconInsta } from '../../../../../../../assets/img/public-pages/instagram-icon-seller-social.svg';
import { Dispatch, SetStateAction } from 'react';
import CompanySocialMediaEditModal from '../edit/CompanySocialMediaEditModal';

export type SocialIconMapping = {
  [key: string]: React.ComponentType;
};

const socialIcons: SocialIconMapping = {
  medium: IconMedium,
  dribbble: IconDribbble,
  facebook: IconFacebook,
  github: IconGithub,
  instagram: IconInsta,
  linkedin: IconLinkedin,
  twitter: IconTwitter,
};

interface CompanySocialMediaProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanySocialMedia = ({ isEditModalOpen, setIsEditModalOpen }: CompanySocialMediaProps) => {
  const { companyData } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.socialMediaLinks &&
        Object.entries(companyData.socialMediaLinks).map(([platform, link]) => {
          if (!link) {
            return;
          }
          const IconComponent = socialIcons[platform.toLowerCase()];

          return (
            <a href={link as string} key={platform} target="_blank" rel="noopener noreferrer">
              {IconComponent && (
                <Avatar sx={{ width: '24px', height: '24px' }}>
                  <IconComponent />
                </Avatar>
              )}
            </a>
          );
        })}
      {companyData && isEditModalOpen && (
        <CompanySocialMediaEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          socialIcons={socialIcons}
        />
      )}
    </Stack>
  );
};

export default CompanySocialMedia;
