import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TabPanel } from '@mui/lab';
import { Typography } from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { useNavigate } from 'react-router-dom';
import { EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import { formatDateFns } from '../../../../../utils/helpers';
import CustomReferenceField from '../../../../../components/custom-fields/CustomReferenceField';
import CustomChip from '../../../../../components/custom-chip/CustomChip';

type TablePanelGrid = {
  value: string;
  rows: ProjectDto[];
  tabIndex: number;
  navigateRoute?: string;
};

const ProjectTablePanelDataGrid = ({ value, rows, tabIndex, navigateRoute }: TablePanelGrid) => {
  const navigate = useNavigate();
  return (
    <TabPanel
      sx={{
        overflow: 'hidden',
        bgcolor: 'white',
        p: 0,
        borderRadius: '16px',
        border: '1px solid #C5C7C3',
      }}
      value={value}
      tabIndex={tabIndex}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(e) => e.id}
        disableSelectionOnClick
        onRowClick={(params) => navigate(`${navigateRoute ?? ''}${params.id}` ?? `./${params.id}`)}
        hideFooter
        sx={dataGridStyles}
      />
    </TabPanel>
  );
};

export default ProjectTablePanelDataGrid;

export const mapTempTaskStatus = new Map([
  [EnumProjectStatus.Completed, 'Completed'],
  [EnumProjectStatus.InProgress, 'In Progress'],
  [EnumProjectStatus.Pending, 'Pending'],
  [EnumProjectStatus.Canceled, 'Canceled'],
]);
export const mapTempTaskStatusColors = new Map([
  [EnumProjectStatus.Completed, '#D1F6D0'],
  [EnumProjectStatus.InProgress, '#DFE0FF'],
  [EnumProjectStatus.Pending, '#FFB4A9'],
  [EnumProjectStatus.Canceled, '#F8EBCB'],
]);

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography
        sx={{
          fontSize: '0.875rem',
          fontWeight: 600,
        }}
      >
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <CustomChip label={mapTempTaskStatus.get(params.value)} />
    ),
  },
  {
    field: 'start',
    headerName: 'Start date',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{ fontSize: '0.8rem' }}>{formatDateFns(params.value)}</Typography>
    ),
  },
  {
    field: 'end',
    headerName: 'End date',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{ fontSize: '0.8rem' }}>{formatDateFns(params.value)}</Typography>
    ),
  },
  {
    field: 'buyer',
    headerName: 'Buyer',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <CustomReferenceField
        sx={{ fontSize: '0.8rem' }}
        resource="companies"
        id={params.value}
        displayName="name"
      />
    ),
  },
  {
    field: 'seller',
    headerName: 'Seller',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <CustomReferenceField
        sx={{ fontSize: '0.8rem' }}
        resource="companies"
        id={params.value}
        displayName="name"
      />
    ),
  },
];

const dataGridStyles = {
  height: '400px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  '.MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Poppins, sans-serif',
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#FBFDF8',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: '600',
  },
};
