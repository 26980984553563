import { CircularProgress, circularProgressClasses, Stack } from '@mui/material';
import { getHexFromTheme } from '../../utils/helpers';

interface LayeredCircularProgressProps {
  colorInner: string;
  colorOuter: string;
  valueInner: number,
  valueOuter: number;
}

const LayeredCircularProgress = ({
  colorInner, colorOuter, valueInner, valueOuter,
}: LayeredCircularProgressProps) => {
  const percentageUnpaid = (valueInner / (valueInner + valueOuter)) * 100;
  const large = {
    width: '8rem!important',
    height: '8rem!important',
    position: 'absolute',
  };

  const small = {
    width: '7.25rem!important',
    height: '7.25rem!important',
    padding: '.66rem',
    margin: '0.375rem',
  };

  const isUnpaidBigger = (inverse: boolean) => {
    if (valueInner === valueOuter) {
      return inverse ? large : small;
    }
    return inverse
      ? valueInner > valueOuter ? small : large
      : valueInner < valueOuter ? small : large;
  };

  return (
    <Stack sx={{
      position: 'relative',
      pt: 2,
      margin: 'auto',
      display: !(valueInner || valueOuter) ? 'none' : null,
    }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          filter: `drop-shadow(0px 6px 8px ${getHexFromTheme(colorOuter)}73)`,
          color: colorOuter,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...isUnpaidBigger(true),
        }}
        value={100 - percentageUnpaid}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        thickness={4}
        sx={{
          filter: `drop-shadow(0px 6px 8px ${getHexFromTheme(colorInner)}73)`,
          color: colorInner,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...isUnpaidBigger(false),
        }}
        value={percentageUnpaid}
      />
    </Stack>
  );
};

export default LayeredCircularProgress;
