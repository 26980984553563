import { Box } from '@mui/system';
import { IconButton, Stack, Typography } from '@mui/material';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { useNavigate } from 'react-router-dom';
import { FunctionComponent, SVGProps } from 'react';
import { timeSince } from '../../utils/helpers';
import getNotificationRoute from './getNotificationRoute';
import { useRefresh } from '../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../utils/hooks/crud-hooks/useUpdate';
import { secondaryBlue } from '../../color';

interface NotificationCardProps {
  data: NotificationDto
  Icon: FunctionComponent<SVGProps<SVGSVGElement> & {
    title?: string | undefined;
}>;
}

const NotificationCardLarge = ({ data, Icon } :NotificationCardProps) => {
  const navigate = useNavigate();
  const refresh = useRefresh();

  const { mutate: openNotification } = useUpdate<NotificationDto, unknown>({ resource: 'notifications' });

  const notificationRoute = getNotificationRoute(data);
  const handleOpenNofitication = () => {
    navigate(notificationRoute);
    openNotification({
      id: data.id as string,
      suffix: 'seen',
    }, {
      onSuccess: () => {
        refresh();
      },
    });
  };
  return (
    <>
      <Box
        sx={{
          p: 2,
          px: 3,
          backgroundColor: !data.seen ? 'secondaryBlue.50' : 'transparent',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            zIndex: 999,
            boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
          },
        }}
        onClick={handleOpenNofitication}
      >
        <Stack direction="row" spacing={1}>
          <IconButton
            sx={{
              borderRadius: '4px',
              bgcolor: 'secondaryBlue.100',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                bgcolor: 'secondaryBlue.100',
              },
            }}
          >
            <Icon fill={secondaryBlue[700]} />
          </IconButton>
          <Stack direction="column">
            <Typography
              flexWrap="wrap"
              color="primaryDark.600"
              variant="body2"
            >
              {data.short}
            </Typography>
            {/* FIXME: variant for cooperation company name on figma is not standardized! */}
            {/* hence font override is used here for now */}
            <Typography variant="body3" color="primaryDark.400" fontWeight="600">
              {timeSince(data.created)}
              {' '}
              ago
            </Typography>
          </Stack>
        </Stack>
        {!data.seen && (
        <Box
          sx={{
            backgroundColor: 'primaryLight.500',
            borderRadius: '50%',
            minWidth: '12px',
            minHeight: '12px',
            width: '12px',
            height: '12px',
            border: '2px solid',
            borderColor: 'primaryLight.100',
          }}
        />
        )}
      </Box>
    </>
  );
};

export default NotificationCardLarge;
