import { Stack } from '@mui/system';
import { EnumCountry, mapCountry } from 'tdc-web-backend/enums/enums';
import { Dispatch, SetStateAction } from 'react';
import CompanyTargetMarketsEditModal from '../edit/CompanyTargetMarketsEditModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyTargetMarketsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTargetMarkets = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTargetMarketsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.targetCountries?.map((market: EnumCountry) => (
        <CustomChip
          key={market}
          tooltipTitle={mapCountry.get(market)}
          label={
            market && truncate(mapCountry.get(market) as string, chipLabelTruncateValue as number)
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}

      {companyData && (
        <CompanyTargetMarketsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyTargetMarkets;
