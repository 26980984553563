import { BlogContentSchema } from 'tdc-web-backend/blogs/schemas';
import { Divider, Stack, Typography } from '@mui/material';
import BaseInputLabel from '../../../../../../components/custom-inputs/BaseInputLabel';
import Editor from '../../../../../../components/editor/Editor';
import { BlogEditorMenu } from '../../../../../../components/editor/menu-option-lists/menu-item-lists';

type SectionsViewProps = {
  values?: BlogContentSchema[];
};

const SectionsView = ({ values }: SectionsViewProps) => (
  <Stack spacing={3} sx={{ padding: '0 1.5rem' }} divider={<Divider orientation="horizontal" />}>
    {values?.map((section, index) => (
      <Stack spacing={3} key={index}>
        <Stack>
          <BaseInputLabel text="Subtitle" />
          <Typography variant="body3" color="primaryDark.600" sx={{ lineHeight: '1.25rem', mt: 0 }}>
            {section.subtitle}
          </Typography>
        </Stack>
        <Stack>
          <BaseInputLabel text="Body" />
          <Editor items={BlogEditorMenu} content={section.body} isEditable={false} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export default SectionsView;
