import { Card, Stack, Typography } from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { EnumCurrency, PaymentStatus } from 'tdc-web-backend/enums/enums';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatCurrency } from '../../../../utils/helpers';

type PaymentDetailsProps = {
  projectData: ProjectDto;
  milestones: MilestoneDto[];
}

const ProjectPaymentDetailsCard = ({
  projectData,
  milestones,
}: PaymentDetailsProps) => {
  const paidPayments = milestones?.filter(
    (milestone) => milestone.paymentStatus === PaymentStatus.Paid,
  )
    .map((milestone) => milestone.budget);
  const unpaidPayments = milestones?.filter(
    (milestone) => milestone.paymentStatus !== PaymentStatus.Paid,
  )
    .map((milestone) => milestone.budget);

  const getPaymentAmount = (paymentsList: number[]) => (
    paymentsList?.length ? paymentsList?.reduce((a, b) => a + b) : 0
  );

  return (
    <Card
      elevation={0}
      sx={{
        pt: 3,
        px: 3,
        pb: 3,
        borderRadius: '8px',
        width: '100%',
      }}
      className="react-joyride-step-2-individual-payment"
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        spacing={2}
        width="100%"
        sx={{ p: 1 }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" sx={{ color: '#757875' }} fontWeight="600" fontSize="1rem">
            Project Status
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            <TaskStatus text={projectData?.status} fontSize="0.875rem !important" />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" sx={{ color: '#757875' }} fontWeight="600" fontSize="1rem">
            Project cost
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            {formatCurrency(projectData?.budget as number, projectData?.currency as EnumCurrency)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" sx={{ color: '#757875' }} fontWeight="600" fontSize="1rem">
            Currently paid
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            {
              getPaymentAmount(paidPayments) !== 0 ? (
                formatCurrency(
                  getPaymentAmount(paidPayments) as number, projectData?.currency as EnumCurrency,
                )
              ) : formatCurrency(0, EnumCurrency.Usd)
            }
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" sx={{ color: '#757875' }} fontWeight="600" fontSize="1rem">
            Remaining payments
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            {/* {formatCurrency(
              getPaymentAmount(unpaidPayments) as number, projectData?.currency as EnumCurrency,
            )} */}
            {
              getPaymentAmount(unpaidPayments) !== 0 ? (
                formatCurrency(
                  getPaymentAmount(unpaidPayments) as number, projectData?.currency as EnumCurrency,
                )
              ) : formatCurrency(0, EnumCurrency.Usd)
            }
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProjectPaymentDetailsCard;
