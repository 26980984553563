import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useParams } from 'react-router-dom';
import { TaskDto, UpdateTaskDto } from 'tdc-web-backend/tasks/schemas';
import Button from '../../../../components/button/Button';
import ControlledCheckboxInput from '../../../../components/custom-inputs/ControlledCheckboxInput';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import { TaskModalProps } from './TaskDeleteModal';
import LoadingButton from '../../../../components/button/LoadingButton';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

const TaskEditModal = ({ task }: TaskModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { taskId } = useParams();
  const { mutate: update } = useUpdate<TaskDto, UpdateTaskDto>({ resource: '/tasks' });
  const refresh = useRefresh();

  const formMethods = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset({ ...task });
  }, [isOpen]);

  const handleSubmitEdit = (data: FieldValues) => {
    update(
      { data, id: taskId! },
      {
        onSuccess: (data) => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Button
        onButtonClick={() => setIsOpen(true)}
        startIcon={<EditOutlinedIcon sx={{ fontSize: '1.45rem !important' }} />}
        fontWeight="500"
        setBorderRadius
        size="large"
        className="react-joyride-step-4-task"
        variant="outlined"
        color="secondary"
      >
        Edit
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSubmitEdit)}>
            <Stack
              sx={{
                width: '50%',
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Paper
                  sx={{
                    padding: '2em',
                  }}
                >
                  <Stack spacing={2}>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '1.25em',
                          fontWeight: 600,
                        }}
                      >
                        Edit task
                      </Typography>
                    </Stack>
                    <Stack spacing={3}>
                      <Stack direction="row" spacing={2}>
                        <ControlledCheckboxInput
                          name="status"
                          label=""
                          icon={<CheckCircleOutlineIcon sx={{ color: '#C5C7C3' }} />}
                          checkedIcon={<CheckCircleIcon sx={{ color: '#015732' }} />}
                        />
                        <ControlledTextInput name="name" />
                        <ControlledDateInput name="start" />
                        <ControlledDateInput name="end" />
                      </Stack>
                      <ControlledTextInput
                        name="description"
                        multiline
                        minRows={4}
                        label="Task description"
                        placeholder="Type"
                        variant="outlined"
                      />
                    </Stack>
                    <Divider />
                    <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                      <Button
                        variant="text"
                        onButtonClick={() => setIsOpen(false)}
                        sx={{
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        onButtonClick={() => {}}
                        type="submit"
                        sx={{
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Confirm
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Paper>
              </LocalizationProvider>
            </Stack>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
export default TaskEditModal;
