import { Typography, SxProps, Theme, useMediaQuery } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { useMemo } from 'react';
import { EnumCurrency, EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import useGetMany from '../../../../../../utils/hooks/crud-hooks/useGetMany';
import { useSellerHomescreenContext } from '../../SellerHomescreenContext';
import CircularProgress from './CircularProgress';
import { primaryLight } from '../../../../../../color';
import { truncate } from '../../../../../../utils/helpers';
import CustomNoRowsOverlay from '../../../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import CustomTooltip from '../../../../../../components/tooltip/CustomTooltip';
import theme from '../../../../../../theme';

type ModifiedProjectDto = {
  projectName: string;
  totalPaidUntilNow: number;
  totalBudget: number | null;
};

type PossibleNumbersOfCharts = 1 | 2 | 3;

const dotColors = [primaryLight['400'], primaryLight['500'], primaryLight['800']];

const accumulateMilestonesPaidAmount = (array: MilestoneDto[]) => {
  const sort = array?.reduce(
    (a, b) => {
      if (b.paid !== null) {
        a.paid += b.budget;
      }

      return a;
    },
    {
      paid: 0,
    },
  );

  return sort?.paid;
};

// this approach is autistic and I only have done it this way because
// we have underestimated the estimate once again and things should
// be done quickly
const circularProgressVariants = (
  numberOfLatestProjects: number,
  latestProjects: ModifiedProjectDto[],
) => [
  <CircularProgress
    thickness={3.9}
    value={
      latestProjects[0].totalBudget !== null
        ? (latestProjects[0].totalPaidUntilNow / latestProjects[0].totalBudget) * 100
        : 0
    }
    color={primaryLight[400]}
    position={numberOfLatestProjects === 1 ? 'relative' : 'absolute'}
    sx={{
      width: '9rem !important',
      height: '9rem !important',
      padding: '20px',
      top: 0,
    }}
  />,
  <CircularProgress
    value={
      latestProjects[1] !== undefined
        ? latestProjects[1].totalBudget !== null
          ? (latestProjects[1].totalPaidUntilNow / latestProjects[1].totalBudget) * 100
          : 0
        : 0
    }
    color={primaryLight[500]}
    sx={{
      width: '8.5rem !important',
      height: '8.5rem !important',
      margin: '0.25rem',
    }}
  />,
  <CircularProgress
    thickness={3.1}
    value={
      latestProjects[2] !== undefined
        ? latestProjects[2].totalBudget !== null
          ? (latestProjects[2].totalPaidUntilNow / latestProjects[2].totalBudget) * 100
          : 0
        : 0
    }
    color={primaryLight[800]}
    position={numberOfLatestProjects === 3 ? 'absolute' : 'relative'}
    sx={{
      width: '13rem !important',
      height: '13rem !important',
      padding: '20px',
      top: '-176px',
    }}
  />,
];

// this approach is autistic and I only have done it this way because
// we have underestimated the estimate once again and things should
// be done quickly
const drawCharts = (
  numberOfLatestProjects: PossibleNumbersOfCharts,
  latestProjects: ModifiedProjectDto[],
) => {
  switch (numberOfLatestProjects) {
    case 1:
      return circularProgressVariants(numberOfLatestProjects, latestProjects).slice(0, 1);
    case 2:
      return circularProgressVariants(numberOfLatestProjects, latestProjects).slice(0, 2);
    case 3:
      return circularProgressVariants(numberOfLatestProjects, latestProjects).slice(0, 3);
    default:
      return circularProgressVariants(numberOfLatestProjects, latestProjects).slice(0, 1);
  }
};

// could have looked into the performance more but running out of time
const EarningsChart = ({ sx }: { sx?: SxProps<Theme> }) => {
  const isBetweenLgAndGr = useMediaQuery(theme.breakpoints.between('lg', 'gr'));
  const NUMBER_OF_PROJECT_NAME_LETTERS_TO_DISPLAY = isBetweenLgAndGr ? 10 : 18;
  const { user } = useSellerHomescreenContext();

  const { data: projectsResponse } = useGetMany<ProjectDto>({
    resource: `/projects?company=${user?.membership?.company}`,
    enabled: !!user?.membership?.company,
  });

  const { data: milestonesResponse } = useGetMany<MilestoneDto>({
    resource: `/milestones?company=${user?.membership?.company}`,
    enabled: !!user?.membership?.company,
  });

  // useMemo is here to cache data that got fetched and therefore improve performance
  // and hence prevent re-calculating of threeLatestProjectsWithTotalPaidUntilNow() on every re-render
  //
  const projects = useMemo(() => projectsResponse?.data.results, [projectsResponse?.data.results]);
  const milestones = useMemo(
    () => milestonesResponse?.data.results,
    [milestonesResponse?.data.results],
  );

  const threeLatestProjectsWithTotalPaidUntilNow = (): ModifiedProjectDto[] => {
    // there cannot be a project without a milestone, so those 2 if checks should be good
    // otherwise, let me know
    if ((projects && projects.length === 0) || projects === undefined) return [];
    if ((milestones && milestones.length === 0) || milestones === undefined) return [];

    const threeLatestProjects = projects
      .filter(
        (project) =>
          project.status === EnumProjectStatus.InProgress ||
          project.status === EnumProjectStatus.Completed,
      )
      .sort((a, b) => new Date(b.start as Date).getTime() - new Date(a.start as Date).getTime())
      .slice(0, 3);

    if (threeLatestProjects === undefined) return [];

    const threeLatestProjectsWithTotalPaidUntilNow = threeLatestProjects.map(
      (latestProject: ProjectDto) => ({
        projectName: latestProject.name,
        // filter through milestones that belong to the latest 3 projects
        // and for each accumulate the paid amount
        totalPaidUntilNow:
          accumulateMilestonesPaidAmount(
            milestones.filter((milestone) => milestone.project === latestProject.id),
          ) ?? 0,
        totalBudget: latestProject.budget,
      }),
    );

    return threeLatestProjectsWithTotalPaidUntilNow;
  };

  return (
    <Stack
      direction="row"
      sx={{
        mt: 6,
        width: { md: '75%', lg: '100%' },
        overflow: 'visible',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <>
        {threeLatestProjectsWithTotalPaidUntilNow().length > 0 ? (
          <>
            {/* chart(s) */}
            <Stack
              sx={{
                position: 'relative',
                margin: 'auto',
              }}
            >
              {drawCharts(
                threeLatestProjectsWithTotalPaidUntilNow().length as PossibleNumbersOfCharts,
                threeLatestProjectsWithTotalPaidUntilNow(),
              )}

              {/* the total current amount */}
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={0.5}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <Typography
                  color="primaryDark.500"
                  variant="body3"
                  sx={{ maxWidth: '50%', textAlign: 'center' }}
                >
                  Total current
                </Typography>

                <Typography color="primaryDark.800" variant="bodySemiBold2" fontWeight="600">
                  {threeLatestProjectsWithTotalPaidUntilNow()
                    .reduce((acc, curr) => (acc += curr.totalPaidUntilNow), 0)
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: EnumCurrency.Usd,
                      notation: 'compact',
                      minimumFractionDigits: 1,
                    })}
                </Typography>
              </Stack>
            </Stack>

            {/* project(s) list */}
            <Stack spacing={1.5}>
              {threeLatestProjectsWithTotalPaidUntilNow().map(
                (project: ModifiedProjectDto, index: number) => (
                  // dot w/ project name
                  // and current paid amount
                  <Stack spacing={1} direction="row" alignItems="baseline" width="100%">
                    <Box
                      sx={{
                        borderRadius: '50%',
                        bgcolor: dotColors[index],
                        width: '10px',
                        height: '10px',
                      }}
                    />

                    <Stack>
                      {project.projectName.length <= NUMBER_OF_PROJECT_NAME_LETTERS_TO_DISPLAY ? (
                        <Typography color="primaryDark.500">{project.projectName}</Typography>
                      ) : (
                        <CustomTooltip placement="top" title={project.projectName}>
                          <Typography color="primaryDark.500">
                            {truncate(
                              project.projectName,
                              NUMBER_OF_PROJECT_NAME_LETTERS_TO_DISPLAY,
                            )}
                          </Typography>
                        </CustomTooltip>
                      )}

                      <Typography>
                        {project &&
                          'totalPaidUntilNow' in project &&
                          project.totalPaidUntilNow.toLocaleString('en-US', {
                            style: 'currency',
                            currency: EnumCurrency.Usd,
                            minimumFractionDigits: 2,
                          })}
                      </Typography>
                    </Stack>
                  </Stack>
                ),
              )}
            </Stack>
          </>
        ) : (
          <CustomNoRowsOverlay />
        )}
      </>
    </Stack>
  );
};

export default EarningsChart;
