import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import axios from '../../../api/axios';

export type GetOneProps<T> = {
  resource: string;
} & UseQueryOptions<unknown, unknown, AxiosResponse<T>>

const useGetOne = <T>({
  resource,
  ...props
}: GetOneProps<T>) => {
  const getOne = (): Promise<AxiosResponse<T>> => axios.get(resource);

  // TODO:implement useGetReferenceMany hook and remove need for /
  return useQuery<unknown, unknown, AxiosResponse<T>>({
    queryKey: [`${resource}`],
    queryFn: getOne,
    ...props,
  });
};

export default useGetOne;
