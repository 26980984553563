import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import axios from '../../../api/axios';

export type UpdateProps = {
  resource: string;
}

type MutateParams = {
  data: Request;
  id: string;
}

// removed extends unknown; eslint: Constraining the generic type `Response` to `unknown`
// does nothing and is unnecessary.
const useUpdate = <Response, Request>({ resource }: UpdateProps) => {
  const update = (
    id: string, data?: Request, suffix?: string,
  ): Promise<AxiosResponse<Response>> => {
    // additional checking has been added because there are scenarios in which
    // not every patch query will require data (body), and some of them
    // will need to have a suffix; for example milestones/id/flag, where flag is suffix

    // if suffix got passed
    if (suffix) {
      // if data got passed
      if (data) {
        return axios.patch(`${resource}/${id}/${suffix}`, data);
      }

      // if no data got passed
      return axios.patch(`${resource}/${id}/${suffix}`);
    }

    // if suffix was not passed
    if (data) {
      // if data got passed
      return axios.patch(`${resource}/${id}`, data);
    }

    // if no data got passed
    return axios.patch(`${resource}/${id}`);
  };

  return useMutation((
    variables: {
      id: string; data?: Request; suffix?: string
    },
  ) => update(variables.id, variables?.data, variables?.suffix));
};

export default useUpdate;
