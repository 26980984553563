import { SxProps, Theme, Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import LAYOUT_DEFAULTS from '../../../../layout/utils';

type Card = {
  title: string;
  content: string | JSX.Element;
  bgcolor: string;
  color: string;
  titleStyles?: SxProps<Theme>;
  sx?: SxProps<Theme>;
};

export interface HowMuchDoesItCostProps {
  title?: string;
  titleStyles?: SxProps<Theme>;
  firstCard: Card;
  secondCard: Card;
}

const Card = ({ title, content, bgcolor, color, titleStyles, sx }: Card) => (
  <Stack
    alignItems="center"
    spacing={3}
    sx={{
      width: { xs: '100%', lg: '50%' },
      p: 6,
      borderRadius: '12px',
      bgcolor,
      color,
      boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
      ...sx,
    }}
  >
    <Typography variant="publicHeading4" sx={{ textAlign: 'center', ...titleStyles }}>
      {title}
    </Typography>

    {typeof content === 'string' ? (
      <Typography variant="publicBody4Regular" textAlign="center">
        {content}
      </Typography>
    ) : (
      content
    )}
  </Stack>
);

const HowMuchDoesItCost = ({ title, firstCard, secondCard }: HowMuchDoesItCostProps) => (
  <Stack
    // width="100%"
    spacing={7}
    alignItems="center"
    justifyContent="center"
    sx={{ my: 10, mb: 16, ...LAYOUT_DEFAULTS }}
  >
    {title && (
      <Typography
        sx={{
          typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
          textAlign: { xs: 'center', lg: 'start' },
        }}
      >
        {title}
      </Typography>
    )}

    <Stack width="100%" direction={{ xs: 'column', lg: 'row' }}>
      {/* first card */}
      <Card {...firstCard} sx={{ borderRadius: '12px 0px 0px 12px', ...firstCard.sx }} />

      {/* second card */}
      <Card {...secondCard} sx={{ borderRadius: '0px 12px 12px 0px', ...secondCard.sx }} />
    </Stack>
  </Stack>
);

export default HowMuchDoesItCost;
