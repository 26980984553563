export const primaryLight = {
  50: '#F4F1FE',
  100: '#E3DBFC',
  200: '#C7B9FA',
  300: '#AA96F8',
  400: '#927BF7',
  500: '#6D51F6',
  600: '#513BD3',
  700: '#3A29B0',
  800: '#261B8D',
  900: '#181175',
};

export const primaryDark = {
  100: '#F8F9FC',
  150: '#F4F6FB',
  200: '#F6F6F6',
  250: '#E3E7F0',
  300: '#BFC6D7',
  400: '#9EA8C2',
  500: '#79829B',
  600: '#4F576E',
  700: '#424755',
  800: '#262F46',
  900: '#10141F',
};

export const secondaryGray = {
  100: '#FCFCFC',
  200: '#F6F6F6',
  300: '#DEDEDE',
  400: '#ABABAB',
  500: '#7C7C7C',
  600: '#4C4C4C',
  700: '#383838',
  800: '#232323',
  900: '#000000',
};

export const secondaryPink = {
  100: '#FFF2F4',
  200: '#FFACAC',
  500: '#FF6174',
  700: '#DF5364',
};

export const secondaryOrange = {
  50: '#FEF8F1',
  101: '#FBECD7',
  201: '#F7D6B0',
  301: '#F3BA88',
  401: '#EE9F6C',
  501: '#EA7440',
  601: '#C75530',
  701: '#A63C24',
  801: '#852719',
  901: '#6E1911',
};

export const secondaryGreen = {
  100: '#F5FED4',
  200: '#E8FCAB',
  301: '#D6F884',
  401: '#C4F26A',
  500: '#ABEB4D',
  601: '#8CC940',
  700: '#34A854',
  701: '#70A834',
  801: '#568728',
  901: '#43701F',
};

export const secondaryBlue = {
  50: '#F6F7FF',
  100: '#E5E8FF',
  101: '#D6FDFE',
  200: '#BEC6FF',
  201: '#AEF4FD',
  301: '#8AE5FC',
  401: '#70D2FA',
  500: '#8F9AFE',
  501: '#51B4F9',
  601: '#3D8CD5',
  700: '#4051EC',
  701: '#2C68B1',
  801: '#1C4A8E',
  901: '#123576',
};

export const secondaryYellow = {
  100: '#FEF9D8',
  200: '#FDF3B2',
  301: '#FBEB8E',
  401: '#FAE375',
  500: '#F8D653',
  601: '#D4B343',
  700: '#E3A900',
  701: '#B19134',
  801: '#8E7127',
  901: '#755A1E',
};

export const gradient = {
  1: `linear-gradient(225deg, ${secondaryBlue[100]} 0%, #FFEAE1 68.75%, #FFFBEA 100%)`,
  2: 'linear-gradient(180deg, #030012 17.71%, #6D51F6 100%)',
  3: `linear-gradient(180deg, ${secondaryOrange[501]} 17.71%, ${primaryLight[500]} 100%)`,
  4: `linear-gradient(180deg, #fff 17.71%, ${primaryLight[500]} 100%)`,
  5: `linear-gradient(180deg, ${secondaryYellow[500]} 28.13%, ${primaryLight[500]} 100%)`,
  6: `linear-gradient(180deg, ${secondaryBlue[501]} 17.71%, ${primaryLight[500]} 100%)`,
  7: `linear-gradient(180deg, ${secondaryGreen[500]} 17.71%, ${primaryLight[500]} 100%)`,
  8: `linear-gradient(180deg, ${secondaryYellow[100]} 28.13%, ${secondaryYellow[500]} 100%)`,
  9: `linear-gradient(180deg, ${secondaryBlue[50]} 17.71%, ${secondaryBlue[500]} 100%)`,
  10: `linear-gradient(180deg, ${secondaryGreen[100]} 17.71%, ${secondaryGreen[500]} 100%)`,
  11: `linear-gradient(180deg, ${secondaryPink[100]} 17.71%, ${secondaryPink[500]} 100%)`,
  12: `linear-gradient(180deg, ${secondaryBlue[101]} 17.71%, ${secondaryBlue[500]} 100%, ${secondaryBlue[500]})`,
};
