import { Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import CaseStudyFileUploadQueue from './CaseStudyFileUploadQueue';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import ControlledTitleInput from '../../../../components/custom-inputs/ControlledTitleInput';

const GeneralStep = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const handleFile = (acceptedFiles: File[], onChange: (...event: any[]) => void) => {
    const file = acceptedFiles?.[0];
    if (!file) return;
    onChange(file);
    setValue('fileName', file.name);
  };

  const file = useWatch({ control, name: 'file' });

  const hasTitleError = errors?.title;
  const hasFileError = errors?.file;

  return (
    <Stack direction="column" spacing={5}>
      <Stack>
        {/* TODO: make a CustomInput.Title component following the
        current implementation of inputs */}
        <ControlledTitleInput
          label="Case study title"
          name="title"
          placeholder="YOUR TITLE"
          required
          inputProps={{ maxLength: 100 }}
        />

        {hasTitleError && (
          <Typography
            color="secondaryPink.500"
            variant="body4"
            sx={{ alignSelf: 'start', display: 'block', margin: '12px 0' }}
          >
            This field is required
          </Typography>
        )}
      </Stack>

      <Stack direction="row" spacing={3}>
        <ControlledDropzoneFileInput
          name="file"
          required
          dropzoneText="Drag your file here or "
          dropzoneHighlightText="browse"
          onUpload={handleFile}
          sx={{
            minWidth: '50%',
          }}
          dropzoneProps={{
            multiple: false,
          }}
        />

        {file && <CaseStudyFileUploadQueue name="file" />}

        {hasFileError && (
          <Typography
            color="secondaryPink.500"
            variant="body4"
            sx={{ alignSelf: 'start', display: 'block', margin: '12px 0' }}
          >
            This field is required
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default GeneralStep;
