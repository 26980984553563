import { Avatar, Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { mapCountry, mapIndustry, mapProjectSize, mapService } from 'tdc-web-backend/enums/enums';
import { secondaryGray } from '../../../../color';
import { CaseStudySidebarDataProps } from '../interface';
import StarRating from '../../../../components/star-rating/StarRating';
import ChipGroupAccordion from './ChipGroupAccordion';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../enums';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomButton from '../../../../components/button/CustomButton';
import theme from '../../../../theme';

const CaseStudySidebar = ({
  data,
  score,
  scrollOnClick,
  setActiveTab,
}: CaseStudySidebarDataProps) => {
  const isLessThanSmall = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      {!isLessThanSmall && (
        <>
          <Avatar
            style={{ margin: '0 auto' }}
            alt="company logo"
            src={data?.seller?.avatar}
            sx={{ width: 76, height: 76 }}
          />
          <Typography textAlign="center" mt={2} mb={8} variant="publicBody2">
            {data?.seller?.name}
          </Typography>
        </>
      )}
      <Divider sx={{ borderColor: secondaryGray[200] }} />

      <Stack py={2.25} gap={2}>
        <Typography variant="heading3" color="primaryDark.500">
          Client review
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignContent="center">
          <Typography variant="publicBody5" color="primaryDark.600">
            Total score
          </Typography>
          <Typography
            variant="publicBody5"
            color="secondaryBlue.700"
            width="100px"
            alignContent="center"
          >
            {Math.round(score ?? 0)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="publicBody5" color="primaryDark.600">
            Execution
          </Typography>
          <Typography
            variant="publicBody5"
            color="primaryDark.600"
            width="100px"
            alignContent="center"
          >
            <StarRating rating={Math.round(data?.review?.score?.qualityScore ?? 0)} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="publicBody5" color="primaryDark.600">
            Budget
          </Typography>
          <Typography
            variant="publicBody5"
            color="primaryDark.600"
            width="100px"
            alignContent="center"
          >
            <StarRating rating={Math.round(data?.review?.score?.budgetScore ?? 0)} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="publicBody5" color="primaryDark.600">
            Timeframe
          </Typography>
          <Typography
            variant="publicBody5"
            color="primaryDark.600"
            width="100px"
            alignContent="center"
          >
            <StarRating rating={Math.round(data?.review?.score?.scheduleScore ?? 0)} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="publicBody5" color="primaryDark.600">
            Recommend to others
          </Typography>
          <Typography
            variant="publicBody5"
            color="primaryDark.600"
            width="100px"
            alignContent="center"
          >
            <StarRating rating={Math.round(data?.review?.score?.recommendationScore ?? 0)} />
          </Typography>
        </Stack>
        <Stack justifyContent="center" direction="row">
          <CustomButton
            variant="primaryText"
            sx={{ mt: 2 }}
            onClick={() => (isLessThanSmall ? setActiveTab('review') : scrollOnClick())}
          >
            Read full review
          </CustomButton>
        </Stack>
      </Stack>

      <Box>
        <Stack pb={3} pt={1.5}>
          <ChipGroupAccordion
            label="services"
            choices={mapService}
            values={data?.details?.services}
          />
        </Stack>

        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack py={3}>
          <ChipGroupAccordion
            label="tech languages"
            choices={mapTechLanguage}
            values={data?.details?.languages}
          />
        </Stack>
        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack py={3}>
          <ChipGroupAccordion
            label="tech tools"
            choices={mapTechTool}
            values={data?.details?.tools}
          />
        </Stack>

        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack py={3}>
          <ChipGroupAccordion
            label="Programming platforms"
            choices={mapTechPlatform}
            values={data?.details?.platforms}
          />
        </Stack>
        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack py={3}>
          <ChipGroupAccordion
            label="Industry focus"
            choices={mapIndustry}
            values={data?.details?.industries}
          />
        </Stack>
        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack py={3}>
          <ChipGroupAccordion
            label="Geography focus"
            choices={mapCountry}
            values={data?.details?.geographyFocus}
          />
        </Stack>
        <Divider sx={{ borderColor: secondaryGray[200] }} />

        <Stack pb={6.75} pt={3}>
          <BaseInputLabel text="Project size" color="primaryDark.900" />
          <Typography variant="heading3" color="primaryDark.900">
            {data?.details?.projectSize ? mapProjectSize.get(data?.details.projectSize) : '--'}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default CaseStudySidebar;
