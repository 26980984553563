import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQueries } from 'react-query';
import { Paginated } from 'tdc-web-backend/common/schemas';
import axios from '../../../api/axios';

type UseGetQueriesType<T> = {
  endpoints: T[];
} & UseQueryOptions<unknown, unknown, AxiosResponse<Paginated<T>>>;

const useGetQueries = <T>({ endpoints, ...props }: UseGetQueriesType<T>) => {
  const getMany = (resource: string): Promise<AxiosResponse<Paginated<T>>> => axios.get(resource);

  if (endpoints) {
    return useQueries(
      endpoints.map((endpoint) => ({
        queryKey: [endpoint],
        queryFn: () => getMany(endpoint as any),
        ...props,
      })),
    );
  }

  return [];
};

export default useGetQueries;
