import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { getPublicImageUrl } from '../../utils/helpers';
import { EnumPublicPagesImage } from '../../utils/enums/public-page-images';

const content: { value: string; title: string }[] = [
  {
    value: '9K+',
    title: 'Digital service sellers',
  },
  {
    value: '4K+',
    title: 'Businesses hiring digital service sellers',
  },
  {
    value: '133+',
    title: 'Countries where we operate',
  },
  {
    value: '40+',
    title: 'Digital services offered',
  },
];

interface SponaInNumbersProps {
  sx?: SxProps<Theme>;
  spacing?: number;
  breakWord?: boolean;
}

const SponaInNumbers = ({ sx, spacing = 8, breakWord = true }: SponaInNumbersProps) => (
  <Stack
    spacing={spacing}
    sx={{
      position: 'relative',
      backgroundImage: `url(${getPublicImageUrl(EnumPublicPagesImage.SponaInNumbers)})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '20%',
      mb: { xs: '2.5rem', md: '5.65rem', lg: '5.65rem' },
      p: '2rem',
      borderRadius: '20px',
      color: 'common.black',
      ...sx,
    }}
  >
    <Typography variant="publicHeading3">
      Spona in {breakWord && <br />}
      Numbers
    </Typography>

    <Stack
      direction="row"
      flexWrap="wrap"
      width={{ lg: '100%', xl: '70%' }}
      justifyContent="space-between"
      alignItems="start"
      gap="40px"
    >
      {content.map((item) => (
        <Stack
          spacing={2}
          sx={{
            width: { xs: '100%', sm: '45%', lg: '20%' },
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          <Typography variant="publicHeading4">{item.value}</Typography>

          <Typography width="100%" variant="publicBody5Regular">
            {item.title}
          </Typography>
        </Stack>
      ))}
    </Stack>
  </Stack>
);

export default SponaInNumbers;
