import { Typography, Stack } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { CaseStudyReviewDto } from 'tdc-web-backend/case-studies-review/schemas';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';
import { truncate } from '../../../utils/helpers';
import StarRating from '../../../components/star-rating/StarRating';
import useTable from '../../../components/table/useTable';
import BaseTable from '../../../components/table/BaseTable';

const CaseStudyReviews = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'reviewer',
      headerName: 'Reviewer',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => {
        const fullName = `${params.row.reviewer?.firstName} ${params.row.reviewer?.lastName}`;
        return (
          <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
            {fullName.length ? fullName : '---'}
          </Typography>
        );
      },
    },
    {
      field: 'caseStudyContent',
      headerName: 'Case study',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <CustomReferenceField
          resource="case-studies-content"
          id={params.value}
          displayName="title"
          renderComponent={(value: CaseStudyContentDto) => (
            <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
              {truncate(value.title, 30)}
            </Typography>
          )}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Overall score',
      flex: 0.15,
      renderCell: ({ row }: GridValueGetterParams) => {
        const { qualityScore, budgetScore, scheduleScore, recommendationScore } = row.score;
        const overallScore = qualityScore + budgetScore + scheduleScore + recommendationScore;
        return <StarRating rating={Math.round(overallScore / 4)} />;
      },
    },
    {
      field: 'adminApproved',
      headerName: 'Approved',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => (
        <StatusChipField
          text={
            params.value
              ? // Used to display yellow dot for not approved
                'approved'
              : // Used to display green dot for approved
                'pending'
          }
          componentReturnType="dot"
        />
      ),
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'case-studies-review',
  });

  return (
    <Stack direction="column" sx={{ height: 'fit-content', width: '100%' }} spacing={2} pt={4}>
      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        onRowClick={(params) => navigate(`./${params.id}`)}
        withElements={{
          withTitle: 'Case study reviews (tabs & filters will be added)',
        }}
        {...props}
      />
    </Stack>
  );
};

export default CaseStudyReviews;
