import {
  Box,
  Card,
  ClickAwayListener,
  IconButton,
  Stack,
  Typography,
  backdropClasses,
} from '@mui/material';
import { useState } from 'react';
import Joyride from 'react-joyride';
import Button from '../../../../components/button/Button';
import JoyrideTooltip from '../../../../utils/hooks/joyride-hooks/JoyrideTooltip';
import useJoyride from '../../../../utils/hooks/joyride-hooks/useJoyride';
import { ReactComponent as QuestionMarkIconRounded } from '../../../../assets/icons/layout-icons/QuestionMarkIconRounded.svg';

export interface FloatingHelpButton {
  iconWidth?: number | string;
  iconHeight?: number | string;
  elementsColor?: string;
  anchorElHoverColor?: string;
}

const FloatingHelpButton = ({
  iconWidth = 50,
  iconHeight = 50,
  elementsColor = 'grey.700',
  anchorElHoverColor = 'grey.600',
}: FloatingHelpButton) => {
  const HoverableLink = (url: string, label: string) => (
    <Button
      href={url}
      onButtonClick={() => {}}
      sx={{
        zIndex: 100,
        transition: '0.3s all ease',
        color: '#fff',
        borderRadius: '4px',
        '&:hover': {
          bgcolor: anchorElHoverColor,
        },
      }}
    >
      <Typography sx={{ px: 2, py: 1 }}>{label}</Typography>
    </Button>
  );

  const [showUserProfileMenu, setShowUserProfileMenu] = useState<boolean>(false);

  const { joyride, runJoyride, updateJoyride } = useJoyride();

  return (
    <>
      <Joyride
        steps={joyride.steps}
        run={joyride.run}
        scrollDuration={400}
        scrollToFirstStep
        showSkipButton
        disableScrolling={
          joyride.steps[joyride.stepIndex] && joyride.steps[joyride.stepIndex].disableScrolling
        }
        stepIndex={joyride.stepIndex}
        callback={(e) => updateJoyride(e)}
        continuous
        tooltipComponent={(props) => JoyrideTooltip(props)}
      />

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'relative', width: 'fit-content' }}
      >
        <ClickAwayListener onClickAway={() => setShowUserProfileMenu(false)}>
          <IconButton sx={{ p: 0 }}>
            <QuestionMarkIconRounded
              onClick={() => setShowUserProfileMenu((prev) => !prev)}
              fill="primaryDark.600"
              width={30}
              height={30}
              style={{ cursor: 'pointer' }}
            />
          </IconButton>
        </ClickAwayListener>

        {showUserProfileMenu && (
          <Card
            elevation={0}
            sx={{
              color: 'common.white',
              bgcolor: elementsColor,
              left: -2,
              bottom: 50,
              position: 'absolute',
              overflow: 'visible',
              zIndex: 200,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: -7,
                left: 7,
                transform: 'scaleY(-1)',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid',
                borderBottomColor: elementsColor,
              }}
            />

            <Box>
              <Stack>
                <Button
                  onButtonClick={() => {
                    setShowUserProfileMenu(false);
                    runJoyride();
                  }}
                  sx={{
                    zIndex: 100,
                    transition: '0.3s all ease',
                    color: '#fff',
                    borderRadius: '4px',
                    '&:hover': {
                      bgcolor: anchorElHoverColor,
                    },
                  }}
                >
                  <Typography sx={{ px: 2, py: 1 }}>Help</Typography>
                </Button>
              </Stack>
              <Stack>{HoverableLink('/contact-us', 'Contact support')}</Stack>
            </Box>
          </Card>
        )}
      </Stack>
    </>
  );
};

export default FloatingHelpButton;
