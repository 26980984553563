import axios from 'axios';

const HOST = process.env.REACT_APP_GATEWAY_URL;

export const instance = axios.create({
  baseURL: HOST,
  withCredentials: true,
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json',
  },
});

export default instance;

(() => {
  const AccessToken = localStorage.getItem('AccessToken');
  if (AccessToken) {
    instance.defaults.headers.common.Authorization = `Bearer ${AccessToken}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
})();
