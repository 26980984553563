import { Stack, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MultiSelectChips from '../../../../components/multi-select-chips/MultiSelectChips';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import { mapIndustry } from 'tdc-web-backend/enums/enums';
import useCreateArticleStore from '../useCreateArticleStore';

const StepThird = () => {
  const createArticleInput = useCreateArticleStore((state) => state.createArticleInput);
  const setCreateArticleInput = useCreateArticleStore((state) => state.setCreateArticleInput);
  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);

  const requiredMessage = 'This field is required';

  const {
    watch,
    control,
    formState: { isValid },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      industries: createArticleInput.industries ?? [],
    },
  });

  const handleRemoveIndustry = (index: number) => {
    const field = getValues('industries');
    field.splice(index, 1);
    setValue('industries', field);
  };

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setCreateArticleInput(watch()));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box component="form">
      <Stack spacing={2}>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Industry focus
          </Typography>
          <Typography variant="body1">Select any industries that apply to your article.</Typography>
        </Box>

        <MultiSelectChips
          enumMap={mapIndustry}
          watch={watch}
          control={control}
          valuesName="industries"
          isRequired
          requiredMessage={requiredMessage}
          backgroundColor="#fff"
          shouldHaveRemove
          remove={(index: number) => handleRemoveIndustry(index)}
        />
      </Stack>
    </Box>
  );
};

export default StepThird;
