import { createContext, useContext } from 'react';
import { CustomInputContextType } from './interface';

const CustomInputContext = createContext<CustomInputContextType | null>(null);

export const useCustomInputContext = () => {
  const context = useContext(CustomInputContext);

  if (!context) {
    throw new Error(
      'Custom input compound components (CustomInput.*) cannot be rendered outside the CustomInput component',
    );
  }

  return context;
};

export default CustomInputContext;
