import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ControlledRatingInput from '../../../../../components/custom-inputs/ControlledRatingInput';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import { FeedbackInputType } from '../../interface';
import { TimedProjectEditorMenu } from '../../../../../components/editor/menu-option-lists/menu-item-lists';
import ControlledTextEditor from '../../../../../components/custom-inputs/ControlledTextEditor';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';

const PitchFeedbackInput = ({
  title,
  description,
  textInputName,
  ratingInputName,
  showEditor = false,
}: FeedbackInputType) => {
  return (
    <Stack py={2} gap={1}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <BaseInputLabel text={title} />
        <ControlledRatingInput name={ratingInputName} required />
      </Stack>
      <Typography color="primaryDark.500" variant="body3">
        {description}
      </Typography>
      {showEditor ? (
        <ControlledTextEditor name={textInputName} items={TimedProjectEditorMenu} />
      ) : (
        <ControlledTextInput
          name={textInputName}
          placeholder="Provide additional information (optional)"
        />
      )}
    </Stack>
  );
};

export default PitchFeedbackInput;
