/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Box, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  mapCountry,
  mapHourlyRate,
  mapIndustry,
  mapProjectSize,
  mapService,
} from 'tdc-web-backend/enums/enums';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/FilterIcon.svg';
import FilterDrawer from './FilterDrawer';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import useQueryState from '../../../../utils/hooks/useQueryState';

export const allMaps = new Map<unknown, string>([
  ...Array.from(mapCountry.entries()),
  ...Array.from(mapHourlyRate.entries()),
  ...Array.from(mapIndustry.entries()),
  ...Array.from(mapProjectSize.entries()),
  ...Array.from(mapService.entries()),
]);

export const mapCompanyOrderBy = new Map([
  ['-created', 'New'],
  ['name', 'A-Z'],
  ['-name', 'Z-A'],
]);

type formNames =
  | 'orderBy'
  | 'service'
  | 'sellerCountry'
  | 'targetIndustry'
  | 'targetMarket'
  | 'averageProjectSize'
  | 'hourlyRate';

const FilterSection = () => {
  const [open, setIsOpen] = useState(false);
  const [, setQueryState, removeQueryState, searchParams] = useQueryState();
  const formMethods = useForm({
    defaultValues: {
      orderBy: '-created',
      service: [],
      sellerCountry: [],
      targetIndustry: [],
      targetMarket: [],
      averageProjectSize: '',
      hourlyRate: '',
    },
    mode: 'onChange',
  });
  const { setValue, trigger, getValues, watch, handleSubmit, reset } = formMethods;

  const onSubmit = () => null;

  const formValues: Record<string, any> = watch();
  const allValues = () => {
    const { orderBy, ...data } = formValues;
    const allKeys = Object.keys(data);
    const agregatedData = allKeys.filter((element) => data[element].length !== 0);
    return agregatedData;
  };

  useEffect(() => {
    formMethods.reset({
      orderBy: (searchParams.get('orderBy') as never) || '-created',
      service: (searchParams.getAll('service') as never[]) || [],
      sellerCountry: (searchParams.getAll('sellerCountry') as never[]) || [],
      targetIndustry: (searchParams.getAll('targetIndustry') as never[]) || [],
      targetMarket: (searchParams.getAll('targetMarket') as never[]) || [],
      averageProjectSize: (searchParams.get('averageProjectSize') as never) || '',
      hourlyRate: (searchParams.get('hourlyRate') as never) || '',
    });
  }, [open]);

  useEffect(() => {
    const subscription = watch(() => setQueryState(watch()));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack gap={3} pt={5} bgcolor="white" width="100%">
      <Typography variant="publicHeading4">Sellers</Typography>
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" gap={3} flexWrap="wrap">
            <CustomButton
              sx={{ textTransform: 'none' }}
              variant="secondaryDark"
              endIcon={<FilterIcon />}
              onClick={() => setIsOpen(!open)}
            >
              All filters
            </CustomButton>
            <CustomInput
              sx={{
                minHeight: 8,
                minWidth: 'fit-content',
                '& .MuiTypography-root': {
                  color: 'primaryDark.600',
                  fontFamily: 'Gellix-SemiBold',
                },
              }}
              input={<CustomInput.Select />}
              name="orderBy"
              placeholder="Sort by"
              choices={mapCompanyOrderBy}
            />
          </Stack>
        </Box>
        {!!allValues()?.length && <Divider />}
        <Stack
          direction="row"
          gap={2.5}
          alignItems="center"
          flexWrap="wrap"
          sx={{ mb: allValues()?.length ? 3 : 0 }}
        >
          {allValues()?.map((key) => {
            if (Array.isArray(formValues[key])) {
              return formValues[key]?.map((value: any, i: number) => (
                <CustomChip
                  key={value + i}
                  label={allMaps.get(value)}
                  onDelete={() => {
                    setValue(
                      key as formNames,
                      getValues(key as any).filter((item: string) => item !== value),
                    );
                    trigger(key as formNames);
                  }}
                />
              ));
            }
            return (
              <CustomChip
                style={{
                  textTransform: 'capitalize',
                }}
                key={formValues[key]}
                label={allMaps.get(formValues[key])}
                onDelete={() => {
                  setValue(key as formNames, '');
                  trigger(key as formNames);
                }}
              />
            );
          })}

          {!!allValues()?.length && (
            <CustomButton
              onClick={() => {
                reset({
                  orderBy: '-created',
                  service: [],
                  sellerCountry: [],
                  targetIndustry: [],
                  targetMarket: [],
                  averageProjectSize: '',
                  hourlyRate: '',
                });
                removeQueryState('orderBy');
                removeQueryState('service');
                removeQueryState('sellerCountry');
                removeQueryState('targetIndustry');
                removeQueryState('targetMarket');
                removeQueryState('averageProjectSize');
                removeQueryState('hourlyRate');
              }}
              variant="secondaryText"
            >
              Clear all
            </CustomButton>
          )}
        </Stack>
      </FormProvider>
      <FilterDrawer open={open} setIsOpen={setIsOpen} />
    </Stack>
  );
};

export default FilterSection;
