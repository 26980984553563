import {
  Box,
  Divider,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import Editor from '../../../../components/editor/Editor';
import { MinifiedEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import { handleImageFileDataChange } from '../../../../utils/file-upload-helpers';
import { FileDataWithUploadData, ImagesUrlData } from '../../../../utils/types';
import useCreateArticleStore from '../useCreateArticleStore';

export interface StepFirst {
  adminMode?: boolean;
  companies?: {
    id: string,
    name: string,
    Profile: Record<string, Record<string, Record<string, string>>>
  }[]
}

const StepFirst = ({ adminMode = false, companies }: StepFirst) => {
  const createArticleInput = useCreateArticleStore((state) => state.createArticleInput);
  const setCreateArticleInput = useCreateArticleStore((state) => state.setCreateArticleInput);
  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);
  const setCanSaveAsDraft = useDynamicMultistepStore((state) => state.setCanSaveAsDraft);
  const [imageData, setImageData] = useState<ImagesUrlData>(
    createArticleInput.files ?? new ImagesUrlData(),
  );
  const requiredMessage = 'This field is required';
  const adminFields = adminMode ? { company: createArticleInput?.company } : {};

  const {
    register, watch, setValue, formState: { errors, isValid }, control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...adminFields,
      title: createArticleInput.title ?? '',
      body: {
        articleBody: createArticleInput?.body?.articleBody ?? null,
        authorBiography: createArticleInput?.body?.authorBiography ?? null,
      },
      authorName: createArticleInput?.authorName ?? null,
    },
  });

  useEffect(() => {
    setCreateArticleInput({
      files: imageData,
    });
  }, [imageData]);

  useEffect(() => {
    setCanSaveAsDraft(!!(isValid && imageData.banner !== undefined));
    setCanGoNext(!!(isValid && imageData.banner !== undefined && watch('body.articleBody') !== null));
  }, [isValid, imageData, watch('body.articleBody')]);

  useEffect(() => {
    const subscription = watch(() => setCreateArticleInput(watch()));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box component="form">
      <Stack spacing={5}>
        {adminMode && (
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>Company</Typography>
            <Controller
              name="company"
              control={control}
              rules={{ required: requiredMessage }}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ background: '#fff' }}
                  displayEmpty
                  fullWidth
                  renderValue={(selected) => {
                    if (selected === undefined) {
                      return <Typography sx={{ color: 'grey.500' }}>Select*</Typography>;
                    }

                    return companies?.find((comp) => comp.id === selected)?.name;
                  }}
                >
                  {
                    companies?.map((comp) => (
                      <MenuItem key={comp.id} value={comp.id}>
                        {comp.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              )}
            />
          </Box>
        )}
        <Box>
          <Box sx={{ mb: 0.5 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Title</Typography>
            <Typography variant="body1">
              The title should include the necessery keywords and be between 4-9 words long.
            </Typography>
          </Box>

          <TextField
            {...register('title', { required: requiredMessage })}
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Type*"
            sx={{ background: '#fff' }}
            helperText={
              errors.title
              && (
                <FormHelperText error sx={{ ml: 0 }}>
                  {errors.title.message}
                </FormHelperText>
              )
            }
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Upload Featured image</Typography>
          <Typography variant="body1" sx={{ mb: 1, maxWidth: 800 }}>
            We’ll need an image that represents your article on the archive page and the thumbnail.
            Suggested dimensions: 1920x1080 pixels, .jpg or .png. Maximum 10MB.
          </Typography>
        </Box>

        <Box>
          <Box sx={{ py: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Article text</Typography>
            <Typography variant="body1">
              Write and format your article here.
              Be sure to add headings, relevant links and keywords.
            </Typography>
          </Box>
          <Editor
            items={MinifiedEditorMenu}
            isEditable
            onUpdateContent={(data: any) => setValue('body.articleBody', data, { shouldValidate: true })}
            onValidationChange={(valid) => {
              if (!valid) {
                setValue('body.articleBody', null);
              }
            }}
          />
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: 'grey.500' }}>OPTIONAL</Typography>
          <Divider />
        </Box>

        <Stack spacing={2}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Author</Typography>
            <Typography variant="body1">
              Placeholder something blah blah
            </Typography>
          </Box>

          <Stack spacing={2}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Name</Typography>
              <TextField
                {...register('authorName')}
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Type"
                sx={{ background: '#fff' }}
                helperText={
                  errors.title
                  && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors.title.message}
                    </FormHelperText>
                  )
                }
              />
            </Box>

            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Biography</Typography>
              <TextField
                {...register('body.authorBiography')}
                fullWidth
                multiline
                rows={5}
                type="text"
                variant="outlined"
                placeholder="Type"
                sx={{ background: '#fff' }}
                helperText={
                  errors.title
                  && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors.title.message}
                    </FormHelperText>
                  )
                }
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default StepFirst;
