import { Stack, Typography } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { secondaryBlue } from '../../../../color';
import ReusableHeroSection from '../../../../components/public-screen-components/ReusableHeroSection';
import TextAndImage from '../../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from '../components/HowMuchDoesItCost';
import BecomeAPartOfSpona from '../../../../components/public-screen-components/BecomeAPartOfSpona';
import { textsAndImagesMonitorYourKPIs } from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../../components/seo/MetaTags';
import { EnumPublicPagesImage } from '../../../../utils/enums/public-page-images';
import { getPublicImageUrl } from '../../../../utils/helpers';

const metaTags = {
  description: `Is data driving decisions on your outsourced project? If not, see how Spona's End to End Management tool can help.`,
};

const MonitorYourKPIs = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Monitor Your KPIs"
        content="Get essential project data that will help you organize your work, provide better time estimates, quickly pinpoint your areas of improvement and engender future insights to improve all facets of your business in the future. Spona’s seamlessly integrated project reporting supports your management activities and provides you with results that you need, when you need them."
        image={getPublicImageUrl(EnumPublicPagesImage.MonitorYourKPIs)}
        sx={{ height: { xs: `calc(79vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="Close up of a hand pointing at statistic data on a personal computer"
      />

      <Stack spacing={10} sx={{ mt: { xs: '-60px', lg: 12 } }}>
        {textsAndImagesMonitorYourKPIs.map(({ title, content, image, contentOrder, alt }) => (
          <TextAndImage
            key={content}
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentStyles={{ justifyContent: 'center' }}
            contentSpacing={{ xs: 2, lg: 3 }}
            alt={alt}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        firstCard={{
          title: 'Traditional Reports',
          sx: { alignItems: 'start' },
          bgcolor: 'white',
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Require additional tools or services</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Promote data micromanagement</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Complicate instead of streamlining</Typography>
              </Stack>
            </Stack>
          ),
        }}
        secondCard={{
          title: 'With Spona',
          sx: { alignItems: 'start' },
          bgcolor: secondaryBlue[101],
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Get all your data in one place</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Improve communications across the board</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Facilitate learning and make smart decisions</Typography>
              </Stack>
            </Stack>
          ),
        }}
      />

      <BecomeAPartOfSpona
        title="Get started with End to End"
        bgcolor="secondaryYellow.100"
        color="black"
      />
    </Stack>
  </>
);

export default MonitorYourKPIs;
