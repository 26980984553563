import { TabContext } from '@mui/lab';
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import TabChip from './TabChip';
import CustomTablePanel from './CustomTablePanel';
import DashboardCard from '../dashboard-card/EndToEndCard.tsx/DashboardCard';
import { secondaryBlue } from '../../color';
import { CustomTableProps } from './interface';
import defaultPanelConfig from './utils';

const secondaryVisualStyle = {
  '.MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },
  '.MuiDataGrid-row': {
    border: '2px solid transparent',
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: 'primaryDark.150',
    '&:hover': {
      boxShadow: 'none',
      background: secondaryBlue[50],
    },
  },
  '.Mui-selected': {
    border: '2px solid',
    borderColor: 'secondaryBlue.200',
    backgroundColor: `${secondaryBlue[50]} !important`,
    '&>*:nth-child(2)>*': {
      color: 'primaryLight.500',
    },
  },
};

const Table = <T,>({
  sx,
  panelConfig = defaultPanelConfig,
  searchParams,
  visualStyle = 'secondary',
  resource,
  height,
  disableTabView = false,
  tabsClassName,
  onLoadCount,
  onDataLoaded,
  ...props
}: CustomTableProps<T>) => {
  const [selectedTab, setSelectedTab] = useState('0');
  const [tabResultsCounter, setTabResultsCounter] = useState<Record<string, string | number>>({});

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <TabContext value={selectedTab}>
        <Stack sx={{ width: '100%' }}>
          {!disableTabView && (
            <Tabs
              className={tabsClassName || undefined}
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="secondary tabs example"
              sx={{
                mb: 2,
                mt: 2,
                p: 0,
              }}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              {panelConfig?.map(({ title }, index) => {
                const resultCount = tabResultsCounter[index];

                return (
                  <Tab
                    value={`${index}`}
                    disableRipple
                    sx={{
                      p: 0,
                      mx: 1,
                      minHeight: '2rem',
                      minWidth: 0,
                      '&.Mui-selected': {
                        p: 0,
                      },
                    }}
                    icon={
                      <TabChip
                        resultCount={resultCount as number}
                        value={`${index}`}
                        selectedTab={selectedTab}
                        label={title}
                      />
                    }
                  />
                );
              })}
            </Tabs>
          )}

          {panelConfig?.map((item, index) => {
            const onLoadCountFunc = (count: number) => {
              setTabResultsCounter((prevState) => ({ ...prevState, [index]: count }));
            };

            return (
              <CustomTablePanel<T, unknown>
                key={item.id ? item.id : index}
                // never display footer by default here
                // use CustomTableWithPagination component if you need pagination
                hideFooter
                resource={resource ?? ''}
                sx={{
                  ...(visualStyle === 'secondary' && secondaryVisualStyle),
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    px: 0,
                  },
                  ...sx,
                }}
                height={height}
                value={`${index}`}
                searchParams={{ ...searchParams, ...item?.search }}
                onLoadCount={(count) => {
                  onLoadCountFunc(count);
                  if (onLoadCount) {
                    onLoadCount(count);
                  }
                }}
                onDataLoaded={onDataLoaded ? (data) => onDataLoaded(data) : () => {}}
                {...props}
              />
            );
          })}
        </Stack>
      </TabContext>
    </Box>
  );
};

const CustomTable = <T,>({
  sx,
  panelConfig = defaultPanelConfig,
  searchParams,
  visualStyle = 'secondary',
  resource,
  height,
  disableTabView = false,
  tabsClassName,
  onLoadCount,
  onDataLoaded,
  withDashboardCard,
  ...props
}: CustomTableProps<T>) => (
  <>
    {withDashboardCard ? (
      <DashboardCard
        headline={withDashboardCard?.headline ?? ''}
        linkText={withDashboardCard?.linkText ?? ''}
        link={withDashboardCard?.link ?? ''}
        headlineEndElement={withDashboardCard.headlineEndElement ?? null}
        sx={withDashboardCard?.sx}
        renderComponent={
          <Stack className="withDashboardCard">
            <Divider sx={{ borderColor: 'primaryDark.250' }} />

            <Table
              sx={sx}
              panelConfig={panelConfig}
              searchParams={searchParams}
              visualStyle={visualStyle}
              resource={resource}
              height={height}
              disableTabView={disableTabView}
              tabsClassName={tabsClassName}
              onLoadCount={onLoadCount}
              onDataLoaded={onDataLoaded}
              {...props}
            />
          </Stack>
        }
      />
    ) : (
      <Table
        sx={sx}
        panelConfig={panelConfig}
        searchParams={searchParams}
        visualStyle={visualStyle}
        resource={resource}
        height={height}
        disableTabView={disableTabView}
        tabsClassName={tabsClassName}
        onLoadCount={onLoadCount}
        onDataLoaded={onDataLoaded}
        {...props}
      />
    )}
  </>
);

export default CustomTable;
