/* eslint-disable import/no-unresolved */
import './slider.scss';
import { IconButton, Stack } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/system';
import { CarouselProps } from '../interface';

const settings: Settings = {
  centerMode: true,
  prevArrow: (
    <IconButton
      sx={{
        background: '#red !important',
        backgroundColor: '#red !important',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid',
        borderColor: 'primaryLight.500',
        p: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          color: 'primaryLight.500',
        },
      }}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <ChevronLeftIcon sx={{ color: 'primaryLight.700', fontSize: '1.5rem' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton
      sx={{
        background: '#red !important',
        backgroundColor: '#red !important',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid',
        borderColor: 'primaryLight.500',
        p: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          color: 'primaryLight.500',
        },
      }}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <ChevronRightIcon sx={{ color: 'primaryLight.700', fontSize: '1.5rem' }} />
    </IconButton>
  ),
  arrows: true,
  className: 'center',
  responsive: [
    {
      breakpoint: 1204,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 899,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // initialSlide: 2,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  infinite: true,
  centerPadding: '0px',
  slidesToShow: 3,
  speed: 500,
};

const CenterCarousel = <T,>({ data, renderCustomComponent }: CarouselProps<T>) => (
  <Stack width="100%" maxWidth="55rem" mx="auto">
    {data && <Slider {...settings}>{renderCustomComponent(data)}</Slider>}
  </Stack>
);

export default CenterCarousel;

// This wrapper is used to accept sliders default styling so that it could display paddings
export const SliderWrapperItem = ({ children }: { children: JSX.Element }) => (
  <Box
    sx={{
      maxWidth: '15rem',
      mx: 'auto',
      py: '3.5rem',
    }}
  >
    {children}
  </Box>
);
