import {
  Card, Stack, Typography,
} from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import CircularProgressWithLabel
  from '../../../../../components/review-score/CircularProgressWithLabel';
import CustomChip from '../../../../../components/custom-chip/CustomChip';
import { mapProjectStatus } from '../../../../../enums';
import ProjectCancelModal from '../modals/ProjectCancelModal';
import StartProjectModal from '../modals/StartProjectModal';

export interface ProjectStatusCardProps {
  projectData: ProjectDto,
  initialContract: ContractDto,
}

const getBackgroundColor = (status: EnumProjectStatus) => {
  switch (status) {
    case EnumProjectStatus.Completed:
      return '#D1F6D0';
    case EnumProjectStatus.InProgress:
      return '#DFE0FF';
    case EnumProjectStatus.Canceled:
      return '#FFB4A9';
    case EnumProjectStatus.Pending:
      return '#F8EBCB';
    default:
      return '#C5C7C3';
  }
};

const ProjectStatusCard = ({ projectData, initialContract }: ProjectStatusCardProps) => {
  const score = Math.ceil((projectData?.tasksCompletedCount / projectData?.tasksCount) * 100);
  return (
    <Card
      elevation={3}
      sx={{
        width: '30%',
        borderRadius: '8px',
      }}
    >
      <Stack spacing={4} sx={{ p: '1em' }} height="100%" justifyContent="space-between">
        <Stack
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="body2" sx={{ my: 'auto' }}>Status</Typography>
          <CustomChip
            label={mapProjectStatus.get(projectData?.status)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center">
          <CircularProgressWithLabel
            displayScore
            size={10}
            truePercentage={Number.isNaN(score) ? 0 : score}
          />
        </Stack>
        <Stack direction="column" width="100%" spacing={1} alignItems="center">
          <ProjectCancelModal projectData={projectData} />
          <StartProjectModal initialContract={initialContract} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProjectStatusCard;
