import { useFormContext } from 'react-hook-form';
import {
  EnumCountry,
  EnumIndustry,
  EnumService,
  EnumTechLanguage,
  EnumTechPlatform,
  EnumTechTool,
  mapIndustry,
  mapService,
  mapCountry,
} from 'tdc-web-backend/enums/enums';
import { Box, Stack, Typography } from '@mui/material';
import { mapProviderScope, mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../enums';
import PreviewText from '../../../../components/preview/PreviewText';
import UserAgreement from '../../../../components/multisteps/components/UserAgreement';
import DateDisplayCard from '../../../../components/DateDisplayCard/DateDisplayCard';
import CustomChip from '../../../../components/custom-chip/CustomChip';

const sxDateCard = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'auto',
  height: 'auto',
  '.MuiTypography-heading4': {
    fontSize: '12px',
  },
  '.MuiTypography-heading3': {
    fontSize: '14px',
  },
};

const sxChipContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '8px',
  margin: '8px 0 0 0',
};

const PreviewSidebar = () => {
  const { getValues } = useFormContext();
  const { budget, skills, timeline, about } = getValues(); // timedProject

  const projectType = budget.recurring ? 'Recurring' : 'One time';
  const providerScope = mapProviderScope.get(skills.providerScope) || '';
  const targetMarkets = about.targetMarkets?.map((key: EnumCountry) => mapCountry.get(key)) || [];
  const industries = about.industries.map((key: EnumIndustry) => mapIndustry.get(key));
  const services = skills.services.map((key: EnumService) => mapService.get(key));
  const platforms = skills.platforms.map((key: EnumTechPlatform) => mapTechPlatform.get(key));
  const languages = skills.languages.map((key: EnumTechLanguage) => mapTechLanguage.get(key));
  const tools = skills.tools.map((key: EnumTechTool) => mapTechTool.get(key));

  return (
    <Stack>
      <DateDisplayCard
        sx={{
          ...sxDateCard,
          borderBottom: '1px solid #E5E8FF',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        variant="start"
        text="pitch deadline"
        date={timeline.pitchDeadline}
      />
      <DateDisplayCard
        sx={{ ...sxDateCard, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        variant="end"
        text="project start"
        date={timeline.projectStart}
      />
      <Box
        sx={{
          '.MuiStack-root': { padding: '16px', borderBottom: '1px solid #E5E8FF' },
          '.MuiStack-root:nth-last-child(1)': { borderBottom: 'none' },
        }}
      >
        <PreviewText label="seller location" value={providerScope} />
        <PreviewText label="project type" value={projectType} />
        <PreviewText label="budget" value={`$${budget.budget}`} />
        {!!targetMarkets.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              target market
            </Typography>
            <Box sx={sxChipContainer}>
              {targetMarkets.map((targetMarket: EnumCountry) => (
                <CustomChip label={targetMarket} />
              ))}
            </Box>
          </Stack>
        )}
        {!!industries.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              industry
            </Typography>
            <Box sx={sxChipContainer}>
              {industries.map((industry: EnumIndustry) => (
                <CustomChip label={industry} />
              ))}
            </Box>
          </Stack>
        )}
        {!!services.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              required services
            </Typography>
            <Box sx={sxChipContainer}>
              {services.map((service: EnumService) => (
                <CustomChip label={service} />
              ))}
            </Box>
          </Stack>
        )}
        {!!platforms.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              tech platforms
            </Typography>
            <Box sx={sxChipContainer}>
              {platforms.map((platform: EnumTechPlatform) => (
                <CustomChip label={platform} />
              ))}
            </Box>
          </Stack>
        )}
        {!!tools.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              tech tools
            </Typography>
            <Box sx={sxChipContainer}>
              {tools.map((tool: EnumTechTool) => (
                <CustomChip label={tool} />
              ))}
            </Box>
          </Stack>
        )}
        {!!languages.length && (
          <Stack>
            <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
              programming languages
            </Typography>
            <Box sx={sxChipContainer}>
              {languages.map((language: EnumTechLanguage) => (
                <CustomChip label={language} />
              ))}
            </Box>
          </Stack>
        )}
      </Box>
      {/* <UserAgreement sx={{ margin: '16px 0 0 0' }} /> */}
    </Stack>
  );
};

export default PreviewSidebar;
