import {
  Stack,
  SxProps,
  Theme,
  Typography,
  MenuItem,
  ClickAwayListener,
  Button as MUIButton,
  Box,
  Paper,
  Popper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState, Dispatch, SetStateAction } from 'react';
import { MilestoneDto, MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { format } from 'date-fns';
import { EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { useNavigate, useParams } from 'react-router-dom';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { ReactComponent as CircularArrowsIcon } from '../../../../../assets/icons/content/CircularArrowsIcon.svg';
import StatusChipField from '../../../../../components/StatusChipField/StatusChipField';
import { truncate } from '../../../../../utils/helpers';

interface RecurringMilestoneProps {
  milestone: MilestoneSnakeDto;
  setIsMouseDown?: Dispatch<SetStateAction<boolean>>;
  setTaskId: Dispatch<SetStateAction<string | null>>;
  showText?: boolean;
  determineWhichTaskToShow: (tasks: TaskDto[]) => string | null;
  sx?: SxProps<Theme> | undefined;
}

const numberOfLettersToDisplay = 18;

const RecurringMilestoneDropdown = ({
  milestone,
  setIsMouseDown,
  setTaskId,
  showText = false,
  determineWhichTaskToShow,
  sx,
}: RecurringMilestoneProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box
          onMouseDown={(e) => setIsMouseDown?.(true)}
          onMouseUp={(e) => setIsMouseDown?.(false)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MUIButton
            disableRipple
            onMouseDown={(e) => setIsMouseDown?.(true)}
            onMouseUp={(e) => setIsMouseDown?.(false)}
            onClick={(e) => {
              e.stopPropagation();

              if (anchorEl) {
                setAnchorEl(null);
              } else {
                setAnchorEl(e.currentTarget);
              }
            }}
            sx={{ p: 0 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: 'common.white',
                border: '1px solid',
                borderColor: 'primaryLight.100',
                px: 0.8,
                py: 0.6,
                borderRadius: '6px',
                ...(showText && {
                  height: '100%',
                }),
                ...sx,
              }}
            >
              <Stack spacing={0.5} direction="row" alignItems="center">
                <CircularArrowsIcon fill="#6D51F6" style={{ width: 18, height: 18 }} />

                {showText && (
                  <Typography variant="heading5" color="primaryDark.600">
                    recurring
                  </Typography>
                )}

                <Typography variant="heading5" color="primaryLight.500">
                  {milestone.status === EnumProjectMilestoneStatus.InProgress ||
                  milestone.status === EnumProjectMilestoneStatus.Completed
                    ? milestone.activeMilestoneIndex
                    : '-'}
                </Typography>

                <Typography variant="heading5" color="primaryLight.500">
                  /
                </Typography>

                <Typography variant="heading5" color="primaryLight.500">
                  {milestone.relatedMilestones ? milestone.relatedMilestones.length : 0}
                </Typography>

                {open ? (
                  <KeyboardArrowUpIcon sx={{ width: 18, height: 18, color: 'primaryDark.600' }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ width: 18, height: 18, color: 'primaryDark.600' }} />
                )}
              </Stack>
            </Stack>
          </MUIButton>

          <Popper anchorEl={anchorEl} open={open} placement="bottom-end">
            <Paper
              elevation={0}
              sx={{
                mt: 0.85,
                width: 340,
                maxHeight: 320,
                border: '1px solid',
                borderColor: '#E5E8FF',
                borderRadius: '8px',
                overflowY: 'auto',
              }}
            >
              {milestone.relatedMilestones.map((relatedMilestone: MilestoneDto, index: number) => (
                <MenuItem
                  onClick={() => {
                    navigate(`/dashboard/end-to-end/milestone/${relatedMilestone.id}`);

                    if (relatedMilestone) {
                      setTaskId(determineWhichTaskToShow(relatedMilestone.tasks));
                    }
                  }}
                  sx={{
                    height: 80,
                    borderBottom: '1px solid',
                    borderBottomColor: '#E5E8FF',
                    bgcolor:
                      relatedMilestone.id === params?.milestoneId
                        ? 'rgba(0, 0, 0, 0.04)'
                        : 'common.white',
                  }}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Stack
                          spacing={0.25}
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            py: 0.5,
                            px: 0.75,
                            borderRadius: '4px',
                            backgroundColor: '#E5E8FF',
                          }}
                        >
                          <Typography
                            variant="body3"
                            color="secondaryBlue.700"
                            sx={{
                              borderBottom: '1px solid',
                              borderBottomColor: 'secondaryBlue.700',
                            }}
                          >
                            {index + 1}
                          </Typography>

                          <Typography variant="body3">
                            {milestone.relatedMilestones ? milestone.relatedMilestones.length : 0}
                          </Typography>
                        </Stack>

                        <Stack>
                          <Typography
                            fontWeight="500"
                            color="primaryDark.600"
                            sx={{
                              textDecoration:
                                relatedMilestone.status === EnumProjectMilestoneStatus.Completed
                                  ? 'line-through'
                                  : 'none',
                            }}
                          >
                            {relatedMilestone.name &&
                            relatedMilestone.name.length <= numberOfLettersToDisplay
                              ? relatedMilestone.name
                              : truncate(relatedMilestone.name || '', numberOfLettersToDisplay)}
                          </Typography>

                          <Stack direction="row" color="primaryDark.400">
                            <Typography variant="heading4" letterSpacing={0}>
                              {relatedMilestone.start
                                ? format(new Date(relatedMilestone.start as Date), 'MM.dd')
                                : '-'}
                            </Typography>

                            <Typography variant="heading4" letterSpacing={0}>
                              &nbsp; - &nbsp;
                            </Typography>

                            <Typography variant="heading4" letterSpacing={0}>
                              {relatedMilestone.end
                                ? format(new Date(relatedMilestone.end as Date), 'MM.dd')
                                : '-'}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>

                    <StatusChipField
                      text={relatedMilestone.status}
                      componentReturnType="chip"
                      sx={{ pointerEvents: 'none' }}
                    />
                  </Stack>
                </MenuItem>
              ))}
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default RecurringMilestoneDropdown;
