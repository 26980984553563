import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Stack } from '@mui/system';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { mapIndustry } from 'tdc-web-backend/enums/enums';
import { EnumIndustry } from 'tdc-web-backend/enums/enums';
import { Dispatch, SetStateAction } from 'react';
import CompanyTargetIndustriesEditModal from '../edit/CompanyTargetIndustriesEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyTargetIndustriesProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTargetIndustries = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTargetIndustriesProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.targetIndustries?.map((industry: EnumIndustry) => (
        <CustomChip
          key={industry}
          tooltipTitle={mapIndustry.get(industry)}
          label={
            industry &&
            truncate(mapIndustry.get(industry) as string, chipLabelTruncateValue as number)
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}
      {companyData && (
        <CompanyTargetIndustriesEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyTargetIndustries;
