import { CircularProgress, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import useReferenceOne from '../../utils/hooks/crud-hooks/useReferenceOne';

type ReferenceFieldProps<RecordType extends unknown = any> = {
  resource: string;
  id: string;
  displayName: string| number
  renderComponent?: (data: RecordType) => JSX.Element;
} & TypographyProps

const CustomReferenceField = ({
  id,
  displayName = 'name',
  resource,
  renderComponent,
  ...props
}: ReferenceFieldProps) => {
  const { data, isLoading } = useReferenceOne<Record<string, unknown>>({ id, resource: `${resource}` });
  return isLoading
    ? <CircularProgress size={20} />
    : (
      <>
        {/* Use this prop if you want to display data with component other than Typography  */}
        {renderComponent ? renderComponent(data) : <Typography {...props}>{data ? data[displayName] : '---'}</Typography>}
      </>
    );
};

export default CustomReferenceField;

// @example
//
// Example for renderComponent prop
//
// <CustomReferenceField
//    id={item.project}
//    resource="projects"
//    displayName="documents"
//    renderComponent={(data: yourType) => (<StatusChipField text={data?.status} />)}
//    color="primaryDark.600"
//    variant="heading5"
//  />
