import { Stack, Typography } from '@mui/material';
import Editor from '../../../../../../components/editor/Editor';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../../../layout/public-layout/navigation/Navigation';

export type BlogSectionItem = {
  subtitle: string;
  body: string;
  id: string;
};

type SectionsProps = {
  sections: BlogSectionItem[];
};

const Sections = ({ sections }: SectionsProps) => (
  <Stack spacing={5}>
    {sections?.map((section: BlogSectionItem) => (
      <Stack spacing={2} id={section.id} sx={{ scrollMarginTop: `${PUBLIC_APPBAR_HEIGHT + 16}px` }}>
        <Stack>
          <Typography variant="publicBody1" color="secondaryGray.900">
            {section.subtitle}
          </Typography>
        </Stack>
        <Stack>
          <Editor
            items={[]}
            content={section.body}
            isEditable={false}
            notEditableFontSize="1.25rem !important"
          />
        </Stack>
      </Stack>
    ))}
  </Stack>
);

export default Sections;
