import { Box, Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import filesIcon from '../../assets/img/public-pages/files.png';

export interface ClipboardProps {
  text: string;
}

const ClipboardButton = ({ text }: ClipboardProps) => {
  const [open, setIsOpened] = useState(false);
  const copyToClipboard = () => {
    setIsOpened(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setIsOpened(false);
    }, 1000);
  };
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      enterTouchDelay={1000}
      disableTouchListener
      title="Copied link"
      arrow
    >
      <Button
        onClick={copyToClipboard}
        sx={{
          borderRadius: '50px',
          border: '1px solid #79829B',
          color: '#79829B',
          px: '1rem',
          py: '0.2rem',
          maxWidth: 'fit-content',
        }}
        endIcon={<Box component="img" src={filesIcon} />}
      >
        {text}
      </Button>
    </Tooltip>
  );
};
export default ClipboardButton;
