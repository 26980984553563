import { Input, TextField } from '@material-ui/core';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Card, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../../components/button/Button';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import useTaskManagementStore from '../useTaskManagementStore';
import { dateToLocalDateString } from '../../../../utils/helpers';

export default function StepSubtasks() {
  const taskManagementInput = useTaskManagementStore((state) => state.taskManagementInput);
  const setTaskManagementInput = useTaskManagementStore((state) => state.setTaskManagementInput);

  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);
  const requiredMessage = 'This field is required';

  const {
    register,
    watch,
    formState: { isValid },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ProjectSubtasks: taskManagementInput.ProjectSubtasks
        ? taskManagementInput?.ProjectSubtasks?.map((material: any) => ({
            title: material?.title ?? undefined,
            estStartOn: material?.estStartOn
              ? dateToLocalDateString(new Date(material?.estStartOn as string))
              : undefined,
            estEndOn: material?.estEndOn
              ? dateToLocalDateString(new Date(material?.estEndOn as string))
              : undefined,
            description: material?.description ?? undefined,
          }))
        : [
            {
              title: undefined,
              estStartOn: undefined,
              estEndOn: undefined,
              description: undefined,
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ProjectSubtasks',
  });

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setTaskManagementInput(watch()));
    if (
      taskManagementInput.body?.subtaskList?.length !== 0 &&
      taskManagementInput.body?.subtaskList
    ) {
      remove(0);
      append(taskManagementInput.body?.subtaskList);
    }
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ marginBottom: '1em' }}>
        <Stack spacing={4}>
          {fields.map((el, index) => (
            <Card sx={{ padding: '2em', position: 'relative' }}>
              <CloseIcon
                onClick={() => remove(index)}
                sx={{
                  position: 'absolute',
                  top: '.5em',
                  right: '.5em',
                  cursor: 'pointer',
                }}
              />
              {/* eslint-disable-next-line react/no-array-index-key */}
              <Stack key={index}>
                <Stack direction="row">
                  <Controller
                    control={control}
                    name={`ProjectSubtasks.${index}.title`}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        placeholder="Subtask name"
                        fullWidth
                        {...register(`ProjectSubtasks.${index}.title`, {
                          required: requiredMessage,
                        })}
                        value={value || taskManagementInput.body?.subtaskList?.[index]?.title || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'space-between', width: '100%', margin: '1em 0' }}
                >
                  <Controller
                    control={control}
                    name={`ProjectSubtasks.${index}.estEndOn`}
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        value={
                          value || taskManagementInput.body?.subtaskList?.[index]?.estEndOn || ''
                        }
                        {...register(`ProjectSubtasks.${index}.estEndOn`, {
                          required: requiredMessage,
                        })}
                        onChange={onChange}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Card>
          ))}
          <Button
            sx={{
              margin: 'auto',
              border: '1px dashed black',
              width: '100%',
            }}
            onButtonClick={() =>
              append({
                title: undefined,
                description: undefined,
                startDate: undefined,
                endDate: undefined,
              })
            }
          >
            ADD NEW <AddIcon />
          </Button>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
