import defaultIcon from '../../assets/icons/services/icon-service-default.svg';
import AngularIcon from '../../assets/icons/tech-tools/icon-technology-expertise-angular-js.svg';
import ASPdotNETIcon from '../../assets/icons/tech-tools/icon-technology-expertise-asp-net.svg';
import DrupalIcon from '../../assets/icons/tech-tools/icon-technology-expertise-drupal.svg';
import JoomlaIcon from '../../assets/icons/tech-tools/icon-technology-expertise-joomla.svg';
import LaravelIcon from '../../assets/icons/tech-tools/icon-technology-expertise-laravel.svg';
import MongoDBIcon from '../../assets/icons/tech-tools/icon-technology-expertise-mongodb.svg';
import MySQL_or_MariaDBIcon from '../../assets/icons/tech-tools/icon-technology-expertise-mariadb-mysql.svg';
import NodeJSIcon from '../../assets/icons/tech-tools/icon-technology-expertise-node-js.svg';
import ReactIcon from '../../assets/icons/tech-tools/icon-technology-expertise-react-js.svg';
import Ruby_on_RailsIcon from '../../assets/icons/tech-tools/icon-technology-expertise-ruby-on-rails.svg';
import SymfonyIcon from '../../assets/icons/tech-tools/icon-technology-expertise-symfony.svg';
import UnityIcon from '../../assets/icons/tech-tools/icon-technology-expertise-unity.svg';
import VueJSIcon from '../../assets/icons/tech-tools/icon-technology-expertise-vue-js.svg';
import WordPress from '../../assets/icons/tech-tools/icon-technology-expertise-wordpress.svg';

const mapIconTechTool = new Map([
  ['Default', defaultIcon],
  ['Angular', AngularIcon],
  ['ASPdotNET', ASPdotNETIcon],
  ['Drupal', DrupalIcon],
  ['Joomla', JoomlaIcon],
  ['Laravel', LaravelIcon],
  ['MongoDB', MongoDBIcon],
  ['MySQL_or_MariaDB', MySQL_or_MariaDBIcon],
  ['NodeJS', NodeJSIcon],
  ['React', ReactIcon],
  ['Ruby_on_Rails', Ruby_on_RailsIcon],
  ['Symfony', SymfonyIcon],
  ['Unity', UnityIcon],
  ['VueJS', VueJSIcon],
  ['WordPress', WordPress],
]);

export default mapIconTechTool;
