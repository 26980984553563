import { Box, Stack, Typography } from '@mui/material';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import useTaskManagementStore from '../useTaskManagementStore';

export default function StepConfirm() {
  const taskManagementInput = useTaskManagementStore((state) => state.taskManagementInput);

  const taskStatus = () => (
    <TaskStatus text={taskManagementInput.taskStatus} />
  );

  return (
    <Stack spacing={6} sx={{ marginBottom: '4em' }}>
      <Typography sx={{ textAlign: 'center' }}>
        <Stack>
          <Typography>You have successfully created a new task.</Typography>
        </Stack>
        <Stack>
          <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
            Task is currently in
            <Box sx={{ marginRight: '0.5em' }}>
              {taskStatus()}
            </Box>
            status.
          </Typography>
        </Stack>
        <Stack>
          <Typography>
            When the agency confirms it the task will be treated as valid within the contract.
          </Typography>
        </Stack>
      </Typography>
    </Stack>
  );
}
