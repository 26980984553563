import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useParams } from 'react-router-dom';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import ControlledSelectInput from '../../../../../components/custom-inputs/ControlledSelectInput';

const SelectBuyerStep = () => {
  const { timedProjectId } = useParams();

  const { data: buyerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=buyer&status=approved',
  });

  return (
    <ControlledSelectInput
      name="buyer"
      label="Select buyer"
      required
      disabled={!!timedProjectId}
      choices={buyerData?.data.results as any}
      placeholder="Select"
    />
  );
};

export default SelectBuyerStep;
