import { IconButton, SxProps, Theme } from '@mui/material';
import { useRef } from 'react';
import AliceCarousel, { Props } from 'react-alice-carousel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './custom-alice-carousel.scss';

type CustomAliceCarousel<T> = {
  items: T[];
  buttonStyles?: SxProps<Theme>;
  onChangedSlide?: (item: number) => void;
} & Props;

const CustomAliceCarousel = <T,>({
  items,
  buttonStyles,
  onChangedSlide,
  ...rest
}: CustomAliceCarousel<T>) => {
  const carousel = useRef(null);

  const ArrowButton = ({ sx }: { sx: SxProps<Theme> }) => (
    <IconButton
      onClick={() => (carousel.current as any).slideNext(500)}
      sx={{
        position: 'absolute',
        width: '2.7rem',
        height: '2.7rem',
        zIndex: 2,
        backgroundColor: 'primaryLight.100',
        top: { xs: '58%', lg: '48%', xl: '45%' },
        right: { xs: '0%' },
        boxShadow: '6px 6px 15px 0px rgba(56, 69, 114, 0.10)',
        ...sx,
      }}
    >
      <ChevronRightIcon sx={{ color: 'primaryLight.700', fontSize: '1.9rem' }} />
    </IconButton>
  );

  return (
    <>
      <AliceCarousel
        ref={carousel}
        onSlideChanged={(props) => onChangedSlide?.(props.item)}
        mouseTracking
        touchTracking
        items={items}
        animationType="slide"
        paddingLeft={0}
        paddingRight={0}
        autoPlay
        autoPlayInterval={4500}
        controlsStrategy="alternate"
        infinite
        disableButtonsControls
        {...rest}
      />

      <ArrowButton sx={{ ...buttonStyles }} />
    </>
  );
};

export default CustomAliceCarousel;
