import { Divider, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../../components/button/CustomButton';
import CompanyDetailSubsectionItem from '../CompanyDetailSubsectionItem';
import CompanyDetailSubsectionRow from '../CompanyDetailSubsectionRow';
import CompanyOverview from './components/CompanyOverview';
import CompanyClients from './components/CompanyClients';
import CompanyOfficeLocations from './components/CompanyOfficeLocations';
import CompanyWorkingLanguages from './components/CompanyWorkingLanguages';
import CompanyCertifications from './components/CompanyCertifications';
import CompanyAwards from './components/CompanyAwards';
import CompanyTargetIndustries from './components/CompanyTargetIndustries';
import CompanyTargetMarkets from './components/CompanyTargetMarkets';
import ComapnySocialMedia from './components/CompanySocialMedia';
import CompanyServices from './components/CompanyServices';
import CompanyTechTools from './components/CompanyTechTools';
import CompanyTechPlatforms from './components/CompanyTechPlatforms';
import CompanyTechLanguages from './components/CompanyTechLanguages';
import CompanyAbout from './components/CompanyAbout';
import CompanyTeam from './components/CompanyTeam';

const CompanyDetailSubsection = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={4}>
      {/* row first */}
      <CompanyDetailSubsectionRow
        sx={{
          justifyContent: 'space-between',
        }}
        row={[
          // element first
          <CompanyDetailSubsectionItem
            title="Overview"
            content={(isOverviewModalOpen, setIsOverviewModalOpen) => (
              <CompanyOverview
                isOverviewModalOpen={isOverviewModalOpen}
                setIsOverviewModalOpen={setIsOverviewModalOpen}
              />
            )}
          />,

          // element second
          <CompanyDetailSubsectionItem
            title="About"
            content={(isEditModalOpen, setIsEditModalOpen) => (
              <CompanyAbout
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
              />
            )}
          />,

          // element third
          // team & clients columnn
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CompanyDetailSubsectionItem
              title="Team"
              actionButton={
                <CustomButton
                  variant="primaryText"
                  onClick={() => navigate('./team')}
                  sx={{ fontSize: '14px' }}
                >
                  Add
                </CustomButton>
              }
              content={<CompanyTeam />}
            />

            <CompanyDetailSubsectionItem
              title="Clients"
              actionButton={
                <CustomButton variant="primaryText" onClick={() => {}} sx={{ fontSize: '14px' }}>
                  View all
                </CustomButton>
              }
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyClients
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,
        ]}
      />

      <Divider />

      {/* row second */}
      <CompanyDetailSubsectionRow
        sx={{ justifyContent: 'space-between' }}
        row={[
          // element first
          // office locations & working languages
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CompanyDetailSubsectionItem
              title="Office Locations"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyOfficeLocations
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />

            <CompanyDetailSubsectionItem
              title="Working Languages"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyWorkingLanguages
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,

          // element second
          // certifications & awards
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CompanyDetailSubsectionItem
              title="Certifications"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyCertifications
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />

            <CompanyDetailSubsectionItem
              title="Awards"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyAwards
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,

          // element third
          // team & clients columnn
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CompanyDetailSubsectionItem
              title="Target Industries"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyTargetIndustries
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />

            <CompanyDetailSubsectionItem
              title="Target Markets"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyTargetMarkets
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,
        ]}
      />

      <Divider />

      {/* row third */}
      <CompanyDetailSubsectionRow
        sx={{ justifyContent: 'space-between' }}
        row={[
          // element first
          // social media
          <CompanyDetailSubsectionItem
            title="Social Media"
            content={(isEditModalOpen, setIsEditModalOpen) => (
              <ComapnySocialMedia
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
              />
            )}
          />,

          // element second
          // services & tech tools
          <Stack spacing={2} sx={{ width: '100%' }}>
            {/* <CompanyDetailSubsectionItem title="Services" content={<CompanyServices />} /> */}
            <CompanyDetailSubsectionItem
              title="Services"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyServices
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />

            <CompanyDetailSubsectionItem
              title="Tech Tools"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyTechTools
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,

          // element third
          // tech platforms & programming languages
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CompanyDetailSubsectionItem
              title="Tech Platforms"
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyTechPlatforms
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />

            <CompanyDetailSubsectionItem
              title="Programming Languages"
              // FIXME: this is put here as an example;
              // continue to implement fully
              content={(isEditModalOpen, setIsEditModalOpen) => (
                <CompanyTechLanguages
                  isEditModalOpen={isEditModalOpen}
                  setIsEditModalOpen={setIsEditModalOpen}
                />
              )}
            />
          </Stack>,
        ]}
      />
    </Stack>
  );
};

export default CompanyDetailSubsection;
