import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import axios from '../../../api/axios';

export type DeleteOneProps = {
  resource: string;
  onSuccess?: () => void;
  onError?: () => void;
};

const useDeleteOne = <T extends unknown>({ resource }: DeleteOneProps) => {
  const callDeleteOne = (id: string): Promise<AxiosResponse<T>> =>
    axios.delete(`${resource}/${id}`);

  return useMutation((variables: { id: string }) => callDeleteOne(variables.id), {});
};

export default useDeleteOne;
