/* eslint-disable max-classes-per-file */
/* eslint-disable lines-between-class-members */
/*
 * I disagree with these rules above as this file is supposed to contain all the utility interfaces
 * and classes for our project. Hence, I believe it is reasonable to disable these rules but this
 * is definitely open to discussion
 */
import { EnumProjectMilestoneStatus, EnumProjectTaskStatus } from 'tdc-web-backend/enums/enums';
import { TimedProjectPitchContentCustomDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import {
  ToolsCarousel,
  TimedProjectCarousel,
  ArticleCard,
  CaseStudyCard,
  CompanyCard,
  PriceCard,
  ServicePackageCard,
  ProjectCardDetails,
} from '../components/carousel/CarouselType';
import CardSizeEnum from './enums/card-size';

export type FileWithBlobData = File & { preview: string };

// Aws returns
export class AwsUploadData {
  presignedUrl: string;
  file: File | FileWithBlobData;
  resourceUrl: string;
  // resourceType: ResourceType;
  resourceType: any;

  public constructor(
    presignedUrl: string,
    file: File | FileWithBlobData,
    resourceUrl: string,
    // resourceType: ResourceType,
    resourceType: any,
  ) {
    this.presignedUrl = presignedUrl;
    this.file = file;
    this.resourceUrl = resourceUrl;
    this.resourceType = resourceType;
  }
}

export interface AwsPresignedData {
  banner?: AwsUploadData | null;
  logo?: AwsUploadData | null;
  profile?: AwsUploadData | null;
  carousel?: Array<AwsUploadData>;
  attachments?: Array<AwsUploadData>;
}

// Backend structure
export class FileData {
  uri?: string;
  filename?: string | null;
  meta?: object | null;
  altText?: string | null;
  preview?: string;

  public constructor(init?: Partial<FileData>) {
    Object.assign(this, init);
  }
}

export class VideoTimestamp {
  time?: number;
  title?: string;

  public constructor(init?: Partial<VideoTimestamp>) {
    Object.assign(this, init);
  }
}

export class VideoData {
  uri?: string;
  filename?: string;
  timestamps?: Array<VideoTimestamp>;

  public constructor(init?: Partial<VideoData>) {
    Object.assign(this, init);
  }
}

export type FileDataWithUploadData = FileData & { toUpload?: AwsUploadData } & { preview?: string };
export type VideoDataWithUploadData = VideoData & { toUpload?: AwsUploadData };

export class ImagesUrlData {
  banner?: FileDataWithUploadData | null;
  logo?: FileDataWithUploadData | null;
  profile?: FileDataWithUploadData | null;
  carousel?: Array<FileDataWithUploadData> | null;

  public constructor(init?: Partial<ImagesUrlData>) {
    Object.assign(this, init);
  }
}

export class FilesUrlData {
  attachments?: Array<FileDataWithUploadData> | null;
  buyerAttachments?: Array<FileDataWithUploadData> | null;
  sellerAttachments?: Array<FileDataWithUploadData> | null;
  buyerPaymentInvoice?: Array<FileDataWithUploadData> | null;
  sellerPaymentInvoice?: Array<FileDataWithUploadData> | null;
  videoPitch?: VideoDataWithUploadData | null;

  public constructor(init?: Partial<FilesUrlData>) {
    Object.assign(this, init);
  }
}

export interface CaseStudiesCarousel {
  type: CardSizeEnum;
  companyName: string;
  caseStudyTitle: string;
  slug: string;
  banner: string;
  companyLogo: string;
  services: string[];
  onClick: (slug: string) => void;
}

export interface ServicePackageI {
  type: CardSizeEnum;
  title: string;
  companyName: string;
  slug: string;
  banner: string;
  companyLogo: string;
  services: string[];
  price: string | number;
  onClick: (slug: string) => void;
}

// TODO SEPARATE TYPES FILES
export type CarouselUnionType =
  | ProjectCardDetails[]
  | ToolsCarousel[]
  | TimedProjectCarousel[]
  | ServicePackageI[]
  | ArticleCard[]
  | CaseStudyCard[]
  | CompanyCard[]
  | PriceCard[]
  | ServicePackageCard[]
  | CaseStudiesCarousel[];

export interface HomepageMarketplaceTab {
  img?: string;
  title: string;
  body: string;
  subtext: string;
  path: string;
}

export class NavigationColorSchema {
  bgColor: string;

  textColor: string;

  searchBgColor: string;

  searchBorderColor: string;

  constructor(
    bgColor: string,
    textColor: string,
    searchBgColor: string,
    searchBorderColor: string,
  ) {
    this.bgColor = bgColor;
    this.textColor = textColor;
    this.searchBgColor = searchBgColor;
    this.searchBorderColor = searchBorderColor;
  }

  static get default() {
    return new NavigationColorSchema('#fff', 'primary.main', '#F6F6F6', '#F6F6F6');
  }

  static get dark() {
    return new NavigationColorSchema(
      'primary.main',
      'primary.contrastText',
      'primary.main',
      'secondary.main',
    );
  }
}

export interface IContractListItem {
  publicationStatus: string;
  body: {
    description: string;
  };
  signedOn: string;
  signedStatus: string;
  completionDate?: string;
  completionStatus?: string;
  isCurrentlyActiveContract: boolean;
  createdOn?: Date;
  id: string;
}

export interface IUpcomingDeadline {
  id: string;
  name: string;
  end: Date;
  status?: EnumProjectMilestoneStatus | EnumProjectTaskStatus;
  type: string;
}

export type PitchesPanelData = {
  [key: string]: TimedProjectPitchContentCustomDto[];
};
