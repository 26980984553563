import { Box, Stack, Typography } from '@mui/material';

interface ICompetitor {
  name: string;
  description?: string;
}

const PreviewCompetitors = ({ competitors }: { competitors: ICompetitor[] }) => (
  <Stack>
    {competitors.map((competitor) => {
      const hasDescription = competitor?.description;
      return (
        <Box
          sx={{ padding: '12px', margin: '12px 0', borderLeft: '1px solid #E3A900' }}
          key={competitor.name}
        >
          <Typography
            variant="body1"
            color="primaryDark.600"
            sx={{
              marginBottom: hasDescription ? '8px' : '0px',
              fontSize: '16px',
              fontFamily: 'Gellix-SemiBold',
              letterSpacing: '0.05em',
            }}
          >
            {competitor.name}
          </Typography>
          {hasDescription && (
            <Typography variant="inherit" color="primaryDark.600" sx={{ fontSize: '14px' }}>
              {competitor.description}
            </Typography>
          )}
        </Box>
      );
    })}
  </Stack>
);

export default PreviewCompetitors;
