import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { FlagDto } from 'tdc-web-backend/flags/schema';
import Button from '../../../../../components/button/Button';
import CustomReferenceField from '../../../../../components/custom-fields/CustomReferenceField';
import { checkForEntityType } from '../project-details/FlagDataGrid';

export interface FlagDetailsModalProps {
  flagData: FlagDto;
}

const FlagDetailsModal = ({ flagData }: FlagDetailsModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const entity = checkForEntityType(flagData);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        <SummarizeIcon />
      </IconButton>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Flag details
                  </Typography>
                </Stack>
                <Stack spacing={2}>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 600,
                    }}
                  >
                    Flag for:
                    <CustomReferenceField
                      sx={{
                        fontSize: '1em',
                        fontWeight: 500,
                        mb: 3,
                      }}
                      resource={`${entity}s`}
                      id={entity && flagData[entity]}
                      displayName="name"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 600,
                    }}
                  >
                    Summary:
                    <Typography
                      sx={{
                        fontSize: '1em',
                        fontWeight: 500,
                      }}
                    >
                      {flagData.summary}
                    </Typography>
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="contained"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};
export default FlagDetailsModal;
