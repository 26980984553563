import { Checkbox, FormControlLabel } from '@mui/material';
import { useCustomInputContext } from '../CustomInputContext';
import { ReactComponent as CheckboxChecked } from '../../../assets/icons/layout-icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from '../../../assets/icons/layout-icons/CheckboxUnchecked.svg';

const CustomInputCheckbox = () => {
  const { field, label, disabled } = useCustomInputContext();

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          checked={field.value}
          disabled={disabled}
          icon={<CheckboxUnchecked style={{ width: 16, height: 16 }} />}
          checkedIcon={<CheckboxChecked style={{ width: 16, height: 16 }} />}
          sx={{
            padding: '4px',
          }}
        />
      }
      label={label}
      sx={{
        width: 'fit-content',
        ml: '-4px',
        '.MuiFormControlLabel-label': {
          typography: 'bodySemiBold4',
          color: 'primaryDark.500',
          pl: '4px',
        },
      }}
    />
  );
};

export default CustomInputCheckbox;
