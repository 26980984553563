import {
  Card, Divider, Stack, SxProps, Theme, Typography,
} from '@mui/material';
import EndToEndDetailScreenTypeEnum from '../../../utils/enums/end-to-end-detail-screen-type';

export interface MilestoneBudgetComponentProps {
  detailScreenType: EndToEndDetailScreenTypeEnum,
  price?: string | number;
  start: string;
  end: string;
  sx?: SxProps<Theme> | undefined,
}

export function MilestoneBudgetComponent({
  detailScreenType, price, start, end, sx,
}: MilestoneBudgetComponentProps) {
  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid #C5C7C3',
        borderRadius: '1rem',
        ...sx,
      }}
    >
      <Stack sx={{ padding: '1em' }} spacing={2}>
        {
          detailScreenType !== EndToEndDetailScreenTypeEnum.Task
          && (
            <>
              <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '.875em', fontWeight: 600 }}>Budget</Typography>
                <Typography sx={{ fontSize: '.875em' }}>{price}</Typography>
              </Stack>
              <Divider />
            </>
          )
        }
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '.875em', fontWeight: 600 }}>Start date</Typography>
          <Typography sx={{ fontSize: '.875em' }}>{start}</Typography>
        </Stack>
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '.875em', fontWeight: 600 }}>End date</Typography>
          <Typography sx={{ fontSize: '.875em' }}>{end}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
