import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Stack } from '@mui/system';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { Dispatch, SetStateAction } from 'react';
import CompanyAwardsEditModal from '../edit/CompanyAwardsEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyAwardsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyAwards = ({ isEditModalOpen, setIsEditModalOpen }: CompanyAwardsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.awards?.map((award: string) => (
        <CustomChip
          key={award}
          tooltipTitle={award}
          label={award && truncate(award as string, chipLabelTruncateValue as number)}
          sx={{
            width: 'fit-content',
            textTransform: 'capitalize',
          }}
        />
      ))}

      {companyData && (
        <CompanyAwardsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyAwards;
