import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TabPanelContent from './components/TabPanelContent';
import privacyPolicy from './contents/PrivacyPolicy';
import { onLinkClickArray } from './utils';
import cookiePolicy from './contents/CookiePolicy';
import sponaDigitalContractTerms from './contents/SponaDigitalContractTerms';

const TermsOfServicePageOutlet = () => {
  const location = useLocation();

  const renderAppropriateContent = () => {
    if (location.pathname === '/terms-of-service/privacy-policy') {
      return privacyPolicy.map(({ title, introduction, content }, index) => (
        <TabPanelContent
          key={title}
          title={title}
          introduction={introduction}
          content={content}
          onLinkClickArray={onLinkClickArray}
        />
      ));
    }

    if (location.pathname === '/terms-of-service/spona-digital-contract-terms') {
      return sponaDigitalContractTerms.map(({ title, introduction, content }, index) => (
        <TabPanelContent
          key={title}
          title={title}
          introduction={introduction}
          content={content}
          onLinkClickArray={onLinkClickArray}
        />
      ));
    }

    if (location.pathname === '/terms-of-service/cookie-policy') {
      return cookiePolicy.map(({ title, introduction, content }, index) => (
        <TabPanelContent
          key={title}
          title={title}
          introduction={introduction}
          content={content}
          onLinkClickArray={onLinkClickArray}
        />
      ));
    }
  };

  return <Stack spacing={8}>{renderAppropriateContent()}</Stack>;
};

export default TermsOfServicePageOutlet;
