import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { secondaryBlue } from '../../color';

type Props = {
  rating: number | string;
};

const StarBadge = ({ rating }: Props) => (
  <Stack direction="row" gap={0.75} alignItems="center">
    <StarIcon fill={secondaryBlue[700]} width={17} />
    <Typography color="secondaryBlue.700" variant="heading4">
      {rating}
    </Typography>
  </Stack>
);

export default StarBadge;
