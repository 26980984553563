import { Checkbox } from '@mui/material';

export interface CustomCheckboxProps {
  checked: boolean;
  className?: string;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
}

// TODO: delete this component and use the newly created CustomizedCheckbox one
export function CustomCheckbox({ onChange, checked, onClick, className }: CustomCheckboxProps) {
  return (
    <Checkbox
      className={className || undefined}
      onChange={onChange}
      onClick={onClick}
      checked={checked}
    />
  );
}
