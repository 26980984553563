import {
  ExtendButtonBase, Grid, Tab, TabTypeMap,
} from '@mui/material';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { TabPanel } from '@mui/lab';
import TabChip from '../../../../components/custom-table/TabChip';
import ContractCardItem from './ContractCardItem';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';

type ContractTabProps = {
  resultCount: number
  selectedTab: string
  value: string
  label: string
  [x: string]: any
}

type ContractTabPanelProps = {
  data: ContractDto[] | undefined;
  value: string;
  onCardClick: (open: boolean) => void;
}

export const ContractTab = ({
  resultCount, selectedTab, value, label, ...props
}: ContractTabProps) => (
  <Tab
    {...props}
    value={value}
    disableRipple

    sx={{
      p: 0,
      mx: 1,
      minHeight: '2rem',
      minWidth: 0,
      '&.Mui-selected': {
        p: 0,
      },
    }}
    icon={(
      <TabChip
        resultCount={resultCount}
        value={value}
        selectedTab={selectedTab}
        label={label}
      />
    )}
    {...props}
  />
);

const ContractTabPanel = ({
  value,
  data,
  onCardClick,
}: ContractTabPanelProps) => (
  <TabPanel
    value={value}
    sx={{
      overflow: 'hidden',
      bgcolor: 'white',
      p: 0,
      pt: 0,
      position: 'relative',
      minHeight: '10rem',
    }}
  >
    <Grid container spacing={3} pt={4}>
      {data?.map((item, index) => (
        <Grid key={item.id} item xs={4}>
          <ContractCardItem
            item={item}
            onCardClick={(open) => onCardClick(open)}
          />
        </Grid>
      ))}
    </Grid>
    {!data?.length && <CustomNoRowsOverlay />}
  </TabPanel>
);

export default ContractTabPanel;
