import { Accordion, AccordionSummary, Stack, Typography, AccordionDetails } from '@mui/material';
import { useEffect, useState } from 'react';
import { mapIndustry, mapProjectSize, mapService, mapCountry } from 'tdc-web-backend/enums/enums';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../enums';
import EntityTypeEnum from '../../utils/enums/entity-type';
import mapIconService from '../../utils/icon-maps/service-icon-maps';
import ContentTypeEnum from '../../utils/enums/content-type';
import CustomChip from '../custom-chip/CustomChip';
import mapIconTechTool from '../../utils/icon-maps/tech-tool-icon-map';
import mapIconTechLanguage from '../../utils/icon-maps/tech-language-icon-map';
import mapIconTechPlatform from '../../utils/icon-maps/tech-platform-icon-map';

export interface CustomAccordionProps {
  entityType: EntityTypeEnum;
  entityArray?: string[];
  entity?: string;
  contentType?: ContentTypeEnum;
  bgcolor?: string;
  showIcon?: boolean;
  chipRoundedCorners?: boolean;
  chipBackgroundColor?: string;
}

const CustomAccordion = ({
  entityType,
  entityArray,
  entity,
  contentType,
  bgcolor,
  showIcon,
  chipRoundedCorners,
  chipBackgroundColor,
}: CustomAccordionProps) => {
  const [rotateChevron, setRotateChevron] = useState<boolean>(false);
  const handleRotate = () => setRotateChevron(!rotateChevron);

  const [label, setLabel] = useState<string | null>(null);
  const [iconMap, setIconMap] = useState<Map<string, string> | null>(null);
  const [entityMap, setEntityMap] = useState<Map<string, string> | null>(null);

  const rotate = rotateChevron ? 'rotate(90deg)' : 'rotate(0)';

  const defineIconService = () => {
    // TODO: apply setIconMap() when it gets implemented where it is necessary
    switch (entityType) {
      case EntityTypeEnum.Service:
        setLabel('Expertise');
        setIconMap(mapIconService);
        setEntityMap(mapService);
        break;
      case EntityTypeEnum.Industry:
        // setIconMap()
        setLabel('Industry focus');
        setEntityMap(mapIndustry);
        break;
      case EntityTypeEnum.TechLanguage:
        setLabel('Tech languages');
        setIconMap(mapIconTechLanguage);
        setEntityMap(mapTechLanguage);
        break;
      case EntityTypeEnum.TechPlatform:
        setLabel('Tech platforms');
        setIconMap(mapIconTechPlatform);
        setEntityMap(mapTechPlatform);
        break;
      case EntityTypeEnum.TechTool:
        setLabel('Tech tools');
        setIconMap(mapIconTechTool);
        setEntityMap(mapTechTool);
        break;
      case EntityTypeEnum.Country:
        setLabel('Geography focus');
        // setIconMap()
        setEntityMap(mapCountry);
        break;
      case EntityTypeEnum.ProjectSize:
        setLabel('Project size');
        // setIconMap()
        setEntityMap(mapProjectSize);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    defineIconService();
  }, []);

  return (
    <Accordion
      elevation={0}
      sx={{
        pt: 2,
        width: '100%',
        bgcolor: bgcolor ?? 'grey.200',
        '& .MuiAccordionSummary-content': {
          m: 0,
        },
        '&.MuiAccordion-root::before': {
          content: 'unset',
        },
      }}
    >
      <AccordionSummary
        onClick={handleRotate}
        sx={{
          '& .MuiAccordionSummary-content.Mui-expanded': {
            m: 0,
          },
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
        }}
      >
        <Stack spacing={1.5} direction="column" width="100%" sx={{ mt: 1 }}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ px: 1 }}
          >
            <Typography
              sx={{
                color: 'grey.600',
                fontSize: '0.95rem',
                fontWeight: 'bold',
                flexGrow: 1,
              }}
            >
              {label}
            </Typography>

            <ChevronRightIcon
              sx={{
                visibility: (entityArray || [])?.length > 2 ? 'visible' : 'hidden',
                color: 'grey.600',
                pt: 0.5,
                transform: rotate,
                transition: 'all 0.2s linear',
              }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
              {entityArray
                ?.map((element) => (
                  <CustomChip variant="outlined" label={entityMap?.get(element)} />
                ))
                .slice(0, 2)}

              {entity && <CustomChip variant="outlined" label={entityMap?.get(entity)} />}
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
          {entityArray
            ?.map((element) => <CustomChip variant="outlined" label={entityMap?.get(element)} />)
            .slice(2)}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
