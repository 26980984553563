/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { EnumNotificationSubcategory } from 'tdc-web-backend/enums/enums';
import { NotificationDataDto, NotificationDto } from 'tdc-web-backend/notifications/schemas';

interface BannerNotification extends NotificationDto {
  route?: string | boolean;
  customText?: string;
}

const SubCategory = EnumNotificationSubcategory;

const bannerSubcategoryMap = new Map<
  EnumNotificationSubcategory,
  (data: NotificationDataDto) => {
    route: string | boolean;
    customText?: string;
  }
>([
  [SubCategory.BannerProjectReady, (data) => ({ customText: 'click here', route: `./contract` })],
  [
    SubCategory.BannerOtherReviewedContract,
    (data) => ({ customText: 'Click here', route: `./contract/${data.ids?.contractId}` }),
  ],
  [
    SubCategory.BannerOtherSubmittedContract,
    (data) => ({ customText: 'Click here', route: `./contract/${data.ids?.contractId}` }),
  ],
  [
    SubCategory.BannerOtherSubmittedAnnex,
    (data) => ({ customText: 'Click here', route: `./contract/${data.ids?.contractId}` }),
  ],
  [
    SubCategory.BannerOtherReviewedAnnex,
    (data) => ({ customText: 'Click here', route: `./contract/${data.ids?.contractId}` }),
  ],
  [SubCategory.BannerRecurringMilestone, (data) => ({ customText: 'Click here', route: true })],

  [
    SubCategory.BannerTimedProjectRejected,
    (data) => ({ customText: 'Click here', route: `./${data.ids?.projectId}/edit` }),
  ],
  [
    SubCategory.BannerTimedProjectDraftSaved,
    (data) => ({ customText: 'Click here', route: `../${data.ids?.projectId}/edit` }),
  ],
  [
    SubCategory.BannerTimedProjectEditRejected,
    (data) => ({ customText: 'Click here', route: `../${data.ids?.projectId}/edit` }),
  ],
  [
    SubCategory.BannerPitchEditRejected,
    (data) => ({ customText: 'Click here', route: `./${data.ids?.pitchId}/edit` }),
  ],
  [
    SubCategory.BannerPitchRejected,
    (data) => ({ customText: 'Click here', route: `./${data.ids?.pitchId}/edit` }),
  ],
  [
    SubCategory.BannerPitchHired,
    (data) => ({ customText: 'Click here', route: `/dashboard/end-to-end` }),
  ],

  [SubCategory.BannerCaseStudyApproved, (data) => ({ customText: 'Click here', route: true })],
  [
    SubCategory.BannerCaseStudyRejected,
    (data) => ({ customText: 'Click here', route: `./edit/${data.ids?.caseStudyId}` }),
  ],
  [
    SubCategory.BannerCaseStudyEditRejected,
    (data) => ({ customText: 'Click here', route: `./edit/${data.ids?.caseStudyId}` }),
  ],
  [SubCategory.BannerBuyerProfileApproved, (data) => ({ route: `/faq`, customText: 'here' })],
]);

const projectSubcategories = [
  SubCategory.ApproveContract,
  SubCategory.SubmittedContract,
  SubCategory.SubmittedAnnex,
  SubCategory.ReviewContract,
  SubCategory.ReviewAnnex,
  SubCategory.ContractReady,
  SubCategory.AnnexReady,
  SubCategory.ApproveAnnex,
  SubCategory.CancelContract,
  SubCategory.TerminateContract,
  SubCategory.Unflag,
  SubCategory.PaidMilestone,
  SubCategory.PaymentDue,
  SubCategory.PaymentOverdue,
];

const milestoneSubcategories = [
  SubCategory.ApproveMilestone,
  SubCategory.CompleteMilestone,
  SubCategory.CreateTask,
  SubCategory.DeleteTask,
  SubCategory.Flag,
];

const getNotificationRoute: (notification: NotificationDto) => string = (
  notification: NotificationDto,
) => {
  if (projectSubcategories.includes(notification.subcategory))
    return `/dashboard/end-to-end/project/${notification.data.ids?.projectId as string}`;
  if (milestoneSubcategories.includes(notification.subcategory))
    return `/dashboard/end-to-end/milestone/${notification.data.ids?.milestoneId as string}`;
  return ' ';
};

export default getNotificationRoute;

export const getBannerNotificationData = (notification: BannerNotification) => {
  const bannerActions = bannerSubcategoryMap.get(notification.subcategory);
  const bannerObject = bannerActions?.(notification.data);
  const customText = bannerObject?.customText;
  if (customText && notification?.body?.includes(customText)) {
    notification.body = notification.body.split(customText)[0];
    const route = bannerObject?.route;
    notification.route = route;
    notification.customText = customText;
  }
  return notification;
};
