import { Typography, Stack, Box, Theme, SxProps, useMediaQuery } from '@mui/material';
import { LocationI as LocationProps } from '../utils';
import theme from '../../../../theme';

const width = { xs: '100%', sm: '60%', md: '90%', lg: '95%' };

const Location = ({ state, address, img, sx, alt }: LocationProps & { sx?: SxProps<Theme> }) => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Stack spacing={1.5} alignItems={{ xs: 'center', lg: 'normal' }} sx={{ width: '100%', ...sx }}>
      {isLessThanLarge ? (
        <Box component="img" src={img} alt={alt} sx={{ borderRadius: '15px', width }} />
      ) : (
        <Box component="img" src={img} alt={alt} sx={{ borderRadius: '15px', width }} />
      )}

      <Stack direction="row" alignItems="start" justifyContent="space-between" sx={{ width }}>
        <Typography variant="publicHeading5">{state}</Typography>

        {address}
      </Stack>
    </Stack>
  );
};

export default Location;
