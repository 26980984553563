import { Avatar, Card, Divider, Link, Stack, Typography } from '@mui/material';
import { mapCompanySize, mapIndustry, mapService } from 'tdc-web-backend/enums/enums';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useParams } from 'react-router-dom';
import { HTMLContent } from '@tiptap/react';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { TabContext } from '@mui/lab';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import MultipleChipFields from './MultipleChipFields';
import CompanyLocationInfo from './CompanyLocationInfo';
import ProjectTablePanelDataGrid from '../../end-to-end/projects/project-list/ProjectTablePanelDataGrid';
import { ProjectListTab } from '../../end-to-end/projects/project-list/AdminProjectList';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import Editor from '../../../../components/editor/Editor';
import CompanyClientsList from './CompanyClientsList';
import { countriesSorted } from '../../../../utils/helpers';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';

const CompanyDetails = () => {
  const params = useParams();

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: params.companyId,
    refetchOnWindowFocus: false,
  });
  const { data: projectResponse } = useGetMany<ProjectDto>({
    resource: `/projects?company=${params.companyId}`,
  });
  const { data: companyClientResponse } = useGetMany<CompanyClientDto>({
    resource: `/company-client?company=${params.companyId}`,
  });

  const projectData = projectResponse?.data.results;
  const companyClientData = companyClientResponse?.data.results;

  return (
    <Stack spacing={3} direction="column">
      <Stack
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          mt: 6,
          mb: 0,
          pb: 0,
        }}
      >
        <Stack
          spacing={6}
          width="30%"
          height="100%"
          sx={{
            mb: 0,
            pb: 0,
            mt: 5,
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={0}>
              <Stack direction="column">
                <Stack direction="row" spacing={3}>
                  <Avatar alt="Avatar" src={companyData?.avatar} />
                  <Typography variant="h3">{companyData?.name}</Typography>
                </Stack>
                <Typography variant="caption" sx={{ mt: 1 }}>
                  <Link href={companyData?.url} target="_blank" underline="none">
                    {companyData?.url}
                  </Link>
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  pt: 3,
                }}
              ></Typography>
              {companyData && (
                <Editor
                  items={[]}
                  isEditable={false}
                  content={companyData?.description as HTMLContent}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          width="30%"
          height="100%"
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Stack height="100%">
            <Card
              elevation={2}
              sx={{
                borderRadius: '16px',
                height: '100%',
              }}
            >
              <MultipleChipFields
                enums={mapIndustry}
                values={companyData?.targetIndustries ?? []}
                label="Target industry"
              />
              <Divider />
              <MultipleChipFields
                enums={mapService}
                values={companyData?.services ?? []}
                label="Services"
              />
              <Divider />
              <MultipleChipFields
                enums={mapCompanySize}
                values={companyData?.targetCompanySizes ?? []}
                label="Target company sizes"
              />
              <Divider />
              <MultipleChipFields
                enums={countriesSorted()}
                values={companyData?.targetCountries ?? []}
                label="Target countries"
              />
              <Divider />
            </Card>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          width="30%"
          height="100%"
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Stack height="100%">
            <CompanyLocationInfo data={companyData as CompanyDto} />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ pt: 4 }}
          spacing={2}
          width="100%"
        >
          <Stack width="30%">
            <Typography
              sx={{
                fontSize: '.875em',
                fontWeight: 600,
                pb: 2,
              }}
            >
              Company clients
            </Typography>
            <Card
              sx={{
                borderRadius: '16px',
                height: '100%',
              }}
              elevation={2}
            >
              <CompanyClientsList rows={companyClientData ?? []} />
            </Card>
          </Stack>
          <Stack width="65%">
            <TabContext value={ProjectListTab.All}>
              <Typography
                sx={{
                  fontSize: '.875em',
                  fontWeight: 600,
                  pb: 2,
                }}
              >
                Company projects
              </Typography>
              <ProjectTablePanelDataGrid
                value={ProjectListTab.All}
                rows={projectData ?? []}
                tabIndex={0}
                navigateRoute="../../end-to-end/projects/"
              />
            </TabContext>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyDetails;
