import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { EnumCountry, mapCountry } from 'tdc-web-backend/enums/enums';
import { Box } from '@mui/system';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { primaryLight } from '../../../../../../../color';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';

interface CompanyTargetMarketsEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTargetMarketsEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTargetMarketsEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();

  const methods = useForm<Pick<CompanyDto, 'targetCountries'>>({
    mode: 'onChange',
    defaultValues: {
      targetCountries: companyData?.targetCountries ?? ([] as EnumCountry[]),
    },
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Pick<CompanyDto, 'targetCountries'>) => {
    update(
      { id: companyData?.id as string, data },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      confirmButtonText="Save"
      title="Target markets"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      onCancel={() => setIsEditModalOpen(false)}
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      onConfirm={methods.handleSubmit(onSubmit)}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      message={
        <FormProvider {...methods}>
          <Box component="form">
            <CustomInput
              required
              input={<CustomInput.Autocomplete />}
              choices={mapCountry}
              name="targetCountries"
              label="Target Markets"
              multiple
              placeholder="Select target markets"
              sx={{ width: '100%' }}
            />
          </Box>
        </FormProvider>
      }
    />
  );
};
export default CompanyTargetMarketsEditModal;
