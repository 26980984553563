import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import routes from './routes';
import instance from './api/axios';
import 'react-alice-carousel/lib/alice-carousel.css';
import './fonts.css';
import { NotificationProvider } from './utils/context/notification';
import MetaTags from './components/seo/MetaTags';
import CookiesBanner from './components/cookies-banner-modal/CookiesBanner';
import FreshchatWidget from './components/freshchatWidget/FreshchatWidget';
import { useState } from 'react';

const queryClient = new QueryClient();

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [bannerOpen, setBannerOpen] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, [pathname]);

  const userData = localStorage.getItem('userData');
  const agreementDate = JSON.parse(userData as string)?.agreementDate;

  const accessToken = localStorage.getItem('AccessToken');

  const parsedUserData = JSON.parse(userData as string);
  const roles = parsedUserData?.roles || [];
  const isSponaAdmin = roles.includes('admin');

  const routing = useRoutes(routes({ accessToken, agreementDate, isSponaAdmin }));

  useEffect(() => {
    if (!accessToken) return;
    instance.defaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';

    // WebsocketManager.getInstance().connect(accessToken);
    // WebsocketManager.getInstance()
    // eslint-disable-next-line max-len
    //   .connect('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmZTUwYzMwMC1jZmEyLTExZWMtYmUwOC0zM2FjZjgzMzhhY2MiLCJ1c2VyQ2xhaW1zIjpbIkZvbyIsIlVzZXJfVmVyaWZpZWQiXSwiY29tcGFuaWVzIjp7ImZlZWIwNzMwLWNmYTItMTFlYy1iZTA4LTMzYWNmODMzOGFjYyI6eyJjb21wYW55Q2xhaW1zIjpbIkZvbyJdLCJjb21wYW55VXNlckNsYWltcyI6WyJGb28iXX19LCJ0b2tlblR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE2NTQwODUzMTksImV4cCI6MjY1NDA4NTkxOX0.N8epjvVdRY4EVVZ8tdbaHuGh4hmjZOfTGrDLwowOJ5s');
  }, [accessToken]);

  useEffect(() => {
    if (pathname.includes('dashboard') || pathname.includes('admin')) {
      if (!agreementDate) navigate('/login');
    }
  }, [agreementDate, pathname]);

  const metaTags = {
    description:
      'If you want to streamline your digital project collaboration, management, and financing within a single platform, see what Spona can do for you.',
  };

  return (
    <>
      <MetaTags description={metaTags.description} />
      <NotificationProvider>
        <QueryClientProvider client={queryClient}>
          {routing}
          <FreshchatWidget />
          <CookiesBanner bannerOpen={bannerOpen} setBannerOpen={setBannerOpen} />
        </QueryClientProvider>
      </NotificationProvider>
    </>
  );
}

export default App;
