import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useEffect, useState } from 'react';
import BaseTextInput from './BaseTextInput';
import { formatAmount } from '../../utils/helpers';

type Props = {
  name: string;
  required?: boolean;
  initialValue: number;
  min?: number;
  max?: number;
  onBlur?: (value: number) => void;
  onChange?: (value: number) => void;
} & TextFieldProps;

const ControlledContractNumberInput = ({
  name,
  required,
  initialValue,
  min,
  max,
  onBlur,
  onChange,
  ...props
}: Props) => {
  const { control, setValue, getValues, formState, setError, trigger } = useFormContext();

  const [currentField, setCurrentField] = useState<ControllerRenderProps<FieldValues, string>>();

  useEffect(() => {
    if (initialValue) {
      const newAmount = formatAmount(initialValue?.toString());
      if (currentField && newAmount) {
        currentField.onChange(newAmount);
      }
    }
  }, [initialValue]);

  const inputName = `${name}-input`;
  return (
    <Controller
      name={inputName}
      control={control}
      rules={{
        required: required ? 'This field is required' : false,
        validate: (value) => {
          const parsed = parseFloat(value.replaceAll(',', ''));
          if (min !== undefined && parsed <= min) return `Value should be greater than ${min}`;
          if (max !== undefined && parsed >= max) return `Value should be smaller than ${max}`;
          return true;
        },
      }}
      render={({ field }) => {
        if (!currentField) {
          setCurrentField(field);
        }
        return (
          <BaseTextInput
            {...field}
            {...props}
            showErrorAlways
            field={{
              ...field,
              name: inputName,
              onBlur: () => (onBlur ? onBlur(getValues(name)) : null),
              onChange: (e) => {
                const newAmount = formatAmount(e.target.value, true);
                if (newAmount != null) {
                  const parsed = parseFloat(e.target.value.replaceAll(',', ''));
                  setValue(name, parsed);
                  if (onChange) {
                    onChange(parsed);
                  }

                  field.onChange(newAmount);
                }

                if (formState.errors[inputName]) {
                  setError(name, formState.errors[inputName]);
                  trigger([name, inputName]);
                }
              },
            }}
            name={inputName}
            required={required}
            sx={{ ...props.sx }}
          />
        );
      }}
    />
  );
};

export default ControlledContractNumberInput;
