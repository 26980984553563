import {
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box } from '@mui/system';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../../components/button/Button';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import InvoiceFileUpload from '../../../EndToEnd/Payments/PaymentDetails/InvoiceFileUpload';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';

type InvoiceModalProps = {
  data: MilestoneDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const InvoiceUploadModal = ({ data, isOpen, setIsOpen }: InvoiceModalProps) => {
  const [file, setFile] = useState<string | null>(null);
  const [dueDateCheckboxChecked, setDueDateCheckboxChecked] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string>('');
  const { mutate: upload } = useCreate({ resource: `/milestones/${data?.id}/buyer-invoice` });
  const refresh = useRefresh();

  const formProviderData = useForm({
    defaultValues: {
      dueDate: new Date(),
    },
  });

  const handleUploadInvoice = () => {
    upload(
      {
        invoice: file,
        ...(dueDateCheckboxChecked && { dueDate: formProviderData.getValues('dueDate') }),
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          setFile(null);
          setFileName('');
          refresh();
        },
      },
    );
  };
  const handleDeleteFile = () => {
    setFile(null);
    setFileName('');
  };

  const handlSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDueDateCheckboxChecked(event.target.checked);
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack
        sx={{
          width: '40%',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Paper
            sx={{
              padding: '2em',
            }}
          >
            <Stack spacing={2}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '1.25em',
                    fontWeight: 600,
                    mt: 3,
                  }}
                >
                  Attach the invoice
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }} spacing={1}>
                <InvoiceFileUpload setFile={setFile} setFileName={setFileName} />
                {fileName && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 3,
                      mt: 1,
                      textAlign: 'center',

                      color: '#5C5F5C',
                      border: '1px solid #A9ACA8',
                      borderRadius: 1,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      width="50%"
                      gap={1}
                    >
                      <Box
                        sx={{
                          p: 1,
                          background: '#E1E3DF',
                          borderRadius: 1,
                        }}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fontSize: '1rem' }} />
                      </Box>
                      <Typography variant="caption">{fileName}</Typography>
                    </Stack>
                    <IconButton onClick={handleDeleteFile}>
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={dueDateCheckboxChecked} onChange={handlSwitchChange} />}
                  label="Set due date"
                />
              </FormGroup>
              {dueDateCheckboxChecked && (
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 600,
                      mt: 0,
                      mb: 1,
                    }}
                  >
                    Invoice due date:
                  </Typography>
                  <FormProvider {...formProviderData}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <ControlledDateInput name="dueDate" />
                    </LocalizationProvider>
                  </FormProvider>
                </Stack>
              )}
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <Button
                  variant="text"
                  onButtonClick={() => setIsOpen(false)}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onButtonClick={handleUploadInvoice}
                  disabled={!fileName}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </LocalizationProvider>
      </Stack>
    </Modal>
  );
};

export default InvoiceUploadModal;
