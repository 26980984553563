import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Typography, Avatar, Stack, Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import CompanyOfficeLocationsEditModal from '../edit/CompanyOfficeLocationsEditModal';
import CompanyClientsEditModal from '../edit/CompanyClientsEditModal';

interface CompanyClientsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyClients = ({ isEditModalOpen, setIsEditModalOpen }: CompanyClientsProps) => {
  const { companyClients } = useSellerHomescreenContext();

  return (
    <Stack direction="column" gap={1} alignItems="flex-start" sx={{ maxWidth: '190px' }}>
      {companyClients &&
        companyClients.length > 0 &&
        companyClients.slice(0, 3).map((client: CompanyClientDto) => (
          <Stack key={client.id} direction="row" alignItems="center" spacing={1}>
            {' '}
            <Avatar
              src={client.avatar as unknown as string}
              alt={client.name}
              sx={{ width: 20, height: 20 }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1rem',
                letterSpacing: 'normal',
                color: 'rgb(79, 87, 110)',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {client.name}
            </Typography>
          </Stack>
        ))}
      <Stack direction="row">
        {companyClients && companyClients?.length > 3 && (
          <Box
            sx={{
              bgcolor: 'rgb(227, 219, 252)',
              borderRadius: '50%',
              width: 20,
              height: 20,
            }}
          />
        )}
        {companyClients && companyClients?.length > 3 && (
          <Typography paddingLeft="3px" sx={{ color: 'rgb(79, 87, 110)' }} variant="bodySemiBold1">
            +{companyClients && companyClients.length - 3}
          </Typography>
        )}
      </Stack>

      {isEditModalOpen && (
        <CompanyClientsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyClients;
