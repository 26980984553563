import { Button, Icon } from '@mui/material';
import { EditorItem } from './EditorItem';

interface MenuButtonProps {
  editorItem: EditorItem;
}

const MenuButton = ({ editorItem }: MenuButtonProps) => (
  <Button
    sx={{
      minWidth: 44, padding: '7px 5px', color: editorItem.isActive ? '#6D51F6 !important' : '#384572', '&:hover': { backgroundColor: 'transparent', color: '#AA96F8' },
    }}
    size="small"
    disableElevation
    variant="text"
    aria-label={editorItem.label}
    onClick={() => { editorItem.action(); }}
  >
    <Icon sx={{ fontSize: '1.2rem' }}>{editorItem.iconName}</Icon>
  </Button>
);

export default MenuButton;
