import {
  Box,
  CircularProgress as MuiCircularProgress,
  circularProgressClasses,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';

interface CircularProgressProps {
  size?: number;
  thickness?: number;
  sx?: SxProps<Theme>;
  position?: 'relative' | 'absolute';
  color?: string;
  value: number;
}

const CircularProgress = ({
  size = 6,
  thickness = 3.5,
  sx,
  position,
  color,
  value,
}: CircularProgressProps) => (
  <Box sx={{ position: 'relative' }}>
    <Stack alignItems="center" justifyContent="center">
      <MuiCircularProgress
        variant="determinate"
        sx={{
          color: 'primaryDark.250',
          position: 'absolute',
          ...sx,
        }}
        size={`${size}rem`}
        thickness={thickness}
        value={100}
      />

      {/* actual circular progress that shows progress */}
      <MuiCircularProgress
        variant="determinate"
        thickness={thickness}
        value={value}
        size={`${size}rem`}
        sx={{
          color,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...sx,
          position,
        }}
      />
    </Stack>
  </Box>
);

export default CircularProgress;
