import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Card, Divider, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../../../../../../components/custom-input/CustomInput';
import ControlledTextEditor from '../../../../../../components/custom-inputs/ControlledTextEditor';
import { BlogEditorMenu } from '../../../../../../components/editor/menu-option-lists/menu-item-lists';
import CustomButton from '../../../../../../components/button/CustomButton';

const SectionsEdit = () => {
  const { control } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'sections',
  });

  return (
    <>
      {fields.map((item, index) => (
        <Card key={item.id} elevation={4} sx={{ padding: '0' }}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              size="small"
              sx={{
                paddingBottom: 0,
                visibility: index === 0 ? 'hidden' : 'visible',
              }}
              onClick={() => {
                remove(index);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0 16px  16px 16px' }}>
            <CustomInput
              input={<CustomInput.Text />}
              name={`sections.${index}.subtitle`}
              type="text"
              placeholder="Enter subtitle"
              label="subtitle"
              required
              sx={{ paddingBottom: '1.25rem' }}
            />
            <ControlledTextEditor
              key={`${item.id}-body`}
              label="body"
              name={`sections.${index}.body`}
              items={BlogEditorMenu}
              required
            />
          </Box>
        </Card>
      ))}
      <Divider orientation="horizontal" />
      <Stack alignItems="center" justifyContent="center">
        <CustomButton
          variant="primaryLight"
          onClick={() => {
            append({ subtitle: '', body: '' });
          }}
        >
          new section
        </CustomButton>
      </Stack>
    </>
  );
};

export default SectionsEdit;
