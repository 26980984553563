import { Stack, Typography } from '@mui/material';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { primaryDark, secondaryBlue } from '../../color';

type StarRatingProps = {
  rating: number;
  color?: string;
  showSingle?: boolean;
};

const StarRating = ({
  rating,
  color = secondaryBlue[700],
  showSingle = false,
}: StarRatingProps) => {
  const starColorCondition = (i: number) => i >= 0 && i < rating;
  return showSingle ? (
    <Stack direction="row" gap={1}>
      <StarIcon stroke={color} fill={color} />
      <Typography variant="heading5" color={color}>
        {Math.round(rating)}
      </Typography>
    </Stack>
  ) : (
    <Stack direction="row" gap={0.625}>
      {Array.from(Array(5), (e, i) => (
        <StarIcon
          stroke={starColorCondition(i) ? color : primaryDark[300]}
          fill={starColorCondition(i) ? color : 'transparent'}
        />
      ))}
    </Stack>
  );
};

export default StarRating;
