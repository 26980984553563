import { Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import CustomButton from '../../../components/button/CustomButton';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';

const ForgotPasswordSection = () => {
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const { mutate } = useCreate<Record<string, unknown>, Record<string, unknown>>({
    resource: '/auth/reset-password',
  });

  function handleClick() {
    setShowResetPassword(true);
    mutate(
      { email },
      {
        onSuccess: () => {
          setEmailSent(true);
        },
      },
    );
  }

  return (
    <>
      <ForgotPasswordModal
        open={showResetPassword}
        closeModal={() => setShowResetPassword(false)}
      />

      <Stack width="100%" spacing={4}>
        <Typography
          variant="headline1"
          sx={{
            color: 'primaryDark.600',
          }}
        >
          Forgot Password?
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: 'primaryDark.600',
          }}
        >
          Send us a password reset request and we’ll get back to you shortly.
        </Typography>

        <Stack
          direction="column"
          width="100%"
          sx={{ mt: '2.5rem !important', mb: '1rem !important' }}
          spacing={1}
        >
          <Typography
            sx={{ textTransform: 'uppercase', fontFamily: 'Gellix-SemiBold', color: '#79829B' }}
          >
            email
          </Typography>

          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            placeholder="Enter your email"
            fullWidth
            variant="outlined"
          />
        </Stack>

        <CustomButton
          sx={{
            alignSelf: 'flex-end',
            width: { xs: '100%', lg: '35%', xxl: '30%' },
          }}
          onClick={() => handleClick()}
          disabled={!email}
        >
          Reset Password
        </CustomButton>
      </Stack>
    </>
  );
};

export default ForgotPasswordSection;
