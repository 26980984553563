import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

export default styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    width: 100,
    borderRadius: 4,
    position: 'relative',
    display: 'inline',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '8px 12px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));
