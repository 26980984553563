import { LinearProgressProps, LinearProgress, Typography, Stack, Box } from '@mui/material';

export default function LinearProgressWithLabel({
  value,
  ...props
}: { value: number } & LinearProgressProps) {
  function handleColor(percentage: number) {
    if (percentage === 100 && percentage !== undefined) {
      return 'secondaryGreen.500';
    }
    return 'secondaryBlue.500';
  }
  return (
    <Stack spacing={1} direction="row" width="100%" alignItems="center">
      <LinearProgress
        variant="determinate"
        value={value}
        {...props}
        sx={{
          height: '6px',
          borderRadius: '20px',
          width: '100%',
          backgroundColor: 'primaryDark.250',
          '& .MuiLinearProgress-bar': {
            backgroundColor: handleColor(value as number),
          },
        }}
      />

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body4" color="primaryDark.500">
          {value || value === 0 ? `${Math.round(value)}%` : '-'}
        </Typography>
      </Box>
    </Stack>
  );
}
