import { FieldValues, FormProvider, UseFieldArrayAppend, useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { CompanyClientDto, CreateCompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import { getBase64 } from '../../../../utils/helpers';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import { ReactComponent as FileUploadSvg } from '../../../../assets/icons/project-icons/FileUploadSvg.svg';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import { primaryDark } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/button-icons/PlusIcon.svg';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';

const ClientForm = ({
  append,
}: {
  append: UseFieldArrayAppend<FieldValues, 'details.clients'>;
}) => {
  const formMethods = useForm({
    defaultValues: {
      avatar: '',
      name: '',
    },
  });
  const [key, setKey] = useState(0);

  const { mutate: createCompanyClient, isLoading: isCreateCompanyClientLoading } = useCreate<
    CompanyClientDto,
    CreateCompanyClientDto
  >({
    resource: 'company-client',
  });

  const { avatar, name } = formMethods.watch();
  const onSubmit = (data: any) => {
    createCompanyClient(
      { avatar: data.avatar, name: data.name },
      {
        onSuccess: (response) => {
          const { data } = response;

          append(data);
          setKey((k) => k + 1);
          formMethods.reset({ avatar: '', name: '' });
        },
      },
    );
  };

  const onUpload = async (acceptedFiles: File[], onChange: (...event: any[]) => void) => {
    const file = acceptedFiles?.[0];
    if (!file) return;

    const base64 = await getBase64(file);
    formMethods.setValue('avatar', base64 as string);
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack direction="row" alignItems="center" gap={2.5} justifyContent="space-between">
          <Stack alignItems="center" justifyContent="center">
            <ControlledDropzoneFileInput
              name="avatar"
              onUpload={onUpload}
              required
              key={key}
              dropzoneProps={{
                multiple: false,
              }}
              renderCustomDroppableAreaWidth={135}
              renderCustomDroppableAreaHeight={135}
              renderCustomDroppableArea={({ getRootProps, getInputProps }) => (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  {...getRootProps()}
                  spacing={1}
                  sx={{
                    width: 135,
                    height: 135,
                    borderRadius: '50%',
                    border: '2px dashed',
                    borderColor: 'primaryDark.300',
                    '.MuiTypography-root': {
                      fontSize: '12px',
                    },
                    backgroundColor: 'primaryDark.200',
                  }}
                >
                  <input {...getInputProps()} />
                  <FileUploadSvg fill={primaryDark[400]} />
                  <Typography color="primaryDark.400" fontWeight="600">
                    ADD LOGO
                  </Typography>
                </Stack>
              )}
            />
          </Stack>
          <ControlledTextInput
            sx={{ flex: 1, mb: 1 }}
            name="name"
            placeholder="Add client"
            required
            label="Client company name"
          />
          <CustomButton
            variant="primaryLight"
            loading={isCreateCompanyClientLoading}
            sx={{ minWidth: 'fit-content' }}
            endIcon={<PlusIcon />}
            onClick={() => onSubmit(formMethods.getValues())}
            disabled={!(avatar?.length && name?.length)}
          >
            add
          </CustomButton>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ClientForm;
