import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { useState } from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';

interface EmbedMenuButtonProps {
  editor: Editor;
}

const EmbedMenuButton = ({ editor }: EmbedMenuButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInsert = () => {
    if (url) {
      editor.chain().focus().setIframe({ src: url }).run();
      setOpen(false);
    } else {
      setUrl('');
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <Button
        sx={{ minWidth: 44, color: '#384572', '&:hover': { backgroundColor: 'transparent', color: '#AA96F8' } }}
        onClick={handleClickOpen}
        component="span"
        size="small"
        disableElevation
        variant="text"
        color="secondary"
        aria-label="Embed"
      >
        <YouTubeIcon style={{ fontSize: '1.4rem' }} />
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Insert Video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a valid Youtube URL
          </DialogContentText>
          <TextField
            autoFocus
            error={url === ''}
            id="youtube-url"
            label="Youtube URL"
            type="text"
            fullWidth
            variant="outlined"
            sx={{ mt: '1em' }}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInsert}>Insert</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmbedMenuButton;
