import { useFormContext } from 'react-hook-form';
import { Typography, Stack, Divider } from '@mui/material';
import PreviewMaterials from '../../../../components/multisteps/components/PreviewMaterials';
import PreviewHeader from '../../../../components/preview/PreviewHeader';
import PreviewCompetitors from '../../../../components/preview/PreviewCompetitors';
import Editor from '../../../../components/editor/Editor';
import { primaryDark } from '../../../../color';
import { ReactComponent as JournalIcon } from '../../../../assets/icons/journal.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as PersonBoundingIcon } from '../../../../assets/icons/person-bounding.svg';
import { ReactComponent as PersonLinesIcon } from '../../../../assets/icons/person-lines.svg';
import { ReactComponent as CashIcon } from '../../../../assets/icons/cash.svg';
import { ReactComponent as PaperclipIcon } from '../../../../assets/icons/paperclip.svg';

const sxPreviewElem = {
  padding: '40px 24px',
  borderBottom: '1px solid #E5E8FF',
};

const BG =
  'linear-gradient(240.98deg, rgba(230, 232, 255, 0.2) 0%, rgba(255, 234, 225, 0.2) 68.46%, rgba(255, 251, 234, 0.2) 99.58%);';

const Preview = () => {
  const { getValues } = useFormContext();
  const { overview, about, budget, materials } = getValues(); // timedProject

  return (
    <Stack
      sx={{
        background: BG,
        border: '1px solid #E5E8FF',
        '.ProseMirror': {
          padding: 0,
          minHeight: 'auto',
          color: primaryDark[600],
          lineHeight: '22px',
        },
      }}
    >
      <Typography sx={{ padding: '24px 24px 0 24px' }} variant="heading1" color="primaryDark.600">
        {overview.name}
      </Typography>
      <Stack sx={sxPreviewElem} spacing={2}>
        <PreviewHeader title="company description" icon={<JournalIcon />} />
        <Editor items={[]} isEditable={false} content={overview.description} />
      </Stack>
      <Stack sx={sxPreviewElem} direction="row" spacing={2}>
        <Stack sx={{ flex: '1' }} spacing={2}>
          <PreviewHeader title="project goals" icon={<TrophyIcon />} />
          <Editor items={[]} isEditable={false} content={about.goals} />
        </Stack>
        {about.audience && (
          <>
            <Divider orientation="vertical" flexItem />
            <Stack sx={{ flex: '1' }} spacing={2}>
              <PreviewHeader title="target audience" icon={<PersonBoundingIcon />} />
              <Editor items={[]} isEditable={false} content={about.audience} />
            </Stack>
          </>
        )}
      </Stack>
      {!!about.competitors.length && (
        <Stack sx={sxPreviewElem} spacing={2}>
          <PreviewHeader title="main competitors" icon={<PersonLinesIcon />} />
          <PreviewCompetitors competitors={about.competitors} />
        </Stack>
      )}
      <Stack sx={sxPreviewElem} spacing={2}>
        <PreviewHeader title="budget description" icon={<CashIcon />} />
        <Editor items={[]} isEditable={false} content={budget.budgetDescription} />
      </Stack>
      {!!materials.length && (
        <Stack sx={sxPreviewElem} spacing={2}>
          <PreviewHeader title="materials" icon={<PaperclipIcon />} />
          <PreviewMaterials materials={materials} />
        </Stack>
      )}
    </Stack>
  );
};

export default Preview;
