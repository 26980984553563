/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Controller, useForm } from 'react-hook-form';
import { mapIndustry, mapService } from 'tdc-web-backend/enums/enums';
import { useEffect, useState } from 'react';
import { HTMLContent } from '@tiptap/react';
import { useNavigate, useParams } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import Button from '../../../components/button/Button';
import { getImageFilesToUpload } from '../../../utils/file-upload-helpers';
import { AwsUploadData, FileDataWithUploadData, ImagesUrlData } from '../../../utils/types';
import Editor from '../../../components/editor/Editor';
import { MinifiedEditorMenu } from '../../../components/editor/menu-option-lists/menu-item-lists';
import MultiSelectChips from '../../../components/multi-select-chips/MultiSelectChips';
import { mapArticleType, mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../enums';
import LoadingButton from '../../../components/button/LoadingButton';

export interface EditArticleProps {
  adminMode?: boolean;
}
const EditArticle = ({ adminMode }: EditArticleProps) => {
  // eslint-disable-next-line no-param-reassign
  adminMode = adminMode ?? false;
  const params = useParams();
  const navigate = useNavigate();

  const [imageData, setImageData] = useState<ImagesUrlData>(new ImagesUrlData());

  const [generalEl, setGeneralEl] = useState<HTMLElement | null>(null);
  const [technologiesEl, setTechnologiesEl] = useState<HTMLElement | null>(null);
  const [industry, setIndustry] = useState<HTMLElement | null>(null);
  const [type, setType] = useState<HTMLElement | null>(null);
  const [articleData, setArticleData] = useState<any>(null);
  const [companies, setCompanies] = useState<any[]>([]);

  useEffect(() => {
    // this is to make page scroll to top if previously
    // you are on a page that is scrolled
    window.scrollTo(0, 0);

    setGeneralEl(document.getElementById('general'));
    setTechnologiesEl(document.getElementById('technologies'));
    setIndustry(document.getElementById('industry'));
    setType(document.getElementById('type'));
  }, []);

  const {
    register,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      body: {
        articleBody: {},
        authorBiography: '',
      },
      authorName: '',
      services: articleData?.services ?? [],
      techLanguages: articleData?.techLanguages ?? [],
      techPlatforms: articleData?.techPlatforms ?? [],
      techTools: articleData?.techTools ?? [],
      industries: articleData?.industries ?? [],
      articleTypes: [],
      ...(adminMode && { Company: { id: '' } }),
    },
  });

  register('body.articleBody', { required: true });
  const requiredMessage = 'This field is required';

  useEffect(() => {
    reset(articleData);
  }, [articleData]);

  const handleRemove = (index: number, fieldString: string) => {
    let field: Array<string>;
    switch (fieldString) {
      case 'services':
        field = getValues('services');
        field.splice(index, 1);
        setValue('services', field);
        break;
      case 'techLanguages':
        field = getValues('techLanguages');
        field.splice(index, 1);
        setValue('techLanguages', field);
        break;
      case 'techPlatforms':
        field = getValues('techPlatforms');
        field.splice(index, 1);
        setValue('techPlatforms', field);
        break;
      case 'techTools':
        field = getValues('techTools');
        field.splice(index, 1);
        setValue('techTools', field);
        break;
      case 'industries':
        field = getValues('industries');
        field.splice(index, 1);
        setValue('industries', field);
        break;
      default:
    }
  };

  const articleEditParams = {
    variables: {
      articleId: articleData?.id,
      articleInput: {
        title: watch('title'),
        body: {
          articleBody: watch('body.articleBody'),
          authorBiography: watch('body.authorBiography'),
        },
        authorName: watch('authorName'),
        services: watch('services'),
        techLanguages: watch('techLanguages'),
        techPlatforms: watch('techPlatforms'),
        techTools: watch('techTools'),
        industries: watch('industries'),
        articleTypes: watch('articleTypes'),
        ...(adminMode && { company: watch('Company.id') }),
      },
    },
  };

  const handleSubmit = async () => {
    // if new image is selected
    // I would love if @pkleskovic or anyone has a better idea of how to implement
    // mutation for editing when there is a new image selected and
    // when there is no new image selected;
    // since there is code repetition atm
    if (imageData?.banner !== undefined) {
      const filesToUpload: Array<AwsUploadData> = getImageFilesToUpload(imageData);
    }
  };

  return (
    <Stack spacing={2} direction="row" sx={{ py: 3 }}>
      <Box sx={{ width: '25%' }}>
        <Stack
          spacing={2}
          width="fit-content"
          alignItems="flex-start"
          sx={{ position: 'sticky', top: 85 }}
        >
          {/* TODO: where should this button navigate? */}
          <Button
            onButtonClick={() => {}}
            variant="text"
            color="inherit"
            minWidth={120}
            linkTo="../list"
            startIcon={<ChevronLeftIcon />}
            sx={{ width: 'fit-content', fontSize: '1.1rem' }}
          >
            Back to Article list
          </Button>

          <Stack sx={{ pl: 3 }}>
            <Button
              onButtonClick={() => {}}
              variant="text"
              color="inherit"
              target={generalEl ?? null}
              sx={{ width: 'fit-content', fontSize: '1.1rem' }}
            >
              General
            </Button>

            <Button
              onButtonClick={() => {}}
              variant="text"
              target={technologiesEl ?? null}
              color="inherit"
              sx={{ width: 'fit-content', fontSize: '1.1rem' }}
            >
              Technologies
            </Button>

            <Button
              onButtonClick={() => {}}
              variant="text"
              color="inherit"
              target={industry ?? null}
              sx={{ width: 'fit-content', fontSize: '1.1rem' }}
            >
              Industry
            </Button>

            <Button
              onButtonClick={() => {}}
              variant="text"
              color="inherit"
              target={type ?? null}
              sx={{ width: 'fit-content', fontSize: '1.1rem' }}
            >
              Type
            </Button>
          </Stack>
        </Stack>
      </Box>

      <Stack spacing={3} width="55%">
        <Typography variant="h3">Edit</Typography>

        <Stack spacing={3}>
          <Box>
            <Typography id="general" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              Overview
            </Typography>
            <Divider />
          </Box>

          {adminMode && (
            <Paper sx={{ p: 3, bgcolor: 'grey.100' }}>
              <Stack spacing={1}>
                <Box sx={{ mb: 0.5 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Company
                  </Typography>
                </Box>

                <Controller
                  name="Company.id"
                  control={control}
                  rules={{ required: requiredMessage }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ background: '#fff' }}
                      displayEmpty
                      fullWidth
                      renderValue={(selected) => {
                        if (selected === undefined) {
                          return <Typography sx={{ color: 'grey.500' }}>Select*</Typography>;
                        }

                        return companies?.find((comp) => comp.id === selected)?.name;
                      }}
                    >
                      {companies?.map((comp) => (
                        <MenuItem key={comp.id} value={comp.id}>
                          {comp.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Stack>
            </Paper>
          )}
          <Paper sx={{ p: 3, bgcolor: 'grey.100' }}>
            <Stack spacing={1}>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Title
                </Typography>
                <Typography variant="body1">
                  The title should include the necessery keywords and be between 4-9 words long.
                </Typography>
              </Box>

              <TextField
                {...register('title', { required: requiredMessage })}
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Type"
                sx={{ background: '#fff' }}
                helperText={
                  errors.title && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors.title.message}
                    </FormHelperText>
                  )
                }
              />
            </Stack>
          </Paper>

          <Paper sx={{ p: 3, bgcolor: 'grey.100' }}>
            <Stack spacing={1}>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Upload Featured Image
                </Typography>
                <Typography variant="body1">
                  We’ll need an image that represents your article on the archive page and the
                  thumbnail. Suggested dimensions: 1920x1080 pixels, .jpg or .png. Maximum 10MB.
                </Typography>
              </Box>
            </Stack>
          </Paper>

          <Stack spacing={1}>
            <Paper sx={{ bgcolor: 'grey.100', px: 3, py: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Article text
              </Typography>
              <Typography variant="body1">
                Write and format your article here. Be sure to add headings, relevant links and
                keywords.
              </Typography>
            </Paper>

            {articleData && articleData?.body?.articleBody && (
              <Editor
                key={uuidv1()}
                items={MinifiedEditorMenu}
                content={watch('body.articleBody') as HTMLContent}
                isEditable
                onUpdateContent={(data: any) => {
                  setValue('body.articleBody', data, { shouldValidate: true });
                }}
                onValidationChange={(valid) => {
                  if (!valid) {
                    setValue('body.articleBody', '');
                  }
                }}
              />
            )}
          </Stack>

          <Stack spacing={2}>
            <Box>
              <Typography variant="h6" sx={{ color: 'grey.500' }}>
                OPTIONAL
              </Typography>
              <Divider />
            </Box>

            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Author
              </Typography>
              <Typography variant="body1">Placeholder something blah blah</Typography>
            </Box>

            <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Name
                </Typography>
              </Box>

              <TextField
                {...register('authorName', { required: requiredMessage })}
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Type*"
                sx={{ background: '#fff' }}
                helperText={
                  errors.title && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors.title.message}
                    </FormHelperText>
                  )
                }
              />
            </Paper>

            <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
              <Box sx={{ mb: 0.5 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Biography
                </Typography>
              </Box>

              <TextField
                {...register('body.authorBiography')}
                fullWidth
                multiline
                rows={5}
                type="text"
                variant="outlined"
                placeholder="Type"
                sx={{ background: '#fff' }}
                helperText={
                  errors.title && (
                    <FormHelperText error sx={{ ml: 0 }}>
                      {errors.title.message}
                    </FormHelperText>
                  )
                }
              />
            </Paper>
          </Stack>
        </Stack>

        <Stack spacing={3} sx={{ pt: 5 }}>
          <Box>
            <Typography id="technologies" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              Technologies
            </Typography>
            <Divider />
          </Box>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Expertise
                </Typography>
                <Typography variant="body1">
                  Select the expertise & concepts expressed in your article.
                </Typography>
              </Box>

              <MultiSelectChips
                enumMap={mapService}
                watch={watch}
                control={control}
                valuesName="services"
                isRequired
                requiredMessage={requiredMessage}
                backgroundColor="#fff"
                shouldHaveRemove
                remove={(index: number) => handleRemove(index, 'services')}
              />
            </Stack>
          </Paper>

          <Box>
            <Typography variant="h6" sx={{ color: 'grey.500' }}>
              OPTIONAL
            </Typography>
            <Divider />
          </Box>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Tech languages
              </Typography>

              <MultiSelectChips
                enumMap={mapTechLanguage}
                watch={watch}
                control={control}
                valuesName="techLanguages"
                isRequired
                requiredMessage={requiredMessage}
                backgroundColor="#fff"
                shouldHaveRemove
                remove={(index: number) => handleRemove(index, 'techLanguages')}
              />
            </Stack>
          </Paper>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Tech platforms
              </Typography>

              <MultiSelectChips
                enumMap={mapTechPlatform}
                watch={watch}
                control={control}
                valuesName="techPlatforms"
                isRequired
                requiredMessage={requiredMessage}
                backgroundColor="#fff"
                shouldHaveRemove
                remove={(index: number) => handleRemove(index, 'techPlatforms')}
              />
            </Stack>
          </Paper>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Tech tools
              </Typography>

              <MultiSelectChips
                enumMap={mapTechTool}
                watch={watch}
                control={control}
                valuesName="techTools"
                isRequired
                requiredMessage={requiredMessage}
                backgroundColor="#fff"
                shouldHaveRemove
                remove={(index: number) => handleRemove(index, 'techTools')}
              />
            </Stack>
          </Paper>
        </Stack>

        <Stack spacing={3} sx={{ pt: 5 }}>
          <Box>
            <Typography id="industry" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              Industry
            </Typography>
            <Divider />
          </Box>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Industry focus
                </Typography>
                <Typography variant="body1">
                  Select any industries that apply to your article.
                </Typography>
              </Box>

              <MultiSelectChips
                enumMap={mapIndustry}
                watch={watch}
                control={control}
                valuesName="industries"
                isRequired
                requiredMessage={requiredMessage}
                backgroundColor="#fff"
                shouldHaveRemove
                remove={(index: number) => handleRemove(index, 'industries')}
              />
            </Stack>
          </Paper>
        </Stack>

        <Stack spacing={3} sx={{ pt: 5 }}>
          <Box>
            <Typography id="type" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
              Type
            </Typography>
            <Divider />
          </Box>

          <Paper sx={{ bgcolor: 'grey.100', p: 3 }}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Article types
                </Typography>
              </Box>

              {articleData && articleData?.articleTypes && (
                <Controller
                  name="articleTypes"
                  control={control}
                  render={({ field }) => (
                    <Grid
                      direction="row"
                      justifyContent="space-between"
                      container
                      columns={{ md: 3 }}
                    >
                      {Array.from(mapArticleType, ([key, value]: [string, string]) => (
                        <Grid key={key} item md={1}>
                          <FormControlLabel
                            {...field}
                            key={key}
                            label={value}
                            control={
                              <Checkbox
                                color="secondary"
                                checked={watch('articleTypes')?.includes(key as never)}
                                onChange={() => {
                                  if (!field.value.includes(key as never)) {
                                    field.onChange([...field.value, key]);
                                    return;
                                  }

                                  const newArticleTypes = field.value.filter(
                                    (articleTypes: string) => articleTypes !== key,
                                  );

                                  field.onChange(newArticleTypes);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                />
              )}
            </Stack>
          </Paper>
        </Stack>

        <Divider sx={{ pt: 10 }} />
        {articleData && (
          <LoadingButton
            onButtonClick={() => handleSubmit()}
            sx={{ alignSelf: 'end' }}
            variant="contained"
            size="medium"
            color="secondary"
            setBorderRadius
            fontWeight="bold"
            minWidth={102}
          >
            Save
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default EditArticle;
