import { Stack, Box, Grid } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import { EnumPublicPagesImage } from '../../../../utils/enums/public-page-images';
import { getPublicImageUrl } from '../../../../utils/helpers';

const partnersLogos: EnumPublicPagesImage[] = [
  EnumPublicPagesImage.SmGoogleAds,
  EnumPublicPagesImage.SmDigitalTurbine,
  EnumPublicPagesImage.SmMeta,
  EnumPublicPagesImage.SmReddit,
  EnumPublicPagesImage.SmSnapchat,
  EnumPublicPagesImage.SmSpotify,
  EnumPublicPagesImage.SmTiktok,
  EnumPublicPagesImage.SmX,
];

const Partners = () => (
  <Stack spacing={2.5} alignItems="center" sx={{ ...LAYOUT_DEFAULTS, my: 12 }}>
    <Grid
      container
      rowSpacing={{ xs: 2, md: 3 }}
      columnGap={{ xs: 2, md: 3 }}
      justifyContent="center"
    >
      {partnersLogos.map((logo) => (
        <Grid item key={logo} xs={2} sm={1} md={1} bg={1} lg={1} gr={1} xl={1} xxl={1}>
          <Box
            component="img"
            src={getPublicImageUrl(logo)}
            sx={{ width: { xs: '40px', md: '60px' }, height: { xs: '60px', md: '60px' } }}
          />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export default Partners;
