import { Stack } from '@mui/material';
import CustomInput from '../../../../../../components/custom-input/CustomInput';

const NameEmail = ({ isLoading }: { isLoading?: boolean }) => (
  <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
    <CustomInput
      input={<CustomInput.Text />}
      name="name"
      type="text"
      label="* Name"
      placeholder="Enter your first and last name"
      required
      disabled={isLoading}
      sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}
    />
    <CustomInput
      input={<CustomInput.Text />}
      name="email"
      type="email"
      label="* Email"
      placeholder="Enter your e-mail"
      required
      disabled={isLoading}
      validationRegEx={{
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'Invalid email address',
      }}
      sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}
    />
  </Stack>
);
export default NameEmail;
