import { Card, Divider, Stack, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';

import { Box } from '@mui/system';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import {
  EnumCompanySize,
  EnumProjectHourlyRate,
  EnumProjectSize,
  mapCompanySize,
  mapHourlyRate,
  mapProjectSize,
} from 'tdc-web-backend/enums/enums';
import Button from '../../../../components/button/Button';
import ApproveCompanyModal from '../modals/ApproveCompanyModal';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';

interface CompanyLocationInfoProps {
  data: CompanyDto;
}

const CompanyLocationInfo = ({ data }: CompanyLocationInfoProps) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: '100% !important' }}>
      <Card
        elevation={3}
        sx={{
          borderRadius: '16px',
          height: '100%',
        }}
      >
        <Stack sx={{ padding: '0.5em 1em' }} spacing={1}>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              mt: 1,
              mb: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: '.875em',
              }}
            >
              Status
            </Typography>
            <TaskStatus text={data?.status} />
          </Stack>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '.875em',
                fontWeight: 600,
              }}
            >
              Average project size
            </Typography>
            <Typography sx={{ fontSize: '.875em' }}>
              {mapProjectSize.get(data?.averageProjectSize as EnumProjectSize) ?? '-'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '.875em',
                fontWeight: 600,
              }}
            >
              Hourly rate
            </Typography>
            <Typography sx={{ fontSize: '.875em' }}>
              {mapHourlyRate.get(data?.hourlyRate as EnumProjectHourlyRate) ?? '-'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '.875em',
                fontWeight: 600,
              }}
            >
              Company size
            </Typography>
            <Typography sx={{ fontSize: '.875em' }}>
              {mapCompanySize.get(data?.companySize as EnumCompanySize) ?? '-'}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'space-between',
              pt: 2.5,
            }}
          />
          <Button
            onButtonClick={() => navigate('./edit')}
            setBorderRadius
            variant="outlined"
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
          <ApproveCompanyModal company={data} />
        </Stack>
      </Card>
    </Box>
  );
};

export default CompanyLocationInfo;
