/* eslint-disable import/no-unresolved */
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from '@mui/material';
import { Stack } from '@mui/system';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { EnumService, mapService } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { CaseStudyCardProps } from '../interface';
import { truncate } from '../../../../utils/helpers';
import StarRating from '../../../../components/star-rating/StarRating';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import CustomButton from '../../../../components/button/CustomButton';

const CaseStudyCard = ({ data, showFocusArea }: CaseStudyCardProps) => {
  const navigate = useNavigate();
  const { data: caseStudyContent } = useReferenceOne<CaseStudyContentDto>({
    resource: 'case-studies-content',
    id: data?.content,
  });

  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '0.8rem',
        boxShadow: '6px 6px 17px 0px #3845721A',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        height: '100%',
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/case-study/${data?.id}`);
      }}
    >
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
        disableRipple
      >
        <CardMedia
          component="img"
          image={data?.file}
          alt="Case study card"
          sx={{
            width: '100%',
            height: '190px',
            objectFit: 'cover',
          }}
        />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            flex: '1 0 auto',
            minHeight: showFocusArea ? '320px' : ' 216px',
          }}
        >
          <Stack gap={2} sx={{ flex: '1 0 auto' }}>
            <CustomReferenceField
              resource="companies"
              key={data?.seller}
              displayName="name"
              id={data?.seller}
              renderComponent={(item) => (
                // eslint-disable-next-line no-use-before-define
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar alt="card logo" src={item?.avatar} sx={{ width: 34, height: 34 }} />
                  <Typography variant="body3" color="primaryDark.500">
                    {item?.name}
                  </Typography>
                </Stack>
              )}
            />
            <CustomTooltip title={''.length > 70 ? data?.title : ''} placement="top">
              <Typography variant="publicBody6" color="primaryDark.600">
                {truncate(data?.title ?? '', 70)}
              </Typography>
            </CustomTooltip>
            {showFocusArea && (
              <>
                <Typography variant="publicBody6" color="primaryDark.500">
                  SERVICES
                </Typography>
                <Stack
                  direction="row"
                  gap={1.5}
                  justifyContent="start"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {caseStudyContent?.details.services?.slice(0, 5).map((item: EnumService) => (
                    <CustomChip label={mapService.get(item)} key={mapService.get(item)} />
                  ))}
                  {caseStudyContent?.details?.services?.length &&
                    caseStudyContent?.details?.services?.length >= 5}
                </Stack>
              </>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            mt={4}
            justifyContent="space-between"
            sx={{ opacity: data?.score ? 1 : 0 }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <StarRating rating={Math.round(data?.score ?? 0)} />
              <Typography variant="heading4" color="secondaryBlue.700">
                {Math.round(data?.score ?? 0)}
              </Typography>
            </Stack>
            <StatusChipField text={data?.status} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CaseStudyCard;
