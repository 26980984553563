/* eslint-disable no-unused-expressions */
import { Stack, Typography, useMediaQuery, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { UserRoles } from 'tdc-web-backend/enums/enums';
import { LoginRequestDto, LoginResponseDto } from 'tdc-web-backend/auth/schemas';
import _ from 'lodash';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoginModal } from './components/LoginModal';
import ForgotPasswordModal from '../ForgotPassword/components/ForgotPasswordModal';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import TermsAndConditionsModal from './components/TermsAndConditionsModal';
import ResendSuccessModal from './components/ResendSuccessModal';
import NewPasswordSection from '../NewPassword/NewPasswordSection';
import theme from '../../../theme';
import GoToBiggerScreenModal from '../../../components/go-to-bigger-screen-modal/GoToBiggerScreenModal';
import CustomButton from '../../../components/button/CustomButton';
import CustomInput from '../../../components/custom-input/CustomInput';

const inputStyles = {
  minHeight: 120,
};

const LoginSection = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [isGoToBiggerScreenModalOpen, setIsGoToBiggerScreenModalOpen] = useState(false);

  const {
    mutate: mutateLogin,
    isLoading,
    isError,
  } = useCreate<LoginResponseDto, LoginRequestDto>({ resource: '/auth/login' });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData') as string);
    if (userData?.membership?.company) {
      if (!userData?.agreementDate) setOpenTermsModal(true);
    }
  }, []);

  const formMethods = useForm<LoginRequestDto>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const {
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data: LoginRequestDto) => {
    if (isLessThanMedium) {
      setIsGoToBiggerScreenModalOpen(true);
    } else {
      mutateLogin(data, {
        onSuccess: (data) => {
          const {
            data: { user: userLoginData },
          } = data;
          const {
            data: { token: userToken },
          } = data;

          // if user is not verified
          if (!userLoginData.roles?.includes(UserRoles.Verified)) {
            setShowModal(true);
            return;
          }

          localStorage.setItem('AccessToken', userToken);
          localStorage.setItem('userData', JSON.stringify(userLoginData));

          // if a user has a company
          if (userLoginData.membership?.company) {
            if (!userLoginData.agreementDate) setOpenTermsModal(true);
            else navigate('/dashboard', { replace: true });
          } else {
            navigate('/dashboard/create-company', { replace: true });
          }
        },
      });
    }
  };

  useEffect(() => {
    if (isError) {
      setError('password', {
        type: 'manual',
        message: 'Invalid password or email address. Please try again',
      });
    }
  }, [isError]);

  return (
    <>
      {isGoToBiggerScreenModalOpen && (
        <GoToBiggerScreenModal
          isGoToBiggerScreenModalOpen={isGoToBiggerScreenModalOpen}
          automaticallyShowModalIfBelowTreshold={false}
          onClick={() => navigate('/', { replace: true })}
        />
      )}

      <LoginModal
        open={showModal}
        setIsOpen={setShowModal}
        email={watch('email')}
        setOpenSuccessModal={setOpenSuccessModal}
      />

      <ResendSuccessModal
        email={watch('email')}
        open={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
      />

      <ForgotPasswordModal
        open={showResetPassword}
        closeModal={() => setShowResetPassword(false)}
      />

      <TermsAndConditionsModal setIsOpen={setOpenTermsModal} isOpen={openTermsModal} />

      <>
        {token === null ? (
          <FormProvider {...formMethods}>
            {/* form */}
            <Box component="form" sx={{ height: '90%' }} onSubmit={handleSubmit(onSubmit)}>
              <Stack height="100%" justifyContent="space-between" spacing={4}>
                {/* heading and subheading */}
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      color: 'primaryDark.600',
                    }}
                    variant="headline1"
                  >
                    Welcome!
                  </Typography>

                  <Typography variant="body1" sx={{ color: 'primaryDark.500' }}>
                    Sign into your account to access your project management hub
                  </Typography>
                </Stack>

                {/* inputs & forgot password checkbox */}
                <Stack spacing={{ lg: 7 }}>
                  {/* inputs */}
                  <Stack>
                    <CustomInput
                      input={<CustomInput.Text />}
                      name="email"
                      type="email"
                      placeholder="example@mail.com"
                      label="Email"
                      required
                      validationRegEx={{
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: 'Invalid email address',
                      }}
                      sx={{ ...inputStyles }}
                    />

                    <CustomInput
                      input={<CustomInput.Text />}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      label="Password"
                      required
                      placeholder="••••••••"
                      adornment={{
                        icon: showPassword ? (
                          <VisibilityIcon
                            sx={{ color: 'primaryDark.400', width: 22, height: 22 }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{ color: 'primaryDark.400', width: 22, height: 22 }}
                          />
                        ),
                        onClick: () => {
                          setShowPassword((prevValue) => !prevValue);
                        },
                      }}
                      sx={{ ...inputStyles }}
                    />
                  </Stack>

                  <Stack
                    spacing={2}
                    direction={{ xs: 'column', lg: 'row-reverse' }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* sign in button */}
                    <CustomButton
                      type="submit"
                      loading={isLoading}
                      variant="primary"
                      sx={{ width: { xs: '100%', lg: '35%', xxl: '25%' } }}
                    >
                      Sign in
                    </CustomButton>

                    <Typography
                      variant="heading3"
                      color="primaryLight.500"
                      sx={{ cursor: 'pointer', textTransform: 'capitalize' }}
                      onClick={() => navigate('/reset-password')}
                    >
                      Forgot Password?
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </FormProvider>
        ) : (
          <NewPasswordSection token={token} />
        )}
      </>
    </>
  );
};

export default LoginSection;
