import { Stack } from '@mui/material';
import DoItYourself from './components/DoItYourself';
import HowToHireHero from './components/HowToHireHero';
import LetSponaHandleIt from './components/LetSponaHandleIt';
import MetaTags from '../../../components/seo/MetaTags';
import CallToAction from './components/CallToAction';

const metaTags = {
  title: `Spona - Hire the Top Digital Agency for your project needs.`,
  description: `Have a project in mind, but you’re not sure how to proceed? Save your time and energy with Spona Connect and let us find the perfect fit for your project.`,
};

const HowToHire = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <HowToHireHero />

      <LetSponaHandleIt />

      <DoItYourself />

      <CallToAction
        firstTitle="Let's Plan Together"
        firstSubtitle="Not sure which plan is right for you? Let us talk you through the options"
        secondTitle="Get Started"
        secondSubtitle="Become a part of Spona to get started on your next big project"
        bgcolor="secondaryBlue.101"
      />
    </Stack>
  </>
);
export default HowToHire;
