import { useEffect, useState } from 'react';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';

const useGetLocalStorageUserData = () => {
  const [userData, setUserData] = useState<BaseUserDto | null>(
    JSON.parse(localStorage.getItem('userData') as string) || null,
  );

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('userData') as string));
  }, [localStorage.getItem('userData')]);

  return userData;
};

export default useGetLocalStorageUserData;
