import { useFormContext } from 'react-hook-form';
import Stack from '@mui/system/Stack/Stack';
import { mapCompanySize, mapIndustry, mapCountry, mapLanguage } from 'tdc-web-backend/enums/enums';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomInput from '../../../../components/custom-input/CustomInput';

const DetailsBuyer = () => {
  const { watch } = useFormContext();

  return (
    <Stack spacing={2}>
      <BaseInputLabel text="company details" />

      <Stack flexDirection="row" sx={{ margin: '0 0 32px 0' }}>
        <Stack
          spacing={3}
          width="50%"
          pr={3}
          sx={{ borderRight: '1px solid', borderColor: 'secondaryBlue.100' }}
        >
          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.targetIndustries"
            label="company industry"
            placeholder="Select industry"
            choices={mapIndustry}
            required
            multiple
            withChips
          />

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.targetCountries"
            label="Target markets"
            placeholder="Select country"
            choices={mapCountry}
            required
            multiple
            withChips
          />
        </Stack>

        <Stack spacing={3} width="50%" pl={3}>
          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.companySize"
            label="Company size"
            placeholder="Select size"
            choices={mapCompanySize}
            required
          />

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.languages"
            label="Working languages"
            placeholder="Select languages"
            choices={mapLanguage}
            multiple
            withChips
            required
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DetailsBuyer;
