import { Stack, Typography } from '@mui/material';
import CheckedItem from '../../CheckedItem';
import CustomButton from '../../../../../../components/button/CustomButton';

const BuyerPremiumPlan = () => (
  <Stack gap={0.5} alignItems="center" width="100%">
    <Typography variant="publicHeading4" color="primaryLight.500" sx={{ mb: 1 }}>
      Premium
    </Typography>
    <Typography
      variant="publicBody1"
      color="primaryDark.900"
      display="flex"
      justifyContent="center"
      alignItems="center"
      align="center"
      gap={1}
    >
      $799.99
      <Typography component="span" variant="publicBody5Light" color="primaryDark.900">
        monthly
      </Typography>
    </Typography>
    <Typography
      variant="publicBody5Light"
      color="primaryDark.900"
      display="flex"
      justifyContent="center"
      alignItems="center"
      align="center"
      gap={1}
    >
      or
      <Typography
        component="span"
        variant="publicBody5Light"
        color="primaryDark.900"
        sx={{ fontFamily: 'Gellix-SemiBold', fontWeight: 700 }}
      >
        $679.99 per month
      </Typography>
      annually
    </Typography>
    <Typography variant="publicBody3" color="primaryDark.900" my={2}>
      Try for free for 30 days
    </Typography>
    <Stack gap={2} width="83%">
      <CheckedItem
        title="Management of up to 8+ projects"
        sxTitle={{ fontFamily: 'Gellix-Bold' }}
      />
      <CheckedItem title="Built-in chat & video calls" />
      <CheckedItem title="Company calendar" />
      <CheckedItem title="Notifications" />
      <CheckedItem title="Ready-to-use contracts" />
      <CheckedItem title="Milestone & task management" />
      <CheckedItem title="Payment processing" />
      <CheckedItem title="Expense tracking" />
      <CheckedItem title="Priority support" />
      <CheckedItem title="Dedicated account manager" />
    </Stack>
    <CustomButton sx={{ mt: 5 }} href={process.env.REACT_APP_CALENDLY_URL as string}>
      try for free
    </CustomButton>
  </Stack>
);

export default BuyerPremiumPlan;
