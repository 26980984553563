/* eslint-disable import/no-unresolved */
import { Stack, useMediaQuery } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { MutableRefObject } from 'react';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Divider } from '../CaseStudyDetails';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as BullseyeIcon } from '../../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../../assets/icons/layout-icons/LightBulbIcon.svg';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { CaseStudyEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../../components/editor/Editor';
import { primaryDark } from '../../../../color';
import { CaseStudyDataProps } from '../interface';
import CaseStudyClientReview from './CaseStudyClientReview';
import theme from '../../../../theme';

const CaseStudyDescriptions = ({ data, scrollRef }: CaseStudyDataProps) => {
  const isLessThanSmall = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Stack py={{ xs: 3, md: 5 }}>
        <Stack mb={3} direction="row" alignItems="center" spacing={1}>
          <TrophyIcon fill={primaryDark[500]} />
          <BaseInputLabel textVariant="heading3" text="project goals" />
        </Stack>
        {data?.description?.goals && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.description?.goals}
            isEditable={false}
            key="goals"
          />
        )}
      </Stack>
      <Divider />
      <Stack py={{ xs: 3, md: 5 }}>
        <Stack mb={3} direction="row" alignItems="center" spacing={1}>
          <BullseyeIcon fill={primaryDark[500]} />
          <BaseInputLabel textVariant="heading3" text="challenges" />
        </Stack>
        {data?.description?.challenges && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.description?.challenges}
            isEditable={false}
            key="challenges"
          />
        )}
      </Stack>
      <Divider />
      <Stack py={{ xs: 3, md: 5 }}>
        <Stack mb={3} direction="row" alignItems="center" spacing={1}>
          <LightBulbIcon fill={primaryDark[500]} />
          <BaseInputLabel textVariant="heading3" text="solution" />
        </Stack>
        {data?.description?.solutions && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.description?.solutions}
            isEditable={false}
            key="solution"
          />
        )}
      </Stack>
      <Divider />
      <Stack py={{ xs: 3, md: 5 }}>
        <Stack mb={3} direction="row" alignItems="center" spacing={1}>
          <BriefcaseCheckIcon fill={primaryDark[500]} />
          <BaseInputLabel textVariant="heading3" text="results" />
        </Stack>
        {data?.description?.results && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.description?.results}
            isEditable={false}
            key="results"
          />
        )}
      </Stack>
      <Divider />
      <Stack display={{ xs: 'none', md: 'flex' }} py={{ md: 5 }}>
        <CaseStudyClientReview
          scrollRef={
            !isLessThanSmall
              ? scrollRef
              : (null as unknown as MutableRefObject<HTMLDivElement | null>)
          }
          data={data as CaseStudyContentDto}
        />
      </Stack>
    </>
  );
};

export default CaseStudyDescriptions;
