import {
  EnumCountry,
  EnumProjectHourlyRate,
  EnumProjectSize,
  EnumService,
  EnumCurrency as Currency,
  EnumProjectType,
  EnumCurrency,
  EnumProjectMilestoneStatus,
  EnumProjectStatus,
  PaymentStatus,
  EnumProjectContractStatus,
  EnumTimedProjectStatus,
  EnumTimedProjectPitchStatus,
  EnumTechPlatform,
  EnumTechTool,
  EnumTechLanguage,
  EnumServiceProviderScope,
  EnumCaseStudyStatus,
  EnumEventType,
} from 'tdc-web-backend/enums/enums';

export const mapUserClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapUserGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapCompanyClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapCompanyGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapPositionClaim = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
]);

export const mapPositionGroup = new Map([
  ['foo', 'Foo'],
  ['bar', 'Bar'],
  ['baz', 'Baz'],
  ['foobar', 'FooBar'],
  ['foobaz', 'FooBaz'],
  ['barbaz', 'BarBaz'],
  ['foobarbaz', 'FooBarBaz'],
]);

export const mapTimeDifference = new Map([
  ['Two weeks', 'Two weeks'],
  ['One month', 'One month'],
  ['I am flexible', 'I am flexible'],
]);

export const mapPaymentOccurrence = new Map([
  ['Recurring', 'Recurring'],
  ['One time project', 'One time project'],
]);

export const mapArticleType = new Map([
  ['Analysis', 'Analysis'],
  ['Blog', 'Blog'],
  ['Ebook', 'E-book'],
  ['Editorial', 'Editorial'],
  ['Guide', 'Guide'],
  ['Infographic', 'Infographic'],
  ['Interview', 'Interview'],
  ['News', 'News'],
  ['Presentation', 'Presentation'],
  ['Press_Release', 'Press Release'],
  ['Research', 'Research'],
  ['Showcase', 'Showcase'],
  ['Tutorial', 'Tutorial'],
]);

export const mapLocationType = new Map([
  ['Headquarters', 'Headquarters'],
  ['Regional_Headquarters', 'Regional Headquarters'],
  ['Branch_Office', 'Branch Office'],
  ['Subsidiary', 'Subsidiary'],
]);

export const mapProjectType = new Map([
  [EnumProjectType.OneTime, 'One time'],
  [EnumProjectType.Retainer, 'Retainer'],
]);

export const mapPublicationStatus = new Map([
  ['Draft', 'Draft'],
  ['Awaiting_approval', 'Awaiting approval'],
  ['Rejected', 'Rejected'],
  ['Private', 'Private'],
  ['Public', 'Public'],
]);

export const mapTechLanguage = new Map([
  [EnumTechLanguage.Apl, 'APL'],
  [EnumTechLanguage.Assembly, 'Assembly'],
  [EnumTechLanguage.C, 'C'],
  [EnumTechLanguage.Csharp, 'C#'],
  [EnumTechLanguage.Cpp, 'C++'],
  [EnumTechLanguage.Clojure, 'Clojure'],
  [EnumTechLanguage.Cobol, 'COBOL'],
  [EnumTechLanguage.Crystal, 'Crystal'],
  [EnumTechLanguage.Dart, 'Dart'],
  [EnumTechLanguage.Delphi, 'Delphi'],
  [EnumTechLanguage.Elixir, 'Elixir'],
  [EnumTechLanguage.Erlang, 'Erlang'],
  [EnumTechLanguage.Fsharp, 'F#'],
  [EnumTechLanguage.Go, 'Go'],
  [EnumTechLanguage.Groovy, 'Groovy'],
  [EnumTechLanguage.Haskell, 'Haskell'],
  [EnumTechLanguage.HtmlAndCss, 'HTML & CSS'],
  [EnumTechLanguage.Java, 'Java'],
  [EnumTechLanguage.JavaScript, 'JavaScript'],
  [EnumTechLanguage.Julia, 'Julia'],
  [EnumTechLanguage.Kotlin, 'Kotlin'],
  [EnumTechLanguage.Lisp, 'Lisp'],
  [EnumTechLanguage.Matlab, 'MATLAB'],
  [EnumTechLanguage.ObjectiveC, 'Objective-C'],
  [EnumTechLanguage.Perl, 'Perl'],
  [EnumTechLanguage.Php, 'PHP'],
  [EnumTechLanguage.PowerShell, 'PowerShell'],
  [EnumTechLanguage.Python, 'Python'],
  [EnumTechLanguage.R, 'R'],
  [EnumTechLanguage.Ruby, 'Ruby'],
  [EnumTechLanguage.Rust, 'Rust'],
  [EnumTechLanguage.Scala, 'Scala'],
  [EnumTechLanguage.Sql, 'SQL'],
  [EnumTechLanguage.Swift, 'Swift'],
  [EnumTechLanguage.TypeScript, 'TypeScript'],
  [EnumTechLanguage.UnixShell, 'Unix shell'],
  [EnumTechLanguage.VisualBasic, 'Visual Basic'],
]);

export const mapTechPlatform = new Map([
  [EnumTechPlatform.AmazonWebServices, 'Amazon Web Services'],
  [EnumTechPlatform.Android, 'Android'],
  [EnumTechPlatform.Azure, 'Azure'],
  [EnumTechPlatform.DigitalOcean, 'DigitalOcean'],
  [EnumTechPlatform.Docker, 'Docker'],
  [EnumTechPlatform.GoogleCloud, 'Google Cloud'],
  [EnumTechPlatform.Heroku, 'Heroku'],
  [EnumTechPlatform.IbmCloud, 'IBM Cloud'],
  [EnumTechPlatform.IOs, 'iOS'],
  [EnumTechPlatform.Kubernetes, 'Kubernetes'],
  [EnumTechPlatform.Linux, 'Linux'],
  [EnumTechPlatform.Lxc, 'LXC'],
  [EnumTechPlatform.MacOs, 'MacOS'],
  [EnumTechPlatform.OracleCloud, 'Oracle Cloud'],
  [EnumTechPlatform.SalesforcePlatform, 'Salesforce Platform'],
  [EnumTechPlatform.Windows, 'Windows'],
]);

export const mapTechTool = new Map([
  [EnumTechTool.DotNet, '.NET'],
  [EnumTechTool.Angular, 'Angular'],
  [EnumTechTool.AngularJs, 'AngularJS'],
  [EnumTechTool.ApacheHBase, 'Apache HBase'],
  [EnumTechTool.ApacheSpark, 'Apache Spark'],
  [EnumTechTool.AsPdotNet, 'ASP.NET'],
  [EnumTechTool.BackboneJs, 'Backbone.js'],
  [EnumTechTool.Bootstrap, 'Bootstrap'],
  [EnumTechTool.CakePhp, 'CakePHP'],
  [EnumTechTool.Cassandra, 'Cassandra'],
  [EnumTechTool.Cordova, 'Cordova'],
  [EnumTechTool.Django, 'Django'],
  [EnumTechTool.Drupal, 'Drupal'],
  [EnumTechTool.Elasticsearch, 'Elasticsearch'],
  [EnumTechTool.Electron, 'Electron'],
  [EnumTechTool.EmberJs, 'Ember.js'],
  [EnumTechTool.Express, 'Express'],
  [EnumTechTool.FastApi, 'FastAPI'],
  [EnumTechTool.Firebase, 'Firebase'],
  [EnumTechTool.Flask, 'Flask'],
  [EnumTechTool.Flutter, 'Flutter'],
  [EnumTechTool.Gatsby, 'Gatsby'],
  [EnumTechTool.Hadoop, 'Hadoop'],
  [EnumTechTool.IbmDb2, 'IBM Db2'],
  [EnumTechTool.Joomla, 'Joomla'],
  [EnumTechTool.JQuery, 'jQuery'],
  [EnumTechTool.Laravel, 'Laravel'],
  [EnumTechTool.Meteor, 'Meteor'],
  [EnumTechTool.MicrosoftSqlServer, 'Microsoft SQL Server'],
  [EnumTechTool.MongoDb, 'MongoDB'],
  [EnumTechTool.MySqlOrMariaDb, 'MySQL / MariaDB'],
  [EnumTechTool.NextJs, 'Next.js'],
  [EnumTechTool.NodeJs, 'Node.js'],
  [EnumTechTool.NumPy, 'NumPy'],
  [EnumTechTool.OracleDatabase, 'Oracle Database'],
  [EnumTechTool.Pandas, 'Pandas'],
  [EnumTechTool.Play, 'Play'],
  [EnumTechTool.PostgreSql, 'PostgreSQL'],
  [EnumTechTool.PyTorch, 'PyTorch'],
  [EnumTechTool.Qt, 'Qt'],
  [EnumTechTool.Quasar, 'Quasar'],
  [EnumTechTool.React, 'React'],
  [EnumTechTool.ReactNative, 'React Native'],
  [EnumTechTool.Redis, 'Redis'],
  [EnumTechTool.RubyOnRails, 'Ruby on Rails'],
  [EnumTechTool.SailsJs, 'Sails.js'],
  [EnumTechTool.Spring, 'Spring'],
  [EnumTechTool.SqLite, 'SQLite'],
  [EnumTechTool.Struts, 'Struts'],
  [EnumTechTool.Svelte, 'Svelte'],
  [EnumTechTool.Symfony, 'Symfony'],
  [EnumTechTool.TensorFlow, 'TensorFlow'],
  [EnumTechTool.Unity, 'Unity'],
  [EnumTechTool.UnrealEngine, 'Unreal_Engine'],
  [EnumTechTool.VueJs, 'Vue.js'],
  [EnumTechTool.WordPress, 'WordPress'],
]);

export const mapVerificationStatus = new Map([
  ['Unverified', 'Unverified'],
  ['Verified', 'Verified'],
  ['TDA_Verified', 'TDA Verified'],
]);

export const mapApplicationStatus = new Map([
  ['Open', 'Open'],
  ['Closed', 'Closed'],
]);

export const mapProjectOutlineStatus = new Map([
  ['Pitching', 'Pitching'],
  ['Negotiation', 'Negotiation'],
  ['Finished', 'Finished'],
  ['Cancelled', 'Cancelled'],
]);

export const mapPromotableEntities = new Map([
  ['Article', 'Article'],
  ['Case_Study', 'Case Study'],
  ['Tool', 'Tool'],
  ['Predefined_Project', 'Predefined Project'],
  ['Timed_Project', 'Timed Project'],
]);

export const mapPitchStatus = new Map([
  ['Project', 'Project'],
  ['Round_1', 'Round 1'],
  ['Round_2', 'Round 2'],
]);

export const mapClaimCompany = new Map([
  ['Approved_Private', 'Approved Private'],
  ['Approved_Public', 'Approved Public'],
  ['Buyer', 'Buyer'],
  ['Seller_Product', 'Seller Product'],
  ['Seller_Service', 'Seller Service'],
]);

export const mapProjectStatus = new Map([
  [EnumProjectStatus.InProgress, 'In progress'],
  [EnumProjectStatus.Completed, 'Completed'],
  [EnumProjectStatus.Pending, 'Pending'],
  [EnumProjectStatus.Canceled, 'Canceled'],
]);

export const mapProductType = new Map([
  ['Foo', 'Foo'],
  ['Bar', 'Bar'],
  ['Baz', 'Baz'],
]);

export const mapSubtaskStatus = new Map([
  ['inProgress', 'In progress'],
  ['complete', 'Complete'],
  ['pending', 'Pending'],
  ['Pending', 'Pending'],
  ['Completed', 'Completed'],
  ['Canceled', 'Canceled'],
]);

export const mapWorkStatus = new Map([
  ['Finished', 'Finished'],
  ['In_progress', 'In progress'],
  ['Paused', 'Paused'],
  ['Pending', 'Pending'],
]);

/*
  TODO: This is just a temp placeholder, refactor this
  afterwards!
*/
export const mapTempStatusEnum = new Map([
  ['Draft', 'Draft'],
  ['Active', 'Active'],
  ['Paid', 'Paid'],
  ['Finished', 'Finished'],
  ['In_progress', 'In progress'],
  ['Paused', 'Paused'],
  ['Cancelled', 'Cancelled'],
  ['Pending', 'Pending'],
  ['Due', 'Due'],
]);

export const mapTime = new Map([
  ['0:30', '0:30'],
  ['1:00', '1:00'],
  ['1:30', '1:30'],
  ['2:00', '2:00'],
  ['2:30', '2:30'],
  ['3:00', '3:00'],
  ['3:30', '3:30'],
  ['4:00', '4:00'],
  ['4:30', '4:30'],
  ['5:00', '5:00'],
  ['5:30', '5:30'],
  ['6:00', '6:00'],
  ['6:30', '6:30'],
  ['7:00', '7:00'],
  ['7:30', '7:30'],
  ['8:00', '8:00'],
  ['8:30', '8:30'],
  ['9:00', '9:00'],
  ['9:30', '9:30'],
  ['10:00', '10:00'],
  ['10:30', '10:30'],
  ['11:00', '11:00'],
  ['11:30', '11:30'],
  ['12:00', '12:00'],
  ['12:30', '12:30'],
  ['13:00', '13:00'],
  ['13:30', '13:30'],
  ['14:00', '14:00'],
  ['14:30', '14:30'],
  ['15:00', '15:00'],
  ['15:30', '15:30'],
  ['16:00', '16:00'],
  ['16:30', '16:30'],
  ['17:00', '17:00'],
  ['17:30', '17:30'],
  ['18:00', '18:00'],
  ['18:30', '18:30'],
  ['19:00', '19:00'],
  ['19:30', '19:30'],
  ['20:00', '20:00'],
  ['20:30', '20:30'],
  ['21:00', '21:00'],
  ['21:30', '21:30'],
  ['22:00', '22:00'],
  ['22:30', '22:30'],
  ['23:00', '23:00'],
  ['23:30', '23:30'],
  ['00:00', '00:00'],
]);

export const mapProjectPaymentStatus = new Map([
  [PaymentStatus.InProgress, 'In progress'],
  [PaymentStatus.Late, 'Late'],
  [PaymentStatus.Paid, 'Paid'],
]);
export const mapProjectContractStatus = new Map([
  [EnumProjectContractStatus.Canceled, 'Canceled'],
  [EnumProjectContractStatus.Accepted, 'Accepted'],
  [EnumProjectContractStatus.Draft, 'Draft'],
  [EnumProjectContractStatus.Active, 'Active'],
  [EnumProjectContractStatus.Negotiation, 'Negotiation'],
]);

export const mapProjectMilestoneStatus = new Map([
  [EnumProjectMilestoneStatus.Pending, 'Pending'],
  [EnumProjectMilestoneStatus.InProgress, 'In progress'],
  [EnumProjectMilestoneStatus.Completed, 'Completed'],
  [EnumProjectMilestoneStatus.Approved, 'Approved'],
  [EnumProjectMilestoneStatus.Negotiation, 'Negotiation'],
  [EnumProjectMilestoneStatus.UnderReview, 'Under review'],
]);

export const mapTimedProjectStatus = new Map([
  [EnumTimedProjectStatus.Pending, 'Pending'],
  [EnumTimedProjectStatus.Draft, 'Draft'],
  [EnumTimedProjectStatus.Active, 'Active'],
  [EnumTimedProjectStatus.Closed, 'Closed'],
]);
export const mapCaseStudyStatus = new Map([
  [EnumCaseStudyStatus.Pending, 'Pending'],
  [EnumCaseStudyStatus.Draft, 'Draft'],
  [EnumCaseStudyStatus.Archived, 'Archived'],
  [EnumCaseStudyStatus.Published, 'Published'],
  [EnumCaseStudyStatus.Verified, 'Verified'],
]);

export const mapDuration = new Map([
  ['0:30', '0:30'],
  ['1:00', '1:00'],
  ['1:30', '1:30'],
  ['2:00', '2:00'],
  ['2:30', '2:30'],
  ['3:00', '3:00'],
  ['3:30', '3:30'],
  ['4:00', '4:00'],
  ['4:30', '4:30'],
  ['5:00', '5:00'],
  ['5:30', '5:30'],
  ['6:00', '6:00'],
  ['6:30', '6:30'],
  ['7:00', '7:00'],
  ['7:30', '7:30'],
  ['8:00', '8:00'],
  ['8:30', '8:30'],
  ['9:00', '9:00'],
  ['9:30', '9:30'],
  ['10:00', '10:00'],
  ['10:30', '10:30'],
  ['11:00', '11:00'],
  ['11:30', '11:30'],
  ['12:00', '12:00'],
  ['12:30', '12:30'],
  ['13:00', '13:00'],
  ['13:30', '13:30'],
  ['14:00', '14:00'],
  ['14:30', '14:30'],
  ['15:00', '15:00'],
  ['15:30', '15:30'],
  ['16:00', '16:00'],
  ['16:30', '16:30'],
  ['17:00', '17:00'],
  ['17:30', '17:30'],
  ['18:00', '18:00'],
  ['18:30', '18:30'],
  ['19:00', '19:00'],
  ['19:30', '19:30'],
  ['20:00', '20:00'],
  ['20:30', '20:30'],
  ['21:00', '21:00'],
  ['21:30', '21:30'],
  ['22:00', '22:00'],
  ['22:30', '22:30'],
  ['23:00', '23:00'],
  ['23:30', '23:30'],
  ['24:00', '24:00'],
]);

export const mapRecurringInterval = new Map([
  [2, '2'],
  [3, '3'],
  [4, '4'],
  [5, '5'],
  [6, '6'],
  [7, '7'],
  [8, '8'],
  [9, '9'],
  [10, '10'],
  [11, '11'],
  [12, '12'],
]);

export enum CardTypeEnum {
  ToolCard = 'Tool_Card',
  ServicePackageCard = 'Service_Package_Card',
  PriceCard = 'Price_Card',
  CompanyCard = 'Company_Card',
  CaseStudyCard = 'Case_Study_Card',
  TimedProjectCard = 'Timed_Project_Card',
  ArticleCard = 'Article_Card',
}

export enum CarouselType {
  CASE_STUDY = 'Case_study',
  SERVICE_PACKAGE = 'Service_package',
}

export enum EnumDeleteModalType {
  Task = 'task',
  Subtask = 'subtask',
  Milestone = 'milestone,',
}
export enum EnumPaymentType {
  Paid = 'Paid',
  In_progress = 'In progress',
  Late = 'Late',
}

export enum EnumModalActionType {
  Delete = 'Delete',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
}

export const mapTaskStatus = new Map([
  ['pending', 'Pending'],
  ['in_progress', 'In progress'],
  ['completed', 'Completed'],
  ['canceled', 'Canceled'],
]);

export const mapMilestoneStatus = new Map([
  ['Pending', 'Pending'],
  ['In_progress', 'In progress'],
  ['Completed', 'Completed'],
  ['Canceled', 'Canceled'],
  ['Unapproved', 'Unapproved'],
]);

export const mapTimedProjectPitchStatus = new Map([
  [EnumTimedProjectPitchStatus.Draft, 'Draft'],
  [EnumTimedProjectPitchStatus.Pending, 'Pending'],
  [EnumTimedProjectPitchStatus.FirstRound, 'Round 1'],
  [EnumTimedProjectPitchStatus.SecondRound, 'Round 2'],
  [EnumTimedProjectPitchStatus.FirstRoundRejected, 'Rejected'],
  [EnumTimedProjectPitchStatus.SecondRoundRejected, 'Rejected'],
  [EnumTimedProjectPitchStatus.FirstRoundWithdrawn, 'Withdrawn'],
  [EnumTimedProjectPitchStatus.SecondRoundWithdrawn, 'Withdrawn'],
  [EnumTimedProjectPitchStatus.Chosen, 'Chosen'],
  [EnumTimedProjectPitchStatus.Hired, 'Hired'],
  [EnumTimedProjectPitchStatus.Archived, 'Archived'],
]);

export const currencyMapper = (currencyName: EnumCurrency) => {
  switch (currencyName) {
    case EnumCurrency.Usd:
      return '$';
    case EnumCurrency.Hrk:
      return 'kn';
    case EnumCurrency.Btc:
      return 'BTC';
    case EnumCurrency.Eth:
      return 'ETH';
    case EnumCurrency.Inr:
      return 'inr';
    default:
      return null;
  }
};

export const mapProviderScope = new Map([
  [EnumServiceProviderScope.Global, 'Global'],
  [EnumServiceProviderScope.Local, 'Local'],
  [EnumServiceProviderScope.Undefined, "Don't mind"],
]);

enum States {
  'AL' = 'AL',
  'AK' = 'AK',
  'AS' = 'AS',
  'AZ' = 'AZ',
  'AR' = 'AR',
  'CA' = 'CA',
  'CO' = 'CO',
  'CT' = 'CT',
  'DE' = 'DE',
  'DC' = 'DC',
  'FM' = 'FM',
  'FL' = 'FL',
  'GA' = 'GA',
  'GU' = 'GU',
  'HI' = 'HI',
  'ID' = 'ID',
  'IL' = 'IL',
  'IN' = 'IN',
  'IA' = 'IA',
  'KS' = 'KS',
  'KY' = 'KY',
  'LA' = 'LA',
  'ME' = 'ME',
  'MH' = 'MH',
  'MD' = 'MD',
  'MA' = 'MA',
  'MI' = 'MI',
  'MN' = 'MN',
  'MS' = 'MS',
  'MO' = 'MO',
  'MT' = 'MT',
  'NE' = 'NE',
  'NV' = 'NV',
  'NH' = 'NH',
  'NJ' = 'NJ',
  'NM' = 'NM',
  'NY' = 'NY',
  'NC' = 'NC',
  'ND' = 'ND',
  'MP' = 'MP',
  'OH' = 'OH',
  'OK' = 'OK',
  'OR' = 'OR',
  'PW' = 'PW',
  'PA' = 'PA',
  'PR' = 'PR',
  'RI' = 'RI',
  'SC' = 'SC',
  'SD' = 'SD',
  'TN' = 'TN',
  'TX' = 'TX',
  'UT' = 'UT',
  'VT' = 'VT',
  'VI' = 'VI',
  'VA' = 'VA',
  'WA' = 'WA',
  'WV' = 'WV',
  'WI' = 'WI',
  'WY' = 'WY',
}

export const mapStates = new Map([
  [States.AK, 'Alaska'],
  [States.AL, 'Alabama'],
  [States.AR, 'Arkansas'],
  [States.AS, 'American Samoa'],
  [States.AZ, 'Arizona'],
  [States.CA, 'California'],
  [States.CO, 'Colorado'],
  [States.CT, 'Connecticut'],
  [States.DC, 'District Of Columbia'],
  [States.DE, 'Delaware'],
  [States.FL, 'Florida'],
  [States.GA, 'Georgia'],
  [States.GU, 'Guam'],
  [States.HI, 'Hawaii'],
  [States.IA, 'Iowa'],
  [States.ID, 'Idaho'],
  [States.IL, 'Illinois'],
  [States.IN, 'Indiana'],
  [States.KS, 'Kansas'],
  [States.KY, 'Kentucky'],
  [States.LA, 'Louisiana'],
  [States.MA, 'Massachusetts'],
  [States.MD, 'Maryland'],
  [States.ME, 'Maine'],
  [States.MI, 'Michigan'],
  [States.MN, 'Minnesota'],
  [States.MO, 'Missouri'],
  [States.MP, 'Northern Mariana Islands'],
  [States.MS, 'Mississippi'],
  [States.MT, 'Montana'],
  [States.NC, 'North Carolina'],
  [States.ND, 'North Dakota'],
  [States.NE, 'Nebraska'],
  [States.NH, 'New Hampshire'],
  [States.NJ, 'New Jersey'],
  [States.NM, 'New Mexico'],
  [States.NV, 'Nevada'],
  [States.NY, 'New York'],
  [States.OH, 'Ohio'],
  [States.OK, 'Oklahoma'],
  [States.OR, 'Oregon'],
  [States.PA, 'Pennsylvania'],
  [States.PR, 'Puerto Rico'],
  [States.RI, 'Rhode Island'],
  [States.SC, 'South Carolina'],
  [States.SD, 'South Dakota'],
  [States.TN, 'Tennessee'],
  [States.TX, 'Texas'],
  [States.UT, 'Utah'],
  [States.VA, 'Virginia'],
  [States.VI, 'Virgin Islands'],
  [States.VT, 'Vermont'],
  [States.WA, 'Washington'],
  [States.WI, 'Wisconsin'],
  [States.WV, 'West Virginia'],
  [States.WY, 'Wyoming'],
]);

export const mapEventTypes = new Map([
  [EnumEventType.Event, 'Meeting'],
  [EnumEventType.Milestone, 'Milestone due'],
  [EnumEventType.Payment, 'Payment due'],
  [EnumEventType.PitchDeadline, 'Pitching closes '],
  [EnumEventType.Subtask, 'Subtask due'],
  [EnumEventType.Task, 'Task due'],
]);
