import { Stack, Typography, Divider } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import CustomButton from '../button/CustomButton';
import CustomModal from '../modal/CustomModal';

export interface ConfirmModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  title?: string;
  message?: string | JSX.Element;
  showCancelButton?: boolean;
  iconProps?: {
    wrapperBackgroundColor: string;
    icon: ReactNode;
  };
  width?: string;
  maxWidth?: string;
  automaticallyCloseModalOnButtonClick?: boolean;
  isLoading?: boolean;
}

const ConfirmModal = ({
  isModalOpen,
  setIsModalOpen,
  confirmButtonText = 'Confirm',
  onCancel,
  onConfirm,
  title,
  message,
  showCancelButton = true,
  iconProps,
  width = '32%',
  maxWidth = 'none',
  automaticallyCloseModalOnButtonClick = true,
  isLoading = false,
}: ConfirmModalProps) => (
  <CustomModal
    open={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    width={width}
    sx={{ maxWidth }}
  >
    <Stack spacing={5} justifyContent="space-between">
      <Stack spacing={4}>
        <Stack spacing={3}>
          <Stack spacing={2} direction="row" alignItems="center">
            {iconProps && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: iconProps?.wrapperBackgroundColor,
                  width: '48px',
                  height: '48px',
                  borderRadius: '6px',
                  px: 2,
                  py: 1,
                }}
              >
                {iconProps?.icon}
              </Stack>
            )}

            <Typography variant="heading4" color="primaryDark.600">
              {title}
            </Typography>
          </Stack>

          <Divider sx={{ borderColor: '#E5E8FF' }} />
        </Stack>

        {message && typeof message === 'string' ? (
          <Typography color="primaryDark.500">{message}</Typography>
        ) : (
          message
        )}
      </Stack>

      <Stack spacing={2.5}>
        <Divider sx={{ borderColor: '#E5E8FF' }} />

        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          {showCancelButton && (
            <CustomButton
              variant="secondary"
              onClick={() => {
                onCancel?.();
                setIsModalOpen(false);
              }}
            >
              Cancel
            </CustomButton>
          )}

          <CustomButton
            variant="primary"
            onClick={() => {
              onConfirm?.();
              automaticallyCloseModalOnButtonClick ? setIsModalOpen(false) : {};
            }}
            disabled={isLoading}
            loading={isLoading}
          >
            {confirmButtonText}
          </CustomButton>
        </Stack>
      </Stack>
    </Stack>
  </CustomModal>
);

export default ConfirmModal;
