import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface CreateArticleState {
  createArticleInput: Record<string, any>;
  setCreateArticleInput: (value: Record<string, any>) => void;
  deleteEverything: () => void;
}

const useCreateArticleStore = create<CreateArticleState>(devtools((set) => ({
  createArticleInput: {},
  setCreateArticleInput: (value: Record<string, any>) => set((state) => (
    { createArticleInput: { ...state.createArticleInput, ...value } }
  )),
  deleteEverything: () => set({
    createArticleInput: {},
  }),
})));

export default useCreateArticleStore;
