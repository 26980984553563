/* eslint-disable import/no-unresolved */
import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CustomFooterDto } from 'tdc-web-backend/custom-footer/schemas';
import { mapService } from 'tdc-web-backend/enums/enums';
import { Add, Cancel } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import CustomInput from '../../../components/custom-input/CustomInput';
import CustomButton from '../../../components/button/CustomButton';

const FooterEdit = () => {
  const formMethods = useForm({});

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'entries',
  });

  const { footerType } = useParams();

  const { data: response } = useGetMany<CustomFooterDto>({
    resource: `/custom-footer?type=${footerType}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,

    onSuccess: (data) => {
      formMethods.reset({
        entries: data.data.results?.[0].entries,
        type: footerType,
      });
    },
  });

  const footerData = response?.data.results?.[0];

  const { mutate: updateFooter } = useUpdate({
    resource: '/custom-footer',
  });

  const onSubmit = (data: any) => {
    updateFooter({
      id: footerData?.id as string,
      data,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        sx={{
          width: '100%',
          height: '100%',
        }}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Stack direction="column" p={2} width="60%" gap={2}>
          {fields.map((field, index) => (
            <Stack
              p={2}
              sx={{
                border: '1px solid',
                borderColor: 'primaryDark.300',
                borderRadius: '8px',
                boxShadow: '4px 4px 10px rgba(56, 69, 114, 0.1)',
              }}
            >
              <Cancel
                sx={{ color: 'primaryDark.500', alignSelf: 'end', cursor: 'pointer' }}
                onClick={() => remove(index)}
              />
              <CustomInput
                key={field.id}
                sx={{
                  minHeight: 10,
                  minWidth: 'fit-content',
                }}
                input={<CustomInput.Text />}
                name={`entries.${index}.title`}
                placeholder="Title"
                label="Title"
              />
              <CustomInput
                sx={{
                  minHeight: 10,
                  minWidth: 'fit-content',
                }}
                input={<CustomInput.SearchSelect />}
                name={`entries.${index}.services`}
                placeholder="Services"
                label="Services"
                multiple
                choices={mapService}
                withChips
              />
            </Stack>
          ))}
          <CustomButton
            variant="primaryLight"
            endIcon={<Add />}
            onClick={() => append({ title: '', services: [] })}
          >
            Add new
          </CustomButton>
          <CustomButton type="submit">Submit</CustomButton>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default FooterEdit;
