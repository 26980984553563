/* eslint-disable import/no-unresolved */
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FeaturedCompaniesDto } from 'tdc-web-backend/featured-companies/schema';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useNavigate } from 'react-router-dom';
import { EnumService } from 'tdc-web-backend/enums/enums';
import CustomButton from '../../../../components/button/CustomButton';
import CompanyCard from '../../../../components/public-screen-components/CompanyCard';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { urlSearchParamsStringMapper } from '../../../../utils/helpers';

const CompaniesPerCategory = ({
  data,
  sectionTitle,
  bgcolor,
}: {
  data: FeaturedCompaniesDto | undefined;
  sectionTitle: string;
  bgcolor?: string;
}) => {
  const navigate = useNavigate();
  const queryParam =
    data?.linkedServices &&
    urlSearchParamsStringMapper(data?.linkedServices as EnumService[], 'service');
  return (
    <Stack bgcolor={bgcolor} sx={{ position: 'relative' }}>
      <Stack
        direction="column"
        alignItems="center"
        alignContent="flex-start"
        py={10}
        gap={5}
        sx={{ mx: { xs: '1.5rem', gr: 'auto' }, width: { gr: '80rem' } }}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Typography variant="publicBody1">{sectionTitle}</Typography>
          <CustomButton
            variant="primaryText"
            onClick={() => navigate(`/how-to-manage-buyer/seller-subarchive?${queryParam}`)}
          >
            View all
          </CustomButton>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            maxWidth: { xs: 'none', bg: 'none', lg: 'none' },
          }}
          direction={{ xs: 'column', bg: 'row' }}
          spacing={3}
          gap={0}
          justifyContent="center"
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            gap={3}
            sx={{
              maxWidth: { xs: 'none', bg: '30rem', lg: 'none' },
              width: '100%',
            }}
          >
            {data?.companies?.slice(0, 2).map((company: Partial<CompanyDto>) => (
              <CustomReferenceField
                resource="companies"
                key={company.id}
                displayName={company.name as string}
                id={company.id as string}
                renderComponent={(item) => <CompanyCard companyData={item} key={item.id} />}
              />
            ))}
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            gap={3}
            sx={{
              width: '100%',
              maxWidth: { xs: 'none', bg: '30rem', lg: 'none' },
            }}
          >
            {data?.companies?.slice(2, 4).map((company: Partial<CompanyDto>) => (
              <CustomReferenceField
                resource="companies"
                key={company.id}
                displayName={company.name as string}
                id={company.id as string}
                renderComponent={(item) => <CompanyCard companyData={item} key={item.id} />}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompaniesPerCategory;
