import { SxProps, Theme, Typography } from '@mui/material';

type Props = {
  text: string;
  required?: boolean;
  sx?: SxProps<Theme> | undefined;
  adornment?: JSX.Element | null;
  textVariant?: any;
  color?: string;
};

const BaseInputLabel = ({
  text,
  required,
  sx,
  adornment = null,
  textVariant = 'heading5',
  color = 'primaryDark.500',
}: Props) => (
  <Typography
    variant={textVariant}
    color={color}
    sx={{
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      mb: 1,
      ...sx,
    }}
  >
    {/* {required && (
      <Typography color="secondaryBlue.700" sx={{ lineHeight: 1 }}>
        *&nbsp;
      </Typography>
    )} */}
    {text?.toUpperCase()}
    {adornment}
  </Typography>
);

export default BaseInputLabel;
