import { Stack, Typography } from '@mui/material';
import StarRating from '../star-rating/StarRating';

type ClientReviewProps = {
  title: string;
  description: string;
  rating: number;
  detailedView?: boolean;
};

const ClientReview = ({ title, rating, description, detailedView = false }: ClientReviewProps) => (
  <Stack flex={1} spacing={0.5}>
    <Stack direction="row" mb={0.5} alignItems="center" justifyContent="space-between">
      <Typography
        color="primaryDark.500"
        variant="publicBody5Regular"
        sx={{ mr: 3, textTransform: 'none' }}
      >
        {title}
      </Typography>

      <StarRating rating={rating} />
    </Stack>

    <Stack
      sx={{
        height: detailedView ? 'auto' : 0,
        transition: '0.25s linear height',
        overflow: 'hidden',
      }}
    >
      <Typography mb={1.5} color="primaryDark.600" variant="body3">
        {description}
      </Typography>
    </Stack>
  </Stack>
);

export default ClientReview;
