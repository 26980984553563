import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, ListItemIcon, MenuItem, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// eslint-disable-next-line import/no-unresolved
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import Button from '../../../../components/button/Button';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import { SubtaskModalProps } from './SubtaskEditModal';

const SubtaskDeleteModal = ({ subtask }: SubtaskModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: deleteOne } = useDeleteOne<SubtaskDto>({ resource: '/subtasks' });
  const refresh = useRefresh();

  const handleSubmitDelete = () => {
    deleteOne(
      { id: subtask.id as string },
      {
        onSuccess: (data) => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon />
        </ListItemIcon>
        Delete
      </MenuItem>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      my: 2,
                    }}
                  >
                    Delete subtask
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 500,
                      mt: 1,
                    }}
                  >
                    Are you sure you want to delete the subtask?
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Button
                    variant="text"
                    setBorderRadius
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    setBorderRadius
                    onButtonClick={handleSubmitDelete}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};

export default SubtaskDeleteModal;
