import { Divider, Stack, Typography } from '@mui/material';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EnumCaseStudyStatus,
  mapCountry,
  mapIndustry,
  mapProjectSize,
  mapService,
} from 'tdc-web-backend/enums/enums';
import CustomButton from '../../../components/button/CustomButton';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { formatDateFns } from '../../../utils/helpers';
import { primaryDark } from '../../../color';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../enums';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import { CaseStudyEditorMenu } from '../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../components/editor/Editor';
import ChipGroup from '../../Dashboard/CaseStudies/CaseStudiesDetails/CaseStudyChipGroup';
import { ReactComponent as TrophyIcon } from '../../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as BullseyeIcon } from '../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../assets/icons/layout-icons/LightBulbIcon.svg';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';

const CaseStudiesDetails = () => {
  const { caseStudyId } = useParams();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { data } = useReferenceOne<CaseStudyContentDto>({
    id: caseStudyId,
    resource: 'case-studies-content',
    enabled: !!caseStudyId,
  });

  const { mutate: approve, isLoading } = useUpdate({ resource: '/case-studies' });

  const handleCaseStudy = (action: string) => {
    approve(
      {
        id: data?.caseStudy,
        suffix: action,
      },
      {
        onSuccess: () => {
          refresh();
          navigate('../');
        },
      },
    );
  };

  return (
    <Box>
      <Stack p={6}>
        <Typography variant="heading3" color="primaryDark.600">
          {data?.title}
        </Typography>
        <Typography variant="body3" pt={4} color="primaryDark.500">
          {formatDateFns(data?.published, '---')}
        </Typography>
        <Stack direction="row" py={3} alignItems="center" justifyContent="space-between">
          <Stack direction="row" gap={4} alignItems="center">
            <CustomButton
              loading={isLoading}
              variant="secondary"
              onClick={() => handleCaseStudy('reject')}
              disabled={data?.status !== EnumCaseStudyStatus.Pending}
            >
              Reject
            </CustomButton>
            <CustomButton
              disabled={data?.status !== EnumCaseStudyStatus.Pending}
              loading={isLoading}
              onClick={() => handleCaseStudy('approve')}
            >
              Approve
            </CustomButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack width="100%">
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="services" choices={mapService} values={data?.details?.services} />
            <ChipGroup
              label="tech platforms"
              choices={mapTechPlatform}
              values={data?.details?.platforms}
            />
          </Stack>
          <Divider />
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="tech tools" choices={mapTechTool} values={data?.details?.tools} />
            <ChipGroup
              label="Programming languages"
              choices={mapTechLanguage}
              values={data?.details?.languages}
            />
          </Stack>
          <Divider />
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="industry" choices={mapIndustry} values={data?.details?.industries} />
            <ChipGroup
              label="Target market"
              choices={mapCountry}
              values={data?.details?.geographyFocus}
            />
          </Stack>
          <Divider />
          <Stack width="100%" py={2}>
            <BaseInputLabel text="Project size" />
            <Typography variant="heading4">
              {data?.details?.projectSize ? mapProjectSize.get(data.details.projectSize) : '--'}
            </Typography>
          </Stack>
          <Box
            sx={{ m: 4, borderRadius: '3px', maxWidth: '100%' }}
            component="img"
            src={data?.file}
          />
          <Divider />
          {data?.description?.challenges && (
            <Stack py={2} spacing={1}>
              <Stack direction="row" spacing={1}>
                <TrophyIcon fill={primaryDark[500]} />
                <BaseInputLabel text="Project goals" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.description?.goals}
                isEditable={false}
                key="goals"
              />
            </Stack>
          )}
          <Divider />
          {data?.description?.challenges && (
            <Stack py={2} spacing={1}>
              <Stack direction="row" spacing={1}>
                <BullseyeIcon fill={primaryDark[500]} />
                <BaseInputLabel text="challenges" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.description?.challenges}
                isEditable={false}
                key="challenges"
              />
            </Stack>
          )}
          <Divider />
          {data?.description?.solutions && (
            <Stack py={2} spacing={1}>
              <Stack direction="row" spacing={1}>
                <LightBulbIcon fill={primaryDark[500]} />
                <BaseInputLabel text="solution" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.description?.solutions}
                isEditable={false}
                key="solutions"
              />
            </Stack>
          )}
          <Divider />
          {data?.description?.results && (
            <Stack py={2} mb={8} spacing={1}>
              <Stack direction="row" spacing={1}>
                <BriefcaseCheckIcon fill={primaryDark[500]} />
                <BaseInputLabel text="Results" />
              </Stack>
              <Editor
                items={CaseStudyEditorMenu}
                content={data?.description?.results}
                isEditable={false}
                key="results"
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CaseStudiesDetails;
