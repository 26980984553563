import { Checkbox } from '@mui/material';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ReactComponent as CheckboxChecked } from '../../assets/icons/layout-icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from '../../assets/icons/layout-icons/CheckboxUnchecked.svg';

interface CustomizedCheckboxProps {
  field?: ControllerRenderProps<FieldValues, string>;
}

// TODO: use this component everywhere over the old one
const CustomizedCheckbox = ({ field }: CustomizedCheckboxProps) => (
  <Checkbox
    {...field}
    icon={<CheckboxUnchecked style={{ width: 18, height: 18 }} />}
    checkedIcon={<CheckboxChecked style={{ width: 18, height: 18 }} />}
    sx={{ p: 0 }}
  />
);

export default CustomizedCheckbox;
