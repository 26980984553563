import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../../layout/public-layout/navigation/Navigation';
import Header from './components/Header';

const HireMainLayot = ({ children }: { children: ReactNode }) => (
  <Stack
    mt={`${PUBLIC_APPBAR_HEIGHT}px`}
    pt={10}
    gap={3}
    justifyContent="center"
    alignItems="center"
  >
    <Header />
    {children}
  </Stack>
);
export default HireMainLayot;
