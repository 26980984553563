import { Stack, SxProps, Typography } from '@mui/material';

const PreviewText = ({ label, value, sx }: { label: string; value: string; sx?: SxProps }) => {
  if (!value) return null;

  return (
    <Stack sx={sx} spacing={1}>
      <Typography variant="heading4" color="primaryDark.500" sx={{ fontSize: '12px' }}>
        {label}
      </Typography>
      <Typography
        variant="body1"
        color="primaryDark.700"
        sx={{
          fontFamily: 'Gellix-Semibold',
          fontSize: '16px',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default PreviewText;
