import { ReactComponent as LinkedinIcon } from '../../../assets/img/public-pages/linkedin-icon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/img/public-pages/instagram-icon.svg';
import { ReactComponent as XIcon } from '../../../assets/img/public-pages/x-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/img/public-pages/facebook-icon.svg';
import { ReactComponent as TiktokIcon } from '../../../assets/img/public-pages/tiktok-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/img/public-pages/youtube-icon.svg';
import frc from '../../../assets/img/public-pages/frc-white_dark.png';
import fin_eu from '../../../assets/img/public-pages/financira_eu_next_generation_dark.png';
import esif from '../../../assets/img/public-pages/esif.svg';
import hamag from '../../../assets/img/public-pages/hamag.png';
import eusif from '../../../assets/img/public-pages/eusif.svg';
import croai from '../../../assets/img/public-pages/croai.svg';
import feelsgood from '../../../assets/img/public-pages/feelsgood.png'

const iconStyles = {
  cursor: 'pointer',
};

export const socialMediaIcons = [
  {
    id: 1,
    icon: <LinkedinIcon style={{ ...iconStyles }} />,
    link: 'https://www.linkedin.com/company/sponaglobal/',
  },
  {
    id: 2,
    icon: <InstagramIcon style={{ ...iconStyles }} />,
    link: 'https://www.instagram.com/spona.io/',
  },
  {
    id: 3,
    icon: <XIcon style={{ ...iconStyles }} />,
    link: 'https://twitter.com/spona_global',
  },
  {
    id: 4,
    icon: <FacebookIcon style={{ ...iconStyles }} />,
    link: 'https://www.facebook.com/spona.io',
  },
  {
    id: 5,
    icon: <TiktokIcon style={{ ...iconStyles }} />,
    link: 'https://www.tiktok.com/@spona.io',
  },
  {
    id: 6,
    icon: <YoutubeIcon style={{ ...iconStyles }} />,
    link: 'https://www.youtube.com/channel/UCPFYUmbMBCmsT_3oz6h-2kw',
  },
];

export const logos = [
  {
    id: 1,
    src: frc,
    width: 'initial',
  },
  {
    id: 2,
    src: fin_eu,
    width: 'initial',
  },
  {
    id: 3,
    src: eusif,
    width: 140,
  },
  {
    id: 4,
    src: hamag,
    width: 140,
  },
  {
    id: 5,
    src: esif,
    width: 'initial',
  },
  {
    id: 6,
    src: croai,
    width: 'initial',
  },
  {
    id: 7,
    src: feelsgood,
    width: 140,
  }
];
