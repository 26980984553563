import { useLayoutEffect, useState } from 'react';

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

const useGetScrollIndex = (elements:HTMLElement[], offset = 0) => {
  const [activeIdx, setActiveIdx] = useState(0);

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = elements
        .map((element, i) => {
          if (!element) return { i, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { i, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));
      if (position) { setActiveIdx(position?.i); }
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [elements, offset]);

  return activeIdx;
};

export default useGetScrollIndex;
