import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { addMonths, parseISO } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { mapCurrency } from 'tdc-web-backend/enums/enums';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledSwitchInput from '../../../../components/custom-inputs/ControlledSwitchInput';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';
import RecurringMonthlyBudgetDisplay from '../RecurringMonthlyBudgetDisplay';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import { mapRecurringInterval } from '../../../../enums';
import { primaryDark, secondaryBlue } from '../../../../color';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { simpleDateFormatter } from '../../../../utils/helpers';
import CustomButton from '../../../../components/button/CustomButton';
import ControlledContractMilestoneNumberInput from '../../../../components/custom-inputs/ControlledContractMilestoneNumberInput';
import ContractMilestoneBudgetProgress from '../ContractMilestoneBudgetProgress';

const MilestonesForm = () => {
  const { control, getValues, setValue, watch, trigger } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'body.milestones',
  });

  function handleDefaultSliderValue() {
    const initialAmount = getValues('initialAmount');
    const milestones = getValues('body.milestones');
    const newArray: number[] = milestones.map((milestone: MilestoneDto) => milestone.budget);
    const totalSum = newArray.reduce((a, b) => a + b);
    return initialAmount - totalSum;
  }

  // Milestone display inherited value converter
  function handleSliderChange(index: number, value: any) {
    if (value === 0) return;
    const { milestones } = value.body;

    const newArray: number[] = milestones.map((milestone: any) =>
      Number.parseFloat(milestone.budget),
    );
    const totalSum = newArray.reduce((a, b) => a + b);

    if (totalSum > value.initialAmount) {
      const remainingTotal = totalSum - value.initialAmount;
      const finalValue = newArray[index] - remainingTotal;
      setValue(`body.milestones.${index}.budget`, finalValue);
    }
  }

  const setEndDateForRecurring = (index: number, value: number) => {
    const startDate = parseISO(getValues(`body.milestones.${index}.start`));
    if (!isNaN(startDate.valueOf())) {
      startDate.setMonth(startDate.getMonth() + value);
      setValue(`body.milestones.${index}.end`, startDate.toISOString());
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        const lastIndex = getValues('body.milestones').length - 1;

        if (name === 'body.milestones.0.start') {
          setValue(
            'body.projectDurationFrom',
            simpleDateFormatter(parseISO(getValues('body.milestones.0.start'))),
          );
        }

        if (name === `body.milestones.${lastIndex}.end`) {
          const isRecurring = getValues(`body.milestones.${lastIndex}.recurring`);
          if (isRecurring) {
            const lastStartDate = getValues(`body.milestones.${lastIndex}.start`);
            setValue(
              'body.projectDurationTo',
              getValues(`body.milestones.${lastIndex}.duration`)
                ? simpleDateFormatter(
                    addMonths(
                      parseISO(lastStartDate),
                      getValues(`body.milestones.${lastIndex}.duration`),
                    ),
                  )
                : '',
            );
          } else {
            setValue(
              'body.projectDurationTo',
              simpleDateFormatter(getValues(`body.milestones.${lastIndex}.end`)),
            );
          }
        }

        if (name.startsWith('body.milestones.') && name.endsWith('.duration')) {
          const parts = name.split('.');
          const index = +parts[2];
          const { duration } = value.body.milestones[index];
          setEndDateForRecurring(index, duration);
        }
        if (name.startsWith('body.milestones.') && name.endsWith('.recurring')) {
          const parts = name.split('.');
          const index = +parts[2];
          setValue(`body.milestones.${index}.duration`, null);
          setValue(`body.milestones.${index}.end`, '');
          if (getValues(`body.milestones.${index}.recurring`)) {
            setTimeout(() => {
              trigger(`body.milestones.${index}.duration`);
            });
          } else {
            setTimeout(() => {
              trigger(`body.milestones.${index}.end`);
            });
          }
        }
        if (name.startsWith('body.milestones.') && name.endsWith('.budget')) {
          const parts = name.split('.');
          const index = +parts[2];
          handleSliderChange(index, value);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack>
      <Box sx={{ mb: 5 }}>
        <Stack spacing={2}>
          {fields.map((item, index) => {
            const isRecurring = getValues(`body.milestones.${index}.recurring`);
            const isPrevRecurring = getValues(`body.milestones.${index - 1}.recurring`);

            return (
              <Card
                key={item.id}
                elevation={0}
                sx={{
                  position: 'relative',
                  border: 'none',
                  borderRaidus: 0.5,
                  boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
                  p: 3,
                }}
              >
                <Stack direction="row">
                  <Box pr={1.5} pt={4}>
                    <Typography variant="bodySemiBold2" color="primaryDark.500">
                      {index < 9 ? `0${index + 1}` : index + 1}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    {index !== 0 && (
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: 15,
                          top: 30,
                        }}
                        onClick={() => remove(index)}
                        disabled={index === 0}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}

                    <Stack pb={3} spacing={4}>
                      <Stack direction="row" alignItems="center">
                        <Stack
                          width="50%"
                          sx={{
                            paddingRight: 0.625,
                          }}
                        >
                          <ControlledTextInput
                            key={item.id}
                            name={`body.milestones.${index}.name`}
                            sx={{ bgcolor: 'common.white' }}
                            required
                            label="Milestone name"
                          />
                        </Stack>
                        {/* TODO: uncomment with reocurring milestones */}
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          sx={{ ml: 1.5, mt: 3 }}
                        >
                          <ControlledSwitchInput
                            key={item.id}
                            name={`body.milestones.${index}.recurring`}
                            color="secondary"
                          />
                          <Typography variant="body3" color="primaryDark.500">
                            Recurring milestone
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack sx={{ alignItems: 'start' }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="start"
                          spacing={3}
                        >
                          <Grid item xs={6}>
                            <BaseInputLabel required text="Milestone duration" />
                            <Stack
                              direction={{ md: 'column', lg: 'row' }}
                              sx={{
                                '.MuiBox-root': {
                                  flex: 1,
                                },
                              }}
                              spacing={{ md: 0, lg: 0.5 }}
                            >
                              <Stack
                                direction={{ md: 'column', lg: 'row' }}
                                sx={{
                                  flex: 1,
                                  /*  alignItems: {
                                 md: 'flex-start',
                                 lg: 'center'
                               } */
                                }}
                              >
                                <BaseInputLabel
                                  sx={{
                                    overflow: 'initial',
                                    marginRight: 0.5,
                                    mb: isRecurring ? { md: 0, lg: '27px' } : 0,
                                  }}
                                  text="From"
                                />
                                <ControlledDateInput
                                  key={item.id}
                                  defaultCalendarMonth={
                                    getValues(`body.milestones.${index - 1}.end`)
                                      ? parseISO(watch(`body.milestones.${index - 1}.end`))
                                      : null
                                  }
                                  components={{
                                    OpenPickerIcon: KeyboardArrowDownIcon,
                                  }}
                                  minDate={
                                    isPrevRecurring
                                      ? watch(`body.milestones.${index - 1}.start`)
                                        ? addMonths(
                                            parseISO(watch(`body.milestones.${index - 1}.start`)),
                                            getValues(`body.milestones.${index - 1}.duration`),
                                          )
                                        : null
                                      : watch(`body.milestones.${index - 1}.end`)
                                      ? parseISO(watch(`body.milestones.${index - 1}.end`))
                                      : null
                                  }
                                  maxDate={
                                    isRecurring
                                      ? watch(`body.milestones.${index}.end`)
                                        ? addMonths(
                                            parseISO(watch(`body.milestones.${index}.end`)),
                                            getValues(`body.milestones.${index}.duration`),
                                          )
                                        : null
                                      : watch(`body.milestones.${index}.end`)
                                      ? parseISO(watch(`body.milestones.${index}.end`))
                                      : null
                                  }
                                  name={`body.milestones.${index}.start`}
                                  sx={{
                                    flex: 1,
                                  }}
                                  inputProps={{ style: { fontSize: '0.83rem' } }}
                                  required
                                />
                              </Stack>
                              <Stack
                                direction={{ md: 'column', lg: 'row' }}
                                sx={{
                                  flex: 1,
                                }}
                              >
                                <BaseInputLabel
                                  sx={{
                                    overflow: 'initial',
                                    mx: { md: '0 !important', lg: '8px !important' },
                                    whiteSpace: 'pre',
                                    mb: isRecurring ? { md: 0, lg: '27px' } : 0,
                                  }}
                                  text={isRecurring ? 'Number\nof months' : 'To'}
                                />
                                {isRecurring ? (
                                  <ControlledSelectInput
                                    key={item.id}
                                    name={`body.milestones.${index}.duration`}
                                    required
                                    choices={mapRecurringInterval}
                                    sx={{
                                      flex: 1,
                                      marginBottom: '-14px',
                                    }}
                                  />
                                ) : (
                                  <ControlledDateInput
                                    key={item.id}
                                    components={{
                                      OpenPickerIcon: KeyboardArrowDownIcon,
                                    }}
                                    required
                                    fullWidth
                                    defaultCalendarMonth={
                                      watch(`body.milestones.${index}.start`)
                                        ? parseISO(watch(`body.milestones.${index}.start`))
                                        : null
                                    }
                                    minDate={
                                      isRecurring
                                        ? watch(`body.milestones.${index}.start`)
                                          ? addMonths(
                                              parseISO(watch(`body.milestones.${index}.start`)),
                                              watch(`body.milestones.${index}.duration`),
                                            )
                                          : null
                                        : watch(`body.milestones.${index}.start`)
                                        ? parseISO(watch(`body.milestones.${index}.start`))
                                        : null
                                    }
                                    maxDate={
                                      watch(`body.milestones.${index + 1}.start`)
                                        ? parseISO(watch(`body.milestones.${index + 1}.start`))
                                        : null
                                    }
                                    name={`body.milestones.${index}.end`}
                                    sx={{
                                      flex: 1,
                                    }}
                                    errorMessages={{
                                      minDate: 'End date should be after start date',
                                    }}
                                    inputProps={{ style: { fontSize: '0.83rem' } }}
                                  />
                                )}
                              </Stack>
                            </Stack>
                          </Grid>

                          <Grid
                            item
                            sx={{
                              paddingTop: 0,
                            }}
                            xs={6}
                          >
                            <Stack spacing={2}>
                              <Stack direction="row">
                                <BaseInputLabel required text="Budget allocation" />
                              </Stack>

                              <Stack
                                spacing={3}
                                direction="row"
                                alignItems="center"
                                sx={{
                                  mt: '8px !important',
                                }}
                              >
                                <ContractMilestoneBudgetProgress key={item.id} index={index} />
                                <ControlledContractMilestoneNumberInput
                                  key={item.id}
                                  initialValue={watch(`body.milestones.${index}.budget`)}
                                  name={`body.milestones.${index}.budget`}
                                  adornmentText={mapCurrency.get(watch(`body.scope.currency`))}
                                />
                              </Stack>
                              {!!watch(`body.milestones.${index}.duration`) && (
                                <RecurringMonthlyBudgetDisplay index={index} />
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack>
                        <ControlledTextInput
                          key={item.id}
                          label="Milestone summary"
                          name={`body.milestones.${index}.summary`}
                          multiline
                          sx={{ bgcolor: 'white' }}
                          minRows={5}
                          placeholder="Type"
                          required
                          variant="outlined"
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Box>

      <Stack alignItems="center" justifyContent="center">
        <CustomButton
          onClick={() => {
            handleDefaultSliderValue();
            append({
              name: '',
              start: '',
              end: '',
              duration: null,
              budget: handleDefaultSliderValue(),
              summary: '',
              recurring: false,
            });
          }}
          variant="primaryText"
          startIcon={
            <Stack
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: 'primaryLight.500',
                borderRadius: '2px',
              }}
            >
              <AddIcon
                sx={{
                  color: '#fff',
                }}
              />
            </Stack>
          }
          sx={{
            border: `1px solid ${secondaryBlue[100]}`,
            color: 'primaryLight.500',
            bgcolor: 'primaryDark.150',
            borderRadius: '4px',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: 600,
            padding: '10px 12px',
            boxShadow: `6px 6px 15px rgba(${primaryDark[600]}, 0.1)`,
            '&:hover': {
              bgcolor: 'primaryLight.100',
              borderColor: 'transparent',
            },
          }}
        >
          New Milestone
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default MilestonesForm;
