import { Stack, Typography } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { TermOfServiceI } from '../utils';

type CategoryTextProps = {
  onLinkClickArray?: (
    navigate: NavigateFunction,
    setValue: Dispatch<SetStateAction<number>>,
  ) => (() => void)[];
  setValue?: Dispatch<SetStateAction<number>>;
} & Pick<TermOfServiceI, 'title' | 'introduction' | 'content'>;

const TabPanelContent = ({
  title,
  introduction,
  content,
  onLinkClickArray,
  setValue,
}: CategoryTextProps) => {
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <Typography variant="headline2">{title}</Typography>

        <Typography variant="publicBody5Regular" color="common.black">
          {introduction}
        </Typography>
      </Stack>

      {content?.map(({ title, subtitle, paragraphs }, index) => (
        <Stack spacing={2}>
          <Typography variant="headline2">{title}</Typography>

          <Typography variant="headline4" color="common.black" fontWeight="bold">
            {subtitle}
          </Typography>

          <Stack spacing={3}>
            {paragraphs?.map((paragraph) => (
              <Typography variant="publicBody5Regular" color="common.black">
                {paragraph.text(
                  onLinkClickArray?.(navigate, setValue as Dispatch<SetStateAction<number>>),
                )}
              </Typography>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default TabPanelContent;
