/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const joyrideConfig: Partial<Step> = {
  disableBeacon: true,
  hideCloseButton: false,
  hideFooter: false,
  placement: 'auto',
  styles: {
    options: {
      zIndex: 10,
    },
  },
};

const MilestoneDetailScreenJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    content: (
      <Typography>
        By adding a new milestone, you will automatically be creating a contract annex.
        Contract annexes need to be approved by
        the other party and signed before the changes are fully applied.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-1-milestone',
    title: 'Contract annex',
  },
  {
    content: (
      <Typography>
        Use the milestone carousel to scroll through and enter
        any of your previous, current or upcoming milestones.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-2-milestone',
    title: 'Milestone carousel',
  },
  {
    content: (
      <Typography>
        Click the action button to edit and flag your milestone.
        Editing your milestone will automatically create a contract annex,
        while flagging your milestone indicates an issue
        that needs to be resolved with the other party.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-3-milestone',
    title: 'Milestone actions',
  },
  {
    content: (
      <Typography>
        Milestone status will be updated automatically as your seller completes tasks.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-4-milestone',
    title: 'Milestone status',
  },
  {
    content: (
      <Typography>
        Once the seller finishes all tasks within the milestone and
        sends it for review, the Approve button will be enabled.
        By approving the milestone, you are proceeding with the payment process.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-5-milestone',
    title: 'Approve milestone',
  },
  {
    content: (
      <Typography>
        Enter your task name and click the Add task button to create a task.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-6-milestone',
    title: 'Add tasks',
  },
];

export const TasksJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    content: userBuyer ? (
      <Typography>
        The task status can be changed by clicking the status button.
        View the statuses to get a quick overview of task progress.
      </Typography>
    ) : (
      <Typography>
        You can change task status by clicking the status button.
        For example, if you've started working on a task, change the status to
        In progress to update the buyer and update your overall milestone progress.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-1-task',
    title: 'Task status',
  },
  {
    content: (
      <Typography>
        Click the action button to flag or delete the task.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-2-task',
    title: 'Task actions',
  },
  {
    content: (
      <Typography>
        To add subtasks, press the Add subtask button.
        Enter your subtask name and deadline and click Save when you're ready.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-4-task',
    title: 'Add subtask',
  },
  {
    content: (
      <Typography>
        Press the pen icon to edit the task.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-3-task',
    title: 'Edit task',
  },
];

export const SubTasksJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    content: (
      <Typography>
        The subtask action button lets you edit, flag or delete the subtask.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-1-subtask',
    title: 'Subtask actions',
  },
];

export default MilestoneDetailScreenJoyrideSteps;
