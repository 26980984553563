import {
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Typography, SelectChangeEvent,
} from '@mui/material';
import { EnumProjectTaskStatus } from 'tdc-web-backend/enums/enums';
import { TaskDto, UpdateTaskDto } from 'tdc-web-backend/tasks/schemas';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';

export const mapTempTaskStatus = new Map([
  [EnumProjectTaskStatus.Completed, 'Completed'],
  [EnumProjectTaskStatus.InProgress, 'In Progress'],
  [EnumProjectTaskStatus.Pending, 'Pending'],
  [EnumProjectTaskStatus.Canceled, 'Canceled'],
]);

export interface TaskDropdownChipProps {
  task: TaskDto;
  fontSize?: string | number;
  className?: string;
}

const TaskDropdownChip = ({
  task, fontSize = '10px !important', className,
}: TaskDropdownChipProps) => {
  const { mutate: update } = useUpdate<TaskDto, UpdateTaskDto>({ resource: '/tasks' });
  const refresh = useRefresh();

  const status = task?.status;

  const handleChange = (event: SelectChangeEvent<EnumProjectTaskStatus>) => {
    task.status = event.target.value as EnumProjectTaskStatus;
    update({
      // data: task,
      // id: task.id,
      data: task as Partial<UpdateTaskDto>,
      id: task.id as string,
    }, {
      onSuccess: (data) => {
        refresh();
      },
    });
  };

  const getBackgroundColor = (status: EnumProjectTaskStatus) => {
    switch (status) {
      case EnumProjectTaskStatus.Completed:
        return '#D1F6D0';
      case EnumProjectTaskStatus.InProgress:
        return '#DFE0FF';
      case EnumProjectTaskStatus.Canceled:
        return '#FFB4A9';
      case EnumProjectTaskStatus.Pending:
        return '#F8EBCB';
      default:
        return '#C5C7C3';
    }
  };

  return (
    <>
      <FormControl sx={{
        m: 1,
        width: 'fit-content',
        my: 'auto',
      }}
      >
        <Select
          className={className || undefined}
          sx={{
            fontSize,
            '&.MuiInputBase-root': {
              borderRadius: 6,
              background: getBackgroundColor(status as EnumProjectTaskStatus),
            },
            '& .MuiSelect-select': {
              padding: '3.5px 10px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          displayEmpty
          value={status}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected) {
              return <em>{mapTempTaskStatus.get(status as EnumProjectTaskStatus)}</em>;
            }
            return mapTempTaskStatus.get(selected);
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {(Object.values(EnumProjectTaskStatus)).map((key) => (
            <MenuItem
              key={key}
              value={key}
            >
              <Typography sx={{ fontSize }}>
                {mapTempTaskStatus.get(key)}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TaskDropdownChip;
