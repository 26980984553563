import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import Button from '../../../../components/button/Button';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';

export interface TaskModalProps {
  task: TaskDto;
}

const TaskDeleteModal = ({ task }: TaskModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { mutate: deleteOne } = useDeleteOne<TaskDto>({ resource: '/tasks' });

  const handleSubmitDelete = () => {
    deleteOne(
      { id: taskId as string },
      {
        onSuccess: (data) => {
          setIsOpen(false);
          navigate(`../milestone/${task.milestone}`);
        },
      },
    );
  };

  return (
    <>
      <Button
        onButtonClick={() => setIsOpen(true)}
        startIcon={<DeleteIcon sx={{ fontSize: '1.45rem !important' }} />}
        fontWeight="500"
        setBorderRadius
        size="large"
        variant="outlined"
        className="react-joyride-step-3-task"
        color="secondary"
      >
        Delete
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Delete task
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Are you sure you want to delete the task?
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    setBorderRadius
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    setBorderRadius
                    color="secondary"
                    variant="contained"
                    onButtonClick={() => handleSubmitDelete()}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};
export default TaskDeleteModal;
