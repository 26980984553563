/*
  TODO: This is just a temp placeholder, refactor this
  afterwards!
*/
enum TempStatusEnum {
  Draft = 'Draft',
  Active = 'Active',
  Paid = 'Paid',
  Finished = 'Finished',
  In_progress = 'In progress',
  InProgress = 'In_progress',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Due = 'Due',
}

export default TempStatusEnum;
