/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const joyrideConfig : Partial<Step> = {
  disableBeacon: true,
  hideCloseButton: false,
  hideFooter: false,
  placement: 'auto',
  styles: {
    options: {
      zIndex: 10,
    },
  },
};

const ProjectDetailScreenJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    title: 'Navigation',
    content: (
      <Typography>
        Click Projects to return to the previous screen. Whenever you enter a new screen,
        you can use this navigation bar to take a step back at any time you want.
      </Typography>
    ),
    target: '.react-joyride-step-1-project',
    ...joyrideConfig,
  },
  {
    title: 'Project screen',
    disableScrolling: true,
    content: (
      <Typography>
        This is your project screen. Here you can perform all activities
        connected to the project and navigate to other essential screens.

        First, let's start with your contract.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-2-project',
  },
  {
    title: 'New contract',
    disableScrolling: true,
    content: (
      <Typography>
        The first step is to create your digital contract.
        Either parties can start this process,
        but both need to edit the contract and agree to the terms to proceed.
        Once the contract is signed and activated, you can start working on the project.

        For now, let's take a closer look at the rest of your dashboard.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-3-project',
  },
  {
    title: 'Contracts screen',
    disableScrolling: true,
    content: (
      <Typography>
        Once you've created your contract, you can get a full overview of you
        contracts and all changes by clicking view all and going to your Contracts screen.
        There you can also download your signed contract and any annexes in pdf format.
      </Typography>
    ),
    target: '.react-joyride-step-4-project',
    ...joyrideConfig,
  },
  {
    title: 'Milestones',
    content: (
      <Typography>
        This is your milestone overview. Once you've signed your contract,
        you will be able to track all your milestones with their associated tasks on this screen.
      </Typography>
    ),
    ...joyrideConfig,
    floaterProps: {
      disableFlip: true,
    },
    target: '.react-joyride-step-5-project',
  },
  {
    title: 'Milestone screen',
    content: (
      <Typography>
        To view your current ""in progress"" milestone, click the explore button.
        It will take you to your milestone screen, where you can
        review tasks and subtasks and manage all aspects of your milestone.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-6-project',
  },
  {
    title: 'Milestone status',
    content: (
      <Typography>
        Your milestone status and completion percentage will
        update automatically as work on project milestones is completed.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-7-project',
  },
  {
    title: 'Project health',
    content: (
      <Typography>
        Track how your project is doing by checking your project health frequently.
        All major updates will be reflected in one of the four main metrics.

        Let's go over them one by one.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-8-project',
  },
  {
    title: 'Schedule',
    content: (
      <Typography>
        Check how your milestones are progressing - whether you're on, behind or ahead schedule.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-9-project',
  },
  {
    title: 'Cost',
    content: (
      <Typography>
        The cost metric lets you know how your payments are progressing,
        whether you're over or under budget.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-10-project',
  },
  {
    title: 'Scope',
    content: (
      <Typography>

        Your project scope shows all modifications to your originally signed contract.

      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-11-project',
  },
  {
    title: 'Quality',
    content: (
      <Typography>
        The number of unresolved flagged issues will affect the quality of your project.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-12-project',
  },
  {
    title: 'Payment overview',
    content: userBuyer ? (
      <Typography>
        Start working on your project to check your upcoming and
        completed payments across milestones and download your invoices.
      </Typography>
    ) : (
      <Typography>
        Start working on your project to check your upcoming and
        received payments across milestones and download your invoices.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-13-project',
  },
  {
    title: 'Request payment',
    content: (
      <Typography>
        After a milestone has been completed and approved, you will be
        able to upload an invoice for the buyer by clicking on request payment.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-14-project',
  },
];

export const ActiveContractScreenJoyrideSteps = (): Step[] => [
  {
    title: 'Milestone status',
    content: (
      <Typography>
        Milestone status denotes the progress made on a particular milestone.
        Paid milestones have been fully completed and paid for,
        In progress milestones are currently being worked on,
        Pending milestones are yet to be started and Late milestones are overdue for payment.
      </Typography>
    ),
    target: '.react-joyride-step-1-active-project',
    ...joyrideConfig,
  },
  {
    title: 'Upload invoice',
    content: (
      <Typography>
        Once a completed milestone has been approved by the buyer,
        you will need to upload it here to continue the payment process.
      </Typography>
    ),
    target: '.react-joyride-step-2-active-project',
    ...joyrideConfig,
  },
  {
    title: 'Download invoice',
    content: (
      <Typography>
        You can download invoices for approved milestones here.
        To complete the payment process, simply download your invoice and pay it off-platform.
        TDA will forward the full amount to your seller as soon as the payment has been processed.
      </Typography>
    ),
    target: '.react-joyride-step-3-active-project',
    ...joyrideConfig,
  },

];

export default ProjectDetailScreenJoyrideSteps;
