import { FormControlLabel, Icon, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { primaryDark, primaryLight } from '../../color';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio.svg';
import { ReactComponent as RadioCheckIcon } from '../../assets/icons/radio-check.svg';
import StatusChipField from '../StatusChipField/StatusChipField';

interface IActionPanelLineProps {
  icon: ReactNode;
  label: string;
  value: ReactNode;
  type?: 'string' | 'radio' | 'chip';
  onChange?: (e: ReactNode) => void;
}

const ActionPanelLine = ({
  icon,
  label,
  value,
  type = 'string',
  onChange,
}: IActionPanelLineProps) => {
  let val = (
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      <>{value}</>
    </Typography>
  );
  switch (type) {
    case 'chip':
      val = value ? <StatusChipField sx={{ mt: '0.0.125rem' }} text={value as string} /> : <></>;
      break;
    case 'radio':
      val = (
        <RadioGroup
          aria-labelledby="visibility-options"
          name="visibility"
          value={value}
          onChange={(_, newVisibility) => {
            if (newVisibility !== null) {
              onChange?.(newVisibility);
            }
          }}
          sx={{
            flexDirection: 'column',
          }}
        >
          <FormControlLabel
            value="true"
            control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckIcon />} />}
            label="Public"
            sx={{
              '& .Mui-checked': {
                color: primaryLight[500],
              },
            }}
            componentsProps={{ typography: { variant: 'body3' } }}
          />
          <FormControlLabel
            value="false"
            control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckIcon />} />}
            label="Private"
            sx={{
              '& .Mui-checked': {
                color: primaryLight[500],
              },
            }}
            componentsProps={{ typography: { variant: 'body3' } }}
          />
        </RadioGroup>
      );
      break;

    default:
      val = (
        <Typography variant="body3" sx={{ fontWeight: 'bold', mt: '0.375rem' }}>
          {value}
        </Typography>
      );
      break;
  }

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ py: 2 }}>
      <Icon sx={{ color: primaryDark[500] }}>{icon}</Icon>
      <Typography
        variant="heading5"
        color={primaryDark[500]}
        sx={{ width: '7rem', ml: '0.25rem', mr: '0.5rem', mt: '0.375rem', fontSize: '0.75rem' }}
      >
        {label}
      </Typography>
      {val}
    </Stack>
  );
};

export default ActionPanelLine;
