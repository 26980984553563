import Typography from '@mui/material/Typography';
import { Divider, Stack, useMediaQuery, Theme } from '@mui/material';
import { primaryDark, primaryLight, secondaryGray } from '../../../../color';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import { Box } from '@mui/system';
import CustomButton from '../../../../components/button/CustomButton';

interface CallToActionProps {
  firstTitle?: string;
  secondTitle?: string;
  firstSubtitle?: string;
  secondSubtitle?: string;
  bgcolor?: string;
  color?: string;
  dividerColor?: string;
  borderColor?: string;
  boxShadow?: boolean;
  buttonBgColor?: string;
  onButtonClick?: () => void;
}

const CallToAction = ({
  firstTitle,
  firstSubtitle,
  secondTitle,
  secondSubtitle,
  bgcolor,
  color = secondaryGray[900],
  dividerColor = primaryDark[400],
  borderColor,
  boxShadow = false,
}: CallToActionProps) => {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <Stack alignItems="center" sx={{ ...LAYOUT_DEFAULTS, pb: '12px' }}>
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        spacing={5}
        sx={{
          bgcolor,
          width: '100%',
          borderRadius: '20px',
          border: borderColor ? `1px solid ${borderColor}` : 'none',
          boxShadow: boxShadow ? '6px 6px 15px 0px rgba(56, 69, 114, 0.10)' : 'none',
          p: {
            xs: 3,
            md: 6,
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
          spacing={2}
        >
          <Typography
            sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}
            color={color}
            width={{ xs: '80%', sm: '60%', md: '100%' }}
            textAlign={'center'}
          >
            {firstTitle}
          </Typography>

          <Typography
            sx={{
              typography: { xs: 'publicBodyRegular', lg: 'publicBody3Regular' },
              fontSize: '16px',
            }}
            color={color}
            width={{ xs: '70%', sm: '45%', md: '70%' }}
            textAlign={'center'}
          >
            {firstSubtitle}
          </Typography>
          <CustomButton href={process.env.REACT_APP_CALENDLY_URL as string}>
            Book a demo
          </CustomButton>
        </Stack>

        <Box>
          {isLargeScreen ? (
            <Divider
              orientation="vertical"
              sx={{
                borderColor: '#9EA8C2',
                height: '100%',
                '&::before, &::after': {
                  borderTop: `thin solid ${dividerColor}`,
                },
              }}
            >
              Or
            </Divider>
          ) : (
            <Divider
              orientation="horizontal"
              sx={{
                borderColor: '#9EA8C2',
                height: '100%',
                '&::before, &::after': {
                  borderTop: `thin solid ${dividerColor}`,
                },
              }}
            >
              Or
            </Divider>
          )}
        </Box>

        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
          spacing={2}
        >
          <Typography
            sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}
            color={color}
            width={{ xs: '60%', sm: '60%', md: '100%' }}
            textAlign={'center'}
          >
            {secondTitle}
          </Typography>

          <Typography
            sx={{
              typography: { xs: 'publicBodyRegular', lg: 'publicBody3Regular' },
              fontSize: '16px',
            }}
            color={color}
            width={{ xs: '70%', sm: '40%', md: '70%' }}
            textAlign={'center'}
          >
            {secondSubtitle}
          </Typography>
          <CustomButton variant="secondary" href="/register">
            Sign up
          </CustomButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CallToAction;
