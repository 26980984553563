/* eslint-disable no-unused-expressions */
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import CustomButton from '../../../components/button/CustomButton';

const ActivateAccountSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');

  const { mutate, isLoading } = useCreate<Record<string, unknown>, string>({
    resource: `/auth/activate?token=${token}`,
  });

  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      mutate(token, {
        onSuccess: () => {
          setValidated(true);
        },
        onError: () => {
          setValidated(false);
        },
      });
    }
  }, [token]);

  return (
    <Stack width="100%" spacing={3}>
      {validated ? (
        <>
          <Typography
            variant="headline1"
            sx={{
              color: 'primaryDark.600',
            }}
          >
            Verification successful!
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: 'primaryDark.600',
            }}
          >
            Log in to complete your account creation.
          </Typography>

          <CustomButton
            onClick={() => navigate('/login')}
            sx={{
              maxWidth: 'fit-content',
              mt: 2,
              alignSelf: { xs: 'start', md: 'end' },
            }}
          >
            Continue
          </CustomButton>
        </>
      ) : (
        <>
          <Typography
            variant="headline1"
            sx={{
              color: 'primaryDark.600',
            }}
          >
            Sorry, verification failed.
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: 'primaryDark.600',
            }}
          >
            Email verification has failed, please request a new verification link to complete your
            sign up to Spona.
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default ActivateAccountSection;
