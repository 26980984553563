import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useState } from 'react';
import { PasswordResetDto } from 'tdc-web-backend/auth/schemas';
import CustomButton from '../../../components/button/CustomButton';
import ControlledPasswordInput from '../../../components/custom-inputs/ControlledPasswordInput';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';

const NewPasswordSection = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const formMethods = useForm();

  const [showPass, setShowPass] = useState<Record<string, boolean>>({
    password: false,
    repeatPassword: false,
  });

  const { getValues, control } = formMethods;

  const { mutate } = useCreate<Record<string, unknown>, PasswordResetDto>({
    resource: '/auth/edit-password',
  });

  return (
    <Stack width="100%" spacing={4}>
      <Typography
        variant="headline1"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        Enter Password
      </Typography>

      <Stack spacing={6}>
        <FormProvider {...formMethods}>
          {/* TODO: replace with new CustomTextInput */}
          <ControlledPasswordInput
            value={useWatch({ control, name: 'password' }) ?? ''}
            variant="outlined"
            name="password"
            placeholder="•••••••"
            outerLabel="NEW PASSWORD"
            type={showPass.password ? 'text' : 'password'}
            required
            InputLabelProps={{
              shrink: useWatch({ control, name: 'headquartersLocation' }) && true,
            }}
            endAdornmentOnClick={() =>
              setShowPass({
                ...showPass,
                password: !showPass.password,
              })
            }
          />

          {/* TODO: replace with new CustomTextInput with type=password */}
          <ControlledPasswordInput
            value={useWatch({ control, name: 'repeatPassword' }) ?? ''}
            variant="outlined"
            name="repeatPassword"
            placeholder="•••••••"
            outerLabel="REPEAT PASSWORD"
            type={showPass.repeatPassword ? 'text' : 'password'}
            required
            InputLabelProps={{
              shrink: useWatch({ control, name: 'headquartersLocation' }) && true,
            }}
            endAdornmentOnClick={() =>
              setShowPass({
                ...showPass,
                repeatPassword: !showPass.repeatPassword,
              })
            }
          />
        </FormProvider>
      </Stack>

      <CustomButton
        sx={{ width: { xs: '100%', lg: '50%' }, alignSelf: 'flex-end', mt: 2 }}
        onClick={() => {
          mutate(
            { token, password: getValues('password') },
            {
              onSuccess: () => {
                navigate('/login');
              },
            },
          );
        }}
        disabled={
          getValues('password') !== getValues('repeatPassword') ||
          getValues('password')?.length < 8 ||
          getValues('repeatPassword')?.length < 8
        }
      >
        Reset Password
      </CustomButton>
    </Stack>
  );
};

export default NewPasswordSection;
