import { Stack, useMediaQuery } from '@mui/material';
import { BlogContentSchema, BlogDto } from 'tdc-web-backend/blogs/schemas';
import { useState } from 'react';
import { Box } from '@mui/system';
import Sections from './components/Sections';
import TableOfContents from './components/TableOfContents';
import theme from '../../../../../theme';
import useIntersectionObserver from '../../../../../utils/hooks/useIntersectionObserver';

type ContentProps = {
  blog: BlogDto;
};

type BlogSectionItem = BlogContentSchema & {
  id: string;
};

const Content = ({ blog }: ContentProps) => {
  const [activeId, setActiveId] = useState('');
  const sections: BlogSectionItem[] = blog?.sections.map(
    (section: BlogContentSchema, index: string) => ({
      subtitle: section.subtitle,
      body: section.body,
      id: `section-${index}`,
    }),
  );
  const sectionIds = sections.map((section: BlogSectionItem) => `#${section.id}`);
  useIntersectionObserver(setActiveId, sectionIds);
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('bg'));

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box sx={{ width: { xs: '720px' } }}>
        <Sections sections={sections} />
      </Box>
      {!isLessThanLarge && (
        <Box
          sx={{
            width: '340px',
            minWidth: '340px',
            marginLeft: { xs: 'auto', bg: '40px' },
          }}
        >
          <TableOfContents sections={sections} activeId={activeId} />
        </Box>
      )}
    </Stack>
  );
};

export default Content;
