import { Stack, Typography, Box, useMediaQuery } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import theme from '../../../../theme';
import { companyLogosGreaterThanGreat, companyLogosLessThanGreat } from '../utils';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums/public-page-images';

const AboutUsHero = () => {
  const isLessThanGreat = useMediaQuery(theme.breakpoints.down('gr'));

  return (
    // main row
    <Stack
      spacing={{ md: 8 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        ...LAYOUT_DEFAULTS,
        height: `calc(100vh + ${PUBLIC_APPBAR_HEIGHT}px)`,
      }}
    >
      {/* about us text & company icons */}
      <Stack spacing={3} maxWidth={{ xs: '100%', xl: '45%' }}>
        <Stack spacing={2}>
          <Typography sx={{ typography: { xs: 'publicHeading3', md: 'publicHeading2' } }}>
            About us
          </Typography>

          <Typography sx={{ typography: { xs: 'publicBody4Regular', md: 'publicBody3Regular' } }}>
            Spona is a global B2B platform that helps businesses streamline the process of hiring
            and managing digital service sellers. Our network of vetted sellers makes hiring the
            perfect project partner a painless process. The next step is supporting every step of
            the managing process, from planning, execution, project insights to successfully
            financing all of the above.
            <br />
            <br />
            Join our community and find out how simple and risk-free starting a project can be.
          </Typography>
        </Stack>

        {isLessThanGreat ? (
          <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {companyLogosLessThanGreat.map((logo) => (
                <Box
                  key={logo.src}
                  component="img"
                  src={logo.src}
                  alt={logo.alt}
                  width={logo.width}
                />
              ))}
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {companyLogosGreaterThanGreat.map((logo) => (
              <Box
                key={logo.src}
                component="img"
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
              />
            ))}
          </Stack>
        )}
      </Stack>

      {/* image */}
      <Stack
        display={{ xs: 'none', md: 'flex' }}
        sx={{ width: { xs: '100%', xl: '50%' } }}
        alignItems="center"
      >
        <Box
          component="img"
          src={getPublicImageUrl(EnumPublicPagesImage.AbaoutUsHero)}
          alt="Female posing with the Spona tote bag. Close up image of the Spona pen and notebook on a laptop."
          sx={{ width: { md: '40vw', xl: '90%' } }}
        />
      </Stack>
    </Stack>
  );
};

export default AboutUsHero;
