/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { EnumCaseStudyStatus, mapService } from 'tdc-web-backend/enums/enums';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FeaturedCaseStudiesDto } from 'tdc-web-backend/featured-case-studies/schemas';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import CustomButton from '../../../components/button/CustomButton';
import CustomInput from '../../../components/custom-input/CustomInput';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';

const CaseStudyArchiveEdit = () => {
  const { archiveId } = useParams();
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: {
      linkedServices: [],
      caseStudies: [],
      description: '',
      title: '',
    },
  });

  const { data: caseStudyResponse } = useGetMany<BaseCaseStudy>({
    resource: `case-studies?status=${EnumCaseStudyStatus.Verified}`,
  });

  const { data: featuredResponse } = useGetOne<FeaturedCaseStudiesDto>({
    resource: `featured-case-studies/${archiveId}`,
    onSuccess({ data }) {
      const { id, main, caseStudies, linkedServices, description, title } = data;
      const caseStudiesIds = caseStudies?.map((caseStudy: Partial<BaseCaseStudy>) => caseStudy.id);
      formMethods.reset({
        title,
        description,
        caseStudies: caseStudiesIds,
        linkedServices: linkedServices as any,
      });
    },
  });

  const caseStudyArray = caseStudyResponse?.data.results;

  const caseStudyChoices = new Map(
    caseStudyArray?.map((caseStudy) => [caseStudy.id, caseStudy.title]),
  );

  const { mutate: updateFeatured } = useUpdate({
    resource: '/featured-case-studies',
  });

  const onSubmit = (data: any) => {
    updateFeatured(
      {
        id: archiveId as string,
        data: {
          ...data,
        },
      },
      {
        onSuccess: () => {
          navigate('/admin/case-study-archive');
        },
      },
    );
  };
  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        sx={{
          width: '100%',
          height: '100%',
        }}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Stack direction="column" p={2} width="60%">
          <Stack gap={3}>
            <Stack width="100%" mb={2}>
              <Typography variant="publicHeading4" color="primaryDark.900">
                Edit featured caseStudies
              </Typography>
            </Stack>
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.Text />}
              name="title"
              placeholder="Title"
              label="Title"
            />
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.Text />}
              name="description"
              placeholder="Description"
              label="Description"
            />
            {caseStudyResponse && (
              <CustomInput
                sx={{
                  minHeight: 10,
                  minWidth: 'fit-content',
                }}
                input={<CustomInput.SearchSelect />}
                withChips
                name="caseStudies"
                placeholder="Case Studies"
                choices={caseStudyChoices || []}
                multiple
                label="Case Studies"
              />
            )}
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.SearchSelect />}
              name="linkedServices"
              placeholder="Services"
              label="Services"
              multiple
              choices={mapService}
              withChips
            />
            <Stack direction="row" gap={2} mt={2}>
              <CustomButton type="submit">Sumbit</CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default CaseStudyArchiveEdit;
