import { TextFieldProps, TextField, Typography } from '@mui/material';

const textFieldStyles = {
  '&.MuiFormControl-root': {
    minWidth: '108px',
    bgcolor: '#F6F7FF',
    border: '1px solid #BEC6FF',
    cursor: 'pointer',
  },
  // remove button icon
  '.MuiButtonBase-root': {
    display: 'none',
  },
  '.MuiOutlinedInput-root .MuiInputAdornment-root': {
    display: 'none',
  },
  input: {
    fontSize: '1rem',
    cursor: 'pointer',
    fontWeight: 500,
    color: '#A5AAB9',
    padding: '10px 7px',
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0,
    boxShadow: 'none !important',
    color: 'secondaryPink.500',
    marginTop: '0 !important',
    lineHeight: 1,
  },
  fieldset: {
    border: '0 !important',
  },
};

const CustomTextField = ({ textFieldProps, ...props }: { textFieldProps?: TextFieldProps }) => (
  <TextField
    {...textFieldProps}
    {...props}
    focused={false}
    label={(() => {
      const propsObj = { ...props } as any;

      if (propsObj.ownerState.value === null) {
        return (
          <Typography
            fontWeight={500}
            color="primaryDark.400"
            sx={{ mt: '-6px', height: '100%', alignItems: 'center', pl: '-13px', width: '100%' }}
          >
            End Date
          </Typography>
        );
      }

      return '';
    })()}
    InputLabelProps={{ shrink: false }}
    variant="outlined"
    sx={(() => {
      const propsObj = { ...props } as any;

      return {
        ...textFieldStyles,
        '& .MuiInputLabel-root': {
          display: propsObj.ownerState.value === null ? 'flex' : 'none',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          ml: '-6px',
          mt: '-2px',
        },
        caretColor: 'transparent',
        '&.MuiFormControl-root': {
          ...textFieldStyles['&.MuiFormControl-root'],
          width: '102px',
        },
        input: {
          ...textFieldStyles.input,
        },
      };
    })()}
  />
);

export default CustomTextField;
