import { Stack } from '@mui/system';
import { EnumCountry, mapCountry } from 'tdc-web-backend/enums/enums';
import { Dispatch, SetStateAction } from 'react';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import CompanyOfficeLocationsEditModal from '../edit/CompanyOfficeLocationsEditModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyOfficeLocationsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyOfficeLocations = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyOfficeLocationsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.officeLocations?.map((location, index: number) => (
        <CustomChip
          tooltipTitle={
            location.state
              ? `${location.city}, ${location.state}, ${mapCountry.get(
                  location.country as EnumCountry,
                )}`
              : `${location.city}, ${mapCountry.get(location.country as EnumCountry)}`
          }
          key={index}
          label={
            location.state
              ? truncate(
                  `${location.city}, ${location.state}, ${mapCountry.get(
                    location.country as EnumCountry,
                  )}`,
                  chipLabelTruncateValue as number,
                )
              : truncate(
                  `${location.city}, ${mapCountry.get(location.country as EnumCountry)}`,
                  chipLabelTruncateValue as number,
                )
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}

      {companyData && isEditModalOpen && (
        <CompanyOfficeLocationsEditModal
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          showCancelButton
        />
      )}
    </Stack>
  );
};

export default CompanyOfficeLocations;
