import { useState, useEffect } from 'react';
import {
  ACTIONS, CallBackProps, EVENTS, STATUS, Step,
} from 'react-joyride';
import { useLocation, useParams } from 'react-router-dom';
import { CompanyRole, EnumProjectStatus, EnumTutorialStatus } from 'tdc-web-backend/enums/enums';
import { UpdateUserDto, BaseUserDto } from 'tdc-web-backend/users/shemas';
import useUpdate from '../crud-hooks/useUpdate';
import useDetermineCompanyRoles from '../useDetermineCompanyRoles';
import useGetLocalStorageUserData from '../useGetLocalStorageUserData';
import ContractsDetailScreenJoyride from './ContractsDetailScreenJoyride';
import EndToEndJoyrideSteps from './EndToEndScreenJoyride';
import IndividualContractDetailsJoyride from './IndividualContractDetailsJoyride';
import IndividualPaymentScreenJoyride from './IndividualPaymentJoyride';
import MilestoneDetailScreenJoyrideSteps from './MilestoneDetailScreenJoyride';
import PaymentsDetailScreenJoyrideSteps from './PaymentsDetailScreenJoyride';
import ProjectDetailScreenJoyrideSteps from './ProjectDetailScreenJoyride';
import TaskDetailScreenJoyrideSteps from './TaskDetailScreenJoyride';

interface JoyrideState {
  run: boolean,
  stepIndex: number,
  steps: Step[],
  disableScrolling?: boolean,
}

const defaultState: JoyrideState = {
  run: false,
  stepIndex: 0,
  steps: [],
  disableScrolling: false,
};

const useJoyride = () => {
  const location = useLocation();
  const params = useParams();
  const [joyride, setJoyride] = useState<JoyrideState>({
    ...defaultState,
  });

  const [user, setUser] = useState<BaseUserDto | null>(useGetLocalStorageUserData());

  const { mutate: updateUser } = useUpdate<BaseUserDto, UpdateUserDto>({ resource: '/users' });

  const update = (status: EnumTutorialStatus) => {
    updateUser({
      id: user?.id as string,
      data: {
        tutorialStatus: [...user?.tutorialStatus || [], status],
      },
    },
    {
      onSuccess: (data) => {
        if (!data || !data.data) return;
        localStorage.setItem('userData', JSON.stringify(data.data));
        setUser(data.data);
      },
    });
  };

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer) || false;

  const includes = (path: string) => location.pathname.includes(path);
  const body = document.querySelector('html');

  // Prevent manual scrolling
  const runJoyride = () => {
    if (!body) return;
    window.scrollTo(0, 0);
    body.style.overflow = 'hidden';
    setJoyride({
      ...joyride,
      run: true,
    });
    window.scrollTo(0, 0);
  };

  // Update joyride state without having to copy joyride state every time
  const updateJoyrideState = (value: Partial<JoyrideState>) => {
    setJoyride({
      ...joyride,
      ...value,
    });
  };

  const tutorials = user?.tutorialStatus;

  // Manual handler for steps
  const updateJoyride = (e: CallBackProps) => {
    const {
      action, index, status, type,
    } = e;
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      updateJoyrideState({ run: false, stepIndex: 0 });
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index !== joyride.steps.length - 1) {
        updateJoyrideState({ stepIndex: nextStepIndex });
      } else if (index === joyride.steps.length - 1) {
        if (nextStepIndex === 1) {
          updateJoyrideState({ run: false });
        } else {
          updateJoyrideState({ stepIndex: nextStepIndex });
        }
      }
    }
  };

  // Enable scrolling when done
  useEffect(() => {
    if (body) {
      if (!joyride.run) {
        body.style.overflow = 'auto';
      }
    }
  }, [joyride.run]);

  // Selector for which joyride should be used.
  useEffect(() => {
    switch (true) {
      case includes('/dashboard/end-to-end/project'):
        updateJoyrideState({ steps: ProjectDetailScreenJoyrideSteps(isUserBuyer) });
        break;
      case includes('/dashboard/end-to-end/payments'):
        if (params.paymentId) {
          updateJoyrideState({ steps: IndividualPaymentScreenJoyride(isUserBuyer) });
        } else {
          updateJoyrideState({ steps: PaymentsDetailScreenJoyrideSteps(isUserBuyer) });
        }
        break;
      case includes('/dashboard/end-to-end/contracts'):
        if (params.projectId) {
          updateJoyrideState({ steps: IndividualContractDetailsJoyride(isUserBuyer) });
        } else {
          updateJoyrideState({ steps: ContractsDetailScreenJoyride(isUserBuyer) });
        }
        break;
      case includes('/dashboard/end-to-end/task'):
        updateJoyrideState({ steps: TaskDetailScreenJoyrideSteps(isUserBuyer) });
        break;
      case includes('/dashboard/end-to-end/milestone'):
        updateJoyrideState({ steps: MilestoneDetailScreenJoyrideSteps(isUserBuyer) });
        break;
      case includes('/dashboard/end-to-end'):
        updateJoyrideState({ steps: EndToEndJoyrideSteps(isUserBuyer) });
        break;
      default:
        break;
    }
  }, [location]);

  // Trigger for 1st time run of the tutorials.
  useEffect(() => {
    if (!(joyride.steps.length > 0)) return;
    setTimeout(() => {
      switch (true) {
        case includes('/dashboard/end-to-end/project'):
          if (!tutorials?.includes(EnumTutorialStatus.ProjectDetail)
            && joyride.steps.length > 0) {
            update(EnumTutorialStatus.ProjectDetail);
            runJoyride();
          }
          break;
        case includes('/dashboard/end-to-end/payments'):
          if (params.paymentId) {
            if (!tutorials?.includes(EnumTutorialStatus.IndividualPayment)
              && joyride.steps.length > 0) {
              update(EnumTutorialStatus.IndividualPayment);
              runJoyride();
            }
          } else if (!tutorials?.includes(
            EnumTutorialStatus.PaymentDetail,
          ) && joyride.steps.length > 0) {
            update(EnumTutorialStatus.PaymentDetail);
            runJoyride();
          }
          break;
        case includes('/dashboard/end-to-end/contracts'):
          if (!tutorials?.includes(
            EnumTutorialStatus.ContractDetail,
          ) && joyride.steps.length > 0) {
            update(EnumTutorialStatus.ContractDetail);
            runJoyride();
          }
          break;
        case includes('/dashboard/end-to-end/task'):
          if (!tutorials?.includes(EnumTutorialStatus.TaskDetail) && joyride.steps.length > 0) {
            update(EnumTutorialStatus.TaskDetail);
            runJoyride();
          }
          break;
        case includes('/dashboard/end-to-end/milestone'):
          if (!tutorials?.includes(
            EnumTutorialStatus.MilestoneDetail,
          ) && joyride.steps.length > 0) {
            update(EnumTutorialStatus.MilestoneDetail);
            runJoyride();
          }
          break;
        case includes('/dashboard/end-to-end'):
          if (!tutorials?.includes(EnumTutorialStatus.EndToEnd) && joyride.steps.length > 0) {
            update(EnumTutorialStatus.EndToEnd);
            runJoyride();
          }
          break;
        default:
          break;
      }
    }, 200);
  }, [joyride.steps]);

  return {
    joyride,
    setJoyride,
    runJoyride,
    updateJoyride,
    updateJoyrideState,
    tutorials,
    update,
  };
};

export default useJoyride;
