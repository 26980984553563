import React, { useState } from 'react';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import { ChannelDto } from 'tdc-web-backend/channels/schemas';
import { dateToLocalDateString, iconUrlForFile } from '../../utils/helpers';
import { ReactComponent as DownloadIcon } from '../../assets/icons/chat-icons/download.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/chat-icons/link.svg';
import { ReactComponent as ForwardIcon } from '../../assets/icons/chat-icons/forward.svg';
import { ReactComponent as HashIcon } from '../../assets/icons/chat-icons/hash.svg';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import ShareAttachmentModal from './Modals/ShareAttachmentModal';

type AttachmentInfoPaneProps = {
  attachment: AttachmentDto | null;
};

export default ({ attachment }: AttachmentInfoPaneProps) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const channelResponse = useGetMany<ChannelDto>({
    resource: `channels?attachment=${attachment?.id}`,
    enabled: !!attachment,
  });
  const channels = channelResponse.data?.data.results ?? [];
  return (
    <Box width="440px" height="100%">
      {attachment && (
        <Stack direction="column" gap={2} m={2.5}>
          <Stack direction="row" gap={1.5} alignItems="center">
            <Box
              component="img"
              sx={{
                width: 30,
                height: 40,
                objectFit: 'cover',
              }}
              src={iconUrlForFile(attachment.fileName)}
            />
            <Stack direction="column">
              <Typography variant="bodySemiBold3" color="primaryDark.900">
                {attachment.fileName}
              </Typography>
              <Typography variant="body4" color="primaryDark.500">
                {dateToLocalDateString(new Date(attachment.created))}
              </Typography>
            </Stack>
            <Stack direction="row" ml="auto">
              <Link sx={{ p: 1 }} href={attachment.file} target="_blank">
                <LinkIcon width="12px" height="12px" />
              </Link>
              <Link sx={{ p: 1 }} href={attachment.file} download target="_blank">
                <DownloadIcon width="12px" height="12px" />
              </Link>
              <IconButton disableRipple sx={{ p: 1 }} onClick={() => setShareModalOpen(true)}>
                <ForwardIcon width="19px" height="12px" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="heading5" color="primaryDark.500">
              shared in
            </Typography>
            {channels.map((channel) => (
              <Stack direction="row" alignItems="center" spacing={1}>
                <HashIcon />
                <Typography color="primaryDark.600" variant="bodySemiBold3">
                  {channel.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
      {attachment && (
        <ShareAttachmentModal
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          attachmentId={attachment.id}
        />
      )}
    </Box>
  );
};
