import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { JSXElementConstructor, ReactElement } from 'react';
import { secondaryGreen } from '../../color';

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label: string | number | ReactElement<any, string | JSXElementConstructor<any>>;
  onChange?: () => void;
  removeRequiredAsterisk?: boolean;
} & CheckboxProps;

const ControlledCheckboxInput = ({
  name,
  required,
  disabled,
  label,
  onChange,
  removeRequiredAsterisk = false,
  ...props
}: Props) => {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <FormControlLabel
          sx={{
            m: 0,
            '.MuiCheckbox-root': {
              zIndex: 2,
              p: 0,
            },
            '.Mui-checked': {
              color: `${secondaryGreen[500]} !important`,
            },
            '.Mui-checked:after': {
              content: '""',
              left: '50%',
              top: '50%',

              zIndex: -1,
              transform: 'translate(-50%, -50%)',
              height: 15,
              width: 15,
              position: 'absolute',
              backgroundColor: 'primaryDark.600',
              borderRadius: '50%',
            },
            '& .MuiFormControlLabel-asterisk': {
              display: removeRequiredAsterisk ? 'none' : 'block'
            },
          }}
          control={
            <Checkbox
              required={required}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                onChange?.();
              }}
              {...props}
              checked={getValues(name)}
            />
          }
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};

export default ControlledCheckboxInput;
