// import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
} from 'react-router-dom';
import Box from '@mui/material/Box';

const Articles = () => {
  const [, setValue] = useState<number>(1);
  const location = useLocation();

  const tabLinks = ['/add-new', '/published', '/pending'];

  // set appropriate tab regarding the current location pathname
  useEffect(() => {
    const urlPath = window.location.pathname.split('/').pop();
    const index = tabLinks.findIndex((idx) => idx.split('/').pop() === urlPath);
    setValue(index);
  }, [location]);

  return (
    <Box sx={{ width: '100%' }}>
      <Outlet />
    </Box>
  );
};

export default Articles;
