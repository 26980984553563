import { Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { TimedProjectPitchContentDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { EnumScreenList } from 'tdc-web-backend/enums/enums';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import CustomScrollFade from '../../../../components/custom-scroll-fade/CustomScrollFade';
import TimedProjectsDetailsGeneral from './TimedProjectsDetailsGeneral';
import TimedProjectsDetailsPitches from './TimedProjectsDetailsPitches';
import theme from '../../../../theme';
import PitchDetailsDrawer from '../../../Pitch/DetailScreen/PitchDetailsDrawer';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { DrawerContext } from './DrawerContext';
import CaseStudiesDetailsDrawer from '../../CaseStudies/CaseStudiesDetails/CaseStudiesDetailsDrawer';
import BannerNotificationMapper from '../../../../components/notifications/bannerNotifications/BannerNotificationMapper';

const TimedProjectsDetails = () => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [timedProjectPitchId, setTimedProjectPitchId] = useState<string | null>(null);

  // case study related states
  const [caseStudyId, setCaseStudyId] = useState('');
  const [isCaseStudyDrawerOpen, setIsCaseStudyDrawerOpen] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);

  const { data: pitchContentData } = useReferenceOne<TimedProjectPitchContentDto>({
    enabled: !!timedProjectPitchId,
    id: timedProjectPitchId as string,
    resource: 'timed-project-pitch-contents',
  });

  // case study
  const { data: caseStudyData } = useReferenceOne<BaseCaseStudy>({
    resource: `case-studies`,
    id: caseStudyId,
    enabled: !!caseStudyId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Stack direction="row">
        <Stack spacing={8} sx={{ width: isLessThanLarge ? '100%' : '81%' }}>
          <BannerNotificationMapper screen={EnumScreenList.TimedProjectDetailScreen} />
          <TimedProjectsDetailsGeneral />
          <DrawerContext.Provider
            value={{ isDrawerOpen, setIsDrawerOpen, timedProjectPitchId, setTimedProjectPitchId }}
          >
            <TimedProjectsDetailsPitches />
          </DrawerContext.Provider>
          {/* Drawer */}
          <PitchDetailsDrawer
            pitchContent={pitchContentData as TimedProjectPitchContentDto}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            isRejectModalOpen={isRejectModalOpen}
            setIsRejectModalOpen={setIsRejectModalOpen}
            setCaseStudyId={setCaseStudyId}
            setIsCaseStudyDrawerOpen={setIsCaseStudyDrawerOpen}
          />
          {/* case study drawer */}
          {caseStudyData && (
            <CaseStudiesDetailsDrawer
              item={caseStudyData as BaseCaseStudy}
              setIsOpen={setIsCaseStudyDrawerOpen}
              open={isCaseStudyDrawerOpen}
              setShowReviewForm={setShowReviewForm}
              showReviewForm={showReviewForm}
            />
          )}
          <CustomScrollFade />
        </Stack>
        {!isLessThanLarge && <Stack sx={{ width: '19%' }} />}
      </Stack>
    </>
  );
};

export default TimedProjectsDetails;
