import {
  CompanyRole,
  EnumCompanySize,
  EnumCountry,
  EnumCurrency,
  EnumIndustry,
  EnumLanguage,
  EnumProjectHourlyRate,
  EnumProjectSize,
  EnumService,
} from 'tdc-web-backend/enums/enums';
import { TClient, TPostRegistration } from './interface';

export const initialState: Omit<TPostRegistration, 'details'> = {
  user: CompanyRole.SellerService,
  overview: {
    name: '',
    url: '',
    description: '',
    headquartersLocation: {
      country: '' as EnumCountry,
      city: '',
      state: '',
    },
    avatar: '',
  },
  admin: {
    jobTitle: '',
  },
};

// Depending on the user type we have different set of fields
export const initialBuyerState: TPostRegistration = {
  ...initialState,
  details: {
    targetIndustries: [] as EnumIndustry[],
    targetCountries: [] as EnumCountry[],
    companySize: '' as EnumCompanySize,
    languages: [] as EnumLanguage[],
  },
  user: CompanyRole.Buyer,
};

// The seller's set of fields is default by design
export const initialSellerState: TPostRegistration = {
  ...initialState,
  details: {
    targetIndustries: [] as EnumIndustry[],
    targetCountries: [] as EnumCountry[],
    companySize: '' as EnumCompanySize,
    languages: [] as EnumLanguage[],
    services: [] as EnumService[],
    clients: [] as TClient[],
    hourlyRate: '' as EnumProjectHourlyRate,
    averageProjectSize: '' as EnumProjectSize,
    currency: EnumCurrency.Usd,
  },
  user: CompanyRole.SellerService,
};

export const labels = ['User', 'Overview', 'Admin', 'Details'];

export const helperTexts = [
  '',
  '',
  'Your account administrator is user that registered your company account. Contact our support team to add additional account users and assign roles and permissions.',
  '',
];
