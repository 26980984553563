import { useState } from 'react';

const usePagination = () => {
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  return {
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
  };
};

export default usePagination;
