import { Stack, Typography } from '@mui/material';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified-icon.svg';

const VerifiedChip = () => (
  <Stack
    sx={{
      px: 1.25,
      py: 0.75,
      bgcolor: '#F6F7FF',
    }}
    direction="row"
  >
    <VerifiedIcon fill="#4051EC" />
    <Typography ml={0.75} variant="heading5" color="secondaryBlue.700">Verified</Typography>
  </Stack>
);

export default VerifiedChip;
