import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { EnumLanguage, mapLanguage } from 'tdc-web-backend/enums/enums';
import { Box } from '@mui/system';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as LanguagesIcon } from '../../../../../../../assets/icons/working-languages-icon.svg';
import { primaryLight } from '../../../../../../../color';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';

interface CompanyWorkingLanguagesModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyWorkingLanguagesEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyWorkingLanguagesModalProps) => {
  const refresh = useRefresh();
  const { companyData } = useSellerHomescreenContext();

  const methods = useForm<Pick<CompanyDto, 'languages'>>({
    mode: 'onChange',
    defaultValues: {
      languages: companyData?.languages ?? ([] as EnumLanguage[]),
    },
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Pick<CompanyDto, 'languages'>) => {
    update(
      { id: companyData?.id as string, data },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      title="Working languages"
      onCancel={() => setIsEditModalOpen(false)}
      onConfirm={methods.handleSubmit(onSubmit)}
      confirmButtonText="Save"
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      message={
        <FormProvider {...methods}>
          <Box component="form">
            <CustomInput
              input={<CustomInput.Autocomplete />}
              choices={mapLanguage}
              name="languages"
              label="Language"
              multiple
              placeholder="Select Language"
              sx={{ width: '100%' }}
              required
            />
          </Box>
        </FormProvider>
      }
      iconProps={{
        icon: <LanguagesIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
    />
  );
};

export default CompanyWorkingLanguagesEditModal;
