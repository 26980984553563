import { Card, Stack, Typography, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { ReactNode } from 'react';
import Link from '../../link/Link';

export interface DashboardCardProps {
  sx?: SxProps<Theme> | undefined;
  renderComponent: ReactNode | ReactNode[];
  headlineSx?: SxProps<Theme> | undefined;
  renderComponentSx?: SxProps<Theme> | undefined;
  headline?: string | ReactNode;
  headlineStartIcon?: string;
  headlineStartIconWidth?: string;
  headlineEndIcon?: ReactNode;
  headlineEndElement?: ReactNode;
  link?: string;
  linkText?: string;
  linkSx?: SxProps<Theme> | undefined;
  linkStartIcon?: JSX.Element;
  mainCardClassName?: string;
  viewAllClassName?: string;
  mainStackSpacing?: number;
}

const DashboardCard = ({
  sx,
  renderComponent,
  headlineSx,
  renderComponentSx,
  headline,
  headlineStartIcon,
  headlineStartIconWidth,
  headlineEndIcon,
  headlineEndElement,
  link,
  mainCardClassName,
  viewAllClassName,
  linkText = 'view all',
  linkSx,
  linkStartIcon,
  mainStackSpacing = 2,
}: DashboardCardProps) => (
  <Card
    className={mainCardClassName || undefined}
    elevation={0}
    sx={{
      overflow: 'visible',
      p: 3,
      pt: 3.5,
      backgroundColor: 'common.white',
      border: '1px solid',
      borderColor: 'secondaryBlue.100',
      borderRadius: 2,
      ...sx,
    }}
  >
    <Stack spacing={mainStackSpacing} minHeight={{ xs: '', xl: 170 }} height="100%">
      <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
        {headlineStartIcon ? (
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
            <Stack spacing={1.5} direction="row">
              {headlineStartIcon && (
                <Box component="img" src={headlineStartIcon} width={headlineStartIconWidth} />
              )}

              {typeof headline === 'string' ? (
                <Typography variant="heading4" color="primaryDark.500" sx={{ ...headlineSx }}>
                  {headline}
                </Typography>
              ) : (
                { headline }
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={1.5} direction="row" alignItems="center">
            <Typography variant="heading4" color="primaryDark.500" sx={{ ...headlineSx }}>
              {headline}
            </Typography>

            {headlineEndIcon}
          </Stack>
        )}

        {link && (
          <Link to={link}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{ pr: 1, ...linkSx }}
              className={viewAllClassName || undefined}
            >
              {linkStartIcon}
              <Typography variant="heading4" color="primaryLight.500">
                {linkText}
              </Typography>
            </Stack>
          </Link>
        )}
        {headlineEndElement && headlineEndElement}
      </Stack>

      <Box sx={{ ...renderComponentSx }}>{renderComponent}</Box>
    </Stack>
  </Card>
);

export default DashboardCard;
