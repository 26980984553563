/* eslint-disable import/no-unresolved */
import './relatedSlider.scss';
import { IconButton, Stack } from '@mui/material';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/system';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import { CaseStudyCarouselProps } from '../interface';

const settings: Settings = {
  centerMode: true,
  prevArrow: (
    <IconButton
      sx={{
        background: '#red !important',
        backgroundColor: '#red !important',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid',
        borderColor: 'primaryLight.500',
        p: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          color: 'primaryLight.500',
        },
      }}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <ChevronLeftIcon sx={{ color: 'primaryLight.700', fontSize: '1.5rem' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton
      sx={{
        background: '#red !important',
        backgroundColor: '#red !important',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid',
        borderColor: 'primaryLight.500',
        p: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          color: 'primaryLight.500',
        },
      }}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <ChevronRightIcon sx={{ color: 'primaryLight.700', fontSize: '1.5rem' }} />
    </IconButton>
  ),
  arrows: true,
  className: 'related',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  infinite: true,
  centerPadding: '0px',
  slidesToShow: 3,
  speed: 500,
};

const RelatedCaseStudy = ({ renderCustomComponent, services }: CaseStudyCarouselProps) => {
  const params = new URLSearchParams();

  services?.map((service: string) => {
    params.append('services', service);
  });

  const { data: response } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?limit=6&${params.toString()}`,
    enabled: !!params.toString().length,
  });

  const { data: responseBackup } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?limit=6`,
    enabled: !!params.toString().length,
  });
  const caseStudies = response?.data.results.length
    ? response?.data.results
    : responseBackup?.data.results;

  return (
    <Stack
      width="100%"
      sx={{ px: 0.5 }}
      maxWidth={{ md: '55rem', lg: '64.6rem', xxl: '72.4rem' }}
      mx="auto"
    >
      {caseStudies && <Slider {...settings}>{renderCustomComponent(caseStudies)}</Slider>}
    </Stack>
  );
};

export default RelatedCaseStudy;

// This wrapper is used to accept sliders default styling so that it could display paddings
export const SliderWrapperItem = ({ children }: { children: JSX.Element }) => (
  <Box
    sx={{
      maxWidth: { md: '16.75rem', lg: '18.875rem' },
      mx: 'auto',
      my: 3,
    }}
  >
    {children}
  </Box>
);
