import { Box, Stack } from '@mui/material';

const CustomArrow = ({ color }: { color: string }) => (
  <Stack direction="row" alignItems="center" spacing={0} width="100%">
    <Box sx={{ height: '5px', width: '5px', backgroundColor: color, borderRadius: '50%' }} />
    <Box sx={{ height: '1px', width: '100%', backgroundColor: color }} />
    <Box
      sx={{
        borderWidth: '3px 0px 3px 5px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderLeftColor: color,
      }}
    />
  </Stack>
);

export default CustomArrow;
