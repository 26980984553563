import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Card, Stack,
  Typography,
} from '@mui/material';
import { FlagDto } from 'tdc-web-backend/flags/schema';
import CustomReferenceField from '../../../../../components/custom-fields/CustomReferenceField';
import FlagDetailsModal from '../modals/FlagDetailsModal';
import { capitaliseString } from '../../../../../utils/helpers';

type TablePanelGrid = {
  rows: FlagDto[] | [];
}

export const checkForEntityType = (object: FlagDto) => {
  if (object.hasOwnProperty('milestone')) return 'milestone';
  if (object.hasOwnProperty('task')) return 'task';
  if (object.hasOwnProperty('subtask')) return 'subtask';
};

const FlagDataGrid = ({
  rows,
}: TablePanelGrid) => (

  <Card sx={{
    padding: '16px',
    borderRadius: '16px',
    width: '40%',
  }}
  >
    <Stack direction="row" justifyContent="space-between">
      <Typography sx={{
        fontWeight: 500,
        fontSize: '1.2em',
        marginBottom: '12px',
      }}
      >
        Project flags
      </Typography>
    </Stack>
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(e) => e.id}
      disableSelectionOnClick
      hideFooter
      sx={{ height: '400px' }}
    />
  </Card>
);

export default FlagDataGrid;

const columns: GridColDef[] = [

  {
    field: 'entity',
    headerName: 'Type',
    editable: false,
    disableColumnMenu: true,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography>{capitaliseString(checkForEntityType(params.row))}</Typography>
    ),
  },
  {
    field: 'flaggedBy',
    headerName: 'Flagged by',
    editable: false,
    flex: 0.3,
    disableColumnMenu: true,
    renderCell: (params: GridValueGetterParams) => (
      <CustomReferenceField resource="companies" id={params.value} displayName="name" />
    ),
  },
  {
    field: 'action',
    headerName: '',
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    flex: 0.01,
    renderCell: (params: GridValueGetterParams) => (
      <FlagDetailsModal flagData={params.row} />
    ),
  },
];
