import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepConnector, Typography } from '@mui/material';


type Props = {
  stepperValue: { label: string, completed: boolean, active: boolean }[];
  onClickStep: (i: number) => void
}
const connectorStyles = {
  '&.MuiStepConnector-vertical': {
    borderColor: 'primaryDark.500',
    borderLeftStyle: 'dashed',
    borderLeftWidth: '1px',
    minHeight: '25px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  '&.MuiStepConnector-root': {
    marginLeft: '6px',
  },
  '.MuiStepConnector-line': {
    display: 'none !important',
  },

  '&.Mui-completed': {
    borderColor: 'secondaryGreen.700',
    borderLeftStyle: 'solid',

  },
};

export default function ContractStepper({ stepperValue, onClickStep }: Props) {
  return (
    <Box sx={{
      alignItems: 'start',
    }}
    >
      <Stepper
        orientation="vertical"
        connector={<StepConnector sx={connectorStyles} />}
        activeStep={1}
      >
        {stepperValue?.map((step, i) => (
          <Step onClick={() => onClickStep(i)} completed={step.completed} key={step.label}>
            <StepLabel
              sx={{
                margin: 0,
                padding: 0,
                display: 'flex',
                flexDirection: 'row',
              }}
              icon={(
                <Box
                  sx={{
                    borderRadius: '50%',
                    fontSize: '1.2rem',
                    fontWeight: 800,
                    borderColor: step.active ? 'secondaryBlue.100' : (step.completed ? 'secondaryGreen.200' : 'primaryDark.300'),
                    backgroundColor: step.active ? 'secondaryBlue.700' : (step.completed ? 'secondaryGreen.500' : 'primaryDark.500'),
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    width: '12px',
                    height: '12px',
                    zIndex: 1,
                  }}
                />
              )}
            >
              <Typography
                sx={{
                  color:
                    step.active ? 'primaryLight.500' : (step.completed ? 'secondaryGreen.700' : 'primaryDark.500'),
                  fontSize: '16px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primaryLight.500'
                  }
                }}
              >
                {`${i + 1 <= 9 ? '0' : ''}${i + 1} ${step.label}`}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
