import { CompanyRole } from 'tdc-web-backend/enums/enums';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';
import BuyerHomescreen from './BuyerHomescreen/BuyerHomescreen';
import SellerHomescreen from './SellerHomescreen/SellerHomescreen';

type Props = {};

const Homescreen = (props: Props) => {
  const isBuyer = useDetermineCompanyRoles()?.includes(CompanyRole.Buyer);
  return isBuyer ? <BuyerHomescreen /> : <SellerHomescreen />;
};

export default Homescreen;
