import { Grid, Stack } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import ContactUsForm from './components/ContactUsForm';
import Location from './components/Location';
import locations from './utils';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  description: `Get in touch with Spona and find our offices.`,
};

const ContactUs = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack
      spacing={12}
      alignItems="start"
      direction={{ xs: 'column-reverse', lg: 'row' }}
      sx={{ mt: 20, ...LAYOUT_DEFAULTS }}
    >
      <Grid
        width={{ xs: 'auto', lg: '45%' }}
        container
        columnSpacing={3}
        rowSpacing={4}
        columns={{ xs: 12, md: 6, lg: 12 }}
      >
        {locations.map(({ id, ...rest }) => (
          <Grid item key={id} xs={12} md={3} lg={6}>
            <Location id={id} {...rest} />
          </Grid>
        ))}
      </Grid>

      <ContactUsForm sx={{ width: { xs: '100%', lg: '55%' } }} />
    </Stack>
  </>
);
export default ContactUs;
