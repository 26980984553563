/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
import { Box, Stack } from '@mui/system';
import { Divider, Grid } from '@mui/material';
import { useEffect } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import FilterSection from './FilterSection';
import CompanyCard from '../../../../components/public-screen-components/CompanyCard';
import CardListPagination from '../components/CardListPagination';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import useQueryState from '../../../../utils/hooks/useQueryState';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import LoadingIndicator from '../../../../components/loading-indicator/LoadingIndicator';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';

const SellerSubarchive = () => {
  const [queryState, , , searchParams] = useQueryState({ defaultValue: { limit: 12, offset: 0 } });

  const queryBuilder = () => {
    const params = new URLSearchParams(searchParams);
    params.set('limit', queryState.limit ?? '12');
    params.set('offset', queryState.offset ?? '0');
    params.set('orderBy', queryState.orderBy ?? '-created');
    params.set('role', 'seller_service');
    params.set('status', 'approved');
    params.set('visibility', 'true');

    return params.toString();
  };

  const {
    data: response,
    refetch,
    isLoading,
  } = useGetMany<CompanyDto>({
    resource: `/companies?${queryBuilder()}`,
  });

  const companies = response?.data.results;
  const count = response?.data.count;

  useEffect(() => {
    refetch();
  }, [queryState.limit, queryState.offset, refetch]);

  return (
    <>
      <Box>
        <Stack
          overflow="hidden"
          maxWidth="100%"
          alignSelf="center"
          mt={`${PUBLIC_APPBAR_HEIGHT}px`}
          sx={{
            px: { xs: '1.5rem', lg: '2.5rem' },
            mx: { gr: 'auto' },
            width: { gr: '85rem' },
          }}
        >
          <FilterSection />
        </Stack>
      </Box>
      <Box sx={{ backgroundColor: 'primaryDark.100' }}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {count && count > 0 ? (
              <Stack
                overflow="hidden"
                maxWidth="100%"
                alignSelf="center"
                sx={{
                  px: { xs: '1.5rem', lg: '2.5rem' },
                  mx: { gr: 'auto' },
                  width: { gr: '85rem' },
                }}
              >
                <Grid
                  container
                  spacing={3}
                  rowSpacing={5}
                  pt={0}
                  mt={-1.5}
                  gridAutoRows="max-content"
                >
                  {companies?.map((item: any, index) => (
                    <Grid
                      key={item?.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      gridAutoRows="max-content"
                    >
                      <CompanyCard showFocusArea companyData={item} />
                    </Grid>
                  ))}
                </Grid>

                <Divider
                  sx={{
                    mt: 5,
                  }}
                />
                <CardListPagination count={count} />
              </Stack>
            ) : (
              <CustomNoRowsOverlay
                sx={{ transform: 'none', WebkitTransform: 'none', position: 'static' }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SellerSubarchive;
