import { Dispatch, SetStateAction } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as TrashBinIcon } from '../../../../assets/icons/project-icons/TrashBinIcon.svg';
import { secondaryBlue } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';
import useDeleteOne from '../../../../utils/hooks/crud-hooks/useDeleteOne';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

type DeleteCaseStudyModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  id: string;
};

const DeleteCaseStudyModal = ({ setIsOpen, isOpen, id }: DeleteCaseStudyModalProps) => {
  const { mutate: deleteOne } = useDeleteOne<BaseCaseStudy>({ resource: '/case-studies' });
  const refresh = useRefresh();
  return (
    <CustomModal open={isOpen} onClose={() => setIsOpen(false)} width="35%">
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              p: 2,
            }}
          >
            <TrashBinIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
          </Stack>
          <Typography variant="heading3" color="primaryDark.600">
            Delete case study
          </Typography>
        </Stack>
        <Divider />
        <Typography color="primaryDark.500">
          Your case study will be permanently deleted. Are you sure you want to proceed?
        </Typography>
        <Divider />
        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          <CustomButton variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </CustomButton>
          <CustomButton
            variant="primary"
            onClick={() => {
              deleteOne(
                { id },
                {
                  onSuccess: () => {
                    refresh();
                    setIsOpen(false);
                  },
                },
              );
            }}
          >
            confirm
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DeleteCaseStudyModal;
