import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { useMediaQuery } from '@mui/material';
import SponaLogo from '../../../assets/img/logo/spona-purple.svg';
import CustomLink from '../../../components/link/Link';
import theme from '../../../theme';
import LAYOUT_DEFAULTS from '../../utils';
import { logos, socialMediaIcons } from './utils';

interface ColumnsLink {
  id: string;
  title: string;
  link: string;
}

interface Columns {
  id: number;
  title: string;
  links: ColumnsLink[];
}

interface SubLinks {
  title: string;
  link: string;
}

export interface FooterProps {
  columns: Columns[];
  subLinks?: SubLinks[];
}

// on screens greater than or equal to 1920 (xxl), we want the navigation
// and footer to be wider than the rest of the content
const CUSTOM_LAYOUT_DEFAULTS = { mx: { ...LAYOUT_DEFAULTS.mx, xxl: 10 } };

const Footer = ({ columns, subLinks }: FooterProps) => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Stack
        sx={{
          bgcolor: 'common.white',
          mt: 12,
          mb: 6,
          ...CUSTOM_LAYOUT_DEFAULTS,
        }}
      >
        <Stack spacing={2} height="100%" justifyContent="center">
          {/* spona logo, keep it together & links */}
          <Stack
            spacing={0.1}
            alignItems="start"
            justifyContent="space-between"
            width="100%"
            direction={isLessThanLarge ? 'column' : 'row'}
          >
            <Stack spacing={3}>
              <CustomLink to="/" sx={{ width: 'fit-content' }}>
                <Box component="img" src={SponaLogo} sx={{ height: '30px' }} />
              </CustomLink>

              <Stack spacing={-2}>
                <Typography
                  variant="publicHeadline9"
                  color="primaryLight.500"
                  fontSize="57px"
                  fontFamily="'Gellix-Regular'"
                >
                  Keep it
                </Typography>

                <Typography
                  component="span"
                  variant="publicHeading2"
                  fontSize="57px"
                  sx={{ position: 'relative' }}
                  color="primaryLight.500"
                >
                  together.
                </Typography>
              </Stack>
            </Stack>

            {/* links */}
            <Grid container rowSpacing={5} width={isLessThanLarge ? '100%' : '60%'}>
              {columns.map((column) => (
                <Grid
                  item
                  key={column.id}
                  xs={6}
                  lg={3}
                  sx={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Stack spacing={1} alignItems="start" width="100%">
                    <Typography color="primaryDark.500" variant="publicHeading5" fontSize="22px">
                      {column.title}
                    </Typography>

                    <Stack>
                      {column.links.map((link) => (
                        <Stack mb="6px" key={link.title}>
                          <CustomLink to={link.link}>
                            <Typography
                              color="common.black"
                              sx={{
                                typography: {
                                  xs: 'bodyMedium3',
                                  sm: 'bodyMedium2',
                                },
                              }}
                            >
                              {link.title}
                            </Typography>
                          </CustomLink>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>

          {/* social icons & eu icons */}
          <Stack spacing={7}>
            <Stack spacing={1}>
              <Stack direction="column" alignItems="start" sx={{ mt: 5, flexWrap: 'wrap' }}>
                <Box
                  sx={{
                    color: 'primaryDark.600',
                    textAlign: 'center',
                    flex: { xs: '1 0 auto', md: '0 0 auto' },
                  }}
                >
                  {socialMediaIcons.map((icon) => (
                    <a
                      style={{ color: '#32009A', textDecoration: 'none' }}
                      href={icon.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon key={icon.id} color="inherit" sx={{ mr: { xs: 1, md: 2 } }}>
                        {icon.icon}
                      </Icon>
                    </a>
                  ))}
                </Box>
              </Stack>

              <Divider
                light
                sx={{
                  width: '100%',
                  borderBottomWidth: '1px',
                  borderColor: 'primaryDark.400',
                }}
              />

              <Stack>
                <Typography variant="publicBody6Regular" color="common.black">
                  © Top Digital Agency Limited
                </Typography>

                <Typography variant="publicBody6Regular" color="common.black">
                  43 O'Connell Street Upper North City, Dublin, D01 DK81 Ireland
                </Typography>
              </Stack>
            </Stack>

            <Stack spacing={2}>
              {isLessThanLarge ? (
                <Grid
                  container
                  rowGap={4}
                  columns={{ xs: 12, sm: 12 }}
                  sx={{ width: { xs: '100%' } }}
                >
                  {logos.map((logo, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      lg={1}
                      key={logo.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'space-between', lg: 'start' },
                      }}
                    >
                      <Box component="img" width={logo.width} src={logo.src} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Stack
                  spacing={{ xs: 0, md: 3 }}
                  direction="row"
                  alignItems="center"
                  justifyContent={{ xs: 'space-between', md: 'start' }}
                  flexWrap="wrap"
                  rowGap={2}
                >
                  {logos.map((logo) => (
                    <Box component="img" width={100} src={logo.src} />
                  ))}
                </Stack>
              )}

              <Typography color="primaryDark.500" fontSize="0.7rem">
                Recipient of co-financing from the European Fund for Regional Development,
                Competitiveness and Cohesion Program.
              </Typography>

              <Typography color="primaryDark.600" fontSize="1rem">
                Copyright © 2023 Spona
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
