import {
  Divider, Stack, Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../components/modal/CustomModal';
import { secondaryBlue } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/layout-icons/PencilIcon.svg';

interface AmendContractModalProps {
    projectId: string;
    contractId: string;
setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
}

const AmendContractModal = ({
  setIsOpen,
  isOpen,
  contractId,
  projectId,
}: AmendContractModalProps) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (

    <CustomModal
      open={isOpen}
      width="30%"
      onClose={handleCancel}
    >
      {/* icon, title & divider */}
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              borderRadius: '6px',
              px: 2.05,
              py: 2.05,
            }}
          >
            <PencilIcon fill={secondaryBlue[700]} style={{ width: 18, height: 18 }} />
          </Stack>

          <Typography
            variant="heading3"
            color="primaryDark.600"
          >
            Amend contract
          </Typography>
        </Stack>

        <Divider sx={{ borderColor: '#E5E8FF' }} />

        <Typography color="primaryDark.500">
          Any edits to the contract must be made through an annex.
          Changes must be formally approved by both parties.
        </Typography>

        <Stack spacing={2.5}>
          <Divider sx={{ borderColor: '#E5E8FF' }} />

          <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
            <CustomButton
              variant="secondary"
              onClick={handleCancel}
            >
              Cancel
            </CustomButton>

            <CustomButton
              variant="primary"
              onClick={() => {
                navigate(`../project/${projectId}/contract/${contractId}`);
              }}
            >
              Continue
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default AmendContractModal;
