import { MutableRefObject, forwardRef, useRef, useState } from 'react';
import { Stack } from '@mui/system';
import { Slider, Typography } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { primaryDark, secondaryBlue } from '../../color';
import noBgImage from '../../assets/img/no-background.png';
import CustomButton from '../button/CustomButton';

type Props = {
  avatar: string;
  image?: any;
  cropRef: MutableRefObject<any>;
  withOpenFileExplorerButton?: boolean;
};

const AvatarEditorField = forwardRef(
  ({ avatar, image, cropRef, withOpenFileExplorerButton = false }: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [slideValue, setSlideValue] = useState(11);
    const [imageLocal, setImageLocal] = useState<any>(null);

    return (
      <Stack alignItems="center" spacing={2}>
        <Stack alignItems="center" bgcolor="primaryDark.800" width="100%">
          <AvatarEditor
            ref={cropRef}
            image={withOpenFileExplorerButton ? imageLocal || avatar : image || avatar}
            style={{
              borderRadius: '50%',
              border: '2px solid #FFF',
              cursor: 'move',
              background: `url(${noBgImage}) lightgray 0px 0px / 100% 108% no-repeat`,
            }}
            width={150}
            height={150}
            crossOrigin="anonymous"
            border={0}
            borderRadius={100}
            color={[38, 47, 70, 1]} // RGBA
            scale={slideValue / 10}
          />
        </Stack>
        <Stack alignItems="center" direction="row" spacing={3} width="100%">
          <Typography variant="body2">Size</Typography>
          <Slider
            min={10}
            max={40}
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e: any) => setSlideValue(e.target?.value)}
            sx={{
              color: 'primaryLight.500',
              width: '100%',
              height: '5px',
              '.MuiSlider-thumb': {
                border: `5px solid ${secondaryBlue[100]}`,
                boxShadow: `6px 6px 15px rgba(${primaryDark[500]}, 0.1)`,
              },
              '.MuiSlider-rail': {
                backgroundColor: 'primaryDark.300',
              },
            }}
          />
        </Stack>

        {withOpenFileExplorerButton && (
          <>
            <CustomButton
              onClick={() => inputRef?.current?.click()}
              type="button"
              variant="secondary"
              sx={{ alignSelf: 'end' }}
            >
              Change photo
            </CustomButton>

            <input
              ref={inputRef}
              type="file"
              hidden
              onChange={(e) => {
                setImageLocal(e.target.files?.[0]);
              }}
            />
          </>
        )}
      </Stack>
    );
  },
);

export default AvatarEditorField;
