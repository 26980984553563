import { Divider, Stack, Typography } from '@mui/material';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as EnvelopeIcon } from '../../../../assets/icons/project-icons/EnvelopeIcon.svg';
import CustomButton from '../../../../components/button/CustomButton';
import { Dispatch, SetStateAction } from 'react';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import { useNavigate } from 'react-router-dom';

export interface LoginModalProps {
  open: boolean;
  email: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
}

export const LoginModal = ({ open, email, setIsOpen, setOpenSuccessModal }: LoginModalProps) => {
  const navigate = useNavigate();

  const { mutate: resendEmail } = useCreate<Record<string, unknown>, Record<string, unknown>>({
    resource: '/auth/resend-verification',
  });

  const resendVerification = () => {
    resendEmail(
      { email },
      {
        onSuccess: () => {
          setOpenSuccessModal(true);
          setIsOpen(false);
        },
      },
    );
  };
  return (
    <CustomModal open={open} sx={{ width: { xs: '85%', lg: '45%' } }}>
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              p: 1.5,
            }}
          >
            <EnvelopeIcon />
          </Stack>
          <Typography variant="heading3" color="primaryDark.600">
            verify your email
          </Typography>
        </Stack>
        <Divider />
        <Typography color="primaryDark.500">
          You must verify your email before you can set up your account. If you did not receive a
          verification email, please{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={resendVerification}
          >
            click to resend.
          </span>
        </Typography>
        <Typography color="primaryDark.500">
          If you would like to talk to our support team,{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/contact-us')}
          >
            send us a message
          </span>
        </Typography>
        <Divider />
        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          <CustomButton variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
