import {
  Box,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { useState } from 'react';
import { TabContext } from '@mui/lab';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import SubtasksTablePanelDataGrid from './SubtasksTablePanelDataGrid';
import { SubtasksModalProps } from '../Modals/SubtasksAddModal';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';

enum SubtaskListTab {
  ALL = 'All',
  TODO = 'To do',
  COMPLETE = 'Complete',
}

const tabs: SubtaskListTab[] = [
  SubtaskListTab.ALL,
  SubtaskListTab.TODO,
  SubtaskListTab.COMPLETE,
];

// TODO: re-factor this list to be implemented the same way as all lists in end-to-end screens are
// and all of its children components;
// and also I have made flagging working here on the existing implementation,
// but I highly encourage to follow existing coding style because it differs from the existing
// ones and that results in wasting time figuring things out and making stuff work etc.
// FIXME: WHY is SubtasksModalProps outside this component??
const SubtaskList = ({ task }: SubtasksModalProps) => {
  const [selectedTab, setSelectedTab] = useState<SubtaskListTab>(tabs[0]);
  const { data } = useGetMany<SubtaskDto>({ resource: `/subtasks?task=${task?.id}` });
  const subtasksArray = data?.data.results;

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column" spacing={1}>
        <TabContext value={selectedTab}>
          <Box>
            <Tabs
              className="react-joyride-step-5-task"
              sx={{
                mb: 1.5,
                '& .MuiTabs-indicator': {
                  bgcolor: 'secondary.main',
                  mb: '8px !important',
                },
              }}
              value={selectedTab}
              onChange={(e, value) => setSelectedTab(value)}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab value={SubtaskListTab.ALL} label={`All (${task?.subtasksCount})`} />
              <Tab
                value={SubtaskListTab.TODO}
                label={`To do (${task?.subtasksCount - task?.subtasksCompletedCount})`}
              />
              <Tab
                value={SubtaskListTab.COMPLETE}
                label={`Completed (${task?.subtasksCompletedCount})`}
              />
            </Tabs>
            <SubtasksTablePanelDataGrid
              value={SubtaskListTab.ALL}
              tabIndex={0}
              rows={subtasksArray ?? []}
            />
            <SubtasksTablePanelDataGrid
              value={SubtaskListTab.TODO}
              tabIndex={1}
              rows={subtasksArray?.filter((task) => task?.status !== true) ?? []}
            />
            <SubtasksTablePanelDataGrid
              value={SubtaskListTab.COMPLETE}
              tabIndex={2}
              rows={subtasksArray?.filter((task) => task?.status === true) ?? []}
            />
          </Box>
        </TabContext>
      </Stack>
    </Box>
  );
};

export default SubtaskList;
