import { Avatar, Divider, Stack, SxProps, Theme, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import {
  EnumCountry,
  mapCompanySize,
  mapCountry,
  mapHourlyRate,
  mapProjectSize,
} from 'tdc-web-backend/enums/enums';
import { ReactComponent as TimedProjectIcon } from '../../../../assets/icons/project-icons/TimedProject.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/subheader/LocationOnFilled.svg';
import { ReactComponent as TagIcon } from '../../../../assets/img/public-pages/tag.svg';
import { ReactComponent as ThreePeopleIcon } from '../../../../assets/img/public-pages/threeUsers.svg';
import { primaryDark } from '../../../../color';

import IconWithToolTip from '../../../../components/icon-with-tooltip/IconWithToolTip';

const CompanyProfileInfoIcons = ({
  company,
  sx,
}: {
  company: CompanyDto | undefined;
  sx?: SxProps<Theme>;
}) => (
  <Stack spacing={4} sx={{ ...sx }}>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.5}>
        <IconWithToolTip
          icon={
            <ThreePeopleIcon fill={primaryDark[500]} style={{ width: '20px', height: '20px' }} />
          }
          toolTipText="COMPANY SIZE"
        />
        <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
          People
        </Typography>
      </Stack>
      <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
        {company && company.companySize && company.companySize
          ? mapCompanySize.get(company.companySize)
          : '-'}
      </Typography>
    </Stack>

    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.5}>
        <IconWithToolTip
          icon={<TagIcon fill={primaryDark[500]} />}
          toolTipText="TYPICAL PROJECT SIZE"
          style={{ width: '20px', height: '20px' }}
        />
        <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
          Price
        </Typography>
      </Stack>
      <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
        {company && company.averageProjectSize && company.averageProjectSize
          ? mapProjectSize.get(company.averageProjectSize)
          : '-'}
      </Typography>
    </Stack>

    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.5}>
        <IconWithToolTip
          icon={<LocationIcon fill={primaryDark[500]} />}
          toolTipText="HEADQUARTERS LOCATION"
          style={{ width: '20px', height: '20px' }}
        />
        <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
          Location
        </Typography>
      </Stack>
      <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
        {company && company.headquartersLocation
          ? `${mapCountry.get(company.headquartersLocation.country as EnumCountry)}, ${
              company.headquartersLocation.city
            }`
          : '-'}
      </Typography>
    </Stack>

    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.5}>
        <IconWithToolTip
          icon={<TimedProjectIcon fill={primaryDark[500]} />}
          toolTipText="HOURLY RATE"
          style={{ width: '20px', height: '20px' }}
        />
        <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
          Hourly rate
        </Typography>
      </Stack>
      <Typography variant="bodyMedium3" sx={{ fontWeight: 450 }}>
        {company && company.hourlyRate ? mapHourlyRate.get(company.hourlyRate) : '-'}
      </Typography>
    </Stack>
  </Stack>
);

export default CompanyProfileInfoIcons;
