import { Checkbox, Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import axios, { instance } from '../../../../api/axios';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import CustomModal from '../../../../components/modal/CustomModal';

interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TermsAndConditionsModal = ({ isOpen, setIsOpen }: ModalProps) => {
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const userData = useGetLocalStorageUserData();
  const token = localStorage.getItem('AccessToken');

  // FIXME: code below resolves the issue of "401 Unauthorized" for the refetch query below;
  // the problem is that the Authorization header is not set (in time) in App.tsx;
  // so after login query, the Authorization header is still empty
  // (assuming of course if page is not refreshed or redirected);
  // so then `users/${userData?.id}` query returns 401 error;
  useEffect(() => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }, [token]);

  const { refetch } = useReferenceOne<BaseUserDto>({
    resource: 'users',
    id: userData?.id,
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      localStorage.setItem('userData', JSON.stringify(data));
      navigate('/dashboard/end-to-end', { replace: true });
      setIsOpen(false);
    },
  });

  const { mutate: acceptTerms } = useMutation({
    mutationFn: () =>
      axios.post(
        `/users/${userData?.id}/acceptTerms`,
        {},
        { headers: { Authorization: `Bearer ${token}` } },
      ),
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (userData !== null && userData.agreementDate === null) return setIsOpen(true);
    return setIsOpen(false);
  }, [userData]);

  const handleConfirm = () => {
    if (userData && token) {
      acceptTerms();
    }
  };

  return (
    <CustomModal open={isOpen}>
      <Stack spacing={2}>
        <Stack>
          <Typography
            sx={{
              fontSize: '1.25em',
              fontWeight: 500,
            }}
          >
            Important!
          </Typography>
        </Stack>
        <Stack direction="row">
          <Checkbox color="secondary" onChange={() => setDisabled(!disabled)} />
          <Typography
            sx={{
              mt: 3,
            }}
          >
            To start using Spona, you must first agree to our&nbsp;
            <a
              style={{ color: '#32009A', textDecoration: 'none' }}
              href="/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms of service
            </a>{' '}
            and{' '}
            <a
              href="/terms-of-service/privacy-policy"
              style={{ color: '#32009A', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            by checking the box.
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={disabled}
            onButtonClick={() => handleConfirm()}
            sx={{
              borderRadius: '100px',
              fontWeight: 500,
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
export default TermsAndConditionsModal;
