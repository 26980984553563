import { Divider, Stack, Typography } from '@mui/material';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import CustomModal from '../../../../../../components/modal/CustomModal';
import { secondaryBlue } from '../../../../../../color';
import CustomButton from '../../../../../../components/button/CustomButton';

export interface ReviewModalProps {
  open: boolean;
  handleConfirm: () => void;
}

const ReviewModal = ({ open, handleConfirm }: ReviewModalProps) => {
  return (
    <CustomModal open={open} width="40%">
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <BriefcaseCheckIcon width="20px" fill={secondaryBlue[700]} height="20px" />
          </Stack>
          <Typography variant="heading5">schedule call</Typography>
        </Stack>
        <Divider />
        <Typography pt={1} pb={1} variant="body3" color={'primaryDark.500'}>
          Thank you for submitting your case study review. The last step is to schedule a quick call
          with a Spona advisor so that we can verify your identity and publish your review on our
          platform.
        </Typography>
        <Typography pt={1} pb={1} variant="body3" color={'primaryDark.500'}>
          Please click continue to select a time for the call.
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'right' }} spacing={2}>
          <CustomButton onClick={() => handleConfirm()}>Continue</CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ReviewModal;
