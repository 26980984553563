/* eslint-disable react/no-unescaped-entities */
import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const joyrideConfig: Partial<Step> = {
  disableBeacon: true,
  hideCloseButton: false,
  hideFooter: false,
  placement: 'auto',
  styles: {
    options: {
      zIndex: 10,
    },
  },
};

const EndToEndJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    title: 'Projects Screen',
    content: (
      <Typography>
        Welcome to your Projects screen. Here you can perform all essential project management
        actions and view all related project data.
      </Typography>
    ),
    target: '.react-joyride-step-1',
    ...joyrideConfig,
    disableScrolling: true,
  },
  {
    title: 'Messaging',
    content: (
      <Typography>
        Our messaging feature is currently in progress. Get in touch with your buyer and the Spona
        support team, share files, schedule meetings and more.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-2',
  },
  {
    title: 'Calendar',
    content: (
      <Typography>
        Click the calendar icon to check your project schedule. You can also use the calendar to
        create new events and invite participants.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-3',
  },
  {
    title: 'Notifications',
    content: (
      <Typography>
        Click the bell icon to see all your project activities. When you have unread notifications,
        a purple dot will appear next to the bell.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-4',
  },
  {
    title: 'Payment summary',
    content: (
      <Typography>
        Start working on a project to get an overview of your most recent transactions on the
        platform.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-5',
  },
  {
    title: 'Upcoming deadlines',
    content: (
      <Typography>
        Start working on a project to view deadlines on your most recent milestones and tasks across
        projects.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-6',
  },
  {
    title: userBuyer ? 'Outgoing payments' : 'Earnings',
    content: userBuyer ? (
      <Typography>
        The outgoing payments graph shows your finalized and upcoming payments across your desired
        time period. Filter it by 1 month, 6 months or a year.
      </Typography>
    ) : (
      <Typography>
        The earnings graph shows your received and upcoming earnings across your desired time
        period. Filter it by 1 month, 6 months or a year.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-7',
  },
  {
    title: 'Contracts',
    content: (
      <Typography>
        Click the contracts button to go to your contracts screen. There you can get a detailed
        overview of how your contracts are progressing.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-8',
  },
  {
    title: 'Project status',
    content: (
      <Typography>
        Take a look at your current status across all projects. Clicking a status will filter your
        data - for example, clicking In progress shows only projects that are currently being worked
        on.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-9',
  },
  {
    title: 'Project screen',
    content: (
      <Typography>
        Click anywhere on a project entry to enter that project's screen. You can do that right now
        to continue setting up your first project.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-10',
  },
];

export default EndToEndJoyrideSteps;
