import { Menu } from '@material-ui/core';
import { MenuItem } from '../EditorCommand';

export const DefaultEditorMenu = [
  MenuItem.TextStyle,
  MenuItem.DividerLine,
  // MenuItem.FontFamily,
  MenuItem.Bold,
  MenuItem.Italic,
  MenuItem.Strike,
  MenuItem.Underline,
  MenuItem.Highlight,
  MenuItem.BlockQuote,
  MenuItem.DividerLine,
  MenuItem.BulletList,
  MenuItem.OrderedList,
  MenuItem.DividerLine,
  MenuItem.TextAlignLeft,
  MenuItem.TextAlignCenter,
  MenuItem.TextAlignRight,
  MenuItem.TextAlignJusitfy,
  MenuItem.DividerLine,
  MenuItem.CodeBlock,
  MenuItem.Link,
  MenuItem.Image,
  MenuItem.Embed,
  MenuItem.Table,
  MenuItem.DividerLine,
  MenuItem.Undo,
  MenuItem.Redo,
];

export const MinifiedEditorMenu = [
  MenuItem.TextStyle,
  MenuItem.DividerLine,
  MenuItem.Bold,
  MenuItem.Italic,
  MenuItem.Strike,
  MenuItem.Underline,
  MenuItem.Highlight,
  MenuItem.BlockQuote,
  MenuItem.DividerLine,
  MenuItem.BulletList,
  MenuItem.OrderedList,
  MenuItem.DividerLine,
  MenuItem.TextAlignLeft,
  MenuItem.TextAlignCenter,
  MenuItem.TextAlignRight,
];

export const TimedProjectEditorMenu = [
  MenuItem.Bold,
  MenuItem.Italic,
  MenuItem.BulletList,
  MenuItem.OrderedList,
  MenuItem.DividerLine,
];

export const CaseStudyEditorMenu = [
  MenuItem.Bold,
  MenuItem.Italic,
  MenuItem.DividerLine,
  MenuItem.Link,
  MenuItem.Image,
  MenuItem.DividerLine,
  MenuItem.BulletList,
  MenuItem.OrderedList,
];

export const BlogEditorMenu = [
  MenuItem.TextStyle,
  MenuItem.DividerLine,
  MenuItem.Bold,
  MenuItem.Italic,
  MenuItem.BlockQuote,
  MenuItem.DividerLine,
  MenuItem.Image,
  MenuItem.Link,
  MenuItem.DividerLine,
  MenuItem.BulletList,
  MenuItem.OrderedList,
  MenuItem.TextAlignLeft,
  MenuItem.TextAlignCenter,
  MenuItem.TextAlignRight,
  MenuItem.TextAlignJusitfy,
];
