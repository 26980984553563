import { Typography, Avatar, Stack } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';

const CompanyTeam = () => {
  const { companyData } = useSellerHomescreenContext();

  const teamMembers: CompanyDto['team'][] = companyData?.team;

  return (
    <Stack direction="row" gap={1} alignItems="flex-start" sx={{ flexWrap: { md: 'wrap' } }}>
      {teamMembers &&
        teamMembers.length > 0 &&
        teamMembers.slice(0, 6).map((teamMember) => (
          <Stack key={teamMember.id} direction="row" alignItems="center" spacing={1}>
            {' '}
            <Avatar
              src={teamMember.avatar}
              alt={teamMember.name}
              sx={{ width: 32, height: 32, boxShadow: '0px 1px 3px 1px #00000026' }}
            />
          </Stack>
        ))}

      {teamMembers && teamMembers.length > 6 && (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'primaryLight.100',
            borderRadius: '50%',
            boxShadow: '0px 1px 3px 1px #00000026',
            width: 32,
            height: 32,
          }}
        >
          <Typography variant="body1" fontSize="1rem" color="primaryLight.500">
            +{teamMembers.length - 6}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyTeam;
