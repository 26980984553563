import { createContext, useContext } from 'react';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';

export interface SellerHomescreenContextI {
  companyData: CompanyDto | undefined;
  user: BaseUserDto | null;
  companyClients: CompanyClientDto[] | undefined;
  companyCaseStudies: BaseCaseStudy[] | undefined;
  chipLabelTruncateValue?: number;
}

const SellerHomescreenContext = createContext<SellerHomescreenContextI>(
  {} as SellerHomescreenContextI,
);

export const useSellerHomescreenContext = (): SellerHomescreenContextI => {
  const context = useContext(SellerHomescreenContext);

  return context;
};

export default SellerHomescreenContext;
