import { Stack, Box } from '@mui/system';
import { Typography } from '@mui/material';
import CustomButton from '../../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/layout-icons/ArrowRight.svg';
import { useNavigate } from 'react-router-dom';
import imageAuth from '../../../../../assets/img/seller_dashboard_createstudy.svg';
import imageNotAuth from '../../../../../assets/img/seller_dashboard_createstudy_disabled.svg';
import { useSellerHomescreenContext } from '../SellerHomescreenContext';

const CompanyHomeCreateCS = () => {
  const { companyData, companyCaseStudies } = useSellerHomescreenContext();
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <Box
        component="img"
        src={
          companyData?.status === 'pending' || companyData?.status === 'rejected'
            ? imageNotAuth
            : imageAuth
        }
        sx={{ objectFit: 'cover', height: 200, width: '100%' }}
      />
      <Typography color="primaryDark.600" alignSelf="center" textAlign="center" sx={{ px: 4 }}>
        Client-reviewed case studies are the first step to winning new projects on Spona.
      </Typography>
      <CustomButton
        endIcon={<ArrowRightIcon fill="#fff" style={{ width: 16, height: 16 }} />}
        sx={{ width: 'auto', alignSelf: 'center' }}
        variant="primary"
        disabled={companyData?.status === 'pending' || companyData?.status === 'rejected'}
        onClick={() => navigate('/dashboard/case-studies/')}
      >
        {companyCaseStudies && companyCaseStudies?.length > 0
          ? 'case studies'
          : 'create case study'}
      </CustomButton>
    </Stack>
  );
};

export default CompanyHomeCreateCS;
