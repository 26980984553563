import { Stack, Typography } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { secondaryBlue } from '../../../../color';
import ReusableHeroSection from '../../../../components/public-screen-components/ReusableHeroSection';
import TextAndImage from '../../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from '../components/HowMuchDoesItCost';
import BecomeAPartOfSpona from '../../../../components/public-screen-components/BecomeAPartOfSpona';
import { textsAndImagesMilestonesAndTasks } from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../../components/seo/MetaTags';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums/public-page-images';

const metaTags = {
  description: `See how Spona can help you track your outsourced project's progress with real-time milestone and task management.`,
};

const MilestonesAndTasks = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Milestones and Tasks"
        content="Keeping up to date with milestones and tasks is the core of successfully managing a project. Spona’s standardized and streamlined approach allows you to obtain an immediate overview of your operations across all of your projects."
        image={getPublicImageUrl(EnumPublicPagesImage.MilestonesAndTasksHero)}
        sx={{ height: { xs: `calc(73vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        alt="A person checking off a task from to-do list"
      />

      <Stack spacing={10} sx={{ mt: { xs: '-90px', lg: 12 } }}>
        {textsAndImagesMilestonesAndTasks.map(({ title, content, image, contentOrder, alt }) => (
          <TextAndImage
            key={content}
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentStyles={{ justifyContent: 'center' }}
            contentSpacing={{ xs: 2, lg: 3 }}
            alt={alt}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        firstCard={{
          title: 'Traditional Task Management',
          sx: { alignItems: 'start' },
          bgcolor: 'white',
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Spread across multiple platforms</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Not standardized between teams</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Additional tools used to get metrics</Typography>
              </Stack>
            </Stack>
          ),
        }}
        secondCard={{
          title: 'With Spona',
          sx: { alignItems: 'start' },
          bgcolor: secondaryBlue[101],
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Centralizes tasks across all projects</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Makes your management uniform </Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryBlue.501' }} />

                <Typography>Provides you with all data you need</Typography>
              </Stack>
            </Stack>
          ),
        }}
      />

      <BecomeAPartOfSpona
        title="Get started with End to End"
        bgcolor="secondaryYellow.100"
        color="black"
      />
    </Stack>
  </>
);

export default MilestonesAndTasks;
