import { MenuItem } from '@mui/material';

import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import ControlledSelectInput from '../../components/custom-inputs/ControlledSelectInput';

export type ResourceSelectInputProps<T extends { id: string }> = {
  name: string;
  resource: string;
  multiple?: boolean;
  label: string;
  placeholder: string;
  filter: Record<string, string>;
  choiceLabel: (choice: T) => string;
  choiceFilter?: (choices: T[]) => T[];
};

export default <T extends { id: string }>({
  name,
  resource,
  multiple,
  filter,
  choiceLabel,
  label,
  placeholder,
  choiceFilter,
}: ResourceSelectInputProps<T>) => {
  const { data } = useGetMany<T>({
    resource: `${resource}?${new URLSearchParams(filter).toString()}`,
  });

  let choices = data?.data.results ?? [];
  if (choiceFilter) {
    choices = choiceFilter(choices);
  }
  const choiceMap = new Map(choices.map((user) => [user.id, user]));

  return (
    <>
      <ControlledSelectInput<T>
        multiple={multiple}
        choices={choiceMap}
        defaultValue={multiple ? [] : null}
        label={label}
        placeholder={placeholder}
        name={name}
        withChips={multiple}
        transformLabelFn={choiceLabel}
        renderCustomMenuItem={(choice) => (
          <MenuItem key={choice.id} value={choice.id}>
            {choiceLabel(choice)}
          </MenuItem>
        )}
      />
    </>
  );
};
