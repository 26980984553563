import React from 'react';
import { useForm } from 'react-hook-form';
import { IStep, TStepAction } from './multistep.interface';

interface IProps<T> {
  labels: string[];
  initialState: T;
}

function useMultiStep<T extends { [x: string]: any } | undefined>({
  labels,
  initialState,
}: IProps<T>) {
  const [currentStep, setCurrentStep] = React.useState(1);

  const form = useForm({
    defaultValues: initialState,
    mode: 'onChange',
  });

  const onStepChange = async (action: TStepAction) => {
    const isValid = await form.trigger();

    switch (action) {
      case 'increment':
        if (isValid) setCurrentStep((prev) => prev + 1);
        break;
      case 'decrement':
        setCurrentStep((prev) => prev - 1);
        break;
      default:
        break;
    }
  };

  const steps: IStep[] = React.useMemo(
    () =>
      labels.map((label, idx) => ({
        label,
        position: idx + 1,
        status: idx + 1 === currentStep ? 'active' : idx + 1 < currentStep ? 'success' : 'disabled',
      })),
    [currentStep],
  );

  return {
    currentStep,
    steps,
    onStepChange,
    form,
  };
}

export default useMultiStep;
