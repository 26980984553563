import {
  Container as MuiContainer, ContainerProps, SxProps, Theme,
} from '@mui/material';
import { PADDINGS } from '../../../utils/constants/paddings';

export interface ContainerPropsI {
  xs?: boolean,
  sm?: boolean,
  md?: boolean,
  lg?: boolean,
  xl?: boolean,
  height?: number | string,
  children: React.ReactNode,
  sx?: SxProps<Theme> | undefined,
}

const Container = ({
  disableGutters, maxWidth, xs, sm, md, lg, xl, height, children, sx,
}: (ContainerProps & ContainerPropsI)) => (
  <MuiContainer
    disableGutters={disableGutters ?? true}
    maxWidth={maxWidth ?? 'xl'}
    sx={{
      px: {
        xs: xs ? PADDINGS.XS : 'none',
        sm: sm ? PADDINGS.SM : 'none',
        md: md ? PADDINGS.MD : 'none',
        lg: lg ? PADDINGS.LG : 'none',
        xl: xl ? PADDINGS.XL : 'none',
      },
      height: height ?? 'auto',
      ...sx,
    }}
  >
    {children}
  </MuiContainer>
);

export default Container;
