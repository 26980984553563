import { Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { TermOfServiceI, fakeLinkStyles } from '../utils';
import CustomList from '../components/CustomList';

const cookiePolicy: TermOfServiceI[] = [
  {
    content: [
      {
        title: 'Effective from January 2024',
        paragraphs: [
          {
            text: () => (
              <>
                This policy provides detailed information about how Spona uses cookies on{' '}
                <a href="https://spona.io" style={{ textDecoration: 'underline' }}>
                  https://spona.io
                </a>{' '}
                (the “Site”). We advise you to also consult our Privacy Policy for additional
                information on how we collect and use information collected from visitors to the
                Site. This Cookie Policy applies to any Spona product or service that links to this
                policy or incorporates it by reference.
              </>
            ),
          },
          {
            text: () => {
              const [setBannerOpen] = useOutletContext() as any;

              return (
                <>
                  <Typography variant="publicBody5Regular" color="common.black">
                    By using the Site, you agree that we can use the cookies described in this
                    Cookie Policy. Although most browsers and devices accept cookies by default,
                    their settings usually allow you to clear and to decline cookies. You may change
                    your cookie consent choices by clicking on the button{' '}
                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles }}
                      onClick={() => setBannerOpen(true)}
                    >
                      manage cookies
                    </Typography>
                    . If you disable cookies, however, some of the features of our Site may not
                    function properly.
                  </Typography>
                </>
              );
            },
          },
        ],
      },
      {
        title: 'What are Cookies ?',
        paragraphs: [
          {
            text: () =>
              'A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'First party cookies are cookies set by the website you are visiting. Only that website can read them. In addition, a website might potentially use external services, which also set their own cookies, known as third-party cookies.',
                  ],
                })}
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit your browser, unlike a session cookie, which is deleted when you quit your browser.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'First time you visit the website managed by Spona you will be prompted to accept or refuse cookies.The purpose is to enable the site to remember your preferences (such as user name, language, etc.) for a certain period of time. That way, you do not have to re-enter them when browsing around the site during the same visit.Cookies can also be used to establish anonymised statistics about the browsing experience on our sites.',
          },
        ],
      },
      {
        title: 'How Do We Use Cookies?',
        paragraphs: [
          {
            text: () =>
              'As described in Section 1.4 of our Privacy Policy we use cookies and other technologies to ensure everyone who uses Spona has the best possible experience. Our websites mostly use first-party cookies. These are cookies set and controlled by the Spona, not by any external organisation. However, to view some of our pages, you will have to accept cookies from external organisations.',
          },
          {
            text: () => 'The 3 types of first-party cookie we use are to:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: ['store visitor preferences'],
                })}
                {CustomList({
                  listType: 'dot',
                  items: ['make our websites operational'],
                })}
                {CustomList({
                  listType: 'dot',
                  items: ['gather analytics data (about user behaviour).'],
                })}
              </>
            ),
          },
        ],
      },
      {
        title: 'When Does Spona Place Cookies?',
        paragraphs: [
          {
            text: () => (
              <>
                We use cookies on{' '}
                <a href="https://spona.io" style={{ textDecoration: 'underline' }}>
                  https://spona.io
                </a>{' '}
                and other Spona related sites. Any browser visiting these sites will receive cookies
                from us. We also place cookies in your browser when you visit non-Spona sites that
                host our plugins or tags. We do not place unnecessary cookies before the visitor
                consent.
              </>
            ),
          },
        ],
      },
      {
        title: 'What Are Cookies Used For?',
        paragraphs: [
          {
            text: () =>
              'Cookies applied on Spona fall into two categories: strictly necessary and analytics. Cookies can be used to recognize you when you visit Spona, remember your preferences, and give you a personalized experience that’s in line with your settings. Cookies also make your interactions with Spona faster and more secure. Additionally, cookies allow us to bring you advertising both on and off the Spona sites, and bring customized features to you through Spona plugins.',
          },
          {
            text: () => 'Essential',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () => 'Analytics',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        title: 'What Is Do Not Track (DNT)?',
        paragraphs: [
          {
            text: () =>
              'DNT is a concept that has been promoted by regulatory agencies such, as the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites by using browser settings. The World Wide Web Consortium (W3C) has been working with industry groups, Internet browsers, technology companies, and regulators to develop a DNT technology standard. While some progress has been made, it has been slow. No standard has been adopted to this date. As such, Spona does not generally respond to “do not track” signals.',
          },
        ],
      },
      {
        title: 'How Are Cookies Used for Advertising Purposes?',
        paragraphs: [
          {
            text: () =>
              'Cookies and other ad technology such as pixels, and tags help us serve relevant ads to you more effectively. They also help us provide aggregated auditing, research, and reporting for advertisers, understand and improve our service, and know when content has been shown to you. Note: Because your web browser may request advertisements and beacons directly from third party ad network servers, these networks can view, edit, or set third-party cookies, just as if you had requested a web page from their site. Ads served by Spona may also set third-party cookies.',
          },
          {
            text: () =>
              'If you are logged in on any of our sites or another service that references this Cookie Policy or browsing a third party partner site of Spona and one of our cookies on your device identifies you, your usage (such as your browsing behavior) and log data (such as your IP address), will be associated by us with your account as noted in Sections 1.3 and 1.4 of our Privacy Policy. We also use aggregate data from third parties and data from your profile and Spona activity.',
          },
          {
            text: () =>
              'If you are a Spona member but logged out of your account on a browser, Spona may still continue to log your interaction with our services on that browser for up to 30 days in order to generate usage analytics for our services, which analytics we may share in aggregate form with our advertising customers.',
          },
          {
            text: () =>
              'Unless you clear these cookies from your browser, we may use this information to:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Provide more relevant, interest-based advertising.',
                    'Provide aggregate reports of ads activity to advertisers and websites hosting the ads.',
                    'Help website and app owners understand how visitors engage with their sites or apps.',
                    'Detect and defend against fraud and other risks to protect users and partners.',
                    'Improve our products.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        title: 'Controlling Cookies',
        paragraphs: [
          {
            text: () => (
              <>
                <Typography variant="publicBody4">Removing cookies from your device</Typography>
                <br />
                You can delete all cookies that are already on your device by clearing the browsing
                history of your browser. This will remove all cookies from all websites you have
                visited.Be aware though that you may also lose some saved information (e.g. saved
                login details, site preferences).
              </>
            ),
          },
          {
            text: () => (
              <>
                <Typography variant="publicBody4">Managing site-specific cookies</Typography>
                <br />
                For more detailed control over site-specific cookies, check the privacy and cookie
                settings in your preferred browser.
              </>
            ),
          },
          {
            text: () => (
              <>
                <Typography variant="publicBody4">Blocking cookies</Typography>
                <br />
                You can set most modern browsers to prevent any cookies being placed on your device,
                but you may then have to manually adjust some preferences every time you visit a
                site/page. And some services and functionalities may not work properly at all (e.g.
                profile logging-in).
              </>
            ),
          },
        ],
      },
      {
        title: 'Other Helpful Resources',
        paragraphs: [
          {
            text: () =>
              'To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit:',
          },
          {
            text: (onClick) => (
              <>
                {CustomList({
                  listType: 'dot',
                  omitListType: true,
                  items: [
                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() => window.location.replace('http://www.youronlinechoices.eu')}
                    >
                      European Interactive Digital Advertising Alliance (EU)
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() => window.location.replace('http://www.iab.net/')}
                    >
                      Internet Advertising Bureau (US)
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() => window.location.replace('http://www.iabeurope.eu/')}
                    >
                      Internet Advertising Bureau (EU)
                    </Typography>,
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  omitListType: true,
                  items: [
                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace(
                          'https://support.google.com/chrome/answer/95647?hl=en',
                        )
                      }
                    >
                      Google Chrome
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace('https://support.microsoft.com/en-us/kb/260971')
                      }
                    >
                      Internet Explorer
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace(
                          'https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer',
                        )
                      }
                    >
                      Mozilla Firefox
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace('https://support.apple.com/kb/PH5042?locale=en_US')
                      }
                    >
                      Safari (Desktop)
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace('https://support.apple.com/en-us/HT201265')
                      }
                    >
                      Safari (Mobile)
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace(
                          'https://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067',
                        )
                      }
                    >
                      Android Browser
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() => window.location.replace('https://www.opera.com/help')}
                    >
                      Opera
                    </Typography>,

                    <Typography
                      variant="publicBody5Regular"
                      color="common.black"
                      sx={{ ...fakeLinkStyles, textDecoration: 'underline' }}
                      onClick={() =>
                        window.location.replace('https://www.opera.com/help/mobile/android#privacy')
                      }
                    >
                      Opera Mobile
                    </Typography>,
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'For other browsers, please consult the documentation that your browser manufacturer provides.',
          },
        ],
      },
    ],
  },
];

export default cookiePolicy;
