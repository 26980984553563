import { useState, RefAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { ReactComponent as ClockIcon } from '../../assets/icons/project-icons/ClockIcon.svg';
import BaseTextInput, { TBaseTextInput } from './BaseTextInput';

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  minTime?: Date | null;
  maxTime?: Date | null;
  [x: string]: any;
};

interface ITimeProps<TDate> extends TimePickerProps<TDate> {
  slotProps: {
    textField: TBaseTextInput;
  };
}

type TTimePickerComponent = (<TDate>(
  props: ITimeProps<TDate> & RefAttributes<HTMLDivElement>,
) => JSX.Element) & { propTypes?: any };

const CustomTimePicker = TimePicker as TTimePickerComponent;

const ControlledTimeInput = ({ name, required, disabled, minTime, maxTime, ...props }: Props) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <CustomTimePicker
          {...field}
          open={open}
          minTime={minTime}
          maxTime={maxTime}
          onClose={() => setOpen(false)}
          value={field.value || null}
          slots={{
            textField: BaseTextInput,
          }}
          slotProps={{
            textField: {
              name,
              disabled,
              required,
              onKeyDown: (e) => e.preventDefault(),
              onIconClick: () => setOpen(true),
              icon: <ClockIcon />,
              ...props,
            },
          }}
        />
      )}
    />
  );
};

export default ControlledTimeInput;
