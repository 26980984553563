import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { Dispatch, SetStateAction, useState } from 'react';
import TableChartIcon from '@mui/icons-material/TableChart';

interface TableMenuButtonProps {
  editor: Editor;
}

const TableMenuButton = ({ editor }: TableMenuButtonProps) => {
  const MAX_ELEMENTS = 10;

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<number>(1);
  const [cols, setCols] = useState<number>(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInsert = () => {
    console.log('handle insert');
    if (rows > 0 && cols > 0) {
      editor.chain().focus().insertTable({ rows, cols, withHeaderRow: true }).run();
    }
  };

  const handleRowColInput = (value: number, stateSetter: Dispatch<SetStateAction<number>>) => {
    if (value > MAX_ELEMENTS) {
      stateSetter(MAX_ELEMENTS);
    } else if (value < 1) {
      stateSetter(1);
    } else {
      stateSetter(value);
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <Button
        onClick={handleClickOpen}
        component="span"
        size="small"
        disableElevation
        variant="text"
        aria-label="Embed"
        sx={{ minWidth: 44, color: '#384572', '&:hover': { backgroundColor: 'transparent', color: '#AA96F8' } }}
      >
        <TableChartIcon style={{ fontSize: '1.2rem' }} />
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Insert table</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a number of columns and rows (maximum of 10).
          </DialogContentText>
          <Stack
            direction="row"
            spacing={1}
            sx={{ mt: '1em' }}
          >
            <TextField
              error={!rows || rows < 1 || rows > MAX_ELEMENTS}
              id="no-rows"
              label="Rows"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                const value = Number(event.target.value);
                if (typeof value === 'number') {
                  handleRowColInput(value, setRows);
                }
              }}
              InputProps={{ inputProps: { min: 1, max: MAX_ELEMENTS } }}
            />
            <TextField
              error={!cols || cols < 1 || rows > MAX_ELEMENTS}
              id="no-columns"
              label="Columns"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                const value = Number(event.target.value);
                if (typeof value === 'number') {
                  handleRowColInput(value, setCols);
                }
              }}
              InputProps={{ inputProps: { min: 1, max: MAX_ELEMENTS } }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInsert}>Insert</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableMenuButton;
