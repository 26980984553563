import { Avatar, Stack, Typography } from '@mui/material';
import { ChatChannelType, CompanyRole } from 'tdc-web-backend/enums/enums';
import { RecentChannelDto } from 'tdc-web-backend/channels/schemas';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';
import { timeSince } from '../../../utils/helpers';

type RecentChannelProps = {
  recentChannel: RecentChannelDto;
  onClick: () => void;
};

export default ({ recentChannel, onClick }: RecentChannelProps) => {
  const isBuyer = useDetermineCompanyRoles()?.includes(CompanyRole.Buyer);
  const { project } = recentChannel.channel.space;
  const otherCompany = isBuyer ? project?.seller : project?.buyer;

  return (
    <Stack
      direction="row"
      p="10px"
      gap="10px"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'secondaryBlue.50',
        },
      }}
    >
      <Avatar src={otherCompany?.avatar} />
      <Stack flex="1">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="bodyMedium3" color="primaryDark.600">
            {recentChannel.channel?.type === ChatChannelType.Public
              ? `# ${recentChannel.channel.name}`
              : `${recentChannel.user.firstName} ${recentChannel.user.lastName}`}
          </Typography>
          <Typography variant="bodySemiBold4" color="primaryDark.400">
            {timeSince(recentChannel.created)} ago
          </Typography>
        </Stack>
        <Typography variant="body4" fontSize={11} color="primaryDark.500">
          {recentChannel.body}
        </Typography>
      </Stack>
    </Stack>
  );
};
