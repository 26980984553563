import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import theme from '../../theme';
import { concatinatedAllStatusesMap } from '../../utils/helpers';

export interface CircularProgressWithLabelProps<T> {
  displayScore: boolean;
  score?: number;
  truePercentage?: number;
  size?: number;
  thickness?: number;
  color?: string | undefined;
  entityStatus?: T;
}

// The CircularProgress with the value within the cirlce is not an actual
// MUI component, I followed Tihor's progress bar example and MUI documentation
const CircularProgressWithLabel = <T, >({
  displayScore,
  score,
  truePercentage,
  size = 12,
  thickness = 2,
  color = 'secondaryBlue.700',
  entityStatus,
}: CircularProgressWithLabelProps<T>) => {
  const [scorePercentage, setScorePercentage] = useState(100);

  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const [circularProgressSize, setCircularProgressSize] = useState(size);

  useEffect(() => {
    if (isLessThanLarge) {
      setCircularProgressSize(11);
    } else {
      setCircularProgressSize(12);
    }
  }, [isLessThanLarge]);

  const displayScoreToPercentage = (score: number) => {
    if (score > 5 || score < 1) {

    } else {
      const percentage = (score / 5) * 100;
      setScorePercentage(percentage);
    }
  };

  useEffect(() => {
    if (score) displayScoreToPercentage(score);
  }, [score]);

  useEffect(() => {
    if (truePercentage != null) {
      setScorePercentage(truePercentage ?? 0);
    }
  }, [truePercentage]);

  return (
    <Box sx={{ position: 'relative' }}>
      {/* To achieve the "unfilled" space of the progress bar to be
      grey, there is a dummy progress bar placed behind the main one */}
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress
          variant="determinate"
          sx={{
            color: 'primaryDark.500',
            position: 'absolute',
            '& .MuiCircularProgress-svg': {
              filter: 'drop-shadow(10px 10px 20px rgba(64, 81, 236, 0.2))',
            },
          }}
          size={`${circularProgressSize}rem`}
          thickness={thickness}
          value={100}
        />

        {/* inner circular progress */}
        <CircularProgress
          variant="determinate"
          sx={{
            position: 'absolute',
            padding: 1,
            color: '#e7e8f5',
          }}
          size="100%"
          thickness={3}
          value={100}
        />

        {/* actual circular progress that shows progress */}
        <CircularProgress
          variant="determinate"
          thickness={thickness}
          value={scorePercentage}
          size={`${circularProgressSize}rem`}
          sx={{
            color,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
        />
      </Stack>

      <Stack
        spacing={1.25}
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {
          displayScore === true
          && (
            <Typography
              color="primaryDark.500"
              variant="body2"
              fontWeight="600"
              fontSize={`${circularProgressSize * 0.15}rem`}
            >
              {(truePercentage != null) ? `${truePercentage}%` : score?.toFixed(1)}
            </Typography>
          )
        }

        {
          entityStatus && (
            <Typography variant="heading3" color="primaryDark.500" fontSize={`${circularProgressSize * 0.08}rem`}>
              {concatinatedAllStatusesMap.get(entityStatus)}
            </Typography>
          )
        }
      </Stack>
    </Box>
  );
};

export default CircularProgressWithLabel;
