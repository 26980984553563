import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import ControlledTitleInput from '../../../../components/custom-inputs/ControlledTitleInput';

const Title = ({ isLoading }: { isLoading: boolean }) => {
  const form = useFormContext();
  const hasNameError = form.formState.errors?.projectName;

  return (
    <Stack sx={{ pl: '24px', ml: '24px !important' }}>
      <ControlledTitleInput
        label="* Project name"
        name="projectName"
        placeholder="YOUR PROJECT NAME"
        required
        inputProps={{ maxLength: 100 }}
        disabled={isLoading}
      />

      {hasNameError && (
        <Typography
          color="secondaryPink.500"
          variant="body4"
          sx={{ alignSelf: 'start', display: 'block', margin: '12px 0' }}
        >
          This field is required
        </Typography>
      )}
    </Stack>
  );
};

export default Title;
