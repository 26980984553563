import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack/Stack';
import { CompanyRole, EnumCountry, mapCountry, mapProjectSize } from 'tdc-web-backend/enums/enums';
import { useEffect } from 'react';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import { mapStates } from '../../../../enums';
import { primaryDark, secondaryBlue } from '../../../../color';
import { getBase64 } from '../../../../utils/helpers';
import { ReactComponent as FileUploadSvg } from '../../../../assets/icons/project-icons/FileUploadSvg.svg';
import { TUserType } from '../interface';
import ControlledAutocompleteInput from '../../../../components/custom-inputs/ControlledAutocompleteInput';
import CustomInput from '../../../../components/custom-input/CustomInput';

const BORDER = `1px solid ${secondaryBlue[100]}`;

const Overview = () => {
  const { setValue, watch, setError, clearErrors, getValues, formState } = useFormContext();
  const userType: TUserType = watch('user');

  const onUpload = async (acceptedFiles: File[]) => {
    const file = acceptedFiles?.[0];
    if (!file) return;
    clearErrors('overview.avatar');

    const base64 = await getBase64(file);
    setValue('overview.avatar', base64);
  };

  // for handling scenario when you select USA, and then change to some else country
  useEffect(() => {
    if (getValues('overview.headquartersLocation.country') !== EnumCountry.Us) {
      // programatically set the state of state field to null
      // setValue('overview.headquartersLocation.state', null);
      setValue('overview.headquartersLocation.state', '');
    }
  }, [getValues('overview.headquartersLocation.country')]);

  useEffect(() => {
    if (getValues('overview.headquartersLocation.country') === EnumCountry.Us) {
      // programatically set error if usa is selected as country
      // it will trigger the required field so that the next button
      // cannot be clicked
      setError('overview.headquartersLocation.state', {
        message: 'This field is required',
        type: 'onChange',
      });
    }
  }, [getValues('overview.headquartersLocation.country')]);

  const hasAvatarError =
    formState.errors && 'overview' in formState.errors
      ? Object.keys(formState.errors.overview).includes('avatar')
      : null;

  return (
    <Stack>
      <BaseInputLabel text="company overview" />
      <Stack flexDirection="row" sx={{ margin: '0 0 32px 0' }}>
        <Stack spacing={3} flex={1} sx={{ padding: '0 24px 0 0', borderRight: BORDER }}>
          {userType === CompanyRole.SellerService && (
            <Stack alignItems="center" justifyContent="center">
              <ControlledDropzoneFileInput
                name="overview.avatar"
                onUpload={onUpload}
                required
                dropzoneProps={{
                  multiple: false,
                }}
                renderCustomDroppableAreaWidth={135}
                renderCustomDroppableAreaHeight={135}
                renderCustomDroppableArea={({ getRootProps, getInputProps }) => (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    {...getRootProps()}
                    spacing={1}
                    sx={{
                      width: 135,
                      height: 135,
                      borderRadius: '50%',
                      border: '2px dashed',
                      borderColor: hasAvatarError ? 'secondaryPink.500' : 'primaryDark.300',
                      '.MuiTypography-root': {
                        fontSize: '12px',
                      },
                      backgroundColor: hasAvatarError ? 'secondaryPink.100' : 'primaryDark.200',
                    }}
                  >
                    <input {...getInputProps()} />
                    <FileUploadSvg fill={primaryDark[400]} />
                    <Typography color="primaryDark.400" fontWeight="600">
                      ADD LOGO
                    </Typography>
                  </Stack>
                )}
              />

              {hasAvatarError && (
                <Typography
                  color="secondaryPink.500"
                  variant="body4"
                  sx={{ alignSelf: 'start', display: 'block', margin: '12px 0' }}
                >
                  This field is required
                </Typography>
              )}
            </Stack>
          )}

          <CustomInput
            input={<CustomInput.Text />}
            name="overview.name"
            choices={mapProjectSize}
            type="email"
            placeholder="Add company name"
            label="company name"
            required
          />

          <CustomInput
            input={<CustomInput.Text />}
            name="overview.url"
            choices={mapProjectSize}
            type="email"
            placeholder="Add company url"
            label="company website"
            required
          />
        </Stack>

        <Stack spacing={3} flex={1} sx={{ padding: '0 0 0 24px' }}>
          <BaseInputLabel
            text="headquarters location"
            sx={{
              padding: '0 0 12px 0',
              margin: '0 0 24px 0',
              borderBottom: BORDER,
              mb: '0px !important',
            }}
          />

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="overview.headquartersLocation.country"
            choices={mapCountry}
            placeholder="Select country"
            label="headquarters location"
            required
          />

          <CustomInput
            input={<CustomInput.Text />}
            name="overview.headquartersLocation.city"
            choices={mapCountry}
            placeholder="Add city"
            label="city"
            required
          />

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="overview.headquartersLocation.state"
            label="State"
            placeholder="Select"
            choices={mapStates}
            // error={¨}
            disabled={watch('overview.headquartersLocation.country') !== EnumCountry.Us}
            required={watch('overview.headquartersLocation.country') === EnumCountry.Us}
          />

          {/* <ControlledAutocompleteInput
            name="overview.headquartersLocation.state"
            label="State"
            placeholder="Select"
            choices={mapStates}
            // error={¨}
            disabled={watch('overview.headquartersLocation.country') !== EnumCountry.Us}
            required={watch('overview.headquartersLocation.country') === EnumCountry.Us}
          /> */}
        </Stack>
      </Stack>

      <ControlledTextEditor
        label="company description"
        name="overview.description"
        items={TimedProjectEditorMenu}
        required
      />
    </Stack>
  );
};

export default Overview;
