import { Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/button/CustomButton';

const VerifyEmailSection = () => {
  const navigate = useNavigate();

  return (
    <Stack width="100%" spacing={3}>
      <Typography
        variant="headline1"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        Verify your email
      </Typography>

      <Typography
        variant="body2"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        An email with a verification link has been sent to your account. Click on the link to
        complete the registration process.
      </Typography>

      <CustomButton
        sx={{
          maxWidth: 'fit-content',
          mt: 2,
          alignSelf: { xs: 'start', md: 'end' },
        }}
        onClick={() => navigate('/login')}
      >
        Continue
      </CustomButton>
    </Stack>
  );
};

export default VerifyEmailSection;
