import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { MilestoneDto, UpdateMilestoneDto } from 'tdc-web-backend/milestones/schemas';
import Button from '../../../../components/button/Button';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';

type CompleteModalProps = {
  data: MilestoneDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const CompletePaymentModal = ({ data, isOpen, setIsOpen }: CompleteModalProps) => {
  const { mutate: update } = useUpdate<MilestoneDto, UpdateMilestoneDto>({
    resource: '/milestones',
  });
  const refresh = useRefresh();

  const handleUpdate = () => {
    update(
      {
        data: {
          ...data,
          paid: new Date(),
        },
        id: data.id,
        suffix: 'mark-paid',
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack />
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Complete payment
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Are you sure you want to complete payment?
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onButtonClick={handleUpdate}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};

export default CompletePaymentModal;
