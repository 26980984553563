import { ChangeEvent } from 'react';
import { SubtaskDto, UpdateSubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import { CustomCheckbox } from '../../../../components/custom-checkbox/CustomCheckbox';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

export interface SubtasksTableCheckboxControllerProps {
  data : SubtaskDto;
  className?: string;
}

const SubtasksTableCheckboxController = ({ data, className }: SubtasksTableCheckboxControllerProps) => {
  const { mutate: update } = useUpdate<SubtaskDto, UpdateSubtaskDto>({ resource: '/subtasks' });
  const refresh = useRefresh();

  const handleCheck = (e : ChangeEvent<HTMLInputElement>) => {
    data.status = !data.status;
    update({
      data,
      id: data.id as string,
    }, {
      onSuccess: (data) => {
        refresh();
      },
    });
  };

  return (
    <CustomCheckbox
      className={className || undefined}
      checked={data.status}
      onClick={handleCheck}
    />
  );
};
export default SubtasksTableCheckboxController;
