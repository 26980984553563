type NavPageLinkType = {
  type: 'link' | 'subtitle';
  id: string;
  title: string;
  to: string;
};

export type NavPage =
  | {
      type: 'dropdown';
      id: string;
      title: string;
      links: NavPageLinkType[];
    }
  | {
      type: 'link';
      id: string;
      title: string;
      to: string;
    };

export const pages: NavPage[] = [
  {
    type: 'link',
    id: '100',
    title: 'Manage your project',
    to: '/how-to-manage-buyer',
  },
  {
    type: 'dropdown',
    id: '200',
    title: 'Marketplace',
    links: [
      { type: 'subtitle', id: '201', title: 'For Buyers', to: '' },
      { type: 'link', id: '202', title: 'Hire a seller', to: '/how-to-hire' },
      { type: 'subtitle', id: '203', title: 'For Sellers', to: '' },
      { type: 'link', id: '204', title: 'Find work', to: '/how-to-find-work' },
      { type: 'link', id: '205', title: 'Seller archive', to: '/seller-archive' },
      { type: 'link', id: '206', title: 'Case study archive', to: '/case-study-archive' },
    ],
  },
  {
    type: 'dropdown',
    id: '500',
    title: 'About Spona',
    links: [
      { type: 'link', id: '503', title: 'About us', to: '/about-us' },
      { type: 'link', id: '501', title: 'Spona Media', to: '/spona-media' },
      { type: 'link', id: '502', title: 'Work with us', to: '/work-with-us' },
    ],
  },
  {
    type: 'dropdown',
    id: '600',
    title: 'News & Updates',
    links: [{ type: 'link', id: '601', title: 'Blogs', to: '/blog-archive' }],
  },
];
