import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ExtendedFile } from './ExtendedFile';
import { ReactComponent as FileIcon } from '../../../assets/icons/chat-icons/file.svg';
import { iconUrlForFile } from '../../../utils/helpers';

type ChatInputAttachmentProps = {
  attachment: ExtendedFile;
};

export default ({ attachment }: ChatInputAttachmentProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      px: 1,
      minWidth: 56,
      minHeight: 56,
      borderRadius: '8px',
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: 'primaryDark.300',
      backgroundColor: 'primaryDark.200',
    }}
  >
    <Box height="40px" width="40px" sx={{ position: 'relative', display: 'inline-flex' }}>
      {attachment.attachmentId ? (
        <Box
          component="img"
          sx={{ width: 30, height: 40, objectFit: 'cover' }}
          src={iconUrlForFile(attachment.name)}
        />
      ) : (
        <>
          <Box sx={{ filter: 'grayscale(100%) brightness(130%)' }}>
            <FileIcon />
          </Box>
          <Box
            sx={{
              top: 2,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              size={15}
              variant="determinate"
              thickness={7}
              sx={{
                color: 'secondaryBlue.500',
                // circle background
                borderRadius: '50%',
                boxShadow: 'inset 0 0 0 2.3px #fff',
              }}
              value={attachment.progress * 100}
            />
          </Box>
        </>
      )}
    </Box>
    <Typography
      variant="bodySemiBold3"
      color="primaryDark.600"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {attachment.name}
    </Typography>
  </Box>
);
