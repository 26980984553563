import { useFormContext, useWatch } from 'react-hook-form';
import LinearProgressWithLabel from '../../../components/progress-bar/ProgressBar';

type Props = {
  index: number;
};

const ContractMilestoneBudgetProgress = ({ index }: Props) => {
  const { control } = useFormContext();

  const initialAmount = useWatch({
    name: 'initialAmount',
    control,
  });

  const budgetAmount = useWatch({
    name: `body.milestones.${index}.budget`,
    control,
  });

  return (
    <LinearProgressWithLabel
      value={!initialAmount ? 0 : Math.round((budgetAmount / initialAmount) * 100)}
    />
  );
};

export default ContractMilestoneBudgetProgress;
