import {
  IconButton, ListItemIcon, Menu, MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, MouseEvent } from 'react';
import { GridValueGetterParams } from '@mui/x-data-grid';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import SubtaskEditModal from '../Modals/SubtaskEditModal';
import SubtaskDeleteModal from '../Modals/SubtaskDeleteModal';
import useDetermineCompanyRoles from '../../../../utils/hooks/useDetermineCompanyRoles';
import FlagAndUnflagModal from '../../../../components/flag-modal-and-unflag-modal/FlagAndUnflagModal';
import EndToEndDetailScreenTypeEnum from '../../../../utils/enums/end-to-end-detail-screen-type';
import FlagTypeEnum from '../../../../utils/enums/flag-type';

interface SubtaskActionsMenuType {
  params: GridValueGetterParams;
}

// TODO: as in this component's parent is written,
// re-factor this list to be implemented the same way as all lists in end-to-end screens are
// and all of its children components
const SubtaskActionsMenu = ({ params }: SubtaskActionsMenuType) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const [
    currentlyActiveModalSubtaskId, setCurrentlyActiveModalSubtaskId,
  ] = useState<string | null>(null);

  const [isFlagAndUnflagModalOpen, setIsFlagAndUnflagModalOpen] = useState<boolean>(false);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleOpenFlagAndUnflagModal = (params: GridValueGetterParams) => {
    handleClose();

    setCurrentlyActiveModalSubtaskId(params.row.id);

    setIsFlagAndUnflagModalOpen(true);
  };

  return (
    <>
      <FlagAndUnflagModal
        entityId={currentlyActiveModalSubtaskId as string}
        entityType={EndToEndDetailScreenTypeEnum.Subtask}
        actionType={
          (() => {
            // if user is buyer
            if (isUserBuyer) {
              // if buyer flagged a milestone, action will be to unflag it
              if (params.row.buyerFlagged) return FlagTypeEnum.Unflag;

              // if buyer did not flag a milestone, action will be to flag it
              return FlagTypeEnum.Flag;
            }

            // if user is seller
            // if seller flagged a milestone, action will be to unflag it
            if (params.row.sellerFlagged) return FlagTypeEnum.Unflag;

            // if seller did not flag a milestone, action will be to flag it
            return FlagTypeEnum.Flag;
          })()
        }
        isModalOpen={isFlagAndUnflagModalOpen}
        setIsModalOpen={setIsFlagAndUnflagModalOpen}
      />

      <IconButton
        className="react-joyride-step-8-task"
        onClick={(event) => handleMenuOpen(event)}
      >
        <MoreVertIcon sx={{ fontSize: '1.55rem' }} />
      </IconButton>
      <Menu
        key={`menu-${params.row.id}`}
        id={`menu-${params.row.id}`}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={1}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'grey.50',
          },
        }}
      >
        <MenuItem onClick={() => handleOpenFlagAndUnflagModal(params)}>
          <ListItemIcon>
            <EmojiFlagsIcon />
          </ListItemIcon>
          {
            isUserBuyer
              ? (params.row.buyerFlagged ? 'Un-Flag subtask' : 'Flag subtask')
              : (params.row.sellerFlagged ? 'Un-Flag subtask' : 'Flag subtask')
          }
        </MenuItem>

        <SubtaskEditModal subtask={params.row} />
        <SubtaskDeleteModal subtask={params.row} />
      </Menu>
    </>
  );
};

export default SubtaskActionsMenu;
