import { IconButton, Stack, SxProps, Theme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import NavigationDashboardContext from '../../layout/dashboard-navigation/NavigationDashboardContext';
import { shadeColor } from '../../utils/helpers';
import theme from '../../theme';
import useDebounce from '../../utils/hooks/useDebounce';

export interface RenderCarouselItemProps<T> {
  item: T;
  sx?: SxProps<Theme> | undefined;
}

export interface CustomBackAndNextButtons {
  back: {
    handleGoBack: () => void;
    disabled: boolean;
  };
  next: {
    handleGoNext: () => void;
    disabled: boolean;
  };
}

export interface SingleSlideCarouselProps<T> {
  items: T[];
  activeIndexItem?: number;
  activeItemSx?: SxProps<Theme> | undefined;
  renderCarouselItem: ({ item, sx }: RenderCarouselItemProps<T>) => ReactNode;
  sx?: SxProps<Theme> | undefined;
  carouselItemWidth: number;
  renderCustomBackAndNextButtons?: ({ back, next }: CustomBackAndNextButtons) => ReactNode;
}

const DEBOUNCE_VALUE = 190;

const SingleSlideCarousel = <T,>({
  items,
  activeIndexItem,
  activeItemSx,
  renderCarouselItem,
  sx,
  carouselItemWidth,
  renderCustomBackAndNextButtons,
}: SingleSlideCarouselProps<T>) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const [carouselScrollAmount, setCarouselScrollAmount] = useState<number>(0);
  const [isCarouselFullyScrolled, setIsCarouselFullyScrolled] = useState<boolean>(false);

  const [canAllItemsFitInCarouselOffsetWidth, setCanAllItemsFitInCarouselOffsetWidth] =
    useState<boolean>(true);

  const currentNavigationDashboardWidth = useContext(NavigationDashboardContext);

  useEffect(() => {
    if (activeIndexItem !== undefined && carouselRef.current) {
      const carouselScrollAmount =
        activeIndexItem * (carouselRef.current.scrollWidth / items.length);

      setCarouselScrollAmount(carouselScrollAmount);

      if (carouselScrollAmount > 0) {
        carouselRef.current.scrollTo({ left: carouselScrollAmount, behavior: 'smooth' });
      }

      setIsCarouselFullyScrolled(
        carouselScrollAmount >= carouselRef.current.scrollWidth - carouselRef.current.offsetWidth,
      );

      setCanAllItemsFitInCarouselOffsetWidth(
        carouselRef.current.offsetWidth >= carouselItemWidth * items.length + 32,
      );
    }
  }, [carouselRef.current, activeIndexItem]);

  const handleGoBack = useDebounce(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        behavior: 'smooth',
        left: carouselRef.current.scrollLeft - carouselRef.current.scrollWidth / items.length,
      });

      // setTimeout is used here so that is delayed and ran after the scrollTo function
      // above; it prevents the bug of clicking back and disabling the back button
      // but the carousel not moving to the left at all
      setTimeout(() => {
        if (carouselRef.current) {
          setCarouselScrollAmount((prevValue: number) => {
            if (carouselRef.current) {
              return Math.floor(prevValue - carouselRef?.current?.scrollWidth / items.length);
            }

            return 0;
          });
        }
      }, DEBOUNCE_VALUE);

      setIsCarouselFullyScrolled(
        carouselRef.current.scrollLeft - carouselItemWidth >=
          carouselRef.current.scrollWidth - carouselRef.current.offsetWidth,
      );
    }
  }, DEBOUNCE_VALUE);

  const handleGoNext = useDebounce(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        behavior: 'smooth',
        left: carouselRef.current.scrollLeft + carouselRef.current.scrollWidth / items.length,
      });

      setCarouselScrollAmount(
        Math.floor(carouselRef.current.scrollLeft + carouselRef.current.scrollWidth / items.length),
      );

      setTimeout(() => {
        if (carouselRef.current) {
          setIsCarouselFullyScrolled(
            carouselScrollAmount + carouselItemWidth >=
              carouselRef.current.scrollWidth - carouselRef.current.offsetWidth,
          );
        }
      }, DEBOUNCE_VALUE);
    }
  }, DEBOUNCE_VALUE);

  return (
    <Stack
      ref={carouselRef}
      spacing={2}
      width={`calc(100vw - ${currentNavigationDashboardWidth}px - var(--scrollbar-width))`}
      flexWrap="nowrap"
      direction="row"
      sx={{
        overflowX: 'hidden',
        overflowY: 'visible',
        [`&>*:nth-child(${items.length + 1})`]: {
          marginRight: '16px',
        },
        ...sx,
      }}
    >
      {!renderCustomBackAndNextButtons && (
        <IconButton
          onClick={() => {
            handleGoBack();
          }}
          sx={{
            visibility: carouselScrollAmount > 0 ? 'visible' : 'hidden',
            zIndex: 4,
            position: 'absolute',
            left: 5,
            bottom: -7,
            backgroundColor: 'primaryDark.150',
            border: '1px solid',
            borderColor: 'primaryLight.100',
            width: '2.9rem',
            height: '2.9rem',
            display: canAllItemsFitInCarouselOffsetWidth ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: shadeColor(theme.palette.primaryDark[150], -5),
            },
          }}
        >
          <ChevronLeftIcon sx={{ color: 'primaryDark.600', fontSize: '2rem' }} />
        </IconButton>
      )}

      {items.map((item, index) =>
        renderCarouselItem({
          item,
          sx: { ...activeItemSx },
        }),
      )}

      {!renderCustomBackAndNextButtons && (
        <IconButton
          onClick={() => {
            handleGoNext();
          }}
          sx={{
            visibility: isCarouselFullyScrolled ? 'hidden' : 'visible',
            position: 'absolute',
            right: 15,
            bottom: -7,
            backgroundColor: 'primaryDark.150',
            border: '1px solid',
            borderColor: 'primaryLight.100',
            width: '2.9rem',
            height: '2.9rem',
            display: canAllItemsFitInCarouselOffsetWidth ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: shadeColor(theme.palette.primaryDark[150], -5),
            },
          }}
        >
          <ChevronRightIcon sx={{ color: 'primaryDark.600', fontSize: '2rem' }} />
        </IconButton>
      )}

      <Stack sx={{ position: 'absolute', right: 15, bottom: -10 }}>
        {!canAllItemsFitInCarouselOffsetWidth &&
          renderCustomBackAndNextButtons &&
          renderCustomBackAndNextButtons({
            back: {
              handleGoBack: () => handleGoBack(),
              disabled: !(carouselScrollAmount > 0),
            },
            next: {
              handleGoNext: () => handleGoNext(),
              disabled: isCarouselFullyScrolled,
            },
          })}
      </Stack>
    </Stack>
  );
};

export default SingleSlideCarousel;
