import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import { CreatePitchProps } from '../PitchingAdminCreate';

const SelectSellerAndTimedProjectStep = ({ type }: CreatePitchProps) => {
  const { timedProjectPitchId } = useParams();
  const { getValues, setValue } = useFormContext();

  const { data: sellerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=seller_tool&role=seller_service&status=approved',
  });

  const { data: timedProjectData } = useGetMany<CompanyDto>({
    resource: '/timed-projects',
  });

  const timedProjectsArr = timedProjectData?.data.results;

  useEffect(() => {
    if (type === 'create') {
      if (timedProjectsArr && getValues('timedProject') !== '') {
        const targetTimedProjectName = timedProjectsArr.find(
          (timedProject) => String(timedProject.id) === String(getValues('timedProject')),
        )?.name;

        setValue('timedProjectName', targetTimedProjectName);
      }
    }
  }, [type, timedProjectsArr, getValues('timedProject')]);

  return (
    <Stack spacing={4}>
      <ControlledSelectInput
        name="timedProject"
        label="Timed project"
        required
        disabled={!!timedProjectPitchId}
        choices={timedProjectsArr as any}
        placeholder="Select"
      />

      <ControlledSelectInput
        name="seller"
        label="Seller"
        required
        disabled={!!timedProjectPitchId}
        choices={sellerData?.data.results as any}
        placeholder="Select"
      />
    </Stack>
  );
};

export default SelectSellerAndTimedProjectStep;
