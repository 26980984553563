import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

const AdminEndToEnd = () => (
  <Box sx={{ width: '100%' }}>
    <Outlet />
  </Box>
);

export default AdminEndToEnd;
