import { Stack, Typography } from '@mui/material';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import Editor from '../../../../components/editor/Editor';
import { BlogEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import CustomInput from '../../../../components/custom-input/CustomInput';

type LeadTextProps = {
  viewMode?: boolean;
  value?: string;
};

const LeadText = ({ viewMode, value }: LeadTextProps) => (
  <>
    {viewMode ? (
      <Stack spacing={0}>
        <BaseInputLabel text="Lead text" />
        <Typography variant="body3" color="primaryDark.600" sx={{ lineHeight: '1.25rem', mt: 0 }}>
          {value}
        </Typography>
      </Stack>
    ) : (
      <CustomInput
        input={<CustomInput.Text />}
        name="leadText"
        type="text"
        placeholder="Enter lead text"
        label="Lead text"
        required
        sx={{ paddingBottom: '1.25rem' }}
        multiline
        rows={5}
      />
    )}
  </>
);
export default LeadText;
