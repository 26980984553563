import { SpaceDto } from 'tdc-web-backend/spaces/schemas';
import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { ChannelDto, CreateDirectChannelDto } from 'tdc-web-backend/channels/schemas';
import { CompanyRole, UserRoles } from 'tdc-web-backend/enums/enums';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';
import React from 'react';
import { ReactComponent as HashIcon } from '../../../assets/icons/chat-icons/hash.svg';
import { useChatNavigation } from '../useChatNavigation';
import CreateChannelModalButton from '../Modals/CreateChannelModalButton';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import axios from '../../../api/axios';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import { AxiosResponse } from 'axios';

export type ChannelListSpaceProps = {
  space: SpaceDto;
};

export const Space = ({ space }: ChannelListSpaceProps) => {
  const userData = useGetLocalStorageUserData();
  const chatNavigation = useChatNavigation();
  const isBuyer = useDetermineCompanyRoles()?.includes(CompanyRole.Buyer);
  const isSponaAdmin = useGetLocalStorageUserData()?.roles.includes(UserRoles.Admin);
  let partnerCompany;
  if (space.project) {
    partnerCompany = isBuyer ? space.project?.seller : space.project?.buyer;
  } else if (space.pitch) {
    partnerCompany = isBuyer ? space.pitch?.seller : space.pitch?.buyer;
  } else if (space.supportCompany && isSponaAdmin) {
    partnerCompany = space.supportCompany;
  }

  const { data: channelsData } = useGetMany<ChannelDto>({
    resource: `channels?space=${space.id}`,
  });
  const channels = channelsData?.data.results ?? [];

  const { data: usersData } = useGetMany<BaseUserDto>({
    resource: `users?space=${space.id}`,
  });
  const users = usersData?.data.results ?? [];

  const isSelected = channels.some(
    (channel: ChannelDto) => channel.id === chatNavigation.currentChannelId,
  );

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: isSelected ? 'secondaryBlue.50' : null,
      }}
    >
      <Stack padding={3} spacing={1} direction="column">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            px={3.5}
            variant="heading4"
            color={isSelected ? 'secondaryBlue.700' : 'primaryDark.600'}
          >
            {space.name}
          </Typography>
          {(space.pitch || space.project) && <CreateChannelModalButton spaceId={space.id} />}
        </Stack>
        {partnerCompany && (
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar
              variant="rounded"
              sx={{
                width: 20,
                height: 20,
              }}
              src={partnerCompany.avatar}
            />
            <Typography variant="bodyMedium3" color="primaryDark.500">
              {partnerCompany.name}
            </Typography>
          </Stack>
        )}
        <Stack direction="column">
          {channels.map((channel: ChannelDto) => (
            <Stack direction="row">
              <Button
                onClick={() => {
                  chatNavigation.navigateTo(channel.id);
                }}
                fullWidth
                sx={{
                  height: '40px',
                  px: 4.5,
                  backgroundColor:
                    channel.id === chatNavigation.currentChannelId
                      ? 'secondaryBlue.100'
                      : 'transparent',
                  borderRadius: '4px',
                  justifyContent: 'flex-start',
                }}
                startIcon={<HashIcon />}
              >
                <Typography
                  variant={
                    channel.id === chatNavigation.currentChannelId ? 'bodySemiBold3' : 'body3'
                  }
                >
                  {channel.name}
                </Typography>
              </Button>
            </Stack>
          ))}
        </Stack>
        <Stack direction="column">
          {users
            .filter((user) => user.id !== userData?.id)
            .map((user) => (
              <Stack direction="row">
                <Button
                  onClick={async () => {
                    if (!user.channel) {
                      const channel = await axios.post(`/channels/direct`, {
                        user: user.id,
                        space: space.id,
                      });

                      chatNavigation.navigateTo(channel.data.id);
                    } else {
                      chatNavigation.navigateTo(user.channel);
                    }
                  }}
                  fullWidth
                  sx={{
                    height: '40px',
                    px: 4.5,
                    backgroundColor:
                      user.channel === chatNavigation.currentChannelId
                        ? 'secondaryBlue.100'
                        : 'transparent',
                    borderRadius: '4px',
                    justifyContent: 'flex-start',
                  }}
                  startIcon={
                    <Avatar sx={{ width: '24px', height: '24px' }}>{user.firstName[0]}</Avatar>
                  }
                >
                  <Typography
                    variant={
                      user.channel === chatNavigation.currentChannelId ? 'bodySemiBold3' : 'body3'
                    }
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                </Button>
              </Stack>
            ))}
        </Stack>
      </Stack>
      <Divider color="secondaryBlue.100" />
    </Stack>
  );
};
