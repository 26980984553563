import { Editor } from '@tiptap/react';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { MenuItem, EditorCommand } from '../EditorCommand';

export interface MenuProps {
  editor: Editor | null;
  items: MenuItem[];
  uniqueKey?: string;
}

// eslint-disable-next-line react/display-name
export default (props: MenuProps) => {
  const { editor, items, uniqueKey } = props;

  if (!editor) {
    return null;
  }

  const command = new EditorCommand(editor);

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'flex-start',
      }}
    >
      {items.map((action) => {
        command.setAction(action);
        return (
          <Box key={command.getEditorItem().label} sx={{ m: 0 }}>
            {command.getMenuItemUI(editor, uniqueKey)}
          </Box>
        );
      })}
    </Paper>
  );
};
