import useWebSocketBase from 'react-use-websocket';

type UseNotificationsOptions = {
  handlers: Record<string, (payload: any) => void>;
};

export const useWebSocket = ({ handlers }: UseNotificationsOptions) => {
  let webSocketUrl = new URL(process.env.REACT_APP_GATEWAY_URL ?? '', window.location.href);
  webSocketUrl.protocol = webSocketUrl.protocol === 'https:' ? 'wss:' : 'ws:';
  webSocketUrl = new URL('/api/ws', webSocketUrl);
  useWebSocketBase(webSocketUrl.href, {
    share: true,
    shouldReconnect: () => true,
    onMessage: (message) => {
      const data = JSON.parse(message.data);
      handlers[data.type]?.(data.payload);
    },
  });
};

export default useWebSocket;
