import { Chip, ChipProps, SxProps, Theme, Tooltip } from '@mui/material';

export interface CustomChipI {
  sx?: SxProps<Theme> | undefined;
  styleVariant?: 'default' | 'rounded';
  tooltipTitle?: string;
}

const defaultStyles: SxProps<Theme> = {
  borderRadius: '6px',
  height: '34px',
  bgcolor: 'primaryDark.150',
  color: 'primaryDark.600',
  fontSize: 12,
  fontWeight: 600,
  fontStyle: 'normal',
  textTransform: 'uppercase',
  letterSpacing: '0.55px',
  lineHeight: '16px',
  '&:hover': {
    boxShadow: '6px 6px 15px 0px rgba(56, 69, 114, 0.10)',
  },
  '.MuiSvgIcon-root': {
    color: 'primaryDark.300',
    fontSize: 20,
  },
  '&.Mui-disabled': {
    bgcolor: 'primaryDark.200',
    color: 'primaryDark.400',
  },
};

const roundedStyles: SxProps<Theme> = {
  bgcolor: 'transparent',
  borderRadius: '999px',
  border: '1px solid',
  borderColor: 'primaryDark.300',
  height: '31px',
  padding: '5px 3px',
};

const CustomChip = (props: CustomChipI & ChipProps) => {
  const { sx, styleVariant = 'default', tooltipTitle, ...nativeProps } = props;

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        {...nativeProps}
        sx={{
          ...defaultStyles,
          ...(styleVariant === 'rounded' && roundedStyles),
          ...sx,
        }}
      />
    </Tooltip>
  );
};
export default CustomChip;
