import { useNavigate, useParams } from 'react-router-dom';

export const useChatNavigation = () => {
  const navigate = useNavigate();
  const currentChannelId = useParams().channelId;
  return {
    currentChannelId,
    navigateTo: (channelId: string) => navigate(`/dashboard/chat/${channelId}`),
  };
};
