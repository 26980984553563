import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const PaymentsDetailScreenJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    content: userBuyer ? (
      <Typography>
        View a graph of your past and upcoming payments and filter your data by your desired time period.

      </Typography>
    ) : (
      <Typography>

        View a graph of your past and upcoming earnings and filter your data by your desired time period.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-1-payments',
    title: 'Total balance',
  },
  {
    content: userBuyer ? (
      <Typography>

        Check your payment summary screen for an overview of all
        your payment statuses and payment amounts across projects and agencies.

      </Typography>
    ) : (
      <Typography>

        Check your payment summary screen for an overview of all
        your payment statuses and earnings across projects and agencies.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-2-payments',
    title: 'Payment summary',
  },
  {
    content: (
      <Typography>
        This visualization shows your overall spendings through
        your finalized and remaining payments across all projects.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-3-payments',
    title: 'Payment overview',
  },
  {
    content: userBuyer ? (
      <Typography>
        Get a breakdown of your agencies and project budgets.
        The project status will tell you if the project is still ongoing,
        in negotiations or completed.

      </Typography>
    ) : (
      <Typography>

        Get a breakdown of your clients and project budgets.
        The project status will tell you if the project is still ongoing,
        in negotiations or completed.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-4-payments',
    title: 'Payment overview',
  },
  {
    content: (
      <Typography>

        Navigate to the arrow button to access the individual payment screen
        for each of your projects. Get more payment details and manage your invoices.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-5-payments',
    title: 'Individual payment screen',
  },
];

export default PaymentsDetailScreenJoyrideSteps;
