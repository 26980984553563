import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface DynamicMultistepState {
  canGoNext: boolean;
  setCanGoNext: (value: boolean) => void;
  canSaveAsDraft?: boolean;
  setCanSaveAsDraft: (value: boolean) => void;
  canSubmit?: boolean;
  setCanSubmit?: (value: boolean) => void;
}

const useDynamicMultistepStore = create<DynamicMultistepState>(devtools((set) => ({
  canGoNext: false,
  setCanGoNext: (value: boolean) => set(() => ({ canGoNext: value })),
  canSaveAsDraft: false,
  setCanSaveAsDraft: (value: boolean) => set(() => ({ canSaveAsDraft: value })),
  canSubmit: false,
  setCanSubmit: (value: boolean) => set(() => ({ canSubmit: value })),
})));

export default useDynamicMultistepStore;
