import Stack from '@mui/material/Stack';
import ControlledTitleInput from '../../../../components/custom-inputs/ControlledTitleInput';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';

const Overview = () => (
  <Stack direction="column" spacing={5}>
    <ControlledTitleInput label="project name" name="overview.name" placeholder="Name" required />
    <ControlledTextEditor
      label="company description"
      name="overview.description"
      items={TimedProjectEditorMenu}
      required
    />
  </Stack>
);

export default Overview;
