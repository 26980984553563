import { TooltipRenderProps } from 'react-joyride';
import {
  Box, Button, Divider, IconButton, Paper, Stack, Typography,
} from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const tooltipBoxStyles = {
  boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
  border: '1px solid #FFFFFF',
  borderRadius: '1rem',
  p: '1.3rem',
  background: 'rgba(256, 256, 256, 0.25)',
};

const JoyrideTooltip = ({
  continuous,
  index,
  step,
  isLastStep,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => (
  <Box {...tooltipProps} sx={{ ...tooltipBoxStyles }}>
    <Paper sx={{ maxWidth: '27em', padding: '2em', borderRadius: '0.5rem' }}>
      <Stack spacing={2}>
        <Stack direction="row" sx={{ my: 'auto', justifyContent: 'space-between' }}>
          <Typography variant="heading3" sx={{ alignSelf: 'center', color: 'primaryDark.600' }}>
            {step.title?.toString().toUpperCase()}
          </Typography>
          <IconButton>
            <CancelSharpIcon
              sx={{ color: 'primaryDark.500' }}
              onClick={(e) => skipProps.onClick(e as any)}
            />
          </IconButton>
        </Stack>
        <Divider />
        <Typography sx={{ color: 'primaryDark.500' }} variant="body2">{step.content}</Typography>
        <Divider />
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'end' }}>
          {
            index === 0 ? (
              <Button
                {...skipProps}
                variant="outlined"
                sx={{
                  borderColor: 'primaryDark.300', borderRadius: '4px', width: '120px', height: '45px',
                }}
              >
                <Typography sx={{ color: 'primaryDark.600' }} variant="heading4">EXIT</Typography>
              </Button>
            ) : (
              <Button
                {...backProps}
                variant="outlined"
                sx={{
                  borderColor: 'primaryDark.300', borderRadius: '4px', width: '120px', height: '45px',
                }}
              >
                <Typography sx={{ color: 'primaryDark.600' }} variant="heading4">BACK</Typography>
              </Button>
            )
          }

          {continuous && (
            <Button
              sx={{
                backgroundColor: 'primaryLight.500', borderRadius: '4px', width: '120px', height: '45px',
              }}
              variant="contained"
              {...primaryProps}
              endIcon={
                !isLastStep && <ArrowRightAltIcon />
              }
            >
              <Typography sx={{ color: 'common.white' }} variant="heading4">
                {isLastStep ? 'DONE' : 'NEXT'}
              </Typography>
            </Button>
          )}
        </Stack>

      </Stack>
    </Paper>
  </Box>
);

export default JoyrideTooltip;
