import { Dispatch, SetStateAction } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Stack } from '@mui/system';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { primaryLight } from '../../../../../../../color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { SocialIconMapping } from '../components/CompanySocialMedia';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';

interface CompanySocialMediaEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
  socialIcons: SocialIconMapping;
}

const CompanySocialMediaEditModal = ({
  isEditModalOpen,

  setIsEditModalOpen,
  socialIcons,
}: CompanySocialMediaEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();
  const formMethods = useForm<Pick<CompanyDto, 'socialMediaLinks'>>({
    defaultValues: {
      socialMediaLinks: {
        medium: companyData?.socialMediaLinks?.medium ?? '',
        dribbble: companyData?.socialMediaLinks?.dribbble ?? '',
        facebook: companyData?.socialMediaLinks?.facebook ?? '',
        github: companyData?.socialMediaLinks?.github ?? '',
        instagram: companyData?.socialMediaLinks?.instagram ?? '',
        linkedin: companyData?.socialMediaLinks?.linkedin ?? '',
        twitter: companyData?.socialMediaLinks?.twitter ?? '',
      },
    },
    mode: 'onChange',
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Pick<CompanyDto, 'socialMediaLinks'>) => {
    update(
      { id: companyData?.id as string, data },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  const url = /^(http|https):\/\/[^ "]+$/;

  return (
    <ConfirmModal
      width="40%"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      title="Social Media"
      onCancel={() => setIsEditModalOpen(false)}
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      onConfirm={formMethods.handleSubmit(onSubmit)}
      confirmButtonText="Save"
      message={
        <FormProvider {...formMethods}>
          <Box component="form" sx={{ maxHeight: '398px', overflowY: 'auto' }}>
            {Object.entries(socialIcons).map(([platform, key]) => {
              const IconComponent = socialIcons[platform.toLowerCase()];
              return (
                <Stack
                  key={platform}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mr="10px"
                >
                  <CustomInput
                    input={<CustomInput.Text />}
                    placeholder="Type"
                    name={`socialMediaLinks.${platform}`}
                    label={platform as string}
                    sx={{ width: '100%', minHeight: 'none', mb: '10px' }}
                    adornment={{ icon: <IconComponent /> }}
                    defaultValue={key}
                    rules={{
                      pattern: {
                        value: url,
                        message: 'Invalid URL. Please include http:// or https://',
                      },
                    }}
                  />
                </Stack>
              );
            })}
          </Box>
        </FormProvider>
      }
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
    />
  );
};

export default CompanySocialMediaEditModal;
