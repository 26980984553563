import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { TaskDto, UpdateTaskDto } from 'tdc-web-backend/tasks/schemas';
import { useParams } from 'react-router-dom';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import Button from '../../../../components/button/Button';
import ControlledCheckboxInput from '../../../../components/custom-inputs/ControlledCheckboxInput';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import LoadingButton from '../../../../components/button/LoadingButton';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

export interface SubtasksModalProps {
  task: TaskDto;
}

const SubtaskAddModal = ({ task }: SubtasksModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { taskId } = useParams();
  const { mutate: update } = useUpdate<SubtaskDto, UpdateTaskDto>({ resource: '/tasks' });
  const refresh = useRefresh();

  const formMethods = useForm<UpdateTaskDto>({
    mode: 'onChange',
    defaultValues: {
      subtasks: [
        {
          status: false,
          name: '',
          end: new Date(),
        },
      ],
    },
  });

  const { control, reset } = formMethods;

  const { fields, append, remove } = useFieldArray<FieldValues>({
    control,
    name: 'subtasks',
  });

  const { handleSubmit, formState } = formMethods;

  const handleSubmitCreate = (data: FieldValues) => {
    update(
      { data, id: taskId as string },
      {
        onSuccess: (data) => {
          setIsOpen(false);
          reset({
            subtasks: [
              {
                status: false,
                name: '',
                end: new Date(),
              },
            ],
          });
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Button
        onButtonClick={() => setIsOpen(true)}
        startIcon={<AddIcon sx={{ fontSize: '1.45rem !important' }} />}
        fontWeight="500"
        setBorderRadius
        size="large"
        variant="outlined"
        className="react-joyride-step-6-task"
        color="secondary"
      >
        Add subtask
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSubmitCreate)}>
            <Stack
              sx={{
                width: '50%',
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Paper
                  sx={{
                    padding: '2em',
                  }}
                >
                  <Stack spacing={2}>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '1.25em',
                          fontWeight: 600,
                        }}
                      >
                        Add new task
                      </Typography>
                    </Stack>
                    <Stack>
                      {fields.map((field, index) => (
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                          }}
                        >
                          <ControlledCheckboxInput
                            name={`subtasks.${index}.status`}
                            label=""
                            sx={{
                              mt: 3,
                            }}
                            icon={<CheckCircleOutlineIcon sx={{ color: '#C5C7C3' }} />}
                            checkedIcon={<CheckCircleIcon sx={{ color: '#015732' }} />}
                          />
                          <ControlledTextInput
                            name={`subtasks.${index}.name`}
                            label="Name"
                            required
                          />
                          <ControlledDateInput
                            name={`subtasks.${index}.end`}
                            label="End date"
                            required
                          />
                          <IconButton
                            onClick={() => {
                              index !== 0 ? remove(index) : setIsOpen(false);
                            }}
                            disabled={index === 0}
                          >
                            <DeleteOutlineOutlinedIcon
                              sx={{
                                mt: 2,
                              }}
                            />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        onButtonClick={() => {
                          append({
                            status: false,
                            name: '',
                            end: new Date(),
                          });
                        }}
                        variant="outlined"
                        endIcon={<AddIcon />}
                        sx={{
                          mt: 2,
                          width: '12em',
                          border: 2,
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Add subtask
                      </Button>
                    </Stack>
                    <Divider />
                    <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                      <Button
                        variant="text"
                        onButtonClick={() => {
                          setIsOpen(false);
                          reset({
                            subtasks: [
                              {
                                status: false,
                                name: '',
                                end: new Date(),
                              },
                            ],
                          });
                        }}
                        sx={{
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="contained"
                        onButtonClick={() => null}
                        type="submit"
                        disabled={!formState.isValid}
                        sx={{
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Confirm
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Paper>
              </LocalizationProvider>
            </Stack>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
export default SubtaskAddModal;
