// Disabled rule for destructuring here since it would make the file less readable
/* eslint-disable prefer-destructuring */
/* eslint no-param-reassign: ["error", { "props": false }] */
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import {
  AwsUploadData,
  FileDataWithUploadData,
  FilesUrlData,
  ImagesUrlData,
  VideoDataWithUploadData,
} from './types';

/**
 * Helper function for file upload that enables us to dynamically update and set the `ImagesUrlData`
 * object in the `imageDataState` of the parent component using the `setImageDataState` prop
 * This object practically fully represents the backend structure for uploaded images and simplifies
 * the construction of image data inside the parent DB object. For more information check the
 * `ImagesUrlData` object itself.
 * @param fileData Array containing file data that has to be added to the image state
 * This addition will be emitted using the `setImageDataState` setter. Keep in mind that
 * for components that update just a single file (`Avatar`, `Banner`, `Logo` and possibly
 * others), you have to initialize a new array with this single element so we have a cleaner,
 * more reusable implementation
 * @param resourceType Resource type of the resource that has to be added to the state
 * Currently limited to just one resource type as one file upload component should only
 * ever deal with one `ResourceType` at a time in the current implementation.
 * @param imageDataState Image state of the parent component calling this function. Passing
 * it into this function allows us to load the preexisting state so we don't void everything
 * that is not touched on update.
 * @param setImageDataState Setter for the image state of the parent component calling this
 * function. Passing it into this function enables us to reuse this function for any component
 * containing an upload component
 */
export const handleImageFileDataChange = (
  fileData: Array<FileDataWithUploadData>,
  // resourceType: ResourceType,
  resourceType: any,
  imageDataState: ImagesUrlData,
  setImageDataState: Dispatch<SetStateAction<ImagesUrlData>>,
) => {
  const images = new ImagesUrlData(imageDataState);

  // switch (resourceType) {
  //   case ResourceType.Banner:
  //     images.banner = fileData[0];
  //     break;
  //   case ResourceType.Logo:
  //     images.logo = fileData[0];
  //     break;
  //   case ResourceType.Profile:
  //     images.profile = fileData[0];
  //     break;
  //   case ResourceType.Carousel:
  //     images.carousel = fileData;
  //     break;
  //   default:
  //     break;
  // }

  setImageDataState(images);
};

/**
 * Helper function for file upload that enables us to dynamically update and set the `FilesUrlData`
 * object in the `fileDataState` of the parent component using the `setFileDataState` prop
 * This object practically fully represents the backend structure for uploaded files and simplifies
 * the construction of file data inside the parent DB object. For more information check the
 * `FilesUrlData` object itself.
 * @param fileData Array containing file data that has to be added to the file state
 * This addition will be emitted using the `setFileDataState` setter.
 * @param resourceType Resource type of the resource that has to be added to the state
 * Currently limited to just one resource type as one file upload component should only
 * ever deal with one `ResourceType` at a time in the current implementation.
 * @param fileDataState File state of the parent component calling this function. Passing
 * it into this function allows us to load the preexisting state so we don't void everything
 * that is not touched on update.
 * @param setFileDataState Setter for the file state of the parent component calling this
 * function. Passing it into this function enables us to reuse this function for any component
 * containing an upload component
 */
export const handleAttachmentFileDataChange = (
  fileData: Array<FileDataWithUploadData | VideoDataWithUploadData>,
  // resourceType: ResourceType,
  resourceType: any,
  fileDataState: FilesUrlData,
  setFileDataState: Dispatch<SetStateAction<FilesUrlData>>,
) => {
  const files = new FilesUrlData(fileDataState);

  // switch (resourceType) {
  //   case ResourceType.Attachment:
  //     files.attachments = fileData;
  //     break;
  //   case ResourceType.BuyerAttachment:
  //     files.buyerAttachments = fileData;
  //     break;
  //   case ResourceType.SellerAttachment:
  //     files.sellerAttachments = fileData;
  //     break;
  //   case ResourceType.BuyerPaymentInvoice:
  //     files.buyerPaymentInvoice = fileData;
  //     break;
  //   case ResourceType.SellerPaymentInvoice:
  //     files.sellerPaymentInvoice = fileData;
  //     break;
  //   default:
  //     break;
  // }

  setFileDataState(files);
};

/**
 * Helper function that extracts upload data for files that are
 * to be uploaded from the passed `Array<FileDataWithUploadData>` object. The discriminator in
 * this case is the `toUpload` prop that is appended to the newly uploaded files by file upload
 * components at the moment of presigning the future Amazon Web Services upload.
 * The resulting array contains only the `AwsUploadData` that can be used to upload files.
 * @param data Array containing the data with both files to be uploaded and those that were
 * previously uploaded
 * @returns Array containing `AwsUploadData` needed to upload them to the cloud
 */
const extractArrayFilesToUpload = (
  data: Array<FileDataWithUploadData | VideoDataWithUploadData>,
): Array<AwsUploadData> => {
  const toUpload: Array<AwsUploadData> = data.reduce<Array<AwsUploadData>>((a, c) => {
    const file: FileDataWithUploadData | VideoDataWithUploadData = c;

    if (!file.toUpload) {
      return a;
    }
    a.push(
      new AwsUploadData(
        file.toUpload.presignedUrl,
        file.toUpload.file,
        file.toUpload.resourceUrl,
        file.toUpload.resourceType,
      ),
    );
    delete file.toUpload;
    return a;
  }, []);

  return toUpload;
};

/**
 * Extracts all the images that have to be uploaded from the `ImagesUrlData` object that is passed
 * as a parameter. This is done in a non-destructive manner (original `ImagesUrlData` object is not
 * mutated or changed in any way). File data is extracted from all the possible different types of
 * images.
 * @param images `ImagesUrlData` object representing the DB structure that is utilized for storing
 * of images.
 * @returns Array of `AwsUploadData` objects containing all the data neccessary to
 * upload new images.
 */
export const getImageFilesToUpload = (
  images: ImagesUrlData,
) => {
  let filesToUpload: Array<AwsUploadData> = new Array<AwsUploadData>();

  if (images.carousel && images.carousel.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(images.carousel);
    filesToUpload = filesToUpload.concat(toUpload);
  } else {
    let uploadData: AwsUploadData | null = null;

    if (images.banner && images.banner.toUpload) {
      uploadData = images.banner.toUpload;
      delete images.banner.toUpload;
    }

    if (images.logo && images.logo.toUpload) {
      uploadData = images.logo.toUpload;
      delete images.logo.toUpload;
    }

    if (images.profile && images.profile.toUpload) {
      uploadData = images.profile.toUpload;
      delete images.profile.toUpload;
    }

    if (uploadData) filesToUpload.push(uploadData);
  }

  return filesToUpload;
};

/**
 * Extracts all the files that have to be uploaded from the `FilesUrlData` object that is passed
 * as a parameter. This is done in a non-destructive manner (original `FilesUrlData` object is not
 * mutated or changed in any way). File data is extracted from all the possible different types of
 * files.
 * @param files `FilesUrlData` object representing the DB structure that is utilized for storing
 * of files.
 * @returns Array of `AwsUploadData` objects containing all the data neccessary to
 * upload new images.
 */
export const getAttachmentFilesToUpload = (
  files: FilesUrlData,
) => {
  let filesToUpload: Array<AwsUploadData> = new Array<AwsUploadData>();

  if (files.attachments && files.attachments.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(files.attachments);
    filesToUpload = filesToUpload.concat(toUpload);
  }

  if (files.buyerAttachments && files.buyerAttachments.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(files.buyerAttachments);
    filesToUpload = filesToUpload.concat(toUpload);
  }

  if (files.sellerAttachments && files.sellerAttachments.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(files.sellerAttachments);
    filesToUpload = filesToUpload.concat(toUpload);
  }

  if (files.buyerPaymentInvoice && files.buyerPaymentInvoice.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(files.buyerPaymentInvoice);
    filesToUpload = filesToUpload.concat(toUpload);
  }

  if (files.sellerPaymentInvoice && files.sellerPaymentInvoice.length > 0) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(files.sellerPaymentInvoice);
    filesToUpload = filesToUpload.concat(toUpload);
  }

  if (files.videoPitch) {
    const toUpload: Array<AwsUploadData> = extractArrayFilesToUpload(new Array(files.videoPitch));
    filesToUpload = filesToUpload.concat(toUpload);
  }

  return filesToUpload;
};

/**
 * Helper function enabling the uploading of files to the provided URL
 * @param presignedUrl Presigned URL of provided by the AWS for the upload
 * @param file File to be uploaded
 * @returns `AxiosPromise` containing the upload confirmation
 */
export const uploadFile = (presignedUrl: string, file: File) => axios({
  method: 'put',
  url: presignedUrl,
  data: file,
  headers: {
    'Content-Type': file.name.includes('svg') ? 'image/svg+xml' : 'Image',
  },
});
