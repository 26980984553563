import { Stack, Typography, Box } from '@mui/material';
import theme from '../../../../theme';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import './custom-alice-carousel-overrides.scss';
import CustomAliceCarousel from '../../../../components/custom-alice-carousel/CustomAliceCarousel';
import CustomButton from '../../../../components/button/CustomButton';
import { getPublicImageUrl } from '../../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../../utils/enums/public-page-images';

const imageDetails = [
  {
    src: getPublicImageUrl(EnumPublicPagesImage.People1),
    alt: 'Two colleagues casually chatting on sofas.',
  },
  {
    src: getPublicImageUrl(EnumPublicPagesImage.People2),
    alt: 'A person standing in front of the multi-colored background.',
  },
  {
    src: getPublicImageUrl(EnumPublicPagesImage.People3),
    alt: 'Two colleagues working together on their computers',
  },
];

const images: JSX.Element[] = imageDetails?.map((img) => (
  <Box
    component="img"
    alt={img.alt}
    src={img.src}
    width="100%"
    height="100%"
    sx={{ pr: { xs: 0, lg: 4 } }}
  />
));

const responsive = {
  [theme.breakpoints.values.xs]: { items: 1 },
  [theme.breakpoints.values.sm]: { items: 2 },
  [theme.breakpoints.values.md]: { items: 3 },
};

const OurPeople = () => (
  <Stack
    pt={8}
    pb={1}
    sx={{
      backgroundColor: 'primaryDark.200',
      position: 'relative',
    }}
  >
    <Stack
      spacing={{ xs: 4 }}
      direction={{ xs: 'column' }}
      alignItems="center"
      justifyContent="start"
      sx={{ ...LAYOUT_DEFAULTS, position: 'relative' }}
    >
      <Typography
        sx={{
          width: '100%',
          typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
          pb: { xs: 0, md: 1, lg: 2 },
        }}
      >
        Our People
      </Typography>

      <Box className="ourPeopleCarousel" sx={{ width: '100%' }}>
        <CustomAliceCarousel
          responsive={responsive}
          items={images}
          buttonStyles={{
            display: { xs: 'none', md: 'block' },
            top: { xs: '15%', sm: '38%', lg: '43%' },
            transform: 'translateY(-50%)',
            right: { xs: '-4%', md: '-1%', lg: '2%', xxl: '-15%' },
          }}
        />
      </Box>

      <Stack spacing={{ xs: 4, lg: 4 }} sx={{ maxWidth: { xs: '100%', lg: '75%' } }}>
        <Typography variant="publicBody5Regular" color="secondaryGray.600">
          We’ve been fostering know-how and creating tools that facilitate digital projects since
          2018. We create lasting bonds between businesses and companies that offer digital
          services, changing the way people cooperate for the better - all this while cultivating a
          positive work environment in which each member of the team has their own distinct voice
          and impact.
        </Typography>

        {/* TODO: pass href to the new page where it needs to navigate once it gets done */}
        <CustomButton
          variant="primary"
          sx={{
            opacity: 0,
            pointerEvents: 'none',
            cursor: 'initial',
            width: { xs: '100%', md: '20%', lg: '17%' },
          }}
        >
          View open positions
        </CustomButton>
      </Stack>
    </Stack>
  </Stack>
);
export default OurPeople;
