import { Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import ReusableHeroSection from '../../../components/public-screen-components/ReusableHeroSection';
import HowDoesItWork from '../../../components/public-screen-components/HowDoesItWork';
import SponaOptions from '../../../components/public-screen-components/SponaOptions';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../components/seo/MetaTags';
import CustomModal from '../../../components/modal/CustomModal';
import CustomButton from '../../../components/button/CustomButton';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

const metaTags = {
  description: `Learn how Spona finds your dream partners to outsource your project to.`,
};

const SponaConnectSubpage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const isScreenHeightMoreThan1200 = useMediaQuery('(min-height:1200px)');
  const isScreenWidthMoreThan600 = useMediaQuery('(min-width:600px)');
  const isScreenWidthMoreThan1490 = useMediaQuery('(min-width:1490px)');

  const CalendlyModal = ({ openModal }: { openModal: boolean }) => (
    <CustomModal
      open={openModal}
      width="auto"
      paperStyleProps={{ p: { xs: 0, lg: '2rem' } }}
      sx={{ alignItems: 'end' }}
      onClose={() => setOpenModal(false)}
    >
      <>
        <InlineWidget
          styles={{
            height: isScreenHeightMoreThan1200 ? '600px' : '480px',
            width: isScreenWidthMoreThan1490
              ? '490px'
              : isScreenWidthMoreThan600
              ? '380px'
              : '310px',
          }}
          url={process.env.REACT_APP_CALENDLY_URL as string}
        />
        {showButton && (
          <CustomButton
            onClick={() => {
              setOpenModal(false);
              setShowButton(false);
            }}
          >
            Close
          </CustomButton>
        )}
      </>
    </CustomModal>
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setShowButton(true);
    },
  });
  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        {openModal && <CalendlyModal openModal={openModal} />}
        <ReusableHeroSection
          image={getPublicImageUrl(EnumPublicPagesImage.LetSponaHandleItHero)}
          title="Let Spona Handle It"
          content="Are you looking for digital service sellers with highly specialized expertise, or simply having trouble finding the time to make a fully informed choice yourself? Spona has you covered with Spona Connect, our hands-off option to hiring the seller that best suits your needs. Get in touch with a member of our team to start working on your project today."
          sx={{ height: { xs: `calc(85vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
          alt="Person sitting on a couch with coffee mug, looking at his computer"
        />

        <HowDoesItWork
          sx={{ mt: { xs: '-86px', lg: '110px' } }}
          items={[
            {
              title: 'List your requirements',
              content:
                'Schedule a meeting with a Spona advisor and define your project requirements, including goals, budget, schedule and desired expertise. We’ll take it from there: let us browse our community of thousands of digital service sellers and shortlist the best candidates for your project.',
              image: getPublicImageUrl(EnumPublicPagesImage.ListYourRequirements),
              color: 'secondaryBlue.50',
              alt: 'Illustration of the calendar on Spona',
            },
            {
              title: 'Review your candidates',
              content:
                'Your recruiter will provide you with a list of your top options. Members of the Spona community are vetted through their seller profiles and client-reviewed and scored case studies. Once you’ve made your choice, you’re ready to start working on your next big project.',
              image: getPublicImageUrl(EnumPublicPagesImage.ReviewYourCandidates),
              color: 'secondaryBlue.50',
              alt: 'Illustration of user profile components on Spona',
            },
            {
              title: 'Save your time and money',
              content:
                'Your recruiter will provide you with a list of your top options. Members of the Spona community are vetted through their seller profiles and client-reviewed and scored case studies. Once you’ve made your choice, you’re ready to start working on your next big project. ',
              image: getPublicImageUrl(EnumPublicPagesImage.SaveYourTimeAndMoney),
              color: 'secondaryBlue.50',
              alt: 'Illustration of the dashboard on Spona',
            },
          ]}
        />

        <SponaOptions
          title="Spona Makes Your Business…"
          optionsProps={[
            {
              title: 'Smarter',
              content:
                'Our advisors are up to date with the latest trends in the digital business market and will be able to efficiently find your best picks. Not only that, but their expertise with helping other sellers will ensure that they can pinpoint what your business needs.',
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesSubpage),
              alt: 'Image of a desk with a computer on it showing graphs and charts',
            },
            {
              title: 'Simpler',
              content:
                'Find a solution in a matter of days by significantly shortening the recruitment process. No need to handle the paperwork, research and filtering through dozens of pitches yourself - you can leave the heavy lifting to Spona.',
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesSubpage),
              alt: 'Image of a desk with a computer on it showing graphs and charts',
            },
            {
              title: 'Streamlined',
              content:
                'Reduce complications by selecting one capable person to do the job. Our advisors are experts in their field and will be able to answer your needs without gathering feedback from multiple sources and delaying the process.',
              image: getPublicImageUrl(EnumPublicPagesImage.SponaMakesSubpage),
              alt: 'Image of a desk with a computer on it showing graphs and charts',
            },
          ]}
        />

        <BecomeAPartOfSpona
          title="Find your Spona advisor"
          subtitle="Get in touch with us to get started with your project journey on Spona."
          bgcolor="secondaryBlue.101"
          color="common.black"
          onButtonClick={() => setOpenModal(true)}
        />
      </Stack>
    </>
  );
};
export default SponaConnectSubpage;
