import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Avatar,
  Typography,
} from '@mui/material';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';

type TablePanelGrid = {
  rows: CompanyClientDto[];
}

const CompanyClientsList = ({
  rows,
}: TablePanelGrid) => (
  <DataGrid
    columns={columns}
    rows={rows}
    getRowId={(e) => e.id}
    disableSelectionOnClick
    hideFooter
    sx={dataGridStyles}
  />
);

export default CompanyClientsList;

const columns: GridColDef[] = [
  {
    field: 'avatar',
    headerName: ' ',
    editable: false,
    flex: 0.2,
    renderCell: (params: GridValueGetterParams) => (
      <Avatar alt="Avatar" src={params.value} />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    flex: 0.8,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{
        fontSize: '0.875rem',
        fontWeight: 600,
      }}
      >
        {params.row.name}
      </Typography>
    ),
  },
];

const dataGridStyles = {
  height: '400px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  '.MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Poppins, sans-serif',
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#FBFDF8',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: '600',
  },
};
