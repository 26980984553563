import { Stack } from '@mui/system';
import { secondaryBlue } from '../../../../../color';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import CustomButton from '../../../../../components/button/CustomButton';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { RejectFirstStepType } from '../../interface';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

const RejectFirstStep = ({ setSliderPage, setIsOpen, pitchId }: RejectFirstStepType) => {
  const { mutate: reject } = useUpdate({
    resource: '/timed-project-pitches',
  });

  const refresh = useRefresh();

  const handleReject = () => {
    reject(
      {
        id: pitchId,
        suffix: 'reject-pitch',
      },
      {
        onSuccess: () => {
          refresh();
          setSliderPage(1);
        },
      },
    );
  };

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" mb={3}>
        <Stack
          width={40}
          height={40}
          mr={2}
          bgcolor="secondaryBlue.100"
          borderRadius={1}
          justifyContent="center"
          alignItems="center"
        >
          <BriefcaseCheckIcon width="20px" fill={secondaryBlue[700]} />
        </Stack>
        <Typography variant="heading4" color="primaryDark.600">
          reject pitch
        </Typography>
      </Stack>
      <Stack
        py={2}
        sx={{
          borderTop: `1px solid ${secondaryBlue[100]}`,
          borderBottom: `1px solid ${secondaryBlue[100]}`,
        }}
      >
        <Typography color="primaryDark.500" variant="body2">
          By proceeding, you will be rejecting the pitch for the second round of negotations. The
          seller will be notified immediately.
        </Typography>
      </Stack>
      <Stack gap={0.625} direction="row" justifyContent="center" my={3}>
        <FiberManualRecord fontSize="small" sx={{ color: 'secondaryBlue.500' }} />
        <FiberManualRecord fontSize="small" sx={{ color: '#C5C7C3' }} />
      </Stack>
      <Stack direction="row" gap={3} justifyContent="flex-end">
        <CustomButton onClick={() => setIsOpen(false)} variant="secondary">
          Cancel
        </CustomButton>
        <CustomButton onClick={handleReject} variant="primary">
          Confirm
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default RejectFirstStep;
