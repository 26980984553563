import { Stack } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../../layout/utils';
import LogoSection from '../LogoSection';
import PricingFaq from '../PricingFaq';
import GetDemoSection from '../GetDemoSection/GetDemoSection';
import PricingCard from '../PricingCard';
import SellerBasicPlan from './components/SellerBasicPlan';
import SellerStandardPlan from './components/SellerStandardPlan';
import SellerPremiumPlan from './components/SellerPremiumPlan';

const SellerPricingContent = () => (
  <>
    <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
      <Stack
        direction={{ lg: 'row' }}
        alignItems="center"
        width="100%"
        minHeight="100%"
        maxWidth="1310px"
        my={8}
        gap={{ xs: 5, lg: 0 }}
      >
        <PricingCard
          purple
          sx={{
            mr: { lg: '-32px' },
            width: { xs: '100%', lg: '50%' },
            maxWidth: '500px',
          }}
        >
          <SellerBasicPlan />
        </PricingCard>

        <PricingCard
          sx={{
            width: { xs: '100%', lg: '50%' },
            maxWidth: '500px',
            zIndex: 4,
          }}
        >
          <SellerStandardPlan />
        </PricingCard>

        <PricingCard
          purple
          sx={{
            ml: { lg: '-32px' },
            width: { xs: '100%', lg: '50%' },
            maxWidth: '500px',
          }}
        >
          <SellerPremiumPlan />
        </PricingCard>
      </Stack>
      <LogoSection />
      <PricingFaq />
    </Stack>
    <GetDemoSection type="manage" />
  </>
);
export default SellerPricingContent;
