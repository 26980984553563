import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyApprovalStatus, CompanyRole } from 'tdc-web-backend/enums/enums';
import Main from './Main';
import GoToBiggerScreenModal from '../../components/go-to-bigger-screen-modal/GoToBiggerScreenModal';
import useGetLocalStorageUserData from '../../utils/hooks/useGetLocalStorageUserData';
import useReferenceOne from '../../utils/hooks/crud-hooks/useReferenceOne';
import useDetermineCompanyRoles from '../../utils/hooks/useDetermineCompanyRoles';
import { Tune } from '@mui/icons-material';

const Dashboard = ({
  showSidebar = true,
  showChat,
  padding = true,
  bgColor = '#FFF',
}: {
  showSidebar?: boolean;
  showChat?: boolean;
  bgColor?: string;
  padding?: boolean;
}) => {
  const userData = useGetLocalStorageUserData();
  const navigate = useNavigate();

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);
  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData && !!userData.membership?.company,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const disablelayout =
    companyData?.status === CompanyApprovalStatus.Pending ||
    companyData?.status === CompanyApprovalStatus.Rejected;

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        minHeight: '100vh',
        pointerEvents: disablelayout && !isUserBuyer ? 'none' : 'inherit',
      }}
    >
      <GoToBiggerScreenModal onClick={() => navigate('/', { replace: true })} />
      <Main showSidebar={showSidebar} showChat={showChat} padding={padding} outlet={<Outlet />} />
    </Box>
  );
};

export default Dashboard;
