import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import {
  mapCountry,
  mapIndustry,
  mapLanguage,
  mapService,
  mapTechLanguage,
} from 'tdc-web-backend/enums/enums';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import { mapTechPlatform, mapTechTool } from '../../../../enums';

const accordionSummaryStyles = {
  px: 0,
  '& .MuiAccordionSummary-content': { my: 0, minHeight: 'unset' },
  '& .MuiAccordionSummary-content.Mui-expanded': { my: 0, minHeight: 'unset' },
};

type AccordionPropertyEnum = {
  type: 'enum';
  name: string;
  map: Map<any, any>;
};

type AccordionPropertyString = {
  type: 'string';
  name: string;
};

type AccordionPropertyLocations = {
  type: 'locations';
  name: string;
  map: Map<any, any>;
};

type AccordionPropertyHqLocation = {
  type: 'hq_loaction';
  name: string;
  map: Map<any, any>;
};

type AccordionElement = {
  title: string;
  properties: (
    | AccordionPropertyEnum
    | AccordionPropertyString
    | AccordionPropertyHqLocation
    | AccordionPropertyLocations
  )[];
};

const accordians: AccordionElement[] = [
  {
    title: 'industry focus',
    properties: [{ name: 'targetIndustries', map: mapIndustry, type: 'enum' }],
  },
  {
    title: 'services',
    properties: [{ name: 'services', map: mapService, type: 'enum' }],
  },
  {
    title: 'programing languages',
    properties: [{ name: 'techLanguages', map: mapTechLanguage, type: 'enum' }],
  },
  {
    title: 'tech tools',
    properties: [{ name: 'techTools', map: mapTechTool, type: 'enum' }],
  },
  {
    title: 'tech platforms',
    properties: [{ name: 'techPlatforms', map: mapTechPlatform, type: 'enum' }],
  },
  {
    title: 'target markets',
    properties: [{ name: 'targetCountries', map: mapCountry, type: 'enum' }],
  },
  {
    title: 'languages',
    properties: [{ name: 'languages', map: mapLanguage, type: 'enum' }],
  },
  {
    title: 'office locations',
    properties: [
      { name: 'headquartersLocation', map: mapCountry, type: 'hq_loaction' },
      { name: 'officeLocations', map: mapCountry, type: 'locations' },
    ],
  },
  {
    title: 'certifications',
    properties: [{ name: 'certifications', type: 'string' }],
  },
  {
    title: 'awards',
    properties: [{ name: 'awards', type: 'string' }],
  },
];

const CompanyProfileAccordionInfo = ({
  company,
  sx,
}: {
  company: CompanyDto | undefined;
  sx?: SxProps<Theme>;
}) => (
  <Stack
    spacing={1}
    sx={{
      ...sx,
    }}
    divider={<Divider flexItem sx={{ borderColor: 'secondaryBlue.100' }} />}
  >
    {company &&
      accordians.map((accordian) => {
        const labelsLabels = accordian.properties.map((property) => {
          const content = company[property.name as keyof CompanyDto];

          if (property.type === 'enum') {
            return content.map((value: string) => property.map.get(value));
          }
          if (property.type === 'hq_loaction') {
            return content
              ? [
                  `${content.city}, ${
                    content.state !== '' ? `${content.state}, ` : ''
                  }${property.map.get(content.country)} (HQ)`,
                ]
              : [];
          }
          if (property.type === 'locations') {
            return content.map(
              (location: any) =>
                `${location.city}, ${
                  location.state !== '' ? `${location.state}, ` : ''
                }${property.map.get(location.country)}`,
            );
          }
          return content;
        });
        const labels = labelsLabels.flat();
        if (labels.length === 0) {
          return null;
        }
        return (
          <Accordion defaultExpanded sx={{ boxShadow: 'none', m: '0 !important' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ...accordionSummaryStyles }}>
              <Typography variant="heading4">{accordian.title}</Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ px: 0 }}>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {labels.map((label) => (
                  <CustomChip
                    label={label}
                    tooltipTitle={label}
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'break-spaces',
                        padding: '0.5rem 0.75rem',
                      },
                    }}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
  </Stack>
);

export default CompanyProfileAccordionInfo;
