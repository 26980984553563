import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

const HelpText = () => (
  <Box>
    <Stack
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={{
        bgcolor: 'primaryLight.50',
        p: 2,
        borderRadius: '4px',
        ml: '24px',
      }}
    >
      <Typography color="primaryDark.600">
        Please complete the form to share important information about your project.{' '}
      </Typography>
    </Stack>
  </Box>
);

export default HelpText;
