import { Divider, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box } from '@mui/system';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import Button from '../../../../../components/button/Button';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import InvoiceFileUpload from '../../../../EndToEnd/Payments/PaymentDetails/InvoiceFileUpload';

type ContractUploadModalProps = {
  data: ContractDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ContractPdfUploadModal = ({ data, isOpen, setIsOpen }: ContractUploadModalProps) => {
  const [file, setFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const { mutate: upload } = useCreate({ resource: `/contracts/${data?.id}/document` });
  const refresh = useRefresh();

  const handleUploadContract = () => {
    upload(
      {
        document: file,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          setFile(null);
          setFileName('');
          refresh();
        },
      },
    );
  };
  const handleDeleteFile = () => {
    setFile(null);
    setFileName('');
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack
        sx={{
          width: '40%',
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
          <Paper
            sx={{
              padding: '2em',
            }}
          >
            <Stack spacing={2}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '1.25em',
                    fontWeight: 600,
                    mt: 3,
                  }}
                >
                  Attach the contract
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }} spacing={1}>
                <InvoiceFileUpload
                  uploadSubject={'contract'}
                  setFile={setFile}
                  setFileName={setFileName}
                />
                {fileName && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      p: 3,
                      mt: 1,
                      textAlign: 'center',

                      color: '#5C5F5C',
                      border: '1px solid #A9ACA8',
                      borderRadius: 1,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      width="50%"
                      gap={1}
                    >
                      <Box
                        sx={{
                          p: 1,
                          background: '#E1E3DF',
                          borderRadius: 1,
                        }}
                      >
                        <InsertDriveFileOutlinedIcon sx={{ fontSize: '1rem' }} />
                      </Box>
                      <Typography variant="caption">{fileName}</Typography>
                    </Stack>
                    <IconButton onClick={handleDeleteFile}>
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <Button
                  variant="text"
                  onButtonClick={() => setIsOpen(false)}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onButtonClick={handleUploadContract}
                  disabled={!fileName}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Paper>
      </Stack>
    </Modal>
  );
};

export default ContractPdfUploadModal;
