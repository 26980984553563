import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { IconButton, Stack, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useLocation } from 'react-router-dom';
import arrowLeftMenuIcon from '../../../assets/icons/layout-icons/ArrowLeftMenuIcon.svg';
import menuIconTablet from '../../../assets/icons/layout-icons/MenuIconTablet.svg';
import sponaLogo from '../../../assets/img/logo/sponaLogo.svg';
import sponaLogoLarge from '../../../assets/img/logo/sponaLogoLarge.svg';
import BreadcrumbsNavigation from './BreadcrumbsNavigation';
import RightSideToolbat from './RightSideToolbar';

interface AppbarProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  drawerWidth: number;
  appbarHeight: number;
}

const DRAWER_BUTTON_WIDTH = 36;

const DashboardAppbar = ({ isOpen, setIsOpen, drawerWidth, appbarHeight }: AppbarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('bg'));

  const logoSectionWidth = isOpen ? drawerWidth - DRAWER_BUTTON_WIDTH : theme.spacing(7);
  const onCloseTransitionStyle = 'width 0.5s, padding 0.5s';

  const printAppropriateTitle = () => {
    if (location.pathname.includes('end-to-end')) {
      return 'End to End';
    }
    if (location.pathname.includes('timed-projects')) {
      return 'Projects';
    }
    if (location.pathname.includes('calendar')) {
      return 'Calendar';
    }
    if (location.pathname.includes('notifications')) {
      return 'Notifications';
    }
    if (location.pathname.includes('pitches')) {
      return 'Pitches';
    }
    if (location.pathname.includes('case-studies') || location.pathname.includes('case-study')) {
      return 'Case studies';
    }
    if (location.pathname.includes('chat')) {
      return 'Chat';
    }
    if (location.pathname.includes('create-company')) {
      return 'Register ';
    }
    if (location.pathname.includes('dashboard')) {
      return 'Homescreen ';
    }
    if (location.pathname.includes('seller-archive')) {
      return 'Seller Archive';
    }
    if (location.pathname.includes('case-study-archive')) {
      return 'Case Study Archive';
    }
    if (location.pathname.includes('footer')) {
      return 'Footer Edit ';
    }
    return 'Add appropriate title for this case';
  };

  return (
    <MuiAppBar
      sx={{
        background: '#FFF',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 'none',
        height: appbarHeight,
        display: 'flex',
        direction: 'row',
        borderBottom: '1px solid #DCDCFF',
      }}
    >
      <Stack width="100%" height="100%" direction="row" alignItems="center">
        {isLessThanLarge && !isOpen ? (
          <>
            <IconButton
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '2.8rem',
                borderRadius: 0,
                height: '100%',
                background: 'linear-gradient(270deg, #5238D3 0%, rgb(109, 81, 246) 100%)',
                border: 'none',
                transition: 'all 0.5s',
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Box
                component="img"
                src={menuIconTablet}
                sx={{ transform: isOpen ? null : 'rotate(180deg)' }}
              />
            </IconButton>
            <Stack
              height="100%"
              alignItems="center"
              direction="row"
              justifyContent="center"
              sx={{
                background: '#6D51F6',
                width: '2.8rem',
                transition: onCloseTransitionStyle,
                transitionTiming: 'ease-in-out',
              }}
            >
              <Box
                component="img"
                src={sponaLogo}
                width={30}
                sx={{ transition: onCloseTransitionStyle, transitionTiming: 'ease-in-out' }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack
              height="100%"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{
                background: '#6D51F6',
                width: logoSectionWidth,
                pl: isOpen ? '28px' : '13px',
                transition: onCloseTransitionStyle,
                transitionTiming: 'ease-in-out',
              }}
            >
              <Box
                component="img"
                src={isOpen ? sponaLogoLarge : sponaLogo}
                width={isOpen ? 120 : 30}
                sx={{ transition: onCloseTransitionStyle, transitionTiming: 'ease-in-out' }}
              />
            </Stack>
            <IconButton
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: DRAWER_BUTTON_WIDTH,
                borderRadius: 0,
                height: '100%',
                background: 'linear-gradient(90deg, #5238D3 0%, rgb(109, 81, 246) 100%)',
                border: 'none',
                transition: 'all 0.5s',
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Box
                component="img"
                src={arrowLeftMenuIcon}
                sx={{ transform: isOpen ? null : 'rotate(180deg)' }}
              />
            </IconButton>
          </>
        )}
        <Stack
          height="100%"
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          flex={1}
        >
          <Stack height="45px" alignItems="center" direction="row">
            <Typography
              variant="headline3"
              sx={{
                px: '24px',
                pb: 1,
                borderRight: '1px solid #E6E8FF',
              }}
              color="primaryDark.600"
            >
              {/* TODO: Make this generic when we have better idea of what goes here */}
              {printAppropriateTitle()}
            </Typography>
            <Stack sx={{ ml: '24px' }} className="react-joyride-step-1-project">
              <BreadcrumbsNavigation />
            </Stack>
          </Stack>
          <RightSideToolbat />
        </Stack>
      </Stack>
    </MuiAppBar>
  );
};
export default DashboardAppbar;
