import { Stack } from '@mui/material';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import { countriesSorted } from '../../../../utils/helpers';
import ControlledAutocompleteInput from '../../../../components/custom-inputs/ControlledAutocompleteInput';

interface JurisdictionAccordionProps {
  isAnnex: boolean;
}

const JurisdictionForm = ({ isAnnex }: JurisdictionAccordionProps) => (
  <Stack width="50%" alignItems="start">
    <ControlledAutocompleteInput
      name="body.jurisdiction"
      defaultValue={null}
      label="Country"
      required
      fullWidth
      placeholder="Select country"
      disabled={isAnnex}
      choices={countriesSorted()}
      sx={{ width: '100%' }}
    />
  </Stack>
);

export default JurisdictionForm;
