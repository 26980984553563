import { Card, Stack, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useState } from 'react';
import { EnumProjectContractStatus } from 'tdc-web-backend/enums/enums';
import { formatDateFns } from '../../../../utils/helpers';
import Button from '../../../../components/button/Button';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import ContractPdfUploadModal from './modals/ContractPdfUploadModal';

const AdminContractGeneralDetailsCard = ({ data }: { data: ContractDto }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: '8px',
        width: '50%',
        justifyContent: 'center',
      }}
    >
      <Stack direction="column" alignItems="center">
        <Stack
          direction="row"
          width="100%"
          sx={{
            justifyContent: 'space-between',
            marginY: '12px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: '#757875',
            }}
          >
            Status
          </Typography>
          <TaskStatus text={data?.status} />
        </Stack>
        <Stack
          direction="row"
          width="100%"
          sx={{
            justifyContent: 'space-between',
            marginY: '12px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: '#757875',
            }}
          >
            Created
          </Typography>
          <Typography>{formatDateFns(data?.created, '-')}</Typography>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          sx={{
            justifyContent: 'space-between',
            marginY: '12px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: '#757875',
            }}
          >
            Signed
          </Typography>
          <Typography>{formatDateFns(data?.signed, '-')}</Typography>
        </Stack>
        {data?.document ? (
          <a
            href={data?.document}
            target="_blank"
            rel="noreferrer"
            style={{
              width: '80%',
              textDecoration: 'none',
            }}
          >
            <Button
              variant="contained"
              startIcon={<SaveAltIcon />}
              onButtonClick={() => null}
              color="secondary"
              sx={{
                borderRadius: '100px',
                fontWeight: 500,
                width: '100%',
                mt: 3,
              }}
            >
              Download PDF
            </Button>
          </a>
        ) : (
          <Button
            variant="contained"
            startIcon={<UploadFileIcon />}
            onButtonClick={() => setIsModalOpen(true)}
            disabled={
              data?.status === EnumProjectContractStatus.Draft ||
              data?.status === EnumProjectContractStatus.Canceled ||
              data?.status === EnumProjectContractStatus.Negotiation
            }
            color="secondary"
            sx={{
              borderRadius: '100px',
              fontWeight: 500,
              width: '80%',
              mt: 3,
            }}
          >
            Upload PDF
          </Button>
        )}
      </Stack>
      <ContractPdfUploadModal data={data} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Card>
  );
};

export default AdminContractGeneralDetailsCard;
