import { Divider, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as EnvelopeIcon } from '../../../../assets/icons/project-icons/EnvelopeIcon.svg';
import CustomButton from '../../../../components/button/CustomButton';

interface ResendSuccessModalProps {
  open: boolean;
  email: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ResendSuccessModal = ({ open, email, setIsOpen }: ResendSuccessModalProps) => (
  <CustomModal open={open}>
    <Stack spacing={3}>
      <Stack spacing={2} direction="row" alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: '#E5E8FF',
            width: 'fit-content',
            height: 'fit-content',
            borderRadius: '6px',
            p: 1.5,
          }}
        >
          <EnvelopeIcon />
        </Stack>
        <Typography variant="heading3" color="primaryDark.600">
          verify your email
        </Typography>
      </Stack>
      <Divider />
      <Typography color="primaryDark.500">
        The verification email has been resent to this address:
      </Typography>
      <Typography color="primaryDark.500">{email}</Typography>
      <Divider />
      <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
        <CustomButton variant="secondary" onClick={() => setIsOpen(false)}>
          close
        </CustomButton>
      </Stack>
    </Stack>
  </CustomModal>
);

export default ResendSuccessModal;
