import { Box, Stack, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import CaseStudyFileUploadQueue from '../../../Dashboard/CaseStudies/CaseStudiesCreate/CaseStudyFileUploadQueue';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';

const Thumbnail = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleFile = (acceptedFiles: File[], onChange: (...event: any[]) => void) => {
    const file = acceptedFiles?.[0];
    if (!file) return;
    onChange(file);
    setValue('fileName', file.name);
  };

  const file = useWatch({ control, name: 'file' });
  const hasFileError = errors?.file;

  return (
    <Box>
      <BaseInputLabel text="thumbnail" />
      <Stack direction="row" spacing={3}>
        <ControlledDropzoneFileInput
          name="file"
          required
          dropzoneText="Drag your file here or "
          dropzoneHighlightText="browse"
          onUpload={handleFile}
          sx={{
            minWidth: '50%',
          }}
          dropzoneProps={{
            multiple: false,
          }}
        />

        {file && <CaseStudyFileUploadQueue name="file" />}
        {hasFileError && (
          <Typography
            color="secondaryPink.500"
            variant="body4"
            sx={{ alignSelf: 'start', display: 'block', margin: '12px 0' }}
          >
            This field is required
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default Thumbnail;
