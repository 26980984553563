import {
  ReactElement,
  RefAttributes,
  useState,
  ReactNode,
  ComponentType,
  Dispatch,
  SetStateAction,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { parseISO } from 'date-fns';
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  DesktopDatePickerSlotsComponentsProps,
} from '@mui/x-date-pickers';
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldProps,
} from '@mui/material';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import BaseTextInput, { TBaseTextInput } from './BaseTextInput';
import { primaryDark } from '../../color';
import { dateToLocalDateString, getPlatformFormat } from '../../utils/helpers';
import { fontSize } from '@mui/system';

interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: () => void;
  [x: string]: any;
  openDatePicker?: boolean;
  RenderInputElement?: ({
    textFieldProps,
  }: {
    textFieldProps: TextFieldProps;
  }) => JSX.Element | ReactElement | ReactNode;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
}

interface ISlotProps<TDate> extends DesktopDatePickerSlotsComponentsProps<TDate> {
  textField: TBaseTextInput;
}

interface IDateProps<TDate> extends DesktopDatePickerProps<TDate> {
  slotProps: ISlotProps<TDate>;
}

type TDatePickerComponent = (<TDate>(
  props: IDateProps<TDate> & RefAttributes<HTMLDivElement>,
) => JSX.Element) & { propTypes?: any };

const CustomDatePicker = DesktopDatePicker as TDatePickerComponent;

const ControlledDateInput = ({
  name,
  required,
  minDate,
  maxDate,
  onChange,
  openDatePicker = false,
  disabled,
  RenderInputElement,
  onClick,
  ...props
}: Props) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(openDatePicker);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => {
        const value = typeof field.value === 'string' ? parseISO(field.value) : field.value;
        return (
          <CustomDatePicker
            {...field}
            open={open}
            minDate={minDate}
            maxDate={maxDate}
            format={getPlatformFormat()}
            onChange={(newValue: unknown) => {
              field.onChange(dateToLocalDateString(new Date(newValue as string)));
              if (onChange) onChange();
            }}
            onClose={() => setOpen(false)}
            value={value || null}
            slots={{
              textField:
                (RenderInputElement as ComponentType<
                  FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps | undefined
                >) || BaseTextInput,
            }}
            slotProps={{
              textField: {
                name,
                disabled,
                onKeyDown: (e) => {
                  e.preventDefault();
                },
                onIconClick: () => {
                  setOpen(true);
                },
                onClick: () => {
                  setOpen(true);
                  onClick?.();
                },
                icon: <CalendarIcon fill={primaryDark[500]} />,
                ...props,
              },
              popper: { placement: 'bottom' },
              desktopPaper: {
                style: {
                  borderRadius: '16px',
                  boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
                },
              },
            }}
            {...props}
          />
        );
      }}
    />
  );
};

export default ControlledDateInput;
