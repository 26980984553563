import PricingMainLayout from './components/PricingMainLayout/PricingMainLayout';
import SellerPricingContent from './components/SellerPricingContent/SellerPricingContent';

const SellerPricing = () => (
  <PricingMainLayout>
    <SellerPricingContent />
  </PricingMainLayout>
);

export default SellerPricing;
