import { Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { EnumProjectMilestoneStatus, EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import { MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import Link from '../../../components/link/Link';
import CircularProgressWithLabel from '../../../components/review-score/CircularProgressWithLabel';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { SIDEBAR_CONTAINER_PADDING_TOP } from '../../Dashboard/Main';
import LinearProgressStepper from './LinearProgressStepper';
import { calculateProjectProgress } from '../../../utils/helpers';

const LinearProgressStepperWrapper = () => {
  const location = useLocation();
  const params = useParams();

  const circularProgressBarWrapperRef = useRef<HTMLDivElement | null>(null);
  const rowHeadingRef = useRef<HTMLDivElement | null>(null);

  const [adjacentDimensions, setAdjacentDimensions] = useState({
    circularProgressBarWrapperHeight: 0,
    rowHeadingHeight: 0,
  });

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: params.projectId,
    enabled: !!params.projectId,
  });

  const { data: milestones } = useGetMany<MilestoneSnakeDto>({
    resource: `milestones/snake?project=${params.projectId}`,
    enabled: !!params.projectId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const milestonesArray = milestones?.data.results;

  const findIdByStatus = (status: EnumProjectMilestoneStatus, milestones = milestonesArray) => {
    const milestone = milestones?.find((item) => item.status === status);
    if (milestone?.relatedMilestones.length) {
      return milestone.relatedMilestones[(milestone.activeMilestoneIndex as number) - 1]?.id;
    }
    return milestone?.id;
  };

  const findActiveMilestoneId = () =>
    findIdByStatus(EnumProjectMilestoneStatus.InProgress) ||
    findIdByStatus(EnumProjectMilestoneStatus.UnderReview) ||
    findIdByStatus(EnumProjectMilestoneStatus.Approved) ||
    findIdByStatus(EnumProjectMilestoneStatus.Pending) ||
    findIdByStatus(EnumProjectMilestoneStatus.Completed, milestonesArray?.reverse()) ||
    findIdByStatus(EnumProjectMilestoneStatus.Negotiation);

  const projectCompletion =
    projectData &&
    calculateProjectProgress(
      projectData.tasksCount,
      projectData.milestoneCount,
      projectData.tasksCompletedCount,
      projectData.tasksCanceledCount,
      projectData.milestoneCompletedCount,
      projectData.milestoneApprovedCount,
    );

  useEffect(() => {
    if (circularProgressBarWrapperRef.current && rowHeadingRef.current) {
      setAdjacentDimensions({
        circularProgressBarWrapperHeight: circularProgressBarWrapperRef.current.offsetHeight,
        rowHeadingHeight: rowHeadingRef.current.offsetHeight,
      });
    }
  }, [params, location, circularProgressBarWrapperRef.current, rowHeadingRef.current]);

  return (
    <Stack sx={{ position: 'sticky', top: 114, m: 0 }}>
      <Stack ref={rowHeadingRef} direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="heading3"
          color="primaryDark.600"
          className="react-joyride-step-5-project"
        >
          Milestones
        </Typography>
        <Link underline="none" to={`end-to-end/milestone/${findActiveMilestoneId()}`}>
          <Typography
            variant="heading4"
            color="primaryLight.500"
            className="react-joyride-step-6-project"
          >
            Explore
          </Typography>
        </Link>
      </Stack>

      <Stack
        ref={circularProgressBarWrapperRef}
        alignItems="center"
        justifyContent="center"
        height={250}
        className="react-joyride-step-7-project"
      >
        <CircularProgressWithLabel<EnumProjectStatus>
          displayScore
          truePercentage={projectCompletion ? Math.floor(projectCompletion) : 0}
          entityStatus={projectData?.status}
        />
      </Stack>

      <LinearProgressStepper
        milestones={milestonesArray || []}
        adjacentLayoutHeight={
          adjacentDimensions.circularProgressBarWrapperHeight +
          adjacentDimensions.rowHeadingHeight +
          SIDEBAR_CONTAINER_PADDING_TOP
        }
      />
    </Stack>
  );
};

export default LinearProgressStepperWrapper;
