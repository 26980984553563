import {
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {
  Dispatch, SetStateAction,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FlagDto } from 'tdc-web-backend/log-events/schema';
import EndToEndDetailScreenTypeEnum from '../../utils/enums/end-to-end-detail-screen-type';
import FlagTypeEnum from '../../utils/enums/flag-type';
import useUpdate from '../../utils/hooks/crud-hooks/useUpdate';
import { ReactComponent as FlagIcon } from '../../assets/icons/layout-icons/FlagIcon.svg';
import ControlledTextInput from '../custom-inputs/ControlledTextInput';
import { useRefresh } from '../../utils/hooks/crud-hooks/useRefresh';
import CustomModal from '../modal/CustomModal';
import CustomButton from '../button/CustomButton';
import { secondaryPink } from '../../color';

export interface FlagModalProps {
  entityId: string;
  entityType: EndToEndDetailScreenTypeEnum;
  actionType: FlagTypeEnum;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const FlagAndUnflagModal = ({
  entityId, entityType, actionType, isModalOpen, setIsModalOpen,
}: FlagModalProps) => {
  const refresh = useRefresh();

  const formMethods = useForm<FlagDto>({
    mode: 'onChange',
    defaultValues: {
      summary: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const printAppropriateTitle = (
    entityType: EndToEndDetailScreenTypeEnum,
    actionType: FlagTypeEnum,
  ) => {
    // MILESTONE
    if (entityType === EndToEndDetailScreenTypeEnum.Milestone) {
      if (actionType === FlagTypeEnum.Unflag) {
        return 'Remove flag';
      }

      return 'Flag milestone';
    }

    // TASK
    if (entityType === EndToEndDetailScreenTypeEnum.Task) {
      if (actionType === FlagTypeEnum.Unflag) {
        return 'Remove flag';
      }

      return 'Flag task';
    }

    // SUBTASK
    if (entityType === EndToEndDetailScreenTypeEnum.Subtask) {
      if (actionType === FlagTypeEnum.Unflag) {
        return 'Remove flag';
      }

      return 'Flag subtask';
    }

    return 'Flag';
  };

  const {
    mutate: flagMilestone,
  } = useUpdate<unknown, FlagDto>({ resource: '/milestones' });

  const {
    mutate: unFlagMilestone,
  } = useUpdate<unknown, null>({ resource: '/milestones' });

  const {
    mutate: flagTask,
  } = useUpdate<unknown, FlagDto>({ resource: '/tasks' });

  const {
    mutate: unflagTask,
  } = useUpdate<unknown, null>({ resource: '/tasks' });

  const {
    mutate: flagSubtask,
  } = useUpdate<unknown, FlagDto>({ resource: '/subtasks' });

  const {
    mutate: unflagSubtask,
  } = useUpdate<unknown, null>({ resource: '/subtasks' });

  const onSubmit = (data?: FlagDto) => {
    // entity type
    switch (entityType) {
      // MILESTONE
      case EndToEndDetailScreenTypeEnum.Milestone:
        // action type
        switch (actionType) {
          // FLAG
          case FlagTypeEnum.Flag:
            if (data) {
              flagMilestone({
                id: entityId as string,
                suffix: 'flag',
                data: {
                  summary: data.summary,
                },
              }, {
                onSuccess: () => {
                  reset();
                  refresh();
                  setIsModalOpen(false);
                  // TODO: notify the Backoffice Manager about the flag and sending both
                  // seller and buyer a notification to the other party and a chat message to
                  // the relevant project-related chat (possibly also an email)
                },
              });
            }
            break;

          // UNFLAG
          case FlagTypeEnum.Unflag:
            unFlagMilestone({
              id: entityId as string,
              suffix: 'unflag',
            }, {
              onSuccess: () => {
                refresh();
                setIsModalOpen(false);
                // TODO: notify the Backoffice Manager about the flag and sending both
                // seller and buyer a notification to the other party and a chat message to
                // the relevant project-related chat (possibly also an email)
              },
            });
            break;
          default:
            return null;
        }
        break;

      // TASK
      case EndToEndDetailScreenTypeEnum.Task:
        // action type
        switch (actionType) {
          // FLAG
          case FlagTypeEnum.Flag:
            if (data) {
              flagTask({
                id: entityId as string,
                suffix: 'flag',
                data: {
                  summary: data.summary,
                },
              }, {
                onSuccess: () => {
                  reset();
                  refresh();
                  setIsModalOpen(false);
                  // TODO: notify the Backoffice Manager about the flag and sending both
                  // seller and buyer a notification to the other party and a chat message to
                  // the relevant project-related chat (possibly also an email)
                },
              });
            }
            break;

          // UNFLAG
          case FlagTypeEnum.Unflag:
            unflagTask({
              id: entityId as string,
              suffix: 'unflag',
            }, {
              onSuccess: () => {
                refresh();
                setIsModalOpen(false);
                // TODO: notify the Backoffice Manager about the flag and sending both
                // seller and buyer a notification to the other party and a chat message to
                // the relevant project-related chat (possibly also an email)
              },
            });
            break;
          default:
            return null;
        }
        break;

      // SUBTASK
      case EndToEndDetailScreenTypeEnum.Subtask:
        // action type
        switch (actionType) {
          // FLAG
          case FlagTypeEnum.Flag:
            if (data) {
              flagSubtask({
                id: entityId as string,
                suffix: 'flag',
                data: {
                  summary: data.summary,
                },
              }, {
                onSuccess: () => {
                  reset();
                  refresh();
                  setIsModalOpen(false);
                  // TODO: notify the Backoffice Manager about the flag and sending both
                  // seller and buyer a notification to the other party and a chat message to
                  // the relevant project-related chat (possibly also an email)
                },
              });
            }
            break;

          // UNFLAG
          case FlagTypeEnum.Unflag:
            unflagSubtask({
              id: entityId as string,
              suffix: 'unflag',
            }, {
              onSuccess: () => {
                refresh();
                setIsModalOpen(false);
                // TODO: notify the Backoffice Manager about the flag and sending both
                // seller and buyer a notification to the other party and a chat message to
                // the relevant project-related chat (possibly also an email)
              },
            });
            break;
          default:
            return null;
        }
        break;

      default:
        return null;
    }

    return null;
  };

  return (
    <CustomModal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      width="32%"
    >
      <Stack spacing={5} justifyContent="space-between">
        <Stack spacing={4}>
          {/* icon, title & divider */}
          <Stack spacing={3}>
            <Stack spacing={2} direction="row" alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: 'secondaryPink.100',
                  width: 'fit-content',
                  height: 'fit-content',
                  borderRadius: '6px',
                  px: 2,
                  py: 1,
                }}
              >
                <FlagIcon fill={secondaryPink[700]} style={{ width: 22, height: 22 }} />
              </Stack>

              <Typography
                variant="heading3"
                color="primaryDark.600"
              >
                {printAppropriateTitle(entityType, actionType)}
              </Typography>
            </Stack>

            <Divider sx={{ borderColor: '#E5E8FF' }} />
          </Stack>

          {/* description */}
          {
            actionType === FlagTypeEnum.Flag
            && (
              <Typography color="primaryDark.500">
                Flagging this
                {' '}
                {entityType}
                {' '}
                will send an alert your partner company.
                Add a summary to resolve the issue as quickly as possible.
              </Typography>
            )
          }
        </Stack>

        {/* form */}
        {
          actionType === FlagTypeEnum.Flag
          && (
            <Stack spacing={1}>
              <FormProvider {...formMethods}>
                <Box
                  id="flag-and-unflag"
                  component="form"
                  sx={{ height: '100%' }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <ControlledTextInput
                    required
                    label="description"
                    placeholder="Type"
                    name="summary"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                  />
                </Box>
              </FormProvider>
            </Stack>
          )
        }

        {
          actionType === FlagTypeEnum.Unflag
          && (
            <Typography color="primaryDark.500">
              {
                // `Clicking resolve will remove the flagged status from the ${entityType},
                // and the issue will be considered resolved.`
                `Removing the flagged status from the ${entityType} notifies
                your partner company that the issue is resolved.`
              }
            </Typography>
          )
        }

        <Stack spacing={2.5}>
          <Divider sx={{ borderColor: '#E5E8FF' }} />

          <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
            <CustomButton
              variant="secondary"
              // reset here is to clear the summary input
              onClick={() => { reset(); setIsModalOpen(false); }}
            >
              Cancel
            </CustomButton>

            <CustomButton
              form="flag-and-unflag"
              type="submit"
              variant="primary"
              onClick={() => {
                if (actionType === FlagTypeEnum.Unflag) {
                  // unflag triggers here
                  onSubmit();
                }
              }}
              disabled={
                (() => {
                  // check if form is valid only when you are flagging
                  // because only when you are flagging you have the
                  // summary text input
                  if (actionType === FlagTypeEnum.Flag) {
                    return !isValid;
                  }

                  return false;
                })()
              }
            >
              {actionType === FlagTypeEnum.Flag ? 'Flag' : 'Continue'}
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default FlagAndUnflagModal;
