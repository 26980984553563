import { Stack, Typography } from '@mui/material';

const Header = () => (
  <Stack spacing={3}>
    <Typography variant="publicHeading2" sx={{ fontSize: { xs: '3rem', lg: '4rem' } }}>
      Interested in Spona Media?
    </Typography>
    <Typography variant="publicBody3Regular">
      Contact us now and elevate your advertising!
    </Typography>
  </Stack>
);
export default Header;
