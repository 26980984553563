import { useParams } from 'react-router-dom';
import { TimedProjectPitchContentCustomDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { EnumTimedProjectPitchStatus } from 'tdc-web-backend/enums/enums';
import TimedProjectsDetailsPitchesPanel from './TimedProjectsDetailsPitchesPanel/TimedProjectsDetailsPitchesPanel';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import { dndGroupPitches } from '../../../../utils/helpers';

const TimedProjectsDetailsPitches = () => {
  const params = useParams();
  const { data: timedProjectPitchesContent } = useGetMany<TimedProjectPitchContentCustomDto>({
    enabled: !!params.projectId,
    resource: `timed-projects/${params.projectId}/pitches`,
  });

  const groupedPitches = dndGroupPitches(timedProjectPitchesContent?.data as any, {
    groupBy: 'status',
    groups: {
      round1: [
        EnumTimedProjectPitchStatus.FirstRound,
        EnumTimedProjectPitchStatus.FirstRoundRejected,
      ],
      round2: [
        EnumTimedProjectPitchStatus.SecondRound,
        EnumTimedProjectPitchStatus.SecondRoundRejected,
      ],
      round3: [EnumTimedProjectPitchStatus.Chosen],
    },
  });

  return <TimedProjectsDetailsPitchesPanel pitches={groupedPitches} />;
};

export default TimedProjectsDetailsPitches;
