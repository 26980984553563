import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Stack } from '@mui/system';
import { EnumTechPlatform } from 'tdc-web-backend/enums/enums';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { mapTechPlatform } from '../../../../../../../enums';
import { Dispatch, SetStateAction } from 'react';
import CompanyTechPlatformsEditModal from '../edit/CompanyTechPlatformsEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyTechPlatformsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTechPlatforms = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTechPlatformsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.techPlatforms?.map((platform: EnumTechPlatform) => (
        <CustomChip
          key={platform}
          tooltipTitle={mapTechPlatform.get(platform)}
          label={
            platform &&
            truncate(mapTechPlatform.get(platform) as string, chipLabelTruncateValue as number)
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}

      {companyData && isEditModalOpen && (
        <CompanyTechPlatformsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyTechPlatforms;
