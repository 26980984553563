import { Outlet } from 'react-router-dom';
import Footer from './footer/Footer';
import Navigation from './navigation/Navigation';

const Layout = () => (
  <>
    <Navigation />
    <Outlet />
    <Footer
      columns={[
        // TODO: Change links!!
        {
          id: 1,
          title: 'About',
          links: [
            {
              id: '1.1',
              title: 'About us',
              link: 'about-us',
            },
            {
              id: '1.2',
              title: 'Work with us',
              link: 'work-with-us',
            },
            // FIXME: commented out for version 1
            // {
            //   title: 'Success stories',
            //   link: '',
            // },
            {
              id: '1.3',
              title: 'Spona Media',
              link: 'spona-media',
            },
            {
              id: '1.4',
              title: 'Contact us ',
              link: '/contact-us',
            },
          ],
        },
        {
          id: 2,
          title: 'Buyers',
          links: [
            {
              id: '2.1',
              title: 'Hire',
              link: 'how-to-hire',
            },
            {
              id: '2.2',
              title: 'Manage',
              link: 'how-to-manage-buyer',
            },
            {
              id: '2.3',
              title: 'Pricing',
              link: 'how-to-manage-buyer/pricing',
            },
            // FIXME: commented out for version 1
            // {
            //   title: 'Enterprise',
            //   link: 'enterprise',
            // },
          ],
        },
        {
          id: 3,
          title: 'Sellers',
          links: [
            {
              id: '3.1',
              title: 'Find work',
              link: 'how-to-find-work',
            },
            {
              id: '3.2',
              title: 'Manage',
              link: 'how-to-manage-seller',
            },
            {
              id: '3.3',
              title: 'Pricing',
              link: 'how-to-manage-seller/pricing',
            },
          ],
        },
        {
          id: 4,
          title: 'Help & Support',
          links: [
            // FIXME: commented out for version 1
            // {
            //   title: 'FAQs',
            //   link: '/faq',
            // },
            {
              id: '4.1',
              title: 'Terms & Conditions',
              link: 'terms-of-service',
            },

            {
              id: '4.2',
              title: 'Blogs',
              link: 'blog-archive',
            },
          ],
        },
      ]}
    />
  </>
);

export default Layout;
