import { Stack, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { useState } from 'react';
import { ReactComponent as ErrorAlertIcon } from '../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { ReactComponent as FirstToSecondRoundIcon } from '../../../../../assets/icons/timed-projects-pitches/FirstToSecondRoundIcon.svg';
import { ReactComponent as MessageIcon } from '../../../../../assets/icons/layout-icons/MessageIcon.svg';
import { ReactComponent as ChosenIcon } from '../../../../../assets/icons/timed-projects-pitches/ChosenIcon.svg';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import CustomButton from '../../../../../components/button/CustomButton';
import CustomModal from '../../../../../components/modal/CustomModal';
import { secondaryBlue, secondaryPink } from '../../../../../color';
import LoadingButton from '../../../../../components/button/LoadingButton';
import { useNavigate } from 'react-router-dom';

export type ModalType = 'confirm' | 'hire';

export type ModalProps = {
  type: ModalType;
  isOpen: boolean;
  handleConfirm: () => void;
  handleOnClose: (revert: boolean) => void;
  patchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  projectId?: string | null;
};

const ConfirmPitchModal = ({
  type,
  isOpen,
  handleConfirm,
  handleOnClose,
  patchStatus,
  projectId,
}: ModalProps) => {
  const navigate = useNavigate();
  let slideNumber = 0;

  switch (patchStatus) {
    case 'loading':
      slideNumber = 0;
      break;
    case 'succeeded':
      slideNumber = 1;
      break;
    default:
      slideNumber = 0;
  }

  const slides = {
    confirm: [
      {
        title: 'Round 2 negotiations',
        icon: (
          <FirstToSecondRoundIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
        ),
        text: 'By proceeding, you will be selecting the seller for the second round of negotiations. You can select multiple sellers for this step of the process.',
      },
      {
        title: 'Chat created',
        icon: <MessageIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
        text: 'A chat group with the seller will be created automatically. Contact the seller to schedule a meeting and discuss the project strategy in more detail.',
      },
    ],
    hire: [
      {
        title: 'Hire seller',
        icon: <ChosenIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
        text: 'By proceeding, you will be hiring a seller to work on your project. You can only select one seller.',
      },
      {
        title: 'Well done, you have hired a seller for your project!',
        icon: <SuitcaseCheckIcon fill={secondaryBlue[700]} style={{ width: 18, height: 18 }} />,
        text: 'Your project will now be transferred to End-to-End Project Management, where the first step is to start working on the contract with your chosen seller.',
      },
    ],
  };

  const error = {
    title: 'Error',
    icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
    text: 'Unexpected error, please try again.',
  };

  const onCloseHandler = () => {
    if (slideNumber === 0) {
      handleOnClose(true);
    } else {
      handleOnClose(false);
    }
  };

  const onConfirmHandler = () => {
    handleConfirm();
  };

  return (
    <CustomModal open={isOpen}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" mb={3}>
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor={patchStatus === 'failed' ? secondaryPink[100] : secondaryBlue[100]}
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            {patchStatus === 'failed' ? error.icon : slides[type][slideNumber].icon}
          </Stack>
          <Typography variant="heading4" color="primaryDark.600">
            {patchStatus === 'failed' ? error.title : slides[type][slideNumber].title}
          </Typography>
        </Stack>
        <Stack
          py={2}
          sx={{
            borderTop: `1px solid ${secondaryBlue[100]}`,
            borderBottom: `1px solid ${secondaryBlue[100]}`,
          }}
        >
          <Typography color="primaryDark.500" variant="body2">
            {patchStatus === 'failed' ? error.text : slides[type][slideNumber].text}
          </Typography>
        </Stack>
        <Stack gap={0.625} direction="row" justifyContent="center" my={3}>
          {patchStatus !== 'failed' &&
            slides[type].map((slide, i) => (
              <FiberManualRecord
                key={i}
                fontSize="small"
                sx={{ color: i === slideNumber ? 'secondaryBlue.500' : '#C5C7C3' }}
              />
            ))}
        </Stack>

        <Stack mt={3} direction="row" gap={3} justifyContent="flex-end">
          {patchStatus !== 'failed' && slideNumber === 0 && (
            <>
              <CustomButton
                disabled={patchStatus === 'loading'}
                onClick={onCloseHandler}
                variant="secondary"
              >
                Cancel
              </CustomButton>
              <LoadingButton
                variant="contained"
                type="button"
                loading={patchStatus === 'loading'}
                color="secondary"
                size="large"
                sx={{
                  borderRadius: '4px',
                  height: '45px',
                  width: '120px',
                }}
                onButtonClick={onConfirmHandler}
              >
                <Typography variant="heading4">Confirm</Typography>
              </LoadingButton>
            </>
          )}
          {(slideNumber === 1 || patchStatus === 'failed') && (
            <CustomButton
              onClick={() =>
                patchStatus !== 'failed' && type === 'hire'
                  ? navigate(
                      `/dashboard/end-to-end/${projectId === null ? '' : `project/${projectId}`}`,
                    )
                  : onCloseHandler()
              }
              variant="primary"
            >
              {patchStatus !== 'failed' && type === 'hire' ? 'Continue' : 'Close'}
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ConfirmPitchModal;
