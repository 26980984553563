export default {
  name: '',
  roles: [
    'buyer',
  ],
  url: '',
  avatar: '',
  headquartersLocation: {
    country: '',
    city: '',
    state: '',
  },
  description: undefined,
  targetIndustries: [],
  averageProjectSize: 'bt_5k_10k',
  hourlyRate: 'bt_25_49',
  companySize: 'bt_2_10',
  languages: [],
  targetCompanySizes: [],
  targetCountries: [],
  services: [],
};
