import { Divider, Stack, Typography } from '@mui/material';
import { SpaceDto } from 'tdc-web-backend/spaces/schemas';
import React, { useCallback, useEffect, useState } from 'react';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { Space } from './Space';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as SupportIcon } from '../../../assets/icons/chat-icons/support.svg';
import SupportForm from './SupportForm';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import { UserRoles } from 'tdc-web-backend/enums/enums';
import { createSearchParams, useSearchParams } from 'react-router-dom';

export default () => {
  const isSponaAdmin = useGetLocalStorageUserData()?.roles.includes(UserRoles.Admin);
  let [searchParams, setSearchParams] = useSearchParams();

  const { data } = useGetMany<SpaceDto>({
    resource: 'spaces',
  });
  const spaces = data?.data.results ?? [];

  return (
    <Stack
      width="375px"
      direction="column"
      overflow="auto"
      sx={{ backgroundColor: 'primaryDark.150' }}
    >
      {searchParams.get('showSupport') === 'true' ? (
        <SupportForm />
      ) : (
        <Stack>
          <Stack px={3} py={1} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="heading4" color="primaryDark.600">
              Spaces
            </Typography>
          </Stack>
          <Divider color="secondaryBlue.100" />
          <Stack direction="row" justifyContent="space-between" />
          {spaces.map((space) => (
            <Space space={space} />
          ))}

          {!isSponaAdmin && (
            <Stack direction="row" px={3} py={2}>
              <CustomButton
                variant="primaryText"
                disableRipple
                onClick={() => {
                  setSearchParams(
                    createSearchParams({
                      showSupport: 'true',
                    }).toString(),
                  );
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <SupportIcon />
                  <Typography variant="heading4" color="primaryDark.600">
                    support
                  </Typography>
                </Stack>
              </CustomButton>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
