import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Stack } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { useMemo } from 'react';
import { useCustomInputContext } from '../CustomInputContext';
import CustomChip from '../../custom-chip/CustomChip';

// TODO: edge cases, e.g.: choices instanceof Array
// TODO: add transformLabelFn prop, etc.
const CustomInputAutocomplete = () => {
  const { field, hasError, placeholder, useFormContext, choices, multiple, name, disabled } =
    useCustomInputContext();

  const { getValues, setValue, trigger } = useFormContext();

  const fieldValue = getValues(name);

  const memoizedChoices = useMemo(() => choices, [choices]);

  return (
    <Stack>
      <Autocomplete
        {...field}
        disabled={disabled}
        disablePortal
        id="combo-box-demo"
        multiple={multiple}
        value={field.value === '' ? null : field.value}
        onChange={(_, data) => field.onChange(data)}
        options={Array.from(memoizedChoices!.keys())}
        getOptionLabel={(option) => memoizedChoices!.get(option) as string}
        // option is key here
        // props.key is value
        renderOption={(props: any, option, state) => (
          <MenuItem
            {...props}
            key={option}
            value={props.key}
            sx={{ justifyContent: 'flex-start !important', alignItems: 'center' }}
          >
            {multiple && (
              <>
                <CheckBoxOutlinedIcon
                  className="MenuItemCheckedIcon"
                  sx={{ color: 'primaryLight.500', width: 22, height: 22, mr: 0.85 }}
                />

                <CheckBoxOutlineBlankOutlinedIcon
                  className="MenuItemUncheckedIcon"
                  sx={{ color: 'primaryDark.300', width: 22, height: 22, mr: 0.85 }}
                />
              </>
            )}

            {props.key}
          </MenuItem>
        )}
        popupIcon={<ExpandMoreIcon sx={{ pointerEvents: 'none !important' }} />}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} error={hasError} variant="outlined" />
        )}
        renderTags={(option: any) => []}
      />

      {multiple && fieldValue?.length ? (
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
          {fieldValue.map((val: string) => {
            const chipLabel = choices instanceof Map ? choices.get(val) : '';
            return (
              <CustomChip
                key={val}
                label={chipLabel}
                // label={transformLabelFn ? transformLabelFn(chipLabel) : chipLabel}
                onDelete={() => {
                  setValue(
                    name,
                    fieldValue.filter((item: string) => item !== val),
                  );
                  trigger(name);
                }}
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CustomInputAutocomplete;
