import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../../../components/modal/CustomModal';
import { InlineWidget } from 'react-calendly';
import CustomButton from '../../../../../../components/button/CustomButton';

const CalendlyModal = ({
  open,
  showActionBtn,
  caseStudyId,
}: {
  open: boolean;
  showActionBtn: boolean;
  caseStudyId: string;
}) => {
  const navigate = useNavigate();
  return (
    <CustomModal
      width={{ xs: '80%', sm: '70%', lg: '50%' } as Record<string, string>}
      paperStyleProps={{ p: { xs: '2rem', lg: '2rem' } }}
      open={open}
    >
      <>
        <InlineWidget url={process.env.REACT_APP_CALENDLY_URL as string} />
        {showActionBtn && (
          <CustomButton
            sx={{ mt: 2 }}
            onClick={() => navigate(`../case-study/${caseStudyId}/submit-review`)}
          >
            Continue
          </CustomButton>
        )}
      </>
    </CustomModal>
  );
};

export default CalendlyModal;
