/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab,
  Paper,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const AdminFlagging = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [idToDelete] = useState<any>(undefined);
  const [, setErrors] = useState<string>('');

  const [flaggedMilestones, setflaggedMilestones] = useState<any[]>([]);
  const [flaggedTasks, setFlaggedTasks] = useState<any[]>([]);
  const [flaggedSubtasks, setFlaggedSubtasks] = useState<any[]>([]);

  const [tabValue, setTabValue] = useState<string>('1');

  function groupByStatus(data: any[]) {
    const flagged: {
      milestones: any[], tasks: any[], subtasks: any[]
    } = {
      milestones: [], tasks: [], subtasks: [],
    };
    return flagged;
  }


  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.30,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.20,
    },
    {
      field: 'buyerFlagged',
      headerName: 'Buyer Flagged',
      flex: 0.10,
      type: 'boolean',
    },
    {
      field: 'sellerFlagged',
      headerName: 'Seller Flagged',
      flex: 0.10,
      type: 'boolean',
    },
    {
      field: 'projectTitle',
      headerName: 'Project',
      flex: 0.30,
    },

  ];

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <TabContext value={tabValue}>
        <Paper sx={{ width: '100%' }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 2 }}>
            <Tab value="1" label="Milestones" />
            <Tab value="2" label="Tasks" />
            <Tab value="3" label="Subtask" />
          </Tabs>
          <TabPanel value="1" tabIndex={0}>
            <div style={{ height: '600px' }}>
              <DataGrid columns={columns} rows={flaggedMilestones} />
            </div>
          </TabPanel>
          <TabPanel value="2" tabIndex={-1}>
            <div style={{ height: '600px' }}>
              <DataGrid columns={columns} rows={flaggedTasks} />
            </div>
          </TabPanel>
          <TabPanel value="3" tabIndex={-2}>
            <div style={{ height: '600px' }}>
              <DataGrid columns={columns} rows={flaggedSubtasks} />
            </div>
          </TabPanel>
        </Paper>
      </TabContext>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Article delete
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete article?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminFlagging;
