import { Stack } from '@mui/system';
import { EnumService, mapService } from 'tdc-web-backend/enums/enums';
import { Dispatch, SetStateAction } from 'react';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import CompanyServicesEditModal from '../edit/CompanyServicesEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface ServicesProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const Services = ({ isEditModalOpen, setIsEditModalOpen }: ServicesProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <>
        {isEditModalOpen && (
          <CompanyServicesEditModal
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
          />
        )}

        {companyData?.services?.map((service: EnumService) => (
          <CustomChip
            key={service}
            tooltipTitle={mapService.get(service)}
            label={
              service &&
              truncate(mapService.get(service) as string, chipLabelTruncateValue as number)
            }
            sx={{ width: 'fit-content', textTransform: 'capitalize' }}
          />
        ))}
      </>
    </Stack>
  );
};

export default Services;
