import { Stack, Typography } from '@mui/material';

const MandatoryLegalForm = () => (
  <Stack sx={{
    backgroundColor: 'primaryDark.150',
    p: 3,
    color: 'primaryDark.500',
    borderRadius: 2,
    '& span': {
      display: 'block',
    },
  }}
  >
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,
      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        1. Payment Method
      </Typography>
      Each Party agrees that the payment in the respect of the Seller for the services
      provided according to this Contract will be paid by the Buyer through the Top
      Digital Agency platform (hereinafter referred to as TDA).
      Once the Buyer approves the work and sends the funds to TDA, TDA will disburse
      the funds to the Seller.
      Invoices and due dates will be based on the payment schedule agreed upon between
      the Buyer and the Seller.
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,
      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        2. No Partnership, No Agency
      </Typography>
      Nothing in this Contract is intended to create any partnership or joint venture
      between the Parties.
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,

      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        3. Warrants
      </Typography>
      Each Party represents and warrants that it has the full right and power to enter
      into this Contract and that it is fully ready, willing, and able to perform all
      its obligations hereunder.
      Each Party further warrants that it has obtained or will obtain all necessary
      clearances, permissions, and/or releases as provided above.
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,

      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        4. Indemnity
      </Typography>
      Each Party shall indemnify, defend, and hold harmless the other Party against
      any and all Third Party claims, demands, proceedings, actions, orders, awards,
      judgments, liabilities, damages, losses, costs, charges and expenses (including
      without limitation, reasonable attorneys’ fees), (hereinafter referred to as:
      ‘’Losses’’) to the extent such losses result from any:
      <Typography
        variant="body3"
        sx={{
          p: 1,
          pl: 2,
          textAlign: 'left',
        }}
      >
        a. breach of any representation or warranty by the Indemnifying Party
        contained in this Contract,
      </Typography>
      <Typography
        variant="body3"
        sx={{
          p: 1,
          pl: 2,
          textAlign: 'left',
        }}
      >
        b. breach of any applicable law by such Indemnifying Party, or
      </Typography>
      <Typography
        variant="body3"
        sx={{
          p: 1,
          pl: 2,
          textAlign: 'left',
        }}
      >
        c. gross negligence or willful misconduct by such Indemnifying Party, its
        Affiliates, or their respective employees, Referrers, or Agents
      </Typography>
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,
      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        5. Limitation of Liability
      </Typography>
      Under no circumstances shall either Party be liable to the other Party or any
      third Party for any damages resulting from actions taken by any part of this
      Contract such as, but not limited to, loss of revenue or anticipated profit or
      lost business, costs of delay or failure of delivery, which is not related to or
      the direct result of a Party’s gross negligence or an intentional breach.
      Under no circumstances will either Party be liable to the other Party or any
      third Party for an amount greater than the aggregate amounts invoiced to the
      Buyer during the preceding 12 months. The foregoing limitations of liability
      will not apply to non-compliance with laws or other government requirements or
      in cases of illegal actions or acts, fraud, theft, or willful misconduct by
      either Party.

    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,
      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        6. Severability
      </Typography>
      In the event, any provision of this Contract is deemed invalid or unenforceable,
      in whole or in part, that part shall be severed from the remainder of the
      Contract and all other provisions should continue in full force and effect as
      valid and enforceable.
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,

      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        7. Waiver of Contractual Rights.
      </Typography>
      The failure of either Party to enforce any provision of this Contract shall not
      be construed as a waiver or limitation of that Party's right to subsequently
      enforce and compel strict compliance with every provision of this Contract.
    </Typography>
    <Typography
      variant="body3"
      sx={{
        textAlign: 'justify',
        pb: 5,
        display: 'block',
      }}
    >
      <Typography
        variant="heading3"
        sx={{
          textAlign: 'left',
        }}
      >
        8. Dispute Resolution
      </Typography>
      <Typography
        variant="body3"
        sx={{
          p: 2,
          textAlign: 'left',
        }}
      >
        8.1. Attempt to Amicably Resolve Disputes
      </Typography>
      If any dispute, controversy, or claim is initiated by either Party arising out
      of, resulting from or relating in any way to this Contract, the Parties agree to
      try in good faith to resolve such dispute. If such dispute, controversy or claim
      cannot be resolved amicably the parties agree on governing law and jurisdiction
      as follows.
    </Typography>
  </Stack>
);

export default MandatoryLegalForm;
