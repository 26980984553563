import { Avatar, Box, Stack, StackProps, SxProps, Theme, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import { useParams } from 'react-router-dom';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import Editor from '../../../../components/editor/Editor';
import { cardStyles } from '../CompanyProfilePublicScreen';

type CompanyProfileAboutProps = {
  company: CompanyDto | undefined;
  sx?: SxProps<Theme>;
} & StackProps;

const CompanyProfileDescriptionAndClients = ({
  company,
  sx,
  ...props
}: CompanyProfileAboutProps) => {
  const params = useParams();

  const { data: companyClientResponse } = useGetMany<CompanyClientDto>({
    resource: `company-client?company=${params?.companyId}`,
  });

  const clientData = companyClientResponse?.data.results;

  return (
    <Stack spacing={3} sx={{ ...sx }} {...props}>
      {/* company description */}
      <Box sx={{ ...cardStyles }}>
        {company && company.description && (
          <Editor items={[]} isEditable={false} content={company.description} />
        )}
      </Box>

      {/* clients */}
      <Stack spacing={3} sx={{ ...cardStyles }}>
        <Typography color="common.black" sx={{ fontSize: '20px', fontWeight: 600 }}>
          Clients
        </Typography>

        {clientData && clientData.length > 0 ? (
          <Stack direction="row" flexWrap="wrap" sx={{ columnGap: '45px', rowGap: '25px' }}>
            {/* {clientsMock.map((data) => ( */}
            {clientData.map((data) => (
              <Stack key={data.id} spacing={1.5} direction="row" alignItems="center">
                <Avatar
                  alt="company logo"
                  src={`${data.avatar}`}
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                />

                <Typography color="common.black">{data.name}</Typography>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography
            sx={{
              fontStyle: 'italic',
              paddingTop: '15px',
              paddingLeft: '15px',
              fontSize: '14px',
              width: '100%',
            }}
          >
            No information provided.
          </Typography>
        )}
      </Stack>

      {/* team */}
      {/* FIXME: removed for release */}
      {/* <Stack spacing={3} sx={{ ...cardStyles }}>
        <Typography color="common.black" sx={{ fontSize: '20px', fontWeight: 600 }}>
          Meet the team
        </Typography>

        {teamMock && teamMock.length > 0 ? (
          <Stack direction="row" flexWrap="wrap" sx={{ columnGap: '45px', rowGap: '25px' }}>
            {teamMock.map((data) => (
              <Card
                key={data.id}
                elevation={0}
                sx={{
                  width: 160,
                  height: 230,
                  borderRadius: '12px',
                  bgcolor: 'primaryDark.100',
                }}
              >
                <Stack height="100%" justifyContent="space-between">
                  <CardMedia
                    component="img"
                    height="140"
                    image={data.avatar}
                    alt={`${data.name}-employee-image`}
                  />

                  <Stack spacing={0.5} height="100%" justifyContent="center" sx={{ px: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: 'bold', color: '#383838' }}
                    >
                      {data.name}
                    </Typography>

                    <Typography
                      variant="body2"
                      lineHeight="1rem"
                      color="primaryDark.500"
                      sx={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                      {data.role}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Stack>
        ) : (
          <Typography
            sx={{
              fontStyle: 'italic',
              paddingTop: '15px',
              paddingLeft: '15px',
              fontSize: '14px',
              width: '100%',
            }}
          >
            No information provided.
          </Typography>
        )}
      </Stack> */}
    </Stack>
  );
};

export default CompanyProfileDescriptionAndClients;
