import {
  Box, Button, ButtonProps, Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as RadioButton } from '../../../../../assets/icons/layout-icons/RadioButton.svg';

type MilestoneActionButtonVariant =
  | 'completedDisabled'
  | 'incompleteDisabled'
  | 'incompleteEnabled';

const checkmarkCircle = <RadioButton />;
const emptyCircle = (
  <Box sx={{
    border: '2px solid',
    borderColor: 'primaryDark.300',
    borderRadius: '50%',
    width: 15,
    height: 15,
  }}
  />
);

const buttonStyles = {
  width: {
    md: '42%',
    xl: '25%',
  },
  p: '6px',
  height: 35,
  border: '1px solid',
  boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
  borderColor: '#E5E8FF',
  bgcolor: 'common.white',
  color: 'primaryDark.600',
};

type MilestoneActionButtonProps = {
  variant: MilestoneActionButtonVariant;
  target?: string;
  rel?: string;
  onClick?: any;
} & Omit<ButtonProps, 'variant'>

const CustomTypography = ({ children }: { children: ReactNode }) => (
  <Typography
    variant="heading4"
    color="inherit"
    whiteSpace="pre"
  >
    {children}
  </Typography>
);

/**
 * A button with three variants.
 * incompleteDisabled - a disabled greyed out button with an empty circle icon
 * incompleteEnabled - an enabled button with an empty circle icon
 * completeDisabled - a disabled button with a green outline and a checkmark inside a circle icon
 */
export const MilestoneActionButton = ({
  variant,
  children,
  sx,
  ...rest
}: MilestoneActionButtonProps) => {
  if (variant === 'incompleteDisabled') {
    return (
      <Button
        className="react-joyride-step-5-milestone"
        disabled
        startIcon={emptyCircle}
        sx={{
          ...buttonStyles,
          '&:disabled': {
            border: 'none',
            bgcolor: 'primaryDark.150',
            color: 'primaryDark.400',
          },
        }}
        {...rest}
      >
        <CustomTypography>
          {children}
        </CustomTypography>
      </Button>
    );
  } if (variant === 'incompleteEnabled') {
    return (
      <Button startIcon={emptyCircle} sx={buttonStyles} {...rest} className="react-joyride-step-5-milestone">
        <CustomTypography>
          {children}
        </CustomTypography>

      </Button>
    );
  } // 'completedDisabled'
  return (
    <Button
      disabled
      startIcon={checkmarkCircle}
      className="react-joyride-step-5-milestone"
      sx={{
        ...buttonStyles,
        '&:disabled': {
          borderColor: 'secondaryGreen.500',
          bgcolor: 'common.white',
          color: 'primaryDark.600',
        },
      }}
      {...rest}
    >
      <CustomTypography>
        {children}
      </CustomTypography>
    </Button>
  );
};
