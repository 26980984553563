import { Box, Stack } from '@mui/material';
import { secondaryOrange } from '../../../color';
import TextAndImage from '../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from './components/HowMuchDoesItCost';
import HowToManageHero from './components/HowToManageHero';
import TheFutureOfSmartManaging from './components/TheFutureOfSmartManaging';
import { useNavigate } from 'react-router-dom';
import { textsAndImagesSeller, theFutureOfSmartManagingTextAndIcons } from './utils';
import MetaTags from '../../../components/seo/MetaTags';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';

const metaTags = {
  title: `Spona - The Ultimate Project Management Tool is here`,
  description: `End-to-End Project Management is our all-in-one project management tool. Sign contracts, Track project milestones get reports and pay, all in one place.`,
};

const HowToManageSeller = () => {
  const navigate = useNavigate();
  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        <HowToManageHero
          bgcolor={secondaryOrange[401]}
          title="The Ultimate Project Management Tool Is Here"
          subtitle="End-to-End Project Management is our all-in-one project management tool. It is designed to support successful project completion and smooth development with comprehensive features that make all phases of the process streamlined and convenient."
        />

        <Stack spacing={10} sx={{ mt: 12 }}>
          {textsAndImagesSeller.map(({ title, content, image, contentOrder, contentButton }) => (
            <TextAndImage
              title={title}
              content={content}
              image={image}
              contentOrder={contentOrder}
              contentButton={contentButton}
            />
          ))}
        </Stack>

        <HowMuchDoesItCost
          title="How much does it cost?"
          firstCard={{
            title: 'For Spona users',
            content:
              'End-to-End Project Management is a free service for users that have published a project and hired a digital service seller over Spona, for the duration of that project.',
            bgcolor: 'white',
            color: 'black',
          }}
          secondCard={{
            title: 'For End-to-End Project Management users',
            content:
              'End-to-End Project Management is a paid service for users that already have a contract with a seller and are interested only in investing in a management tool.',
            bgcolor: secondaryOrange[101],
            color: 'black',
          }}
        />

        <TheFutureOfSmartManaging
          textAndIcons={theFutureOfSmartManagingTextAndIcons(secondaryOrange[401])}
        />

        <Box sx={{ mt: '9rem' }}>
          <BecomeAPartOfSpona
            title="See our pricing plans"
            subtitle="Choose the plan that fits your needs best and get started with Spona today."
            bgcolor="secondaryOrange.501"
            onButtonClick={() => navigate('/how-to-manage-seller/pricing')}
          />
        </Box>
      </Stack>
    </>
  );
};

export default HowToManageSeller;
