import { Avatar, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { BaseTimedProject } from 'tdc-web-backend/timed-projects/schemas';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CustomTable from '../../../../components/custom-table/CustomTable';
import DashboardCard from '../../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import { primaryDark } from '../../../../color';

const PitchesList = () => {
  const navigate = useNavigate();
  const user = useGetLocalStorageUserData();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.15,
      renderCell: (params: GridValueGetterParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'project name',
      flex: 0.4,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600', width: '90%' }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'id',
      headerName: 'latest pitch',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Stack direction="row" gap={1}>
          <Avatar
            alt={params.row?.seller.name}
            src={params.row?.seller.avatar}
            sx={{ width: 24, height: 24 }}
          />
          <Typography>{params.row?.seller.name}</Typography>
        </Stack>
      ),
    },
    {
      field: 'totalPitches',
      headerName: 'Total Pitches',
      align: 'center',
      headerAlign: 'right',
      flex: 0.15,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="body1" sx={{ color: 'primaryDark.600' }}>
          {params.value}
        </Typography>
      ),
    },
  ];

  return (
    <DashboardCard
      headline="project pitches"
      linkText="view all"
      link="/dashboard/timed-projects"
      renderComponent={
        <>
          <Divider
            sx={{ width: '100%', my: 0, backgroundColor: primaryDark[250] }}
            color="primaryDark.500"
          />
          <CustomTable<BaseTimedProject>
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell': {
                padding: '0px',
              },
            }}
            resource="timed-projects/featured-pitches"
            disableTabView
            columns={columns}
            searchParams={{
              limit: 4,
            }}
            onRowClick={(params) => navigate(`/dashboard/timed-projects/${params.id}`)}
            autoHeight
            hideFooter
          />
        </>
      }
    />
  );
};

export default PitchesList;
