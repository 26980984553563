import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';

const AdminHome = () => (
  <Card sx={{ my: 3, p: 3 }}>
    <Typography>Admin home</Typography>
  </Card>
);

export default AdminHome;
