/* eslint-disable no-unused-expressions */
import { Stack, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RegisterRequestDto } from 'tdc-web-backend/auth/schemas';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AxiosError } from 'axios';
import Link from '../../../components/link/Link';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import CustomButton from '../../../components/button/CustomButton';
import CustomInput from '../../../components/custom-input/CustomInput';

export interface ShowPasswordI {
  password: boolean;
  confirmPassword: boolean;
}

const inputStyles = {
  minHeight: 120,
};

const RegisterSection = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useCreate<Record<string, unknown>, RegisterRequestDto>({
    resource: '/auth/register',
  });

  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmPassword: '',
      password: '',
      TermsAndConditions: false,
    },
    mode: 'onChange',
  });
  const { watch, handleSubmit, getValues, setError } = formMethods;

  const [showPass, setShowPass] = useState<Record<string, boolean>>({
    password: false,
    repeatPassword: false,
  });

  const onSubmit = (data: RegisterRequestDto) => {
    mutate(data, {
      onError: (error: AxiosError) => {
        if (error.response?.data.code === 'already-registered') {
          setError('email', {
            type: 'manual',
            message: error.response?.data.message,
          });
        }
      },
      onSuccess: () => {
        navigate('/verify');
      },
    });
  };

  return (
    <Stack spacing={6} sx={{ pb: 4 }}>
      <Typography
        variant="headline1"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        Sign Up
      </Typography>

      <FormProvider {...formMethods}>
        {/* form */}
        <Box
          component="form"
          sx={{ height: '90%' }}
          onSubmit={handleSubmit(() =>
            onSubmit({
              email: getValues('email'),
              password: getValues('password'),
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
            }),
          )}
        >
          <Stack>
            <CustomInput
              input={<CustomInput.Text />}
              name="firstName"
              label="First name"
              required
              sx={{ ...inputStyles }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              name="lastName"
              label="Last name"
              required
              sx={{ ...inputStyles }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              name="email"
              type="email"
              placeholder="example@mail.com"
              label="Email"
              required
              validationRegEx={{
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Invalid email address',
              }}
              sx={{ ...inputStyles }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.password ? 'text' : 'password'}
              name="password"
              label="Password"
              required
              defaultHelperText="Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character."
              placeholder="••••••••"
              adornment={{
                icon: showPass.password ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, password: !showPass.password });
                },
              }}
              validationRegEx={{
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
                message:
                  'Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.',
              }}
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
              sx={{ ...inputStyles }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.repeatPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="Repeat password"
              required
              placeholder="••••••••"
              adornment={{
                icon: showPass.repeatPassword ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, repeatPassword: !showPass.repeatPassword });
                },
              }}
              validate={() =>
                watch('confirmPassword') === watch('password') || 'Passwords do not match'
              }
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
              sx={{ ...inputStyles }}
            />

            {/* user agreeement & already have an account & create account button */}
            <Stack>
              <CustomInput
                input={<CustomInput.UserAgreement />}
                name="TermsAndConditions"
                required
                customRequiredMessage="Agree to terms to continue"
                sx={{ ...inputStyles }}
              />

              {/* already have an account & create account button */}
              <Stack
                spacing={2}
                direction={{ xs: 'column-reverse', lg: 'row' }}
                alignSelf="center"
                width="100%"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    alignSelf: 'center',
                    width: { xs: 'fit-content', lg: '100%' },
                    fontSize: '14px',
                  }}
                >
                  Already have an account?{' '}
                  <Link to="/login" color="secondary.main" sx={{ fontWeight: 'bold' }}>
                    Sign In
                  </Link>
                </Typography>

                <CustomButton
                  type="submit"
                  loading={isLoading}
                  variant="primary"
                  sx={{ width: { xs: '100%', lg: '55%' } }}
                >
                  Create Account
                </CustomButton>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </FormProvider>
    </Stack>
  );
};

export default RegisterSection;
