import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import TitleInput from './TitleInput';

type Props = {
  name: string;
  required?: boolean;
  label?: string;
  labelAdornment?:JSX.Element | null
  helperStyles?:SxProps<Theme>
} & TextFieldProps;

function ControlledTitleInput({
  name,
  label,
  required,
  labelAdornment,
  sx,
  ...props
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={required
        ? { required: 'This field is required' }
        : { required: false }}
      render={({ field }) => (
        <TitleInput
          label={label}
          field={field}
          name={name}
          required={required}
          labelAdornment={labelAdornment}
          sx={{ ...sx }}
          {...props}
        />
      )}
    />

  );
}

export default ControlledTitleInput;
