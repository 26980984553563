import {
  EnumIndustry,
  EnumCountry,
  EnumCompanySize,
  EnumLanguage,
  EnumService,
  EnumProjectHourlyRate,
  EnumProjectSize,
  CompanyRole,
  EnumCurrency,
} from 'tdc-web-backend/enums/enums';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useNavigate } from 'react-router-dom';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import useMultiStep from '../../../../components/multisteps/useMultiStep';
import MultiStep from '../../../../components/multisteps/Multistep';
import { printHelperTextForSpecificCurrentStep } from '../../../../utils/helpers';
import {
  TClient,
  TUserType,
  IBuyerDetails,
  ISellerDetails,
} from '../../../Company/PostRegistration/interface';
import Overview from '../../../Company/PostRegistration/steps/Overview';
import User from '../../../Company/PostRegistration/steps/User';
import { helperTexts } from '../../../Company/PostRegistration/utils';
import DetailsSeller from '../../../Company/PostRegistration/steps/DetailsSeller';
import DetailsBuyer from '../../../Company/PostRegistration/steps/DetailsBuyer';
import CustomizedAdminStep from './components/CustomizedAdminStep';
import { useLocation } from 'react-router-dom';

export const labels = ['User', 'Overview', 'Admin', 'Details'];

export type TPostRegistration = {
  user: TUserType;
  overview: {
    name: string;
    url: string;
    description: string;
    headquartersLocation: {
      country: EnumCountry;
      city: string;
      state: string;
    };
    avatar?: string;
  };
  admin: {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
  };
  details: IBuyerDetails | ISellerDetails;
};

const initialState: Omit<TPostRegistration, 'details'> = {
  user: CompanyRole.SellerService,
  overview: {
    name: '',
    url: '',
    description: '',
    headquartersLocation: {
      country: '' as EnumCountry,
      city: '',
      state: '',
    },
    avatar: '',
  },
  admin: {
    jobTitle: '',
    email: '',
    firstName: '',
    lastName: '',
  },
};

export const initialSellerState: TPostRegistration = {
  ...initialState,
  details: {
    targetIndustries: [] as EnumIndustry[],
    targetCountries: [] as EnumCountry[],
    companySize: '' as EnumCompanySize,
    languages: [] as EnumLanguage[],
    services: [] as EnumService[],
    clients: [] as TClient[],
    hourlyRate: '' as EnumProjectHourlyRate,
    averageProjectSize: '' as EnumProjectSize,
    currency: EnumCurrency.Usd,
  },
  user: CompanyRole.SellerService,
};

export const initialBuyerState: TPostRegistration = {
  ...initialState,
  details: {
    targetIndustries: [] as EnumIndustry[],
    targetCountries: [] as EnumCountry[],
    companySize: '' as EnumCompanySize,
    languages: [] as EnumLanguage[],
  },
  user: CompanyRole.Buyer,
};

interface LocationState {
  fromBuyerPage?: boolean;
}

const AdminCompanyCreateNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFromBuyerPage = (location.state as LocationState)?.fromBuyerPage || false;
  let initialUserType: TUserType = isFromBuyerPage ? CompanyRole.Buyer : CompanyRole.SellerService;

  const dynamicInitialState: TPostRegistration = {
    ...initialState,
    user: initialUserType,
    details:
      initialUserType === CompanyRole.SellerService
        ? initialSellerState.details
        : initialBuyerState.details,
  };

  const { currentStep, steps, onStepChange, form } = useMultiStep<TPostRegistration>({
    labels,
    initialState: dynamicInitialState,
  });

  const { mutate: create, isLoading: isCreateLoading } = useCreate<any, CompanyDto>({
    resource: 'users/create-company-user',
  });

  const onSubmit = (data: any) => {
    const { tmpClients, ...companyDetails } = data.details;

    create(
      {
        ...companyDetails,
        ...data.overview,
        admin: data.admin,
        roles: [data.user],
      },
      {
        onSuccess: () => {
          navigate('../sos');
        },
      },
    );
  };

  const userType: TUserType = form.watch('user');

  const detailsForUser =
    userType === CompanyRole.SellerService ? <DetailsSeller /> : <DetailsBuyer />;

  return (
    <MultiStep
      form={form}
      steps={steps}
      currentStep={currentStep}
      onChange={onStepChange}
      onSubmit={onSubmit}
      helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
      disableCancelButton
      isSubmitLoading={isCreateLoading}
    >
      <>
        {currentStep === 1 && <User />}
        {currentStep === 2 && <Overview />}
        {currentStep === 3 && <CustomizedAdminStep />}
        {currentStep === 4 && detailsForUser}
      </>
    </MultiStep>
  );
};

export default AdminCompanyCreateNew;
