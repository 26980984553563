import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { Stack } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import CompanyCertificationsEditModal from '../edit/CompanyCertificationsEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyCertificationsProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyCertifications = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyCertificationsProps) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {companyData?.certifications?.map((certification: string) => (
        <CustomChip
          tooltipTitle={certification}
          key={certification}
          label={
            certification && truncate(certification as string, chipLabelTruncateValue as number)
          }
          sx={{ width: 'fit-content', textTransform: 'capitalize' }}
        />
      ))}
      {companyData && isEditModalOpen && (
        <CompanyCertificationsEditModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyCertifications;
