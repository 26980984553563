import {
  Card, Grid, Stack, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { useFormContext } from 'react-hook-form';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import ControlledCheckboxInput from '../../../../components/custom-inputs/ControlledCheckboxInput';
import { secondaryBlue, secondaryGreen } from '../../../../color';

interface SignaturesAccordionProps {
  userType: CompanyRole | undefined
  buyer: string
  seller: string
}

const SignaturesForm = ({
  userType,
  buyer,
  seller,
}: SignaturesAccordionProps) => {
  const { trigger } = useFormContext();

  useEffect(() => {
    trigger(['buyerApproved', 'sellerApproved']);
  }, [userType]);

  return (

    <Stack alignItems="center">
      <Grid
        container
        spacing={3}

      >
        <Grid
          sx={{
            borderRight: `1px solid ${secondaryBlue[100]}`,
            pr: 2.5,
            py: 3,
          }}
          item
          xs={6}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                lineSpacing: 1,

              }}
            >
              <Typography
                variant="heading5"
                color="primaryDark.500"
                mb={1}
              >
                Buyer
              </Typography>

              <CustomReferenceField
                displayName="name"
                resource="companies"
                id={buyer}
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: 'primaryDark.600',
                }}
              />

            </Typography>

            <Card
              sx={{
                px: 3,
                py: 4,
                border: `1px solid ${userType === CompanyRole.SellerService || userType === CompanyRole.SellerTool ? secondaryBlue[100] : secondaryGreen[500]}`,
                borderRadius: 1.5,
                boxShadow: 'none !important',
              }}
              elevation={2}
            >
              <ControlledCheckboxInput
                name="buyerApproved"
                color="secondary"
                disabled={
                  userType === CompanyRole.SellerService || userType === CompanyRole.SellerTool
                }
                required={userType === undefined ? true : userType === CompanyRole.Buyer}
                label={(
                  <Typography
                    variant="heading5"
                    color="primaryDark.600"
                  >
                    I accept the terms of the Contract
                  </Typography>
                )}
              />
            </Card>
          </Stack>
        </Grid>
        <Grid
          sx={{
            pl: 2.5,
            py: 3,
          }}
          item
          xs={6}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                lineSpacing: 1,
              }}
            >

              <Typography
                variant="heading5"
                color="primaryDark.500"
                mb={1}
              >
                Seller
              </Typography>
              <CustomReferenceField
                displayName="name"
                resource="companies"
                id={seller}
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: 'primaryDark.600',
                }}
              />
            </Typography>

            <Card
              sx={{
                px: 3,
                py: 4,
                border: `1px solid ${userType === CompanyRole.Buyer ? secondaryBlue[100] : secondaryGreen[500]}`,
                borderRadius: 1.5,
                boxShadow: 'none !important',
              }}
              elevation={2}
            >
              <ControlledCheckboxInput
                name="sellerApproved"
                color="secondary"
                disabled={userType === CompanyRole.Buyer}
                required={
                  userType === CompanyRole.SellerService || userType === CompanyRole.SellerTool
                }
                sx={{ fontSize: '12px' }}
                label={(
                  <Typography
                    variant="heading5"
                    color="primaryDark.600"
                  >
                    I accept the terms of the Contract
                  </Typography>
                )}
              />
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SignaturesForm;
