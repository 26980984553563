import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import { secondaryBlue, secondaryPink } from '../../../../../color';
import { ReactComponent as ErrorAlertIcon } from '../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { ReactComponent as RequestReviewIcon } from '../../../../../assets/icons/project-icons/RequestReviewIcon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/icons/layout-icons/SaveIcon.svg';
import { ReactComponent as CancelIcon } from '../../../../../assets/icons/project-icons/CancelCircleIcon.svg';

const modalsContent = {
  create: {
    title: ' Case study under review',
    text: 'Your case study needs to be reviewed by the Spona editorial team before it is published on the platform. You will be notified when the review process is complete.',
    icon: <RequestReviewIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
  edit: {
    title: 'Case study edit under review',
    text: 'Spona will be reviewing the changes to your case study shortly.',
    icon: <RequestReviewIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
  draft: {
    title: 'Save draft',
    text: 'Save your case study as draft to continue editing it later.',
    icon: <SaveIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
  error: {
    title: 'Error',
    text: 'Something went wrong. Please try again later.',
    icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryPink[100],
  },
  cancel: {
    title: 'Cancel Form',
    text: 'You are coming out of the creative form. Your recorded data will be lost.',
    icon: <CancelIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
};

type CaseStudiesCreateModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  type: 'create' | 'edit' | 'error' | 'draft' | 'cancel';
  errorMessage?: string;
  onConfirm?: () => void;
};

const CaseStudiesCreateModal = ({
  isOpen,
  setIsOpen,
  type,
  errorMessage,
  onConfirm,
}: CaseStudiesCreateModalProps) => {
  const message = errorMessage || modalsContent[type].text;
  const navigate = useNavigate();
  const confirm = onConfirm || (() => navigate('../'));

  return (
    <ConfirmModal
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      title={modalsContent[type].title}
      message={
        <Typography color="primaryDark.500" lineHeight="21px">
          {message}
        </Typography>
      }
      confirmButtonText={type === 'draft' ? 'Confirm' : type === 'cancel' ? 'Proceed' : 'Close'}
      onConfirm={confirm}
      iconProps={{
        icon: modalsContent[type].icon,
        wrapperBackgroundColor: modalsContent[type].wrapperBackgroundColor,
      }}
      showCancelButton={type === 'draft' || type === 'cancel' }
      onCancel={() => setIsOpen(false)}
    />
  );
};

export default CaseStudiesCreateModal;
