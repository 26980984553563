import { Dispatch, SetStateAction } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RequestReviewIcon } from '../../../../../assets/icons/project-icons/RequestReviewIcon.svg';
import CustomModal from '../../../../../components/modal/CustomModal';
import CustomButton from '../../../../../components/button/CustomButton';
import { secondaryBlue } from '../../../../../color';

type ConfirmModalProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

const ConfirmCaseStudyReviewModal = ({ setIsOpen, isOpen }: ConfirmModalProps) => {
  const navigate = useNavigate();
  return (
    <CustomModal open={isOpen} onClose={() => setIsOpen(false)} width="35%">
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 'fit-content',
              height: 'fit-content',
              borderRadius: '6px',
              p: 2,
            }}
          >
            <RequestReviewIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
          </Stack>
          <Typography variant="heading3" color="primaryDark.600">
            Review submitted
          </Typography>
        </Stack>
        <Divider />
        <Typography color="primaryDark.500">
          Thank you for submitting your case study review. After Spona verifies your identity, your
          review will be published on the platform.
        </Typography>
        <Divider />
        <Stack sx={{ justifyContent: 'end' }} spacing={2} direction="row">
          <CustomButton
            variant="primary"
            onClick={() => {
              navigate(-1);
              setIsOpen(false);
            }}
          >
            Close
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ConfirmCaseStudyReviewModal;
