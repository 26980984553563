import React from 'react';
import { Stack } from '@mui/material';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import ChatMessageAttachment from './ChatMessageAttachment';

type ChatMessageAttachmentListProps = {
  attachments: AttachmentDto[];
};

export default ({ attachments }: ChatMessageAttachmentListProps) => (
  <Stack direction="column" spacing={1}>
    {attachments.map((attachment) => (
      <ChatMessageAttachment attachment={attachment} />
    ))}
  </Stack>
);
