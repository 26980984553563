import React from 'react';
import ContainedDrawer from './ContainedDrawer';
import AttachmentInfoPane from './AttachmentInfoPane';
import { useChatStore } from './useChatStore';

export default () => {
  const [selectedAttachment, selectAttachment] = useChatStore((state) => [
    state.selectedAttachment,
    state.selectAttachment,
  ]);
  return (
    <ContainedDrawer
      onClose={() => {
        selectAttachment(null);
      }}
      open={!!selectedAttachment}
      anchor="right"
    >
      <AttachmentInfoPane attachment={selectedAttachment} />
    </ContainedDrawer>
  );
};
