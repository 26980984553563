import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import {
  CompanyTeamMemberDto,
  CreateCompanyTeamMemberDto,
} from 'tdc-web-backend/company-team-member/schemas';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight, secondaryBlue } from '../../../../../color';
import CustomInput from '../../../../../components/custom-input/CustomInput';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';
import AvatarEditorField from '../../../../../components/custom-inputs/AvatarEditorField';

interface TeamCreateAndEditModalProps {
  type: 'create' | 'edit';
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
  teamMemberId: string | null;
  setTeamMemberId: Dispatch<SetStateAction<string | null>>;
  setForceAvatarRemount: Dispatch<SetStateAction<number>>;
}

const defaultValues = {
  name: '',
  jobTitle: '',
  avatar: '',
};

const TeamCreateAndEditModal = ({
  type,
  isEditModalOpen,
  setIsEditModalOpen,
  teamMemberId,
  setTeamMemberId,
  setForceAvatarRemount,
}: TeamCreateAndEditModalProps) => {
  const refresh = useRefresh();
  const isEdit = teamMemberId && type === 'edit';
  const cropRef = useRef<any>(null);

  const { data: getTeamMember } = useGetOne<CompanyTeamMemberDto>({
    enabled: !!isEdit,
    resource: `company-team-member/${teamMemberId}`,
  });

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { mutate: createTeamMember, isLoading: isCreateLoading } = useCreate({
    resource: 'company-team-member',
  });

  const { mutate: updateTeamMember, isLoading: isUpdateLoading } = useUpdate({
    resource: 'company-team-member',
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (isEdit) reset({ ...getTeamMember?.data });
  }, [teamMemberId, type, getTeamMember]);

  const onSubmit = (data: Partial<CreateCompanyTeamMemberDto>) => {
    const dataUrl = cropRef?.current?.getImage().toDataURL();

    if (!isEdit) {
      createTeamMember(
        { ...data, avatar: dataUrl },
        {
          onSuccess: () => {
            refresh();
            setTeamMemberId(null);
            setForceAvatarRemount((prev) => prev + 1);
            setIsEditModalOpen(false);
          },
        },
      );
    } else {
      updateTeamMember(
        { data: { ...data, avatar: dataUrl }, id: teamMemberId },
        {
          onSuccess: () => {
            refresh();
            setTeamMemberId(null);
            setForceAvatarRemount((prev) => prev + 1);
            setIsEditModalOpen(false);
          },
        },
      );
    }
  };

  return (
    <ConfirmModal
      width="40%"
      title={`${isEdit ? 'Edit' : 'Add'} team member`}
      confirmButtonText={`${isEdit ? 'Save' : 'Add'}`}
      isLoading={isCreateLoading || isUpdateLoading}
      onCancel={() => setTeamMemberId(null)}
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      automaticallyCloseModalOnButtonClick={false}
      onConfirm={handleSubmit(onSubmit)}
      iconProps={{
        icon: <PersonIcon sx={{ color: secondaryBlue[700] }} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      message={
        <FormProvider {...formMethods}>
          <Stack spacing={6} sx={{ height: '380px', overflowY: 'auto', pr: 1 }}>
            <AvatarEditorField
              cropRef={cropRef}
              avatar={getTeamMember?.data.avatar ?? ''}
              withOpenFileExplorerButton
            />

            <CustomInput
              required
              name="name"
              input={<CustomInput.Text />}
              placeholder="Enter full name"
              label="Full name"
              sx={{ minHeight: 'none' }}
            />

            <CustomInput
              required
              name="jobTitle"
              input={<CustomInput.Text />}
              placeholder="Enter job title"
              label="Job title"
              sx={{ minHeight: 'none' }}
            />
          </Stack>
        </FormProvider>
      }
    />
  );
};

export default TeamCreateAndEditModal;
