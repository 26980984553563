import { Controller, useFormContext } from 'react-hook-form';
import Dropzone, { DropzoneInputProps, DropzoneProps, DropzoneRootProps } from 'react-dropzone';
import { Card, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { formatBytes } from '../../../utils/helpers';
import { ReactComponent as FileUploadSvg } from '../../../assets/icons/project-icons/FileUploadSvg.svg';
import { primaryDark } from '../../../color';
import CustomDroppableArea from './components/CustomDroppableArea';

type ControlledDropzoneFileInputProps = {
  name: string;
  required?: boolean;
  maxFileSize?: number;
  dropzoneProps?: DropzoneProps;
  dropzoneText?: string;
  dropzoneHighlightText?: string;
  onUpload?: (acceptedFiles: File[], onChange: (...event: any[]) => void) => void;
  sx?: SxProps<Theme>;
  // intended for handling custom circular pickers (logo, avatar, etc...)
  renderCustomDroppableArea?: ({
    getRootProps,
    getInputProps,
  }: {
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  }) => JSX.Element;
  renderCustomDroppableAreaWidth?: string | number;
  renderCustomDroppableAreaHeight?: string | number;
};

const dropzoneStyles: SxProps<Theme> = {
  p: 3,
  flexDirection: 'column',
  textAlign: 'center',
  color: '#5C5F5C',
  border: '1px dashed',
  borderColor: 'primaryDark.300',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'secondaryBlue.50',
    border: '1px solid',
    borderColor: 'secondaryBlue.200',
  },
};

const ControlledDropzoneFileInput = ({
  name,
  required,
  maxFileSize = 10000000,
  onUpload,
  dropzoneProps,
  sx,
  renderCustomDroppableArea,
  renderCustomDroppableAreaWidth,
  renderCustomDroppableAreaHeight,
  dropzoneText,
  dropzoneHighlightText,
}: ControlledDropzoneFileInputProps) => {
  const { control } = useFormContext();
  const [acceptedFiles, setAcceptedFiles] = useState<File[] | null>(null);

  return (
    <Controller
      control={control}
      name={name}
      rules={required ? { required: 'This field is required' } : { required: false }}
      render={({ field }) => (
        <Dropzone
          onDrop={
            onUpload &&
            ((acceptedFiles) => {
              onUpload(acceptedFiles, field.onChange);
              if (renderCustomDroppableArea) {
                setAcceptedFiles(acceptedFiles);
              }
            })
          }
          maxSize={maxFileSize}
          {...dropzoneProps}
        >
          {({ getRootProps, getInputProps }) => {
            if (renderCustomDroppableArea) {
              return (
                <CustomDroppableArea
                  acceptedFiles={acceptedFiles}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  renderCustomDroppableArea={renderCustomDroppableArea}
                  renderCustomDroppableAreaWidth={renderCustomDroppableAreaWidth}
                  renderCustomDroppableAreaHeight={renderCustomDroppableAreaHeight}
                />
              );
            }

            // default droppable area
            return (
              <Card
                {...getRootProps()}
                variant="outlined"
                component="div"
                sx={{ ...dropzoneStyles, ...sx }}
              >
                <input {...getInputProps()} />
                <FileUploadSvg
                  style={{
                    marginRight: '1em',
                    marginBottom: '1em',
                  }}
                  fill={primaryDark[400]}
                />
                <Typography color="primaryDark.400" variant="body3" lineHeight="1.5rem">
                  {dropzoneText || 'Drag & Drop your file here or '}
                  <Typography color="primaryLight.500" component="span" variant="body3">
                    {dropzoneHighlightText || 'upload here'}
                  </Typography>
                  <br />
                  Max. file size {formatBytes(maxFileSize)}
                </Typography>
              </Card>
            );
          }}
        </Dropzone>
      )}
    />
  );
};

export default ControlledDropzoneFileInput;
