import { FormProvider, useForm } from 'react-hook-form';
import { IconButton, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import React, { useReducer, useState } from 'react';
import { CreateMessageDto, MessageDto } from 'tdc-web-backend/messages/schemas';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import axios from '../../../api/axios';
import { ExtendedFile } from './ExtendedFile';
import ChatInputAttachmentList from './ChatInputAttachmentList';
import { ReactComponent as SendIcon } from '../../../assets/icons/chat-icons/send.svg';
import { ReactComponent as AddFileIcon } from '../../../assets/icons/chat-icons/add-file.svg';
import { ReactComponent as EmojiIcon } from '../../../assets/icons/chat-icons/emoji.svg';
import { ReactComponent as MeetingIcon } from '../../../assets/icons/chat-icons/meeting.svg';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import { EventMeetingModal } from '../../Calendar/Modals/EventMeeting';

type ChatInputProps = {
  channelId: string;
};

export default ({ channelId }: ChatInputProps) => {
  const form = useForm({
    defaultValues: {
      message: '',
      // any[] is required because RHF V7 can't handle circular types like File.
      // https://github.com/react-hook-form/react-hook-form/issues/4055
      attachments: [] as any[],
    },
  });

  // Force rerender to update the state of attachment upload progress.
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [eventModalOpen, setEventModalOpen] = useState(false);

  const { mutate } = useCreate<MessageDto, CreateMessageDto>({
    resource: '/messages',
  });

  const sendMessage = async () => {
    const body = form.getValues('message');
    const attachments = form
      .getValues('attachments')
      .map((attachment: ExtendedFile) => attachment.attachmentId);

    if (!body && !attachments.length) {
      return;
    }

    await mutate({
      body,
      channel: channelId,
      attachments,
    });
    form.reset();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    getFilesFromEvent: async (event: any) => {
      const files = [];
      const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        files.push(file);
      }
      return files;
    },
    onDrop: async (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        Object.defineProperty(file, 'progress', { value: 0, writable: true });
        Object.defineProperty(file, 'attachmentId', { value: '', writable: true });
        const extendedFile = file as ExtendedFile;
        form.setValue('attachments', form.getValues('attachments').concat([extendedFile]));
        const formData = new FormData();
        formData.append('file', file);
        axios
          .post<AttachmentDto>('/attachments', formData, {
            onUploadProgress: (progressEvent) => {
              extendedFile.progress = progressEvent.loaded / progressEvent.total;
              forceUpdate();
            },
          })
          .then((attachment) => {
            extendedFile.attachmentId = attachment.data.id;
            forceUpdate();
          });
      }
    },
  });

  return (
    <FormProvider {...form}>
      <Stack {...getRootProps()} px={2} direction="row" alignItems="end">
        <input {...getInputProps()} />
        <Stack direction="row" gap={1} mt="auto" mb="13px" mr={1.5}>
          <IconButton
            sx={{
              borderRadius: '3px',
              p: 0.6,
              backgroundColor: 'secondaryBlue.100',
              height: '24px',
              width: '24x',
            }}
            onClick={open}
          >
            <AddFileIcon />
          </IconButton>
          <IconButton
            sx={{
              borderRadius: '3px',
              p: 0.6,
              backgroundColor: 'secondaryBlue.100',
              height: '24px',
              width: '24x',
            }}
            onClick={() => setEventModalOpen(true)}
          >
            <MeetingIcon />
          </IconButton>
        </Stack>
        <ControlledTextInput
          name="message"
          sx={{
            flex: 1,
            '& .MuiInputBase-root': { flexDirection: 'column', padding: '14px' }, // causes the end adornment to be placed on the bottom the input
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            padding: 0,
          }}
          multiline
          fullWidth
          placeholder="Your message..."
          InputProps={{
            endAdornment: <ChatInputAttachmentList attachments={form.watch('attachments')} />,
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              sendMessage();
              event.preventDefault();
            }
          }}
        />
        <IconButton
          sx={{
            backgroundColor: 'primaryLight.500',
            borderRadius: 0,
            borderTopRightRadius: 4,
            height: 50,
            width: 50,
          }}
          disableRipple
          onClick={sendMessage}
        >
          <SendIcon />
        </IconButton>
        <EventMeetingModal
          onCancel={() => setEventModalOpen(false)}
          open={eventModalOpen}
          meetingChannel={channelId}
        />
      </Stack>
    </FormProvider>
  );
};
