import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, LinkProps, SxProps, Theme } from '@mui/material';

export interface LinkPropsI {
  to: string;
  sx?: SxProps<Theme> | undefined;
}

const Link = ({
  underline,
  color,
  variant,
  children,
  to,
  sx,
  ...props
}: LinkProps & LinkPropsI) => (
  <MuiLink
    component={RouterLink}
    underline={underline ?? 'none'}
    color={color ?? 'primary'}
    variant={variant ?? 'inherit'}
    to={to}
    sx={{ ...sx }}
    {...props}
  >
    {children}
  </MuiLink>
);

export default Link;
