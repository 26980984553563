import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button/CustomButton';

const EmptyCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        overflow: 'hidden',
        backgroundColor: 'common.white',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: 2,
        flex: 1,
      }}
      elevation={0}
    >
      <Stack
        height="100%"
        sx={{
          backgroundColor: 'secondaryBlue.50',
        }}
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Typography
          variant="body3"
          width="60%"
          textAlign="center"
          color="primaryDark.600"
          mt={3}
          mb={2}
        >
          Discover our all-in-one tool for streamlined project management
        </Typography>
        <CustomButton onClick={() => navigate('/how-to-manage-buyer')}>Learn more</CustomButton>
      </Stack>
    </Card>
  );
};

export default EmptyCard;
