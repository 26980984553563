import { Card, Divider, Stack, Typography } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { APPBAR_HEIGHT } from '../../../../../layout/dashboard-navigation/NavigationDashboard';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import ControlledTextEditor from '../../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../../components/editor/menu-option-lists/menu-item-lists';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';
import ControlledRatingInput from '../../../../../components/custom-inputs/ControlledRatingInput';
import ControlledCheckboxInput from '../../../../../components/custom-inputs/ControlledCheckboxInput';
import CustomButton from '../../../../../components/button/CustomButton';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow-right-short.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import { SubmitCaseStudyReviewDto } from 'tdc-web-backend/case-studies-review/schemas';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { formatDateFns } from '../../../../../utils/helpers';
import { useEffect, useState } from 'react';
import ConfirmCaseStudyReviewModal from './ConfirmCaseStudyReviewModal';
import PitchFeedbackInput from '../../../TimedProjects/TimedProjectsDetails/components/PitchFeedbackInput';

const CaseStudyReviewForm = () => {
  const [openModal, setIsOpenModal] = useState(false);
  const form = useForm<SubmitCaseStudyReviewDto>({
    defaultValues: {
      reviewer: {
        firstName: '',
        lastName: '',
        jobTitle: '',
        companyName: '',
        isAnonymous: false,
      },
      summarization: '',
      score: {
        qualityScore: 0,
        qualityText: '',
        budgetScore: 0,
        budgetText: '',
        scheduleScore: 0,
        scheduleText: '',
        recommendationScore: 0,
        recommendationText: '',
      },
      contactNumber: '',
    },
    mode: 'onChange',
  });
  const { caseStudyId, token, contentId } = useParams();
  const navigate = useNavigate();
  const { mutate: submit, isLoading } = useCreate({
    resource: `/case-studies/${caseStudyId}/submit-review?token=${token}`,
  });

  const { data: caseStudyContent } = useReferenceOne<CaseStudyContentDto>({
    resource: `case-studies-content`,
    id: contentId,
    enabled: !!contentId,
  });

  useEffect(() => {
    const reviewer = caseStudyContent?.review?.reviewer;
    form.reset({ reviewer: { ...reviewer } });
  }, [caseStudyContent]);

  const submitReview = (data: SubmitCaseStudyReviewDto) => {
    submit(data, {
      onSuccess: () => {
        navigate(-1);
      },
    });
  };
  return (
    <Stack mt={APPBAR_HEIGHT + 'px'} width={'100%'}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitReview)}>
          <Stack px={'10%'} direction={'row'} width={'100%'} justifyContent={'center'}>
            <ScoreInputs data={caseStudyContent as CaseStudyContentDto} />
            <GeneralInputs isLoading={isLoading} />
          </Stack>
        </form>
      </FormProvider>
      <ConfirmCaseStudyReviewModal isOpen={openModal} setIsOpen={setIsOpenModal} />
    </Stack>
  );
};

export default CaseStudyReviewForm;

const ScoreInputs = ({ data }: { data: CaseStudyContentDto }) => (
  <Stack spacing={1} width={'60%'} sx={{ border: '1px solid', borderColor: 'primaryDark.200' }}>
    <Stack p={4}>
      <Typography variant="heading1" p={1} color={'primaryDark.600'}>
        {data?.title}
      </Typography>
      <Typography variant="body3" p={1} py={3} color={'primaryDark.500'}>
        {formatDateFns(data?.published)}
      </Typography>
    </Stack>
    <Divider />
    <Stack p={4}>
      <ControlledTextInput
        required
        multiline
        minRows={5}
        name="summarization"
        placeholder="type"
        label="quote"
      />
      <Stack py={2}>
        <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
          <BaseInputLabel text="quality" />
          <ControlledRatingInput name="score.qualityScore" required />
        </Stack>
        <Typography variant="body3" pb={2} color={'primaryDark.500'}>
          Summarize your working relationship.
        </Typography>
        <ControlledTextEditor required name="score.qualityText" items={TimedProjectEditorMenu} />
      </Stack>
      <Stack py={2}>
        <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
          <BaseInputLabel text="cost" />
          <ControlledRatingInput name="score.budgetScore" required />
        </Stack>
        <Typography variant="body3" pb={2} color={'primaryDark.500'}>
          Overall value and conformance to cost estimates.
        </Typography>
        <ControlledTextEditor required name="score.budgetText" items={TimedProjectEditorMenu} />
      </Stack>
      <Stack py={2}>
        <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
          <BaseInputLabel text="schedule" />
          <ControlledRatingInput name="score.scheduleScore" required />
        </Stack>
        <Typography variant="body3" pb={2} color={'primaryDark.500'}>
          Ability to meet deadlines and meet agreed on project.
        </Typography>
        <ControlledTextEditor required name="score.scheduleText" items={TimedProjectEditorMenu} />
      </Stack>
      <Stack py={2}>
        <Stack spacing={1} direction={'row'} justifyContent={'space-between'}>
          <BaseInputLabel text="Willingness to refer" />
          <ControlledRatingInput name="score.recommendationScore" required />
        </Stack>
        <Typography variant="body3" pb={2} color={'primaryDark.500'}>
          Willingness to refer colleague to the company.
        </Typography>
        <ControlledTextEditor
          required
          name="score.recommendationText"
          items={TimedProjectEditorMenu}
        />
      </Stack>
    </Stack>
  </Stack>
);

const GeneralInputs = ({ isLoading }: { isLoading: boolean }) => {
  const { formState } = useFormContext();
  return (
    <Stack
      spacing={4}
      width={'40%'}
      sx={{ border: '1px solid', borderColor: 'primaryDark.200' }}
      p={3}
      pt={20}
    >
      <ControlledTextInput
        required
        name="reviewer.firstName"
        placeholder="Add first name"
        label="First name"
      />
      <ControlledTextInput
        required
        name="reviewer.lastName"
        placeholder="Add Last name"
        label="last name"
      />
      <ControlledTextInput
        required
        name="reviewer.jobTitle"
        placeholder="Add job title"
        label="Job title"
      />
      <ControlledTextInput
        required
        name="reviewer.companyName"
        placeholder="Add company name"
        label="Company name"
      />
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'primaryDark.150',
          p: 1,
        }}
      >
        <ControlledCheckboxInput
          name="reviewer.isAnonymous"
          label={
            <Typography ml={1} variant="body3" color="primaryDark.600">
              I prefer to remain anonymous on the public review
            </Typography>
          }
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'primaryDark.200',
          p: 2,
        }}
      >
        <Typography ml={1} variant="body3" color="primaryDark.500">
          Please make sure that this information is correct, as it will be displayed publicly
          alongside your review. If you prefer all your details to remain hidden, please check the
          box above.
        </Typography>
      </Card>
      <CustomButton
        disabled={!formState.isValid}
        loading={isLoading}
        type="submit"
        endIcon={<ArrowRight fill="white" />}
      >
        Submit review
      </CustomButton>
    </Stack>
  );
};
