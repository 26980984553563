import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import CustomButton from '../button/CustomButton';
import CookiesModal from './CookiesModal';
import { updateGoogleAnalytics } from '../../utils/helpers';
import { useEffect } from 'react';

interface CookiesBannerProps {
  bannerOpen: boolean;
  setBannerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CookiesBanner = ({ bannerOpen = false, setBannerOpen }: CookiesBannerProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const gaConsent = localStorage.getItem('ga-consent');
    if (gaConsent === null) {
      setBannerOpen(true);
    }
  }, []);

  const closeBanner = () => {
    setBannerOpen(false);
  };

  const handleAcceptAll = () => {
    localStorage.setItem('ga-consent', 'accepted');
    updateGoogleAnalytics(true);
    closeBanner();
  };

  const handleRejectAll = () => {
    localStorage.setItem('ga-consent', 'declined');
    updateGoogleAnalytics(false);
    closeBanner();
  };

  return (
    <Box>
      <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              background: 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              py: 3,
              px: 15,
              borderWidth: 0,
              borderTopWidth: 1,
              zIndex: 10,
            }}
          >
            <Stack direction={'column'} justifyContent="space-between" gap={2}>
              <Stack
                sx={{
                  flexShrink: 1,
                  alignSelf: 'flex-start',
                }}
                spacing={1}
              >
                <Typography sx={{ lineHeight: '22px' }} variant="headline7">
                  Cookie policy
                </Typography>
                <Typography variant="body4" sx={{ fontWeight: 600 }}>
                  We are using cookies to give you the best experience on our website. <br /> You
                  can find out more about which cookies we are using or switch them off in
                  <CustomButton
                    sx={{
                      fontFamily: 'Gellix-Regular',
                      fontSize: 12,
                      fontWeight: 550,
                      lineHeight: '1rem',
                      textDecoration: 'underline',
                      textTransform: 'lowercase',
                      verticalAlign: 'middle',
                      padding: '2px 4px',
                    }}
                    variant="secondaryText"
                    onClick={() => setIsModalOpen(true)}
                  >
                    settings
                  </CustomButton>
                </Typography>
              </Stack>
              <Stack
                gap={2}
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                sx={{
                  flexShrink: 0,
                  alignSelf: 'flex-start',
                }}
              >
                <CustomButton variant="secondary" onClick={handleAcceptAll}>
                  Allow all
                </CustomButton>
                <CustomButton
                  sx={{
                    fontWeight: 550,
                    lineHeight: '1.313rem',
                    whiteSpace: 'nowrap',
                  }}
                  variant="secondary"
                  onClick={handleRejectAll}
                >
                  Accept only necessary
                </CustomButton>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
      <CookiesModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setBannerOpen={setBannerOpen}
      />
    </Box>
  );
};
export default CookiesBanner;
