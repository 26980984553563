import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { CompanyDto, UpdateCompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyApprovalStatus } from 'tdc-web-backend/enums/enums';
import Button from '../../../../components/button/Button';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

export interface CompanyModalProps {
  company: CompanyDto;
}

const ApproveCompanyModal = ({ company }: CompanyModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: approveCompany } = useUpdate<CompanyDto, UpdateCompanyDto>({
    resource: '/companies',
  });
  const refresh = useRefresh();

  const handleCompanyApproval = () => {
    approveCompany(
      {
        data: {
          status: CompanyApprovalStatus.Approved,
        },
        id: company.id,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Button
        onButtonClick={() => setIsOpen(true)}
        setBorderRadius
        color="secondary"
        variant="contained"
        startIcon={<AddTaskIcon />}
      >
        Approve
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Approve company
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Are you sure you want to approve company: {company?.name}?
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onButtonClick={() => handleCompanyApproval()}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};
export default ApproveCompanyModal;
