import { Box, ClickAwayListener, Drawer, DrawerProps } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';

export type ContainedDrawerProps = {
  children: React.ReactElement;
  onClose: () => void;
} & DrawerProps;

/*
  A drawer that is positioned within the parent element instead of floating over the entire page.
  The parent element should have `position: relative`.
 */
export default ({ children, onClose, ...props }: ContainedDrawerProps) => {
  const boxRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={boxRef}
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        position: 'absolute',
        overflow: 'hidden',
      }}
    >
      <Drawer
        PaperProps={{
          sx: {
            pointerEvents: 'initial',
            position: 'absolute',
            zIndex: 'auto',
            boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
          },
        }}
        ModalProps={{
          container: boxRef.current,
          sx: { position: 'absolute' },
        }}
        variant="persistent"
        hideBackdrop
        {...props}
      >
        {props.open ? (
          <ClickAwayListener
            onClickAway={() => {
              onClose();
            }}
          >
            <Box>{children}</Box>
          </ClickAwayListener>
        ) : (
          <Box>{children}</Box>
        )}
      </Drawer>
    </div>
  );
};
