/* eslint-disable max-len */
import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const TaskDetailScreenJoyrideSteps = (userBuyer: boolean): Step[] => [
  {
    content: userBuyer ? (
      <Typography>
        Here you can check task status. Once all subtasks have been completed,
        the progress will be updated accordingly.

      </Typography>
    ) : (
      <Typography>

        Here you can check your task status. Once you have completed all subtasks, the progress will be updated accordingly.
        You can also change the status manually by clicking the status and changing it to Complete.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'left',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-1-task',
    title: 'Task status',
  },
  {
    content: (
      <Typography>
        If you come across an issue with the task,
        click flag task to alert your agency and resolve the problem as quickly as possible.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-2-task',
    title: 'Flag task',
  },
  {
    content: userBuyer ? (
      <Typography>

        Click the Delete button to permanently delete the task.
        Your agency will be notified of this action.

      </Typography>
    ) : (
      <Typography>

        Click the Delete button to permanently delete the task.
        Your client will be notified of this action.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-3-task',
    title: 'Delete task',
  },
  {
    content: (
      <Typography>

        Click the Edit button to change the task description, name and schedule.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,

    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-4-task',
    title: 'Edit task',
  },
  {
    content: (
      <Typography>

        View all subtasks within a task and filter them by a particular status.
        For example, click on To do to see only subtasks that haven't been started yet.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,

    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-5-task',
    title: 'Subtasks overview',
  },
  {
    content: (
      <Typography>
        Click the Add subtask button to create a subtask - add a name and end date and press the Confirm button.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,

    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-6-task',
    title: 'Add subtask',
  },
  {
    content: userBuyer ? (
      <Typography>
        Once a subtask has been completed, this checkmark will be marked green.
        The overall task progress will be updated accordingly.

      </Typography>
    ) : (
      <Typography>
        Click on the checkmark icon to mark your subtask as complete.
        The overall task progress will be updated accordingly.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,

    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-7-task',
    title: 'Finish subtask',
  },
  {
    content: (
      <Typography>

        Clicking the action button gets you access to multiple useful actions,
        such as flag, edit and delete subtask.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,

    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-8-task',
    title: 'Subtask actions',
  },
];

export default TaskDetailScreenJoyrideSteps;
