import { useState, ChangeEvent } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as CancelCircleIcon } from 'tdc-web-frontend/src/assets/icons/project-icons/CancelCircleIcon.svg';
import BaseTextInput from '../../../../components/custom-inputs/BaseTextInput';
import CustomButton from '../../../../components/button/CustomButton';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';

const initialState = { name: '', description: '' };
type TCompetitors = (typeof initialState)[] | [];

const Competitors = () => {
  // Use 'watch' to trigger re-render upon change
  const { setValue: setFormValue, watch } = useFormContext();
  const [values, setValues] = useState(initialState);
  const [error, setError] = useState(false);
  const competitors: TCompetitors = watch('about.competitors');
  const hasCompetitors = !!competitors?.length;

  const onAddCompetitor = () => {
    if (values.name) {
      setFormValue('about.competitors', [...competitors, values]);
      setValues(initialState);
    } else {
      setError(true);
    }
  };

  const onChange = (
    name: string,
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    required?: boolean,
  ) => {
    const { value } = e.target;
    const showError = required && !value.length;
    const hideError = required && value.length;

    setValues({ ...values, [name]: value });
    if (showError) setError(true);
    if (hideError) setError(false);
  };

  return (
    <Box style={{ display: 'flex', maxHeight: '350px' }}>
      <Box style={{ flex: 1, paddingRight: '24px' }}>
        <BaseInputLabel sx={{ marginBottom: '12px' }} text="Client competitors" />
        <BaseTextInput
          sx={{
            marginBottom: error ? '6px' : '12px',
          }}
          name="about.competitors.name"
          placeholder="Company name"
          value={values.name}
          onChange={(e) => onChange('name', e, true)}
        />
        {error ? (
          <Typography
            color="secondaryPink.500"
            variant="body4"
            sx={{ display: 'block', marginBottom: '24px' }}
          >
            This field is required
          </Typography>
        ) : null}
        <BaseTextInput
          name="about.competitors.description"
          placeholder="Company description"
          value={values.description}
          onChange={(e) => onChange('description', e)}
          multiline
          rows={3}
        />
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton sx={{ margin: '12px 0', '&:hover': { textDecoration: 'none' } }} variant="primaryText" onClick={onAddCompetitor}>
            Add
          </CustomButton>
        </Box>
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: '#E5E8FF' }} flexItem />
      <Box style={{ flex: 1, padding: '0 24px', overflowY: 'scroll' }}>
        {hasCompetitors &&
          competitors.map((competitor) => (
            <Box sx={{ margin: '0 0 28px 0' }} key={competitor.name}>
              <Box sx={{ position: 'relative' }}>
                <Typography
                  variant="body2"
                  color="primaryDark.600"
                  sx={{
                    fontSize: '15px',
                    fontFamily: 'Gellix-SemiBold',
                    margin: '0 0 4px 0',
                    padding: '0 20px 0 0',
                  }}
                >
                  {competitor.name}
                </Typography>
                <CancelCircleIcon
                  onClick={() =>
                    setFormValue(
                      'about.competitors',
                      competitors.filter((c) => c.name !== competitor.name),
                    )
                  }
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    fill: '#A5AAB9',
                    cursor: 'pointer',
                  }}
                />
              </Box>
              <Typography variant="body1" color="primaryDark.600" sx={{ fontSize: '14px' }}>
                {competitor.description}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Competitors;
