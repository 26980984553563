import { Typography, Stack } from '@mui/material';
import { TermOfServiceI, fakeLinkStyles } from '../utils';
import CustomList from '../components/CustomList';

const termsOfService: TermOfServiceI[] = [
  {
    title: '1. Terms of Use Agreement',
    introduction:
      'This page explains the terms for using Spona services (“Spona” “we,” or “us”) software, website, online services, and any other software provided on or in connection with the Spona (collectively called the “Service”), so please read these terms of use (this “Agreement”) carefully before using the Service. If you have any questions, feel free to contact us.',
    content: [
      {
        title: 'Acceptance of the Terms of Use',
        paragraphs: [
          {
            text: () =>
              'Read this agreement carefully before browsing the website or otherwise using the service. Using any part of the service indicates that you have both read and accepted these terms. You cannot use the website or our services if you do not accept these terms.',
          },
          {
            text: () =>
              'By using the Service or otherwise accessing the Service, creating or registering an Account, downloading content or other information, you agree that you are at the age of lawful capacity to form binding legal contracts in your jurisdiction of residence; provided that, in no event, will you be permitted to use the Service if you are under the age of 18.',
          },
          {
            text: () =>
              'Since Spona is a B2B platform you agree to use the Service on behalf of a company or other entity. The use of the term “You”, “Your” , or “User” within these Terms includes you as an individual and company or entity you are representing, and you represent and warrant that you are an authorized representative of the entity with the authority to bind the entity to this Agreement, and you agree to this Agreement on the entity’s behalf.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                You also consent to the collection and use of your information as set forth in the
                Spona{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[1]()}
                >
                  privacy policy
                </Typography>
                , whether or not you are an approved or registered user of our service.
              </Typography>
            ),
          },
          {
            text: () =>
              'In case of any conflict between this Agreement and any individual agreement concluded between Spona and you, the terms of the individual agreement shall prevail.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                These terms also include{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[2]()}
                >
                  Digital Contract Terms{' '}
                </Typography>
                and other policies displayed by Spona or provided to you from time to time.
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '1.1. Services',
        paragraphs: [
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                This Agreement applies to{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[4]()}
                >
                  www.spona.io
                </Typography>
                , Spona-related sites, communications and other services that state that they are
                offered under this Agreement (“Services”), including the offsite collection of data
                for those Services, such as our ads and plugins. This Agreement applies to both
                registered Members and Visitors both referred to as Users.{' '}
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '1.2. Spona',
        paragraphs: [
          {
            text: () =>
              'You are entering into this Agreement with Top Digital Agency Limited, a company incorporated under the laws of Ireland with company number 642562 and whose registered office is at First Floor Offices, 6B Raven Terrace, Galway, H91 H24H, Ireland, and its subsidiary Top Digital d.o.o., Vladimira Nazora 23, Zabok, Croatia (Spona Croatia), collectively referred herein as “Spona” “we” or “us”.',
          },
        ],
      },
      {
        subtitle: '1.3 What Do We Do?',
        paragraphs: [
          {
            text: () =>
              'Spona is a global platform where Digital Service Providers (herein referred to as “Sellers”) and their Clients (herein referred to as “Buyers”) can identify each other, advertise and sell services online as well as manage their digital projects.',
          },
          {
            text: () =>
              'Spona makes the site and Services available to enable Sellers and Buyers to find each other, arrange the provision of digital services and manage their projects.',
          },
          {
            text: () => (
              <Typography variant="publicBody5Regular" color="common.black">
                On the Spona site{' '}
                <Typography variant="publicBody5Regular" color="common.black" fontWeight="bold">
                  Buyers
                </Typography>{' '}
                who need work completed can post a project on our platform. Through the Site and
                Site Services,{' '}
                <Typography variant="publicBody5Regular" color="common.black" fontWeight="bold">
                  Sellers
                </Typography>{' '}
                may be notified of Buyers that may be seeking the services they offer, and Buyers
                may be notified of Sellers that may offer the services they seek.
              </Typography>
            ),
          },
          {
            text: () =>
              'Sellers registered on our platform can then pitch for the projects if they meet the minimum requirements to do so.',
          },
          {
            text: () =>
              'Using our End to End Project Management Tool Buyers can sign Digital Contracts, centralize their projects through one platform, and get easy insight and prompt management all with just one tool.',
          },
          {
            text: () =>
              'Spona facilitates all payments between Buyers and Sellers and all payments must be processed through Spona.',
          },
        ],
      },
      {
        subtitle: '1.4. What Do We NOT Do?',
        paragraphs: [
          {
            text: () =>
              'Spona does not select projects for Sellers or, select Sellers for Buyers. You are responsible for evaluating and determining the suitability of any project, Buyer, Seller, information or connection made on the Spona platform.',
          },
          {
            text: () =>
              'Spona does not make any representation (either express or implied) as to the suitability, reliability, capability, or qualifications of the Sellers.',
          },
          {
            text: () =>
              'We do not negotiate contracts, statements of work, service agreements or other final project details and specifications. This is a matter for Users to negotiate amongst themselves, and Digital Contract Service is merely a contract drafting tool with pre-built contract fields leaving the parties the complete freedom to decide on the milestones, time frame, budget allocation and jurisdiction.',
          },
          {
            text: () =>
              'We do not participate in the execution of work on your Digital Project. We are not a party to nor do we have any liability with respect to any agreement between the Users.',
          },
          {
            text: () => 'Spona is not an agent for Buyers or Sellers.',
          },
        ],
      },
      {
        subtitle: '1.4. Digital Contract',
        paragraphs: [
          {
            text: () =>
              'If you decide to enter into a Digital Contract or any type of  Service or other agreement that you find appropriate with another User of Spona, Spona is not a party to that agreement.  You may not, by agreement with another User of Spona, alter the rights or responsibilities of these terms, or agree to any terms that would violate any of these terms.',
          },
          {
            text: () =>
              'You acknowledge and agree that Users have discretion whether to contract with each other and will negotiate and determine the specific terms of their Digital Contracts with each other. If you decide to enter into a Digital Contract with another User using the Spona Digital Contract service, Digital Contract Terms will apply.',
          },
        ],
      },
    ],
  },
  {
    title: '2. Obligations',
    content: [
      {
        subtitle: '2.1 Your Account',
        paragraphs: [
          {
            text: () =>
              'You will keep your password a secret. You will not share an account with anyone else outside of an Authorized Member of the company affiliated with the account and will follow our rules and the law.',
          },
          {
            text: () =>
              'You agree to (1) try to choose a strong and secure password; (2) keep your password secure and confidential; (3) not transfer any part of your account (e.g. connections) and (4) follow the law and our list of Dos and Don’ts and Professional Community Policies. You are responsible for anything that happens through your account unless you close it or report misuse.',
          },
        ],
      },
    ],
  },
  {
    title: '2.2. Payment',
    introduction:
      'You’ll honor your payment obligations toward Spona and the selected Seller and you are okay with us storing your payment information. You understand that there may be fees and taxes that are added to our prices. We don’t guarantee refunds.',
    content: [
      {
        subtitle: 'Payment Method',
        paragraphs: [
          {
            text: () =>
              'If you enter into a business relationship with another User, you agree that the budget for the Services provided regarding the project published on the Spona platform shall be managed by Spona according to the Payment Schedule agreed between you and the other User you entered a business relationship with. Users set the budget and budget allocations according to their agreement.',
          },
        ],
      },
      {
        subtitle: 'Payment Schedule',
        paragraphs: [
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                You and the other User you entered a business relationship with will agree on the
                scope of work, payment schedule and milestones, if any, depending on the type of
                service being provided. Spona shall disburse the funds to the selected Seller as set
                forth in the payment schedule you agreed. In case your business relationship is
                based on Digital Contract entered into through the Spona platform, the{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[2]()}
                >
                  Digital Contract Terms{' '}
                </Typography>
                shall be applied.
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: 'Premium Services',
        paragraphs: [
          {
            text: () =>
              'Spona premium Services allow paying users to use our End-to-End project management tool for their ongoing projects, connect a Buyer or Seller of choice and enable smoother communication and collaboration.',
          },
          {
            text: () =>
              'We sell premium Services that provide our customers functionality tools, support, project and account management, as part of our business solutions. Customers can export the project reports from their profile and use them for various reporting needs. Premium Services fees are calculated on a project basis and depend on the type and size of the project.',
          },
        ],
      },
      {
        subtitle: 'Spona Fee Structure',
        paragraphs: [
          {
            text: (onClick) => (
              <Stack spacing={2}>
                <Typography variant="publicBody5Regular" color="common.black">
                  The following Fees are payable to Spona per Digital Project:
                </Typography>

                {CustomList({ listType: 'number', items: ['Buyers'], bold: true })}

                <Typography variant="publicBody5Regular" color="common.black">
                  Buyers bear a payment processing and administration fee depending on the type of
                  Service the Buyer chooses and can be either 3% for Self-Service or 5% for Managed
                  Service. This fee is calculated on top of the agreed milestone budget allocation
                  and charged on each invoice, ensuring a seamless flow.
                </Typography>

                <Stack>
                  <Typography variant="publicBody5" color="common.black">
                    Self-Service includes:
                  </Typography>

                  {CustomList({
                    listType: 'dot',
                    items: [
                      'Posting unlimited pitches',
                      'Agency evaluation tool',
                      'Built-in agency selection tool',
                      'Customer support services',
                    ],
                  })}
                </Stack>

                <Stack>
                  <Typography variant="publicBody5" color="common.black">
                    In addition to Self-Service, Managed Service includes:
                  </Typography>

                  {CustomList({
                    listType: 'dot',
                    items: [
                      'Dedicated account manager',
                      'Pitch analyses',
                      'Project proposal creation',
                      'Tailored agency outreach',
                      '1:1 briefing sessions with shortlisted agencies',
                      'Budget negotiation',
                      'Contract management support',
                      'Payment and invoicing support',
                      'Communications management',
                    ],
                  })}
                </Stack>

                {CustomList({
                  listType: 'number',
                  items: ['2. Sellers'],
                  bold: true,
                  omitIndex: true,
                })}

                <Typography variant="publicBody5Regular" color="common.black">
                  The Seller is charged a 10% fee calculated from the total project budget. This fee
                  is deducted from each payment Spona is distributing to the Seller account.
                </Typography>

                <Typography variant="publicBody5Regular" color="common.black">
                  Spona Fees for a Project shall be payable irrespective of whether a Digital
                  Project is completed to the Buyer’s satisfaction or not.
                </Typography>
              </Stack>
            ),
          },
        ],
      },
    ],
  },
  {
    title: '2.3 Notices and Messages',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'You’re okay with us providing notices and messages to you through our websites and contact information. If your contact information is out of date, you may miss out on important notices.',
          },
          {
            text: () =>
              'You agree that we will provide notices and messages to you in the following ways (1) within the Service, or (2) sent to the contact information you provided us (e.g. email, mobile number, physical address). You agree to keep your contact information up to date.',
          },
          {
            text: () => 'Please review your settings to limit the messages you receive from us.',
          },
        ],
      },
    ],
  },
  {
    title: '2.4 Sharing',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'When you share information on our Services, others can see, copy, and use that information.',
          },
          {
            text: () =>
              'Our Services allow sharing of information in many ways, such as (1) your profile, links to news articles, case studies, and blogs, and (2) service requests for organizations seeking services.',
          },
          {
            text: () =>
              'Information and content that you share or post may be seen by other Members, Visitors, or others (including off the Services). Where we have made settings available, we will honor the choices you make about who can see content or information.',
          },
          {
            text: () =>
              'We are not obligated to publish any information or content on our Service and can remove it at our sole discretion, with or without notice.',
          },
        ],
      },
    ],
  },
  {
    title: '3. Rights and Limits',
    content: [
      {
        subtitle: '3.1. Your License to Spona',
        paragraphs: [
          {
            text: () =>
              'You own all of the content, feedback, and personal information you provide to us, but you also grant us a non-exclusive license to it. We’ll honor the choices you make about who gets to see your information and content, including how it can be used for ads.',
          },
          {
            text: () =>
              'As between you and Spona, you own the content and information that you submit or post to the Services, and you are only granting Spona and our affiliates the following non-exclusive license: A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish, and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:',
          },
          {
            text: (onClick) => (
              <>
                {CustomList({
                  listType: 'number',
                  items: [
                    'You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (i) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (ii) for the reasonable time it takes to remove from backup and other systems.',
                    <Typography variant="publicBody5Regular" color="common.black">
                      We will not include your content in advertisements for the products and
                      services of third parties to others without your separate consent (including
                      sponsored content). However, we have the right, without payment to you or
                      others, to serve ads near your content and information, and your actions may
                      be visible and included with ads, as noted in the{' '}
                      <Typography
                        variant="publicBody5Regular"
                        color="common.black"
                        sx={{ ...fakeLinkStyles }}
                        onClick={() => onClick?.[1]()}
                      >
                        Privacy Policy.
                      </Typography>
                    </Typography>,
                    'We will get your consent if we want to give others the right to publish your content beyond the Services. However, if you post content we will enable a feature that allows other Users to view that content, and we enable search engines to make that public content findable through their services.',
                    'While we may edit and make format changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not materially modify the meaning of your expression.',
                  ],
                })}
              </>
            ),
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                You and Spona agree that if the content includes personal data, it is subject to our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[1]()}
                >
                  Privacy Policy.
                </Typography>
              </Typography>
            ),
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                You and Spona agree that we may access, store, process and use any information and
                personal data that you provide in accordance with the terms of the{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[1]()}
                >
                  Privacy Policy{' '}
                </Typography>
                and your choices.
              </Typography>
            ),
          },
          {
            text: () =>
              'By submitting suggestions or other feedback regarding our Services to Spona, you agree that Spona can use and share (but does not have to) such feedback for any purpose without compensation to you.',
          },
          {
            text: () =>
              'You promise to only provide information and content that you have the right to share, and that your Spona profile will be truthful.',
          },
          {
            text: () =>
              'You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information and other use of the Services will be truthful. Spona may be required by law to remove certain information or content in certain countries.',
          },
        ],
      },
      {
        subtitle: '3.2 Service Availability',
        paragraphs: [
          {
            text: () =>
              'We may change, suspend or end any Service, or change and modify prices prospectively at our discretion. To the extent allowed under law, these changes may be effective upon notice provided to you.',
          },
          {
            text: () =>
              'We may change or discontinue any of our Services. We don’t promise to store or keep showing any information and content that you’ve posted.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                Spona is not a storage service. You agree that we have no obligation to store,
                maintain or provide you a copy of any content or information that you or others
                provide, except to the extent required by applicable law and as noted in our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[1]()}
                >
                  Privacy Policy.
                </Typography>
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '3.3 No Professional Advice',
        paragraphs: [
          {
            text: () =>
              'If the Service provides professional information (for example, professional referrals, tutorials, guides, especially contract drafting through Digital Contract Service, etc.), such information is for informational purposes only and should not be construed as professional advice.',
          },
          {
            text: () =>
              'No action should be taken based upon any information contained in the Service. You should seek independent professional advice from a person who is qualified in the applicable area.',
          },
        ],
      },
      {
        subtitle: '3.4 Other Content, Sites and Apps',
        paragraphs: [
          {
            text: () =>
              'Your use of others’ content and information posted on our Services is at your own risk. Others may offer their own products and services through our Services, and we aren’t responsible for those third-party activities.',
          },
          {
            text: () =>
              'By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive, or otherwise harmful. You agree that we are not responsible for others (including other Members’) content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or your organization may be mistakenly associated with content about others when we let connections and followers know you or your organization were mentioned in the news.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                You are responsible for deciding if you want to access or use third-party apps or
                sites that link from our Services. If you allow a third-party app or site to
                authenticate you or connect with your Spona account, that app or site can access
                information on Spona related to you and your connections. Third-party apps and sites
                have their own legal terms and privacy policies, and you may be giving others
                permission to use your information in ways we would not. Except to the limited
                extent it may be required by applicable law, Spona is not responsible for these
                other sites and apps – use these at your own risk. Please see our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[1]()}
                >
                  Privacy Policy.
                </Typography>
              </Typography>
            ),
          },
        ],
      },
      {
        subtitle: '3.5 Limits',
        paragraphs: [
          {
            text: () => 'We have the right to limit how you use our Services.',
          },
          {
            text: () =>
              'Spona reserves the right to limit your use of the Services and your ability to post content. Spona reserves the right to restrict, suspend, or terminate your account if Spona believes that you may be in breach of this Contract or law or are misusing the Services (e.g. violating any of the Dos and Don’ts or Professional Community Policies).',
          },
        ],
      },
      {
        subtitle: '3.6 Intellectual Property Rights',
        paragraphs: [
          {
            text: () => 'We’re providing you with notice about our intellectual property rights.',
          },
          {
            text: () =>
              'Spona owns all of the intellectual property rights to the Services, including but not limited to: all features, graphics, design, code, Digital Contract Services, the compilation of User Content, the Website, and all other components of the Services. Using the Services does not give you any ownership of our Services or the content or information made available through our Services. You agree not to take any action(s) inconsistent with such ownership interests.',
          },
          {
            text: () =>
              'Trademarks and logos used in connection with the Services are the trademarks of their respective owners. Spona and other Spona trademarks, service marks, graphics, and logos used for our Services are trademarks or registered trademarks of Spona.',
          },
          {
            text: () =>
              'Spona respects the intellectual property rights of others and expects users of the Services to do the same. ',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                We will respond to notices of alleged copyright infringement that comply with
                applicable law and are properly provided to us. If you believe that User Content has
                been copied in a way that constitutes copyright infringement, please let us know by
                using this{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[5]()}
                >
                  contact form.
                </Typography>
              </Typography>
            ),
          },
          {
            text: () =>
              'We reserve the right to remove User content alleged to be infringing or otherwise illegal without prior notice and at our sole discretion. We also reserve the right to terminate a user’s account if the user is determined to be a repeat infringer.',
          },
        ],
      },
      {
        subtitle: '3.7 Automated Processing',
        paragraphs: [
          {
            text: () =>
              'We use data and information about you to make relevant suggestions to you and others. We will use the information and data that you provide and that we have about Users to improve content and features that may be useful to you. For example, we use verified client reviews to enable digital service Sellers to demonstrate areas in which they have expertise.',
          },
          {
            text: () =>
              'Keeping your profile accurate and up-to-date helps us to make these recommendations more accurate and relevant.',
          },
        ],
      },
    ],
  },
  {
    title: '4. Referral and Non-Circumvention',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'To ensure the effective operation of the Service, Users agree that when they are introduced and connected through the Spona platform, they are required to adhere to our payment processes and terms.',
          },
          {
            text: () =>
              'Starting from the moment of User registration and extending for a period of twelve (12) months following the termination of these Terms, or the suspension or deactivation of the account, Users are not allowed to:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Enter into written/oral agreements to provide services for other Users, User companies, affiliates, or other parties introduced through the Spona platform.',
                    'Initiate or participate in actions such as approaching, soliciting, inducing, or performing services to Users introduced through the Spona platform, except through the designated Spona Service channels, while abiding by the stipulated payment terms, Spona Fees, and other applicable provisions stated in these Terms.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              "Users agree not to exchange services with other Users or parties introduced Spona platform outside the established payment system and interaction framework of the Spona Service. This behavior can result in a permanent ban from Spona, lack of protection from the Service's features, and potential liability for Spona fees, liquidated damages, and other enforcement actions.",
          },
          {
            text: () =>
              'These responsibilities are binding regardless of any prior relationship or previous collaboration between Users and other involved parties.',
          },
          {
            text: () =>
              'Promoting or soliciting off-platform payments is a violation of these Terms. Users must promptly notify Spona if other User approaches to conduct services outside the Service. ',
          },
          {
            text: () =>
              "Additionally, Users pledge to accurately report all financial transactions with introduced parties and promptly inform Spona in writing if services are pursued outside the Service's framework.",
          },
          {
            text: () =>
              'Failing to comply may lead to the enforcement of Spona’s rights under these Terms, potentially resulting in a permanent ban.',
          },
        ],
      },
      {
        subtitle: 'Supplementary Charges',
        paragraphs: [
          {
            text: () =>
              'Users acknowledge that Spona may identify breaches of Fee payment obligations. If such breaches are confirmed, Spona shall invoice Users for liquidated damages as a consequence of the breach. The liquidated damages shall be calculated as Fees equal to twice (2x) the Spona',
          },
          {
            text: () =>
              'Fees payable that you would otherwise have been required to pay if you had complied with your Fee payment obligations through the Spona platform.',
          },
          {
            text: () =>
              'After the mentioned twelve (12) month period, Users are free to directly engage parties introduced via the Spona platform, independent of these Terms.',
          },
        ],
      },
    ],
  },
  {
    title: '5. Disclaimer and Limit of Liability',
    content: [
      {
        subtitle: '5.1 No Warranty',
        paragraphs: [
          {
            text: () =>
              'This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.',
          },
          {
            text: () =>
              'Content provided on Spona sites or as part of the Services is not advice from Spona and should not be treated as such. Your use of material on www.spona.io and related Spona sites is entirely at your own risk.',
          },
          {
            text: () =>
              'To the extent allowed under law, Spona and its affiliates (and those that Spona works with to provide the services) (a) Disclaim all implied warranties and representations (e.g. warranties of merchantability, fitness for a particular purpose, the accuracy of data, and noninfringement); (b) Do not guarantee that the services will function without interruption or errors, and (c) Provide the service (Including content and information) on an “as is” and “as available” basis.',
          },
          {
            text: () =>
              'Spona does not represent or warrant that using the services will create any additional profits, sales, exposure, or brand recognition. Spona has no responsibility to the users if the services do not lead to the user’s desired result(s).',
          },
        ],
      },
      {
        subtitle: '5.2 Limitation of Liability',
        paragraphs: [
          {
            text: () => 'These are the limits of legal liability we may have to you.',
          },
          {
            text: () =>
              'Spona and its Affiliates or their licensors, Service Providers, Employees, Agents, Officers and Directors are not liable for damages of any kind, and you agree not to hold us responsible, for any damages or losses arising out of or in connection with the Spona Services including, but not limited to:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Your use or your inability to use Spona or the Spona Services;',
                    'Delays or interruptions in our site or the Spona Services',
                    'Viruses or other malicious software obtained by accessing, or linking to, our site of the Spona Services;',
                    'Your reliance on the quality, accuracy, or reliability of postings, profiles, reviews, articles, service requests, project metrics or any other content made available through Spona.',
                  ],
                })}
              </>
            ),
          },
          {
            text: () =>
              'To the extent permitted under law (and unless Spona has entered into a separate written agreement that overrides this contract), Spona and its affiliates (and those that Spona works with to provide the services) shall not be liable to you or others for any indirect, incidental, special, consequential or punitive damages, or any loss of data, opportunities, reputation, profits or revenues, related to the services (e.g. offensive or defamatory statements, downtime or loss, use of, or changes to, your information or content or related actions).',
          },
          {
            text: () =>
              'In no event shall the liability of Spona and its affiliates (and those that Spona works with to provide the services) exceed, in the aggregate for all claims, an amount that is the lesser of (a) the most recent yearly fee that you paid for a Spona service, if any, or (b) $1000.',
          },
          {
            text: () =>
              'This limitation of liability is part of the basis of the bargain between you and Spona and shall apply to all claims of liability (e.g. warranty, tort, negligence, contract, law) and even if Spona or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.',
          },
        ],
      },
      {
        subtitle: '5.3. Release',
        paragraphs: [
          {
            text: () =>
              'In addition to the recognition that Spona is not a party to any contract between Users, you hereby release Spona, our Affiliates, and our respective officers, directors, agents, subsidiaries, joint ventures, employees and service providers from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with any dispute you have with another User, whether it be at law or in equity that exists as of the time you enter into this agreement. This release includes, for example, and without limitation, any disputes regarding the performance, functions, and quality of work provided by any User.',
          },
        ],
      },
    ],
  },
  {
    title: '6. Termination',
    content: [
      {
        paragraphs: [
          {
            text: (onClick) => (
              <Stack>
                <Stack spacing={1}>
                  <Typography variant="publicBody5Regular" color="common.black">
                    We can each end this Contract anytime we want.
                  </Typography>

                  <Typography variant="publicBody5Regular" color="common.black">
                    Both you and Spona may terminate this Contract at any time with notice to the
                    other. On termination, you lose the right to access or use the Services. The
                    following shall survive termination:
                  </Typography>

                  {CustomList({
                    listType: 'dot',
                    items: [
                      'Our rights to use and disclose your feedback;',
                      'User’s rights to further re-share content and information you shared through the Service to the extent copied or re-shared prior to termination;',
                      'Sections 4,5, 7.2, 8 and 9 of this Contract;',
                      'Any amounts owed by either party prior to termination remain owed after termination.',
                    ],
                  })}
                </Stack>
              </Stack>
            ),
          },
          {
            text: () => (
              <Typography variant="publicBody5Regular" color="common.black">
                You can contact us with any questions or to close your account at this email
                address: legal@spona.io
              </Typography>
            ),
          },
        ],
      },
    ],
  },
  {
    title: '7. Suspension of Services',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'Spona is entitled, in its sole discretion, to suspend your Account or its Services in the event of reasonable grounds if you fail to properly fulfill its obligations under this Agreement. In that case, Spona shall notify you thereof within three (3) Business Days following the suspension.',
          },
          {
            text: () =>
              'If for the purpose of determining the continuation of the Services and your Account you do not provide complete and accurate information in response to such a request, Spona may refuse to restore your access to the Service.',
          },
          {
            text: () =>
              'Spona shall provide you with notice of such action. Spona reserves the right to close, suspend or limit access to the Service in the event Spona is unable to obtain and verify such information for monitoring purposes or in the case of your non-compliance with the obligations, representations and warranties from this Agreement.',
          },
          {
            text: () =>
              'Spona reserves the right to take action, with or without advance notice if Spona believes you have violated this Agreement, including without limitation, disabling the ability to use the Service, disabling your ability to access the Website, and/or other actions.',
          },
          {
            text: () =>
              'Suspension of Services shall be for any reasonable period of time required by Spona to fully investigate your account activity and resolve, to Spona’s sole satisfaction, the subject questionable, suspect or fraudulent transactions or activities.',
          },
          {
            text: () =>
              'The right to extraordinary termination as set herein shall remain unaffected by such suspension.',
          },
        ],
      },
    ],
  },
  {
    title: '8. Governing Law and Dispute Resolution',
    content: [
      {
        paragraphs: [
          {
            text: () =>
              'In the unlikely event that we end up in a legal dispute, Spona and you agree to resolve it in an amicable way.',
          },
          {
            text: () =>
              'You and Spona agree that the laws of Ireland shall exclusively govern any dispute relating to this Contract and/or the Services.',
          },
        ],
      },
      {
        subtitle: '8.1. Dispute Resolution Between You and Spona and Our Affiliates',
        paragraphs: [
          {
            text: () =>
              'Before serving a Claim, you and Spona agree to first notify each other of the Claim. You agree to notify Spona of the Claim by email to office@todigital.agency, and Spona agrees to provide you with a notice at the email address you provided while registering on the Site (in each case, a “Notice”).',
          },
          {
            text: () =>
              'You and Spona then will seek an informal voluntary resolution of the Claim. Any Notice must include your account information, a brief description of the Claim, and contact information, so that you or Spona, as applicable, may evaluate the Claim and attempt to informally resolve the Claim. Both you and Spona will have 45 days from the date of the receipt of the Notice to informally resolve the other party’s Claim, which, if successful, will avoid the need for further action.',
          },
          {
            text: () =>
              'In the event we are unable to resolve a Claim within 45 days of the receipt of the applicable Notice, you, Spona, and our Affiliates agree to the exclusive jurisdiction of the Courts of Ireland to settle any dispute which may arise out of or in connection with this Agreement or the Services.',
          },
        ],
      },
      {
        subtitle: '8.2. Dispute Resolution Between You and Another User',
        paragraphs: [
          {
            text: () =>
              'In the event of a dispute arising between you and another User of the Services, you agree to put in a reasonable effort to resolve all disputes amicably.',
          },
          {
            text: () =>
              'Spona may provide assistance to the parties included by providing information about the projects, giving insight into the messages parties have exchanged through the Spona platform, or documentation relating to the subject matter of the dispute if possible. You agree to inform Spona of any disputes between you and another User of the Services as soon as they arise. The moment Spona is informed of any disputes between the Users all monies allocated to Spona’s account will be placed in suspense until the dispute is resolved, but no longer than 30 days. Once Spona is informed that the dispute has been resolved Spona will ask all affected Users to provide written advice of the outcome. Spona will continue the disbursement of the monies according to the written advice.',
          },
          {
            text: () =>
              'If members are unable to resolve the dispute within 30 days of first informing Spona of the dispute, further proceedings for handling the dispute shall be followed as agreed in the Digital Contract, Service Contract, or similar agreement between the parties. All monies allocated to Spona’s account for the services of the Seller will be returned to the Buyer less the amount of Spona’s commission fee and transfer fees. Spona is not a party to any contract between Users, and you agree to release Spona from any claims, demands and damages as detailed described in section 5.3. of this User Agreement.',
          },
          {
            text: () =>
              'If members are unable to resolve the dispute within 30 days of first informing Spona of the dispute, further proceedings for handling the dispute shall be followed as agreed in the Digital Contract, Service Contract, or similar agreement between the parties. All monies allocated to Spona’s account for the services of the Seller will be returned to the Buyer less the amount of Spona’s commission fee and transfer fees. Spona is not a party to any contract between Users, and you agree to release Spona from any claims, demands, and damages as detailed described in section 5.3. of this User Agreement.',
          },
          {
            text: () =>
              'If there is a dispute between users of the Services, or between any user of the Service and any third party, you acknowledge and agree that Spona is under no obligation to become involved. In the event that a dispute arises between you and any third party, you hereby release Spona’s officers, managers, members, directors, employees, attorneys, agents, and successors in rights from any claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, foreseeable or unforeseeable, disclosed or undisclosed, arising out of or in any way related to such disputes.',
          },
        ],
      },
    ],
  },
  {
    title: '9. General Terms',
    content: [
      {
        paragraphs: [
          {
            text: () => 'Here are some important details about the Agreement.',
          },
          {
            text: () =>
              'If a court with authority over this Agreement finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Agreement.',
          },
          {
            text: () =>
              'To the extent allowed by law, the English language version of this Agreement is binding and other translations are for convenience only.',
          },
        ],
      },
      {
        subtitle: 'Waiver of Contractual Rights',
        paragraphs: [
          {
            text: () =>
              'The failure of Spona to enforce any provision of this Agreement shall not be construed as a waiver or limitation of Spona’s right to subsequently enforce and compel strict compliance with every provision of this Agreement.',
          },
          {
            text: () =>
              'You may not assign or transfer this Agreement (or your membership or use of Services) to anyone without our consent. However, you agree that Spona may assign this Agreement to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Agreement.',
          },
          {
            text: () => '∆',
          },
          {
            text: () =>
              'Nothing in this Agreement is intended to create any partnership, employment relationship or joint venture between Spona and Users.',
          },
          {
            text: () =>
              'You agree that the only way to provide us with legal notice is via the email address office@topdigital.agency.',
          },
        ],
      },
    ],
  },
  {
    title: '10. Spona’s “Dos and Don’ts”',
    content: [
      {
        subtitle: '10.1. Dos',
        paragraphs: [
          {
            text: () =>
              'Spona is a platform for professional organizations. This list of “Dos and Don’ts” limit what you can and cannot do with our Services.',
          },
          {
            text: () => 'You agree that you will:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'dot',
                  items: [
                    'Comply with all applicable laws, including, without limitation, privacy laws, anti-money laundry regulations, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;',
                    'Provide accurate information to us and keep it updated;',
                    'Use your real organization name on your profile; and',
                    'Use the Services in a professional manner.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
      {
        subtitle: '10.2. Don’ts',
        paragraphs: [
          {
            text: () => 'You agree that you will not:',
          },
          {
            text: () => (
              <>
                {CustomList({
                  listType: 'number',
                  items: [
                    'Create a false identity on Spona, misrepresent your identity, create a User profile for anyone other than yourself (a real person), or use or attempt to use another’s account;',
                    'Develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins and add-ons, or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;',
                    'Override any security feature or bypass or circumvent any access controls or use limits of the Service;',
                    'Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Spona;',
                    'Disclose information that you do not have consent to disclose (such as confidential information of others including Authorized Users of your organization or company);',
                    'Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts, published projects or other content of others without their permission;',
                    'Violate the intellectual property or other rights of Spona, including, without limitation, (i) copying or distributing our technology, unless it is released under open source licenses; (ii) using the word “Spona” or “TDA”, “Top Digital Agency ”our logos in any business name, email, or URL',
                    'Post anything that contains software viruses, worms, or any other harmful code;',
                    'Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;',
                    'Imply or state that you are affiliated with or endorsed by Spona without our express consent;',
                    'Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without Spona’s consent;',
                    'Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without Spona’s consent;',
                    'Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;',
                    'Monitor the Services’ availability, performance or functionality for any competitive purpose;',
                    'Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;',
                    'Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included in the Services);',
                    'Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or',
                    'Violate any additional terms concerning a specific Service that are provided when you sign up for or start using such Service.',
                  ],
                })}
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    title: '11. How to Contact Us',
    content: [
      {
        paragraphs: [
          {
            text: () => 'Our contact information.',
          },
          {
            text: (onClick) => (
              <Typography variant="publicBody5Regular" color="common.black">
                If you want to send us notices or service of process, please send us an email at
                connect@topdigital.agency or fill out our{' '}
                <Typography
                  variant="publicBody5Regular"
                  color="common.black"
                  sx={{ ...fakeLinkStyles }}
                  onClick={() => onClick?.[5]()}
                >
                  contact form{' '}
                </Typography>
                and we will be in touch shortly.
              </Typography>
            ),
          },
        ],
      },
    ],
  },
];

export default termsOfService;
