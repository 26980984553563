import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ErrorAlertIcon } from '../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { ReactComponent as RequestReviewIcon } from '../../../../assets/icons/project-icons/RequestReviewIcon.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/layout-icons/SaveIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { PitchCreateModalProps } from '../../pitch.interface';
import { secondaryBlue, secondaryPink } from '../../../../color';
import ConfirmModal from '../../../../components/confirm-modal/ConfirmModal';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';

const PitchCreateModal = ({
  isOpen,
  setIsOpen,
  type,
  errorMessage,
  onConfirm,
  timedProjectName,
}: PitchCreateModalProps) => {
  const modalsContent = {
    create: {
      title: 'Well done',
      text: `Your pitch for ${timedProjectName} has been successfully submitted and is now under review. You'll be notified once the review process is complete.`,
      icon: <BriefcaseCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryBlue[100],
    },
    edit: {
      title: 'Notices before change',
      text: 'Your changes will be changed when the Spona team validates and approves them. In the meantime, the Pitch will remain as it was.  ',
      icon: <BriefcaseCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryBlue[100],
    },
    draft: {
      title: 'Save draft',
      text: 'Save your case study as draft to continue editing it later.',
      icon: <BriefcaseCheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryBlue[100],
    },
    error: {
      title: 'Error',
      text: 'Something went wrong. Please try again later.',
      icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryPink[100],
    },
    cancel: {
      title: 'Cancel Form',
      text: 'You are coming out of the creative form. Your recorded data will be lost.',
      icon: <CancelIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryBlue[100],
    },
  };

  const message = errorMessage || modalsContent[type].text;
  const navigate = useNavigate();
  const confirm = onConfirm || (() => navigate('../'));

  return (
    <ConfirmModal
      width="50%"
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      title={modalsContent[type].title}
      message={
        <Typography color="primaryDark.500" lineHeight="21px">
          {message}
        </Typography>
      }
      confirmButtonText={type === 'draft' ? 'Confirm' : type === 'cancel' ? 'Proceed' : 'Close'}
      onConfirm={confirm}
      iconProps={{
        icon: modalsContent[type].icon,
        wrapperBackgroundColor: modalsContent[type].wrapperBackgroundColor,
      }}
      showCancelButton={type === 'draft' || type === 'cancel'}
      onCancel={() => setIsOpen(false)}
    />
  );
};

export default PitchCreateModal;
