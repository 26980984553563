import {
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../layout/utils';

const headers = ['Spent', 'Commission'];
const data = [
  ['$2k - $99k', '5%'],
  ['$100k - $499k', '4%'],
  ['$500k - $999k', '3%'],
  ['$1m+', '2%'],
];

const PricingPlan = () => (
  <Stack
    direction={{ xs: 'column', md: 'row' }}
    spacing={{ xs: 4, md: 0, lg: 8 }}
    sx={{
      backgroundColor: 'primaryLight.100',
      p: { xs: '32px 16px', md: '32px 16px' },
      ...LAYOUT_DEFAULTS,
      mt: 3,
      mb: 14,
      borderRadius: '20px',
      boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
    }}
  >
    <Stack
      sx={{ p: { xs: 0, md: '40px 24px', gr: '40px 48px' }, width: { xs: 'none', md: '50%' } }}
      spacing={3}
    >
      <Typography sx={{ fontSize: { xs: '2rem', lg: '3rem' } }} variant="headline1">
        Pricing plan
      </Typography>
      <Typography variant="publicBody5Regular">
        We provide ad accounts for a small percentage of your monthly ad spending. Our prices are
        flexible depending on the size of the monthly budget (ad spend). <br /> Possibility of
        Cashback for TikTok and Snapchat Ad Accounts
      </Typography>
    </Stack>
    <Box sx={{ width: { xs: 'none', md: '50%' }, p: { xs: 0, md: '40px 24px', gr: '40px 31px' } }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, ind) => {
              const style = {
                backgroundColor: 'primaryLight.500',
                color: '#fff',
                borderRadius: ind === 0 ? '20px 0 0 0' : '0 20px 0 0',
                borderLeft: ind === 1 ? '1px solid' : '',
                borderLeftColor: 'primaryLight.200',
                fontFamily: 'Gellix-SemiBold',
                fontSize: { xs: '1rem', lg: '1.5rem' },
                padding: { xs: '1.125rem 1rem', md: '1.375rem 1.5rem' },
              };

              return (
                <TableCell
                  key={header}
                  sx={{
                    ...style,
                  }}
                >
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowInd) => (
            <TableRow key={rowInd}>
              {row.map((cell, cellInd) => {
                const style = {
                  backgroundColor: '#f9f9f9',
                  color: 'primaryDark.900',
                  borderLeft: cellInd !== 0 ? '1px solid' : '',
                  borderLeftColor: 'primaryLight.200',
                  borderBottom: rowInd !== data.length - 1 ? '1px solid' : 'none',
                  borderBottomColor: 'primaryLight.200',
                  borderRadius:
                    data.length - 1 !== rowInd
                      ? ''
                      : cellInd === 0
                      ? '0 0 0 20px'
                      : cellInd === row.length - 1
                      ? '0 0 20px 0'
                      : '',
                  fontFamily: cellInd === 0 ? 'Gellix-Regular' : 'Gellix-SemiBold',
                  fontSize: { xs: '1rem', lg: '1.25rem' },
                  padding: { xs: '1.125rem 1rem ', md: '1.25rem 1.5rem' },
                };

                return (
                  <TableCell
                    key={cell}
                    sx={{
                      ...style,
                    }}
                  >
                    {cell}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  </Stack>
);

export default PricingPlan;
