import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import circles from '../../assets/img/public-pages/circles.png';
import CustomButton from '../../components/button/CustomButton';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/layout-icons/ArrowLeftShortFill.svg';
import theme from '../../theme';
import MetaTags from '../../components/seo/MetaTags';

const metaTags = {
  description: `Spona.io 404 Error Page`,
};

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%" mt={{ xs: '3rem', sm: '4rem', lg: '4.5rem' }}>
        <Box
          sx={{
            backgroundColor: 'primaryLight.700',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${circles})`,
            height: '70vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'cover',
          }}
        >
          <Stack sx={{ paddingX: '30px' }} spacing={3.5}>
            <Typography
              color="#fff"
              variant="publicHeading1"
              sx={{ [theme.breakpoints.down('sm')]: { fontSize: '32px' } }}
            >
              Oops! This page does not exist.
            </Typography>
            <Typography color="#fff" variant="publicBody2" mb="1rem">
              Click below to go back to safety. If the link is broken, let us know and we&apos;ll
              fix it shortly.
            </Typography>
            <CustomButton
              startIcon={<ArrowLeftIcon />}
              variant="primaryLight"
              sx={{ width: 'fit-content', color: '#384572' }}
              onClick={() => navigate(-1)}
            >
              BACK
            </CustomButton>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
export default NotFound;
