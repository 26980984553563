import {
  Stack, Select, Typography, MenuItem,
} from '@mui/material';
import { Controller, FieldValues, UseFormWatch } from 'react-hook-form';
import CustomChip from '../custom-chip/CustomChip';

export interface MultiSelectChipsProps {
  enumMap?: Map<string, string>;
  elements?: Record<string, string>[];
  getElementTitle?: (id: string) => string;
  valuesName: string;
  watch: UseFormWatch<FieldValues>;
  control: any;
  isRequired: boolean;
  requiredMessage?: string;
  backgroundColor?: string;
  // control: Control<{ services: never[] }, any>
  chipBackgroundColor?: 'default' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  chipRoundedCorners?: boolean;
  shouldHaveRemove?: boolean;
  remove?: (index: number) => void;
  deleteIconColor?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectChips = ({
  enumMap,
  elements,
  getElementTitle,
  watch,
  control,
  valuesName,
  isRequired,
  requiredMessage,
  backgroundColor,
  chipBackgroundColor,
  chipRoundedCorners = true,
  shouldHaveRemove,
  remove,
  deleteIconColor,
}: MultiSelectChipsProps) => (
  <Stack spacing={1.5} width="100%">
    <Controller
      name={valuesName}
      control={control}
      rules={{ required: isRequired ? requiredMessage : false }}
      render={({ field }) => (
        <Select
          {...field}
          sx={{ background: backgroundColor ?? 'transparent' }}
          multiple
          displayEmpty
          MenuProps={MenuProps}
          fullWidth
          renderValue={(selected) => {
            if (selected?.length >= 0) {
              return <Typography sx={{ color: 'grey.500' }}>Select*</Typography>;
            }

            return null;
          }}
        >
          {
            enumMap
            && (
              Array.from(enumMap, ([key, value]: [string, string]) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  {value}
                </MenuItem>
              ))
            )
          }

          {
            elements
            && (
              elements.map((element: Record<string, string>) => (
                <MenuItem
                  key={element?.id}
                  value={element?.id}
                >
                  {element?.title}
                </MenuItem>
              ))
            )
          }
        </Select>
      )}
    />

    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px' }}>
      {
        shouldHaveRemove
          ? (watch(valuesName) ?? []).map((value: string, index: number) => (
            <CustomChip
              onDelete={() => {
                if (remove) {
                  remove(index);
                }
              }}
              key={value}
              variant="filled"
              label={enumMap ? enumMap?.get(value) : getElementTitle?.(value)}
            />
          ))
          : (watch(valuesName) ?? []).map((value: string, index: number) => (
            <CustomChip
              key={value}
              variant="filled"
              label={enumMap ? enumMap?.get(value) : getElementTitle?.(value)}
            />
          ))
      }
    </Stack>
  </Stack>
);
export default MultiSelectChips;
