import { useState } from 'react';
import { Box, Divider, Stack, SxProps, Theme } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import CustomButton from '../../../../components/button/CustomButton';
import ComapnyProfileAvatar from './CompanyProfileAvatar';
import CompanyProfileInfoIcons from './CompanyProfileInfoIcons';
import CompanyProfileMediaLinks from './CompanyProfileMediaLinks';
import CompanyProfileAccordionInfo from './CompanyProfileAccordionInfo';
import CompanyProfileDescriptionAndClients from './CompanyProfileDescriptionAndClients';
import CompanyCaseStudies from './CompanyCaseStudies';
import LoadingIndicator from '../../../../components/loading-indicator/LoadingIndicator';

interface CompanyProfileMobileViewProps {
  company: CompanyDto | undefined;
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

const CompanyProfileMobileView = ({ company, isLoading, sx }: CompanyProfileMobileViewProps) => {
  const [activeTab, setActiveTab] = useState<'overview' | 'about' | 'case-study'>('overview');
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Stack direction="column" justifyContent="space-between" spacing={1} px={2}>
          <ComapnyProfileAvatar company={company} sx={{ mt: '-2.125rem' }} spacing={1.5} />
          <Stack direction="row" width="100%" justifyContent="center" pt={2} pb={5}>
            <CustomButton
              onClick={() => setActiveTab('overview')}
              variant="primaryLight"
              sx={{
                borderColor: 'primaryDark.250',
                borderRadius: 0,
                boxShadow: activeTab === 'overview' ? '6px 6px 15px 0px #3845721A' : 'none',
                color: activeTab === 'overview' ? 'primaryLight.500' : 'primaryDark.500',
                fontSize: '0.75rem',
                minWidth: '6.4rem',
              }}
            >
              Overview
            </CustomButton>
            <CustomButton
              onClick={() => setActiveTab('about')}
              variant="primaryLight"
              sx={{
                borderColor: 'primaryDark.250',
                borderRadius: '0 4px 4px 0',
                boxShadow: activeTab === 'about' ? '6px 6px 15px 0px #3845721A' : 'none',
                color: activeTab === 'about' ? 'primaryLight.500' : 'primaryDark.500',
                fontSize: '0.75rem',
                minWidth: '6.4rem',
              }}
            >
              About
            </CustomButton>
            <CustomButton
              onClick={() => setActiveTab('case-study')}
              variant="primaryLight"
              sx={{
                borderColor: 'primaryDark.250',
                borderRadius: ' 4px 0 0 4px',
                boxShadow: activeTab === 'case-study' ? '6px 6px 15px 0px #3845721A' : 'none',
                color: activeTab === 'case-study' ? 'primaryLight.500' : 'primaryDark.500',
                fontSize: '0.75rem',
                minWidth: '6.4rem',
              }}
            >
              Case study
            </CustomButton>
          </Stack>
          {activeTab === 'overview' && (
            <Box sx={{ mt: 1 }}>
              <CompanyProfileDescriptionAndClients company={company} spacing={6} />
            </Box>
          )}
          {activeTab === 'about' && (
            <Box>
              <Stack
                sx={{
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: '#E5E8FF',
                  borderRadius: '15px',
                  pb: 3.5,
                  px: 1.5,
                }}
                divider={<Divider flexItem />}
              >
                <CompanyProfileInfoIcons company={company} sx={{ my: 3, pl: 2 }} />
                <CompanyProfileMediaLinks company={company} sx={{ mt: 3 }} />
              </Stack>
              <CompanyProfileAccordionInfo
                company={company}
                sx={{
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: '#E5E8FF',
                  borderRadius: '15px',
                  pb: 3.5,
                  px: 1.5,
                  my: 6,
                }}
              />
            </Box>
          )}
          {activeTab === 'case-study' && (
            <Box>
              <CompanyCaseStudies />
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export default CompanyProfileMobileView;
