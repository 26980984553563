import { BlogContentSchema } from 'tdc-web-backend/blogs/schemas';
import SectionsEdit from './components/SectionsEdit';
import SectionsView from './components/SectionsView';

type SectionsProps = {
  viewMode?: boolean;
  values?: BlogContentSchema[];
};

const Sections = ({ viewMode, values }: SectionsProps) => (
  <>{viewMode ? <SectionsView values={values} /> : <SectionsEdit />}</>
);

export default Sections;
