import { LoadingButton as MuiLoadingButton, LoadingButtonProps } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface LoadingButtonPropsI {
  loading?: boolean;
  setUpperCase?: boolean;
  setBorderRadius?: boolean;
  hasShadow?: boolean;
  fontWeight?: string;
  linkTo?: string | null;
  minWidth?: string | number;
  onButtonClick: (linkTo?: string | null) => void;
  href?: string;
  disabled?: boolean;
  type?: string;
  sx?: SxProps<Theme> | undefined;
}

const LoadingButton = ({
  variant,
  size,
  color,
  children,
  loading,
  setUpperCase,
  setBorderRadius,
  fontWeight,
  type,
  linkTo,
  minWidth,
  onButtonClick,
  href,
  startIcon,
  endIcon,
  disabled,
  hasShadow = true,
  sx,
}: (LoadingButtonProps & LoadingButtonPropsI)) => {
  const navigate = useNavigate();

  return (
    <MuiLoadingButton
      variant={variant}
      size={size}
      onClick={() => (linkTo ? navigate(linkTo) : onButtonClick(linkTo))}
      href={href}
      startIcon={startIcon ?? null}
      endIcon={endIcon ?? null}
      disabled={disabled ?? false}
      loading={loading}
      type={type}
      sx={{
        textTransform: setUpperCase ? 'uppercase' : 'none',
        borderRadius: setBorderRadius ? 1 : 'none',
        fontWeight: fontWeight || 'normal',
        fontSize: '12px',
        minWidth: minWidth ?? 64,
        backgroundColor: 'primaryLight.500',
        height: '45px',
        boxShadow: hasShadow ? '6px 6px 15px rgba(109, 81, 246, 0.4);' : '',
        color: 'primaryDark.150',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'primaryLight.500',

        },
        '&.Mui-disabled': {
          backgroundColor: 'primaryLight.100',
          color: 'primaryDark.150',

        },
        ...sx,
      }}
    >
      {children}
    </MuiLoadingButton>
  );
};

export default LoadingButton;
