import { IconButton, Stack, Typography } from '@mui/material';
import { Navigate, ToolbarProps, Views } from 'react-big-calendar';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/calendar-icons/ArrowLeftIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/calendar-icons/ArrowRightIcon.svg';
import CustomButton from '../../components/button/CustomButton';

const CalendarToolbar = (toolbar : ToolbarProps) => {
  const {
    onNavigate, label, date, onView, view,
  } = toolbar;
  const isActive = (viewType: string) => (viewType === view ? {
    color: 'primaryLight.500',
    boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
  } : {
    color: 'primaryDark.500',
    boxShadow: 'none',
  });
  return (
    <Stack direction="row" justifyContent="space-between" p={2} px={3}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <IconButton
            sx={{
              border: '1px solid',
              borderColor: 'primaryDark.300',
              width: '1.8rem',
              height: '1.8rem',
            }}
            onClick={() => onNavigate(Navigate.PREVIOUS)}
          >
            <ArrowLeftIcon />
          </IconButton>
          <Typography variant="heading3" color="primaryDark.600">{label}</Typography>
          <IconButton
            sx={{
              border: '1px solid',
              borderColor: 'primaryDark.300',
              width: '1.8rem',
              height: '1.8rem',
            }}
            onClick={() => onNavigate(Navigate.NEXT)}
          >
            <ArrowRightIcon />
          </IconButton>
        </Stack>
        <CustomButton
          sx={{
            minWidth: '0px',
            ml: 1,
          }}
          variant="primaryText"
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          TODAY
        </CustomButton>
      </Stack>
      <Stack direction="row" alignItems="center" mb={1}>
        <CustomButton
          sx={{
            minWidth: '0px',
            borderRadius: '4px 0px 0px 4px',
            padding: '0 1.5rem',
            height: '2.4rem',
            borderRight: 'none',
            ...isActive(Views.MONTH),
          }}
          variant="secondary"
          onClick={() => onView(Views.MONTH)}
        >
          Month
        </CustomButton>
        <CustomButton
          sx={{
            minWidth: '0px',
            borderRadius: '0px',
            padding: '0 1.5rem',
            height: '2.4rem',
            borderRight: 'none',
            ...isActive(Views.WEEK),
          }}
          variant="secondary"
          onClick={() => onView(Views.WEEK)}
        >
          week
        </CustomButton>
        <CustomButton
          sx={{
            minWidth: '0px',
            borderRadius: '0px',
            padding: '0 1.5rem',
            height: '2.4rem',
            borderRight: 'none',
            ...isActive(Views.DAY),
          }}
          variant="secondary"
          onClick={() => onView(Views.DAY)}
        >
          day
        </CustomButton>
        <CustomButton
          sx={{
            minWidth: '0px',
            borderRadius: '0px 4px 4px 0px',
            padding: '0 1.5rem',
            height: '2.4rem',
            ...isActive(Views.AGENDA),
          }}
          variant="secondary"
          onClick={() => onView(Views.AGENDA)}
        >
          agenda
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default CalendarToolbar;
