import { IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationMenu from '../../../components/notifications/NotificationMenu';
import UserMenuDropdown from './UserMenuDropdown';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/layout-icons/CalendarIcon.svg';
import { ReactComponent as MessageIcon } from '../../../assets/icons/layout-icons/MessageIcon.svg';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';

const RightSideToolbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useGetLocalStorageUserData();
  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: user?.membership?.company,
    enabled: !!user?.membership?.company || false,
  });

  const path = location.pathname;

  return (
    <Stack spacing="15px" direction="row" sx={{ pr: path.includes('dashboard') ? '24px' : 0 }}>
      {companyData?.status === 'approved' && (
        <IconButton
          className="react-joyride-step-2"
          size="large"
          sx={{
            border: '1px solid #BFC6D7',
          }}
          onClick={() => navigate('/dashboard/chat')}
        >
          <MessageIcon fill="#79829B" />
        </IconButton>
      )}

      {companyData?.status === 'approved' && (
        <IconButton
          className="react-joyride-step-3"
          size="large"
          sx={{
            border: '1px solid #BFC6D7',
          }}
          onClick={() => navigate('/dashboard/calendar')}
        >
          <CalendarIcon fill="#79829B" />
        </IconButton>
      )}
      {companyData?.status === 'approved' && <NotificationMenu />}
      <UserMenuDropdown />
    </Stack>
  );
};
export default RightSideToolbar;
