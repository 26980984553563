import { Box, Card } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import Dropzone from 'react-dropzone';
import UploadIcon from '../../../../assets/img/upload-icon.svg';
import { getBase64 } from '../../../../utils/helpers';

type Props = {
  setFile: Dispatch<SetStateAction<any>>
  setFileName: Dispatch<SetStateAction<string>>;
  uploadSubject?: string
}

const InvoiceFileUpload = ({ setFile, setFileName, uploadSubject = 'invoice' }: Props) => {
  const handleFile = async (e: any) => {
    const file = e?.[0];
    if (!file) return;

    const base64 = await getBase64(file);
    setFileName(file?.name);
    setFile(base64);
  };

  return (
    <Dropzone onDrop={handleFile} accept=".pdf">
      {({ getRootProps, getInputProps }) => (
        <Card
          {...getRootProps()}
          variant="outlined"
          component="div"
          sx={{
            p: 3,
            mt: 5,
            flexDirection: 'column',
            textAlign: 'center',
            color: '#5C5F5C',
            border: '1px dashed #A9ACA8',
          }}
        >
          <input
            {...getInputProps()}
          />
          <Box
            component="img"
                // width="2.5rem"
            src={UploadIcon}
            sx={{
              mr: '1em',
              mb: '1em',
            }}
          />
          <p>
            Drag & drop to upload file (PDF) or
            {' '}
            <span style={{ color: '#7F57FF' }}>browse</span>
            <br />
            Max. size: 10 MB
          </p>
        </Card>
      )}
    </Dropzone>
  );
};

export default InvoiceFileUpload;
