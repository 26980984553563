import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { RecentChannelDto } from 'tdc-web-backend/channels/schemas';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import FloatingChatElement from './FloatingChatElement';
import { ReactComponent as UpArrowIcon } from '../../../assets/icons/chat-icons/caret-up-fill.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/chat-icons/caret-down-fill.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/chat-icons/x-circle-fill.svg';
import { primaryDark, secondaryBlue } from '../../../color';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import RecentChannel from './RecentChannel';
import ChannelView from '../Channel/ChannelView';

export default () => {
  const userData = useGetLocalStorageUserData();
  const [expanded, setExpanded] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');

  const recentChannelsResponse = useGetOne<RecentChannelDto[]>({
    resource: `channels/recent`,
  });
  const recentChannels = recentChannelsResponse.data?.data ?? [];

  return (
    <Stack position="fixed" bottom={0} right={30} direction="row" gap="10px" alignItems="flex-end">
      {selectedChannel ? (
        <FloatingChatElement position="relative" width="410px" height="640px">
          <Box position="absolute" top="8px" right="16px" zIndex={1}>
            <IconButton sx={{ p: 0 }} disableRipple onClick={() => setSelectedChannel('')}>
              <CloseIcon fill={primaryDark[500]} />
            </IconButton>
          </Box>
          <ChannelView channelId={selectedChannel} floating />
        </FloatingChatElement>
      ) : null}
      <Stack width={300} gap={1}>
        <FloatingChatElement
          sx={{ cursor: 'pointer' }}
          onClick={() => setExpanded((expanded) => !expanded)}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" p="10px">
            <Stack direction="row" alignItems="center" gap="10px">
              <Avatar>{userData?.firstName?.[0]}</Avatar>
              <Typography variant="heading3" color="primaryDark.600">
                chat
              </Typography>
            </Stack>
            {expanded ? (
              <DownArrowIcon fill={secondaryBlue[500]} />
            ) : (
              <UpArrowIcon fill={primaryDark[500]} />
            )}
          </Stack>
        </FloatingChatElement>
        {expanded && (
          <FloatingChatElement>
            <Stack>
              {recentChannels.map((recentChannel) => (
                <RecentChannel
                  recentChannel={recentChannel}
                  onClick={() => setSelectedChannel(recentChannel.channel.id)}
                />
              ))}
            </Stack>
          </FloatingChatElement>
        )}
      </Stack>
    </Stack>
  );
};
