/* eslint-disable import/no-unresolved */
import { useParams } from 'react-router-dom';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Avatar, Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { CaseStudy } from 'tdc-web-backend/case-studies/entities';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { MutableRefObject, useRef, useState } from 'react';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import { formatDateFns } from '../../../utils/helpers';
import CaseStudyDescriptions from './components/CaseStudyDescriptions';
import CaseStudyClientReview from './components/CaseStudyClientReview';
import CaseStudySidebar from './components/CaseStudySidebar';
import RelatedCaseStudy, { SliderWrapperItem } from './components/RelatedCaseStudy';
import CaseStudyCard from '../Archives/components/CaseStudyCard';
import { primaryDark, secondaryGray } from '../../../color';
import { ReactComponent as JournalIcon } from '../../../assets/icons/journals-fill.svg';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import theme from '../../../theme';
import CustomButton from '../../../components/button/CustomButton';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';

export const Divider = () => <Stack width="100%" bgcolor={secondaryGray[200]} height="1px" />;

const CaseStudyDetails = () => {
  const [activeTab, setActiveTab] = useState<'case-study' | 'details' | 'review'>('case-study');
  const params = useParams();
  const isLessThanSmall = useMediaQuery(theme.breakpoints.down('md'));

  const review = useRef<HTMLDivElement | null>(null);

  const scrollOnClick = () =>
    review?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  const { data: caseStudy, isLoading: isLoadingCs } = useGetOne<CaseStudy>({
    enabled: !!params?.caseStudyId,
    resource: `case-studies/${params.caseStudyId}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: caseStudyContent, isLoading: isLoadingContent } = useGetOne<CaseStudyContentDto>({
    enabled: !!caseStudy?.data?.content,
    resource: `case-studies-content/${caseStudy?.data.content}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const shouldDisplayTab = (tab: 'case-study' | 'details' | 'review') => {
    if (tab === 'review' && !isLessThanSmall) {
      return 'none';
    }

    return !isLessThanSmall ? 'flex' : tab !== activeTab && 'none';
  };

  return (
    <>
      {isLoadingContent || isLoadingCs ? (
        <LoadingIndicator sx={{ pt: '15rem', pb: '10rem' }} />
      ) : (
        <>
          <Stack pt={20} px={{ xs: 3, gr: 10 }}>
            {isLessThanSmall && (
              <>
                <Avatar
                  style={{ margin: '0 auto' }}
                  alt="company logo"
                  src={caseStudyContent?.data?.seller?.avatar}
                  sx={{ width: 100, height: 100 }}
                />
                <Typography textAlign="center" mt={2} variant="publicBody1">
                  {caseStudyContent?.data?.seller?.name}
                </Typography>
              </>
            )}
            {isLessThanSmall && (
              <Stack direction="row" width="100%" justifyContent="center" pt={4} pb={5}>
                <CustomButton
                  onClick={() => setActiveTab('case-study')}
                  variant="primaryLight"
                  sx={{
                    borderColor: 'primaryDark.250',
                    borderRadius: ' 4px 0 0 4px',
                    boxShadow: activeTab === 'case-study' ? '6px 6px 15px 0px #3845721A' : 'none',
                    color: activeTab === 'case-study' ? 'primaryLight.500' : 'primaryDark.500',
                    fontSize: '0.75rem',
                    minWidth: '6.4rem',
                  }}
                >
                  Case study
                </CustomButton>
                <CustomButton
                  onClick={() => setActiveTab('details')}
                  variant="primaryLight"
                  sx={{
                    borderColor: 'primaryDark.250',
                    borderRadius: 0,
                    boxShadow: activeTab === 'details' ? '6px 6px 15px 0px #3845721A' : 'none',
                    color: activeTab === 'details' ? 'primaryLight.500' : 'primaryDark.500',
                    fontSize: '0.75rem',
                    minWidth: '6.4rem',
                  }}
                >
                  Details
                </CustomButton>
                <CustomButton
                  onClick={() => setActiveTab('review')}
                  variant="primaryLight"
                  sx={{
                    borderColor: 'primaryDark.250',
                    borderRadius: '0 4px 4px 0',
                    boxShadow: activeTab === 'review' ? '6px 6px 15px 0px #3845721A' : 'none',
                    color: activeTab === 'review' ? 'primaryLight.500' : 'primaryDark.500',
                    fontSize: '0.75rem',
                    minWidth: '6.4rem',
                  }}
                >
                  Review
                </CustomButton>
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={{ xs: 0, md: 5 }}
              maxWidth={{ xl: '86.0625rem' }}
              mx={{ xl: 'auto' }}
            >
              <Stack
                width={{ xs: '100%', lg: '100%' }}
                display={shouldDisplayTab('case-study') as string}
              >
                <Stack mb={{ xs: 3, md: 5 }}>
                  <Typography
                    px={1.5}
                    pt={1}
                    mb={2}
                    color="primaryDark.600"
                    variant="heading1"
                    sx={{ textTransform: 'inherit' }}
                  >
                    {caseStudyContent?.data.title}
                  </Typography>
                  <Typography
                    px={1.5}
                    mb={{ xs: 3, md: 5 }}
                    color="primaryDark.500"
                    variant="body3"
                  >
                    {formatDateFns(caseStudyContent?.data?.published, '')}
                  </Typography>
                  <Box maxHeight="22rem" width="100%" overflow="hidden" borderRadius="12px">
                    <Box
                      component="img"
                      width="100%"
                      src={caseStudyContent?.data.file}
                      height="100%"
                      sx={{ objectFit: 'contain' }}
                    />
                  </Box>
                </Stack>
                <Divider />
                <CaseStudyDescriptions
                  data={caseStudyContent?.data as CaseStudyContentDto}
                  scrollRef={review}
                />
                {!isLessThanSmall && (
                  <Stack direction="row" alignItems="center" spacing={1} pl={3}>
                    <JournalIcon fill={primaryDark[500]} />
                    <BaseInputLabel textVariant="heading3" text="Related case studies" />
                  </Stack>
                )}
              </Stack>

              <Stack
                width={{ xs: '100%', lg: '100%' }}
                display={shouldDisplayTab('details') as string}
                direction={{ xs: 'column-reverse', md: 'column' }}
                alignContent="center"
                height="fit-content"
                mx={{ xs: 0, lg: 3 }}
                border={{ md: `1px solid ${primaryDark[300]}` }}
                borderRadius="12px"
                px={{ xs: 0, md: 2, lg: 3, gr: 5 }}
                maxWidth={{ xs: '100%', md: '22.25rem', lg: '25rem', gr: '26.875rem' }}
                pt={{ xs: 0, md: 3 }}
              >
                <CaseStudySidebar
                  setActiveTab={setActiveTab}
                  scrollOnClick={scrollOnClick}
                  score={caseStudy?.data?.score ?? null}
                  data={caseStudyContent?.data as CaseStudyContentDto}
                />
              </Stack>
            </Stack>

            <Stack py={{ xs: 0, lg: 3 }} display={shouldDisplayTab('review') as string}>
              <CaseStudyClientReview
                scrollRef={
                  isLessThanSmall
                    ? review
                    : (null as unknown as MutableRefObject<HTMLDivElement | null>)
                }
                data={caseStudyContent?.data as CaseStudyContentDto}
              />
            </Stack>
          </Stack>
          {!isLessThanSmall && (
            <RelatedCaseStudy
              services={caseStudyContent?.data.details?.services}
              renderCustomComponent={(data: BaseCaseStudy[]) =>
                data?.map((caseStudy: Partial<BaseCaseStudy>) => (
                  // eslint-disable-next-line no-use-before-define
                  <SliderWrapperItem key={caseStudy.id}>
                    <CaseStudyCard data={caseStudy as any} />
                  </SliderWrapperItem>
                ))
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default CaseStudyDetails;
