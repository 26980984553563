import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import SidebarCalendarItem from './SidebarCalendarItem';
import CustomButton from '../../../../components/button/CustomButton';
import { primaryLight } from '../../../../color';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import { simpleDateFormatter } from '../../../../utils/helpers';

const SidebarCalendar = () => {
  const chevronIconSize = '1 rem';

  const user = useGetLocalStorageUserData();

  const { data: calendarEvent } = useGetMany<CalendarEventDto>({
    enabled: !!user,
    resource: `/calendar?user=${user?.id}`,
  });

  const eventList = calendarEvent?.data.results;

  const filterEventsPerDay = (date: Date) =>
    eventList?.filter((event) => simpleDateFormatter(event.end) === simpleDateFormatter(date));

  const newDate = new Date();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const navigate = useNavigate();
  const [initialYear] = useState<number>(newDate.getFullYear());
  // the month (from 0-11)
  const [initialMonth] = useState<number>(newDate.getMonth());

  const [currentYear, setCurrentYear] = useState<number>(newDate.getFullYear());
  const [currentMonthName, setCurrentMonthName] = useState<string>(
    newDate.toLocaleString('default', { month: 'long' }),
  );

  // the day of the month (from 1-31)
  const [currentMonthDateNumber, setCurrentMonthDateNumber] = useState<number>(newDate.getDate());

  const [dates, setDates] = useState<Array<Date>>([]);

  const calculateDatesAndSetCurrentMonthNameAndCurrentYear = (startOfTheWeekMonday: number) => {
    const datesArray = [];

    for (let i = startOfTheWeekMonday; i < startOfTheWeekMonday + 7; i += 1) {
      datesArray.push(new Date(initialYear, initialMonth, i));
    }

    setCurrentMonthName(
      new Date(initialYear, initialMonth, currentMonthDateNumber).toLocaleString('default', {
        month: 'long',
      }),
    );
    setCurrentYear(new Date(initialYear, initialMonth, currentMonthDateNumber).getFullYear());

    setDates(datesArray);
  };

  const findStartOfTheWeekMonday = () => {
    // the day of the week (from 0-6)
    const today = new Date(initialYear, initialMonth, currentMonthDateNumber).getDay();
    let startOfTheWeekMonday = 1;

    // if sunday
    if (today === 0) {
      startOfTheWeekMonday = currentMonthDateNumber - 6;
      // if tuesday
    } else if (today === 2) {
      startOfTheWeekMonday = currentMonthDateNumber - 1;
      // if wednesday
    } else if (today === 3) {
      startOfTheWeekMonday = currentMonthDateNumber - 2;
      // if thursday
    } else if (today === 4) {
      startOfTheWeekMonday = currentMonthDateNumber - 3;
      //  if friday
    } else if (today === 5) {
      startOfTheWeekMonday = currentMonthDateNumber - 4;
      // if saturday
    } else if (today === 6) {
      startOfTheWeekMonday = currentMonthDateNumber - 5;
    } else {
      // if monday
      startOfTheWeekMonday = currentMonthDateNumber;
    }

    // startOfTheWeekMonday is a month date number (obviously monday) at which a week should start
    calculateDatesAndSetCurrentMonthNameAndCurrentYear(startOfTheWeekMonday);
  };

  useEffect(() => {
    findStartOfTheWeekMonday();
  }, [currentMonthDateNumber]);

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      <Typography variant="heading4" color="primaryDark.600">
        {currentMonthName} {currentYear}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        px={1}
        pb={1}
        justifyContent="space-between"
      >
        <IconButton
          sx={{ p: 0.2, border: `1px solid ${primaryLight[500]}`, bgcolor: 'white' }}
          onClick={() => setCurrentMonthDateNumber((prev) => prev - 7)}
        >
          <ChevronLeftIcon sx={{ fontSize: chevronIconSize, color: 'primaryLight.500' }} />
        </IconButton>
        <CustomButton
          onClick={() => setCurrentMonthDateNumber(new Date().getDate())}
          variant="primaryText"
        >
          today
        </CustomButton>

        <IconButton
          sx={{
            p: 0.2,
            border: `1px solid ${primaryLight[500]}`,
            bgcolor: 'white',
          }}
          onClick={() => setCurrentMonthDateNumber((prev) => prev + 7)}
        >
          <ChevronRightIcon sx={{ fontSize: chevronIconSize, color: 'primaryLight.500' }} />
        </IconButton>
      </Stack>

      <Stack direction="row" justifyContent="space-between" width="100%">
        {dates?.map((date) => (
          <Box key={date.getDay()} sx={{ flex: 1, minWidth: 0 }}>
            <SidebarCalendarItem
              dayName={days[date.getDay()].substring(0, 3)}
              occurringEvents={filterEventsPerDay(date) ?? []}
              dateNumber={date.getDate()}
              todayDate={newDate}
              currentDate={date}
              onClick={(date: Date) =>
                navigate(`/dashboard/calendar?date=${date.toISOString()}&viewType=day`)
              }
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default SidebarCalendar;
