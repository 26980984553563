import { Grid, Tab } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import TabChip from '../../../../components/custom-table/TabChip';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import CaseStudiesCardItem from './CaseStudiesCardItem';

type CaseStudyTabProps = {
  resultCount: number;
  selectedTab: string;
  value: string;
  label: string;
  [x: string]: any;
};

type CaseStudyTabPanelProps = {
  data: BaseCaseStudy[] | undefined;
  value: string;
};

export const CaseStudiesTab = ({
  resultCount,
  selectedTab,
  value,
  label,
  ...props
}: CaseStudyTabProps) => (
  <Tab
    {...props}
    value={value}
    disableRipple
    sx={{
      p: 0,
      mx: 1,
      minHeight: '2rem',
      minWidth: 0,
      '&.Mui-selected': {
        p: 0,
      },
    }}
    icon={
      <TabChip resultCount={resultCount} value={value} selectedTab={selectedTab} label={label} />
    }
    {...props}
  />
);

const CaseStudiesTabPanel = ({ value, data }: CaseStudyTabPanelProps) => (
  <TabPanel
    value={value}
    sx={{
      overflow: 'hidden',
      bgcolor: 'white',
      p: 0,
      pt: 0,
      position: 'relative',
      minHeight: '10rem',
    }}
  >
    <Grid container spacing={3} pt={4}>
      {data?.map((item, index) => (
        <Grid key={item.id} item xs={3} mb={1}>
          <CaseStudiesCardItem item={item} />
        </Grid>
      ))}
    </Grid>
    {!data?.length && <CustomNoRowsOverlay />}
  </TabPanel>
);

export default CaseStudiesTabPanel;
