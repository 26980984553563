import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { Avatar, Box, Stack, StackProps, SxProps, Theme, Typography } from '@mui/material';
import SponaLogoLightImage from '../../../../assets/img/public-pages/spona-logo-light.svg';

const ComapnyProfileAvatar = ({
  company,
  sx,
  ...props
}: {
  company: CompanyDto | undefined;
  sx?: SxProps<Theme>;
} & StackProps) => (
  <Stack sx={{ ...sx }} {...props}>
    <Box>
      {company !== undefined && company.avatar === null ? (
        <Avatar
          alt="company-logo"
          src={SponaLogoLightImage}
          sx={{
            zIndex: 6,
            width: 135,
            height: 135,
            filter: 'drop-shadow(1px 10px 30px rgba(56, 69, 114, 0.10))',
            margin: '0 auto',
          }}
        />
      ) : (
        <Avatar
          alt="company-logo"
          src={company?.avatar}
          sx={{
            zIndex: 6,
            width: 135,
            height: 135,
            filter: 'drop-shadow(1px 10px 30px rgba(56, 69, 114, 0.10))',
            margin: '0 auto',
          }}
        />
      )}
    </Box>
    <Typography
      color="common.black"
      variant="publicBody2"
      sx={{ textAlign: 'center', my: '1.5rem' }}
    >
      {company?.name}
    </Typography>
  </Stack>
);

export default ComapnyProfileAvatar;
