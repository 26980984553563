/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Icon } from '@mui/material';
import { Editor } from '@tiptap/react';

interface ImageUploadMenuButtonProps {
  editor: Editor;
  uniqueKey?: string;
}

const ImageUploadMenuButton = ({ editor, uniqueKey }: ImageUploadMenuButtonProps) => {
  return (
    <div style={{ display: 'inline' }}>
      <label htmlFor={uniqueKey}>
        <Button
          sx={{
            minWidth: 44,
            color: '#384572',
            '&:hover': { backgroundColor: 'transparent', color: '#AA96F8' },
          }}
          component="span"
          size="small"
          disableElevation
          variant="text"
          aria-label="Image"
        >
          <Icon sx={{ fontSize: '1.2rem' }}>insert_photo</Icon>
        </Button>
      </label>

      <input
        hidden
        id={uniqueKey}
        type="file"
        accept="image/*"
        onChange={async ({ target }) => {
          const file = target?.files?.[0];
          if (!file) return;

          const base64String = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
          });

          editor
            .chain()
            .focus()
            .setImage({ src: base64String as string })
            .run();
        }}
      />
    </div>
  );
};

export default ImageUploadMenuButton;
