import { BlogCategoryDto } from 'tdc-web-backend/blog-categories/schemas';
import { Box } from '@mui/material';
import BlogCategoryChip from './BlogCategoryChip';
import useGetMany from '../../../../../../../utils/hooks/crud-hooks/useGetMany';
import useQueryState from '../../../../../../../utils/hooks/useQueryState';

const BlogArchiveFilters = () => {
  const [, setQueryState, removeQueryState, searchParams] = useQueryState();
  const { data: categoriesResponse } = useGetMany<BlogCategoryDto>({
    resource: `/blog-categories`,
  });

  const categories = categoriesResponse?.data.results.filter((item) => item.blogCount > 0);

  return (
    <Box
      sx={{
        overflow: 'auto',
        mx: 1,
        pb: 1,
        whiteSpace: 'nowrap',
        '::-webkit-scrollbar': { height: '6px' },
        zIndex: 99,
      }}
    >
      <Box sx={{ display: 'inline-block' }} key="all">
        <BlogCategoryChip
          key="all"
          label="All"
          active={!searchParams.has('categories')}
          onClick={() => {
            removeQueryState('categories');
            // const newSer = new URLSearchParams(searchParams);
            // newSer.delete('categories');
            // setSearchParams(newSer);
          }}
        />
      </Box>
      {categories?.map((item: BlogCategoryDto, index) => (
        <Box sx={{ display: 'inline-block', ml: 1 }} key={item.id}>
          <BlogCategoryChip
            key={item.id}
            label={item.name}
            active={searchParams.getAll('categories').includes(item.name)}
            onClick={() => {
              setQueryState({ categories: item.name } as any);
              // const newSearchParams = new URLSearchParams(searchParams);
              // const serachParmasCategoriesValues = newSearchParams.getAll('categories');
              // if (serachParmasCategoriesValues.includes(item.name)) {
              //   newSearchParams.delete('categories');
              //   serachParmasCategoriesValues.splice(
              //     serachParmasCategoriesValues.indexOf(item.name),
              //     1,
              //   );
              //   if (serachParmasCategoriesValues.length > 0) {
              //     newSearchParams.set('categories', serachParmasCategoriesValues[0]);
              //     for (let i = 1; i < serachParmasCategoriesValues.length; i++) {
              //       newSearchParams.append('categories', serachParmasCategoriesValues[i]);
              //     }
              //   }
              // } else {
              //   newSearchParams.append('categories', item.name);
              // }
              // setSearchParams(newSearchParams);
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
export default BlogArchiveFilters;
