import {
  FormControlLabel, Radio, Theme, SxProps, RadioProps,
} from '@mui/material';
import { primaryDark } from '../../color';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio.svg';
import { ReactComponent as RadioSuccessIcon } from '../../assets/icons/radio-success.svg';

interface IProps {
  label?: string;
  value: string | boolean;
  radioProps?: RadioProps;
  labelSx?: SxProps<Theme>;
}

function CustomRadio({
  label, value, radioProps, labelSx,
}: IProps) {
  return (
    <FormControlLabel
      sx={{
        color: primaryDark['600'],
        textTransform: 'uppercase',
        '.MuiTypography-root': { fontFamily: 'Gellix-SemiBold', fontSize: '14px' },
        ...labelSx,
      }}
      value={value}
      control={(
        <Radio
          size="small"
          icon={<RadioIcon />}
          checkedIcon={<RadioSuccessIcon />}
          disableRipple
          {...radioProps}
        />
      )}
      label={label || ''}
    />
  );
}

export default CustomRadio;
