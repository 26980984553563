import { Box, Divider, Stack, Typography } from '@mui/material';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';

const RequestOverview = ({ data }: { data: any }) => {
  function strip(html: string) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  return (
    <Stack gap={4} sx={{ backgroundColor: 'primaryDark.200', p: '1.5rem' }}>
      <Box>
        <BaseInputLabel text="project name" sx={{ fontSize: '12px !important' }} />
        <Typography fontSize="1.75rem">{data?.projectName}</Typography>
      </Box>

      <Box>
        <BaseInputLabel
          text="Project participant information"
          sx={{ fontSize: '12px !important' }}
        />
        <Divider sx={{ mt: '0.5rem', mb: '1rem' }} />
        <Stack direction="row" justifyContent="space-between" gap={4.5} sx={{ pr: '20px' }}>
          <Stack sx={{ width: '50%' }}>
            <BaseInputLabel text="Company name" sx={{ fontSize: '12px !important' }} />
            <Typography>{data?.participantCompanyName}</Typography>
          </Stack>
          <Stack sx={{ width: '50%' }}>
            <BaseInputLabel text="Company url" sx={{ fontSize: '12px !important' }} />
            <Typography>{data?.participantCompanyUrl}</Typography>
          </Stack>
        </Stack>
      </Box>

      <Box>
        <BaseInputLabel text="Description" sx={{ fontSize: '12px !important' }} />
        <Divider sx={{ mt: '0.5rem', mb: '1rem' }} />
        <Typography>{strip(data?.projectDescription)}</Typography>
      </Box>
      <Box>
        <BaseInputLabel text="Contact information" sx={{ fontSize: '12px !important' }} />
        <Divider sx={{ mt: '0.5rem', mb: '1rem' }} />
        <Stack direction="row" justifyContent="space-between" gap={4.5} sx={{ pr: '20px' }}>
          <Stack sx={{ width: '50%' }}>
            <BaseInputLabel text="Contact name" sx={{ fontSize: '12px !important' }} />
            <Typography>{data?.contactName}</Typography>
          </Stack>
          <Stack sx={{ width: '50%' }}>
            <BaseInputLabel text="Email" sx={{ fontSize: '12px !important' }} />
            <Typography>{data?.contactEmail}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default RequestOverview;
