export const getYotubeVideoId = (uri: string) => {
  // list of possible youtube URL's taken from https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486
  // info about youtube video ID structure taken from https://webapps.stackexchange.com/questions/54443/format-for-id-of-youtube-video
  const videoIdRegEx = /^(?:https?:\/\/)?(?:www\.)?(?:(?:(?:m\.)?youtube(?:-nocookie)?\.com\/)(?:(?:watch|ytscreeningroom|)\?(?:[^?&]+&)*?vi?=|(?:vi?|e|embed)\/|user\/[^\/]+\/(?:a\/)?u\/\d+\/|(?:attribution_link|oembed)\?(?:[^?&]+&)*?(?:u|url)=[^&]+?v%3D)|youtu\.be\/)([-_A-Za-z0-9]{11})/;

  if (uri) {
    const result = uri.match(videoIdRegEx);
    const videoId = result ? result[1] : null;

    return videoId;
  }

  return null;
};

export default getYotubeVideoId;
