import { Box } from '@mui/material';

import CustomInput from '../../../../components/custom-input/CustomInput';

const Description = ({ isLoading }: { isLoading: boolean }) => (
  <Box sx={{ pl: '24px', pr: '20px' }}>
    <CustomInput
      input={<CustomInput.Text />}
      name="projectDescription"
      type="text"
      label="Decription"
      sx={{ paddingBottom: '1.25rem', width: '100%' }}
      multiline
      rows={5}
      disabled={isLoading}
    />
  </Box>
);

export default Description;
