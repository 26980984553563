import { Chip, Stack, Typography } from '@mui/material';
import { NotificationDto } from 'tdc-web-backend/notifications/schemas';
import { FunctionComponent, SVGProps, useState } from 'react';
import { Box } from '@mui/system';
import { EnumNotificationCategory } from 'tdc-web-backend/enums/enums';
import NotificationCardLarge from './NotificationCardLarge';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import { mapNotificationCategoryIcons } from './NotificationMenu';
import useCreate from '../../utils/hooks/crud-hooks/useCreate';
import Link from '../link/Link';
import { ReactComponent as SettingsIcon } from '../../assets/icons/notifications/SettingsIcon.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/notifications/CheckmarkIcon.svg';
import theme from '../../theme';

export interface Data {
  id: string;
  title: string;
  description: string;
  createdAt: string;
}

const markAllStyle = {
  transition: '0.3s all ease',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    color: 'primaryLight.300',
  },
};

const chipStyle = {
  transition: '0.5s all ease',
  width: 'fit-content',
  textTransform: 'uppercase',
  fontSize: '11px !important',
  fontWeight: 600,
  mt: 2,
  mr: 1,
  '&.MuiChip-filled': {
    pr: 1,
    bgcolor: 'primaryDark.600',
    color: '#FFF',
  },
  '&.MuiChip-outlined': {
    color: 'primaryDark.500',
    borderColor: 'primaryDark.500',
  },
};

const mapNotificationCategoryTabs = new Map([
  ['', 'All'],
  [EnumNotificationCategory.Content, 'Content'],
  [EnumNotificationCategory.Marketplace, 'Marketplace'],
  [EnumNotificationCategory.E2EManagment, 'E2E Management'],
  [EnumNotificationCategory.Achievements, 'Achievements'],
  [EnumNotificationCategory.TDCNotifications, 'TDA'],
  [EnumNotificationCategory.Premium, 'Premium'],
]);

const NotificationScreen = () => {
  const [activeTab, setActiveTab] = useState('');

  const setFilterString = () => (activeTab ? `&category=${activeTab}` : activeTab);

  const { data: notificationsResponse, refetch } = useGetMany<NotificationDto>({
    queryKey: ['notifications', activeTab],
    resource: `notifications?orderByCreatedOn=desc${setFilterString()}`,
  });

  const { mutate } = useCreate(
    { resource: '/notifications/mark-all-as-seen' },
  );

  const markAllAsRead = () => {
    mutate({}, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const handleTabClick = (index: number, key: string) => {
    setActiveTab(key);
  };

  const notifications = notificationsResponse?.data.results;
  return (
    <Stack
      direction="row"
      width="100%"
      height="100%"
      justifyContent="center"
    >
      <Stack direction="column" width="50%">
        <Stack
          direction="row"
          justifyContent="space-between"
          p={3}
          alignItems="end"
        >
          <Typography
            variant="body3"
            fontWeight={600}
            color="primaryDark.500"
            sx={markAllStyle}
            onClick={markAllAsRead}
          >
            Mark all as read
          </Typography>
          {/* Temporarily hide this part */}
          {/* <Link
            to="../dashboard/notifications"
          >
            <Typography
              color="primaryLight.500"
              variant="heading4"
              justifyContent="center"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}
            >
              Notification settings
              {' '}
              <SettingsIcon />
            </Typography>
          </Link> */}
        </Stack>
        <Stack>
          {
            notifications?.length === 0
              ? (
                <Typography
                  color="primary"
                  variant="body1"
                  fontSize={18}
                  sx={{
                    p: 3,
                    textAlign: 'center',
                  }}
                >
                  0 notifications
                </Typography>
              )
              : notifications?.map(
                (notification: NotificationDto) => (
                  <NotificationCardLarge
                    data={notification}
                    Icon={mapNotificationCategoryIcons.get(notification.category) as
                    FunctionComponent<SVGProps<SVGSVGElement> & {
                      title?: string | undefined;
                    }>}
                  />
                ),
              )
          }
        </Stack>
      </Stack>
      <Box
        width="50%"
        height="100%"
        sx={{
          borderLeft: '1px solid',
          borderColor: 'secondaryBlue.100',
          // position: 'relative',
        }}
      >
        <Stack
          width="80%"
          p={3}
          sx={{
            position: 'sticky',
            top: theme.spacing(11),
          }}
        >
          {/* Temporarily hide this part */}
          {/*  <Typography
            variant="heading4"
            fontWeight={400}
            color="primaryDark.500"
          >
            Show
          </Typography>
          <Stack direction="row" flexWrap="wrap" mt={1}>
            {
              Array.from(mapNotificationCategoryTabs).map(
                ([key, value], index) => (activeTab === key
                  ? (
                    <Chip
                      sx={chipStyle}
                      label={value}
                      variant="filled"
                      deleteIcon={<CheckmarkIcon />}
                      onDelete={() => null}
                    />
                  )
                  : (
                    <Chip
                      sx={chipStyle}
                      onClick={() => handleTabClick(index, key)}
                      label={value}
                      variant="outlined"
                    />
                  )),
              )
            }
          </Stack> */}
        </Stack>
      </Box>
    </Stack>
  );
};
export default NotificationScreen;
