import { Stack } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import TaskEditImg from '../../../../assets/img/task-edit-img.png';
import TaskEditModal from '../Modals/TaskEditModal';
import TaskDeleteModal from '../Modals/TaskDeleteModal';

export interface TaskEditCardProps {
  taskData: TaskDto;
  sx?: SxProps<Theme> | undefined;
}

const TaskEditCard = ({
  taskData,
  sx,
}: TaskEditCardProps) => (
  <Stack
    spacing={8}
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      component="img"
      sx={{
        width: '8.5em',
        mx: 'auto',
        mt: '1em',
      }}
      src={TaskEditImg}
      alt="task-edit-img"
    />
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <TaskDeleteModal task={taskData} />
      <TaskEditModal task={taskData} />
    </Stack>
  </Stack>
);

export default TaskEditCard;
