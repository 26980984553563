import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import Button from '../../../../../components/button/Button';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

export interface CancelProjectModalProps {
  projectData: ProjectDto;
}

const ProjectCancelModal = ({ projectData }: CancelProjectModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { projectId } = useParams();
  const refresh = useRefresh();
  const { mutate: update } = useUpdate({ resource: '/projects' });

  const handleCancel = () => {
    update(
      {
        data: {
          status: 'canceled',
        },
        id: projectId as string,
      },
      {
        onSuccess: () => {
          refresh();
          setIsOpen(false);
        },
      },
    );
  };

  return (
    <>
      <Button
        color="secondary"
        sx={{
          width: '90%',
          border: 2,
          borderRadius: '100px',
          fontWeight: 500,
        }}
        variant="outlined"
        size="large"
        onButtonClick={() => setIsOpen(true)}
      >
        Cancel
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.25em',
                      fontWeight: 600,
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    Cancel project
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 500,
                      mt: 3,
                    }}
                  >
                    Are you sure you want to cancel project?
                  </Typography>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onButtonClick={() => handleCancel()}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};
export default ProjectCancelModal;
