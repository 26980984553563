import { Box, Divider, IconButton, List, ListItemButton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useFormContext } from 'react-hook-form';
import { MaterialsDto } from 'tdc-web-backend/timed-project-contents/schemas';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { ReactComponent as BoxArrowUpRightLeft } from '../../../../assets/icons/project-icons/BoxArrowUpRightLeft.svg';
import { ReactComponent as JournalIcon } from '../../../../assets/icons/journal.svg';
import { ReactComponent as MapIcon } from '../../../../assets/icons/map-fill.svg';
import { ReactComponent as JournalsIcon } from '../../../../assets/icons/journals-fill.svg';
import { ReactComponent as PaperclipIcon } from '../../../../assets/icons/paperclip.svg';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import { primaryDark, secondaryBlue } from '../../../../color';
import Editor from '../../../../components/editor/Editor';
import CaseStudyCard from '../../../../components/case-study-card/CaseStudyCard';
import StarBadge from '../../../../components/star-badge/StarBadge';
import SingleSlideCarousel, {
  RenderCarouselItemProps,
} from '../../../../components/single-slide-carousel/SingleSlideCarousel';
import { iconUrlForFile } from '../../../../utils/helpers';
import CustomBackAndNextButtons from '../../../../components/single-slide-carousel/custom-back-and-next-buttons/CustomBackAndNextButtons';
import VerifiedChip from '../../../../components/verified-chip/VerifiedChip';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';

const CAROUSEL_ITEM_WIDTH = 270;

const Preview = () => {
  const userData = useGetLocalStorageUserData();
  const companyId = userData?.membership?.company;

  const { getValues } = useFormContext();

  const { data: companyData } = useReferenceOne<CompanyDto>({
    enabled: !!companyId,
    resource: 'companies',
    id: companyId,
    refetchOnWindowFocus: false,
  });

  const Heading = ({ icon, title }: { icon: ReactNode; title: string }) => (
    <Stack
      sx={{
        fill: primaryDark[500],
      }}
      alignItems="center"
      direction="row"
    >
      {icon}
      <Typography color="primaryDark.500" variant="heading5" ml={1.375}>
        {title}
      </Typography>
    </Stack>
  );

  return (
    <Box
      sx={{
        background:
          'linear-gradient(240.98deg, rgba(230, 232, 255, 0.2) 0%, rgba(255, 234, 225, 0.2) 68.46%, rgba(255, 251, 234, 0.2) 99.58%)',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        p: 2,
        pr: 0,
      }}
    >
      <Stack spacing={7}>
        {/* company logo & name */}
        <Stack spacing={2} direction="row" alignItems="center">
          <Box
            sx={{
              width: 50,
              height: 50,
              borderRadius: '4px',
              backgroundImage: `url(${companyData?.avatar})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          />

          <Typography variant="heading2" color="primaryDark.600">
            {companyData?.name}
          </Typography>

          <IconButton
            sx={{
              borderRadius: '3px',
              p: 0.6,
              bgcolor: secondaryBlue[100],
            }}
            // TODO: connect this to company profile
            // href={`../end-to-end/project`}
            // target="_blank"
          >
            <BoxArrowUpRightLeft fill={secondaryBlue[700]} />
          </IconButton>
        </Stack>

        {/* team description */}
        <Stack spacing={2}>
          <Heading title="Team description" icon={<JournalIcon />} />

          <Editor items={[]} isEditable={false} content={getValues?.('overview.description')} />
        </Stack>

        {/* top-level strategy */}
        <Stack spacing={2}>
          <Heading title="Top-level strategy" icon={<MapIcon />} />

          <Editor items={[]} isEditable={false} content={getValues?.('overview.strategy')} />
        </Stack>

        {/* case studies carousel */}
        {getValues('caseStudiesPreview').length > 0 && (
          <Stack spacing={2}>
            <Heading title="Case study portfolio" icon={<JournalsIcon />} />

            <Stack position="relative" width="100%">
              <SingleSlideCarousel
                items={getValues('caseStudiesPreview') || []}
                carouselItemWidth={CAROUSEL_ITEM_WIDTH}
                activeIndexItem={0}
                sx={{
                  width: '100%',
                  pb: '30px',
                  ml: '-16px',
                  '&>*:nth-child(1)': {
                    marginLeft: '18px',
                  },
                }}
                renderCustomBackAndNextButtons={({ back, next }) => (
                  <CustomBackAndNextButtons back={back} next={next} />
                )}
                renderCarouselItem={({ item }: RenderCarouselItemProps<any>) => {
                  if (item) {
                    return (
                      <CaseStudyCard
                        sx={{ minWidth: CAROUSEL_ITEM_WIDTH, maxWidth: CAROUSEL_ITEM_WIDTH }}
                        id={item.id}
                        topChip={<VerifiedChip />}
                        bottomComponent={
                          <CustomReferenceField
                            displayName={'name'}
                            id={item.id}
                            resource="case-studies"
                            renderComponent={(data: BaseCaseStudy) => (
                              <StarBadge rating={data.score as number} />
                            )}
                          />
                        }
                        coverImg={item.coverImg}
                        caseStudyName={item.title}
                        companyLogo={item.companyLogo}
                        companyName={item.companyName}
                      />
                    );
                  }

                  return null;
                }}
              />
            </Stack>
          </Stack>
        )}

        <Divider sx={{ mr: '1rem !important', borderColor: 'secondaryBlue.100' }} />

        {getValues('materials').length > 0 && (
          <Stack>
            <Heading title="Related materials" icon={<PaperclipIcon />} />

            <List>
              {getValues('materials').map((material: MaterialsDto) => (
                <ListItemButton
                  component="a"
                  href={material.file || ''}
                  sx={{
                    px: 0,
                  }}
                  download
                >
                  <Stack>
                    <Stack mb={0.75} direction="row" alignItems="center">
                      <Box
                        width={10}
                        height={10}
                        sx={{
                          border: `2px solid ${primaryDark[300]}`,
                          borderRadius: '50%',
                          bgcolor: 'primaryDark.500',
                          mr: 1.25,
                        }}
                      />
                      <Typography variant="body3" color="primaryDark.600">
                        {material.fileName}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" pl={1.25}>
                      <Box
                        component="img"
                        sx={{ width: 30, height: 30 }}
                        src={(() => iconUrlForFile(material?.fileName))()}
                        mr={1.25}
                      />

                      <Typography variant="body3" fontWeight="600" color="primaryDark.600">
                        {material.fileName}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Preview;
