// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {}

const CustomScrollFade = (props: Props) => {
  const isScrollable = window.innerHeight !== document.body.clientHeight;
  return (
    isScrollable ? (
      <div style={{
        height: '90px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 51%, rgba(255,255,255,0) 100%)',
        pointerEvents: 'none',
      }}
      />
    ) : <></>
  );
};

export default CustomScrollFade;
