import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { borderColor } from '@mui/system';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/public-pages/arrow-right.svg';
import { primaryDark, primaryLight } from '../../color';

export type Variants =
  | 'primary'
  | 'primaryText'
  | 'primaryLight'
  | 'secondary'
  | 'secondaryText'
  | 'secondaryDark';

export type CustomButtonProps = {
  variant?: Variants;
  withBackArrow?: boolean;
  withNextArrow?: boolean;
} & Omit<LoadingButtonProps, 'variant'>;

const determineDefaultColor = (variant: Variants) => {
  switch (variant) {
    case 'primary':
      return '#fff';
    case 'primaryText':
    case 'primaryLight':
    case 'secondary':
    case 'secondaryText':
      return primaryLight[500];
    case 'secondaryDark':
      return primaryDark[800];
    default:
      return primaryLight[500];
  }
};

const determineDisabledColor = (variant: Variants) => {
  switch (variant) {
    case 'primary':
      return '#fff';
    case 'primaryText':
      return primaryLight[300];
    case 'primaryLight':
    case 'secondary':
    case 'secondaryText':
      return primaryLight[200];
    case 'secondaryDark':
      return primaryDark[200];
    default:
      return primaryLight[200];
  }
};

const CustomButton = ({
  children,
  variant = 'primary',
  sx,
  withBackArrow = false,
  withNextArrow = false,
  ...props
}: CustomButtonProps) => {
  const styleVariations = {
    primary: {
      bgcolor: 'primaryLight.500',
      border: '3px solid',
      borderColor: 'primaryLight.500',
      '&:hover': {
        boxShadow: 'none',
        borderColor: 'primaryLight.100',
        bgcolor: 'primaryLight.500',
      },
      '&:disabled': {
        color: determineDisabledColor(variant),
        border: 'none',
        bgcolor: 'primaryLight.100',
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
    primaryText: {
      bgcolor: 'transparent',
      minWidth: 0,
      width: 'fit-content',
      height: 'fit-content',
      p: 0,
      '&:hover': {
        bgcolor: 'transparent',
        textDecoration: 'underline',
      },
      '&:disabled': {
        bgcolor: 'transparent',
        textDecoration: 'underline',
        color: determineDisabledColor(variant),
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
    primaryLight: {
      bgcolor: 'common.white',
      border: '1px solid',
      borderColor: 'primaryLight.200',
      '&:hover': {
        boxShadow: 'none',
        bgcolor: 'primaryLight.50',
      },
      '&:disabled': {
        color: determineDisabledColor(variant),
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
    secondary: {
      bgcolor: 'common.white',
      border: '1px solid',
      borderColor: 'primaryLight.500',
      '&:hover': {
        bgcolor: 'primaryLight.50',
      },
      '&:disabled': {
        boxShadow: 'none',
        color: determineDisabledColor(variant),
        borderColor: 'primaryLight.200',
        bgcolor: 'common.white',
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
    secondaryDark: {
      bgcolor: 'common.white',
      border: '1px solid',
      borderColor: 'primaryDark.250',
      '&:hover': {
        bgcolor: 'transparent',
        borderColor: 'primaryLight.200',
      },
      '&:disabled': {
        boxShadow: 'none',
        color: determineDisabledColor(variant),
        borderColor: 'primaryDark.200',
        bgcolor: 'primaryDark.50',
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
    secondaryText: {
      bgcolor: 'transparent',
      minWidth: 0,
      width: 'fit-content',
      height: 'fit-content',
      p: '8px 12px',
      '&:hover': {
        bgcolor: 'transparent',
        textDecoration: 'underline',
      },
      '&:disabled': {
        bgcolor: 'transparent',
        textDecoration: 'underline',
        color: determineDisabledColor(variant),
        svg: {
          color: determineDisabledColor(variant),
        },
      },
    },
  };

  return (
    <LoadingButton
      sx={{
        transition: '0.3s all ease',
        textTransform: variant === 'secondaryText' ? 'capitalize' : 'uppercase',
        borderRadius: '4px',
        px: '0.875rem',
        height: '2.8125rem',
        minWidth: '7.5rem',
        fontSize: '14px',
        fontFamily: 'Gellix-SemiBold',
        '&.MuiLoadingButton-loading': {
          '.MuiLoadingButton-loadingIndicator': {
            color: determineDefaultColor(variant),
          },
          '.MuiButton-endIcon': {
            display: 'none',
          },
        },
        ...styleVariations[variant],
        color: props.loading ? 'transparent !important' : determineDefaultColor(variant),
        ...sx,
      }}
      startIcon={
        withBackArrow ? (
          <ArrowRightIcon
            fill={props.disabled ? determineDisabledColor(variant) : determineDefaultColor(variant)}
            style={{ transform: 'scale(-1, -1)' }}
          />
        ) : (
          props.startIcon
        )
      }
      endIcon={
        withNextArrow ? (
          <ArrowRightIcon
            fill={props.disabled ? determineDisabledColor(variant) : determineDefaultColor(variant)}
          />
        ) : (
          props.endIcon
        )
      }
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomButton;
