import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import {
  mapCompanySize,
  mapCountry,
  mapProjectSize,
  mapHourlyRate,
  EnumCountry,
  EnumCompanySize,
  EnumProjectSize,
  EnumProjectHourlyRate,
} from 'tdc-web-backend/enums/enums';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { Dispatch, SetStateAction } from 'react';
import EditCompanyOverView from '../edit/CompanyOverviewEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyOverviewModal {
  isOverviewModalOpen: boolean;
  setIsOverviewModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyOverview = ({ isOverviewModalOpen, setIsOverviewModalOpen }: CompanyOverviewModal) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack width="100%" spacing={1}>
      {[
        {
          label: 'COMPANY SIZE',
          value: mapCompanySize.get(companyData?.companySize as EnumCompanySize),
        },
        {
          label: 'HEADQUARTERS',
          value: companyData?.headquartersLocation?.state
            ? `${companyData?.headquartersLocation.city}, ${
                companyData?.headquartersLocation.state
              }, ${mapCountry.get(companyData?.headquartersLocation?.country as EnumCountry)}`
            : `${companyData?.headquartersLocation?.city}, ${mapCountry.get(
                companyData?.headquartersLocation?.country as EnumCountry,
              )}`,
        },
        // { label: 'FOUNDED', value: companyData?.founded },
        {
          label: 'AVG. PROJECT SIZE',
          value: mapProjectSize.get(companyData?.averageProjectSize as EnumProjectSize),
        },
        {
          label: 'HOURLY RATE',
          value: mapHourlyRate.get(companyData?.hourlyRate as EnumProjectHourlyRate),
        },
      ].map((data, index) => (
        <Stack
          key={index}
          direction={{ xl: 'row' }}
          justifyContent="flex-start"
          gap={1}
          alignItems="flex-start"
        >
          <Typography
            color="primaryDark.500"
            fontWeight="700"
            fontSize="11px"
            width="40%"
            whiteSpace="nowrap"
            align="left"
            marginTop="8px"
          >
            {data.label}
          </Typography>
          <CustomChip
            tooltipTitle={data.value}
            label={data.value && truncate(data.value, chipLabelTruncateValue as number)}
            sx={{
              flexGrow: 0,
              flexShrink: 5,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
            }}
          />
        </Stack>
      ))}

      {isOverviewModalOpen && (
        <EditCompanyOverView
          isModalOpen={isOverviewModalOpen}
          setIsModalOpen={setIsOverviewModalOpen}
        />
      )}
    </Stack>
  );
};

export default CompanyOverview;
