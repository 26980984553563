/* eslint-disable no-use-before-define */
import { Modal, ModalProps, Paper, Stack, SxProps, Theme } from '@mui/material';

type CustomModalProps = {
  children: JSX.Element | JSX.Element[];
  width?: string | Record<'xs' | 'sm' | 'md' | 'bg' | 'lg' | 'xl' | 'xxl', string>;
  paperStyleProps?: SxProps<Theme> | undefined;
  sx?: SxProps<Theme>;
} & ModalProps;

const CustomModal = ({ children, width = '45%', paperStyleProps, sx, ...props }: CustomModalProps) => (
  <Modal
    disableAutoFocus
    disableEnforceFocus
    disableScrollLock
    {...props}
    BackdropProps={{
      style: {
        background: 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)',
        opacity: 0.9,
      },
    }}
  >
    <Stack sx={{ width, ...modalStyle, ...sx }}>
      <Paper
        sx={{
          padding: '2rem',
          borderRadius: '0.5rem',
          boxShadow: 'none',
          ...paperStyleProps,
        }}
      >
        {children}
      </Paper>
    </Stack>
  </Modal>
);

export default CustomModal;

const modalStyle: any = {
  boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
  border: '2px solid #FFFFFF',
  borderRadius: '1rem',
  p: '1.3rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  background: 'transparent',
  transform: 'translate(-50%, -50%)',
};
