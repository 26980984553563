import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import { getBase64 } from '../../helpers';

export interface FileWithUploadProgress {
  file: File;
  isUploadInProgress: boolean;
  uploadProgress: number;
  error?: string;
}

export interface UploadFileProps<Response> {
  resource: string;
  files?: File[];
  onFilesStartUpload: (filesWithUploadProgresses: FileWithUploadProgress[]) => void;
  onAllFilesCompletedUpload: (onSuccessMutateAsyncDataArray: AxiosResponse<Response>[]) => void;
}

const useUploadMultipleFiles = <Response, Request extends { fileName: string }>({
  resource,
  files,
  onFilesStartUpload,
  onAllFilesCompletedUpload,
}: UploadFileProps<Response>) => {
  // initially for every file we create a fileNameAndUploadProgressObj which holds keys that are
  // file names and values which is progress for each respectively

  // example of fileNameAndUploadProgressObj object:
  // {
  //   file_example_MP4_640_3MG.mp4: 0,
  //   file_example_MP4_1280_10MG.mp4: 0
  // }
  // where keys are file names, and values their upload progress
  const [fileNameAndUploadProgressObj, setFileNameAndUploadProgressObj] = useState<
    Record<string, number>
  >({});

  const [isUploadingInProgress, setIsUploadingInProgress] = useState<boolean>(false);
  const [isEveryFileFinishedUploading, setIsEveryFileFinishedUploading] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const { mutateAsync } = useMutation<AxiosResponse<Response>, unknown, Request>([
    `${resource}-create`,
  ]);

  useEffect(() => {
    if (files && files.length > 0) {
      const tempfileNameAndUploadProgressObj: Record<string, number> = {};

      files.forEach((file) => {
        tempfileNameAndUploadProgressObj[file.name] = 0;
      });

      // initially for every file we create a fileNameAndUploadProgressObj which holds keys that are
      // file names and values which is progress for each respectively.
      setFileNameAndUploadProgressObj(tempfileNameAndUploadProgressObj);

      const convertFilesToBase64AndCallMutation = async () => {
        const base64files = await Promise.all(
          files.map(async (file) => {
            const base64 = await getBase64(file);
            return base64;
          }),
        );

        const onSuccessMutateAsyncDataArray = await Promise.all(
          base64files.map(async (file, index) => {
            const data = await mutateAsync(
              {
                file: file as string,
                fileName: files[index].name as string,
                description: '',
              } as unknown as Request,
              {
                onError: (err) => {
                  setError(true);
                },
              },
            );

            // return data.data;
            return data;
          }),
        );

        onAllFilesCompletedUpload(onSuccessMutateAsyncDataArray);
      };

      convertFilesToBase64AndCallMutation();
    }
  }, [files]);

  useEffect(() => {
    if (files && files.length > 0) {
      const fileNameAndUploadProgressObjFileNames = Object.keys(fileNameAndUploadProgressObj);

      // to every file append uploadProgress and isUploadInProgress
      // using the fileNameAndUploadProgressObj
      const enchancedFiles = files.map((file, index) => {
        if (error) {
          return {
            file,
            uploadProgress: 0,
            isUploadInProgress: false,
            error: 'Something went wrong.',
          };
        }

        if (file.name === fileNameAndUploadProgressObjFileNames[index]) {
          return {
            file,
            uploadProgress: fileNameAndUploadProgressObj[file.name],
            isUploadInProgress: fileNameAndUploadProgressObj[file.name] < 100,
          };
        }

        return {
          file,
        };
      });

      onFilesStartUpload(enchancedFiles as FileWithUploadProgress[]);

      const isEveryFileFinishedUploadingLocalVariable = enchancedFiles.every(
        (item) => item.isUploadInProgress === false,
      );

      setIsEveryFileFinishedUploading(isEveryFileFinishedUploadingLocalVariable);

      if (isEveryFileFinishedUploading) setIsUploadingInProgress(false);
    }
  }, [files, fileNameAndUploadProgressObj, error]);

  // so for each file, a axios.post is triggered,
  // because of that onUploadProgress will get triggered
  // for each one of that mutation and also there is data for each one

  const create = (data: Request): Promise<AxiosResponse<Response>> =>
    axios.post(`${resource}`, data, {
      onUploadProgress: (event) => {
        setIsUploadingInProgress(true);

        // in short: for every file name append its upload progress;
        // we go through the fileNameAndUploadProgressObj's keys (file names) and check
        // whether at least one of these keys
        // is equal to the current mutation's data.fileName; if it is,
        // then spread the previous fileNameAndUploadProgressObj value
        // (so we don't lose the previous' key-value pair ofc) and update the upload
        // progress for that (current data.fileName's) file
        if (
          Object.keys(fileNameAndUploadProgressObj).some((fileName) => fileName === data.fileName)
        ) {
          setFileNameAndUploadProgressObj((prevValue) => ({
            ...prevValue,
            [data.fileName]: Math.round((event.loaded * 100) / event.total),
          }));
        }
      },
    });

  const queryClient = useQueryClient();

  queryClient.setMutationDefaults([`${resource}-create`], {
    mutationFn: create,
  });

  return { isUploadingInProgress, isEveryFileFinishedUploading };
};

export default useUploadMultipleFiles;
