import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../api/axios';

export type CreateProps = {
  resource: string;
  onSuccess?: () => void;
  onError?: () => void;
};

// removed extends unknown; eslint: Constraining the generic type `Response` to `unknown`
// does nothing and is unnecessary.
const useCreate = <Response, Request>({ resource }: CreateProps) => {
  const create = (data: Request): Promise<AxiosResponse<Response>> =>
    axios.post(`${resource}`, data);

  const queryClient = useQueryClient();

  queryClient.setMutationDefaults([`${resource}-create`], {
    mutationFn: create,
  });

  // added AxiosResponse<Response> here because without it, the response thinks it returns
  // only data of type Response and it then collides with the actual response which is
  // AxiosResponse<Response> which is basically standard response object with keys
  // config, headers, request, status, status text and data which has our Response type
  //
  return useMutation<AxiosResponse<Response>, AxiosError, Request>([`${resource}-create`]);
};

export default useCreate;
