import { Input, TextField } from '@material-ui/core';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import { dateToLocalDateString } from '../../../../utils/helpers';
import useTaskManagementStore from '../useTaskManagementStore';

export default function StepNewTask() {
  const taskManagementInput = useTaskManagementStore((state) => state.taskManagementInput);
  const setTaskManagementInput = useTaskManagementStore((state) => state.setTaskManagementInput);

  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);
  const requiredMessage = 'This field is required';

  const {
    register,
    watch,
    control,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: taskManagementInput.title || '',
      estStartOn: taskManagementInput.estStartOn || '',
      estEndOn: taskManagementInput.estEndOn || '',
      description: taskManagementInput?.description || '',
    },
  });

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setTaskManagementInput(watch()));
    // reset(taskManagementInput);
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ marginBottom: '1em' }}>
        <Stack spacing={4}>
          <Stack direction="row">
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder="Task name"
                  fullWidth
                  {...register('title', { required: requiredMessage })}
                  value={!value ? taskManagementInput?.title : value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
            <Controller
              control={control}
              name="estStartOn"
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  onChange={(e: any) =>
                    setValue('estStartOn', dateToLocalDateString(new Date(e as string)))
                  }
                  value={field.value || null}
                />
              )}
            />

            <Controller
              control={control}
              name="estEndOn"
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  onChange={(e: any) =>
                    setValue('estEndOn', dateToLocalDateString(new Date(e as string)))
                  }
                  value={field.value || null}
                />
              )}
            />
          </Stack>
          <Stack direction="row">
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder="Description"
                  fullWidth
                  minRows={5}
                  multiline
                  {...register('description')}
                  value={!value ? taskManagementInput?.description : value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
