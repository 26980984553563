import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { MilestoneDto, UpdateMilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../../components/button/Button';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';

type DueDateModalProps = {
  data: MilestoneDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  propertyName?: 'buyerInvoiceDueDate' | 'sellerInvoiceDueDate';
};

const DueDateModal = ({
  data,
  isOpen,
  setIsOpen,
  propertyName = 'sellerInvoiceDueDate',
}: DueDateModalProps) => {
  const formProviderData = useForm({
    defaultValues: {
      dueDate: new Date(data[propertyName] ?? Date.now()),
    },
  });

  const { mutate: update } = useUpdate<MilestoneDto, UpdateMilestoneDto>({
    resource: '/milestones',
  });
  const refresh = useRefresh();

  const handleUpdate = () => {
    update(
      {
        data: {
          [propertyName]: formProviderData.getValues('dueDate'),
        },
        id: data.id,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '30%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper
              sx={{
                padding: '2em',
              }}
            >
              <Stack spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '1.3em',
                      fontWeight: 600,
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    {propertyName === 'sellerInvoiceDueDate' ? 'Seller' : 'Buyer'} Invoice Due Date
                  </Typography>

                  <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        fontWeight: 600,
                      }}
                    >
                      Current value:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '1em',
                        fontWeight: 800,
                      }}
                    >
                      {data[propertyName] !== null
                        ? new Date(data[propertyName] as Date).toLocaleDateString()
                        : '-'}
                    </Typography>
                  </Stack>

                  <Typography
                    sx={{
                      fontSize: '1em',
                      fontWeight: 600,
                      mt: 4,
                      mb: 0,
                    }}
                  >
                    Set new value:
                  </Typography>

                  <FormProvider {...formProviderData}>
                    <ControlledDateInput name="dueDate" />
                  </FormProvider>
                </Stack>
                <Divider />
                <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="text"
                    onButtonClick={() => setIsOpen(false)}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onButtonClick={handleUpdate}
                    sx={{
                      borderRadius: '100px',
                      fontWeight: 500,
                    }}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Stack>
      </Modal>
    </>
  );
};

export default DueDateModal;
