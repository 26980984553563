import { Box, Stack, Typography } from '@mui/material';

const BlogArchiveHeader = () => (
  //   <Stack direction="column">
  <Stack
    direction="column"
    alignItems="flex-start"
    sx={{ display: 'flex', px: { xs: 1 } }}
    spacing={5}
  >
    <Typography variant="publicHeadline6" color="primaryDark.900">
      Spona Blog
      <Box
        sx={{
          backgroundColor: 'primaryLight.500',
          width: '100%',
          height: '0.75rem',
          borderRadius: '7rem',
        }}
      />
    </Typography>
    <Typography variant="publicBody3Regular" color="primaryDark.900">
      Stay informed with Spona's blog where you'll find updates on our digital service solutions,
      industry trends, news,profiles on standout sellers, Spona Stars, and projects that are
      redefining the digital service marketplace.
    </Typography>
    {/* </Stack> */}
  </Stack>
);

export default BlogArchiveHeader;
