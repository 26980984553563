import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';

const Strategy = () => (
  <ControlledTextEditor
    label="top-level strategy"
    name="overview.strategy"
    items={TimedProjectEditorMenu}
    required
  />
);

export default Strategy;
