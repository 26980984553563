import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { mapCountry, mapIndustry, mapProjectSize, mapService } from 'tdc-web-backend/enums/enums';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import { primaryDark, secondaryBlue } from '../../../../../color';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';
import { ReactComponent as TrophyIcon } from '../../../../../assets/icons/trophy.svg';
import { ReactComponent as BullseyeIcon } from '../../../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as BriefcaseCheckIcon } from '../../../../../assets/icons/layout-icons/BriefcaseCheckIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../../../assets/icons/layout-icons/LightBulbIcon.svg';
import { ReactComponent as ArrowRightShortIcon } from '../../../../../assets/icons/arrow-right-short.svg';
import Editor from '../../../../../components/editor/Editor';
import ChipGroup from '../../CaseStudiesDetails/CaseStudyChipGroup';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../../enums';
import CustomButton from '../../../../../components/button/CustomButton';
import { CaseStudyEditorMenu } from '../../../../../components/editor/menu-option-lists/menu-item-lists';
import useGetQueryParams from '../../../../../utils/hooks/useGetQueryParams';
import { formatDateFns } from '../../../../../utils/helpers';
import { CaseStudy } from 'tdc-web-backend/case-studies/entities';

const Divider = () => <Stack width="100%" bgcolor="secondaryBlue.100" height="1px" />;

const CaseStudyReview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const query = useGetQueryParams();

  const { data: caseStudy } = useGetOne<CaseStudy>({
    enabled: !!params.caseStudyId,
    resource: `case-studies/${params.caseStudyId}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: caseStudyContent } = useGetOne<CaseStudyContentDto>({
    enabled: !!caseStudy?.data.content,
    resource: `case-studies-content/${caseStudy?.data.content}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const reviewEnabled =
    caseStudy?.data.review != null && caseStudy?.data.review.requestPublish == false;

  return (
    <Stack maxWidth={1100} mx="auto" my={20}>
      <Stack direction="row">
        <Stack width="60%" border={`1px solid ${secondaryBlue[100]}`}>
          <Stack p={3}>
            <Typography mb={2} color="primaryDark.600" variant="heading1">
              {caseStudyContent?.data.title}
            </Typography>
            <Typography mb={5} color="primaryDark.500" variant="body3">
              {formatDateFns(caseStudyContent?.data.published)}
            </Typography>
            <Box component="img" borderRadius="12px" src={caseStudyContent?.data.file} />
          </Stack>
          <Divider />
          <Stack p={3}>
            <Stack mb={1.25} direction="row" alignItems="center" spacing={1}>
              <TrophyIcon fill={primaryDark[500]} />
              <BaseInputLabel text="project goals" />
            </Stack>
            {caseStudyContent?.data?.description?.goals && (
              <Editor
                items={CaseStudyEditorMenu}
                content={caseStudyContent?.data?.description?.goals}
                isEditable={false}
                key="goals"
              />
            )}
          </Stack>
          <Divider />
          <Stack p={3}>
            <Stack mb={1.25} direction="row" alignItems="center" spacing={1}>
              <BullseyeIcon fill={primaryDark[500]} />
              <BaseInputLabel text="challenges" />
            </Stack>
            {caseStudyContent?.data?.description?.challenges && (
              <Editor
                items={CaseStudyEditorMenu}
                content={caseStudyContent?.data?.description?.challenges}
                isEditable={false}
                key="challenges"
              />
            )}
          </Stack>
          <Divider />
          <Stack p={3}>
            <Stack mb={1.25} direction="row" alignItems="center" spacing={1}>
              <LightBulbIcon fill={primaryDark[500]} />
              <BaseInputLabel text="solution" />
            </Stack>
            {caseStudyContent?.data?.description?.solutions && (
              <Editor
                items={CaseStudyEditorMenu}
                content={caseStudyContent?.data?.description?.solutions}
                isEditable={false}
                key="solution"
              />
            )}
          </Stack>
          <Divider />
          <Stack p={3}>
            <Stack mb={1.25} direction="row" alignItems="center" spacing={1}>
              <BriefcaseCheckIcon fill={primaryDark[500]} />
              <BaseInputLabel text="results" />
            </Stack>
            {caseStudyContent?.data?.description?.results && (
              <Editor
                items={CaseStudyEditorMenu}
                content={caseStudyContent?.data?.description?.results}
                isEditable={false}
                key="results"
              />
            )}
          </Stack>
        </Stack>
        <Stack width="40%" direction="column" alignContent="center" px={6}>
          <Avatar
            style={{ margin: '0 auto' }}
            alt="company logo"
            src={caseStudyContent?.data?.seller.avatar}
            sx={{ width: 76, height: 76 }}
          />
          <Typography textAlign="center" mt={2} mb={8} variant="body1">
            {caseStudyContent?.data?.seller.name}
          </Typography>
          <Stack py={2.25}>
            <ChipGroup
              label="services"
              choices={mapService}
              values={caseStudyContent?.data?.details?.services}
            />
          </Stack>

          <Divider />
          <Stack py={2.25}>
            <ChipGroup
              label="tech platforms"
              choices={mapTechPlatform}
              values={caseStudyContent?.data?.details?.platforms}
            />
          </Stack>
          <Divider />
          <Stack py={2.25}>
            <ChipGroup
              label="programming tools"
              choices={mapTechTool}
              values={caseStudyContent?.data?.details?.tools}
            />
          </Stack>

          <Divider />
          <Stack py={2.25}>
            <ChipGroup
              label="Programming languages"
              choices={mapTechLanguage}
              values={caseStudyContent?.data?.details?.languages}
            />
          </Stack>
          <Divider />

          <Stack py={2.25}>
            <ChipGroup
              label="buyer industry"
              choices={mapIndustry}
              values={caseStudyContent?.data?.details?.industries}
            />
          </Stack>

          <Divider />
          <Stack py={2.25}>
            <ChipGroup
              label="Target market"
              choices={mapCountry}
              values={caseStudyContent?.data?.details?.geographyFocus}
            />
          </Stack>
          <Divider />

          <Stack py={2.25}>
            <BaseInputLabel text="Project size" />
            <Typography variant="heading4">
              {caseStudyContent?.data?.details?.projectSize
                ? mapProjectSize.get(caseStudyContent?.data?.details.projectSize)
                : '--'}
            </Typography>
          </Stack>
          <Divider />
          {reviewEnabled && (
            <CustomButton
              sx={{ textTransform: 'uppercase', mt: 6 }}
              endIcon={<ArrowRightShortIcon fill={primaryDark[150]} />}
              variant="primary"
              disabled={caseStudyContent?.data.review?.requestPublish}
              onClick={() =>
                navigate(
                  `../case-study-review-submit-public/${params?.caseStudyId}/${query.get(
                    'token',
                  )}/${query.get('content')}`,
                )
              }
            >
              Write review
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CaseStudyReview;
