import { useParams } from 'react-router-dom';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import {
  Stack,
} from '@mui/material';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { FlagDto } from 'tdc-web-backend/flags/schema';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import GeneralDetailsSection from './GeneralDetailsSection';
import ProjectStatusCard from './ProjectStatusCard';
import ContractDataGrid from './ContractDataGrid';
import MilestoneDataGrid from './MilestoneDataGrid';
import FlagDataGrid from './FlagDataGrid';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';

const AdminProjectDetails = () => {
  const { projectId } = useParams();
  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: projectId,
    enabled: !!projectId,
  });
  const { data: milestonesListData } = useGetMany<MilestoneDto>({
    resource: `/milestones?company=${projectData?.buyer}&project=${projectData?.id}`,
    enabled: !!projectData,
  });
  const { data: contractsListData } = useGetMany<ContractDto>({
    resource: `/contracts?company=${projectData?.buyer}&project=${projectData?.id}`,
    enabled: !!projectData,
  });

  const { data: flagListData } = useGetMany<FlagDto>({
    resource: `/flags?project=${projectId}`,
  });

  const contractList = contractsListData?.data.results;

  const initialContract = contractList?.[contractList?.length - 1];

  return (
    <Stack width="100%" sx={{ mt: 4 }} spacing={4}>
      <Stack direction="row" spacing={4} width="100%" justifyContent="space-between">
        <GeneralDetailsSection projectData={projectData as ProjectDto} />
        <ProjectStatusCard
          projectData={projectData as ProjectDto}
          initialContract={initialContract as ContractDto}
        />
        <ContractDataGrid rows={(contractList as ContractDto[]) ?? []} />
      </Stack>
      <Stack direction="row" spacing={4} width="100%">
        <FlagDataGrid rows={(flagListData?.data.results as FlagDto[]) ?? []} />
        <MilestoneDataGrid rows={(milestonesListData?.data.results as MilestoneDto[]) ?? []} />
      </Stack>
    </Stack>
  );
};

export default AdminProjectDetails;
