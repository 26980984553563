import { Box, Stack, Typography } from '@mui/material';
import { v1 as uuidv1 } from 'uuid';
import { useEffect, useState } from 'react';
import Button from '../../../components/button/Button';
import DynamicMultistep, { StepComponentType } from '../../../components/multi-step/DynamicMultistep';
import theme from '../../../theme';
// eslint-disable-next-line import/no-named-as-default
import StepFirst from './steps/StepFirst';
import StepFourth from './steps/StepFourth';
import StepSecond from './steps/StepSecond';
import StepThird from './steps/StepThird';
import useCreateArticleStore from './useCreateArticleStore';
import SuccessScreen, { SuccessScreenProps } from '../../../components/success-screen/SuccessScreen';
import { AwsUploadData } from '../../../utils/types';
import { getImageFilesToUpload, uploadFile } from '../../../utils/file-upload-helpers';
import ArticleDetail from '../ArticleDetail';

export interface CreateArticleProps {
  adminMode?: boolean;
}

const CreateArticle = ({ adminMode = false }: CreateArticleProps) => {
  const createArticleInput = useCreateArticleStore((state) => state.createArticleInput);
  const setCreateArticleInput = useCreateArticleStore((state) => state.setCreateArticleInput);
  const deleteEverything = useCreateArticleStore((state) => state.deleteEverything);
  const [isCurrentStepPreview, setIsCurrentStepPreview] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [successScreenProps, setSuccessScreenProps,
  ] = useState<SuccessScreenProps>({} as SuccessScreenProps);

  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    setCreateArticleInput({
      id: uuidv1(),
    });
  }, []);

  const articleCreateParams = {
    variables: {
      submitForApproval: true,
      articleInput: {
        id: createArticleInput?.id,
        title: createArticleInput?.title,
        files: createArticleInput?.files?.banner?.uri ? {
          banner: {
            uri: createArticleInput?.files?.banner?.uri,
          },
        } : null,
        authorName: createArticleInput?.authorName,
        body: {
          articleBody: createArticleInput?.body?.articleBody,
          authorBiography: createArticleInput?.body?.authorBiography,
        },
        services: createArticleInput?.services,
        techLanguages: createArticleInput?.techLanguages,
        techPlatforms: createArticleInput?.techPlatforms,
        techTools: createArticleInput?.techTools,
        industries: createArticleInput?.industries,
        articleTypes: createArticleInput?.articleTypes,
      },
    },
  };

  const onSubmit = async () => {
    const filesToUpload: Array<AwsUploadData> = getImageFilesToUpload(createArticleInput?.files);
  };

  const onSaveDraft = async () => {
    const filesToUpload: Array<AwsUploadData> = getImageFilesToUpload(createArticleInput?.files);
    // this is to hide the side component when you save as draft,
    // could rename the name of it in the future
    setIsCurrentStepPreview(true);
  };

  return (
    <Stack spacing={20} height="100%" direction="row" justifyContent="space-between">
      <DynamicMultistep
        stepComponents={[
          {
            component: <StepFirst adminMode={adminMode} companies={companies} />,
            label: 'General',
          },
          {
            component: <StepSecond />,
            label: 'Technologies',
          },
          {
            component: <StepThird />,
            label: 'Industry',
          },
          {
            component: <StepFourth />,
            label: 'Type',
          },
          {
            component: <ArticleDetail articleCreationData={createArticleInput} />,
            label: 'Preview',
            type: StepComponentType.PREVIEW,
          },
        ]}
        showSaveDraftButton
        setIsCurrentStepPreview={setIsCurrentStepPreview}
        width="100%"
        onFinish={() => onSubmit()}
        onSaveDraft={() => onSaveDraft()}
        finishComponent={(
          <SuccessScreen {...successScreenProps} />
        )}
        isSubmitButtonLoading={false}
        minHeight={800}
        pushButtonsToBottom
        isSubmitSuccessful={isSubmitSuccessful}
        horizontalLineColor={theme.palette.grey[400]}
        buttonsTopPadding={6}
      />

      {
        !isCurrentStepPreview
        && (
          <Box sx={{ width: '28%', color: 'common.white' }}>
            <Box sx={{
              position: 'sticky', top: 15, height: '88vh', bgcolor: 'primary.main',
            }}
            >
              <Stack height="100%" justifyContent="center" alignItems="center">
                <Stack spacing={3} width="100%" sx={{ px: 4 }}>
                  <Typography variant="h4" sx={{ fontSize: '2rem', maxWidth: 100 }}>
                    What happens next?
                  </Typography>

                  <Typography>
                    As soon as we receive your request, we’ll be in touch to chat about next steps,
                    and to connect you with your chosen service provider.
                  </Typography>

                  <Stack spacing={2}>
                    {/* TODO: implement review draft functionality */}
                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onButtonClick={() => { }}
                      fontWeight="bold"
                      variant="contained"
                      color="secondary"
                      minWidth="100%"
                      setUpperCase
                    >
                      Review draft
                    </Button>

                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onButtonClick={() => { }}
                      fontWeight="bold"
                      variant="contained"
                      color="secondary"
                      minWidth="100%"
                      setUpperCase
                    >
                      Save as draft
                    </Button>

                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        )
      }
    </Stack>
  );
};

export default CreateArticle;
