import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import {
  ClickAwayListener,
  IconButton,
  Stack,
  SxProps, Theme, Typography,
} from '@mui/material';
import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { primaryDark } from '../../color';
import _ from 'lodash';

type ControlledTextInputAutoResizeWidthProps<T> = {
  name: string;
  required?: boolean;
  data?: AxiosResponse;
  onSave: () => void;
  showLabelAndSaveButtonInsideInput?: boolean;
  parentElWidth?: number;
  showPencilIcon?: boolean;
  showSaveButton?: boolean;
  isTaskTitleEditingProp?: boolean;
  shouldDisableEditButton?: boolean;
  sx?: SxProps<Theme> | undefined;
  classNames?: string;
  disableExternalSaveButton?: (shouldDisable: boolean) => void;
  shouldBoldText?: boolean;
  disableClickAway?: boolean;
  getContentAndInitialValue?: ({
    content,
    initialValue,
  }: {
    content: string;
    initialValue: string;
  }) => void;
  isExistingEntity?: boolean,
} & TextFieldProps;

const ControlledTextInputAutoResizeWidth = <T,>({
  name,
  required,
  data,
  onSave,
  showLabelAndSaveButtonInsideInput = false,
  parentElWidth,
  showPencilIcon = true,
  showSaveButton = true,
  isTaskTitleEditingProp = false,
  shouldDisableEditButton,
  sx,
  classNames,
  disableExternalSaveButton,
  shouldBoldText,
  disableClickAway = false,
  getContentAndInitialValue,
  isExistingEntity,
  ...props
}: ControlledTextInputAutoResizeWidthProps<T>) => {
  const {
    control, setValue, getValues  } = useFormContext();

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  useEffect(() => {
    if (getValues(name) === '') {
      setDisableSaveButton(true);
    }

    if (getValues(name) !== '') {
      setDisableSaveButton(false);
    }

    if (disableExternalSaveButton) {
      if (getValues(name) === '') {
        disableExternalSaveButton(true);
      }

      if (getValues(name) !== '') {
        disableExternalSaveButton(false);
      }
    }
  }, [getValues(name)]);

  const contentRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(0);
  const [minWidth, setMinWidth] = useState<number>(0);
  const [content, setContent] = useState('');

  const [initialValue, setInitialValue] = useState<string | null>(null);

  const [isTaskTitleEditing, setIsTaskTitleEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsTaskTitleEditing(isTaskTitleEditingProp as boolean);
  }, [isTaskTitleEditingProp]);

  useEffect(() => {
    setInitialValue(getValues(name));
  }, [data]);

  useEffect(() => {
    setWidth(contentRef.current?.offsetWidth as number);
  }, [content]);

  useEffect(() => {
    setTimeout(() => {
      setWidth(contentRef.current?.offsetWidth as number);

      if (showLabelAndSaveButtonInsideInput) {
        setMinWidth(176);
      }
    }, 0);
  }, [showLabelAndSaveButtonInsideInput]);

  useEffect(() => {
    if (getValues(name) === '' && props.placeholder) {
      setContent(props.placeholder);
    } else {
      setContent(getValues(name));
    }
  }, [getValues(), props.placeholder]);

  // using debounce for optimization because the getContentAndInitialValue callback
  // is updating state in parent
  const functionDebounce = useCallback(
    _.debounce(() => {
      getContentAndInitialValue?.({ content, initialValue: initialValue || '' })
    }, 320),
    [content, initialValue]
  );

  // for editing;
  // useEffect's below purpose is for getting and comparing content
  // and initial value and dispatch it to the parent;
  // i.e., when editing a field, and then clicking outside,
  // return to the old unedited value;
  // also, it runs ONLY when dealing with existing fields (that can be edited);
  // so when creating a new field, it does not run this useEffect
  // because we have no use of it then => !isExistingEntity
  useEffect(() => {
    if (disableClickAway && getContentAndInitialValue && !isExistingEntity) {
      functionDebounce();
    }
  }, [disableClickAway, functionDebounce, isExistingEntity]);
  //

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!disableClickAway) {
          if (isTaskTitleEditing) {
            // if you cancel, set the input to the default value
            setValue(name, initialValue);
            setContent(initialValue as string);

            setIsTaskTitleEditing(false);
          }
        }
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          width: 'fit-content',
          maxWidth: parentElWidth,
          ...sx,
        }}
      >
        {showLabelAndSaveButtonInsideInput && (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent={isTaskTitleEditing ? 'space-between' : 'initial'}
            sx={{
              position: 'absolute',
              top: 12,
              zIndex: 1,
              px: 1,
              minWidth: 160,
              maxWidth: parentElWidth,
              width: '100%',
            }}
          >
            <Typography variant="heading4" color="primaryDark.500">
              description
            </Typography>

            {
              isTaskTitleEditing ? (
                <Typography
                  component="button"
                  disabled={disableSaveButton}
                  onClick={() => {
                    setIsTaskTitleEditing(false);
                    onSave();
                  }}
                  variant="heading4"
                  color={disableSaveButton ? "primaryDark.300" : "primaryLight.500" }
                  sx={{
                    cursor: 'pointer',
                    border: 'none',
                    bgcolor: 'transparent',
                    height: 30,
                  }}
                >
                  save
                </Typography>
              ) : (
                <IconButton
                  disabled={shouldDisableEditButton || false}
                  onClick={() => setIsTaskTitleEditing(true)}
                  sx={{ height: 30, p: '6px' }}
                >
                  <PencilIcon
                    fill={shouldDisableEditButton ? primaryDark[300] : primaryDark[500]}
                    style={{ width: 18, height: 18 }}
                  />
                </IconButton>
              )
            }
          </Stack>
        )}

        <Controller
          control={control}
          name={name}
          rules={required
            ? { required: 'This field is required' }
            : { required: false }}
          render={(renderProps) => (
            <Stack direction="row" alignItems="center">
              <Typography
                ref={contentRef}
                sx={{
                  position: 'absolute',
                  opacity: 0,
                  display: 'inline',
                  px: '16px',
                  pr: showLabelAndSaveButtonInsideInput ? 0 : '16px',
                  zIndex: -100,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontSize: '1rem',
                  fontWeight: 500,
                  maxWidth: parentElWidth,
                }}
              >
                {content}
              </Typography>

              {
                width && (
                  <TextField
                    inputRef={(input) => {
                      if (input) {
                        if (isTaskTitleEditing) {
                          input.focus();
                        } else {
                          input.blur();
                        }
                      }
                    }}
                    {...renderProps.field}
                    onChange={(data) => {
                      setValue(name, data.target.value);
                      setContent(data.target.value);
                    }}
                    multiline
                    placeholder={props.placeholder}
                    focused={isTaskTitleEditing}
                    spellCheck={false}
                    InputProps={{
                      style: {
                        // fontWeight: 500,
                        // fontWeight: showLabelAndSaveButtonInsideInput ? 500 : 600,
                        fontWeight: shouldBoldText ? 600 : 500,
                        fontSize: '1rem',
                        color: showLabelAndSaveButtonInsideInput
                          && isTaskTitleEditing ? primaryDark[400] : primaryDark[600],

                      },
                      endAdornment: isTaskTitleEditing
                        && !showLabelAndSaveButtonInsideInput && showSaveButton
                        ? (
                          <Typography
                            component="button"
                            disabled={disableSaveButton}
                            onClick={() => {
                              setIsTaskTitleEditing(false);
                              onSave();
                            }}
                            variant="heading4"
                            color={disableSaveButton ? "primaryDark.300" : "primaryLight.500" }
                            sx={{
                              cursor: 'pointer', border: 'none', bgcolor: 'transparent', mr: '4px',
                            }}
                          >
                            save
                          </Typography>
                        )
                        : null,
                    }}
                    onFocus={(e) => {
                      setIsTaskTitleEditing(true);
                      // moving cursor to the end
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length, e.currentTarget.value.length,
                      );
                    }}
                    name={name}
                    variant="outlined"
                    required={required}
                    sx={{
                      width: (() => {
                        if (showLabelAndSaveButtonInsideInput) {
                          if (isTaskTitleEditing) {
                            return width;
                          }

                          return width + 16;
                        }

                        if (showSaveButton) {
                          if (isTaskTitleEditing) {
                            return width + 40;
                          }

                          return width + 16;
                        }

                        return width;
                      })(),
                      // width: width,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      pointerEvents: isTaskTitleEditing ? 'all' : 'none',
                      '& fieldset': { border: isTaskTitleEditing ? '1px solid transparent' : 'none' },
                      bgcolor: isTaskTitleEditing ? '#F6F7FF' : 'transparent',
                      minWidth,
                      flexWrap: 'wrap',
                      '& .MuiOutlinedInput-root': {
                        p: 0,
                        pr: '6px',
                        pt: showLabelAndSaveButtonInsideInput ? '30px' : 0,
                        '&.Mui-focused fieldset': {
                          border: '1px solid #BEC6FF',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        width: '100%',
                        px: '8px',
                        pr: showLabelAndSaveButtonInsideInput ? 0 : '8px',
                        py: '10px',
                      },
                    }}
                  />
                )
              }

              {
                showPencilIcon && (
                  !isTaskTitleEditing && !showLabelAndSaveButtonInsideInput && (
                    <IconButton
                      className={classNames}
                      disabled={shouldDisableEditButton || false}
                      onClick={() => setIsTaskTitleEditing(true)}
                      sx={{ ml: -3.95, p: '6px' }}
                    >
                      <PencilIcon
                        fill={shouldDisableEditButton ? primaryDark[300] : primaryDark[500]}
                        style={{ width: 18, height: 18 }}
                      />
                    </IconButton>
                  )
                )
              }
            </Stack>
          )}
        />
      </Stack>
    </ClickAwayListener>
  );
};

export default ControlledTextInputAutoResizeWidth;
