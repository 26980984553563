import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Stack, Typography } from '@mui/material';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatCurrency } from '../../../../utils/helpers';
import InvoiceUploadModal from '../modals/InvoiceUploadModal';

type ProjectPaymentProps = {
  milestones: MilestoneDto[]
}

const ProjectPaymentsList = ({ milestones }: ProjectPaymentProps) => {
  const columns: GridColDef[] = [
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.4,
      renderCell: (params: GridValueGetterParams) => (
        <TaskStatus text={params.value} />
      ),
    },
    {
      field: 'name',
      headerName: 'Milestone Name',
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{params.value}</Typography>
      ),
    },
    {
      field: 'budget',
      headerName: 'Amount',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {params.value ? formatCurrency(params.value, params.row.currency) : '-'}
        </Typography>
      ),
    },
    {
      field: 'invoiceAction',
      headerName: 'Invoice',
      headerClassName: 'react-joyride-step-4-individual-payment',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => (
        <InvoiceUploadModal data={params.row} />
      ),
    },
  ];

  return (
    <Stack spacing={2} className="react-joyride-step-3-individual-payment">
      <Typography variant="h5" fontSize="1.2rem" fontWeight={500}>
        Project payments
      </Typography>

      <Box
        sx={{
          overflow: 'hidden',
          bgcolor: 'white',
          p: 0,
          borderRadius: '16px',
          border: '1px solid #C5C7C3',
        }}
      >
        <DataGrid
          columns={columns}
          rows={milestones}
          // autoHeight
          disableSelectionOnClick
          hideFooter
          sx={{ minHeight: '400px' }}
        />
      </Box>
    </Stack>
  );
};

export default ProjectPaymentsList;
