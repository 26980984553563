const LAYOUT_DEFAULTS = {
  mx: {
    xs: `${24}px !important`,
    sm: `${40}px !important`,
    lg: `${80}px !important`,
    xxl: `${320}px !important`,
  },
};

export default LAYOUT_DEFAULTS;
