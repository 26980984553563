import {
  Stack, Box, Typography, FormControlLabel, Grid, Checkbox,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import { mapArticleType } from '../../../../enums';
import useCreateArticleStore from '../useCreateArticleStore';

const StepFourth = () => {
  const createArticleInput = useCreateArticleStore((state) => state.createArticleInput);
  const setCreateArticleInput = useCreateArticleStore((state) => state.setCreateArticleInput);
  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);

  const {
    watch, control, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      articleTypes: createArticleInput.articleTypes ?? [],
    },
  });

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setCreateArticleInput(watch()));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box component="form">
      <Stack spacing={1}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Article types</Typography>

        <Controller
          name="articleTypes"
          control={control}
          render={({ field }) => (
            <Grid direction="row" justifyContent="space-between" container columns={{ md: 3 }}>
              {Array.from(mapArticleType, ([key, value]: [string, string]) => (
                <Grid key={key} item md={1}>
                  <FormControlLabel
                    {...field}
                    key={key}
                    label={value}
                    control={(
                      <Checkbox
                        color="secondary"
                        checked={createArticleInput?.articleTypes?.includes(key)}
                        onChange={() => {
                          if (!field.value.includes(key)) {
                            field.onChange([...field.value, key]);
                            return;
                          }

                          const newArticleTypes = field.value.filter(
                            (topic: string) => topic !== key,
                          );

                          field.onChange(newArticleTypes);
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      </Stack>
    </Box>
  );
};

export default StepFourth;
