import {
  Stack, TextField, TextFieldProps, Typography, Box,
} from '@mui/material';
import { ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import BaseInputLabel from './BaseInputLabel';

type Props = {
  name: string;
  required?: boolean;
  field?: ControllerRenderProps<FieldValues, string>;
  params?: TextFieldProps,
  labelAdornment?: JSX.Element | null,
} & TextFieldProps;

const TitleInput = ({
  name,
  required,
  label,
  field,
  params,
  labelAdornment,
  disabled,
  ...props
}: Props) => {
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(name);
  const hasError = fieldState.error
  const errorMsg = fieldState.error?.message?.length ? 'This field is required' : '';

  const sharedStyles = {
    bg: disabled ? 'primaryDark.150' : hasError ? 'secondaryPink.100' : 'primaryLight.500',
    color: hasError ? 'secondaryPink.500' : 'primaryDark.300',
  };

  const boxStyles = {
    position: 'relative',
    width: 'calc(100% + 24px)',
    left: '-24px',
    backgroundColor: sharedStyles.bg,
    padding: '24px 24px 40px 24px',
  };

  const labelStyles = {
    color: sharedStyles.color,
    '.MuiTypography-body2': {
      color: sharedStyles.color,
    },
  };

  const styles = {
    backgroundColor: sharedStyles.bg,
    border: 'none',
    '.MuiButtonBase-root': {
      display: 'none',
    },
    '.MuiInputBase-formControl': {
      color: hasError ? 'primaryDark.500' : 'primaryLight.100',
      border: 'transparent',
      backgroundColor: 'transparent',
      '&:not(.Mui-disabled)': {
        '&:hover': {
          backgroundColor: sharedStyles.bg,
        },
      },
    },
    '.Mui-focused': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    input: {
      padding: '8px',
      fontSize: '28px',
      fontWeight: '600',
      pointerEvents: disabled ? 'none' : 'all',
    },
    '.MuiFormHelperText-root': {
      marginLeft: 0,
      boxShadow: 'none !important',
      color: 'secondaryPink.500',
      marginTop: '0 !important',
      lineHeight: 1,
    },
    fieldset: {
      border: '0 !important',
    },
  };

  return (
    <Box sx={boxStyles}>
      {label && (
        <BaseInputLabel
          text={label as string}
          adornment={labelAdornment}
          required={required}
          sx={labelStyles}
        />
      )}
      <Stack sx={{
        position: 'relative',
        ...styles,
      }}
      >
        <TextField
          {...props}
          {...params}
          {...field}
          label=""
          variant="outlined"
          required={required}
          name={name}
          fullWidth
          InputProps={{
            disabled,
          }}
        />
      </Stack>
      {hasError && (
      <Typography color="secondaryPink.500" variant="body4" sx={{ position: 'absolute', bottom: '-28px' }}>
        {errorMsg}
      </Typography>
      )}
    </Box>
  );
};

export default TitleInput;
