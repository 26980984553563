import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface TaskManagementState {
  initialState: Record<string, any>;
  taskManagementInput: Record<string, any>;
  setTaskManagementInput: (value: Record<string, any>) => void;
}

const useTaskManagementStore = create<TaskManagementState>(devtools((set) => ({
  initialState: {},
  taskManagementInput: {},
  setTaskManagementInput: (value: Record<string, any>) => set((state) => (
    { taskManagementInput: { ...state.taskManagementInput, ...value } }
  )),
})));

export default useTaskManagementStore;
