/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { useSearchParams } from 'react-router-dom';

type Props = {
  defaultValue?: Record<string, any>;
};

const useQueryState: (
  props?: Props | undefined,
) => [Record<string, any>, (param: any) => void, (queryKey: string) => void, URLSearchParams] = (
  props?: Props,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryState: Record<string, string> = Object.fromEntries(searchParams);

  const setQueryState = (param: any) => {
    const clean = () => {
      for (const propName in param) {
        if (param[propName] === '' || param[propName] === undefined) {
          // eslint-disable-next-line no-param-reassign
          delete param[propName];
        }
      }
      return param;
    };

    setSearchParams((item) => ({
      ...Object.fromEntries(item),
      ...clean(),
    }));
  };

  const removeQueryState = (queryKey: string) => {
    setSearchParams((item) => {
      item.delete(queryKey);
      return item;
    });
  };

  return [queryState, setQueryState, removeQueryState, searchParams];
};

export default useQueryState;
