import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../components/button/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { CaseStudyReviewDto } from 'tdc-web-backend/case-studies-review/schemas';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { truncate } from '../../../utils/helpers';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';
import ClientReview from '../../../components/client-reviews/ClientReview';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';

type Props = {};

const CaseStudyReviewDetails = (props: Props) => {
  const { reviewId } = useParams();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { data } = useReferenceOne<CaseStudyReviewDto>({
    id: reviewId,
    resource: 'case-studies-review',
    enabled: !!reviewId,
  });

  const { mutate: approve, isLoading } = useUpdate({ resource: '/case-studies-review' });

  const handleCaseStudyReview = () => {
    approve(
      {
        id: reviewId as string,
        suffix: 'approve',
      },
      {
        onSuccess: () => {
          refresh();
          navigate('../');
        },
      },
    );
  };

  return (
    <Stack p={6}>
      <CustomReferenceField
        resource="case-studies-content"
        id={data?.caseStudyContent}
        displayName={'title'}
        renderComponent={(value: CaseStudyContentDto) => (
          <Typography variant="heading3" sx={{ color: 'primaryDark.600' }}>
            {value?.title}
          </Typography>
        )}
      />
      <Stack direction="row" gap={4} pt={4} alignItems="center">
        <CustomButton
          disabled={!(!data?.adminApproved && data?.requestPublish)}
          loading={isLoading}
          onClick={() => handleCaseStudyReview()}
        >
          Approve
        </CustomButton>
      </Stack>
      <Stack spacing={4} pt={4}>
        <Typography>Client info</Typography>
        <Stack>
          <BaseInputLabel text="name" />
          <Typography>
            {data?.reviewer?.firstName} {data?.reviewer?.lastName}
          </Typography>
        </Stack>
        <Stack>
          <BaseInputLabel text="company name" />
          <Typography>{data?.reviewer?.companyName}</Typography>
        </Stack>
        <Stack>
          <BaseInputLabel text="company website" />
          <Typography>{data?.reviewer?.companyWebsite}</Typography>
        </Stack>
        <Stack>
          <BaseInputLabel text="job title" />
          <Typography>{data?.reviewer?.jobTitle}</Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack spacing={4} pt={4}>
        <ClientReview
          title="Quality"
          description={data?.score.qualityText as string}
          rating={data?.score.qualityScore ?? 0}
          detailedView
        />
        <ClientReview
          title="Budget"
          description={data?.score.budgetText as string}
          rating={data?.score.budgetScore ?? 0}
          detailedView
        />
        <ClientReview
          title="Schedule"
          description={data?.score.scheduleText as string}
          rating={data?.score.scheduleScore ?? 0}
          detailedView
        />
        <ClientReview
          title="Recommondation"
          description={data?.score.recommendationText as string}
          rating={data?.score.recommendationScore ?? 0}
          detailedView
        />
      </Stack>
    </Stack>
  );
};

export default CaseStudyReviewDetails;

ClientReview;
