import { Avatar, Box, Stack, Typography } from '@mui/material';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import ChatMessageAttachmentList from './ChatMessageAttachmentList';
import MessageSpecial from './Special/MessageSpecial';

export default ({
  name,
  body,
  created,
  ownMessage,
  collapse,
  attachments,
  special,
}: {
  name: string;
  body: string;
  created: Date;
  ownMessage: boolean;
  collapse: boolean;
  attachments: AttachmentDto[];
  special: any;
}) => (
  <Stack direction="row" spacing={1.5} paddingTop={collapse ? 0.5 : 2}>
    <Avatar sx={{ visibility: collapse ? 'hidden' : 'visible' }}>{name[0]}</Avatar>
    <Stack direction="column" spacing={1}>
      {!collapse && (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography
            variant="bodySemiBold2"
            color={ownMessage ? 'secondaryBlue.700' : 'primaryDark.600'}
          >
            {name}
          </Typography>
          <Typography variant="heading5" color="primaryDark.500">
            {new Date(created).toLocaleTimeString(undefined, {
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Typography>
        </Stack>
      )}
      <Stack direction="row">
        <Box
          p={2}
          sx={{
            backgroundColor: ownMessage ? 'secondaryBlue.50' : 'primaryDark.150',
            borderRadius: '0px 8px 8px 8px',
          }}
        >
          <Stack direction="column" spacing={3}>
            <MessageSpecial special={special} />
            {body.length > 0 ? (
              <Typography variant="body3" color="#384572" sx={{ whiteSpace: 'pre-wrap' }}>
                {body}
              </Typography>
            ) : null}
            {attachments.length ? <ChatMessageAttachmentList attachments={attachments} /> : null}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  </Stack>
);
