import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';

const About = () => (
  <ControlledTextEditor
    label="team description"
    name="overview.description"
    items={TimedProjectEditorMenu}
    required
  />
);

export default About;
