import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  EnumCurrency,
  EnumTimedProjectStatus,
  mapIndustry,
  mapService,
} from 'tdc-web-backend/enums/enums';
import CustomButton from '../../../components/button/CustomButton';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency, formatDateFns } from '../../../utils/helpers';
import { primaryDark } from '../../../color';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../enums';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import { TimedProjectEditorMenu } from '../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../components/editor/Editor';
import ChipGroup from '../../Dashboard/CaseStudies/CaseStudiesDetails/CaseStudyChipGroup';
import { ReactComponent as TrophyIcon } from '../../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as BullseyeIcon } from '../../../assets/icons/layout-icons/BullseyeIcon.svg';
import { ReactComponent as LightBulbIcon } from '../../../assets/icons/layout-icons/LightBulbIcon.svg';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import { TimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';

const TimedProjectsDetailsAdmin = () => {
  const { timedProjectId } = useParams();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const { data: timedData } = useReferenceOne<TimedProjectContentDto>({
    id: timedProjectId,
    resource: 'timed-project-contents',
    enabled: !!timedProjectId,
  });

  const data = timedData?.body;

  const { mutate: approve, isLoading } = useUpdate({ resource: '/timed-projects' });

  const handleTimedProject = (action: string) => {
    approve(
      {
        id: timedData?.timedProject,
        suffix: action,
      },
      {
        onSuccess: () => {
          refresh();
          navigate('../');
        },
      },
    );
  };

  return (
    <Box>
      <Stack p={6}>
        <Stack spacing={2}>
          <Typography variant="heading3" color="primaryDark.600">
            {data?.overview.name}
          </Typography>
          <Typography variant="body3" color="primaryDark.500">
            {data?.overview.description}
          </Typography>
        </Stack>
        <Typography variant="body3" pt={4} color="primaryDark.500">
          {formatDateFns(timedData?.published, '---')}
        </Typography>
        <Stack direction="row" py={3} alignItems="center" justifyContent={'space-between'}>
          <Stack direction="row" gap={4} alignItems="center">
            <CustomButton
              loading={isLoading}
              variant="secondary"
              onClick={() => handleTimedProject('reject')}
              disabled={timedData?.status !== EnumTimedProjectStatus.Pending}
            >
              Reject
            </CustomButton>
            <CustomButton
              disabled={timedData?.status !== EnumTimedProjectStatus.Pending}
              loading={isLoading}
              onClick={() => handleTimedProject('approve')}
            >
              Approve
            </CustomButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack width="100%">
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="services" choices={mapService} values={data?.skills.services} />
            <ChipGroup
              label="tech platforms"
              choices={mapTechPlatform}
              values={data?.skills.platforms}
            />
          </Stack>
          <Divider />
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="tech tools" choices={mapTechTool} values={data?.skills.tools} />
            <ChipGroup
              label="Programming languages"
              choices={mapTechLanguage}
              values={data?.skills?.languages}
            />
          </Stack>
          <Divider />
          <Stack direction="row" width="100%" py={2}>
            <ChipGroup label="industry" choices={mapIndustry} values={data?.about.industries} />
            <ChipGroup
              label="Target market"
              choices={mapIndustry}
              values={data?.about.targetMarkets}
            />
          </Stack>
          <Divider />
          <Stack width="100%" py={2}>
            <BaseInputLabel text="Budget" />
            <Typography variant="heading4">
              {formatCurrency(
                data?.budget.budget as number,
                data?.budget.currency as EnumCurrency,
              ) ?? '--'}
            </Typography>
          </Stack>

          <Divider />
          {data?.about.goals && (
            <Stack py={2}>
              <Stack direction="row" spacing={1} px={3}>
                <TrophyIcon fill={primaryDark[500]} />
                <BaseInputLabel text="Project goals" />
              </Stack>
              <Editor
                items={TimedProjectEditorMenu}
                content={data?.about?.goals}
                isEditable={false}
                key="goals"
              />
            </Stack>
          )}
          <Divider />
          {data?.about?.audience && (
            <Stack py={2}>
              <Stack direction="row" spacing={1} px={3}>
                <BullseyeIcon fill={primaryDark[500]} />
                <BaseInputLabel text="Target audience" />
              </Stack>
              <Editor
                items={TimedProjectEditorMenu}
                content={data?.about?.audience}
                isEditable={false}
                key="audience"
              />
            </Stack>
          )}
          <Divider />
          {data?.budget?.budgetDescription && (
            <Stack py={2}>
              <Stack direction="row" spacing={1} px={3}>
                <LightBulbIcon fill={primaryDark[500]} />
                <BaseInputLabel text="budget Description" />
              </Stack>
              <Editor
                items={TimedProjectEditorMenu}
                content={data?.budget.budgetDescription}
                isEditable={false}
                key="budgetDescription"
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TimedProjectsDetailsAdmin;
