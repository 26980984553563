import { Stack, Typography, Box, useMediaQuery } from '@mui/material';
import SponaInNumbers from '../../../../components/public-screen-components/SponaInNumbers';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import theme from '../../../../theme';

const WorkWithUsHero = () => {
  const isScreenWidthGreaterThanLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const isScreenWidthGreaterThanExtraExtraLarge = useMediaQuery(theme.breakpoints.up('xxl'));

  return (
    <Stack
      spacing={{ xs: 8, xxl: 12 }}
      justifyContent="center"
      sx={{ ...LAYOUT_DEFAULTS, height: `calc(100vh + ${PUBLIC_APPBAR_HEIGHT}px)` }}
    >
      <Stack spacing={{ xs: 5, xxl: 4 }}>
        <Stack justifyContent="center" alignItems="center" width="fit-content">
          <Typography
            display={{ xs: 'grid', md: 'block' }}
            sx={{
              typography: { xs: 'publicHeadline7', md: 'publicHeadline5', lg: 'publicHeadline6' },
            }}
          >
            Help Us Become
            <br />
            <Typography
              component="span"
              sx={{
                position: 'relative',
                typography: { xs: 'publicHeadline7', md: 'publicHeadline5', lg: 'publicHeadline6' },
              }}
            >
              Even Better
              <Box
                sx={{
                  backgroundColor: 'primaryLight.500',
                  width: '100%',
                  height: '0.75rem',
                  borderRadius: '7rem',
                  position: 'absolute',
                  bottom: '-5%',
                  left: 0,
                  zIndex: -1,
                }}
              />
            </Typography>
          </Typography>
        </Stack>

        <Typography variant="publicBody3Regular">
          Changing the (business) world, one step at a time. Learn more about what it means to
          become a part of the Spona team.
        </Typography>
      </Stack>

      {isScreenWidthGreaterThanLarge && (
        <SponaInNumbers
          spacing={4}
          sx={{
            height: 'auto',
            p: isScreenWidthGreaterThanExtraExtraLarge ? '2.85rem !important' : 0,
            px: !isScreenWidthGreaterThanExtraExtraLarge ? '2rem' : 0,
            py: !isScreenWidthGreaterThanExtraExtraLarge ? '1rem' : 0,
          }}
          breakWord={isScreenWidthGreaterThanExtraExtraLarge}
        />
      )}
    </Stack>
  );
};

export default WorkWithUsHero;
