import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import BlogCard from './BlogCard';
import CustomButton from '../../../../components/button/CustomButton';

const LatesBlogs = () => {
  const navigate = useNavigate();
  const { data: response } = useGetMany<BlogDto>({
    resource: `/blogs?limit=3&orderBy=-created`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  const blogs = response?.data.results;

  return (
    <Stack direction="column" spacing={5} pt={2.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="publicBody1">Latest blog posts</Typography>
        <CustomButton
          variant="primaryText"
          onClick={() => navigate(`/blog-archive`)}
          sx={{ minWidth: 'fit-content' }}
        >
          View all
        </CustomButton>
      </Stack>
      <Grid
        container
        spacing={2}
        rowSpacing={3}
        pt={0}
        mt={-1.5}
        gridAutoRows="max-content"
        sx={{ background: 'transparent', marginLeft: '-16px !important' }}
      >
        {blogs?.map((item: any, index) => (
          <Grid key={item?.id} item xs={12} md={4} bg={4} lg={4} xl={4} gridAutoRows="max-content">
            <BlogCard blogData={item} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default LatesBlogs;
