import {
  Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { useState } from 'react';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { useMutation } from 'react-query';
import { Box } from '@mui/system';
import { EnumProjectContractStatus} from 'tdc-web-backend/enums/enums';
import axios from '../../../../api/axios';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as TrashBinIcon } from '../../../../assets/icons/project-icons/TrashBinIcon.svg';
import { primaryDark, primaryLight, secondaryBlue } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';

interface CancelContractModalProps {
  data: ContractDto
}

const CancelContractModal = ({
  data,
}: CancelContractModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const refresh = useRefresh();

  const { mutate: cancel } = useMutation({
    mutationFn: () => axios.post(`/contracts/${data.id}/request-cancellation`),
    onSuccess: () => {
      refresh();
      setIsOpen(false)
    },
  });

  const { mutate: deleteDraft } = useMutation({
    mutationFn: () => axios.delete(`/contracts/${data.id}`),
    onSuccess: () => {
      refresh();
      setIsOpen(false)
    },
  });

  const handleCancel = () => {
    if(data.status === EnumProjectContractStatus.Draft){
      deleteDraft()
    } else{
      cancel();
    }
  };

  return (
    <>
      <IconButton
        sx={{ p: 0 }}
        disableRipple
        className="react-joyride-step-3-contract-drawer"
        onClick={() => setIsOpen(true)}
        disabled={data?.status === EnumProjectContractStatus.Canceled}
      >
        <TrashBinIcon
          fill={data?.status === EnumProjectContractStatus.Canceled
            ? primaryDark[500]
            : primaryLight[500]
          }
        />
      </IconButton>
      <CustomModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1} width="100%">
            <Box
              sx={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
              bgcolor={secondaryBlue[100]}
            >
              <TrashBinIcon fill={secondaryBlue[700]} />
            </Box>
            <Typography variant="heading3">Request to end contract negotiations</Typography>
          </Stack>
          <Divider />
          <Typography variant="body3" color="primaryDark.500">
  You are about to request for contract negotiations to be cancelled. 
  This action will need to be confirmed by your partner company 
  and TDA before it takes effect. Are you sure you want to proceed?
          </Typography>
          <Divider />
          <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
            <CustomButton
              variant="secondary"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton onClick={handleCancel}>
              Confirm
            </CustomButton>
          </Stack>
        </Stack>
      </CustomModal>
    </>
  );
};

export default CancelContractModal;
