import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import axios from '../../../api/axios';

export type UploadFileProps = {
  resource: string;
}
const useUploadFile = <Response, Request> ({ resource }: UploadFileProps) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const create = (data: Request): Promise<AxiosResponse<Response>> => axios.post(
    `${resource}`,
    data,
    {
      onUploadProgress: (event) => {
        setUploadProgress(Math.round((event.loaded * 100) / event.total));
      },
    },
  );

  const queryClient = useQueryClient();

  queryClient.setMutationDefaults([`${resource}-create`], {
    mutationFn: create,
  });
  return { ...useMutation<AxiosResponse<Response>, unknown, Request>([`${resource}-create`]), uploadProgress };
};

export default useUploadFile;
