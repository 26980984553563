import { useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import slugify from 'slug';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/layout-icons/PencilIcon.svg';
import { ReactComponent as FilesEarmarkIcon } from '../../../../assets/icons/layout-icons/FilesEarmarkIcon.svg';
import { primaryLight } from '../../../../color';

interface SlugProps {
  viewMode?: boolean;
  basePath: string;
  initialSlug: string;
  onConfirm?: (slug: string) => void;
}

const Slug = ({ viewMode, basePath, initialSlug, onConfirm }: SlugProps) => {
  const [editMode, setEditMode] = useState(false);
  const [copyTooltipDisplay, setCopyTooltipDisplay] = useState(false);
  const form = useForm({
    defaultValues: {
      slug: initialSlug,
    },
    mode: 'onChange',
  });
  return (
    <Box>
      <BaseInputLabel sx={{ marginBottom: '0.25rem' }} text="Slug" />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ minHeight: '47px' }}
      >
        <Typography
          variant="bodyMedium3"
          color="secondaryGray.700"
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {basePath}
        </Typography>
        {editMode ? (
          <>
            <FormProvider {...form}>
              <CustomInput
                input={<CustomInput.Text />}
                name="slug"
                type="text"
                sx={{
                  width: '100%',
                }}
                onKeyUp={(e) => {
                  form.setValue('slug', slugify(form.getValues('slug')));
                }}
              />
              <CustomButton
                onClick={() => {
                  setEditMode(false);
                  const newSlug = form.getValues('slug');
                  if (newSlug && newSlug !== initialSlug) {
                    if (onConfirm) {
                      onConfirm(newSlug);
                    }
                  }
                }}
                sx={{ marginLeft: '24px', minWidth: 0 }}
                variant="secondary"
              >
                {' '}
                OK{' '}
              </CustomButton>
              <CustomButton
                onClick={() => setEditMode(false)}
                sx={{ marginLeft: '16px' }}
                variant="primaryText"
              >
                {' '}
                Cancel{' '}
              </CustomButton>
            </FormProvider>
          </>
        ) : (
          <>
            <Typography
              variant="body3"
              color="secondaryGray.700"
              sx={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {initialSlug}
            </Typography>
            {!viewMode && (
              <IconButton
                aria-label="edit"
                sx={{ marginLeft: '15px' }}
                onClick={() => {
                  form.setValue('slug', initialSlug);
                  setEditMode(true);
                }}
              >
                <PencilIcon fill={primaryLight[500]} style={{ width: 16, height: 16 }} />
              </IconButton>
            )}
            <Tooltip title="Copied!" open={copyTooltipDisplay}>
              <IconButton
                onClick={() => {
                  setCopyTooltipDisplay(true);
                  navigator.clipboard.writeText(`${basePath}${initialSlug}`);
                  setTimeout(() => {
                    setCopyTooltipDisplay(false);
                  }, 1000);
                }}
                aria-label="edit"
              >
                <FilesEarmarkIcon fill={primaryLight[500]} style={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Slug;
