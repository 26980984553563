import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { mapCompanySize, mapIndustry, mapLanguage } from 'tdc-web-backend/enums/enums';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlledMultipleSelectInput from '../../../../components/custom-inputs/ControlledMultipleSelectInput';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import { countriesSorted } from '../../../../utils/helpers';

const Header = ({ text }: { text: string }) => (
  <Typography variant="h4" fontSize="1.5rem">
    {text}
  </Typography>
);

const Subheader = ({ text }: { text: string }) => (
  <Typography variant="h5" fontSize="1rem">
    {text}
  </Typography>
);

const DetailFormBuyer = () => (
  <Accordion
    sx={{
      py: 1,
      boxShadow: 'none',
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon justity-content="flex-end" fontSize="large" />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{ px: 4 }}
    >
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Header text="Company details" />
        </Stack>
        <Subheader text="Details about company. What products or services does buyer need?" />
      </Stack>
    </AccordionSummary>

    <AccordionDetails
      sx={{
        p: 4,
        borderTop: '1px solid',
        borderTopColor: 'grey.500',
      }}
    >
      <Stack spacing={4} direction="column">
        <Stack spacing={4} direction="row">
          <Stack width="50%">
            <ControlledMultipleSelectInput
              name="targetIndustries"
              label="Target industries"
              choices={mapIndustry}
            />
          </Stack>
          <Stack width="50%">
            <ControlledMultipleSelectInput
              name="targetCountries"
              label="Target countries"
              choices={countriesSorted()}
            />
          </Stack>
        </Stack>
        <Stack spacing={4} direction="row">
          <Stack width="50%">
            <ControlledSelectInput
              name="companySize"
              label="Company size"
              choices={mapCompanySize}
            />
          </Stack>
          <Stack width="50%">
            <ControlledMultipleSelectInput
              name="languages"
              label="Languages"
              choices={mapLanguage}
            />
          </Stack>
        </Stack>
      </Stack>
    </AccordionDetails>
  </Accordion>
);

export default DetailFormBuyer;
