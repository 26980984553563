import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { SpaceDto } from 'tdc-web-backend/spaces/schemas';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { CreateChannelDto } from 'tdc-web-backend/channels/schemas';
import { MessageDto, SupportMessageDto } from 'tdc-web-backend/messages/schemas';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { Space } from './Space';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as SupportIcon } from '../../../assets/icons/chat-icons/support.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/chat-icons/x.svg';
import { ReactComponent as LifePreserverIcon } from '../../../assets/icons/chat-icons/life-preserver-2-fill.svg';
import { ReactComponent as HelpFinanceIcon } from '../../../assets/icons/chat-icons/help-finance-icon.svg';
import { ReactComponent as HelpManageIcon } from '../../../assets/icons/chat-icons/help-manage-icon.svg';
import { ReactComponent as HelpHireIcon } from '../../../assets/icons/chat-icons/help-hire-icon.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/chat-icons/back-icon.svg';
import FramedIcon from '../FramedIcon';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import { useChatNavigation } from '../useChatNavigation';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import { useSearchParams } from 'react-router-dom';

type FormData = {
  body: string;
};

export default () => {
  const refresh = useRefresh();
  const chatNavigation = useChatNavigation();
  const [selectedTopic, setSelectedTopic] = useState('');
  let [searchParams, setSearchParams] = useSearchParams();

  const onClose = () => {
    searchParams.delete('showSupport');
    setSearchParams(searchParams);
  };

  const methods = useForm<FormData>({
    defaultValues: {
      body: '',
    },
  });

  const { mutate } = useCreate<MessageDto, SupportMessageDto>({
    resource: 'messages/support',
  });
  const onSubmit = (data: FormData) => {
    mutate(
      {
        body: data.body,
        topic: selectedTopic,
      },
      {
        onSuccess: (data) => {
          chatNavigation.navigateTo(data.data.channel);
          refresh();
          methods.reset();
          onClose();
        },
      },
    );
  };

  if (selectedTopic) {
    return (
      <Stack gap={3}>
        <Stack
          px={3}
          py={1}
          direction="row"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="secondaryBlue.100"
          gap={1.5}
        >
          <IconButton disableRipple onClick={() => setSelectedTopic('')} sx={{ p: 0 }}>
            <BackIcon />
          </IconButton>
          <Typography variant="heading4" color="primaryDark.600">
            {selectedTopic}
          </Typography>
        </Stack>
        <Stack px={3} gap={1}>
          <Typography variant="heading5" color="primaryDark.500">
            how can we help you?
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack gap={1.5}>
                <ControlledTextInput name="body" multiline sx={{ backgroundColor: '#fff' }} />
                <Stack direction="row" justifyContent="right">
                  <CustomButton type="submit">Send</CustomButton>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Stack
        px={3}
        py={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="secondaryBlue.100"
      >
        <Typography variant="heading4" color="primaryDark.600">
          support
        </Typography>
        <IconButton disableRipple onClick={() => onClose()} sx={{ p: 0 }}>
          <XIcon />
        </IconButton>
      </Stack>
      <Stack gap={1.5}>
        <Stack direction="row" px={3} gap={1} alignItems="center">
          <LifePreserverIcon />
          <Typography variant="heading4" color="primaryDark.600">
            help topics
          </Typography>
        </Stack>
        <Button
          sx={{
            borderRadius: 0,
            px: 3,
            justifyContent: 'flex-start',
          }}
          fullWidth
          disableRipple
          onClick={() => setSelectedTopic('hire')}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <FramedIcon variant="blue" Icon={HelpHireIcon} />
            <Typography variant="heading4" color="primaryDark.600">
              hire
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{
            borderRadius: 0,
            px: 3,
            justifyContent: 'flex-start',
          }}
          fullWidth
          disableRipple
          onClick={() => setSelectedTopic('manage')}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <FramedIcon variant="blue" Icon={HelpManageIcon} />
            <Typography variant="heading4" color="primaryDark.600">
              manage
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{
            borderRadius: 0,
            px: 3,
            justifyContent: 'flex-start',
          }}
          fullWidth
          disableRipple
          onClick={() => setSelectedTopic('manage')}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <FramedIcon variant="blue" Icon={HelpFinanceIcon} />
            <Typography variant="heading4" color="primaryDark.600">
              finance
            </Typography>
          </Stack>
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between" />
    </Stack>
  );
};
