import { Box } from '@mui/material';
import { MyAccordionProps } from '../../components/public-screen-components/accordion-groups/AccordionGroups';
import MuseumOfIllusionsLogoSmall from '../../assets/img/public-pages/moi.svg';
import GameboostLogoSmall from '../../assets/img/public-pages/gameboost.svg';
import LightFilmLogoBig from '../../assets/img/public-pages/lightfilm-big.png';
import MuseumOfIllusionsLogoBig from '../../assets/img/public-pages/moi-big.svg';
import BlvckLogoBig from '../../assets/img/public-pages/blvck-big2.png';
import GameboostLogoBig from '../../assets/img/public-pages/gameboost-big.svg';
import flwPr from '../../assets/img/public-pages/flw-pr.svg';
import gruntek from '../../assets/img/public-pages/gruntek.png';
import icestudio from '../../assets/img/public-pages/ice-studio.svg';
import impress from '../../assets/img/public-pages/impress.png';
import museum from '../../assets/img/public-pages/museum-of-illusions.svg';
import weeve from '../../assets/img/public-pages/weeve.svg';
import { Testimonial } from '../../components/public-screen-components/Testimonials';
import { getPublicImageUrl } from '../../utils/helpers';
import { EnumPublicPagesImage } from '../../utils/enums/public-page-images';

export const companyLogosLessThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'blvck', width: '55px' },
  { src: GameboostLogoSmall, alt: 'gameboost', width: '55px' },
  { src: LightFilmLogoBig, alt: 'lightfilm', width: '103px' },
  { src: MuseumOfIllusionsLogoSmall, alt: 'museum-of-illusions', width: '55px' },
];

export const companyLogosGreaterThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'blvck', width: '100px' },
  { src: GameboostLogoBig, alt: 'gameboost', width: '100px' },
  { src: LightFilmLogoBig, alt: 'lightfilm', width: '170px' },
  { src: MuseumOfIllusionsLogoBig, alt: 'museum-of-illusions', width: '100px' },
];

export const whyUsArray = [
  {
    subtitle: 'Milestone & Task Management',
  },
  {
    subtitle: 'Payment Scheduling & Analytics ',
  },
  {
    subtitle: 'Smart Contracts with DocuSign Integration',
  },
  {
    subtitle: 'Simple Analytics Dashboard',
  },
];

export const whyUsCarouselElements = [
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.HomeMilestones)}
    component="img"
    width="100%"
    height="100%"
    alt="Milestones and task management"
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.HomePayments)}
    component="img"
    width="100%"
    height="100%"
    alt="Payment scheduling and analytics"
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.HomeContracts)}
    component="img"
    width="100%"
    height="100%"
    alt="Smart contracts with DocuSign integration"
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.HomeHealth)}
    component="img"
    width="100%"
    height="100%"
    alt="Simple analytics dashboard"
  />,
];

export const projectBenefitsArray: MyAccordionProps[] = [
  {
    summary: 'Milestone and Task Management',
    details:
      'Organize your projects with clear milestones and tasks. Keep everyone on the same page without the clutter.',
  },
  {
    summary: 'Payment Scheduling and Analytics',
    details:
      'Automate your payment schedules linked directly to project milestones. Simplify your financial workflows.',
  },
  {
    summary: 'Smart Contracts with DocuSign Integration',
    details:
      'Create and manage contracts effortlessly with our integrated DocuSign capabilities. Secure, fast and legally binding.',
  },
  {
    summary: 'Simple Analytics Dashboard',
    details:
      'Get quick insights into supplier activities and project progress with our easy-to-read analytics. No complex reports - just the information you need.',
  },
];

export const testimonialsArray: Testimonial[] = [
  {
    icon: flwPr,
    subject:
      "We have never had a middleman support like we had with Spona. Your project managers provided us with answers to several questions that we had been unable to obtain for months. It's a great support system and a good buffer; if something goes wrong, paying someone a bit more to do your work for you is definitely worth it.",
    author: 'Jennifer Peros',
    title: 'Director of Talent at Famous Last Words PR',
    alt: 'Famous Last Words logo - representing the brand',
  },
  {
    icon: gruntek,
    subject:
      'The platform offers a very quick and efficient service that really does save your time and money. We were a bit strapped for time as the project was being planned during the holiday season and we wanted to kick it off as soon as possible, but thanks to TDA, everything worked out as fast as possible for us.',
    author: 'Nora Mlinarić',
    title: 'PR & Marketing Specialist at Gruntek',
    alt: 'Gruntek logo - representing the brand',
  },
  {
    icon: icestudio,
    subject:
      'There are many benefits of working with Spona, especially payment processing and support, but also the extensive access to new potential clients. The platform is easy to use and Spona has provided us with excellent support during the entire process.',
    author: 'Ivana Cvetković Lakoš',
    title: 'Designer and Creative Director at ICE STUDIO',
    alt: 'iceStudio logo - representing the brand',
  },
  {
    icon: impress,
    subject:
      'We were especially happy with the amount of pitches we received thanks to Spona. Talking with different companies helped us refine and structure our project needs precisely. During the second round of interviews, we were able to find the perfect seller right away, which saved us a lot of time.',
    author: 'Tamara Budhan Caldwell',
    title: 'Founder and CEO at Impress Education',
    alt: 'Impress Education logo - representing the brand',
  },
  {
    icon: museum,
    subject:
      "We enjoyed working with Spona - it was great to get help with centralizing our outsourcing and resolving any issues that might arise. For us, Spona's level of support and the wide pool of diverse talent are the main advantages of using the platform.",
    author: 'Kata Prolić',
    title: 'CMO at Museum of Illusions',
    alt: 'Museum of Illusions logo - representing the brand',
  },
  {
    icon: weeve,
    subject:
      'The Spona team has done a great job supporting us during the project and following up on issues, and saved us resources looking for digital service sellers.',
    author: 'Christos Zachiotis',
    title: 'IoT Solutions Manager at weeve',
    alt: 'Weeve logo - representing the brand',
  },
];

export const forSellersArray: MyAccordionProps[] = [
  {
    summary: 'No Complex Onborading',
    details:
      'Forget about the long, frustrating setup times that come with other tools. With Spona , everything is pre-assembled and ready to use immediately. Start managing your contracts, tasks and payments right away, without requiring IT support or advanced training.',
  },
  {
    summary: 'Automate Your Workflow',
    details:
      'Spona brings automation to your entire workflow. Create smart contracts, link payments to milestones, and track tasks - all on one platform. Simplify how you handle projects so you can spend less time on administration and more time on what you do best.',
  },
  {
    summary: 'Simple Analytics',
    details:
      'Get quick insights into your project status and supplier activities with our easy-to-understand analytics. Track progress, identify bottlenecks and keep your team and suppliers accountable - all from one straightforward dashboard.',
  },
];
