import { EnumTechLanguage } from 'tdc-web-backend/enums/enums';
import { Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import { mapTechLanguage } from '../../../../../../../enums';
import CompanyTechLanguagesEditModal from '../edit/CompanyTechLanguagesEditModal';
import { truncate } from '../../../../../../../utils/helpers';

interface CompanyTechLanguages {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTechLanguages = ({ isEditModalOpen, setIsEditModalOpen }: CompanyTechLanguages) => {
  const { companyData, chipLabelTruncateValue } = useSellerHomescreenContext();

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <>
        {isEditModalOpen && (
          <CompanyTechLanguagesEditModal
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
          />
        )}

        {companyData?.techLanguages?.map((language: EnumTechLanguage) => (
          <CustomChip
            key={language}
            tooltipTitle={mapTechLanguage.get(language)}
            label={
              language &&
              truncate(mapTechLanguage.get(language) as string, chipLabelTruncateValue as number)
            }
            sx={{ width: 'fit-content', textTransform: 'capitalize' }}
          />
        ))}
      </>
    </Stack>
  );
};

export default CompanyTechLanguages;
