import {
  Box, Stack, Step, Stepper, StepLabel, Typography, Divider,
} from '@mui/material';
import {
  Dispatch, ReactNode, useEffect, useState,
} from 'react';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import Button from '../button/Button';
import useMultiStepFormStore from './useDynamicMultistepStore';
import LoadingButton from '../button/LoadingButton';

export enum StepComponentType {
  REGULAR = 'Regular',
  PREVIEW = 'Preview',
}

// TODO: this whole component will probably need to be refactored
export interface StepComponent {
  component: ReactNode;
  label: string;
  heading?: ReactNode;
  subheading?: ReactNode;
  type?: StepComponentType.REGULAR | StepComponentType.PREVIEW;
}

export interface DynamicMultistepProps {
  stepComponents: StepComponent[];
  finishComponent?: ReactNode;
  onFinish: () => void;
  onSaveDraft?: () => void;
  width?: number | string;
  // sets the height of the component
  height?: number | string;
  minHeight?: number | string;
  sx?: SxProps<Theme> | undefined;
  showComponent?: boolean;
  setShowComponent?: Dispatch<React.SetStateAction<boolean>>
  pushButtonsToBottom?: boolean;
  centerButtons?: boolean;
  buttonsVerticalMargins?: string | number;
  buttonsTopPadding?: string | number;
  buttonsBottomPadding?: string | number;
  buttonsHaveRadius?: boolean;
  shouldGoBackOnFirstStep?: boolean;
  showSaveDraftButton?: boolean;
  isSubmitButtonLoading?: boolean;
  isSubmitSuccessful?: boolean;
  horizontalLineColor?: string;
  setIsCurrentStepPreview?: Dispatch<React.SetStateAction<boolean>>;
  // shouldShowStepper?: Dispatch<React.SetStateAction<boolean>>;
  shouldShowStepper?: boolean;
  liftActiveStep?: (activeStep: number) => void;
}

const DynamicMultistep = (
  {
    stepComponents,
    finishComponent,
    onFinish,
    onSaveDraft,
    width,
    height,
    minHeight,
    sx,
    showComponent = true,
    setShowComponent,
    pushButtonsToBottom,
    centerButtons,
    buttonsVerticalMargins,
    buttonsTopPadding,
    buttonsBottomPadding,
    buttonsHaveRadius,
    shouldGoBackOnFirstStep,
    showSaveDraftButton,
    isSubmitButtonLoading,
    isSubmitSuccessful,
    horizontalLineColor,
    setIsCurrentStepPreview,
    shouldShowStepper = true,
    liftActiveStep,
  }: DynamicMultistepProps,
) => {
  const HorizontalLineRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : horizontalLineColor ?? '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',

      ...(ownerState.active && {
        color: theme.palette.secondary.main,
      }),

      '& .HorizontalLine-base': {
        width: 120,
        height: 5,
        borderRadius: '20px',
        backgroundColor: 'currentColor',
      },

      '& .HorizontalLine-completed': {
        color: '#CCBEFF',
        zIndex: 1,
        fontSize: 18,
      },

    }),
  );

  function HorizontalLine({ active, completed, className }: StepIconProps) {
    return (
      <HorizontalLineRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="HorizontalLine-base HorizontalLine-completed" />
        ) : (
          <div className="HorizontalLine-base" />
        )}
      </HorizontalLineRoot>
    );
  }

  const canGoNext = useMultiStepFormStore((state) => state.canGoNext);
  const canSaveAsDraft = useMultiStepFormStore((state) => state.canSaveAsDraft);
  const canSubmit = useMultiStepFormStore((state) => state.canSubmit);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [showStepper, setShowStepper] = useState<boolean>(shouldShowStepper ?? true);

  const [, setShowPreviewComponent] = useState<boolean>(false);
  const [showStepComponents, setShowStepComponents] = useState<boolean>(true);
  const [showFinishComponent, setShowFinishComponent] = useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (setShowComponent && activeStep === 0) {
      setShowComponent(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setShowFinishComponent(true);
      setShowPreviewComponent(false);
      setShowButtons(false);

      setShowStepComponents(false);
      setShowStepper(false);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (setIsCurrentStepPreview) {
      if (stepComponents[activeStep].type === StepComponentType.PREVIEW) {
        setIsCurrentStepPreview(true);
      } else {
        setIsCurrentStepPreview(false);
      }
    }

    // code below is for lifting activeState up
    if (liftActiveStep) liftActiveStep(activeStep);
  }, [activeStep]);

  return (
    <>
      {
        showComponent
        && (
          <Stack sx={{ width: width ?? '100%', minHeight: minHeight ?? '100%', ...sx }}>
            {
              showStepper
              && (
                <Stepper activeStep={activeStep} connector={null} sx={{ mb: 4 }}>
                  {stepComponents.map((step, index) => (
                    <Step key={step.label} sx={{ height: '100%', minHeight: '62px' }}>
                      <StepLabel
                        // eslint-disable-next-line react/jsx-no-bind
                        StepIconComponent={HorizontalLine}
                        sx={{
                          height: '100%',
                          flexDirection: 'column-reverse',
                          '& .MuiStepLabel-label': {
                            maxWidth: 'fit-content',
                            fontWeight: 'bold',
                            '&.Mui-active': {
                              fontWeight: 'bold',
                              color: '#7E0CF5',
                            },
                            '&.Mui-completed': {
                              fontWeight: 'bold',
                              color: '#CCBEFF',
                            },
                            '&.Mui-disabled': {
                              fontWeight: 'bold',
                              color: '#C5C7C3',
                            },
                          },
                        }}
                      >
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )
            }

            {/* heading and subheading */}
            {
              showStepComponents
                && 'heading' in stepComponents[activeStep]
                && 'subheading' in stepComponents[activeStep]
                && typeof stepComponents[activeStep]?.heading === 'string'
                && typeof stepComponents[activeStep]?.subheading === 'string'
                ? (
                  <Stack spacing={1.5} sx={{ mb: 7 }}>
                    <Typography variant="h5" sx={{ fontWeight: '600' }}>{stepComponents[activeStep]?.heading}</Typography>
                    <Typography variant="body1">{stepComponents[activeStep]?.subheading}</Typography>
                  </Stack>
                ) : (
                  <Stack spacing={1.5} sx={{ mb: 7 }}>
                    {stepComponents[activeStep]?.heading}
                    {stepComponents[activeStep]?.subheading}
                  </Stack>
                )
            }

            {/* step components */}
            {
              showStepComponents
              && (
                <Box sx={{ flexGrow: pushButtonsToBottom ? 1 : 0 }}>
                  {activeStep !== stepComponents.length && stepComponents[activeStep].component}
                </Box>

              )
            }

            {/* finish component */}
            <div hidden={activeStep !== stepComponents.length && !showFinishComponent} style={{ width: '100%', height: '100%' }}>{finishComponent}</div>

            <Divider sx={{ mb: 2, mt: 4 }} />

            {/* back and next buttons */}
            {
              (activeStep !== stepComponents.length && showButtons)
              // showButtons
              && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: centerButtons ? '55%' : '100%',
                    alignSelf: centerButtons ? 'center' : 'auto',
                    my: buttonsVerticalMargins ?? 0,
                    pb: buttonsBottomPadding ?? 0,
                    pt: buttonsTopPadding ?? 0,
                  }}
                >
                  <Button
                    disabled={activeStep === 0 && !shouldGoBackOnFirstStep}
                    onButtonClick={handleBack}
                    setBorderRadius={buttonsHaveRadius ?? true}
                    fontWeight="bold"
                    variant="outlined"
                    color="secondary"
                    minWidth={120}
                  >
                    Back
                  </Button>

                  <Stack spacing={2} direction="row">
                    {
                      showSaveDraftButton
                      && (
                        <Button
                          // TODO: implement the Save Draft button functionality
                          disabled={!canSaveAsDraft}
                          onButtonClick={() => { if (onSaveDraft) onSaveDraft(); }}
                          setBorderRadius={buttonsHaveRadius ?? true}
                          fontWeight="bold"
                          variant="outlined"
                          color="secondary"
                          minWidth={120}
                        >
                          Save Draft
                        </Button>
                      )
                    }

                    {
                      activeStep === stepComponents.length - 1
                        ? (
                          <LoadingButton
                            disabled={!canSubmit}
                            loading={isSubmitButtonLoading}
                            onButtonClick={onFinish}
                            setBorderRadius={buttonsHaveRadius ?? true}
                            fontWeight="bold"
                            variant="contained"
                            color="secondary"
                            minWidth={120}
                          >
                            Submit
                          </LoadingButton>
                        ) : (
                          <Button
                            disabled={!canGoNext}
                            onButtonClick={handleNext}
                            setBorderRadius={buttonsHaveRadius ?? true}
                            fontWeight="bold"
                            variant="contained"
                            color="secondary"
                            minWidth={120}
                          >
                            {stepComponents[activeStep + 1].type === StepComponentType.PREVIEW ? 'Preview' : 'Next'}
                          </Button>
                        )
                    }
                  </Stack>
                </Stack>
              )
            }
          </Stack>
        )
      }
    </>
  );
};

export default DynamicMultistep;
