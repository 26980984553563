import { IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import { ReactComponent as ArrowDownThinIcon } from '../../../../assets/icons/layout-icons/ArrowDownThinIcon.svg';

interface ChipGroupProps {
  label: string;
  choices: Map<string, string>;
  values: any;
}

const ChipGroupAccordion = ({ label, choices, values }: ChipGroupProps) => {
  const [open, setOpen] = useState(true);
  return (
    <Stack flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <BaseInputLabel text={label} color="primaryDark.900" />
        <IconButton onClick={() => setOpen(!open)}>
          <ArrowDownThinIcon
            width={12}
            height={8}
            style={{
              transform: open ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'all 0.2s',
              color: 'red !important',
            }}
          />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        gap={1}
        flexWrap="wrap"
        overflow="hidden"
        sx={{
          maxHeight: open ? 'max-content' : '0px',
        }}
      >
        {values?.map((item: any) => (
          <CustomChip label={choices.get(item)} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ChipGroupAccordion;
