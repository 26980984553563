import React, { ElementType, SVGProps } from 'react';
import { Box } from '@mui/material';
import { secondaryBlue, secondaryPink } from '../../color';

type VariantColors = 'blue' | 'pink';

type FramedIconProps = {
  variant: VariantColors;
  Icon: ElementType<SVGProps<SVGSVGElement>>;
};

const variantColorMap: Record<VariantColors, { background: string; foreground: string }> = {
  pink: {
    background: secondaryPink[100],
    foreground: secondaryPink[700],
  },
  blue: {
    background: secondaryBlue[100],
    foreground: secondaryBlue[700],
  },
};

export default ({ Icon, variant }: FramedIconProps) => {
  const { background, foreground } = variantColorMap[variant];
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: background,
        width: '40px',
        height: '40px',
        borderRadius: '4px',
      }}
    >
      <Icon fill={foreground} style={{ width: 20, height: 20 }} />
    </Box>
  );
};
