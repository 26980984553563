import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import { Paginated } from 'tdc-web-backend/common/schemas';
import axios from '../../../api/axios';

type GetMany<T> = {
  resource: string;
} & UseQueryOptions<unknown, unknown, AxiosResponse<Paginated<T>>>

const useGetMany = <T>({
  resource,
  ...props
}: GetMany<T>) => {
  const getMany = (): Promise<AxiosResponse<Paginated<T>>> => axios.get(resource);
  // Remove need for "/" in resource prop
  return useQuery<unknown, unknown, AxiosResponse<Paginated<T>>>({
    queryKey: [`${resource}-many`],
    queryFn: getMany,
    ...props,
  });
};

export default useGetMany;
