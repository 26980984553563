import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack, Box } from '@mui/system';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PasswordResetDto, LoginRequestDto, LoginResponseDto } from 'tdc-web-backend/auth/schemas';
import CustomButton from '../../../components/button/CustomButton';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import CustomInput from '../../../components/custom-input/CustomInput';

const ConfirmAccountSection = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const formMethods = useForm({
    defaultValues: {
      email: email || '',
      password: '',
      confirmPassword: '',
      token: token || '',
    },
  });
  const { watch, handleSubmit, getValues } = formMethods;
  const { mutate, isLoading } = useCreate<Record<string, unknown>, Record<string, unknown>>({
    resource: `auth/confirm-account?token=${token}&email=${email}`,
  });

  const [showPass, setShowPass] = useState({
    password: false,
    repeatPassword: false,
  });

  const { mutate: mutateLogin } = useCreate<LoginResponseDto, LoginRequestDto>({
    resource: '/auth/login',
  });

  const onSubmit = (data: PasswordResetDto) => {
    mutate(data, {
      onSuccess: () => {
        const loginData: LoginRequestDto = {
          email: email as string,
          password: data.password,
        };
        mutateLogin(loginData, {
          onSuccess: (loginResponse) => {
            localStorage.setItem('AccessToken', loginResponse.data.token);
            navigate('/dashboard/end-to-end', { replace: true });
          },
        });
      },
    });
  };

  return (
    <Stack spacing={6} sx={{ pb: 4 }}>
      <Typography
        variant="headline1"
        sx={{
          color: 'primaryDark.600',
        }}
      >
        Enter password
      </Typography>
      <FormProvider {...formMethods}>
        <Box
          component="form"
          sx={{ height: '90%' }}
          onSubmit={handleSubmit(() =>
            onSubmit({
              password: getValues('password'),
              token: getValues('token'),
            }),
          )}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Gellix-SemiBold',
                color: '#79829B',
                fontSize: '12px',
              }}
            >
              email
            </Typography>
            {email}
            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.password ? 'text' : 'password'}
              name="password"
              label="Change Password"
              required
              defaultHelperText="Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character."
              placeholder="••••••••"
              adornment={{
                icon: showPass.password ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, password: !showPass.password });
                },
              }}
              validationRegEx={{
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
                message:
                  'Your password must be a minimum of 8 characters in length and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.',
              }}
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
            />

            <CustomInput
              input={<CustomInput.Text />}
              type={showPass.repeatPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="Repeat password"
              required
              placeholder="••••••••"
              adornment={{
                icon: showPass.repeatPassword ? (
                  <VisibilityIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: 'primaryDark.400', width: 22, height: 22 }} />
                ),
                onClick: () => {
                  setShowPass({ ...showPass, repeatPassword: !showPass.repeatPassword });
                },
              }}
              validate={() =>
                watch('confirmPassword') === watch('password') || 'Passwords do not match'
              }
              rules={{
                maxLength: {
                  value: 127,
                  message:
                    'Password is too long. Please choose a password with fewer than 128 characters',
                },
              }}
            />
            <CustomInput
              input={<CustomInput.UserAgreement />}
              name="TermsAndConditions"
              required
              customRequiredMessage="Agree to terms to continue"
            />

            <CustomButton
              type="submit"
              loading={isLoading}
              sx={{
                alignSelf: 'flex-end',
                width: { xs: 'fit-content', lg: '35%' },
                fontSize: '14px',
              }}
            >
              Continue{' '}
            </CustomButton>
          </Stack>
        </Box>
      </FormProvider>
    </Stack>
  );
};

export default ConfirmAccountSection;
