import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EnumTimedProjectPitchStatus,
  EnumTimedProjectPitchType,
} from 'tdc-web-backend/enums/enums';
import {
  MaterialsDto,
  TimedProjectContentDto,
} from 'tdc-web-backend/timed-project-contents/schemas';
import {
  CreateTimedProjectPitchContentDto,
  TimedProjectPitchContentDto,
  UpdateTimedProjectPitchContentDto,
} from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { BaseTimedProjectPitch } from 'tdc-web-backend/timed-project-pitches/schemas';
import MultiStep from '../../../components/multisteps/Multistep';
import useMultiStep from '../../../components/multisteps/useMultiStep';
import { getFileTypeFromUrl, printHelperTextForSpecificCurrentStep } from '../../../utils/helpers';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import PitchCreateModal from '../../Pitch/create/components/PitchCreateModal';
import Materials from '../../Pitch/create/steps/Materials';
import Portfolio from './components/Portfolio';
import Strategy from '../../Pitch/create/steps/Strategy';
import { CreatePitchType } from '../../Pitch/pitch.interface';
import SelectSellerAndTimedProjectStep from './components/SelectSellerAndTimedProjectStep';
import About from '../../Pitch/create/steps/About';
import Preview from '../../Pitch/create/steps/Preview';

const labels = ['Seller', 'About', 'Strategy', 'Portfolio', 'Materials', 'Preview'];
const helperTexts = [
  'Provide an overview of your company and team that highlights why you are a good fit for the project.',
  'Outline your project strategy. You do not have to go in specific detail, as you will be expected to provide a more concrete solution only if your pitch is accepted into the second round of negotiations.',
  '',
  'Upload additional documentation such as your pitch deck. Add links to external portfolios or any applicable materials that demonstrate relevant skills and knowledge.',
];

export interface CreatePitchProps {
  type: 'create' | 'edit';
}

const initialState: CreatePitchType & { seller: string; timedProjectName: string } = {
  seller: '',
  timedProjectName: '',
  timedProject: '',
  //
  overview: {
    description: '',
    strategy: '',
  },
  caseStudiesPreview: [],
  caseStudies: [],
  materialsPreview: null,
  materials: [],
};

const PitchingAdminCreate = ({ type }: CreatePitchProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const { form, steps, currentStep, onStepChange } = useMultiStep<CreatePitchType>({
    labels,
    initialState,
  });

  const [openModal, setIsOpenModal] = useState(false);
  const [openEditModal, setIsOpenEditModal] = useState(false);
  const [openDraftModal, setIsOpenDraftModal] = useState(false);
  const [openErrorModal, setIsOpenErrorModal] = useState(false);
  const [openCancelModal, setIsOpenCancelModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { data: pitchData } = useReferenceOne<BaseTimedProjectPitch>({
    resource: 'timed-project-pitches',
    enabled: !!params?.timedProjectPitchId,
    id: params.timedProjectPitchId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  const { data: pitchContentData } = useReferenceOne<TimedProjectPitchContentDto>({
    resource: 'timed-project-pitch-contents',
    enabled: !!pitchData,
    id: pitchData?.edit || pitchData?.content,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { data: timedProjectContentData } = useReferenceOne<TimedProjectContentDto>({
    resource: 'timed-project-contents',
    enabled: !!pitchContentData,
    id: pitchContentData?.timedProjectContentCurrent.id,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const { mutate: createPitch, isLoading: createPitchIsLoading } = useCreate<
    TimedProjectPitchContentDto,
    Partial<CreateTimedProjectPitchContentDto>
  >({
    resource: '/timed-project-pitches/admin',
  });

  const { mutate: updatePitch, isLoading: updatePitchIsLoading } = useUpdate<
    BaseTimedProjectPitch,
    UpdateTimedProjectPitchContentDto
  >({
    resource: '/timed-project-pitches',
  });

  useEffect(() => {
    // if type is edit and when there is pitchContentData,
    // reset the data to have inital values populated with
    // pitchContentData
    const resetForm = async () => {
      if (
        type === 'edit' &&
        pitchContentData !== undefined &&
        timedProjectContentData !== undefined &&
        pitchData !== undefined
      ) {
        const convertedFilesUrls = await Promise.all(
          pitchContentData.materials.map(async (material: any) => {
            const response = await fetch(material.file);
            const blob = await response.blob();
            const file = new File([blob], material.fileName, {
              type: getFileTypeFromUrl(material.file),
            });

            return file;
          }),
        );

        form.reset({
          overview: {
            description: pitchContentData.overview?.description,
            strategy: pitchContentData.overview?.strategy,
          },
          caseStudiesPreview: pitchContentData.caseStudies.map(
            (caseStudy: TimedProjectPitchContentDto['caseStudies']) => ({
              id: caseStudy.id,
              companyName: caseStudy.seller.name,
              companyLogo: caseStudy.seller.avatar,
              coverImg: caseStudy.file,
            }),
          ),
          caseStudies: pitchContentData.caseStudies.map(
            (caseStudy: TimedProjectPitchContentDto['caseStudies']) => caseStudy.id,
          ),
          materialsPreview: convertedFilesUrls.map((file) => ({
            file,
            isUploadInProgress: false,
            uploadProgress: 100,
            error: null,
          })),
          materials: pitchContentData.materials.map((material: any) => material),

          //
          seller: pitchContentData.seller,
          timedProject: timedProjectContentData.timedProject,
          timedProjectName: pitchData?.projectName,
        });
      }
    };

    resetForm();
  }, [pitchContentData, timedProjectContentData, pitchData]);

  const onSubmit = (data: any, isDraft?: boolean) => {
    const {
      materialsPreview,
      caseStudiesPreview,
      userAgreement,
      materials,
      timedProjectName,
      ...rest
    } = data;

    const formData = {
      ...rest,
      ...(type === 'create' && { timedProject: form.getValues('timedProject') }),
      requestPublish: !isDraft,
      type: EnumTimedProjectPitchType.Form,
      materials: materials?.map((material: MaterialsDto) => material.id),
    };

    const eventHandlers = {
      onSuccess: () => {
        if (isDraft) {
          setIsOpenDraftModal(false);
          navigate('../');
        } else if (type === 'edit' && pitchData?.status !== EnumTimedProjectPitchStatus.Draft) {
          setIsOpenEditModal(true);
        } else {
          setIsOpenModal(true);
        }
      },
      onError: (err: any) => {
        setErrorMsg(err.response?.data.message);
        if (isDraft) {
          setIsOpenDraftModal(false);
        }
        setIsOpenErrorModal(true);
      },
    };
    if (type === 'edit') {
      updatePitch({ id: pitchData?.id as string, data: formData, suffix: 'admin' }, eventHandlers);
    } else {
      createPitch(formData, eventHandlers);
    }
  };

  // TODO: uncomment and fix
  // useEffect(() => {
  //   if (state === null) {
  //     navigate('/dashboard/pitches')
  //   }
  // }, [state]);

  return (
    <>
      {/* multistep wrapper */}
      <Box sx={{ width: currentStep === 5 ? '100%' : '70%' }}>
        <MultiStep<Partial<CreateTimedProjectPitchContentDto>>
          form={form}
          steps={steps}
          currentStep={currentStep}
          onChange={onStepChange}
          onSaveAsDraft={() => setIsOpenDraftModal(true)}
          onSubmit={(data) => onSubmit(data, false)}
          layoutOrientation={currentStep === 5 ? 'row' : 'column'}
          helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
          onCancelClick={() => setIsOpenCancelModal(true)}
          isSubmitLoading={createPitchIsLoading || updatePitchIsLoading}
        >
          <>
            {currentStep === 1 && <SelectSellerAndTimedProjectStep type={type} />}
            {type === 'edit'
              ? currentStep === 2 && form.getValues('overview.description') !== null && <About />
              : currentStep === 2 && <About />}
            {currentStep === 3 && <Strategy />}
            {currentStep === 4 && <Portfolio />}
            {currentStep === 5 && <Materials resource="/timed-project-pitches/upload-materials" />}
            {currentStep === 6 && <Preview />}
          </>
        </MultiStep>

        <PitchCreateModal
          type="create"
          timedProjectName={form.getValues('timedProjectName')}
          isOpen={openModal}
          setIsOpen={setIsOpenModal}
        />

        <PitchCreateModal type="edit" isOpen={openEditModal} setIsOpen={setIsOpenEditModal} />

        <PitchCreateModal
          type="draft"
          isOpen={openDraftModal}
          setIsOpen={setIsOpenDraftModal}
          onConfirm={() => {
            onSubmit(form.getValues(), true);
          }}
        />

        <PitchCreateModal
          type="error"
          isOpen={openErrorModal}
          setIsOpen={setIsOpenErrorModal}
          errorMessage={errorMsg}
        />

        <PitchCreateModal type="cancel" isOpen={openCancelModal} setIsOpen={setIsOpenCancelModal} />
      </Box>
    </>
  );
};

export default PitchingAdminCreate;
