import { Box, Divider, Paper, Typography } from '@mui/material';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import Document from '@tiptap/extension-document';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Bold from '@tiptap/extension-bold';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import { EditorContent, isActive, HTMLContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect, useState } from 'react';
import './editor.scss';
import { MenuItem } from './EditorCommand';
import Iframe from './extensions/iframe/iframe';
import LinkBubbleMenu from './menu/LinkBubbleMenu';
import MenuBar from './menu/MenuBar';
import { getReadingTime } from '../../utils/helpers';
import { primaryDark, secondaryBlue, secondaryPink } from '../../color';

export interface EditorProps {
  // KEY IS REQUIRED FOR EDITOR TO RENDER, BUT IS MARKED AS OPTIONAL DUE TO SPECIFIC USE CASES
  uniqueKey?: string;
  contentHeight?: number | string;
  isEditable: boolean;
  items: MenuItem[];
  content?: HTMLContent;
  onContentChange?: (data: HTMLContent) => void;
  onUpdateContent?: (data: HTMLContent) => void;
  setReadingTime?: (minutes: number) => void;
  onValidationChange?: (valid: boolean) => void;
  hasError?: boolean; // Control colors
  onBlur?: () => void; // Trigger the RHF validation (it changes the 'isTouched' state)
  textColorWhenNonEditable?: string;
  notEditableFontSize?: string;
}

const Editor = ({
  uniqueKey,
  hasError,
  contentHeight,
  isEditable,
  items,
  content,
  onContentChange,
  setReadingTime,
  onValidationChange,
  onUpdateContent,
  onBlur,
  textColorWhenNonEditable,
  notEditableFontSize,
}: EditorProps) => {
  const editor = useEditor({
    onBlur(event) {
      if (onBlur) {
        onBlur();
      }
      if (onContentChange) {
        onContentChange(event.editor.getHTML());
      }
    },
    onUpdate(event) {
      if (!onUpdateContent) return;
      // Workaround to remove the empty <p> tag that is added when the editor is empty
      const value = event.editor.isEmpty ? '' : event.editor.getHTML();
      onUpdateContent(value);
    },
    extensions: [
      Document,
      StarterKit.configure({
        document: false,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return 'Write the heading...';
          }

          return 'Type';
        },
      }),
      Image.configure({
        allowBase64: true,
        inline: true,
        HTMLAttributes: {
          class: 'editor-image-class',
        },
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
      Underline,
      TextStyle,
      FontFamily.configure({
        types: ['Gallix-Regular'],
      }),
      Iframe,
      Table.configure({
        resizable: true,
      }),
      Bold.configure({
        HTMLAttributes: {
          class: 'editor-bold-text-class',
        },
      }),
      TableRow,
      TableHeader,
      TableCell,
      Link.configure({
        openOnClick: !isEditable,
      }),
      BubbleMenu.configure({
        element: document.querySelector('.bubble-menu') as HTMLElement,
        shouldShow: ({ state }) => isActive(state, 'link'),
      }),
    ],
    editable: isEditable,
    content: content || {},
  });

  if (!isEditable) {
    const contentText = editor?.state.doc.textContent ?? '';
    const minutes = getReadingTime(contentText);
    if (setReadingTime) {
      setReadingTime(minutes);
    }

    return (
      <Box
        sx={{
          '.ProseMirror ': { padding: 0, minHeight: 'unset' },
          '.ProseMirror p': {
            color: textColorWhenNonEditable || primaryDark[600],
            ...(notEditableFontSize ? { fontSize: notEditableFontSize } : {}),
          },
        }}
      >
        <EditorContent editor={editor} />
      </Box>
    );
  }

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(content ? content.length > 0 : false);
    }
  }, [content]);

  let hasItems = true;

  if (!items) {
    hasItems = false;
  } else {
    hasItems = items.length > 0;
  }

  const [updateControl, setUpdateControl] = useState(true);

  useEffect(() => {
    // Prevent unnecessary re-renders when setting to state
    if (updateControl && content !== undefined) {
      editor?.commands.setContent(content || {});
      setUpdateControl(false);
    }
  }, [content]);

  return hasItems ? (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        border: hasError
          ? `1px solid ${secondaryPink['100']}`
          : `1px solid ${secondaryBlue['100']} `,
        backgroundColor: hasError ? '#FFF2F4' : 'transparent',
        '.MuiPaper-rounded': { backgroundColor: hasError ? '#FFF2F4' : 'white' },
      }}
    >
      <MenuBar editor={editor} items={items} uniqueKey={uniqueKey} />
      <Divider sx={{ borderColor: secondaryBlue['100'] }} />
      <Box sx={{ height: contentHeight ?? 250, overflow: 'scroll' }}>
        <LinkBubbleMenu editor={editor} />
        <EditorContent editor={editor} />
      </Box>
    </Paper>
  ) : (
    <Typography color="primary" variant="h4" component="h4">
      You need to provide editor actions...
    </Typography>
  );
};

export default Editor;
