import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as BoxArrowIcon } from '../../../../assets/icons/box-arrow.svg';
import { fr } from 'date-fns/locale';

const Actions = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (data: any) => void;
  isLoading: boolean;
}) => {
  const form = useFormContext();
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="flex-end" sx={{ pl: '24px', pr: '20px' }} spacing={2}>
      <CustomButton
        disabled={isLoading}
        variant="secondary"
        withBackArrow
        onClick={() => navigate('../')}
      >
        Back
      </CustomButton>

      <CustomButton
        key="submit"
        variant="primary"
        onClick={form.handleSubmit(() => onSubmit(form.getValues()))}
        sx={{ width: 'fill-content' }}
        endIcon={<BoxArrowIcon />}
        loading={isLoading}
      >
        Submit
      </CustomButton>
    </Stack>
  );
};

export default Actions;
