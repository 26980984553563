import { GridColDef, DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { EnumProjectContractStatus } from 'tdc-web-backend/enums/enums';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState } from 'react';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatDateFns } from '../../../../utils/helpers';
import ContractPdfUploadModal from './modals/ContractPdfUploadModal';

export interface AnnexListProps {
  rows: ContractDto[];
}

const AdminAnnexList = ({ rows }: AnnexListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => <TaskStatus text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.4,
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        // TODO: Change when BE is ready
        <Typography sx={{ paddingLeft: !params.value ? '38px' : 0 }}>
          {formatDateFns(params.value, '-')}
        </Typography>
      ),
    },
    {
      field: 'signed',
      headerName: 'Last Signed',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ paddingLeft: !params.value ? '38px' : 0 }}>
          {formatDateFns(params.value, '-')}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.01,
      align: 'center',
      renderCell: (params: GridValueGetterParams) =>
        params.row.document ? (
          <a href={params.row.document} target="_blank" rel="noreferrer">
            <IconButton>
              <SaveAltIcon />
            </IconButton>
          </a>
        ) : (
          <>
            <IconButton
              disabled={
                params.row.status === EnumProjectContractStatus.Draft ||
                params.row.status === EnumProjectContractStatus.Canceled ||
                params.row.status === EnumProjectContractStatus.Negotiation
              }
              onClick={() => setIsModalOpen(true)}
            >
              <UploadFileIcon />
            </IconButton>
            <ContractPdfUploadModal
              data={params.row}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
            />
          </>
        ),
    },
  ];

  return (
    <Box
      sx={{
        overflow: 'hidden',
        bgcolor: 'white',
        p: 0,
        borderRadius: '16px',
        border: '1px solid #C5C7C3',
      }}
    >
      <DataGrid
        hideFooter
        rows={rows}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        sx={{
          minHeight: '400px',
          backgroundColor: '#fff',
          '.MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'Poppins, sans-serif',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: '#FBFDF8',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
      />
    </Box>
  );
};

export default AdminAnnexList;
