import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import CustomArrow from '../../../../../components/custom-arrow/CustomArrow';

export interface PitchesPanelColumnsHeaderProps {
  title: string;
  itemsCount: number;
  icon: ReactElement;
  showArrow?: boolean;
}

const PitchesPanelColumnsHeader = ({
  title,
  itemsCount,
  icon,
  showArrow = true,
}: PitchesPanelColumnsHeaderProps) => (
  <Stack spacing={2} direction="row" alignItems="center">
    <Stack direction="row" spacing={0.5} alignItems="center" sx={{ flexShrink: 0 }}>
      {icon}
      <Typography variant="heading3" color="primaryDark.500">
        {title}
      </Typography>
      <Typography variant="heading3" color="primaryDark.700">
        {itemsCount}
      </Typography>
    </Stack>

    {showArrow && <CustomArrow color="primaryDark.300" />}
  </Stack>
);

export default PitchesPanelColumnsHeader;
