import { Typography, Stack } from '@mui/material';
import { ReactNode } from 'react';

const CustomList = ({
  listType = 'dot',
  items,
  bold,
  omitIndex = false,
  omitListType = false,
}: {
  listType?: 'dot' | 'number';
  items: string[] | ReactNode[];
  bold?: boolean;
  omitIndex?: boolean;
  omitListType?: boolean;
}) => (
  <Stack sx={{ ml: '32px !important' }}>
    {items.map((item, index) => (
      <Stack spacing={1} direction="row" alignItems="start">
        {!omitListType && listType === 'dot' && <Typography>•</Typography>}

        {omitIndex ? (
          <Typography variant={bold ? 'publicBody5' : 'publicBody5Regular'} color="common.black">
            {item}
          </Typography>
        ) : (
          <>
            {listType === 'number' && (
              <Typography
                variant={bold ? 'publicBody5' : 'publicBody5Regular'}
                color="common.black"
              >
                {index + 1}.
              </Typography>
            )}

            <Typography variant={bold ? 'publicBody5' : 'publicBody5Regular'} color="common.black">
              {item}
            </Typography>
          </>
        )}
      </Stack>
    ))}
  </Stack>
);

export default CustomList;
