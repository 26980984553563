/* eslint-disable import/no-unresolved */
import { Stack, Typography } from '@mui/material';
import { ReactComponent as FeedbackIcon } from '../../../../assets/icons/feedback.svg';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { CaseStudyEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import Editor from '../../../../components/editor/Editor';
import { primaryDark } from '../../../../color';
import { CaseStudyDataProps } from '../interface';
import StarRating from '../../../../components/star-rating/StarRating';

const CaseStudyClientReview = ({ data, scrollRef }: CaseStudyDataProps) => {
  const reviewer = data?.review?.reviewer;
  return (
    <>
      <Stack pb={2} pt={1}>
        <Stack mb={1.5} direction="row" alignItems="center" spacing={1} py={{ xs: 0, lg: 3 }}>
          <FeedbackIcon ref={scrollRef as any} fill={primaryDark[500]} />
          <BaseInputLabel textVariant="heading3" text="Client review" />
        </Stack>
        <Stack bgcolor="secondaryBlue.50" p={2.25} spacing={1} mb={4}>
          <Typography variant="publicBody5Regular" color="primaryDark.700">
            “{data?.review?.summarization}”
          </Typography>
          <Typography variant="publicBody5" color="primaryDark.700">
            {reviewer
              ? `${reviewer.firstName} ${reviewer.firstName}, ${reviewer.jobTitle}, ${reviewer.companyName}`
              : 'Unknown'}
          </Typography>
        </Stack>
        <Stack mb={1.5} direction="row" justifyContent="space-between">
          <Typography variant="publicBody6">Quality</Typography>
          <StarRating rating={Math.round(data?.review?.score?.qualityScore ?? 0)} />
        </Stack>
        {data?.review?.score.qualityText && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.review?.score.qualityText}
            isEditable={false}
            key="quality"
          />
        )}
      </Stack>
      <Stack py={2}>
        <Stack mb={1.5} direction="row" justifyContent="space-between">
          <Typography variant="publicBody6">Budget</Typography>
          <StarRating rating={Math.round(data?.review?.score?.budgetScore ?? 0)} />
        </Stack>
        {data?.review?.score.budgetText && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.review?.score.budgetText}
            isEditable={false}
            key="budget"
          />
        )}
      </Stack>
      <Stack py={2}>
        <Stack mb={1.5} direction="row" justifyContent="space-between">
          <Typography variant="publicBody6">Schedule</Typography>
          <StarRating rating={Math.round(data?.review?.score?.scheduleScore ?? 0)} />
        </Stack>
        {data?.review?.score.scheduleText && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.review?.score.scheduleText}
            isEditable={false}
            key="schedule"
          />
        )}
      </Stack>
      <Stack py={2}>
        <Stack mb={1.5} direction="row" justifyContent="space-between">
          <Typography variant="publicBody6">Willingness to refer</Typography>
          <StarRating rating={Math.round(data?.review?.score?.recommendationScore ?? 0)} />
        </Stack>
        {data?.review?.score.recommendationText && (
          <Editor
            items={CaseStudyEditorMenu}
            content={data?.review?.score.recommendationText}
            isEditable={false}
            key="willingness"
          />
        )}
      </Stack>
    </>
  );
};

export default CaseStudyClientReview;
