import MultiStep from '../../../../components/multisteps/Multistep';
import { Box } from '@mui/system';
import useMultiStep from '../../../../components/multisteps/useMultiStep';
import { printHelperTextForSpecificCurrentStep } from '../../../../utils/helpers';
import { CreateTimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import About from '../../../Dashboard/TimedProjects/create/About';
import Overview from '../../../Dashboard/TimedProjects/create/Overview';
import Seller from '../../../Dashboard/TimedProjects/create/Seller';
import Timeline from '../../../Dashboard/TimedProjects/create/Timeline';
import Budget from '../../../Dashboard/TimedProjects/create/Budget';
import Materials from '../../../Pitch/create/steps/Materials';
import Preview from '../../../Dashboard/TimedProjects/create/Preview';
import {
  EnumCountry,
  EnumCurrency,
  EnumService,
  EnumServiceProviderScope,
  EnumTechLanguage,
  EnumTechPlatform,
  EnumTechTool,
  EnumTimedProjectStatus,
} from 'tdc-web-backend/enums/enums';
import SelectBuyerStep from './components/SelectBuyerStep';
import { MaterialsDto } from 'tdc-web-backend/timed-project-contents/schemas';
import { getFileTypeFromUrl } from '../../../../utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import { BaseTimedProject } from 'tdc-web-backend/timed-projects/schemas';
import { TimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import TimedProjectCreateModal from '../../../Dashboard/TimedProjects/components/TimedProjectCreateModal';
import PreviewSidebar from '../../../Dashboard/TimedProjects/create/PreviewSidebar';

export const labels = [
  'Buyer',
  'Overview',
  'About',
  'Seller',
  'Timeline',
  'Budget',
  'Materials',
  'Preview',
];

const helperTexts = [
  'In your company description, outline your general goals and direction without referring explicitly to your company name, as your identity will only be shared with sellers in the second round of negotiations.',
  'For project requirements, feel free to go as detailed as you desire. The more information you can provide, the bigger the chance that pitches will address your specific needs.',
  'Define the core service and technical expertise you expect your digital service seller to provide, if applicable to your project type.',
  'Projects with a flexible pitching deadline will be closed once you have selected a seller to cooperate with, or after multiple months of inactivity.',
  'If your project has a separate budget amount for its execution (e.g. marketing budget), please specify the amount of the budget allotted to the execution and to the seller in the budget breakdown section.',
  'Additional materials will be shared only with sellers who are accepted into the second round of negotiations. The files will be automatically uploaded into your shared chat space.',
];

const initialState = {
  overview: {
    name: '',
    description: '',
  },
  about: {
    goals: '',
    targetMarkets: [] as EnumCountry[],
    audience: '',
    competitors: [],
    industries: [],
  },
  skills: {
    services: [] as EnumService[],
    platforms: [] as EnumTechPlatform[],
    tools: [] as EnumTechTool[],
    languages: [] as EnumTechLanguage[],
    providerScope: 'undefined' as EnumServiceProviderScope,
  },
  timeline: {
    pitchDeadline: null,
    projectStart: null,
  },
  budget: {
    budget: null,
    recurring: false,
    currency: 'usd' as EnumCurrency,
    budgetDescription: '',
  },
  materialsPreview: null, // remove this field on the last step
  materials: [],
  userAgreement: false,
  buyer: '',
};

const editableStatuses = ['draft', 'active'];

const setMaterialStatus = (file: File) => ({
  file,
  isUploadInProgress: false,
  uploadProgress: 100,
  error: null,
});

const extendMaterials = (materials: MaterialsDto[] | undefined) => {
  if (materials) {
    return Promise.all(
      materials.map(async (material) => {
        const fileBits = await fetch(material.file).then((res) => res.blob());
        const fileType = getFileTypeFromUrl(material.file);

        return new File([fileBits], material.fileName, {
          type: fileType,
        });
      }),
    );
  }
  return null;
};

const AdminCreateTimedProjects = () => {
  const { currentStep, steps, onStepChange, form } = useMultiStep({ labels, initialState });
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.timedProjectId || '';
  const editMode = !!projectId;
  const [openModal, setIsOpenModal] = useState(false);
  const [openEditModal, setIsOpenEditModal] = useState(false);
  const [openDraftModal, setIsOpenDraftModal] = useState(false);
  const [openErrorModal, setIsOpenErrorModal] = useState(false);
  const [openCancelModal, setIsOpenCancelModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { data: timedProject } = useGetOne<BaseTimedProject>({
    resource: `timed-projects/${projectId}`,
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  const firstDraft: boolean =
    editMode &&
    timedProject?.data.edit === null &&
    timedProject?.data.status === EnumTimedProjectStatus.Draft;

  const contentId =
    timedProject?.data.edit === null ? timedProject?.data.content : timedProject?.data.edit;

  const { data: timedProjectContent } = useGetOne<TimedProjectContentDto>({
    resource: `timed-project-contents/${contentId}`,
    enabled: !!contentId,
    refetchOnWindowFocus: false,
  });

  const { mutate: createTimedProject, isLoading: createTimedProjectIsLoading } = useCreate<
    CreateTimedProjectContentDto,
    Partial<CreateTimedProjectContentDto>
  >({
    resource: '/timed-projects/admin',
  });

  const { mutate: updateTimedProject, isLoading: updateTimedProjectIsLoading } = useUpdate({
    resource: `/timed-projects`,
  });

  const initialValues = timedProjectContent?.data;

  const onSubmit = (data: any, isDraft?: boolean) => {
    const { materialsPreview, userAgreement, materials, buyer, ...rest } = data;

    const formData = {
      body: {
        ...rest,
        budget: { ...rest.budget, budget: Number(rest.budget.budget) },
      },
      buyerApproved: !isDraft,
      buyer: buyer,
      materials: materials?.map((material: MaterialsDto) => material.id),
    };
    const eventHandlers = {
      onSuccess: () => {
        if (!isDraft) {
          params?.projectId ? setIsOpenEditModal(true) : setIsOpenModal(true);
        }
      },
      onError: (err: any) => {
        if (!isDraft) {
          setErrorMsg(err.response?.data.message);
          setIsOpenErrorModal(true);
        }
      },
    };
    if (editMode) {
      updateTimedProject({ id: projectId, data: formData, suffix: 'admin' }, eventHandlers);
    } else {
      createTimedProject(formData, eventHandlers);
    }
  };

  useEffect(() => {
    if (initialValues && projectId) {
      const setInitialValues = async () => {
        const { body, ...rest } = initialValues;
        const extendedMaterials = await extendMaterials(initialValues.materials);
        const materialsPreview = extendedMaterials?.map(setMaterialStatus);

        form.reset({
          ...body,
          materials: rest.materials,
          materialsPreview,
          buyer: timedProjectContent.data.buyer,
        });
      };
      setInitialValues();
    }
  }, [initialValues]);

  return (
    <Box sx={{ width: '70%' }}>
      <MultiStep
        form={form}
        steps={steps}
        currentStep={currentStep}
        onChange={onStepChange}
        onSubmit={(data) => onSubmit(data, false)}
        onSaveAsDraft={() => setIsOpenDraftModal(true)}
        helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
        onCancelClick={() => setIsOpenCancelModal(true)}
        isSubmitLoading={
          !!(updateTimedProjectIsLoading === true || createTimedProjectIsLoading === true)
        }
        rightColumnRenderProp={() => <PreviewSidebar />}
        layoutOrientation={currentStep === 8 ? 'row' : 'column'}
      >
        <>
          {currentStep === 1 && <SelectBuyerStep />}
          {
            // Workaround to fix Editor issue with initialValue
            initialValues
              ? currentStep === 2 && form.getValues('overview.description') && <Overview />
              : currentStep === 2 && <Overview />
          }
          {currentStep === 3 && <About />}
          {currentStep === 4 && <Seller />}
          {currentStep === 5 && <Timeline />}
          {currentStep === 6 && <Budget />}
          {currentStep === 7 && <Materials resource="/timed-projects/upload-materials" />}
          {currentStep === 8 && <Preview />}
        </>
      </MultiStep>
      <TimedProjectCreateModal type="create" isOpen={openModal} setIsOpen={setIsOpenModal} />
      <TimedProjectCreateModal type="edit" isOpen={openEditModal} setIsOpen={setIsOpenEditModal} />
      <TimedProjectCreateModal
        type="draft"
        isOpen={openDraftModal}
        setIsOpen={setIsOpenDraftModal}
        onConfirm={() => {
          onSubmit(form.getValues(), true);
        }}
      />
      <TimedProjectCreateModal
        type="error"
        isOpen={openErrorModal}
        setIsOpen={setIsOpenErrorModal}
        errorMessage={errorMsg}
      />
      <TimedProjectCreateModal
        type="cancel"
        isOpen={openCancelModal}
        setIsOpen={setIsOpenCancelModal}
      />
    </Box>
  );
};

export default AdminCreateTimedProjects;
