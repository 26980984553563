import { Divider, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box } from '@mui/system';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';
import { ReactComponent as CheckedIcon } from '../../../../assets/icons/project-icons/CheckedIcon.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/icons/project-icons/PaymentIcon.svg';
import CustomModal from '../../../../components/modal/CustomModal';
import { ReactComponent as ErrorAlertIcon } from '../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import CustomButton from '../../../../components/button/CustomButton';
import useUploadFile from '../../../../utils/hooks/crud-hooks/useUploadFile';
import { primaryDark, secondaryBlue, secondaryPink } from '../../../../color';
import { ReactComponent as CancelCircleIcon } from '../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { formatBytes, getBase64, iconUrlForFile, truncate } from '../../../../utils/helpers';
import ControlledDropzoneFileInput from '../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';

type InvoiceModalProps = {
  data: MilestoneDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const InvoiceUploadModal = ({ data, isOpen, setIsOpen }: InvoiceModalProps) => {
  const [fileInfo, setFileInfo] = useState<{
    name?: string;
    size?: number;
    errorMsg?: string | null;
  } | null>({
    name: '',
    size: 0,
    errorMsg: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refresh = useRefresh();

  const { mutate, uploadProgress } = useUploadFile({
    resource: `/milestones/${data?.id}/seller-invoice`,
  });

  const form = useForm<{ invoiceFile: string | null }>({
    defaultValues: {
      invoiceFile: null,
    },
    mode: 'onChange',
  });

  const { getValues, setValue, reset } = form;

  const handleDeleteFile = () => {
    reset({ invoiceFile: null });
    setFileInfo({ name: '', size: 0, errorMsg: null });
  };

  const handleConfirm = () => {
    setIsLoading(true);

    mutate(
      {
        invoice: getValues('invoiceFile'),
      },
      {
        onError: (err) => {
          const errorMessage = (err as Error).message;
          setFileInfo({ ...fileInfo, errorMsg: errorMessage });
        },
        onSuccess: () => {
          setTimeout(() => {
            setIsOpen(false);
            setIsLoading(false);
            refresh();
          }, 1000);
        },
      },
    );
  };

  const handleOnFileSelect = async (acceptedFiles: File[]) => {
    if (!acceptedFiles) return;

    const file = acceptedFiles[0];
    setFileInfo({
      ...fileInfo,
      name: file?.name,
      size: file?.size,
    });

    const base64 = await getBase64(acceptedFiles[0]);

    if (base64) {
      setValue('invoiceFile', String(base64));
    }
  };

  return (
    <CustomModal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <IconButton
            sx={{
              borderRadius: '4px',
              bgcolor: 'secondaryBlue.100',
              width: '3rem',
              height: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                bgcolor: 'secondaryBlue.100',
              },
            }}
          >
            <PaymentIcon fill={secondaryBlue[700]} />
          </IconButton>

          <Stack direction="column" spacing={1}>
            <Typography variant="body3" fontWeight={600}>
              Request payment for
            </Typography>

            <Typography variant="body2">{data?.name}</Typography>
          </Stack>
        </Stack>

        <Divider />

        <Stack sx={{ mt: 2 }}>
          <Box sx={{ backgroundColor: 'primaryDark.200', p: 3, gap: 1 }}>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="end" spacing={0.5}>
                <Typography variant="heading5" color="primaryDark.500">
                  Company name:
                </Typography>

                <Divider
                  orientation="horizontal"
                  sx={{ width: '3.5rem', pb: '3px', borderStyle: 'dashed' }}
                />

                <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                  Top Digital Agency Limited
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="end" spacing={0.5}>
                <Typography variant="heading5" color="primaryDark.500">
                  company id number
                </Typography>

                <Divider
                  orientation="horizontal"
                  sx={{ width: '2rem', pb: '3px', borderStyle: 'dashed' }}
                />

                <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                  IE3613706OH
                </Typography>
              </Stack>

              <Stack
                direction="row"
                alignItems="start"
                sx={{ mt: 1 }}
                width="100%"
                justifyContent="space-between"
                spacing={0.5}
              >
                <Stack direction="row">
                  <Typography
                    variant="heading5"
                    color="primaryDark.500"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    company address
                  </Typography>

                  <Divider sx={{ width: '3rem', pb: '3px', borderStyle: 'dashed' }} />
                </Stack>

                <Typography variant="body3" width="100%" color="primaryDark.600" fontWeight={600}>
                  9 Main Street Co. Dublin Blackrock Dublin, A94 N6D0, Ireland
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Stack spacing={2} mt={2}>
            <Typography variant="body2" color="primaryDark.500">
              Upload invoice
            </Typography>

            <Stack spacing={2}>
              <FormProvider {...form}>
                <form>
                  <ControlledDropzoneFileInput
                    name="invoiceFile"
                    onUpload={handleOnFileSelect}
                    dropzoneProps={{
                      accept: '.pdf',
                    }}
                    maxFileSize={10000000}
                    sx={{ maxHeight: '170px', width: '100%' }}
                  />
                </form>
              </FormProvider>

              {getValues('invoiceFile') !== null && fileInfo !== null && (
                <Stack spacing={1} width="100%">
                  <Stack
                    spacing={1}
                    width="100%"
                    height="fit-content"
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      bgcolor: 'secondaryBlue.50',
                      borderRadius: '0.5rem',
                    }}
                  >
                    {/* file preview, title, remove button */}
                    <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                      {/* file preview & file name, success icon/error icon, file size, remove button */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="left"
                        spacing={1.5}
                      >
                        {/* file preview */}
                        <Box
                          component="img"
                          sx={{
                            maxWidth: '3.5rem',
                            maxHeight: '3.5rem',
                            objectFit: 'contain',
                            borderRadius: '4px',
                            p: 0,
                          }}
                          src={(() => iconUrlForFile(fileInfo?.name as string))()}
                        />

                        {/* file name, success icon/error icon & file size */}
                        <Stack spacing={0.5} alignItems="start">
                          {/* file name, success icon/error icon */}
                          <Stack alignItems="center" direction="row">
                            <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                              {truncate(fileInfo.name as string, 30)}
                            </Typography>

                            {uploadProgress === 100 && fileInfo?.errorMsg === null && (
                              <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                                <CheckedIcon />
                              </IconButton>
                            )}

                            {fileInfo?.errorMsg && (
                              <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                                <ErrorAlertIcon fill={secondaryPink[500]} />
                              </IconButton>
                            )}
                          </Stack>

                          {fileInfo.errorMsg ? (
                            <Typography
                              variant="body3"
                              color="secondaryPink.500"
                              sx={{ alignSelf: 'start' }}
                            >
                              {fileInfo.errorMsg}
                            </Typography>
                          ) : (
                            <Typography variant="body3" color="primaryDark.500">
                              {formatBytes(fileInfo.size as number)}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>

                      {/* remove button */}
                      <IconButton
                        onClick={() => handleDeleteFile()}
                        disabled={isLoading}
                        disableRipple
                        disableTouchRipple
                        sx={{
                          alignSelf: 'start',
                          mb: 1,
                          p: 0,
                          pt: '2px',
                        }}
                      >
                        <CancelCircleIcon fill={isLoading ? primaryDark[300] : primaryDark[500]} />
                      </IconButton>
                    </Stack>

                    {/* progress bar & upload progress */}
                    {/* {!file.error && ( */}
                    {isLoading && (
                      <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                        <Box
                          sx={{
                            height: '4px',
                            width: '100%',
                            borderRadius: '1rem',
                          }}
                        >
                          <LinearProgress
                            value={uploadProgress || 0}
                            variant="determinate"
                            color="secondary"
                          />
                        </Box>

                        <Typography variant="heading5" color="primaryDark.500">
                          {uploadProgress}%
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
          <CustomButton
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
              reset({ invoiceFile: null });
              setFileInfo({ name: '', size: 0, errorMsg: null });
            }}
          >
            Cancel
          </CustomButton>

          <CustomButton onClick={handleConfirm} disabled={isLoading}>
            Confirm
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default InvoiceUploadModal;
