import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/project-icons/ArrowRightIcon.svg';
import CustomButton from '../../../components/button/CustomButton';
import { primaryLight } from '../../../color';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';
import { getPublicImageUrl } from '../../../utils/helpers';

const EvolveYourBusiness = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 6, md: 0 }}
      alignItems="center"
      sx={{
        position: 'relative',
        bgcolor: 'primaryLight.100',
        px: { xs: 4, lg: 5 },
        py: { xs: 9, lg: 5, xl: 5 },
        pb: { xs: 3, sm: 6, lg: 5 },
        borderRadius: '20px',
        width: { xs: 'fit-content', xl: 'auto' },
        overflow: { xs: 'hidden', lg: 'visible' },
        mt: '60px',
        my: 8,
        ...LAYOUT_DEFAULTS,
      }}
    >
      {/* Evolve Your Business text & description text & button */}
      <Stack spacing="36px" sx={{ maxWidth: { xs: '100%', lg: '55%' } }}>
        <Typography
          color="common.black"
          whiteSpace="pre-line"
          sx={{
            typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
          }}
        >
          Why stop at project management? Have a project but no service supplier?
        </Typography>

        <Typography
          color="common.black"
          variant="publicBody3Light"
          sx={{
            maxWidth: { xs: '100%', md: '50%', lg: '90%' },
            zIndex: 10,
          }}
        >
          With over 10,000 service sellers in our marketplace from 130+ countries, and our dedicated
          Spona matchmaking tool, you get started with your projects immediately.
        </Typography>
        <CustomButton
          onClick={() => navigate('/how-to-hire')}
          variant="primaryLight"
          sx={{ width: 'fit-content' }}
        >
          Explore Our Marketplace
        </CustomButton>
      </Stack>

      <Box
        alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
        component="img"
        src={getPublicImageUrl(EnumPublicPagesImage.HomeMapSvg)}
        sx={{
          // display: { xs: 'none', sm: 'block' },
          // position: { sm: 'absolute', xl: 'relative' },
          // right: { xs: 10, bg: 0, lg: -85, xxl: -150 },
          maxWidth: { xs: 'none', sm: '580px', md: 'none' },
          width: { xs: '100%', md: '54%' },
        }}
      />
    </Stack>
  );
};

export default EvolveYourBusiness;
