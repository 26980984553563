import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';

import AccordionGroups from '../../../components/public-screen-components/accordion-groups/AccordionGroups';
import { projectBenefitsArray } from '../utils';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

const ProjectBenefits = () => (
  <Stack
    direction="row"
    spacing={{ md: 8 }}
    my={{ xs: 10, md: 10 }}
    sx={{ ...LAYOUT_DEFAULTS }}
    justifyContent="space-between"
    alignItems="center"
  >
    <Box
      src={getPublicImageUrl(EnumPublicPagesImage.HomeFeatures)}
      alt="Creating a project on the Spona platform"
      component="img"
      sx={{ width: '37%', display: { xs: 'none', md: 'block' } }}
    />

    <Stack
      justifyContent="start"
      alignItems="start"
      spacing={{ xs: 3, lg: 6 }}
      sx={{ width: { xs: '100%', lg: '50%' } }}
    >
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        Features
      </Typography>

      <Box
        alt="Creating a project on the Spona platform"
        src={getPublicImageUrl(EnumPublicPagesImage.HomeFeatures)}
        component="img"
        sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}
      />

      <AccordionGroups
        group={projectBenefitsArray}
        image={getPublicImageUrl(EnumPublicPagesImage.HomeFeatures)}
      />
    </Stack>
  </Stack>
);

export default ProjectBenefits;
