const tabs: { id: number; text: string }[] = [
  {
    id: 1,
    text: 'Terms of Service',
  },
  {
    id: 2,
    text: 'Privacy Policy',
  },
  {
    id: 3,
    text: 'Spona Digital Contract Terms',
  },
  {
    id: 4,
    text: 'Cookie Policy',
  },
];

export default tabs;
