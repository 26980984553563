import { primaryDark, primaryLight, secondaryPink } from '../../color';

const styles = {
  '.MuiInputBase-root': {
    overflow: 'hidden',
    p: '0 !important',
    fontSize: '14px',
    border: '1px solid',
    borderColor: 'primaryDark.250',
    color: primaryDark[800],
    height: 47,

    '&.Mui-focused': {
      borderColor: 'primaryLight.500',
    },
    '&.Mui-error': {
      borderColor: 'secondaryPink.700',
      backgroundColor: 'secondaryPink.100',
    },
    '&:not(.Mui-disabled)': {
      // don't do it if class applied
      '&:not(.Mui-focused,.Mui-error)': {
        '&:hover': {
          borderColor: 'primaryLight.200',
        },
      },
    },
    '.MuiInputAdornment-root': {
      mr: '10px',
      '& .MuiIconButton-root': {
        padding: '4px',
        ml: '2px',
      },
    },
  },
  fieldset: {
    border: '0 !important',
  },
  input: {
    padding: '0.844rem 0.75rem !important',
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 50px ${primaryLight[50]} inset !important`,
  },
  '& ::placeholder': {
    fontSize: '14px !important',
    letterSpacing: '0.03rem',
    lineHeight: '1.3125rem',
    fontWeight: 400,
    opacity: '1 !important',
    color: `${primaryDark[400]} !important`,
  },

  // select & autocomplete
  // dropdown
  '& .MuiPaper-root': {
    maxHeight: 225,
    boxShadow:
      '0px 5px 5px 0px rgba(121, 130, 155, 0.10), 0px 3px 14px 0px rgba(121, 130, 155, 0.12), 0px 8px 10px 0px rgba(121, 130, 155, 0.08)',
    '.MuiMenuItem-root,.MuiAutocomplete-option,.MuiAutocomplete-noOptions': {
      height: 37,
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
      lineHeight: 0,
      color: 'primaryDark.500',
      fontFamily: 'Gellix-Regular',
      padding: '8px 16px',
      '&:hover': {
        bgcolor: 'primaryLight.50',
      },

      '&:not(.Mui-selected)': {
        '& .MenuItemCheckedIcon': {
          display: 'none',
        },
      },

      '&.Mui-selected': {
        backgroundColor: 'primaryLight.50',
        color: 'primaryDark.600',
        '&:hover': {
          bgcolor: 'primaryLight.50',
        },
        '& .MenuItemCheckedIcon': {
          // show check icon
          display: 'block',
        },
        '& .MenuItemUncheckedIcon': {
          display: 'none',
        },
      },
    },

    // autocomplete
    '& .MuiAutocomplete-listbox': {
      maxHeight: 225,
      "& .MuiAutocomplete-option[aria-selected='true']": {
        '& .MenuItemCheckedIcon': {
          // show check icon
          display: 'block',
        },
        '& .MenuItemUncheckedIcon': {
          display: 'none',
        },
      },
      "& .MuiAutocomplete-option[aria-selected='false']": {
        '& .MenuItemCheckedIcon': {
          display: 'true',
        },
      },
    },

    '& .MuiAutocomplete-noOptions': {
      padding: '24px 16px',
    },
  },

  '.UserAgreementError': {
    borderColor: 'secondaryPink.700',
    bgcolor: 'secondaryPink.100',
    a: {
      color: `${secondaryPink[700]} !important`,
    },
  },
};

export default styles;
