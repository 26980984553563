import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import theme from '../../../../theme';
import { HeroProps } from '../interface';

const HeroSection = ({ imgUrl, title, description, link, linkText }: HeroProps) => {
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        backgroundImage: isLessThanMedium ? 'none' : `url(${imgUrl})`,
        backgroundSize: 'cover',
        borderRadius: '12px',
        width: { gr: '100%' },
        maxWidth: '80rem',
        my: { xs: '5rem' },
        mb: { gr: '7.5rem' },
        mx: { xs: '2.1875rem', sm: '2rem', bg: '5rem', gr: 'auto' },
        p: {
          xs: 0,
          sm: 0,
          md: '5.3125rem 0 3.0625rem 2.9375rem',
          bg: '10.0625rem 0 2.5rem  4.0625rem',
        },
      }}
    >
      <Stack
        justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
        alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
        height="100%"
        color={{ xs: 'primaryDark.900', sm: 'primaryDark.900', md: 'white', lg: 'white' }}
        gap={3}
        textAlign={{ xs: 'center' }}
      >
        <Typography variant="publicHeading2">{title}</Typography>
        <Typography variant="publicBody3Regular">{description}</Typography>
        <CustomButton endIcon={<ArrowRightIcon fill="#fff" />} href={link}>
          {linkText}
        </CustomButton>
      </Stack>
    </Box>
  );
};
export default HeroSection;
