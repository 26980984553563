import { Node } from '@tiptap/core';
import getYotubeVideoId from './helpers/transform-uri';

export interface IframeOptions {
  allowFullScreen: boolean,
  HTMLAttributes: {
    [key: string]: any
  },
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      setIframe: (options: { src: string }) => ReturnType,
    }
  }
}

const Iframe = Node.create<IframeOptions>({
  name: 'iframe',
  group: 'block',
  atom: true,
  addOptions() {
    return {
      allowFullScreen: true,
      HTMLAttributes: {
        class: 'iframe-wrapper',
      },
    };
  },

  // Responsiveness best practices for YT embeds picked up from:
  // https://dev.to/deammer/embed-responsive-youtube-videos-in-2021-5dkh
  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      allowFullScreen: {
        default: this.options.allowFullScreen,
        parseHTML: () => this.options.allowFullScreen,
      },
      title: {
        default: 'Youtube video player',
      },
      allow: {
        default: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      },
    };
  },

  parseHTML() {
    return [{
      tag: 'iframe',
    }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]];
  },

  addCommands() {
    return {
      setIframe: (options: { src: string }) => ({ tr, dispatch }) => {
        const videoOptions = options;
        const videoId = getYotubeVideoId(videoOptions.src);
        if (videoId) {
          videoOptions.src = `https://www.youtube.com/embed/${videoId}`;
        }

        const { selection } = tr;
        const node = this.type.create(videoOptions);

        if (dispatch) {
          tr.replaceRangeWith(selection.from, selection.to, node);
        }

        return true;
      },
    };
  },
});

export default Iframe;
