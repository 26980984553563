import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const IndividualPaymentScreenJoyride = (userBuyer: boolean): Step[] => [
  {
    content: userBuyer ? (
      <Typography>
        Your individual payment screen gives you information essential to the completion of your project.
        <br />
        From payment overviews to downloadable invoices, here you will be able to manage all your payments to your agency.

      </Typography>
    ) : (
      <Typography>
        Your individual payment screen gives you information essential to the completion of your project.

        From payment overviews to downloadable invoices, here you will be able to manage all your earnings.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-1-individual-payment',
    title: 'Individual payment screen',
  },
  {
    content: userBuyer ? (
      <Typography>
        The payment overview screen shows your project status, cost, amount currently paid and remaining to be paid.

      </Typography>
    ) : (
      <Typography>

        The payment overview screen shows your project status, budget, current and expected earnings.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-2-individual-payment',
    title: 'Payment overview',
  },
  {
    content: (
      <Typography>
        Here you can view all your project payments across milestones,
        including statuses and payment amounts.
      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-3-individual-payment',
    title: 'Payment overview',
  },
  {
    content: userBuyer ? (
      <Typography>
        Once a milestone has been completed, you will be able to
        access and download your invoice here to complete the payment process.
      </Typography>
    ) : (
      <Typography>

        Once a milestone has been approved by your client,
        you will be able to upload your invoice here to start the payment process.

      </Typography>
    ),
    disableBeacon: true,
    hideCloseButton: false,
    disableScrolling: true,
    hideFooter: false,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 10,
      },
    },
    target: '.react-joyride-step-4-individual-payment',
    title: 'Invoice',
  },
];

export default IndividualPaymentScreenJoyride;
