import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { HTMLContent } from '@tiptap/react';
import { useParams } from 'react-router-dom';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import AvatarUpload from '../../../../components/upload/avatar/AvatarUpload';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';
import Editor from '../../../../components/editor/Editor';
import { MinifiedEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import { countriesSorted } from '../../../../utils/helpers';

const Header = ({ text }: { text: string }) => (
  <Typography variant="h4" fontSize="1.5rem">
    {text}
  </Typography>
);
const Subheader = ({ text }: { text: string }) => (
  <Typography variant="h5" fontSize="1rem">
    {text}
  </Typography>
);

const GeneralInfoForm = () => {
  const { setValue, control } = useFormContext();
  const { companyId } = useParams();

  return (
    <Accordion
      sx={{
        py: 1,
        boxShadow: 'none',
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon justity-content="flex-end" fontSize="large" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ px: 4 }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Header text="General information" />
          </Stack>
          <Subheader text="General information about company" />
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          p: 4,
          borderTop: '1px solid',
          borderTopColor: 'grey.500',
        }}
      >
        <Stack direction="row" spacing={6} sx={{ p: 8 }}>
          <Stack
            spacing={2}
            width="30%"
            direction="column"
            sx={{
              display: 'flex',
            }}
            alignItems="center"
          >
            <AvatarUpload
              avatar={useWatch({
                control,
                name: 'avatar',
              })}
              onAvatarChange={(base64) => {
                setValue('avatar', base64, { shouldValidate: true, shouldDirty: true });
              }}
            />
            <Typography color="#757875" fontWeight={600}>
              Upload logo
            </Typography>
          </Stack>
          <Stack width="70%" spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              <ControlledTextInput
                name="name"
                label="Company name"
                InputLabelProps={{ shrink: true }}
              />
              <ControlledTextInput
                name="url"
                label="Company name"
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Stack spacing={2} direction="column" justifyContent="space-between">
              <Stack width="100%">
                <ControlledSelectInput
                  name="headquartersLocation.country"
                  label="Country"
                  autoWidth
                  choices={countriesSorted()}
                />
              </Stack>
              <Stack spacing={2} direction="row" justifyContent="space-between">
                <Stack width="50%">
                  <ControlledTextInput
                    name="headquartersLocation.city"
                    label="City"
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                <Stack width="50%">
                  <ControlledTextInput
                    name="headquartersLocation.state"
                    label="State"
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          {/* editor */}
          <Stack spacing={2}>
            <Typography color="#757875" fontWeight="500">
              Description
            </Typography>
          </Stack>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Editor
                key={companyId}
                items={MinifiedEditorMenu}
                content={field.value}
                isEditable
                onUpdateContent={(data: HTMLContent) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default GeneralInfoForm;
