import defaultIcon from '../../assets/icons/services/icon-service-default.svg';
import Amazon_Web_Services_Icon from '../../assets/icons/tech-platforms/icon-technology-expertise-aws.svg';
import AzureIcon from '../../assets/icons/tech-platforms/icon-technology-expertise-ms-azure.svg';
import Google_Cloud_Icon from '../../assets/icons/tech-platforms/icon-technology-expertise-google-cloud-platform.svg';
import LinuxIcon from '../../assets/icons/tech-platforms/icon-technology-expertise-linux.svg';

const mapIconTechPlatform = new Map([
  ['Default', defaultIcon],
  ['Amazon_Web_Services', Amazon_Web_Services_Icon],
  ['Azure', AzureIcon],
  ['Google_Cloud', Google_Cloud_Icon],
  ['Linux', LinuxIcon],
]);

export default mapIconTechPlatform;
