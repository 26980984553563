import { Card, CardActionArea, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { EnumProjectStatus } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChatIcon } from '../../../../assets/icons/chat-icons/chat3-fill.svg';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import ProgressBar from '../../../../components/progress-bar/ProgressBar';
import { ProjectCardProps } from '../interface';
import { stripTags, truncate } from '../../../../utils/helpers';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';

const E2EProjectCard = ({ project }: ProjectCardProps) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`./end-to-end/project/${project.id}`)}
      sx={{
        overflow: 'hidden',
        backgroundColor: 'common.white',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: 2,
        flex: 1,
      }}
      elevation={0}
    >
      <CardActionArea>
        <Stack height="100%">
          <Stack
            p={2}
            height="80%"
            gap={1}
            sx={{
              background: 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)',
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <StatusChipField text={project.status} />
              <CustomReferenceField
                resource="spaces"
                displayName={project.name as string}
                id={project.space as string}
                renderComponent={(chat) => (
                  <IconButton
                    href={`/dashboard/chat/${chat?.id}`}
                    sx={{
                      borderRadius: '3px',
                      bgcolor: 'secondaryBlue.100',
                    }}
                  >
                    <ChatIcon fill="#4051EC" height="16px" />
                  </IconButton>
                )}
              />
            </Stack>
            <Typography pt={1} variant="heading4" color="primaryDark.600">
              {project.name}
            </Typography>
            {project.status !== EnumProjectStatus.Pending && (
              <Typography variant="body4" color="primaryDark.500">
                {truncate(stripTags(project.description) || '', 100)}
              </Typography>
            )}
          </Stack>
          <Stack p={2} gap={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="heading5" color="primaryDark.400">
                Progress
              </Typography>
              <ProgressBar value={project.progress ? Math.round(project.progress) : 0} />
            </Stack>
            <CustomReferenceField
              displayName="name"
              id={project.seller}
              resource="companies"
              renderComponent={(item: CompanyDto) => (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Box
                    component="img"
                    src={item.avatar}
                    sx={{ borderRadius: '4px' }}
                    height="22px"
                  />
                  <Typography variant="heading4" color="primaryDark.600">
                    {item.name}
                  </Typography>
                </Stack>
              )}
            />
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default E2EProjectCard;
