import { createContext, useState, ReactNode } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as CheckIcon } from '../../assets/icons/calendar-icons/CheckIconGreen.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/diamond-icon.svg';

interface INotification {
  open: boolean;
  type: AlertColor | undefined;
  content: string;
}

interface INotificationContext {
  notify: {
    success: (successMsg: string) => void;
    error: (errorMsg: string) => void;
    warning: (warningMsg: string) => void;
  };
}

const Notification = createContext<INotificationContext>({} as INotificationContext);

export default Notification;

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<INotification>({
    open: false,
    type: undefined,
    content: '',
  });

  const onClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const getNotification = () => ({
    success: (successMsg: string) =>
      setNotification({
        open: true,
        type: 'success',
        content: successMsg,
      }),
    error: (errorMsg: string) => {
      setNotification({
        open: true,
        type: 'error',
        content: errorMsg,
      });
    },
    warning: (warningMsg: string) => {
      setNotification({
        open: true,
        type: 'warning',
        content: warningMsg,
      });
    },
  });

  const notify = getNotification();

  const sxAlert = {
    display: 'flex',
    alignItems: 'center',
    background: notification.type === 'error' ? '#FFF2F4' : '#F5FED4',
    '.MuiAlert-message': {
      fontSize: '14px',
      fontFamily: 'Gellix-Bold',
      color: '#384572',
      lineHeight: '16px',
      letterSpacing: '0.6px',
    },
  };

  const sxIcon = {
    width: '20px',
    height: '20px',
  };

  return (
    <Notification.Provider
      value={{
        notify,
      }}
    >
      {children}
      <Snackbar
        open={notification.open}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={3000}
        onClose={onClose}
      >
        <div>
          <Alert
            sx={sxAlert}
            severity={notification.type}
            iconMapping={{
              success: <CheckIcon style={sxIcon} />,
              error: <ErrorIcon style={sxIcon} />,
            }}
            action={
              <CancelIcon
                style={{ ...sxIcon, cursor: 'pointer', fill: '#384572' }}
                onClick={onClose}
              />
            }
          >
            {notification.content}
          </Alert>
        </div>
      </Snackbar>
    </Notification.Provider>
  );
};
