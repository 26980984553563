import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, Box, RadioProps, SxProps, Theme } from '@mui/material';
import CustomRadio from '../custom-radio/CustomRadio';
import BaseInputLabel from './BaseInputLabel';
import { parseBooleanString } from '../../utils/helpers';

interface IProps {
  name: string;
  label?: string;
  options?: Map<string | boolean, string>;
  required?: boolean;
  radio?: {
    sx?: SxProps<Theme>;
    config?: RadioProps;
    label: string;
  };
}

function ControlledRadioInput({ name, label, radio, required, options }: IProps) {
  const { control } = useFormContext();

  return (
    <>
      {/* Input label */}
      {label && <BaseInputLabel text={label as string} required={required} />}
      {/* Render RadioGroup if the 'options' param passed. If not - render single RadioButton */}
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          options ? (
            <RadioGroup
              {...field}
              onChange={(e) => field.onChange(parseBooleanString(e.target.value))}
              name="row-radio-buttons-group"
            >
              <Box sx={{ display: 'flex' }}>
                {Array.from(options, ([key, value]) => (
                  <CustomRadio labelSx={{ marginRight: '32px' }} value={key} label={value} />
                ))}
              </Box>
            </RadioGroup>
          ) : (
            <CustomRadio
              {...field}
              labelSx={radio?.sx}
              value={field.value}
              label={radio?.label}
              radioProps={{
                ...radio?.config,
                onChange: (e) => field.onChange(parseBooleanString(e.target.value)),
              }}
            />
          )
        }
      />
    </>
  );
}

export default ControlledRadioInput;
