import { Box } from '@mui/system';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import Editor from '../../../../../../../components/editor/Editor';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import EditCompanyAbout from '../edit/CompanyAboutEditModal';

interface CompanyAboutModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyAbout = ({ isEditModalOpen, setIsEditModalOpen }: CompanyAboutModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [companyData]);

  return (
    <Box
      sx={{
        maxHeight: { sm: '400px', xl: '230px' },
        overflowY: 'auto',
        wordBreak: 'break-word',
        '&::-webkit-scrollbar': {
          width: '0.1em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(227, 231, 240)',
          borderRadius: '4px',
          outline: '1px solid slategrey',
          outlineColor: 'rgba(227, 231, 240)',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        },
      }}
    >
      {companyData?.description && (
        <Editor key={key} items={[]} isEditable={false} content={companyData?.description} />
      )}
      {companyData && isEditModalOpen && (
        <EditCompanyAbout isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} />
      )}
    </Box>
  );
};

export default CompanyAbout;
