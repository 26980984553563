import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { TextField } from '@material-ui/core';
import { InputAdornment, Stack } from '@mui/material';
import { formatAmount } from '../../utils/helpers';
import { secondaryBlue } from '../../color';

type TProps = {
  name: string;
  required?: boolean;
  initialValue: number;
  adornmentText?: string;
} & TextFieldProps;

const inputAdornmentStyles = {
  paddingRight: '1px',
  '.MuiTypography-root': {
    fontSize: '14px',
    fontFamily: 'Gellix-SemiBold',
    color: 'sprimaryDark.600',
  },
};

const styles = {
  '.MuiButtonBase-root': {
    display: 'none',
  },
  '.MuiInputBase-formControl': {
    border: `1px solid ${secondaryBlue[100]}`,
    color: 'primaryDark.600',
    backgroundColor: 'transparent',
    paddingRight: 0,
    fontSize: '14px',
    '&:not(.Mui-disabled)': {
      '&:hover': {
        borderColor: 'primaryLight.200',
      },
    },
  },
  input: {
    fontSize: '14px',
    padding: '0.844rem 0',
  },
  '.Mui-focused': {
    borderColor: 'primaryLight.500',
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0,
    boxShadow: 'none !important',
    color: 'secondaryPink.500',
    marginTop: '0 !important',
    lineHeight: 1,
  },
  fieldset: {
    border: '0 !important',
  },
  '.Mui-disabled': {
    backgroundColor: 'primaryDark.200',
    color: 'primaryDark.300',
    border: 'none',
  },
};

const ControlledContractMilestoneNumberInput = ({
  name,
  label,
  required,
  initialValue,
  sx,
  adornmentText,
}: TProps) => {
  const roundedInitialValue =
    Math.round((parseFloat(initialValue.toString()) + Number.EPSILON) * 100) / 100;
  const { setValue } = useFormContext();
  const [textValue, setTextValue] = useState(formatAmount(roundedInitialValue.toString()));
  let textFiledValue;
  if (
    roundedInitialValue.toFixed(2) === parseFloat(textValue?.replaceAll(',', '') || '0').toFixed(2)
  ) {
    textFiledValue = textValue;
  } else {
    textFiledValue = formatAmount(roundedInitialValue.toString());
    setTextValue(textFiledValue);
  }

  return (
    <Stack
      sx={{
        position: 'relative',
        ...styles,
      }}
    >
      <TextField
        variant="outlined"
        value={textFiledValue}
        onChange={(e) => {
          const newAmount = formatAmount(e.target.value, true);

          if (newAmount !== null) {
            setTextValue(newAmount === '' ? '0' : newAmount);
            setValue(name, newAmount === '' ? 0 : parseFloat(e.target.value.replaceAll(',', '')));
          }
        }}
        InputProps={{
          ...(adornmentText && {
            startAdornment: (
              <InputAdornment sx={{ ...inputAdornmentStyles }} position="start">
                {adornmentText}
              </InputAdornment>
            ),
          }),
        }}
      />
    </Stack>
  );
};

export default ControlledContractMilestoneNumberInput;
