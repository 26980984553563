import HireMainLayot from './components/PricingMainLayout/PricingMainLayout';
import { BuyerPricingType } from './interface';
import BuyerPricingContent from './components/BuyerPricingContent/BuyerPricingContent';

const BuyerPricing = ({ type }: { type: BuyerPricingType }) => (
  <HireMainLayot>
    <BuyerPricingContent type={type} />
  </HireMainLayot>
);

export default BuyerPricing;
