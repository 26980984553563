import { IconButton } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/icons/chat-icons/plus.svg';
import CreateChannelModal from './CreateChannelModal';

type CreateChannelModalButtonProps = {
  spaceId: string;
};

export default ({ spaceId }: CreateChannelModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton sx={{ p: 0 }} disableRipple onClick={() => setIsOpen(true)}>
        <PlusIcon />
      </IconButton>
      <CreateChannelModal spaceId={spaceId} open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
