import { Typography } from '@mui/material';
import { TermOfServiceI } from '../utils';

const sponaDigitalContractTerms: TermOfServiceI[] = [
  {
    introduction:
      "Welcome to Spona Digital Contracts – the simple contract drafting tool with pre-built contract fields that enables smooth collaboration between vetted Digital Service Sellers and Buyers. It's essential to note that these terms supplement the Spona Terms of Service, collectively defining the terms and conditions under which Spona offers you its services, and you agree to use the Spona Digital Contracts service, as described in these terms.",
    content: [
      {
        title: 'Creating the Digital Contract',
        paragraphs: [
          {
            text: () =>
              'Digital Contracts enable parties to draft and edit contract proposals by filling in the pre-built contract fields, leaving the parties the complete freedom to decide on the milestones, time frame and budget allocation. Both Sellers and Buyers can initiate a contract proposal. Once a Digital Contract is created, the parties should review the fully completed contract and check the terms. If both parties agree on all the terms and submit the contract they will receive an email from DocuSign to digitally sign it.',
          },
          {
            text: () =>
              'You understand and accept that the Digital Contract will become legally binding once signed.',
          },
        ],
      },
      {
        title: 'Start of the Project',
        paragraphs: [
          {
            text: () =>
              'Once a Digital Contract is created, signed by both parties and verified by the Spona administrator, the project is ready to start.',
          },
        ],
      },
      {
        title: 'Duration of the Project',
        paragraphs: [
          {
            text: () =>
              'As tasks are completed and milestones met, the Seller can request the release of the corresponding funds. Approval by the Buyer initiates the creation of the invoice and funds are to be collected from the Buyer to the Spona account. Once Spona receives the funds in its account it will immediately, or no later than within three working days, distribute the funds to the Seller account. In case of delayed bank transfers Spona is not eligible for late payments.',
          },
        ],
      },
      {
        title: 'Who Can Use Digital Contracts?',
        paragraphs: [
          {
            text: () => (
              <Typography variant="publicBody5Regular" color="common.black">
                You understand that Spona is a B2B project management tool and agree to use its
                services on behalf of an entity (e.g. company, limited partnership, agency,
                organization… ).
                <Typography variant="publicBody5" color="common.black">
                  You represent and warrant that you have the authority to bind that entity.
                </Typography>
              </Typography>
            ),
          },
          {
            text: () =>
              'You acknowledge that neither Spona (registered company name Top Digital Agency Limited)  nor any affiliated entities assume the role of a party in any Service Agreement between users, even when these Digital Contracting Services are used.',
          },
        ],
      },
    ],
  },
];

export default sponaDigitalContractTerms;
