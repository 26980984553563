import { Stack, Box, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { CompanyApprovalStatus, CompanyRole } from 'tdc-web-backend/enums/enums';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HomeLinkIcon } from '../../assets/icons/layout-icons/HomeLinkIcon.svg';
import NavigationDashboard, {
  APPBAR_HEIGHT,
} from '../../layout/dashboard-navigation/NavigationDashboard';
import theme from '../../theme';
import {
  dashboardMenuItemsBuyer,
  dashboardMenuItemsSeller,
} from '../../layout/dashboard-navigation/MenuItem';
import NavigationDashboardContext from '../../layout/dashboard-navigation/NavigationDashboardContext';
import useDetermineCompanyRoles from '../../utils/hooks/useDetermineCompanyRoles';
import Sidebar from '../EndToEnd/Sidebar/Calendar/Sidebar/Sidebar';
import LinearProgressStepperWrapper from '../EndToEnd/LinearProgressStepper/LinearProgressStepperWrapper';
import SmallChat from '../Chat/FloatingChat/FloatingChat';
import useGetLocalStorageUserData from '../../utils/hooks/useGetLocalStorageUserData';
import useReferenceOne from '../../utils/hooks/crud-hooks/useReferenceOne';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';

export interface MainProps {
  outlet: React.ReactElement | null;
  showSidebar?: boolean;
  showChat?: boolean;
  padding?: boolean;
}

export const DRAWER_WIDTH_OPEN = 200;
export const DRAWER_WIDTH_CLOSED = 56;
export const SIDEBAR_CONTAINER_PADDING_TOP = 24;
export const DASHBOARD_WRAPPER_PADDING = 24;
export const DASHBOARD_ELEMENTS_SPACING = { sm: 3, lg: 3 };

const Main = ({ outlet, showSidebar = true, showChat = true, padding = true }: MainProps) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);
  const userData = useGetLocalStorageUserData();
  const membership = userData?.membership;
  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData,
  });

  useEffect(() => {
    // if a user doesn't have a company tied to him, redirect him to create-company
    if (membership === null) navigate('/dashboard/create-company');
  }, [membership, navigate]);

  const disablelayout =
    companyData?.status === CompanyApprovalStatus.Pending ||
    companyData?.status === CompanyApprovalStatus.Rejected;

  return (
    // context here is used to set the value of isSidebarOpen
    // and use it in SingleSlideCarousel to calculate correctly
    // the width of the carousel;
    // scenario (problem) which it solves: the SingleSlideCarousel width
    // needs to be set correctly, but it needs to have different width
    // when navigation is both closed & opened;
    // in addition to that, the logic for handling opening and closing the sidebar
    // (isSidebarOpen) has been moved here and passed to the NavigationDashboard
    <NavigationDashboardContext.Provider
      value={isSidebarOpen ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED}
    >
      <Box sx={{ flexGrow: 1, ml: { md: 0, bg: theme.spacing(7), lg: 0 } }}>
        <Stack direction="row">
          <NavigationDashboard
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            showSidebar={showSidebar}
            items={(() => {
              if (membership === null) {
                return [
                  {
                    title: 'Home',
                    to: '/dashboard',
                    Icon: HomeLinkIcon,
                    name: 'home',
                    disabled: true,
                  },
                ];
              }

              if (isUserBuyer) return dashboardMenuItemsBuyer;

              return dashboardMenuItemsSeller(disablelayout);
            })()}
            sx={{ zIndex: 5 }}
          />

          {!isLessThanMedium && (
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                width: showSidebar
                  ? {
                      md: '70%',
                      bg: '68%',
                      lg: '72%',
                      xl: '77%',
                    }
                  : '100%',
                p: padding ? `${DASHBOARD_WRAPPER_PADDING}px` : '0px',
                mt: `${APPBAR_HEIGHT}px`,
                overflowX: 'hidden !important',
                position: 'relative',
              }}
            >
              {outlet}
            </Box>
          )}

          {showSidebar && (
            <Stack
              sx={{
                width: {
                  md: '30%',
                  bg: '32%',
                  lg: '28%',
                  xl: '23%',
                },
                backgroundColor: 'primaryDark.200',
                pt: `${SIDEBAR_CONTAINER_PADDING_TOP}px`,
                px: { md: 2, xl: 3 },
              }}
            >
              {/* if on project detail screen, render linear milestones stepper */}
              {location.pathname.includes('project') && params.projectId ? (
                <LinearProgressStepperWrapper />
              ) : (
                <Sidebar
                  sidebarData={{
                    sidebarMessages: undefined,
                    // TODO: pass upcomingDeadlines here
                  }}
                  sx={{ position: 'sticky', top: 114, overflow: 'unset' }}
                />
              )}
            </Stack>
          )}
        </Stack>
        {showChat && <SmallChat />}
      </Box>
    </NavigationDashboardContext.Provider>
  );
};

export default Main;
