import { FormControl, Select, MenuItem } from '@mui/material';
import { Editor } from '@tiptap/react';
import InputStyle from './InputStyle';

declare type Level = 1 | 2 | 3 | 4 | 5 | 6;

const headings = [
  { label: 'Paragraph', value: 0 },
  { label: 'Heading', value: 1 },
  { label: 'Subheading', value: 2 },
];

interface HeadingSelectProps {
  editor: Editor;
}

const HeadingSelect = ({ editor }: HeadingSelectProps) => (
  <FormControl>
    <Select
      sx={{ '.MuiSelect-select': { border: 0, padding: '0 32px 0 12px', color: '#384572' } }}
      onChange={({ target }) => {
        if (target.value === 0) {
          editor.chain().focus().setParagraph().run();
        }

        const levelVal = target.value as Level;

        editor.chain().focus().toggleHeading({ level: levelVal }).run();
      }}
      label="Components"
      value={(() =>
        headings.find((heading) => editor.isActive('heading', { level: heading.value }))?.value ||
        (editor.isActive('paragraph') ? 0 : undefined) ||
        headings[0].value)()}
      input={<InputStyle />}
    >
      {headings.map((heading) => (
        <MenuItem key={heading.label} value={heading.value}>
          {heading.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default HeadingSelect;
