import { Box } from '@mui/system';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import { PaymentsList } from './PaymentsList';

const AdminPayments = () => {
  const { data: paymentsData } = useGetMany<MilestoneDto>({ resource: '/milestones/?limit=1000' });

  return (
    <Box sx={{ width: '100%' }}>
      <PaymentsList payments={paymentsData?.data.results || []} />
    </Box>
  );
};

export default AdminPayments;
