import { Stack, SxProps, Theme, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import { ProjectHealthDto } from 'tdc-web-backend/projects/schemas';
import Health from '../../../../assets/icons/health.svg';
import PointerChip, { DirectionEnum } from '../../../../components/PointerChip/PointerChip';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';

export interface ProjectHealthCardProps {
  health?: ProjectHealthDto;
  sx?: SxProps<Theme> | undefined;
}

export interface ProjectHealthPropertyProps {
  name: string;
  value: string;
  text: string;
  direction: DirectionEnum;
  className?: string;
}

const ProjectHealthProperty = ({
  direction,
  name,
  value,
  text,
  className,
}: ProjectHealthPropertyProps) => (
  <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={className || undefined}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <PointerChip
          color={direction === DirectionEnum.Up ? '#0FA97F' : 'red'}
          direction={direction}
        />
        <Typography variant="body2" fontWeight={500} color="primaryDark.600">
          {name}
        </Typography>
      </Stack>

      <Stack spacing={0.5} direction="row">
        <Typography variant="body2" color="primaryDark.600">
          {text}
        </Typography>

        <Typography variant="body2" fontWeight={500} color="primaryDark.600">
          {value}
        </Typography>
      </Stack>
    </Stack>
  </>
);

export function ProjectHealthCard({ health, sx }: ProjectHealthCardProps) {
  const sheduleCalc = Math.abs((health?.schedule as number) * 100).toFixed(1);
  const costCalc = Math.abs((health?.cost as number) * 100).toFixed(1);
  const scopeCalc = Math.abs((health?.cost as number) * 100).toFixed(1);
  const qualityCalc = Math.abs(health?.quality as number).toString();

  return (
    <Stack spacing={2} sx={{ position: 'relative' }}>
      {health == null && <CustomNoRowsOverlay />}
      <ProjectHealthProperty
        className="react-joyride-step-9-project"
        name="Schedule"
        value={`${sheduleCalc}%`}
        text={(health?.schedule as number) >= 1 ? 'Ahead of schedule' : 'Behind'}
        direction={(health?.schedule as number) >= 1 ? DirectionEnum.Up : DirectionEnum.Down}
      />
      <ProjectHealthProperty
        className="react-joyride-step-10-project"
        name="Cost"
        value={`${costCalc}%`}
        text={(health?.cost as number) < 0 ? 'Under budget' : 'Over budget'}
        direction={(health?.cost as number) <= 0 ? DirectionEnum.Up : DirectionEnum.Down}
      />
      <ProjectHealthProperty
        className="react-joyride-step-11-project"
        name="Scope"
        value={scopeCalc}
        text="Changes"
        direction={(health?.scope as number) <= 0 ? DirectionEnum.Up : DirectionEnum.Down}
      />
      <ProjectHealthProperty
        className="react-joyride-step-12-project"
        name="Quality"
        value={qualityCalc}
        text="Project issues"
        direction={(health?.quality as number) <= 0 ? DirectionEnum.Up : DirectionEnum.Down}
      />
    </Stack>
  );
}
