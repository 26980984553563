import React from 'react';
import FlagSpecial from './FlagSpecial';
import MeetingSpecial from './MeetingSpecial';

type MessageSpecialProps = {
  special?: any;
};

/* Renders content of chat messages with special layout such as flag notifications. */
export default ({ special }: MessageSpecialProps) => {
  if (special?.milestoneFlag) {
    return (
      <FlagSpecial
        entity="milestone"
        flaggedBy={special.milestoneFlag.flaggedBy}
        created={special.milestoneFlag.created}
        link={`/dashboard/end-to-end/milestone/${special.milestoneFlag.milestone}`}
      />
    );
  }
  if (special?.taskFlag) {
    return (
      <FlagSpecial
        entity="flag"
        flaggedBy={special.taskFlag.flaggedBy}
        created={special.taskFlag.created}
        link={`/dashboard/end-to-end/milestone/${special.taskFlag.milestone}`}
      />
    );
  }
  if (special?.subtaskFlag) {
    return (
      <FlagSpecial
        entity="subtask"
        flaggedBy={special.subtaskFlag.flaggedBy}
        created={special.subtaskFlag.created}
        link={`/dashboard/end-to-end/milestone/${special.subtaskFlag.milestone}`}
      />
    );
  }
  if (special?.meetingCreated) {
    return (
      <MeetingSpecial
        title="a meeting has been created"
        eventId={special.meetingCreated.event}
        buttons
      />
    );
  }
  if (special?.meetingStarted) {
    return (
      <MeetingSpecial
        title="a meeting has been started"
        eventId={special.meetingStarted.event}
        buttons={false}
      />
    );
  }
  return null;
};
