import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectProps } from '@mui/material/Select/Select';
import { ReactNode } from 'react';
import Button from '../button/Button';

type Props = {
  name: string;
  displayEntityNames?: string;
  required?: boolean;
  choices: any;
  wrapperHeight?: string | number;
  // used for displaying values outside the Select component, e.g. Chips or similar
  displayElementsExternally?: (value: string, index?: number) => JSX.Element | Array<JSX.Element>;
  endAdornment?: ReactNode;
  endAdornmentOnClick?: () => void;
  showEndAdornment?: boolean;
  // These 2 props are to differentiate between arrays of strings and arrays of objects
  // Specifically, objSuffix is the name of the key of the value you want to access
  isArrOfObj?: boolean;
  objSuffix?: string;
} & SelectProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ControlledMultipleSelectInput = ({
  isArrOfObj,
  objSuffix,
  name,
  displayEntityNames,
  required,
  choices,
  wrapperHeight,
  displayElementsExternally,
  endAdornment,
  endAdornmentOnClick,
  showEndAdornment = false,
  ...props
}: Props) => {
  const { register, control, getValues } = useFormContext();

  return (
    <Stack sx={{ height: wrapperHeight || 60 }} spacing={1.5}>
      <FormControl variant="standard">
        <InputLabel id="multiple-select-label">{props.label}</InputLabel>
        <Select
          displayEmpty
          MenuProps={MenuProps}
          fullWidth
          multiple
          label={props.label}
          defaultValue={[]}
          value={useWatch({ control, name })}
          renderValue={(selected: any) => {
            // checks if the displayElementsExternally prop was passsed,
            // in that case the items are displayed outside the Select comp.,
            // so then by design, "Select" is displayed for the renderValue; whereas in else part,
            // selected elements are displayed inside Select comp.
            // separated by a comma (', ')
            if (displayElementsExternally) {
              if (selected?.length > 0) {
                return <Typography sx={{ color: 'grey.500' }}>Select</Typography>;
              }

              return null;
            }

            if (selected?.length > 0) {
              return selected
                ?.map((key: string) => choices?.get(key))
                ?.join(', ');
            }

            return null;
          }}
          {...register(name,
            { required: required ? 'This field is required' : false })}
          // if showEndAdornment is true, then readOnly is true, else it is false
          // same as showEndAdornment ? true : false
          readOnly={!!showEndAdornment}
          // if you want to render endAdornment then the IconComponent will be null
          IconComponent={(props) => (showEndAdornment
            ? null : <i {...props} className={`material-icons ${props.className}`}>arrow_drop_down</i>
          )}
          endAdornment={showEndAdornment ? (
            <InputAdornment sx={{ pb: 2 }} position="end">
              {
                endAdornment || (
                  <Button
                    onButtonClick={() => endAdornmentOnClick?.()}
                    setBorderRadius
                    fontWeight="500"
                    variant="outlined"
                    color="secondary"
                    minWidth={80}
                  >
                    Add
                  </Button>
                )
              }
            </InputAdornment>
          ) : (
            null
          )}
          {...props}
        >
          {Array.from(choices, ([key, value]: [string, string]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      {
        displayElementsExternally
        && (
          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px' }}>
            {(useWatch({ control, name })?.length <= 3) ? (
              // TODO: fix any here!
              useWatch({ control, name }).map((value: any, index: number) => (
                displayElementsExternally(isArrOfObj ? getValues(`${name}.${index}.${objSuffix}`) : choices.get(value), index)
              ))
            ) : (
              useWatch({ control, name }) !== undefined && (
                displayElementsExternally(
                  `${getValues(name)?.length} ${displayEntityNames || 'items'} selected`,
                )
              )
            )}
          </Stack>
        )
      }
    </Stack>
  );
};

export default ControlledMultipleSelectInput;
