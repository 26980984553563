import { useState } from 'react';
import { Box, Divider, Stack, SxProps, Theme } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import CustomButton from '../../../../components/button/CustomButton';
import ComapnyProfileAvatar from './CompanyProfileAvatar';
import CompanyProfileInfoIcons from './CompanyProfileInfoIcons';
import CompanyProfileMediaLinks from './CompanyProfileMediaLinks';
import CompanyProfileAccordionInfo from './CompanyProfileAccordionInfo';
import CompanyProfileDescriptionAndClients from './CompanyProfileDescriptionAndClients';
import CompanyCaseStudies from './CompanyCaseStudies';
import LoadingIndicator from '../../../../components/loading-indicator/LoadingIndicator';

interface CompanyProfileDesktopViewProps {
  company: CompanyDto | undefined;
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

const CompanyProfileDesktopView = ({ company, isLoading, sx }: CompanyProfileDesktopViewProps) => {
  const [activeTab, setActiveTab] = useState<'overview' | 'case-study'>('overview');
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={5}
      sx={{
        px: { xs: '1rem', md: '1.5rem', lg: '2.5rem' },
        mx: { gr: 'auto' },
        width: { gr: '85rem' },
      }}
    >
      <Stack width="100%" maxWidth="374px" sx={{ px: 0 }} spacing={3}>
        <Stack
          sx={{
            border: 'solid',
            borderWidth: '0.5px',
            borderColor: '#E5E8FF',
            borderRadius: '0px 0px 15px 15px',
            py: 3.5,
            px: 1.5,
          }}
          divider={<Divider flexItem />}
        >
          <ComapnyProfileAvatar company={company} sx={{ mt: '-6.125rem' }} />
          <CompanyProfileInfoIcons company={company} sx={{ my: 3, pl: 2 }} />
          <CompanyProfileMediaLinks company={company} sx={{ mt: 3 }} />
        </Stack>
        <Box
          sx={{
            border: 'solid',
            borderWidth: '0.5px',
            borderColor: '#E5E8FF',
            borderRadius: ' 15px',
            p: 2,
            pt: 0,
          }}
        >
          <CompanyProfileAccordionInfo company={company} />
        </Box>
      </Stack>
      {isLoading ? (
        <Box width="100%">
          <LoadingIndicator sx={{ width: '100%', pt: '15rem' }} />
        </Box>
      ) : (
        <Box width="100%">
          <Stack direction="row" width="100%" justifyContent="flex-start" pt={4} pb={5}>
            <CustomButton
              onClick={() => setActiveTab('overview')}
              variant="primaryLight"
              sx={{
                borderColor: 'primaryDark.250',
                borderRadius: ' 4px 0 0 4px',
                boxShadow: activeTab === 'overview' ? '6px 6px 15px 0px #3845721A' : 'none',
                color: activeTab === 'overview' ? 'primaryLight.500' : 'primaryDark.500',
                fontSize: '0.75rem',
                minWidth: '6.4rem',
              }}
            >
              Overview
            </CustomButton>
            <CustomButton
              onClick={() => setActiveTab('case-study')}
              variant="primaryLight"
              sx={{
                borderColor: 'primaryDark.250',
                borderRadius: 0,
                boxShadow: activeTab === 'case-study' ? '6px 6px 15px 0px #3845721A' : 'none',
                color: activeTab === 'case-study' ? 'primaryLight.500' : 'primaryDark.500',
                fontSize: '0.75rem',
                minWidth: '6.4rem',
              }}
            >
              Case study
            </CustomButton>
          </Stack>
          <Box display={activeTab === 'overview' ? 'block' : 'none'}>
            <CompanyProfileDescriptionAndClients company={company} />
          </Box>
          <Box
            display={activeTab === 'case-study' ? 'block' : 'none'}
            alignContent="center"
            justifyContent="center"
          >
            <CompanyCaseStudies />
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default CompanyProfileDesktopView;
