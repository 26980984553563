import { Typography, Stack } from '@mui/material';
import CustomizedCheckbox from '../../custom-checkbox/CustomizedCheckbox';
import { useCustomInputContext } from '../CustomInputContext';

const CustomInputUserAgreement = () => {
  const { field, hasError } = useCustomInputContext();

  return (
    <Stack
      spacing={0.9}
      className={hasError ? 'UserAgreementError' : ''}
      direction="row"
      alignItems="center"
      sx={{
        px: '16px',
        py: '24px',
        border: '1px solid #E5E8FF',
        borderRadius: '6px',
      }}
    >
      <CustomizedCheckbox field={field} />

      <Typography
        variant="body1"
        color="primaryDark.600"
        sx={{
          fontSize: '12px',
          textTransform: 'uppercase',
          fontFamily: 'Gellix-SemiBold',
          lineHeight: '16px',
          letterSpacing: '0.05em',
        }}
      >
        I agree to the{' '}
        <a href="/terms-of-service" target="_blank" rel="noreferrer" style={{ color: '#6D51F6' }}>
          terms of service
        </a>{' '}
        and{' '}
        <a
          href="/terms-of-service/privacy-policy"
          target="_blank"
          rel="noreferrer"
          style={{ color: '#6D51F6' }}
        >
          privacy policy.
        </a>
      </Typography>
    </Stack>
  );
};

export default CustomInputUserAgreement;
