import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';

const Ebook = () => (
  <Stack width="100%" overflow="hidden">
    <Outlet />
  </Stack>
);

export default Ebook;
