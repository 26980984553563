import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';

export interface Testimonial {
  icon: string;
  subject: string;
  author: string;
  title: string;
  alt?: string;
}

export interface TestimonialProps {
  testimonial: Testimonial;
}

const TestimonialCard = ({ testimonial }: TestimonialProps) => (
  <Box
    sx={{
      borderRadius: 4,
      backgroundColor: '#fff',
      height: { xs: 520, sm: 560 },
      width: { xs: '100%' },
      p: { xs: 4 },
    }}
  >
    <Stack spacing={4} sx={{ height: '100%' }} justifyContent="space-between">
      <Stack spacing={3}>
        <Box
          src={testimonial.icon}
          alt={testimonial.alt}
          sx={{ mb: 1.2, borderRadius: 2 }}
          width={{ xs: '30% !important', lg: '16% !important' }}
          component="img"
        />

        <Box sx={{ mb: 2, maxWidth: { xs: '100%', md: '80%' } }}>
          <Typography variant="publicBody5Light" color="common.black" lineHeight="140%">
            {testimonial.subject}
          </Typography>
        </Box>
      </Stack>

      <Stack>
        <Typography variant="publicBody5Light" color="primaryDark.600">
          {testimonial.author}
        </Typography>

        <Typography variant="publicBody5Light" color="primaryDark.600" lineHeight="100%">
          {testimonial.title}
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default TestimonialCard;
