import { Box, Divider, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { BlogCategoryDto } from 'tdc-web-backend/blog-categories/schemas';
import { primaryDark, primaryLight, secondaryGray } from '../../../color';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import Author from './components/Author';
import Categories from './components/Categories';
import LeadText from './components/LeadText';
import Sections from './components/Sections/Sections';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import ActionPanel from './components/ActionPanel';
import ActionMenu from './components/ActionMenu';
import useDeleteOne from '../../../utils/hooks/crud-hooks/useDeleteOne';
import Slug from './components/Slug';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';

const BlogPreview = () => {
  const params = useParams();
  const blogId = params?.blogId || '';
  const navigate = useNavigate();

  if (blogId.length === 0) {
    navigate('../');
  }

  const {
    data: blogData,
    isFetched,
    refetch,
  } = useReferenceOne<BlogDto>({
    id: blogId,
    resource: 'blogs',
    enabled: !!blogId,
  });

  if (isFetched && blogData === undefined) {
    navigate('../');
  }

  const { mutate: update, isLoading: isLoadingUpdate } = useUpdate({ resource: 'blogs' });
  const { mutate: deleteBlog } = useDeleteOne({ resource: 'blogs' });

  const onPublish = () => {
    const postData = {
      id: blogId,
      data: {
        ...blogData,
        author: blogData?.author?.id,
        categories: blogData?.categories.map((category: BlogCategoryDto) => category.id),
        isDraft: false,
      },
    };

    update(postData, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const onDelete = () => {
    deleteBlog({ id: blogId });
    navigate('../');
  };

  return (
    <Stack
      sx={{
        padding: '1.5rem 1.5rem 0 1.5rem',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: primaryLight[50],
        minHeight: '100%',
        alignItems: 'stretch',
      }}
      divider={<Divider orientation="horizontal" />}
    >
      <Stack sx={{ height: '61px', pr: '1.5rem' }} direction="row" justifyContent="space-between">
        <Typography variant="headline3" sx={{ marginTop: '6px' }} color="#384572">
          Preview blog
        </Typography>
        <ActionMenu blog={blogData as BlogDto} />
      </Stack>
      {blogData ? (
        <Stack
          direction="row"
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box sx={{ width: '100%', padding: '1.5rem 1.5rem 1.5rem 0' }}>
            <Stack
              sx={{
                border: '1px solid',
                borderRadius: '5px',
                paddingBottom: '1.5rem',
                backgroundColor: secondaryGray[100],
                borderColor: primaryDark[250],
              }}
              spacing={3}
              divider={<Divider orientation="horizontal" />}
            >
              <Stack spacing={3} sx={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
                <Author
                  viewMode
                  author={`${blogData?.author?.firstName} ${blogData?.author?.lastName}`}
                />

                <Categories
                  viewMode
                  values={blogData?.categories.map((category: BlogCategoryDto) => category.name)}
                />

                <Slug
                  viewMode
                  basePath={`${origin}/blog/`}
                  initialSlug={blogData?.slug as string}
                />
              </Stack>
              <Stack spacing={3} sx={{ padding: '0 1.5rem' }}>
                <Typography
                  variant="heading1"
                  sx={{ fontWeight: 600, lineHeight: '2rem', letterSpacing: '0.035rem' }}
                >
                  {blogData?.headline}
                </Typography>

                <LeadText viewMode value={blogData?.leadText} />
              </Stack>

              <Sections viewMode values={blogData?.sections} />
            </Stack>
          </Box>
          <Box
            sx={{
              width: '20.5rem',
              minWidth: '20.5rem',
            }}
          >
            <ActionPanel
              viewMode
              blogData={blogData}
              isLoading={isLoadingUpdate}
              onPublish={onPublish}
              onDelete={onDelete}
            />
          </Box>
        </Stack>
      ) : (
        <LoadingIndicator />
      )}
    </Stack>
  );
};

export default BlogPreview;
