import { useState } from 'react';
import CustomDropdown from '../custom-dropdown/CustomDropdown';
import StatusChipField from '../StatusChipField/StatusChipField';

interface StatusChipDropdownProps<T,> {
  data: T;
  mapToGetTheValueFrom: Map<string, string>;
  status: string;
  menuElements: Record<string, string>;
  onMenuElementClick: () => void;
  classNames?: string;
}

// wrapper component consisting of CustomDropdown
// and StatusChipField
const StatusChipDropdown = <T extends { status: string }>({
  data,
  mapToGetTheValueFrom,
  status,
  menuElements,
  onMenuElementClick,
  classNames,
}: StatusChipDropdownProps<T>) => {
  const [isChipClicked, setIsChipClicked] = useState<boolean>(false);

  return (
    <CustomDropdown
      classNames={classNames}
      renderDropdownButtonElement={({ onClick }) => (
        <StatusChipField
          text={status}
          showChevronIcons
          isChipClicked={isChipClicked}
          setIsChipClicked={setIsChipClicked}
          onClick={onClick}
          sx={{ height: '28px', cursor: 'pointer' }}
        />
      )}
      onClickAway={() => setIsChipClicked(false)}
      defaultValue={status}
      menuItemsContents={
        (Object.values(menuElements)).map((value) => (
          {
            key: value as string,
            text: mapToGetTheValueFrom.get(value as string),
            onClick: () => {
              data.status = value;
              onMenuElementClick();

              setIsChipClicked(false);
            },
          }
        ))
      }
    />
  );
};

export default StatusChipDropdown;
