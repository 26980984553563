import { Stack, Box, Typography, Link } from '@mui/material';
import GooglePartner from '../../../../assets/img/public-pages/google-partner.svg';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
// import MetaLogo from '../../../../assets/img/public-pages/meta.svg';
import MetaLogo from '../../../../assets/img/public-pages/meta-business-partner.svg';

type MetaPartnesLogo =
  | {
      type: 'img';
      src: string;
      alt: string;
    }
  | {
      type: 'link';
      src: string;
      alt: string;
      href: string;
    };

const companyLogos: MetaPartnesLogo[] = [
  {
    src: MetaLogo,
    alt: 'meta',
    type: 'link',
    href: 'https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&id=8957086400969902&section=overview',
  },
  { src: GooglePartner, alt: 'google ads', type: 'img' },
];

const OurPartners = () => (
  <Stack spacing={2.5} alignItems="center" sx={{ ...LAYOUT_DEFAULTS, my: 12 }}>
    <Typography sx={{ typography: { xs: 'publicHeading4' } }}>Partnered with:</Typography>

    <Stack spacing={{ xs: 3, md: 16 }} direction={{ xs: 'column', md: 'row' }}>
      {companyLogos.map((logo) =>
        logo.type === 'img' ? (
          <Box
            key={logo.src}
            component="img"
            src={logo.src}
            alt={logo.alt}
            width={200}
            height={150}
          />
        ) : (
          <Box
            key={logo.src}
            component={Link}
            href={logo.href}
            target="_blank"
            rel="noopener"
            width={250}
            // height={150}
          >
            <img src={logo.src} alt={logo.alt} width={250} height={150} />
          </Box>
        ),
      )}
    </Stack>
  </Stack>
);

export default OurPartners;
