import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Divider, ListItemIcon, MenuItem, Modal, Paper, Stack, Typography } from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useParams } from 'react-router-dom';
import { TaskDto, UpdateTaskDto } from 'tdc-web-backend/tasks/schemas';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import Button from '../../../../components/button/Button';
import ControlledCheckboxInput from '../../../../components/custom-inputs/ControlledCheckboxInput';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import ControlledDateInput from '../../../../components/custom-inputs/ControlledDateInput';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import LoadingButton from '../../../../components/button/LoadingButton';
import { useRefresh } from '../../../../utils/hooks/crud-hooks/useRefresh';

export interface SubtaskModalProps {
  subtask: SubtaskDto;
}

const SubtaskEditModal = ({ subtask }: SubtaskModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { taskId } = useParams();
  const { mutate: update } = useUpdate<TaskDto, UpdateTaskDto>({ resource: '/subtasks' });
  const refresh = useRefresh();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...subtask,
      task: taskId,
    },
  });

  const { handleSubmit } = formMethods;

  const handleSubmitEdit = (data: FieldValues) => {
    update(
      {
        data,
        id: subtask.id as string,
      },
      {
        onSuccess: (data) => {
          setIsOpen(false);
          refresh();
        },
      },
    );
  };

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)} onFocus={() => null}>
        <ListItemIcon>
          <EditOutlinedIcon />
        </ListItemIcon>
        Edit
      </MenuItem>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSubmitEdit)}>
            <Stack
              sx={{
                width: '50%',
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Paper
                  sx={{
                    padding: '2em',
                  }}
                >
                  <Stack spacing={2}>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '1.25em',
                          fontWeight: 600,
                        }}
                      >
                        Edit subtask
                      </Typography>
                    </Stack>
                    <Stack>
                      <Stack direction="row" spacing={2}>
                        <ControlledCheckboxInput
                          name="status"
                          label=""
                          icon={<CheckCircleOutlineIcon sx={{ color: '#C5C7C3' }} />}
                          checkedIcon={<CheckCircleIcon sx={{ color: '#015732' }} />}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <ControlledTextInput name="name" onKeyDown={(e) => e.stopPropagation()} />
                        <ControlledDateInput name="end" />
                      </Stack>
                    </Stack>
                    <Divider />
                    <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                      <Button
                        variant="text"
                        setBorderRadius
                        onButtonClick={() => setIsOpen(false)}
                        sx={{
                          borderRadius: '100px',
                          fontWeight: 500,
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        setBorderRadius
                        type="submit"
                        onButtonClick={() => {}}
                      >
                        Confirm
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Paper>
              </LocalizationProvider>
            </Stack>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};
export default SubtaskEditModal;
