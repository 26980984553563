import {
  Box, Paper, SxProps, Theme,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicMultistep from '../../../components/multi-step/DynamicMultistep';
import StepConfirm from './Steps/StepConfirm';
import StepNewTask from './Steps/StepNewTask';
import StepSubtasks from './Steps/StepSubtasks';
import useTaskManagementStore from './useTaskManagementStore';

export interface TaskManagementProps {
  milestoneId?: string;
  sx?: SxProps<Theme> | undefined;
  editTaskProps?: Record<string, any>;
}

export default function TaskEditScreen({
  sx,
}: TaskManagementProps) {
  const taskManagementInput = useTaskManagementStore((state) => state.taskManagementInput);
  const setTaskManagementInput = useTaskManagementStore(
    (state) => state.setTaskManagementInput,
  );

  const navigate = useNavigate();

  const params = useParams();

  function finalizeTaskCreation() {
  }

  return (
    <>
      <Box sx={{ ...sx }}>
        <Paper sx={{ padding: '2em' }}>
          {
            taskManagementInput?.title
            && (
              <DynamicMultistep
                stepComponents={
                  [
                    {
                      component: <StepNewTask />,
                      label: 'Edit task',
                      heading: 'Edit task',
                    },
                    {
                      component: <StepSubtasks />,
                      label: 'Subtasks',
                      heading: 'Subtasks',
                    },
                    {
                      component: <StepConfirm />,
                      label: 'Confirm',
                    },
                  ]
                }
                onFinish={() => finalizeTaskCreation()}
              />
            )
          }
        </Paper>
      </Box>
    </>
  );
}
