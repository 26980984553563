import { useState, MouseEvent } from 'react';
import { Box } from '@mui/system';
import { Avatar, Badge, Divider, IconButton, Menu, Stack, Typography } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useNavigate } from 'react-router-dom';
import { UserRoles } from 'tdc-web-backend/enums/enums';
import arrowDownThinIcon from '../../../assets/icons/layout-icons/ArrowDownThinIcon.svg';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { primaryDark } from '../../../color';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as HouseIcon } from '../../../assets/img/public-pages/house-fill.svg';
import { ReactComponent as GridIcon } from '../../../assets/img/public-pages/grid-dropdownMenu.svg';
import { ReactComponent as BoxIcon } from '../../../assets/img/public-pages/box-arrow-dropdownMenu.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/img/public-pages/Logout-userdropdown.svg';
import CustomTooltip from '../../../components/tooltip/CustomTooltip';
import { truncate } from '../../../utils/helpers';
import { primaryLight } from '../../../color';
import { ReactComponent as PenIcon } from '../../../assets/icons/pencil-2.svg';
import AvatarEditorModal from '../../../views/Dashboard/Homescreen/components/AvatarEditorModal';
import { BaseUserDto } from 'tdc-web-backend/users/shemas';

const UserMenuDropdown = () => {
  const navigate = useNavigate();
  const userData = useGetLocalStorageUserData();
  const membership = userData?.membership;
  const companyId = userData?.membership?.company;
  const userId = userData?.id;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openBadge, setBadgeOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = (route: string) => {
    navigate(route);
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('userData');
    navigate('../');
    setAnchorEl(null);
  };

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: companyId,
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  });

  const { data: userCompanyData } = useReferenceOne<BaseUserDto>({
    resource: 'users',
    id: userId,
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  const fullName = `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`.trim();

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <IconButton
          size="large"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            gap: 1,
            p: 0,
            boxShadow: open ? '6px 6px 15px 0px #9747FF40' : 'none',
          }}
        >
          <Avatar
            src={userCompanyData?.avatar}
            alt="Company logo"
            sx={{ border: '1px solid #BFC6D7', height: '45px', width: '45px' }}
          />
        </IconButton>
        <Box
          component="img"
          src={arrowDownThinIcon}
          width="10px"
          sx={{
            transform: open ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'all 0.5s ease',
          }}
        />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 2,
            width: { md: '260px', lg: '20%', xl: '19%', xxl: '300px' },
            borderRadius: '8px',
            overflow: 'visible',
            boxShadow: '6px 6px 15px 4px #3845721A',
            color: primaryDark[500],
            px: 2,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack pb={2} pt={1} gap={1} direction="column" alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <IconButton
                onClick={() => setBadgeOpen(true)}
                sx={{
                  width: '25px',
                  height: '25px',
                  bgcolor: 'primaryLight.50',
                  borderRadius: '4px',
                  padding: '2px',
                  '&:hover': {
                    bgcolor: 'primaryLight.100',
                  },
                }}
              >
                <PenIcon fill={primaryLight[500]} />
              </IconButton>
            }
          >
            <Avatar
              src={userCompanyData?.avatar}
              alt="Company logo"
              sx={{
                border: '1px solid #BFC6D7',
                boxShadow: '6px 6px 15px 0px #9747FF40',
                height: '60px',
                width: '60px',
              }}
            />
          </Badge>
          <Stack mt={2} direction="column" alignItems="center" spacing={1}>
            {companyData && companyData?.name.length > 20 ? (
              <CustomTooltip placement="top" title={companyData?.name ?? ''}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    lineHeight: '21px',
                    color: primaryDark[500],
                    fontSize: '1.17rem',
                  }}
                >
                  {truncate(companyData?.name ?? '', 20)}
                </Typography>
              </CustomTooltip>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  lineHeight: '21px',
                  color: primaryDark[500],
                  fontSize: '1.17rem',
                }}
              >
                {' '}
                {companyData?.name}
              </Typography>
            )}
            {companyData && fullName.length > 20 ? (
              <CustomTooltip placement="top" title={fullName}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    lineHeight: '21px',
                    color: primaryDark[500],
                    paddingTop: '10px',
                  }}
                >
                  {truncate(fullName, 20)}
                </Typography>
              </CustomTooltip>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 400,
                  lineHeight: '21px',
                  color: primaryDark[500],
                  paddingTop: '10px',
                }}
              >
                {fullName}
              </Typography>
            )}
            {userData && userData?.email.length > 35 ? (
              <CustomTooltip placement="top" title={userData?.email ?? ''}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 400, lineHeight: '21px', color: primaryDark[500] }}
                >
                  {truncate(userData?.email ?? '', 35)}
                </Typography>
              </CustomTooltip>
            ) : (
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, lineHeight: '21px', color: primaryDark[500] }}
              >
                {userData?.email}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider
          sx={{
            borderColor: '#E5E8FF',
          }}
        />
        <Stack mt={2} mb={1} pl={2} pr={2} direction="column">
          <CustomButton
            sx={{ py: '4px', fontWeight: 400, fontSize: '1rem' }}
            variant="secondaryText"
            startIcon={<HouseIcon />}
            onClick={() =>
              handleLinkClick(membership === null ? '../dashboard/create-company' : '../dashboard')
            }
          >
            Dashboard
          </CustomButton>
          {userData?.roles?.includes(UserRoles.Admin) && (
            <CustomButton
              sx={{ py: '4px', fontWeight: 400, fontSize: '1rem' }}
              variant="secondaryText"
              startIcon={<GridIcon />}
              onClick={() => handleLinkClick('../admin/end-to-end/projects')}
            >
              Admin dashboard
            </CustomButton>
          )}
          <CustomButton
            sx={{ py: '4px', fontWeight: 400, fontSize: '1rem' }}
            variant="secondaryText"
            startIcon={<LogoutIcon />}
            onClick={handleSignOut}
          >
            Log out
          </CustomButton>
        </Stack>
      </Menu>
      <AvatarEditorModal userDropdown open={openBadge} setOpen={setBadgeOpen} />
    </>
  );
};

export default UserMenuDropdown;
