import { useState } from 'react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../../theme';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import {
  ourJourneyArray,
  ourJourneyCarouselElements,
  ourJourneyCarouselElementsMobile,
} from '../utils';
import CustomAliceCarousel from '../../../../components/custom-alice-carousel/CustomAliceCarousel';

const responsive = {
  [theme.breakpoints.values.xs]: { items: 1 },
  [theme.breakpoints.values.sm]: { items: 2 },
  [theme.breakpoints.values.md]: { items: 3 },
  [theme.breakpoints.values.lg]: { items: 4 },
  [theme.breakpoints.values.xl]: { items: 3 },
  [theme.breakpoints.values.xxl]: { items: 3 },
};

const OurJourney = () => {
  const isGreaterThanSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const [index, setIndex] = useState<number>(0);

  return (
    <Stack
      mt={{ xs: 0, sm: 8 }}
      pt={{ xs: 0, md: 8 }}
      pb={{ xs: 8 }}
      sx={{
        backgroundColor: { xs: 'common.white', lg: 'primaryDark.200' },
        position: 'relative',
      }}
    >
      <Stack
        spacing={{ xs: 1, lg: 20 }}
        direction={{ xs: 'column-reverse', lg: 'row' }}
        alignItems="center"
        justifyContent="start"
        sx={{
          ...LAYOUT_DEFAULTS,
          position: 'relative',
        }}
      >
        <Stack spacing={2.5} width={{ xs: '100%', lg: '60%' }}>
          <Typography
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '100%',
              typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
              mr: 42,
              pb: { xs: 0, md: 1, lg: 2 },
            }}
          >
            Our journey
          </Typography>

          <Typography
            maxWidth={{
              xs: '100%',
              lg: '90%',
            }}
            variant="publicBody2"
          >
            {ourJourneyArray[index].subtitle}
          </Typography>

          <Typography
            maxWidth={{
              xs: '100%',
              lg: '90%',
            }}
            variant="publicBody3Light"
          >
            {ourJourneyArray[index].content}
          </Typography>
        </Stack>

        <Box sx={{ width: '100%' }}>
          <CustomAliceCarousel
            responsive={responsive}
            items={
              isGreaterThanSmall ? ourJourneyCarouselElements : ourJourneyCarouselElementsMobile
            }
            onChangedSlide={(index: number) => setIndex(index)}
            buttonStyles={{
              display: { xs: 'none', md: 'block' },
              top: { xs: '15%', sm: '30%', lg: '50%' },
              transform: 'translateY(-50%)',
              right: { xs: '-4%', md: '-1%', lg: '0%', xxl: '-15%' },
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default OurJourney;
