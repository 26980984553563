import { Dispatch, SetStateAction, createContext } from 'react';

interface DrawerContextInterface {
    isDrawerOpen: boolean;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    timedProjectPitchId: string | null;
    setTimedProjectPitchId: Dispatch<SetStateAction<string | null>>;
  }

  const drawerContextDefaultValue: DrawerContextInterface = {
    isDrawerOpen: false,
    setIsDrawerOpen: () => false,
    timedProjectPitchId: null,
    setTimedProjectPitchId: () => { },
  }

  // eslint-disable-next-line import/prefer-default-export
  export const DrawerContext = createContext<DrawerContextInterface>(drawerContextDefaultValue);
