import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import AdminMain from './AdminMain';

const Admin = () => (
  <Box sx={{ backgroundColor: '#FFF', minHeight: '100vh' }}>
    <AdminMain outlet={<Outlet />} />
  </Box>
);

export default Admin;
