import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Controller, useFormContext } from 'react-hook-form';
import CustomDateCalendar from '../../../../components/custom-date-calendar/CustomDateCalendar';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ControlledRadioInput from '../../../../components/custom-inputs/ControlledRadioInput';

const Timeline = () => {
  const { control, setValue, watch } = useFormContext();
  const projectStart = watch('timeline.projectStart');
  const pitchDeadline = watch('timeline.pitchDeadline');

  return (
    <Stack flexDirection="row">
      <Box>
        <BaseInputLabel sx={{ marginBottom: '16px' }} text="select pitch deadline" required />
        <Controller
          name="timeline.pitchDeadline"
          control={control}
          render={({ field }) => (
            <CustomDateCalendar
              sx={{ marginBottom: '16px' }}
              shouldDisableDate={(day) => projectStart && day > projectStart}
              {...field}
            />
          )}
        />
        <ControlledRadioInput
          name="timeline.pitchDeadlineControl"
          label="or"
          radio={{
            label: 'I am flexible',
            config: {
              onClick: () => setValue('timeline.pitchDeadline', null),
              checked: pitchDeadline === null,
            },
          }}
        />
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: '#E5E8FF', margin: '0 24px' }} flexItem />
      <Box>
        <BaseInputLabel
          sx={{ marginBottom: '16px' }}
          text="select expected project start"
          required
        />
        <Controller
          name="timeline.projectStart"
          control={control}
          render={({ field }) => (
            <CustomDateCalendar
              sx={{ marginBottom: '16px' }}
              shouldDisableDate={(day) => day < pitchDeadline}
              {...field}
            />
          )}
        />
        <ControlledRadioInput
          name="timeline.projectStartControl"
          label="or"
          radio={{
            label: 'I am flexible',
            config: {
              onClick: () => setValue('timeline.projectStart', null),
              checked: projectStart === null,
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default Timeline;
