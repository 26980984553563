import { useFormContext } from 'react-hook-form';
import Stack from '@mui/system/Stack/Stack';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import ControlledRadioInput from '../../../../components/custom-inputs/ControlledRadioInput';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { initialBuyerState, initialSellerState } from '../utils';
import { secondaryBlue, secondaryGreen, primaryDark } from '../../../../color';
import { TUserType } from '../interface';
import { CompanyRole } from 'tdc-web-backend/enums/enums';

const sxRadio = {
  padding: '24px',
  border: `1px solid ${secondaryBlue[100]}`,
  borderRadius: '6px',
  marginLeft: '0px',
};

const sxBoldText = { fontFamily: 'Gellix-SemiBold', color: primaryDark[500] };

const HELPER_MESSAGES = {
  seller_service: (
    <>
      Be sure to choose the correct user type! If your company offers digital services and is
      looking for new clients, your user type is <b style={sxBoldText}>seller</b>.
    </>
  ),
  buyer: (
    <>
      Be sure to choose the correct user type! If your company is looking to hire service providers
      for digital projects, your user type is <b style={sxBoldText}>buyer</b>.
    </>
  ),
};

const User = () => {
  const { watch, reset } = useFormContext();
  const userValue: TUserType = watch('user');
  const isBuyer = userValue === CompanyRole.Buyer;
  const isSeller = userValue === CompanyRole.SellerService;

  return (
    <Stack flexDirection="column" gap={1}>
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        direction="row"
        sx={{
          bgcolor: 'primaryLight.50',
          p: 2,
          borderRadius: '4px',
          mb: 2,
        }}
      >
        <Typography color="primaryDark.600">{HELPER_MESSAGES[userValue]}</Typography>
      </Stack>
      <BaseInputLabel text="select your user type" />
      <RadioGroup name="user" sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <ControlledRadioInput
          name="user"
          radio={{
            label: 'Buyer',
            sx: { ...sxRadio, borderColor: isBuyer ? secondaryGreen[500] : secondaryBlue[100] },
            config: {
              onClick: () => reset(initialBuyerState),
              checked: isBuyer,
            },
          }}
        />
        <ControlledRadioInput
          name="user"
          radio={{
            label: 'Seller',
            sx: { ...sxRadio, borderColor: isSeller ? secondaryGreen[500] : secondaryBlue[100] },
            config: {
              onClick: () => reset(initialSellerState),
              checked: isSeller,
            },
          }}
        />
      </RadioGroup>
    </Stack>
  );
};

export default User;
