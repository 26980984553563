import { Stack } from '@mui/material';
import ChannelList from './Sidebar/SpaceList';
import ChannelView from './Channel/ChannelView';
import { useChatNavigation } from './useChatNavigation';
import { APPBAR_HEIGHT } from '../../layout/dashboard-navigation/NavigationDashboard';

const ChatScreen = () => {
  const chatNavigation = useChatNavigation();

  return (
    <Stack direction="row" height={`calc(100vh - ${APPBAR_HEIGHT}px)`}>
      <ChannelList />
      {chatNavigation.currentChannelId && (
        <ChannelView
          key={chatNavigation.currentChannelId}
          channelId={chatNavigation.currentChannelId}
        />
      )}
    </Stack>
  );
};

export default ChatScreen;
