import { useState, useEffect } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import useGetLocalStorageUserData from './useGetLocalStorageUserData';
import useReferenceOne from './crud-hooks/useReferenceOne';

const useDetermineCompanyRoles = () => {
  const userData = useGetLocalStorageUserData();
  const [companyRoles, setCompanyRoles] = useState<CompanyRole[] | null>(null);

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    enabled: !!userData && !!userData.membership?.company,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    setCompanyRoles(companyData?.roles as CompanyRole[]);
  }, [companyData]);

  return companyRoles;
};

export default useDetermineCompanyRoles;
