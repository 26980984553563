import {
  IconButton, ListItemIcon, Menu, MenuItem, Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useState, MouseEvent } from 'react';
import { GridValueGetterParams } from '@mui/x-data-grid';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { PaymentStatus } from 'tdc-web-backend/enums/enums';
import InvoiceUploadModal from './InvoiceUploadModal';
import CompletePaymentModal from './CompletePaymentModal';
import DueDateModal from './DueDateModal';

interface SubtaskActionsMenuType {
  params: GridValueGetterParams;
}

const PaymentActionMenu = ({ params }: SubtaskActionsMenuType) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isPaymentCompletedModalOpen, setIsPaymentCompletedModalOpen] = useState(false);
  const [isDueDateSellerInvoiceModalOpen, setIsDueDateSellerInvoiceModalOpen] = useState(false);
  const [isDueDateBuyerInvoiceModalOpen, setIsDueDateBuyerInvoiceModalOpen] = useState(false);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  return (
    <>
      <IconButton
        onClick={(event) => handleMenuOpen(event)}
      >
        <MoreVertIcon sx={{ fontSize: '1.55rem' }} />
      </IconButton>
      <Menu
        key={`menu-${params.row.id}`}
        id={`menu-${params.row.id}`}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={1}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'grey.50',
          },
        }}
      >

        {params.row.buyerInvoice
          ? (
            <MenuItem onClick={() => {
              // setIsOpen(true);
            }}
            >
              <a
                href={params.row.buyerInvoice}
                download="invoice"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#747474',
                }}
                target="_blank"
                rel="noreferrer"
              >
                <ListItemIcon>
                  <SaveAltIcon />
                </ListItemIcon>
                <Typography sx={{ color: '#666666' }}>Buyer invoice</Typography>
              </a>
            </MenuItem>
          )

          : (
            <MenuItem
              disabled={!params.row.sellerInvoice}
              onClick={() => {
                setIsInvoiceModalOpen(true);
              }}
            >
              <ListItemIcon>
                <UploadFileOutlinedIcon />
              </ListItemIcon>
              <Typography sx={{ color: '#666666' }}>Upload buyer invoice</Typography>
            </MenuItem>
          )}
        <MenuItem
          disabled={!params.row.sellerInvoice}
          onClick={() => {
            // setIsOpen(true);
          }}
        >
          <a
            href={params.row.sellerInvoice}
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
            target="_blank"
            rel="noreferrer"
            download="invoice"
          >
            <ListItemIcon>
              <SaveAltIcon />
            </ListItemIcon>
            <Typography sx={{ color: '#666666' }}>Seller invoice</Typography>
          </a>
        </MenuItem>
        <MenuItem
          disabled={!params.row.sellerInvoice || params.row.paymentStatus === PaymentStatus.Paid}
          onClick={() => {
            setIsDueDateSellerInvoiceModalOpen(true);
          }}
        >
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>
          <Typography sx={{ color: '#666666' }}>Seller Invoice Due Date</Typography>
        </MenuItem>
        <MenuItem
          disabled={!params.row.buyerInvoice || params.row.paymentStatus === PaymentStatus.Paid}
          onClick={() => {
            setIsDueDateBuyerInvoiceModalOpen(true);
          }}
        >
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>
          <Typography sx={{ color: '#666666' }}>Buyer Invoice Due Date</Typography>
        </MenuItem>
        <MenuItem
          disabled={!params.row.buyerInvoice || params.row.paymentStatus === PaymentStatus.Paid}
          onClick={() => {
            setIsPaymentCompletedModalOpen(true);
          }}
        >
          <ListItemIcon>
            <TaskAltIcon />
          </ListItemIcon>
          <Typography sx={{ color: '#666666' }}>Complete payment</Typography>
        </MenuItem>
      </Menu>

      {/* render only if isOpen */}
      {isInvoiceModalOpen && (
        <InvoiceUploadModal
          data={params.row}
          isOpen={isInvoiceModalOpen}
          setIsOpen={setIsInvoiceModalOpen}
        />
      )}
      
      <CompletePaymentModal
        data={params.row}
        isOpen={isPaymentCompletedModalOpen}
        setIsOpen={setIsPaymentCompletedModalOpen}
      />
      <DueDateModal
        data={params.row}
        isOpen={isDueDateSellerInvoiceModalOpen}
        setIsOpen={setIsDueDateSellerInvoiceModalOpen}
        propertyName="sellerInvoiceDueDate"
      />
      <DueDateModal
        data={params.row}
        isOpen={isDueDateBuyerInvoiceModalOpen}
        setIsOpen={setIsDueDateBuyerInvoiceModalOpen}
        propertyName="buyerInvoiceDueDate"
      />
    </>
  );
};

export default PaymentActionMenu;
