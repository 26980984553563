import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import Heading from './components/Heading/Heading';
import Content from './components/Content/Content';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import LatesBlogs from './components/LatestBlogs';
import SubscribeToNewsletter from '../../../components/subscribe-to-newsletter/SubscribeToNewsletter';

const Blog = () => {
  const params = useParams();

  const { data: blogData } = useGetOne<BlogDto>({
    enabled: !!params?.blogSlug,
    resource: `blogs/${params.blogSlug}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return (
    <>
      {blogData && (
        <>
          <Stack
            spacing={10}
            pt={20}
            // mx={{  xs: 3, lg: 5, gr: 10, xxl: 'auto' }}
            mx="auto"
            maxWidth={{ xs: '1328px', lg: '1360px', gr: '1440px' }}
            px={{ xs: 3, lg: 5, gr: 10, xxl: 'auto' }}
          >
            <Heading blog={blogData?.data as BlogDto} />
            <Content blog={blogData?.data as BlogDto} />
            <LatesBlogs />
            {/* <SubscribeToNewsletter /> */}
          </Stack>
          <Box sx={{ backgroundColor: 'primaryLight.50' }}>
            <Stack
              spacing={10}
              pt={10}
              pb={12.5}
              mt={12.5}
              mx="auto"
              maxWidth={{ xs: '1328px', lg: '1360px', gr: '1440px' }}
              px={{ xs: 3, lg: 5, gr: 10, xxl: 'auto' }}
            >
              <SubscribeToNewsletter />
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

export default Blog;
