import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { primaryDark } from '../../color';

interface IProps {
  icon: ReactNode;
  title: string;
}

const PreviewHeader = ({ icon, title }: IProps) => (
  <Stack
    sx={{
      fill: primaryDark[500],
    }}
    alignItems="center"
    direction="row"
  >
    {icon}
    <Typography color="primaryDark.500" variant="heading4" ml={1.375} sx={{ fontSize: '12px' }}>
      {title}
    </Typography>
  </Stack>
);

export default PreviewHeader;
