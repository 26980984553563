import { Box, BoxProps } from '@mui/material';

export default ({ children, sx, ...props }: BoxProps) => (
  <Box
    sx={{
      backgroundColor: '#ffffff',
      boxShadow: '6px 6px 15px 0px rgba(56, 69, 114, 0.10)',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);
