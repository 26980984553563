import { Divider, Stack, Typography } from '@mui/material';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { ReactComponent as TrashBinIcon } from '../../../assets/icons/project-icons/TrashBinIcon.svg';
import CustomModal from '../../../components/modal/CustomModal';
import CustomButton from '../../../components/button/CustomButton';
import { secondaryBlue } from '../../../color';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import useDeleteOne from '../../../utils/hooks/crud-hooks/useDeleteOne';

export interface DeleteEventModalProps {
  open: boolean;
  onCancel: () => void;
  event : CalendarEventDto
}

const DeleteEventModal = ({ open, onCancel, event }: DeleteEventModalProps) => {
  const refresh = useRefresh();
  const { mutate: deleteEvent } = useDeleteOne({ resource: '/events' });

  const deleteEventClick = () => {
    deleteEvent({
      id: event.id,
    }, {
      onSuccess: () => {
        onCancel();
        refresh();
      },
    });
  };
  return (
    <CustomModal open={open} width="40%">
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Stack width={40} height={40} mr={2} bgcolor="secondaryBlue.100" borderRadius={1} justifyContent="center" alignItems="center">
            <TrashBinIcon width="20px" fill={secondaryBlue[700]} height="20px" />
          </Stack>
          <Typography variant="heading5">Delete Event</Typography>
        </Stack>
        <Divider />
        <Typography pt={1} pb={1} variant="body3">Are you sure you want to delete the event?</Typography>
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'right' }} spacing={2}>
          <CustomButton variant="secondary" onClick={onCancel}>Cancel</CustomButton>
          <CustomButton onClick={deleteEventClick}>Confirm</CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DeleteEventModal;
