import { SxProps } from '@mui/system';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { ReactComponent as Check } from '../../../../assets/icons/check-icon.svg';

type Props = {
  title: string;
  hideCheck?: boolean;
  sxTitle?: SxProps<Theme>;
};

const CheckedItem = ({ title, hideCheck, sxTitle }: Props) => (
  <Stack direction="row" alignItems="center" gap={1.5}>
    <Box sx={{ width: 24, height: 24 }}>
      <Check style={{ display: hideCheck ? 'none' : 'unset' }} />
    </Box>
    <Typography sx={{ ...sxTitle }} variant="publicBody4Regular" color="primaryDark.900">
      {title}
    </Typography>
  </Stack>
);

export default CheckedItem;
