import { useFormContext, Controller, useWatch } from 'react-hook-form';
import TextField from '@mui/material/TextField/TextField';
import { Autocomplete, MenuItem, SelectProps, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import BaseInputLabel from './BaseInputLabel';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { primaryDark, secondaryBlue, secondaryGreen, secondaryPink } from '../../color';
import CustomChip from '../custom-chip/CustomChip';

type ControlledAutocompleteInputProps<T> = {
  name: string;
  choices: Map<string, string>;
  required?: boolean;
  withChips?: boolean;
  transformLabelFn?: (data: any) => string;
} & SelectProps;

const ControlledAutocompleteInput = <T,>({
  name,
  choices,
  required = false,
  placeholder,
  label,
  disabled = false,
  withChips,
  transformLabelFn,
  multiple,
  ...props
}: ControlledAutocompleteInputProps<T>) => {
  const { control, getFieldState, formState, setValue, trigger, watch } = useFormContext();

  const fieldState = getFieldState(name);
  const showError = _.get(formState.errors, name) && fieldState.isTouched;
  const fieldValue = useWatch({ control, name });

  const styles = {
    '.MuiAutocomplete-popper': {
      mt: '2px !important',
    },
    '.MuiAutocomplete-root': {
      border: disabled
        ? 'none'
        : `1px solid ${showError ? secondaryPink[100] : secondaryBlue[100]}`,
      color: disabled ? 'primaryDark.300' : 'primaryDark.600',
      backgroundColor: showError ? 'secondaryPink.100' : 'transparent',

      fontSize: '14px',
      borderRadius: '4px !important',
      '&.Mui-focused': {
        borderColor: 'secondaryYellow.500',
        boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
      },
    },
    '.MuiInputBase-input': {
      cursor: 'pointer !important',
      fontSize: '14px !important',
      padding: '0.844rem 0.75rem !important',
      '&::placeholder': {
        color: 'primaryDark.400 !important',
      },
    },
    '.MuiInputBase-root': {
      '&::before': {
        content: '""',
        display: 'none',
      },
      '&::after': {
        content: '""',
        display: 'none',
      },
    },
    '.MuiAutocomplete-endAdornment': {
      mr: '8px',
    },
    '.MuiAutocomplete-paper': {
      boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
      fontSize: '16px',
      color: 'primaryDark.500',
      fontFamily: 'Gellix-SemiBold',
      padding: '8px 16px',
      '&:hover': {
        color: 'primaryDark.600 !important',
      },
      svg: {
        display: 'none',
      },
      '&.MuiPaper-root': {
        p: '0px 0px !important',
      },
    },
    '.MuiAutocomplete-option[aria-selected="true"]': {
      '.MuiTypography-root': {
        backgroundColor: 'transparent !important',
        color: 'primaryDark.600',
      },
      '&:hover': {
        backgroundColor: 'primaryDark.200 !important',
      },
      svg: {
        display: 'block',
      },

      '&::placeholder': {
        color: 'red !important',
      },
    },
    '.MuiAutocomplete-noOptions': {
      fontSize: '14px',
      color: 'primaryDark.400',
    },
    '.Mui-disabled': {
      backgroundColor: 'primaryDark.200',
      color: 'primaryDark.300',
      border: 'none !important',
    },
  };

  return (
    <Stack
      sx={{
        ...props.sx,
        ...styles,
        mb: '24px',
      }}
    >
      {label && (
        <BaseInputLabel
          sx={{ color: disabled ? 'primaryDark.300' : 'primaryDark.500' }}
          text={label as string}
          required={required}
        />
      )}

      <Controller
        control={control}
        name={name}
        rules={required ? { required: 'This field is required' } : { required: false }}
        render={(props) => (
          <Autocomplete
            multiple={multiple}
            disabled={disabled}
            disablePortal
            value={props.field.value === '' ? null : props.field.value}
            onChange={(_, data) => props.field.onChange(data)}
            options={Array.from(choices.keys())}
            getOptionLabel={(option) => choices.get(option) as string}
            renderOption={({ ...props }: any) => (
              <MenuItem
                {...props}
                sx={{
                  width: '100%',
                  display: 'flex !important',
                  justifyContent: 'space-between !important',
                  alignItems: 'center',
                  '&::after': {
                    display: 'none',
                    content: "''",
                  },
                  '&:hover': {
                    backgroundColor: `${primaryDark[150]} !important`,
                  },
                }}
                key={props.key}
                value={props.key}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'primaryDark.500',
                    fontFamily: 'Gellix-SemiBold',
                  }}
                >
                  {props.key}
                </Typography>

                <CheckIcon fill={secondaryGreen[700]} />
              </MenuItem>
            )}
            popupIcon={<ExpandMoreIcon sx={{ pointerEvents: 'none !important' }} />}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                inputProps={{
                  ...params.inputProps,
                  sx: {
                    '&::placeholder': {
                      color: primaryDark[400],
                      opacity: 1,
                    },
                  },
                }}
              />
            )}
            renderTags={() =>
              // return an empty array to hide the default chips
              []
            }
            {...props}
          />
        )}
      />

      <>
        {showError ? (
          <Stack position="relative" sx={{ mb: 1 }}>
            <Typography
              color="secondaryPink.500"
              variant="body4"
              sx={{ position: 'absolute', mt: 1 }}
            >
              {(fieldState?.error?.message?.length ?? 0) > 0
                ? fieldState?.error?.message
                : 'This field is required'}
            </Typography>
          </Stack>
        ) : (
          ''
        )}

        {withChips && fieldValue?.length ? (
          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
            {fieldValue.map((val: string) => {
              const chipLabel = choices instanceof Map ? choices.get(val) : '';
              return (
                <CustomChip
                  key={val}
                  label={transformLabelFn ? transformLabelFn(chipLabel) : chipLabel}
                  onDelete={() => {
                    setValue(
                      name,
                      fieldValue.filter((item: string) => item !== val),
                    );
                    trigger(name);
                  }}
                />
              );
            })}
          </Stack>
        ) : null}
      </>
    </Stack>
  );
};

export default ControlledAutocompleteInput;
