import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { mapService } from 'tdc-web-backend/enums/enums';
import MultiSelectChips from '../../../../components/multi-select-chips/MultiSelectChips';
import useDynamicMultistepStore from '../../../../components/multi-step/useDynamicMultistepStore';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../enums';
import useCreateArticleStore from '../useCreateArticleStore';

const StepSecond = () => {
  const createArticleInput = useCreateArticleStore((state) => state.createArticleInput);
  const setCreateArticleInput = useCreateArticleStore((state) => state.setCreateArticleInput);
  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);

  const requiredMessage = 'This field is required';

  const {
    watch,
    control,
    formState: { isValid },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      services: createArticleInput.services ?? [],
      techLanguages: createArticleInput.techLanguages ?? [],
      techPlatforms: createArticleInput.techPlatforms ?? [],
      techTools: createArticleInput.techTools ?? [],
    },
  });

  const handleRemove = (index: number, fieldString: string) => {
    let field: [] = [];
    switch (fieldString) {
      case 'services':
        field = getValues('services');
        field.splice(index, 1);
        setValue('services', field);
        break;
      case 'techLanguages':
        field = getValues('techLanguages');
        field.splice(index, 1);
        setValue('techLanguages', field);
        break;
      case 'techPlatforms':
        field = getValues('techPlatforms');
        field.splice(index, 1);
        setValue('techPlatforms', field);
        break;
      case 'techTools':
        field = getValues('techTools');
        field.splice(index, 1);
        setValue('techTools', field);
        break;
      default:
    }
  };

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setCreateArticleInput(watch()));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box component="form">
      <Stack>
        <Stack spacing={2}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Expertise
            </Typography>
            <Typography variant="body1">
              Select the expertise & concepts expressed in your article.
            </Typography>
          </Box>

          <MultiSelectChips
            enumMap={mapService}
            watch={watch}
            control={control}
            valuesName="services"
            isRequired
            requiredMessage={requiredMessage}
            backgroundColor="#fff"
            shouldHaveRemove
            remove={(index: number) => handleRemove(index, 'services')}
          />
        </Stack>

        <Box sx={{ mt: 6, mb: 2 }}>
          <Typography variant="h6" sx={{ color: 'grey.500' }}>
            OPTIONAL
          </Typography>
          <Divider />
        </Box>

        <Stack spacing={3}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              Tech languages
            </Typography>
            <MultiSelectChips
              enumMap={mapTechLanguage}
              watch={watch}
              control={control}
              valuesName="techLanguages"
              isRequired
              requiredMessage={requiredMessage}
              backgroundColor="#fff"
              shouldHaveRemove
              remove={(index: number) => handleRemove(index, 'techLanguages')}
            />
          </Box>

          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              Tech platforms
            </Typography>
            <MultiSelectChips
              enumMap={mapTechPlatform}
              watch={watch}
              control={control}
              valuesName="techPlatforms"
              isRequired
              requiredMessage={requiredMessage}
              backgroundColor="#fff"
              shouldHaveRemove
              remove={(index: number) => handleRemove(index, 'techPlatforms')}
            />
          </Box>

          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              Tech tools
            </Typography>
            <MultiSelectChips
              enumMap={mapTechTool}
              watch={watch}
              control={control}
              valuesName="techTools"
              isRequired
              requiredMessage={requiredMessage}
              backgroundColor="#fff"
              shouldHaveRemove
              remove={(index: number) => handleRemove(index, 'techTools')}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default StepSecond;
