import { Box } from '@mui/material';
import BigCalendar from './big-calendar';
import BannerNotificationMapper from '../../components/notifications/bannerNotifications/BannerNotificationMapper';
import { EnumScreenList } from 'tdc-web-backend/enums/enums';

const CalendarScreen = () => (
  <Box sx={{ height: '500px' }}>
    <BannerNotificationMapper screen={EnumScreenList.Calendar} />
    <BigCalendar />
  </Box>
);

export default CalendarScreen;
