import { Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { BlogSectionItem } from './Sections';

interface TableOfContentsProps {
  sections: BlogSectionItem[];
  activeId: string;
}

const TableOfContents = ({ sections, activeId }: TableOfContentsProps) => (
  <nav className="stickyElement" aria-label="Table of contents" style={{ top: 104 }}>
    <Stack direction="column" spacing={4} marginTop="40px">
      <Typography variant="publicBody3" color="secondaryGray.900" lineHeight="145%">
        Table of contents
      </Typography>
      <ul>
        <Stack spacing={2} direction="column">
          {sections.map((section: BlogSectionItem) => (
            <Link
              key={section.id}
              href={`#${section.id}`}
              onClick={(e) => {
                e.preventDefault();
                const heading = document.querySelector(`#${section.id}`);
                if (heading) {
                  heading.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                variant="publicBody3Regular"
                color={activeId === section.id ? 'primaryLight.500' : '#secondaryGray.900'}
                sx={{ textDecoration: 'none' }}
              >
                {section.subtitle}
              </Typography>
            </Link>
          ))}
        </Stack>
      </ul>
    </Stack>
  </nav>
);

export default TableOfContents;
