import { Stack, Typography } from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import DateDisplayCard from '../../../../components/DateDisplayCard/DateDisplayCard';
import { formatCurrency, stripTags } from '../../../../utils/helpers';
import useGetCooperationCompany from '../../../../utils/hooks/useGetCooperationCompany';
import DynamicDescriptionAndReadMoreWithModal from '../../../../components/dynamic-description-and-read-more-with-modal/DynamicDescriptionAndReadMoreWithModal';

type GeneralProjectDetailsProps = {
  data: ProjectDto;
};

const GeneralProjectDetails = ({ data }: GeneralProjectDetailsProps) => {
  const cooperationCompanyData = useGetCooperationCompany(data?.id);

  return (
    <>
      <Stack direction="row">
        <Stack spacing={10} width="50%" className="react-joyride-step-2-project">
          <Stack spacing={1}>
            <Typography variant="heading4" color="primaryDark.500">
              Project name
            </Typography>
            <Typography
              variant="heading1"
              color="primaryDark.600"
              sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              fontSize={28}
            >
              {data?.name}
              {/* TODO: This button will be available for v3 of TDA app */}
              {/* <IconButton sx={{
                borderRadius: '3px',
                bgcolor: '#E6E8FF',
              }}

              >
                <BoxArrowUpRightLeft fill="#4051EC" />
              </IconButton> */}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <DynamicDescriptionAndReadMoreWithModal
              description={stripTags(data?.description as string)}
              sx={{ mt: 3.2, width: '100%' }}
              title="Project description"
            />
          </Stack>
        </Stack>
        <Stack spacing={10} width="50%">
          <Stack spacing={1} direction="row" justifyContent="end">
            <DateDisplayCard date={data?.start} text="start date" sx={{ width: '8.55rem' }} />
            <DateDisplayCard date={data?.end} variant="end" text="end date" />
          </Stack>
          <Stack spacing={2} direction="row">
            <Stack width="50%" spacing={1}>
              <Typography variant="heading4" color="primaryDark.500">
                cooperation with
              </Typography>
              {/* FIXME: variant for cooperation company name on figma is not standardized! */}
              {/* hence font override is used here for now */}
              <Typography
                variant="heading2"
                color="primaryDark.600"
                textTransform="capitalize"
                fontSize="1.125rem"
              >
                {cooperationCompanyData?.name}
              </Typography>
            </Stack>
            <Stack width="50%" spacing={2}>
              <Typography variant="heading4" color="primaryDark.500">
                budget
              </Typography>

              {data?.budget && data?.currency ? (
                <Typography
                  variant="heading4"
                  color="primaryDark.600"
                  fontSize={{ md: '1.45rem', lg: '1.75rem' }}
                >
                  {formatCurrency(data?.budget as number, data?.currency as EnumCurrency) ?? '-'}
                </Typography>
              ) : (
                <Typography
                  variant="heading4"
                  color="primaryDark.600"
                  fontSize={{ md: '1.45rem', lg: '1.75rem' }}
                >
                  -
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default GeneralProjectDetails;
