import { Stack, Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import theme from '../../../theme';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import CustomButton from '../../../components/button/CustomButton';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

const sponaInNumbers: { value: string; title: string }[] = [
  {
    value: '10K+',
    title: 'Sellers',
  },
  {
    value: '133+',
    title: 'Countries',
  },
  {
    value: '40+',
    title: 'Digital Services',
  },
];
const HomepageHero = () => {
  const hasUser = localStorage.getItem('AccessToken') !== null;
  const navigate = useNavigate();

  const isLessThanGreat = useMediaQuery(theme.breakpoints.down('gr'));
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      spacing={3}
      justifyContent="center"
      sx={{ ...LAYOUT_DEFAULTS, marginTop: `${PUBLIC_APPBAR_HEIGHT + 40}px` }}
    >
      <Typography variant="publicBody2" sx={{ color: 'primaryLight.500' }}>
        Keep it together
      </Typography>
      {/* the keep it together text */}
      <Stack justifyContent="center" alignItems="center" width="fit-content">
        <Typography
          display={{ xs: 'grid', md: 'grid' }}
          sx={{
            typography: { xs: 'publicHeadline7', md: 'publicHeadline7', lg: 'publicHeadline7' },
            fontSize: { xs: '2.9rem', md: '4rem', lg: '4rem' },
            lineHeight: '106%',
          }}
        >
          Your Pre-Assembled Project{' '}
          <Typography
            component="span"
            sx={{
              position: 'relative',
              typography: { xs: 'publicHeadline7', md: 'publicHeadline7', lg: 'publicHeadline7' },
              fontSize: { xs: '2.9rem', md: '4rem', lg: '4rem' },
              lineHeight: '106%',
            }}
          >
            and Supplier Management Tool
            <Box
              sx={{
                backgroundColor: 'primaryLight.500',
                width: '100%',
                height: isLessThanMedium ? '0.5rem' : '1rem',
                borderRadius: '7rem',
                position: 'absolute',
                bottom: { xs: '-15px', md: '-20px' },
                left: 0,
                zIndex: -1,
              }}
            />
          </Typography>
        </Typography>
      </Stack>

      <Stack
        spacing={{ md: 8 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: '1.5rem' }}
      >
        {/* about us text & company icons */}
        <Stack spacing={6} maxWidth={{ xs: '100%', xl: '40%' }}>
          <Stack spacing={4}>
            <Typography variant="publicBody3Regular" sx={{ zIndex: 9 }}>
              Forget complex setups. With Spona, you can manage projects and suppliers instantly -
              simple, effective and all in one place.
            </Typography>

            {!hasUser && (
              <CustomButton
                variant="primary"
                withNextArrow
                sx={{
                  width: { xs: '55%', md: '45%' },
                  py: { xs: '18px' },
                }}
                onClick={() => navigate('/register')}
              >
                Get started
              </CustomButton>
            )}
          </Stack>

          {/* <Stack spacing={4} direction="row" justifyContent="flex-start" alignItems="start">
            {sponaInNumbers.map((item) => (
              <Stack
                spacing={1}
                sx={{
                  // width: { xs: '100%', sm: '45%', lg: '20%' },
                  textAlign: { xs: 'left', sm: 'left' },
                }}
              >
                <Typography variant="publicHeading5">{item.value}</Typography>

                <Typography width="100%" variant="publicBody6Regular">
                  {item.title}
                </Typography>
              </Stack>
            ))}
          </Stack> */}
        </Stack>

        {/* image */}
        <Stack
          display={{ xs: 'none', md: 'flex' }}
          sx={{ width: { xs: '100%', xl: '50%' } }}
          alignItems="flex-end"
        >
          <Box
            component="img"
            src={getPublicImageUrl(EnumPublicPagesImage.HomeE2E)}
            alt="Mock-up of a laptop showing a screenshot of Spona’s tool."
            sx={{ width: { md: '53vw', xl: '48vw' } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomepageHero;
