import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

declare global {
  interface Window {
    fcWidget: {
      init: (config: {
        token: string;
        host: string;
        widgetUuid: string;
        externalId: string;
        firstName: string;
        lastName: string;
        email: string;
        restoreId: {} | null;
      }) => void;
      destroy?: () => void;
      open?: () => void;
      close?: () => void;
      on: (event: string, callback: (resp: any) => void) => void;
    };
  }
}

const FreshchatWidget = () => {
  const { pathname } = useLocation();
  const shouldDisplayChat = !(pathname.includes('dashboard') || pathname.includes('admin'));

  const getUserDataFromLocalStorage = () => {
    const userDataString = localStorage.getItem('userData');
    return userDataString ? JSON.parse(userDataString) : null;
  };

  useEffect(() => {
    const scriptId = 'Freshchat-js-sdk';
    const restoreId = localStorage.getItem('restoreId');

    const initFreshChat = () => {
      const userData = getUserDataFromLocalStorage();

      window.fcWidget.on('user:created', (resp: any) => {
        const { status, data } = resp || {};
        if (status === 200 && data.restoreId) {
          localStorage.setItem('restoreId', data.restoreId);
        }
      });

      if (window.fcWidget) {
        window.fcWidget.init({
          token: process.env.REACT_APP_FRESHCHAT_TOKEN as string,
          host: process.env.REACT_APP_FRESHCHAT_HOST as string,
          widgetUuid: process.env.REACT_APP_FRESHCHAT_WIDGET_UUID as string,
          externalId: userData?.email,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          restoreId: restoreId || null,
        });
      }
    };

    const loadScript = () => {
      if (document.getElementById(scriptId)) {
        initFreshChat();
        return;
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.src = 'https://topdigitalagency.freshchat.com/js/widget.js';
      script.onload = () => {
        initFreshChat();
      };
      document.head.appendChild(script);
    };

    const destroyChat = () => {
      window.fcWidget?.destroy?.();
      const script = document.getElementById(scriptId);
      if (script) {
        document.head.removeChild(script);
      }
    };

    if (shouldDisplayChat) {
      loadScript();
    } else {
      destroyChat();
    }

    return () => {
      destroyChat();
    };
  }, [shouldDisplayChat]);

  return null;
};

export default FreshchatWidget;
