import { Divider, Stack, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface CompanyDetailSubsectionRowProps {
  row: ReactNode[];
  sx?: SxProps<Theme>;
}

const CompanyDetailSubsectionRow = ({ row, sx }: CompanyDetailSubsectionRowProps) => (
  <Stack spacing={1.5} direction="row" sx={{ ...sx }}>
    {row.map((rowItem, i) => (
      <Stack spacing={{ md: 1, lg: 1.5 }} direction="row" width="100%">
        {rowItem}

        {i + 1 !== row.length && <Divider orientation="vertical" />}
      </Stack>
    ))}
  </Stack>
);

export default CompanyDetailSubsectionRow;
