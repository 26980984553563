import { Stack } from '@mui/system';
import { Avatar, Typography } from '@mui/material';
import { Description, FiberManualRecord } from '@mui/icons-material';
import CustomButton from '../../../../../components/button/CustomButton';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { EnumTimedProjectPitchRejectFeedbackScope } from 'tdc-web-backend/enums/enums';
import PitchFeedbackInput from './PitchFeedbackInput';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { FeedbackInputType, FeedbackOutputType, RejectFormType } from '../../interface';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

const RejectSecondStepSecondRound = ({ setSliderPage, setIsOpen, pitchData }: RejectFormType) => {
  const refresh = useRefresh();
  const { data } = useReferenceOne<CompanyDto>({
    id: pitchData?.seller,
    resource: 'companies',
  });

  const { mutate: sendFeedback } = useUpdate({
    resource: '/timed-project-pitches',
  });

  const formMethods = useForm({
    defaultValues: {
      feedback: [],
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const typeArray = [
    EnumTimedProjectPitchRejectFeedbackScope.Overall,
    EnumTimedProjectPitchRejectFeedbackScope.Knowledge,
    EnumTimedProjectPitchRejectFeedbackScope.Strategy,
    EnumTimedProjectPitchRejectFeedbackScope.Budget,
  ];

  const handleSubmitReject = (data: any) => {
    const newData = data?.feedback.map((object: FeedbackOutputType, i: number) => {
      return {
        type: typeArray[i],
        rate: object.rating,
        description: object.description,
      };
    });
    sendFeedback(
      {
        id: pitchData.timedProjectPitch,
        data: newData,
        suffix: 'reject-feedback',
      },
      {
        onSuccess: () => {
          refresh();
          setIsOpen(false);
        },
      },
    );
  };

  const feedbackFields = [
    {
      title: 'OVERALL PRESENTATION',
      description: 'How effectively did the seller present their company and team?',
    },
    {
      title: 'KNOWLEDGE & INSIGHT',
      description: 'How well-informed was the seller about your business and industry?',
    },
    {
      title: 'PROJECT STRATEGY',
      description: "How satisfied were you with the seller's project strategy?",
    },
    {
      title: 'BUDGET',
      description: 'How would you rate the value for money offered by the seller?',
    },
  ];

  return (
    <Stack
      direction="column"
      sx={{
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitReject)}>
          <Stack direction="row" alignItems="center" mb={3} gap={1}>
            <Avatar sx={{ borderRadius: '8px' }} src={data?.avatar} alt="Company logo" />
            <Stack>
              <Typography variant="heading4" color="primaryDark.600">
                {data?.name}
              </Typography>
              <Typography variant="body3" color="primaryDark.600">
                Rate 2st round pitch
              </Typography>
            </Stack>
          </Stack>
          {/* 
            ratingName: `feedback[${index}].description`
            ratingName: `feedback[${index}].rating`

            

          */}

          {feedbackFields.map((item, index) => (
            <PitchFeedbackInput
              title={item.title}
              description={item.description}
              ratingInputName={`feedback[${index}].rating`}
              textInputName={`feedback[${index}].description`}
            />
          ))}
          <Stack gap={0.625} direction="row" justifyContent="center" my={3}>
            <FiberManualRecord fontSize="small" sx={{ color: '#C5C7C3' }} />
            <FiberManualRecord fontSize="small" sx={{ color: 'secondaryBlue.500' }} />
          </Stack>
          <Stack direction="row" gap={3} justifyContent="space-between">
            <CustomButton onClick={() => setIsOpen(false)} variant="primaryText">
              Leave feedback later
            </CustomButton>
            <CustomButton type="submit" variant="primary" disabled={!isValid}>
              submit
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default RejectSecondStepSecondRound;
