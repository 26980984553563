import Stack from '@mui/system/Stack/Stack';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  mapIndustry,
  mapLanguage,
  mapCountry,
  mapHourlyRate,
  mapProjectSize,
  mapService,
  mapCompanySize,
  EnumCurrency,
} from 'tdc-web-backend/enums/enums';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import ClientListModal from '../components/ClientListModal';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusNoBgIcon } from '../../../../assets/icons/button-icons/PlusNoBgIcon.svg';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import { TClient } from '../interface';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomFormHelperText from '../../../../components/custom-input/CustomFormHelperText';

const DetailsSeller = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    formState: { errors, isSubmitting },
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const { fields, remove } = useFieldArray({
    control,
    name: 'details.clients',
  });

  const openClientModal = () => {
    setValue('details.tmpClients', fields);
    setIsOpen(true);
  };

  // // Client form validation
  useEffect(() => {
    const clients: TClient[] = watch('details.clients');

    if (isSubmitting && !clients.length) {
      setError('details.clients', {
        types: {
          required: 'This is required',
          message: 'string',
        },
      });
    }

    if (clients.length) {
      clearErrors('details.clients');
    }
  }, [watch('details.clients'), isSubmitting]);

  const currency = watch('details.currency');

  return (
    <Stack spacing={2}>
      <BaseInputLabel text="company details" />

      <Stack flexDirection="row" sx={{ margin: '0 0 32px 0' }}>
        <Stack
          spacing={3}
          width="50%"
          pr={3}
          sx={{ borderRight: '1px solid', borderColor: 'secondaryBlue.100' }}
        >
          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.services"
            choices={mapService}
            placeholder="Select services"
            label="Offered services"
            required
            multiple
            withChips
          />

          <Stack>
            <BaseInputLabel text="Select operating currency" />

            <Stack direction="row">
              <CustomButton
                variant="secondary"
                sx={{
                  borderTopRightRadius: '0px !important',
                  borderBottomRightRadius: '0px !important',
                  borderColor: 'secondaryBlue.100',
                  fontSize: '12px',
                  color: currency === EnumCurrency.Usd ? 'primaryLight.500' : 'primaryDark.500',
                  boxShadow:
                    currency === EnumCurrency.Usd ? '6px 6px 15px 0px #3845721A' : 'initial',
                }}
                onClick={() => setValue('details.currency', EnumCurrency.Usd)}
              >
                USD
              </CustomButton>

              <CustomButton
                variant="secondary"
                sx={{
                  borderTopLeftRadius: '0px !important',
                  borderBottomLeftRadius: '0px !important',
                  borderColor: 'secondaryBlue.100',
                  fontSize: '12px',
                  color: currency === EnumCurrency.Eur ? 'primaryLight.500' : 'primaryDark.500',
                  boxShadow:
                    currency === EnumCurrency.Eur ? '6px 6px 15px 0px #3845721A' : 'initial',
                }}
                onClick={() => setValue('details.currency', EnumCurrency.Eur)}
              >
                EUR
              </CustomButton>
            </Stack>
          </Stack>

          <Stack>
            <BaseInputLabel text="Typical project size" />

            <Stack direction="row" alignItems="baseline">
              <Stack
                sx={{
                  width: 60,
                  height: 47,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid',
                  borderColor: 'primaryDark.250',
                  borderRightColor: 'transparent',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                }}
              >
                <Typography sx={{ px: 2, fontSize: '14px' }}>
                  {watch('details.currency').toUpperCase()}
                </Typography>
              </Stack>

              <CustomInput
                input={<CustomInput.Select />}
                name="details.averageProjectSize"
                placeholder="Select size"
                choices={mapProjectSize}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                  },
                }}
                required
              />
            </Stack>
          </Stack>

          <CustomInput
            input={<CustomInput.Select />}
            name="details.companySize"
            choices={mapCompanySize}
            placeholder="Select size"
            label="Company size"
            required
          />

          <Stack>
            <BaseInputLabel text="Hourly rate" />

            <Stack direction="row" maxHeight="fit-content" alignItems="baseline">
              <Stack
                sx={{
                  width: 60,
                  height: 47,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid',
                  borderColor: 'primaryDark.250',
                  borderRightColor: 'transparent',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                }}
              >
                <Typography sx={{ px: 2, fontSize: '14px' }}>
                  {watch('details.currency').toUpperCase()}
                </Typography>
              </Stack>

              <CustomInput
                input={<CustomInput.Select />}
                name="details.hourlyRate"
                placeholder="Select rate"
                choices={mapHourlyRate}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                  },
                }}
                required
              />
            </Stack>
          </Stack>

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.languages"
            label="Working languages"
            placeholder="Select languages"
            choices={mapLanguage}
            multiple
            withChips
            required
          />
        </Stack>

        <Stack spacing={3} width="50%" pl={3}>
          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.targetCountries"
            label="Target markets"
            placeholder="Select country"
            choices={mapCountry}
            required
            withChips
            multiple
          />

          <CustomInput
            input={<CustomInput.Autocomplete />}
            name="details.targetIndustries"
            label="company industry"
            placeholder="Select industry"
            choices={mapIndustry}
            required
            multiple
            withChips
          />

          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <BaseInputLabel text="your clients" />

              <CustomButton
                onClick={openClientModal}
                variant="primaryText"
                endIcon={<PlusNoBgIcon />}
              >
                add
              </CustomButton>
            </Stack>

            {errors && 'details' in errors && 'clients' in errors.details && (
              <CustomFormHelperText error={errors.details.clients}>
                This field is required
              </CustomFormHelperText>
            )}
          </Stack>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {(fields as any[])?.map((field, index) => (
              <CustomChip
                sx={{ maxWidth: 'fit-content' }}
                key={`${field.name}-${index}`}
                label={field.name as string}
                onDelete={() => remove(index)}
                icon={
                  <Avatar
                    sx={{ width: '20px', height: '20px' }}
                    src={field.avatar}
                    alt="Company logo"
                  />
                }
              />
            ))}
          </Box>
        </Stack>
      </Stack>

      <ClientListModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Stack>
  );
};

export default DetailsSeller;
