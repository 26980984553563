import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { Stack } from '@mui/material';
import { CompanyApprovalStatus, EnumScreenList } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { CompanyClientDto } from 'tdc-web-backend/company-client/schemas';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import BannerNotificationMapper from '../../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import SellerHomescreenContext from './SellerHomescreenContext';
import HelloBanner from './components/HelloBanner';
import DashboardCard from '../../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CompanyDetailSubsection from './components/CompanyDetailSubsection/CompanyDetailSubsection';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CompanyHomeCreateCS from './components/CompanyHomeCreateCaseStudy';
import ProjectPitchesTable from './components/ProjectPitchesTable';
import EarningsChart from './components/EarningsChart/EarningsChart';
import CustomButton from '../../../../components/button/CustomButton';

const CHIP_LABEL_TRUNCATE_VALUE = 20;

const SellerHomescreen = () => {
  const user = useGetLocalStorageUserData();
  const navigate = useNavigate();

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: user?.membership?.company,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!user?.membership?.company || false,
  });

  const { data: clients } = useGetMany<CompanyClientDto>({
    resource: `company-client/?company=${user?.membership?.company}`,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!user?.membership?.company || false,
  });
  const companyClients = clients?.data.results;

  const isCompanyStatusPendingOrRejected =
    companyData?.status === CompanyApprovalStatus.Pending ||
    companyData?.status === CompanyApprovalStatus.Rejected;

  const { data: caseStudies } = useGetMany<BaseCaseStudy>({
    resource: `case-studies/?company=${user?.membership?.company}`,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    enabled: !!user?.membership?.company || false,
  });

  const companyCaseStudies = caseStudies?.data.results;

  const projectPitchesTableAlone = companyCaseStudies?.some((item) => item.status === 'verified');

  return (
    <SellerHomescreenContext.Provider
      value={{
        companyData,
        user,
        companyClients,
        companyCaseStudies,
        chipLabelTruncateValue: CHIP_LABEL_TRUNCATE_VALUE,
      }}
    >
      <Stack spacing="10px">
        <HelloBanner />

        <BannerNotificationMapper screen={EnumScreenList.DashboardHome} />

        {/* main section layout */}
        <Stack width="100%" spacing={{ md: '20px', lg: '40px' }}>
          {/* earnings & project pitches row */}
          <Stack
            width="100%"
            direction={{ md: 'column', lg: 'row' }}
            spacing={{ md: '20px', lg: '24px' }}
          >
            {/* earnings */}
            <DashboardCard
              headlineEndElement={
                <CustomButton variant="secondary" onClick={() => navigate('./end-to-end')}>
                  E2E
                </CustomButton>
              }
              renderComponentSx={{ my: '32px !important' }}
              headline="Earnings"
              renderComponent={<EarningsChart />}
              // this fixed height here of 306px will dictate the height
              // of this entire stack; reason why fixed height here is because
              // of making space for the 'no records' part
              sx={{ position: 'relative', width: { md: '100%', xxl: '55%' }, minHeight: '306px' }}
            />

            {/* project pitches */}
            {projectPitchesTableAlone ? (
              <ProjectPitchesTable />
            ) : (
              <DashboardCard
                renderComponent={<CompanyHomeCreateCS />}
                sx={{ width: { md: '100%' }, p: 0, pt: 0, pb: 3.5 }}
                mainStackSpacing={0}
              />
            )}
          </Stack>

          {/* company details section - content */}
          <DashboardCard
            headline={
              isCompanyStatusPendingOrRejected ? (
                <StatusChipField text={companyData?.status as string} />
              ) : null
            }
            renderComponent={<CompanyDetailSubsection />}
            renderComponentSx={{ m: isCompanyStatusPendingOrRejected ? 'initial' : '0 !important' }}
            sx={{ width: { md: '100%' } }}
          />
        </Stack>
      </Stack>
    </SellerHomescreenContext.Provider>
  );
};

export default SellerHomescreen;
