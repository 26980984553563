import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useParams } from 'react-router-dom';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import ControlledSelectInput from '../../../../components/custom-inputs/ControlledSelectInput';

const SelectSellerStep = () => {
  const { caseStudyId } = useParams();

  const { data: sellerData } = useGetMany<CompanyDto>({
    resource: '/companies?role=seller_tool&role=seller_service&status=approved',
  });

  return (
    <ControlledSelectInput
      name="seller"
      label="Select seller"
      required
      disabled={!!caseStudyId}
      choices={sellerData?.data.results as any}
      placeholder="Select"
    />
  );
};

export default SelectSellerStep;
