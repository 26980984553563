import { Card, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import newProjectGraphic from '../../../../assets/img/timed-projects/new-project-graphic.png';
import { secondaryBlue } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/button-icons/PlusIcon.svg';

const NewProjectCard = (props: { imgHeight?: string }) => {
  const navigate = useNavigate();
  return (
    <Card
      elevation={0}
      sx={{
        width: '50%',
        height: '100%',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stack
        direction="row"
        pl={2}
        pr={2}
        minHeight="30%"
        height={props.imgHeight || '30%'}
        width="100%"
        justifyContent="space-evenly"
        sx={{
          backgroundColor: secondaryBlue[50],
        }}
      >
        <Box component="img" src={newProjectGraphic} height="100%" sx={{ objectFit: 'contain' }} />
      </Stack>
      <Typography variant="body3" color="primaryDark.600" mt={2}>
        Find a seller for your digital project
      </Typography>
      <CustomButton
        sx={{ m: 2 }}
        onClick={() => navigate('/dashboard/timed-projects/create')}
        variant="primaryLight"
        startIcon={<PlusIcon width="20px" height="20px" />}
      >
        new project
      </CustomButton>
    </Card>
  );
};

export default NewProjectCard;
