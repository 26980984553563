/* eslint-disable no-use-before-define */
import { Chip, Stack, Typography } from '@mui/material';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { mapCountry, mapIndustry, mapProjectSize, mapService } from 'tdc-web-backend/enums/enums';
import { formatDateFns } from '../../../../utils/helpers';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import { primaryDark } from '../../../../color';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../enums';

interface ChipGroupProps {
  label: string;
  choices: Map<string, string>;
  name: string;
}

const RightColumnRenderComponent = ({ form }: { form: UseFormReturn }) => (
  <Stack spacing={2}>
    <Stack
      direction="row"
      p={1.5}
      justifyContent="space-between"
      sx={{ backgroundColor: 'primaryDark.200', borderRadius: '3px' }}
    >
      <Typography variant="heading5" color="primaryDark.500">
        created
      </Typography>
      <Typography variant="heading5" color="primaryDark.600">
        {formatDateFns(new Date())}
      </Typography>
    </Stack>
    <ChipGroup label="Buyer industry" name="details.industries" choices={mapIndustry} />
    <ChipGroup label="Services" name="details.services" choices={mapService} />

    {form.getValues('details.platforms').length !== 0 && (
      <ChipGroup label="tech platforms" name="details.platforms" choices={mapTechPlatform} />
    )}
    {form.getValues('details.tools').length !== 0 && (
      <ChipGroup label="tech tools" name="details.tools" choices={mapTechTool} />
    )}
    {form.getValues('details.languages').length !== 0 && (
      <ChipGroup label="Programming languages" name="details.languages" choices={mapTechLanguage} />
    )}

    <ChipGroup label="Target market" name="details.geographyFocus" choices={mapCountry} />
    <Stack>
      <BaseInputLabel text="Project size" />
      <Stack direction="row" gap={1} flexWrap="wrap">
        {form.watch('details.projectSize') && (
          <Chip sx={chipStyle} label={mapProjectSize.get(form.watch('details.projectSize'))} />
        )}
      </Stack>
    </Stack>
  </Stack>
);

export default RightColumnRenderComponent;

const ChipGroup = ({ label, name, choices }: ChipGroupProps) => {
  const { watch } = useFormContext();
  return (
    <Stack>
      <BaseInputLabel text={label} />
      <Stack direction="row" gap={1} flexWrap="wrap">
        {watch(name)?.map((item: any) => (
          <Chip sx={chipStyle} label={choices.get(item)} />
        ))}
      </Stack>
    </Stack>
  );
};

const chipStyle = {
  padding: '6px 6px',
  fontSize: '11px',
  fontWeight: 600,
  color: primaryDark['500'],
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  lineHeight: '16px',
  borderRadius: '3px',
  background: primaryDark['200'],
};
