import { useForm, FormProvider } from 'react-hook-form';
import { Stack, Box } from '@mui/system';
import { Divider } from '@mui/material';
import { mapCountry, EnumCountry } from 'tdc-web-backend/enums/enums';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import CustomButton from '../../../../../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../../../../../assets/icons/button-icons/PlusIconCompanyEditModal.svg';
import { ReactComponent as GeoLoc } from '../../../../../../../assets/icons/geo-alt-fill.svg';
import CustomChip from '../../../../../../../components/custom-chip/CustomChip';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight } from '../../../../../../../color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { mapStates } from '../../../../../../../enums';

interface EditCompanyOverViewProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelButton: boolean;
}

interface FormValues extends CompanyDto {
  tempCountry: EnumCountry;
  tempCity: string;
  tempState: string;
}

const CompanyOfficeLocationsEditModal = ({
  isModalOpen,
  setIsModalOpen,
  showCancelButton,
}: EditCompanyOverViewProps) => {
  const refresh = useRefresh();
  const { companyData } = useSellerHomescreenContext();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      officeLocations: companyData?.officeLocations || [],
      tempCountry: '' as EnumCountry,
      tempCity: '',
      tempState: '',
    },
  });

  const { setValue, watch } = methods;

  const officeLocations = watch('officeLocations') || [];
  const tempCountryValue = watch('tempCountry');
  const tempCityValue = watch('tempCity');

  const handleAddOffice = () => {
    const { tempCountry, tempCity, tempState } = methods.getValues();

    const locationAlreadyExists = officeLocations.some(
      (location) =>
        location.country === tempCountry &&
        location.city === tempCity &&
        location.state === (tempState || ''),
    );

    if (locationAlreadyExists) {
      methods.setError('tempCity', { type: 'manual', message: 'Location already exists' });
      return;
    }

    if (tempCountry === EnumCountry.Us && !tempState) {
      methods.setError('tempState', { type: 'manual', message: 'State is required for the US' });
      return;
    }

    const newOfficeLocation = { country: tempCountry, city: tempCity, state: tempState || '' };
    const updatedLocations = [...officeLocations, newOfficeLocation];
    setValue('officeLocations', updatedLocations);
    setValue('tempCountry', '' as EnumCountry);
    setValue('tempCity', '');
    setValue('tempState', '');
    methods.clearErrors('officeLocations');
  };

  const handleRemoveOffice = (index: number) => {
    const updatedLocations = officeLocations.filter((_, i) => i !== index);
    setValue('officeLocations', updatedLocations);
  };

  const { mutate, isLoading } = useUpdate<ResponseType, CompanyDto>({
    resource: 'companies',
  });

  const onSubmit = () => {
    const officeLocations = methods.getValues('officeLocations');

    mutate(
      {
        id: companyData?.id as string,
        data: {
          ...(companyData as CompanyDto),
          officeLocations,
        },
      },
      {
        onSuccess: () => {
          refresh();
          setIsModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      confirmButtonText="Save"
      onCancel={() => setIsModalOpen(false)}
      onConfirm={() => onSubmit()}
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      title="Additional office locations"
      width="40%"
      iconProps={{
        icon: <GeoLoc fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      message={
        <form>
          <FormProvider {...methods}>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-start" />

            <Stack
              sx={{
                height: 'auto',
                maxHeight: '400px',
                overflowY: 'auto',
                fontSize: '12px',
                fontFamily: 'Gellix-SemiBold',
              }}
              spacing={2}
              pr="10px"
            >
              <Stack spacing={2}>
                <CustomInput
                  required
                  input={<CustomInput.Autocomplete />}
                  name="tempCountry"
                  choices={mapCountry}
                  label="Country"
                  placeholder="Select country"
                  sx={{ width: '100%', minHeight: '100%' }}
                />
                <CustomInput
                  required
                  input={<CustomInput.Text />}
                  name="tempCity"
                  label="City"
                  placeholder="Type city name"
                  sx={{ width: '100%', minHeight: '100%' }}
                />
                <CustomInput
                  input={<CustomInput.Autocomplete />}
                  name="tempState"
                  label="State"
                  choices={mapStates}
                  placeholder="Type state"
                  sx={{ width: '100%', minHeight: '100%' }}
                  disabled={methods.watch('tempCountry') !== 'us'}
                  required={methods.watch('tempCountry') === EnumCountry.Us}
                />
              </Stack>

              <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <CustomButton
                  variant="primaryLight"
                  sx={{ width: 'auto', mt: '10px', boxShadow: 'none' }}
                  onClick={handleAddOffice}
                  disabled={!tempCountryValue || !tempCityValue}
                  startIcon={<PlusIcon />}
                >
                  Add office
                </CustomButton>
              </Box>

              <Divider />
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {officeLocations?.map((location, index) => (
                  <CustomChip
                    label={
                      location.state
                        ? `${location.city}, ${location.state}, ${
                            location.country ? mapCountry.get(location.country) : ''
                          }`
                        : `${location.city}, ${
                            location.country ? mapCountry.get(location.country) : ''
                          }`
                    }
                    sx={{
                      width: 'fit-content',
                      textTransform: 'capitalize',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onDelete={() => handleRemoveOffice(index)}
                  />
                ))}
              </Stack>
            </Stack>
          </FormProvider>
        </form>
      }
      showCancelButton={showCancelButton}
    />
  );
};
export default CompanyOfficeLocationsEditModal;
