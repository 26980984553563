import { useDroppable } from '@dnd-kit/core';
import { Paper, Stack } from '@mui/material';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { TimedProjectPitchContentCustomDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import PitchesPanelCard from './PitchesPanelCard';

const PitchesPanelColumnsContent = ({
  id,
  pitches,
  disabled,
}: {
  id: string;
  pitches: TimedProjectPitchContentCustomDto[];
  disabled?: boolean;
}) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext disabled={disabled} id={id} items={pitches} strategy={rectSortingStrategy}>
      <Stack spacing={2} ref={setNodeRef}>
        {pitches.length > 0 ? (
          pitches.map((pitch: TimedProjectPitchContentCustomDto) => (
            <PitchesPanelCard key={pitch.id} pitch={pitch} />
          ))
        ) : (
          <Paper
            sx={{ p: 2, borderRadius: '8px', border: '1px dashed #BFC6D7', height: '141px' }}
            variant="outlined"
          />
        )}
      </Stack>
    </SortableContext>
  );
};

export default PitchesPanelColumnsContent;
