import { BubbleMenu, Editor } from '@tiptap/react';
import {
  Button,
  Divider,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

export interface LinkBubbleMenuProps {
  editor: Editor | null;
}

const LinkBubbleMenu = ({ editor }: LinkBubbleMenuProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  const selectLink = () => {
    const selectionIsEmpty = editor?.view.state.selection.empty;
    if (selectionIsEmpty) {
      editor?.chain().focus().extendMarkRange('link').run();
    }
  };

  useEffect(() => {
    if (editor?.isActive('link')) {
      const previousUrl = editor?.getAttributes('link').href;

      if (!previousUrl || previousUrl === '') {
        selectLink();
        if (!isEditing) setIsEditing(true);
      } else if (previousUrl && previousUrl !== '') {
        selectLink();
      }
    } else if (isEditing) setIsEditing(false);
  });

  const removeLink = () => {
    editor?.chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run();
    setUrl(null);
  };

  const startEditing = () => {
    if (!editor) { return; }
    const previousUrl = editor.getAttributes('link').href;

    setUrl(previousUrl);
    setIsEditing(true);
    setSaveEnabled(url !== '');
  };

  const cancelEditing = () => {
    if (!editor) { return; }
    setIsEditing(false);
    const previousUrl = editor.getAttributes('link').href;
    if (previousUrl === '' || previousUrl === null) {
      removeLink();
    }
  };

  const setLink = () => {
    if (!editor) { return; }

    // Cancel when no url
    if (url === null) {
      removeLink();
      return;
    }

    // Make sure that url is not set when there is no value
    if (url === '') {
      removeLink();
      return;
    }

    // Set link
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url, target: '_blank' })
      .run();

    setUrl(null);
    setIsEditing(false);
  };

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
      <Paper sx={{ display: editor.isActive('link') || isEditing ? 'block' : 'none', p: 1 }} elevation={1}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {
            isEditing
            && (
              <TextField
                id="link-input"
                label=""
                size="small"
                placeholder="Enter url"
                value={url}
                onChange={(event) => {
                  const text = event.target.value;
                  setUrl(text);
                  setSaveEnabled(text !== '');
                }}
              // inputRef={(input) => input && input.focus()}
              />
            )
          }

          {
            isEditing
            && (
              <Button
                variant="text"
                onClick={setLink}
                disabled={!saveEnabled}
              >
                Save
              </Button>
            )
          }

          {
            isEditing
            && (
              <Button
                variant="text"
                onClick={cancelEditing}
              >
                Cancel
              </Button>
            )
          }

          {
            (editor.isActive('link') && !isEditing)
            && (
              <Typography variant="caption" component="p">
                Link:
                <Link target="_blank" href={editor.getAttributes('link').href} rel="noreferrer">{editor.getAttributes('link').href?.substr(0, 40)}</Link>
              </Typography>
            )
          }

          {
            (editor.isActive('link') && !isEditing)
            && (
              <Button
                variant="text"
                onClick={startEditing}
              >
                Edit Link
              </Button>
            )
          }

          {
            (editor.isActive('link') && !isEditing)
            && (
              <Divider
                orientation="vertical"
                flexItem
              />
            )
          }

          {
            (editor.isActive('link') && !isEditing)
            && (
              <Button
                variant="text"
                onClick={removeLink}
              >
                Remove Link
              </Button>
            )
          }

        </Stack>
      </Paper>
    </BubbleMenu>
  );
};

export default LinkBubbleMenu;
