import { Box, Stack } from '@mui/system';
import { Avatar, Divider, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomModal from '../../../../components/modal/CustomModal';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ClipboardCheckIcon } from '../../../../assets/icons/layout-icons/ClipboardCheckIcon.svg';
import { secondaryBlue } from '../../../../color';
import { ClientListModalProps } from '../interface';
import ClientForm from './ClientForm';
import CustomChip from '../../../../components/custom-chip/CustomChip';

const ClientListModal = ({ isOpen, setIsOpen }: ClientListModalProps) => {
  const { control, watch, setValue } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'details.tmpClients',
  });

  return (
    <CustomModal open={isOpen} width="50%">
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Stack
            width={40}
            height={40}
            mr={2}
            bgcolor="secondaryBlue.100"
            borderRadius={1}
            justifyContent="center"
            alignItems="center"
          >
            <ClipboardCheckIcon fill={secondaryBlue[700]} />
          </Stack>
          <Typography variant="heading5">Clients</Typography>
        </Stack>
        <Divider />
        <ClientForm append={append} />
        <Divider />
        <Stack>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {(fields as any[])?.map((field, index) => (
              <CustomChip
                sx={{ maxWidth: 'fit-content' }}
                key={`${field.name}-${index}`}
                label={field.name as string}
                onDelete={() => remove(index)}
                icon={
                  <Avatar
                    sx={{ width: '20px', height: '20px' }}
                    src={field.avatar}
                    alt="Company logo"
                  />
                }
              />
            ))}
          </Box>
        </Stack>
        <Divider />
        <Stack direction="row" sx={{ justifyContent: 'right' }} spacing={2}>
          <CustomButton
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setValue('details.clients', watch('details.tmpClients'));
              setIsOpen(false);
            }}
          >
            Save
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ClientListModal;
