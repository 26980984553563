import { useFormContext, Controller } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import BaseTextInput from './BaseTextInput';

type Props = {
  name: string;
  required?: boolean;
  labelAdornment?: JSX.Element | null;
  helperStyles?: SxProps<Theme>;
} & TextFieldProps;

const ControlledTextInput = ({ name, required, labelAdornment, sx, type, ...props }: Props) => {
  const { control } = useFormContext();
  const convertToNumber = type === 'number';
  return (
    <Controller
      control={control}
      name={name}
      rules={required ? { required: 'This field is required' } : { required: false }}
      render={({ field }) => (
        <BaseTextInput
          onChange={({ target: { value } }) => field.onChange(convertToNumber ? +value : value)}
          field={field}
          name={name}
          required={required}
          labelAdornment={labelAdornment}
          sx={{ ...sx }}
          {...props}
        />
      )}
    />
  );
};

export default ControlledTextInput;
