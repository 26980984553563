import { Stack } from '@mui/system';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';

const CustomizedAdminStep = () => (
  <Stack spacing={5} mt={1} width="100%">
    <Stack direction="row" spacing={4} width="100%" pr={3}>
      <ControlledTextInput
        required
        name="admin.firstName"
        placeholder="Enter first name"
        label="First name"
        sx={{ width: '100%' }}
      />

      <ControlledTextInput
        required
        name="admin.lastName"
        placeholder="Enter last name"
        label="Last name"
        sx={{ width: '100%' }}
      />
    </Stack>

    <Stack direction="row" spacing={4} width="100%" pr={3}>
      <ControlledTextInput
        required
        name="admin.email"
        placeholder="Enter email"
        label="User email"
        sx={{ width: '100%' }}
      />

      <ControlledTextInput
        name="admin.jobTitle"
        placeholder="Enter job title"
        label="Job title"
        sx={{ width: '100%' }}
      />
    </Stack>
  </Stack>
);

export default CustomizedAdminStep;
