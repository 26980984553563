import { Card, Typography } from '@mui/material';
import { ReactComponent as QuestionMarkIconRounded } from '../../../../assets/icons/layout-icons/QuestionMarkIconRounded.svg';
import { primaryLight } from '../../../../color';
import CustomButton from '../../../../components/button/CustomButton';

const SidebarHelpBanner = () => {
  return (
    <Card
      className="event_slider"
      elevation={0}
      sx={{
        width: '100%',
        height: 'fit-content',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1.5,
        p: 2,
        background: 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)',
      }}
    >
      <QuestionMarkIconRounded fill={primaryLight[500]} />
      <Typography variant="headline4" color="primaryDark.600">
        Need help?
      </Typography>
      <Typography variant="body2" color="primaryDark.600" textAlign={'center'}>
        Our support team is at your disposal
      </Typography>
      <CustomButton href="/dashboard/chat?showSupport=true">Talk to us</CustomButton>
    </Card>
  );
};

export default SidebarHelpBanner;
