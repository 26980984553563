import { Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { TimedProjectPitchContentDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { secondaryBlue } from '../../color';
import CustomButton from '../button/CustomButton';
import ClientReview from './ClientReview';

interface ClientReviewsProps {
  feedback: TimedProjectPitchContentDto['feedback'];
}

const ClientReviews = ({ feedback }: ClientReviewsProps) => {
  const [showDetailedView, setShowDetailedView] = useState(false);

  return (
    <Stack spacing={2}>
      {feedback.length === 1 && (
        <Stack spacing={3} direction="row" justifyContent="space-between">
          <ClientReview
            detailedView={showDetailedView}
            title={feedback?.[0].title}
            rating={feedback?.[0].rate}
            description={feedback?.[0].description}
          />
        </Stack>
      )}
      {feedback.length !== 1 && (
        <Stack spacing={3} direction="row" justifyContent="space-between">
          <Stack spacing={2}>
            <ClientReview
              detailedView={showDetailedView}
              title={feedback?.[0].title}
              rating={feedback?.[0].rate}
              description={feedback?.[0].description}
            />

            <ClientReview
              detailedView={showDetailedView}
              title={feedback?.[1].title}
              rating={feedback?.[1].rate}
              description={feedback?.[1].description}
            />
          </Stack>

          <Divider orientation="vertical" sx={{ borderColor: secondaryBlue[100] }} />

          <Stack spacing={2}>
            <ClientReview
              detailedView={showDetailedView}
              title={feedback?.[2].title}
              rating={feedback?.[2].rate}
              description={feedback?.[2].description}
            />

            <ClientReview
              detailedView={showDetailedView}
              title={feedback?.[3].title}
              rating={feedback?.[3].rate}
              description={feedback?.[3].description}
            />
          </Stack>
        </Stack>
      )}

      <CustomButton
        onClick={() => {
          setShowDetailedView(!showDetailedView);
        }}
        sx={{
          alignSelf: 'center',
          color: 'primaryDark.500',
          textTransform: 'uppercase',
          my: 1,
          mx: 'auto',
        }}
        variant="secondaryText"
      >
        {showDetailedView ? 'close detailed view' : 'show detailed view'}
      </CustomButton>
    </Stack>
  );
};

export default ClientReviews;
