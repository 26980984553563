import { Divider, Stack, Typography } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../../assets/icons/chat-icons/trash2-fill.svg';
import CustomButton from '../../../components/button/CustomButton';
import CustomModal from '../../../components/modal/CustomModal';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import FramedIcon from '../FramedIcon';
import useDeleteOne from '../../../utils/hooks/crud-hooks/useDeleteOne';

type DeleteChannelModalProps = {
  open: boolean;
  onClose?: () => void;
  channelId: string;
};

export default ({ open, onClose, channelId }: DeleteChannelModalProps) => {
  const refresh = useRefresh();

  const { mutate } = useDeleteOne({
    resource: 'channels',
  });
  const onSubmit = () => {
    mutate(
      {
        id: channelId,
      },
      {
        onSuccess: () => {
          refresh();
          onClose?.();
        },
      },
    );
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            <FramedIcon Icon={TrashIcon} variant="pink" />
            <Typography variant="heading3">Delete channel</Typography>
          </Stack>
          <Divider />
          <Typography variant="body2" color="primaryDark.600">
            Are you sure you want to delete this channel?
          </Typography>
          <Divider />
          <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
            <CustomButton variant="secondary" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton onClick={onSubmit}>Confirm</CustomButton>
          </Stack>
        </Stack>
      </CustomModal>
    </>
  );
};
