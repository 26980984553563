import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { mapCurrency } from 'tdc-web-backend/enums/enums';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import ControlledRadioInput from '../../../../components/custom-inputs/ControlledRadioInput';
import CustomInput from '../../../../components/custom-input/CustomInput';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';

// TODO: Bug - it saves the boolean value as a string 'false' / 'true'. Probably it caused by RHF
export const mapProjectTypes = new Map([
  [false, 'One Time'],
  [true, 'Recurring'],
]);

const Budget = ({ type }: { type?: string }) => {
  const { getValues } = useFormContext();
  return (
    <Box sx={{ position: 'relative' }}>
      <BaseInputLabel required text="Maximum budget" />
      <Stack direction="row" maxHeight="fit-content">
        <CustomInput
          input={<CustomInput.Select />}
          name="budget.currency"
          choices={mapCurrency}
          sx={{
            maxWidth: 'fit-content',
          }}
        />
        <CustomInput
          input={<CustomInput.Text />}
          name="budget.budget"
          sx={{ minHeight: 'none', flex: 1 }}
          type="number"
          required
        />
      </Stack>
      <ControlledTextEditor
        sx={{ margin: '24px 0 24px 0' }}
        label="Budget description"
        name="budget.budgetDescription"
        items={TimedProjectEditorMenu}
        required
      />
      <Divider
        orientation="horizontal"
        sx={{ borderColor: '#E5E8FF', margin: '24px 0' }}
        flexItem
      />
      <ControlledRadioInput
        name="budget.recurring"
        label="select your project type:"
        options={mapProjectTypes}
        required
      />
    </Box>
  );
};

export default Budget;
