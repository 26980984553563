import {
  Stack, Avatar, Typography, Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import ContentSidebarInfoCard from '../../components/content-sidebar-info-card/ContentSidebarInfoCard';
import Editor from '../../components/editor/Editor';
import ContentTypeEnum from '../../utils/enums/content-type';
import Container from '../../layout/public-layout/container/Container';

export interface ArticleDetailProps {
  articleCreationData?: Record<string, any>;
}

const ArticleDetail = ({ articleCreationData }: ArticleDetailProps) => {
  const todaysDate = new Date().toDateString().slice(4, 16);

  const params = useParams();

  const [
    companyProfileData, setCompanyProfileData,
  ] = useState<any | null>(null);

  const [articleData, setArticleData] = useState<Record<string, any>>({});
  const [totalReadingTime, setTotalReadingTime] = useState<number>(0);

  useEffect(() => {
    if (articleCreationData) {
      setArticleData(articleCreationData);
    }
  }, [articleCreationData]);

  return (
    <Container xs sm lg sx={{ mt: 6 }}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        {/* left part */}
        <Stack spacing={3} width="70%">
          <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Stack height="100%" width="100%" spacing={3} direction="row" alignItems="center">
              <Avatar
                alt={companyProfileData?.images?.logo?.altText as string}
                src={companyProfileData?.images?.logo?.uri}
                sx={{ width: 150, height: 150 }}
              />

              <Stack height="100%" width="100%" justifyContent="space-between">
                <Box>
                  <Typography variant="h4" fontWeight={500} fontSize="1.45rem">
                    {companyProfileData?.title}
                  </Typography>

                  <Typography color="grey.600">
                    {articleData && articleData?.authorName}
                  </Typography>
                </Box>

                <Stack spacing={2} direction="row" justifyContent="end">
                  <LinkedInIcon sx={{ fontSize: '1.75rem', cursor: 'pointer' }} />
                  <FacebookOutlinedIcon sx={{ fontSize: '1.75rem', cursor: 'pointer' }} />
                  <TwitterIcon sx={{ fontSize: '1.75rem', cursor: 'pointer' }} />
                </Stack>

                <Stack width="100%" direction="row" justifyContent="space-between">
                  <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                    {
                      articleData && articleData?.articleTypes
                      && (
                        articleData?.articleTypes.map((articleType: string, index: number) => (
                          <>
                            <Typography>
                              {articleType}
                            </Typography>

                            {
                              index + 1 !== articleData.articleTypes.length
                                ? (
                                  <Box sx={{
                                    width: 7,
                                    height: 7,
                                    bgcolor: 'grey.600',
                                    borderRadius: '50%',
                                  }}
                                  />
                                ) : (null)
                            }
                          </>
                        ))
                      )
                    }

                    {/* <Typography>
                    {articleData && article }
                  </Typography> */}
                    <Box sx={{
                      width: 7,
                      height: 7,
                      bgcolor: 'grey.600',
                      borderRadius: '50%',
                    }}
                    />

                    <Typography color="grey.600">
                      {todaysDate}
                    </Typography>

                    <Box sx={{
                      width: 7, height: 7, bgcolor: 'grey.600', borderRadius: '50%',
                    }}
                    />

                    <Typography color="grey.600">
                      {
                        totalReadingTime && totalReadingTime
                      }
                      {' '}
                      min read
                    </Typography>
                  </Stack>

                  <Typography color="grey.600">
                    Awaiting client review
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Box
            component="img"
            src={
              articleData?.files?.banner?.uri
              ?? articleData?.files?.banner?.toUpload?.file?.preview
            }
            width="100%"
            height={500}
            sx={{ objectFit: 'cover' }}
          />

          <Typography variant="h2">
            {articleData?.title}
          </Typography>

          <Stack>
            {articleData && (
              <Editor
                key={uuidv1()}
                items={[]}
                isEditable={false}
                setReadingTime={setTotalReadingTime}
                content={articleData?.body?.articleBody}
              />
            )}
          </Stack>
        </Stack>

        <Box
          sx={{
            position: 'sticky', top: 90, width: '23%', minHeight: 300, height: '100%', pb: 3,
          }}
        >
          {
            articleData && (
              <ContentSidebarInfoCard
                contentType={ContentTypeEnum.Article}
                services={articleData?.services}
                industries={articleData?.industries}
                techLanguages={articleData?.techLanguages}
                techPlatforms={articleData?.techPlatforms}
                techTools={articleData?.techTools}
              />
            )
          }
        </Box>
      </Stack>
    </Container>
  );
};

export default ArticleDetail;
