enum EntityTypeEnum {
  Service = 'service',
  Industry = 'industry',
  TechLanguage = 'tech language',
  TechPlatform = 'tech platform',
  TechTool = 'tech tool',
  Country = 'country',
  ProjectSize = 'project size',
}

export default EntityTypeEnum;
