import { Stack } from '@mui/material';
import BaseInputLabel from '../../../../components/custom-inputs/BaseInputLabel';
import CustomChip from '../../../../components/custom-chip/CustomChip';

interface ChipGroupProps {
  label: string;
  choices: Map<string, string>;
  values: any;
}

const ChipGroup = ({ label, choices, values }: ChipGroupProps) => (
  <Stack flex={1}>
    <BaseInputLabel text={label} />
    <Stack direction="row" gap={1} flexWrap="wrap">
      {values?.map((item: any) => (
        <CustomChip label={choices.get(item)} />
      ))}
    </Stack>
  </Stack>
);

export default ChipGroup;
