import defaultIcon from '../../assets/icons/services/icon-service-default.svg';
import CsharpIcon from '../../assets/icons/tech-languages/icon-technology-expertise-c.svg';
import ElixirIcon from '../../assets/icons/tech-languages/icon-technology-expertise-elixir.svg';
import JavaIcon from '../../assets/icons/tech-languages/icon-technology-expertise-java.svg';
import JavaScriptIcon from '../../assets/icons/tech-languages/icon-technology-expertise-javascript.svg';
import PHPIcon from '../../assets/icons/tech-languages/icon-technology-expertise-php.svg';
import PythonIcon from '../../assets/icons/tech-languages/icon-technology-expertise-python.svg';
import SwiftIcon from '../../assets/icons/tech-languages/icon-technology-expertise-swift.svg';

const mapIconTechLanguage = new Map([
  ['Default', defaultIcon],
  ['Csharp', CsharpIcon],
  ['Elixir', ElixirIcon],
  ['Java', JavaIcon],
  ['JavaScript', JavaScriptIcon],
  ['PHP', PHPIcon],
  ['Python', PythonIcon],
  ['Swift', SwiftIcon],
]);

export default mapIconTechLanguage;
