import {
  Paper, Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { secondaryBlue } from '../../../color';

const RecurringMonthlyBudgetDisplay = ({
  index,
}: { index: number }) => {
  const { control } = useFormContext();

  const budgetAmount = useWatch({
    name: `body.milestones.${index}.budget`,
    control,
  });

  const months = useWatch({
    name: `body.milestones.${index}.duration`,
    control,
  });

  const milestoneReocurringBudget = (amount: number) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
  }).format(Math.abs(amount));

  return (
    <Paper
      elevation={0}
      sx={{
        px: 2,
        py: 0.5,
        backgroundColor: 'secondaryBlue.100',
        borderRadius: 1,
        width: 'fit-content',
      }}
    >
      <Typography fontSize="16px" color={secondaryBlue[700]} fontWeight={500}>
        {`${months ? milestoneReocurringBudget(budgetAmount / months) : milestoneReocurringBudget(budgetAmount)} / month`}
      </Typography>
    </Paper>
  );
};

export default RecurringMonthlyBudgetDisplay;
