import { parseISO } from 'date-fns';
import { DateCalendar, LocalizationProvider, DateCalendarProps } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import DateAdapter from './CustomAdapter';

const styles = {
  '.MuiDateCalendar-root': {
    boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
    borderRadius: '16px',
  },
  '.MuiDayCalendar-weekDayLabel': {
    color: '#79829B',
    fontFamily: 'Gellix-SemiBold',
  },
  '.MuiPickersDay-root': {
    fontSize: '14px',
    fontFamily: 'Gellix-SemiBold',
    color: 'primaryDark.600',
  },
  '.MuiPickersDay-dayOutsideMonth': {
    color: '#BFC6D7 !important',
  },
  '.MuiPickersCalendarHeader-label': {
    fontSize: '16px',
  },
  '.Mui-selected': {
    backgroundColor: '#E3DBFC !important',
    color: '#000000 !important',
    '&:hover': {
      backgroundColor: '#E3DBFC !important',
    },
  },
  '.MuiPickersDay-today': {
    color: '#6D51F6',
    border: 'none !important',
  },
};

function CustomDateCalendar<T extends Date | string>({ ...props }: DateCalendarProps<T>) {
  const value = typeof props.value === 'string' ? (parseISO(props.value) as T) : props.value;

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Box sx={{ ...styles }}>
        <DateCalendar
          {...props}
          views={['day']}
          dayOfWeekFormatter={(day) => day.toUpperCase()} // we need it to take the values from 'getWeekdays'
          showDaysOutsideCurrentMonth
          disablePast
          value={value}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default CustomDateCalendar;
