import { useForm, FormProvider } from 'react-hook-form';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { TimedProjectEditorMenu } from '../../../../../../../components/editor/menu-option-lists/menu-item-lists';
import { ReactComponent as ClipboardCheckIcon } from '../../../../../../../assets/icons/layout-icons/ClipboardCheckIconEditModal.svg';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight } from '../../../../../../../color';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import ControlledTextEditor from '../../../../../../../components/custom-inputs/ControlledTextEditor';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';

interface EditCompanyAboutProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditCompanyAbout = ({ isModalOpen, setIsModalOpen }: EditCompanyAboutProps) => {
  const refresh = useRefresh();
  const { companyData } = useSellerHomescreenContext();

  const methods = useForm<Partial<CompanyDto>>({
    mode: 'onChange',
    defaultValues: {
      description: companyData?.description,
    },
  });

  const { mutate, isLoading } = useUpdate<ResponseType, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Partial<CompanyDto>) => {
    mutate(
      {
        id: companyData?.id as string,
        data: data,
      },
      {
        onSuccess: () => {
          refresh();
          setIsModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onCancel={() => setIsModalOpen(false)}
      onConfirm={methods.handleSubmit(onSubmit)}
      automaticallyCloseModalOnButtonClick={false}
      confirmButtonText="Save"
      title="About your company"
      width={'40%'}
      isLoading={isLoading}
      iconProps={{
        icon: <ClipboardCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: '#E3DBFC',
      }}
      message={
        <FormProvider {...methods}>
          <ControlledTextEditor name="description" items={TimedProjectEditorMenu} required />
        </FormProvider>
      }
    />
  );
};

export default EditCompanyAbout;
