import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { CompanyDto, UpdateCompanyDto } from 'tdc-web-backend/companies/schemas';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Stack, Typography } from '@mui/material';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import GeneralInfoForm from './GeneralInfoForm';
import CompanyDefaultValues from '../CompanyDefaultValues';
import DetailFormService from './DetailFormService';
import DetailFormBuyer from './DetailFormBuyer';
import DetailFormTool from './DetailFormTool';
import LoadingButton from '../../../../components/button/LoadingButton';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';

const AdminCompanyEdit = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const formMethods = useForm<UpdateCompanyDto>({
    mode: 'onBlur',
    defaultValues: CompanyDefaultValues as UpdateCompanyDto,
  });
  const { handleSubmit, reset, formState, getValues } = formMethods;

  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: companyId,
    onSuccess: (data) => {
      reset(
        {
          ...data?.[0],
        },
        { keepTouched: true },
      );
    },
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, UpdateCompanyDto>({
    resource: '/companies',
  });

  const handleSubmitEdit = (data: FieldValues) => {
    const { avatar, ...dataNew } = data;

    if (formState.dirtyFields.hasOwnProperty('avatar')) dataNew.avatar = avatar;

    update(
      {
        data: dataNew,
        id: companyData?.id as string,
      },
      {
        onSuccess: () => {
          navigate(`../${companyData?.id}`);
        },
      },
    );
  };

  const role = companyData?.roles[0];

  return (
    <Stack sx={{ mx: 10 }} spacing={3}>
      <Typography variant="h4" sx={{ textAlign: 'center', mt: 2 }}>
        Edit company
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitEdit)}>
          <Stack spacing={2}>
            <GeneralInfoForm />
            {role === CompanyRole.SellerService && <DetailFormService />}
            {role === CompanyRole.Buyer && <DetailFormBuyer />}
            {role === CompanyRole.SellerTool && <DetailFormTool />}
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <LoadingButton
                variant="outlined"
                setBorderRadius
                size="large"
                onButtonClick={() => navigate(-1)}
                color="secondary"
              >
                Back
              </LoadingButton>
              <LoadingButton
                variant="contained"
                size="large"
                color="secondary"
                setBorderRadius
                onButtonClick={() => null}
                type="submit"
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default AdminCompanyEdit;
