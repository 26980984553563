import { useEffect, useState } from 'react';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'slug';
import { primaryLight } from '../../../color';
import CustomInput from '../../../components/custom-input/CustomInput';
import Thumbnail from './components/Thumbnail';
import Sections from './components/Sections/Sections';
import { getBase64 } from '../../../utils/helpers';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import LeadText from './components/LeadText';
import Slug from './components/Slug';
import Author from './components/Author';
import ActionPanel from './components/ActionPanel';
import Categories from './components/Categories';
import useDeleteOne from '../../../utils/hooks/crud-hooks/useDeleteOne';
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator';

const BlogCreateEdit = () => {
  const [tempSlug, setTempSlug] = useState('');
  const params = useParams();
  const blogId = params?.blogId || '';
  const editMode = !!blogId.length;
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  const form = useForm({
    defaultValues: {
      headline: '',
      fileName: '',
      file: undefined,
      leadText: '',
      sections: [{ subtitle: '', body: '' }],
      slug: '',
      visibility: true,
      author: '',
      categories: [],
    },
    mode: 'onSubmit',
  });

  const { data: blogData } = useReferenceOne<BlogDto>({
    id: blogId,
    resource: 'blogs',
    enabled: !!blogId,
  });

  const { mutate: create, isLoading: isLoadingCreate } = useCreate({ resource: 'blogs' });
  const { mutate: update, isLoading: isLoadingUpdate } = useUpdate({ resource: 'blogs' });
  const { mutate: deleteBlog } = useDeleteOne({ resource: 'blogs' });

  const { refetch } = useGetOne({
    resource: `blogs/${tempSlug}/exist`,
    enabled: false,
    onSuccess: (response) => {
      if (!response.data) {
        form.setValue('slug', tempSlug);
      } else {
        setTempSlug(`${tempSlug}-2`);
      }
    },
  });

  useEffect(() => {
    if (tempSlug) {
      refetch();
    }
  }, [tempSlug]);

  useEffect(() => {
    const resetForm = async () => {
      if (blogData) {
        let file: File | undefined;
        const response = await fetch(blogData.file);
        const blob = await response.blob();

        if (blogData.file) {
          file = new File([blob], blogData.fileName);
        }
        const newBlogData = {
          ...blogData,
          file: file as undefined, // Explicitly set the type of 'file' as 'File | undefined'
          sections: [...blogData.sections], // Add the required 'sections' property
          slug: blogData.slug || '',
          categories: blogData.categories.map((category: any) => category.id),
          leadText: blogData.leadText,
          author: blogData.author?.id,
        };

        form.reset(newBlogData);
        form.trigger();
        setShowContent(true);
      }
    };
    resetForm();
  }, [blogData]);

  const onSubmit = async (formData: FieldValues, isDraft = false) => {
    const { file, fileName, slug, author, ...rest } = formData;
    const base64 = await (file ? getBase64(file) : null);

    const postData = {
      ...rest,
      ...(form.getFieldState('file').isDirty && file && { file: base64, fileName }),
      ...(slug === '' && isDraft ? {} : { slug }),
      ...(form.getValues('author') === '' ? {} : { author }),
      isDraft,
    };

    const eventHandlers = {
      onSuccess: (e: any) => {
        if (blogId) {
          navigate(`../view`, { relative: 'path' });
        } else {
          navigate(`../${e.data.id}/view`, { relative: 'path' });
        }
      },
      onError: (err: any) => {
        navigate('../');
      },
    };
    if (blogId) {
      update({ id: blogId, data: postData }, eventHandlers);
    } else {
      create(postData, eventHandlers);
    }
  };

  const onPublish = (formData: FieldValues) => {
    onSubmit(formData, false);
  };

  const saveAsDraft = (formData: FieldValues) => {
    onSubmit(formData, true);
  };

  const onDelete = () => {
    deleteBlog({ id: blogId });
    navigate('../');
  };
  return (
    <FormProvider {...form}>
      <form>
        <Stack
          sx={{
            padding: '1.5rem 1.5rem 0 1.5rem',
            borderRadius: '8px',
            border: '1px solid',
            borderColor: primaryLight[50],
            minHeight: '100%',
            alignItems: 'stretch',
          }}
          divider={<Divider orientation="horizontal" />}
        >
          <Stack sx={{ height: '61px' }} direction="row" justifyContent="space-between">
            <Typography variant="headline3" sx={{ marginTop: '6px' }} color="#384572">
              {editMode ? 'Edit blog' : 'Create blog'}
            </Typography>
          </Stack>
          {showContent || !editMode ? (
            <Stack
              direction="row"
              justifyContent="space-evenly"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box sx={{ width: '100%', padding: '1.5em 1.5em 1.5em 0' }}>
                <Stack spacing={3}>
                  <Author />
                  <Categories />
                  <CustomInput
                    input={<CustomInput.Text />}
                    name="headline"
                    type="text"
                    placeholder="Enter blog headline"
                    label="headline"
                    required
                    sx={{ paddingBottom: '1.25rem' }}
                    onBlur={(e) => {
                      const slug = form.getValues('slug');
                      const headline = form.getValues('headline');
                      if (!slug) {
                        setTempSlug(slugify(headline));
                      }
                    }}
                  />

                  {form.getValues('slug') && (
                    <Slug
                      basePath={`${origin}/blog/`}
                      initialSlug={form.getValues('slug')}
                      onConfirm={(newSlug) => setTempSlug(newSlug)}
                    />
                  )}
                  <Thumbnail />
                  <LeadText />
                  <Sections />
                </Stack>
              </Box>
              <Box
                sx={{
                  width: '20.5rem',
                  minWidth: '20.5rem',
                }}
              >
                <ActionPanel
                  blogData={blogData}
                  onPublish={form.handleSubmit(onPublish)}
                  saveAsDraft={form.handleSubmit(saveAsDraft)}
                  isLoading={isLoadingCreate || isLoadingUpdate}
                  onDelete={onDelete}
                />
              </Box>
            </Stack>
          ) : (
            <LoadingIndicator />
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
export default BlogCreateEdit;
