import { Divider, Stack, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { ChannelDto } from 'tdc-web-backend/channels/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateMessageDto } from 'tdc-web-backend/messages/schemas';
import { ReactComponent as ForwardIcon } from '../../../assets/icons/chat-icons/forward.svg';
import CustomButton from '../../../components/button/CustomButton';
import CustomModal from '../../../components/modal/CustomModal';
import { useRefresh } from '../../../utils/hooks/crud-hooks/useRefresh';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import ResourceSelectInput from '../ResourceSelectInput';
import FramedIcon from '../FramedIcon';
import { useChatNavigation } from '../useChatNavigation';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';

type FormData = {
  channel: string;
};

type ShareAttachmentModalProps = {
  open: boolean;
  onClose?: () => void;
  attachmentId: string;
};

export default ({ open, onClose, attachmentId }: ShareAttachmentModalProps) => {
  const chatNavigation = useChatNavigation();

  const channelResponse = useGetOne<ChannelDto>({
    resource: `channels/${chatNavigation.currentChannelId}`,
  });
  const channel = channelResponse.data?.data;

  const refresh = useRefresh();
  const methods = useForm<FormData>();

  const { mutate } = useCreate<AxiosResponse, CreateMessageDto>({
    resource: 'messages',
  });
  const onSubmit = (data: FormData) => {
    mutate(
      {
        body: '',
        attachments: [attachmentId],
        channel: data.channel,
      },
      {
        onSuccess: () => {
          refresh();
          methods.reset();
          onClose?.();
        },
      },
    );
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <FramedIcon Icon={ForwardIcon} variant="blue" />
                <Typography variant="heading3">Forward this file</Typography>
              </Stack>
              <Divider />
              <Stack direction="column" spacing={3}>
                <Stack direction="row">
                  <Stack direction="column" sx={{ width: '100%' }} spacing={3}>
                    <ResourceSelectInput<ChannelDto>
                      name="channel"
                      resource="channels"
                      filter={{ space: channel?.space }}
                      choiceLabel={(channel) => `# ${channel.name}`}
                      label="Channel"
                      placeholder="Search for channel"
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <CustomButton variant="secondary" onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomButton type="submit">Forward</CustomButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>
    </>
  );
};
