import {
  Grid, Stack, Typography,
} from '@mui/material';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import ErrorIcon from '@mui/icons-material/Error';
import ControlledTextInput from '../../../../components/custom-inputs/ControlledTextInput';
import { companyNumberInfo } from '../ContractDefaultValues';
import ContractLockedChip from '../ContractLockedChip';
import { primaryDark, secondaryBlue } from '../../../../color';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';

interface CompanyInformationAccordionProps {
  isAnnex: boolean;
  userType: CompanyRole | undefined
}

const CompanyIdTooltip = () => (
  <CustomTooltip title={companyNumberInfo}>
    <InfoIcon
      fill={primaryDark[500]}
      style={{
        marginLeft: '14px',
      }}
    />
  </CustomTooltip>
);

const CompanyInformationForm = ({
  isAnnex,
  userType,
}: CompanyInformationAccordionProps) => (

  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={3}
  >
    <Grid
      sx={{
        borderRight: `1px solid ${secondaryBlue[100]}`,
        pr: 2.5,
        pb: 4,
      }}
      item
      xs={6}
    >
      <Stack spacing={2}>
        <Typography
          color="primaryDark.600"
          variant="heading4"
          sx={{
            pb: 2,
          }}
        >
          Buyer
        </Typography>
        <Stack
          sx={{ marginTop: '0 !important' }}
        >
          <Stack spacing={4}>
            <Stack spacing={4}>
              <ControlledTextInput
                name="body.buyer.companyName"
                disabled={isAnnex}
                placeholder="Enter company name"
                label="Company name"
                required
              />
              <ControlledTextInput
                name="body.buyer.registeredAddress"
                disabled={isAnnex}
                label="Registered address"
                placeholder="Enter company address"
                required
              />
              <ControlledTextInput
                name="body.buyer.companyNumber"
                disabled={isAnnex}
                label="Company identification number"
                placeholder="Enter company identification number"
                required
                labelAdornment={(
                  <CompanyIdTooltip />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Stack spacing={0.5}>
                <Typography
                  color="primaryDark.600"
                  variant="heading4"
                  sx={{
                    pb: 2,
                  }}
                >
                  company representative
                </Typography>
              </Stack>
              <Stack spacing={4} sx={{ marginTop: '0 !important' }}>
                <ControlledTextInput
                  name="body.buyer.companyRepresentative.name"
                  disabled={isAnnex}
                  label="Full name"
                  placeholder="Enter representative name"
                  required
                />
                <ControlledTextInput
                  name="body.buyer.companyRepresentative.jobTitle"
                  disabled={isAnnex}
                  label="Job title"
                  placeholder="Enter job title"
                  required
                />
                <ControlledTextInput
                  name="body.buyer.companyRepresentative.email"
                  disabled={isAnnex}
                  label="Company email"
                  placeholder="Enter company email"
                  required
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
    <Grid
      item
      xs={6}
      sx={{
        pl: 1.25,
        pb: 4,
      }}
    >
      <Stack spacing={2}>
        <Typography
          color="primaryDark.600"
          variant="heading4"
          sx={{
            pb: 2,
          }}
        >
          Seller
        </Typography>

        <Stack sx={{ marginTop: '0 !important' }}>
          <Stack spacing={4}>
            <Stack spacing={4}>
              <ControlledTextInput
                name="body.seller.companyName"
                disabled={isAnnex}
                label="Company name"
                placeholder="Enter company name"
                required
              />
              <ControlledTextInput
                name="body.seller.registeredAddress"
                disabled={isAnnex}
                label="Enter company address"
                placeholder="Enter company address"
                required
              />
              <ControlledTextInput
                name="body.seller.companyNumber"
                disabled={isAnnex}
                label="Company identification number"
                placeholder="Enter company identification number"
                required
                labelAdornment={(
                  <CompanyIdTooltip />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Stack spacing={0.5}>
                <Typography
                  color="primaryDark.600"
                  variant="heading4"
                  sx={{
                    pb: 2,
                  }}
                >
                  company representative
                </Typography>

              </Stack>
              <Stack spacing={4} sx={{ marginTop: '0 !important' }}>
                <ControlledTextInput
                  name="body.seller.companyRepresentative.name"
                  disabled={isAnnex}
                  label="Full name"
                  placeholder="Enter representative name"
                  required
                />
                <ControlledTextInput
                  name="body.seller.companyRepresentative.jobTitle"
                  disabled={isAnnex}
                  label="Job title"
                  placeholder="Enter job title"
                  required
                />
                <ControlledTextInput
                  name="body.seller.companyRepresentative.email"
                  disabled={isAnnex}
                  label="Company email"
                  placeholder="Enter company email"
                  required
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  </Grid>
);

export default CompanyInformationForm;
