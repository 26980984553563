/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { mapService } from 'tdc-web-backend/enums/enums';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { FeaturedCompaniesDto } from 'tdc-web-backend/featured-companies/schema';
import CustomButton from '../../../components/button/CustomButton';
import CustomInput from '../../../components/custom-input/CustomInput';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import useUpdate from '../../../utils/hooks/crud-hooks/useUpdate';

const SellerArchiveEdit = () => {
  const { archiveId } = useParams();
  const navigate = useNavigate();

  const formMethods = useForm({
    defaultValues: {
      linkedServices: [],
      companies: [],
      description: '',
      title: '',
    },
  });

  const { data: companyResponse } = useGetMany<CompanyDto>({
    resource: `companies?role=seller_service&status=approved`,
  });

  const { data: featuredResponse } = useGetOne<FeaturedCompaniesDto>({
    resource: `featured-companies/${archiveId}`,
    onSuccess({ data }) {
      const { id, main, companies, linkedServices, description, title } = data;
      const companyIds = companies?.map((company: Partial<CompanyDto>) => company.id);
      formMethods.reset({
        title,
        description,
        companies: companyIds,
        linkedServices: linkedServices as any,
      });
    },
  });

  const companiesArray = companyResponse?.data.results;

  const companyChoices = new Map(companiesArray?.map((comapny) => [comapny.id, comapny.name]));

  const { mutate: updateFeatured } = useUpdate({
    resource: '/featured-companies',
  });

  const onSubmit = (data: any) => {
    updateFeatured(
      {
        id: archiveId as string,
        data: {
          ...data,
        },
      },
      {
        onSuccess: () => {
          navigate('/admin/seller-archive');
        },
      },
    );
  };
  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        sx={{
          width: '100%',
          height: '100%',
        }}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Stack direction="column" p={2} width="60%">
          <Stack gap={3}>
            <Stack width="100%" mb={2}>
              <Typography variant="publicHeading4" color="primaryDark.900">
                Edit featured companies
              </Typography>
            </Stack>
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.Text />}
              name="title"
              placeholder="Title"
              label="Title"
            />
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.Text />}
              name="description"
              placeholder="Description"
              label="Description"
            />
            {companyResponse && (
              <CustomInput
                sx={{
                  minHeight: 10,
                  minWidth: 'fit-content',
                }}
                input={<CustomInput.SearchSelect />}
                withChips
                name="companies"
                placeholder="Companies"
                choices={companyChoices || []}
                multiple
                label="Companies"
              />
            )}
            <CustomInput
              sx={{
                minHeight: 10,
                minWidth: 'fit-content',
              }}
              input={<CustomInput.SearchSelect />}
              name="linkedServices"
              placeholder="Services"
              label="Services"
              multiple
              choices={mapService}
              withChips
            />
            <Stack direction="row" gap={2} mt={2}>
              <CustomButton type="submit">Sumbit</CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default SellerArchiveEdit;
