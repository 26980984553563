import { Box, Stack, Typography } from '@mui/material';
import museum from '../../../../assets/img/companies/museumofillusions.png';
import blvc from '../../../../assets/img/companies/blvck.png';
import lightfilm from '../../../../assets/img/companies/lightfilm.png';
import gameboost from '../../../../assets/img/companies/gameboost.png';

const LogoSection = () => (
  <Stack alignItems="center" gap={5} width="100%">
    <Typography variant="publicBody4Regular" color="primaryLight.500" my={2}>
      Trusted by companies around the world
    </Typography>
    <Stack direction="row" justifyContent="space-between" width="65%" flexWrap="wrap">
      <Box component="img" src={museum} sx={{ maxWidth: '70px' }} alt="museum" />
      <Box component="img" src={blvc} sx={{ maxWidth: '70px' }} alt="blvc" />
      <Box component="img" src={lightfilm} sx={{ maxWidth: '130px' }} alt="lightfilm" />
      <Box component="img" src={gameboost} sx={{ maxWidth: '70px' }} alt="gameboost" />
    </Stack>
  </Stack>
);

export default LogoSection;
