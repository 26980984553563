import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CustomInput from '../../../../../../components/custom-input/CustomInput';
import BaseInputLabel from '../../../../../../components/custom-inputs/BaseInputLabel';
import CustomFormHelperText from '../../../../../../components/custom-input/CustomFormHelperText';

const SpendAccounts = ({ isLoading }: { isLoading?: boolean }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
      <CustomInput
        input={<CustomInput.Text />}
        name="monthlySpend"
        type="text"
        label="Monthly spend"
        placeholder="10.000,00 USD"
        disabled={isLoading}
        sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}
      />
      <Stack spacing={1.5} sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}>
        <BaseInputLabel text="ad accounts:" />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.meta"
          label="Meta"
          disabled={isLoading}
        />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.google"
          label="Google"
          disabled={isLoading}
        />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.tiktok"
          label="TikTok"
          disabled={isLoading}
        />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.snapchat"
          label="Snapchat"
          disabled={isLoading}
        />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.twitter"
          label="Twitter"
          disabled={isLoading}
        />
        <CustomInput
          input={<CustomInput.Checkbox />}
          type="text"
          name="accounts.taboola"
          label="Taboola(soon)"
          disabled={isLoading}
        />
        {errors && 'accounts' in errors && (
          <CustomFormHelperText error={errors.accounts}>
            This field is required
          </CustomFormHelperText>
        )}
      </Stack>
    </Stack>
  );
};
export default SpendAccounts;
