import {
  Box,
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgressWithLabel from '../review-score/CircularProgressWithLabel';
import ContentTypeEnum from '../../utils/enums/content-type';
import CustomAccordion from '../custom-accordion/CustomAccordion';
import EntityTypeEnum from '../../utils/enums/entity-type';

export interface ContentSidebarInfoCardProps {
  contentType: ContentTypeEnum;
  services?: string[];
  industries?: string[];
  techLanguages?: string[];
  techPlatforms?: string[];
  techTools?: string[];
  geographyFocus?: string[];
  projectSize?: string;
  sx?: SxProps<Theme> | undefined;
}

const ContentSidebarInfoCard = (
  {
    contentType,
    services,
    industries,
    techLanguages,
    techPlatforms,
    techTools,
    geographyFocus,
    projectSize,
    sx,
  }: ContentSidebarInfoCardProps,
) => {
  const Article = () => (
    <Card sx={{ ...sx, bgcolor: 'grey.200' }} elevation={4}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ mb: 3 }}
      >

        <CustomAccordion
          showIcon
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Service}
          entityArray={services as string[]}
        />

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Industry}
          entityArray={industries as string[]}
        />

        {
          techLanguages && techLanguages.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechLanguage}
              entityArray={techLanguages as string[]}
            />
          )
        }

        {
          techPlatforms && techPlatforms.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechPlatform}
              entityArray={techPlatforms as string[]}
            />
          )
        }

        {
          techTools && techTools.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechTool}
              entityArray={techTools as string[]}
            />
          )
        }

      </Stack>
    </Card>
  );

  interface CaseStudyProps {
    addRatingFromTheClient?: boolean;
  }

  const CaseStudy = ({ addRatingFromTheClient }: CaseStudyProps) => (
    <Card sx={{ bgcolor: 'grey.200' }} elevation={2}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ mb: 3 }}
      >

        <CustomAccordion
          showIcon
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Service}
          entityArray={services as string[]}
        />

        {
          techLanguages && techLanguages?.length > 0
          && (
            <CustomAccordion
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechLanguage}
              entityArray={techLanguages as string[]}
            />
          )
        }

        {
          techPlatforms && techPlatforms.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechPlatform}
              entityArray={techPlatforms as string[]}
            />
          )
        }

        {
          techTools && techTools.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechTool}
              entityArray={techTools as string[]}
            />
          )
        }

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Industry}
          entityArray={industries as string[]}
        />

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.ProjectSize}
          entity={projectSize}
        />

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Country}
          entityArray={geographyFocus as string[]}
        />
      </Stack>

      {
        addRatingFromTheClient && (
          <Box sx={{ mx: 2, my: 5 }}>
            <Card elevation={3} sx={{ py: 2 }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Typography sx={{ fontWeight: 'bold' }}>Rating from the client </Typography>
                <CircularProgressWithLabel displayScore score={2} size={3} />
              </Stack>
            </Card>
          </Box>
        )
      }
    </Card>
  );

  const TimedProject = () => (
    <Card sx={{ bgcolor: 'grey.200' }} elevation={2}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ mb: 3 }}
      >

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Industry}
          entityArray={industries as string[]}
        />

        <CustomAccordion
          showIcon
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Service}
          entityArray={services as string[]}
        />

        {
          techLanguages && techLanguages?.length > 0
          && (
            <CustomAccordion
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechLanguage}
              entityArray={techLanguages as string[]}
            />
          )
        }

        {
          techPlatforms && techPlatforms.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechPlatform}
              entityArray={techPlatforms as string[]}
            />
          )
        }

        {
          techTools && techTools.length > 0
          && (
            <CustomAccordion
              showIcon
              chipRoundedCorners
              chipBackgroundColor="grey.50"
              entityType={EntityTypeEnum.TechTool}
              entityArray={techTools as string[]}
            />
          )
        }

        <CustomAccordion
          chipRoundedCorners
          chipBackgroundColor="grey.50"
          entityType={EntityTypeEnum.Country}
          entityArray={geographyFocus as string[]}
        />
      </Stack>
    </Card>
  );

  return (
    <>
      {
        contentType === ContentTypeEnum.Article && (
          <Article />
        )
      }

      {
        contentType === ContentTypeEnum.CaseStudy && (
          <CaseStudy />
        )
      }

      {
        contentType === ContentTypeEnum.CaseStudyReview && (
          <CaseStudy addRatingFromTheClient />
        )
      }

      {
        contentType === ContentTypeEnum.TimedProject && (
          <TimedProject />
        )
      }
    </>
  );
};

export default ContentSidebarInfoCard;
