import animationIcon from '../../assets/icons/services/icon-service-animation.svg';
import appDesignIcon from '../../assets/icons/services/icon-service-app-design.svg';
import appDevelopmentIcon from '../../assets/icons/services/icon-service-app-development.svg';
import augmentedRealityIcon from '../../assets/icons/services/icon-service-augmented-reality-ar.svg';
import blockchainIcon from '../../assets/icons/services/icon-service-blockchain.svg';
import brandingIcon from '../../assets/icons/services/icon-service-branding.svg';
import communityManagementIcon from '../../assets/icons/services/icon-service-community-management.svg';
import contentMarketingIcon from '../../assets/icons/services/icon-service-content-marketing.svg';
import copywritingIcon from '../../assets/icons/services/icon-service-copywriting.svg';
import dataAnalyticsIcon from '../../assets/icons/services/icon-service-data-analytics.svg';
import dataEngineeringIcon from '../../assets/icons/services/icon-service-data-engineering.svg';
import dataScienceIcon from '../../assets/icons/services/icon-service-data-science.svg';
import defaultIcon from '../../assets/icons/services/icon-service-default.svg';
import designStrategyIcon from '../../assets/icons/services/icon-service-design-strategy.svg';
import devopsIcon from '../../assets/icons/services/icon-service-devops.svg';
import digitalStrategyIcon from '../../assets/icons/services/icon-service-digital-strategy.svg';
import eCommerceIcon from '../../assets/icons/services/icon-service-e-commerce.svg';
import emailMarketingIcon from '../../assets/icons/services/icon-service-email-marketing.svg';
import extendedRealityXrIcon from '../../assets/icons/services/icon-service-extended-reality-xr.svg';
import gameDevelopmentIcon from '../../assets/icons/services/icon-service-game-development.svg';
import graphicDesignIcon from '../../assets/icons/services/icon-service-graphic-design.svg';
import influencerMarketingIcon from '../../assets/icons/services/icon-service-influencer-marketing.svg';
import machineLearningIcon from '../../assets/icons/services/icon-service-machine-learning.svg';
import marketResearchIcon from '../../assets/icons/services/icon-service-market-research.svg';
import mediaBuyingIcon from '../../assets/icons/services/icon-service-media-buying.svg';
import mobileAdvertisingIcon from '../../assets/icons/services/icon-service-mobile-advertising.svg';
import mobileAppDesignIcon from '../../assets/icons/services/icon-service-mobile-app-design.svg';
import mobileAppDevelopmentIcon from '../../assets/icons/services/icon-service-mobile-app-development.svg';
import motionDesignIcon from '../../assets/icons/services/icon-service-motion-design.svg';
import paidSearchMarketingIcon from '../../assets/icons/services/icon-service-paid-search-marketing.svg';
import publicRelationsIcon from '../../assets/icons/services/icon-service-public-relations.svg';
import searchEngineOptimizationIcon from '../../assets/icons/services/icon-service-search-engine-optimization.svg';
import socialMediaAdvertisingIcon from '../../assets/icons/services/icon-service-social-media-advertising.svg';
import mediaManagementIcon from '../../assets/icons/services/icon-service-social-media-management.svg';
import userExperienceDesignIcon from '../../assets/icons/services/icon-service-user-experience-design.svg';
import userInterfaceDesignIcon from '../../assets/icons/services/icon-service-user-interface-design.svg';
import videoIcon from '../../assets/icons/services/icon-expertise-video.svg';
import virtualRealityIcon from '../../assets/icons/services/icon-expertise-virtual-reality-vr.svg';
import webDesignIcon from '../../assets/icons/services/icon-expertise-web-design.svg';
import webDevelopmenttIcon from '../../assets/icons/services/icon-expertise-web-development.svg';

const mapIconService = new Map([
  ['Animation', animationIcon],
  ['App_Design', appDesignIcon],
  ['App_Development', appDevelopmentIcon],
  ['Augmented_Reality', augmentedRealityIcon],
  ['Blockchain', blockchainIcon],
  ['Branding', brandingIcon],
  ['Community_Management', communityManagementIcon],
  ['Content_Marketing', contentMarketingIcon],
  ['Copywriting', copywritingIcon],
  ['Data_Analytics', dataAnalyticsIcon],
  ['Data_Engineering', dataEngineeringIcon],
  ['Data_Science', dataScienceIcon],
  ['Default', defaultIcon],
  ['Design_Strategy', designStrategyIcon],
  ['DevOps', devopsIcon],
  ['Digital_Strategy', digitalStrategyIcon],
  ['Ecommerce', eCommerceIcon],
  ['Email_Marketing', emailMarketingIcon],
  ['Extended_Reality', extendedRealityXrIcon],
  ['Game_Development', gameDevelopmentIcon],
  ['Graphic_Design', graphicDesignIcon],
  ['Influencer_Marketing', influencerMarketingIcon],
  ['Machine_Learning', machineLearningIcon],
  ['Market_Research', marketResearchIcon],
  ['Media_Buying', mediaBuyingIcon],
  ['Mobile_Advertising', mobileAdvertisingIcon],
  ['Mobile_App_Design', mobileAppDesignIcon],
  ['Mobile_App_Development', mobileAppDevelopmentIcon],
  ['Motion_Design', motionDesignIcon],
  ['Paid_Search_Marketing', paidSearchMarketingIcon],
  ['Public_Relations', publicRelationsIcon],
  ['Search_Engine_Optimization', searchEngineOptimizationIcon],
  ['Social_Media_Advertising', socialMediaAdvertisingIcon],
  ['Social_Media_Management', mediaManagementIcon],
  ['User_Experience_Design', userExperienceDesignIcon],
  ['User_Interface_Design', userInterfaceDesignIcon],
  ['Video', videoIcon],
  ['Virtual_Reality', virtualRealityIcon],
  ['Web_Design', webDesignIcon],
  ['Web_Development', webDevelopmenttIcon],
]);

export default mapIconService;
