import { Pagination, Stack } from '@mui/material';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import useQueryState from '../../../../utils/hooks/useQueryState';
import CustomButton from '../../../../components/button/CustomButton';
import { primaryLight } from '../../../../color';

type Props = {
  count: number | undefined;
};

const CardListPagination = ({ count }: Props) => {
  const defaultValue = {
    limit: 12,
    offset: 0,
  };
  const [queryState, setQueryState] = useQueryState();

  const handlePagination = (page: number) => {
    const offset = (page - 1) * defaultValue.limit;
    setQueryState({ offset });
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    const currentPage = (queryState.offset || defaultValue.offset) / defaultValue.limit + 1;
    handlePagination(currentPage + 1);
  };

  const handlePreviousPage = () => {
    const currentPage = (queryState.offset || defaultValue.offset) / defaultValue.limit + 1;
    handlePagination(currentPage - 1);
  };

  return (
    <Stack py={3} justifyContent="space-between" alignItems="center" flexDirection="row">
      <CustomButton
        onClick={handlePreviousPage}
        disabled={(queryState.offset || defaultValue.offset) / 12 + 1 === 1}
        variant="secondaryText"
        startIcon={
          <ArrowRightIcon
            fill={primaryLight[500]}
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        }
      >
        Previous
      </CustomButton>
      {/* <Pagination count={10} /> */}
      <Stack direction="row" gap={2}>
        <Pagination
          hideNextButton
          hidePrevButton
          count={Math.ceil((count || 0) / defaultValue.limit)}
          page={(queryState.offset || defaultValue.offset) / 12 + 1}
          siblingCount={1}
          boundaryCount={2}
          sx={{
            '& .MuiPaginationItem-root': {
              color: 'primaryDark.500',
              '&.Mui-selected': {
                backgroundColor: 'primaryLight.50',
                color: 'primaryLight.500',
                borderRadius: '8px',
              },
            },
          }}
          onChange={(event: React.ChangeEvent<unknown>, page: number) => handlePagination(page)}
        />
      </Stack>
      <CustomButton
        onClick={handleNextPage}
        variant="secondaryText"
        disabled={
          Math.ceil((count || 0.001) / defaultValue.limit) ===
          (queryState.offset || defaultValue.offset) / 12 + 1
        }
        endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
      >
        Next
      </CustomButton>
    </Stack>
  );
};

export default CardListPagination;
