import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { secondaryOrange } from '../../../../color';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import { splitFirstWord } from '../../../../utils/helpers';
import { TextAndIcon } from '../utils';

interface TheFutureOfSmartManagingProps {
  title?: string;
  subtitle?: string;
  textAndIcons: TextAndIcon[];
  bgcolor?: string;
}

const TheFutureOfSmartManaging = ({
  title = 'The Future of Smart Managing',
  subtitle = 'Get one project management tool that does it all.',
  textAndIcons,
  bgcolor = secondaryOrange[101],
}: TheFutureOfSmartManagingProps) => (
  <Stack
    width="100%"
    bgcolor={bgcolor}
    sx={{
      position: 'relative',
      py: { xs: '25%', sm: '10%' },
    }}
  >
    <Stack spacing={10} sx={{ ...LAYOUT_DEFAULTS }}>
      <Stack spacing={1}>
        <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading2' } }}>
          {title}
        </Typography>

        <Typography
          color="common.black"
          sx={{ typography: { xs: 'publicBody4Regular', lg: 'publicBody3Regular' } }}
        >
          {subtitle}
        </Typography>
      </Stack>

      <Stack rowGap={10} columnGap="150px" direction="row" flexWrap="wrap">
        {textAndIcons.map(({ title, Icon, iconColor, content }) => (
          <Stack spacing={2} width={{ xs: '100%', md: '35%' }} sx={{ zIndex: 3 }}>
            <Icon fill={iconColor} style={{ width: '54px' }} />

            <Typography
              component="span"
              color="common.black"
              sx={{ typography: { xs: 'publicBody5Regular', sm: 'publicBody4Regular' } }}
            >
              <Typography
                component="span"
                color="common.black"
                sx={{
                  typography: { xs: 'publicBody5Regular', sm: 'publicBody4Regular' },
                  fontFamily: 'Gellix-SemiBold !important',
                }}
              >
                {splitFirstWord(title).firstWord}
              </Typography>{' '}
              {splitFirstWord(title).restOfWords}
            </Typography>

            <Typography color="common.black" variant="publicBody4Regular">
              {content}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  </Stack>
);

export default TheFutureOfSmartManaging;
