import { Tooltip, TooltipProps } from '@mui/material';

type Props = {
  title: string;
  children: JSX.Element;
} & TooltipProps;

const CustomTooltip = ({ title, children, ...props }: Props) => (
  <Tooltip
    arrow
    title={title}
    PopperProps={{
      sx: {
        textTransform: 'none',
        '& .MuiTooltip-tooltip': {
          textTransform: 'none',
          fontWeight: 400,
          lineHeight: '1.25rem',
          fontSize: '0.75rem',
        },
      },
    }}
    {...props}
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
