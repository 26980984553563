import { Stack } from '@mui/system';
import { Avatar, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import CustomButton from '../../../../../components/button/CustomButton';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import { EnumTimedProjectPitchRejectFeedbackScope } from 'tdc-web-backend/enums/enums';
import PitchFeedbackInput from './PitchFeedbackInput';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { RejectFormType } from '../../interface';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

const RejectSecondStepFirstRound = ({ setSliderPage, setIsOpen, pitchData }: RejectFormType) => {
  const refresh = useRefresh();
  const { data } = useReferenceOne<CompanyDto>({
    id: pitchData?.seller,
    resource: 'companies',
  });

  const { mutate: sendFeedback } = useUpdate({
    resource: '/timed-project-pitches',
  });

  const formMethods = useForm({
    defaultValues: {
      feedback: [],
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const handleSubmitReject = (data: any) => {
    data.feedback[0].type = EnumTimedProjectPitchRejectFeedbackScope.Overall;
    sendFeedback(
      {
        id: pitchData.timedProjectPitch,
        data: data?.feedback,
        suffix: 'reject-feedback',
      },
      {
        onSuccess: () => {
          refresh();
          setIsOpen(false);
        },
      },
    );
  };

  return (
    <Stack direction="column">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitReject)}>
          <Stack direction="row" alignItems="center" mb={3} gap={2}>
            <Avatar sx={{ borderRadius: '8px' }} src={data?.avatar} alt="Company logo" />
            <Stack>
              <Typography variant="heading4" color="primaryDark.600">
                {data?.name}
              </Typography>
              <Typography variant="body3" color="primaryDark.600">
                Rate 1st round pitch
              </Typography>
            </Stack>
          </Stack>
          <PitchFeedbackInput
            title="Pitch score"
            description=""
            ratingInputName={'feedback[0].rate'}
            textInputName={'feedback[0].description'}
          />
          <Stack gap={0.625} direction="row" justifyContent="center" my={3}>
            <FiberManualRecord fontSize="small" sx={{ color: '#C5C7C3' }} />
            <FiberManualRecord fontSize="small" sx={{ color: 'secondaryBlue.500' }} />
          </Stack>
          <Stack direction="row" gap={3} justifyContent="flex-end">
            <CustomButton type="submit" variant="primary" disabled={!isValid}>
              submit
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default RejectSecondStepFirstRound;
