import { Divider } from '@mui/material';
import { Editor } from '@tiptap/react';
import { EditorItem } from './menu/items/EditorItem';
import MenuButton from './menu/items/MenuButton';
import FontSelect from './menu/items/selects/FontSelect';
import HeadingSelect from './menu/items/selects/HeadingSelect';
import ImageUploadMenuButton from './menu/items/ImageUploadMenuButton';
import EmbedMenuButton from './menu/items/EmbedMenuButton';
import TableMenuButton from './menu/items/TableMenuButton';

export enum MenuItem {
  Bold = 'Bold',
  Italic = 'Italic',
  Strike = 'Strike',
  Code = 'Code',
  Paragraph = 'Paragraph',
  BulletList = 'Bullet List',
  OrderedList = 'Ordered List',
  CodeBlock = 'Code Block',
  BlockQuote = 'Block Quote',
  HorizonalRule = 'Horizontal Rule',
  HardBreak = 'Hard Break',
  Undo = 'Undo',
  Redo = 'Redo',
  Image = 'Image',
  Highlight = 'Highlight',
  Underline = 'Underline',
  TextAlignLeft = 'Text Align Left',
  TextAlignCenter = 'Text Align Center',
  TextAlignRight = 'Text Align Right',
  TextAlignJusitfy = 'Text Align Justify',
  ClearStyle = 'Clear Style',
  FontFamily = 'Font Family',
  TextStyle = 'Text Style',
  DividerLine = 'Divider',
  Link = 'Link',
  Embed = 'Embed',
  Table = 'Table',
  Empty = 'Empty',
}

export class EditorCommand {
  editor: Editor;

  action: MenuItem;

  constructor(editor: Editor) {
    this.editor = editor;
    this.action = MenuItem.Bold;
  }

  setAction(action: MenuItem) {
    this.action = action;
  }

  getLabel() {
    if (this.action === MenuItem.DividerLine) {
      return Math.random().toString(20).substr(2, 6);
    }

    return this.action;
  }

  getIconName() {
    switch (this.action) {
      case MenuItem.Bold:
        return 'format_bold';
      case MenuItem.Italic:
        return 'format_italic';
      case MenuItem.Strike:
        return 'format_strikethrough';
      case MenuItem.Code:
        return 'code';
      case MenuItem.Paragraph:
        return 'text_snippet';
      case MenuItem.BulletList:
        return 'format_list_bulleted';
      case MenuItem.OrderedList:
        return 'format_list_numbered';
      case MenuItem.CodeBlock:
        return 'code';
      case MenuItem.BlockQuote:
        return 'format_quote';
      case MenuItem.HorizonalRule:
        return 'Horizontal rule';
      case MenuItem.HardBreak:
        return 'Hard Break';
      case MenuItem.Undo:
        return 'undo';
      case MenuItem.Redo:
        return 'redo';
      case MenuItem.Highlight:
        return 'format_paint';
      case MenuItem.Underline:
        return 'format_underlined';
      case MenuItem.TextAlignLeft:
        return 'format_align_left';
      case MenuItem.TextAlignCenter:
        return 'format_align_center';
      case MenuItem.TextAlignRight:
        return 'format_align_right';
      case MenuItem.TextAlignJusitfy:
        return 'format_align_justify';
      case MenuItem.Link:
        return 'insert_link';
      default:
        return '';
    }
  }

  executeAction(action: MenuItem, payload?: any) {
    switch (action) {
      case MenuItem.Bold:
        this.editor.chain().focus().toggleBold().run();
        break;
      case MenuItem.Italic:
        this.editor.chain().focus().toggleItalic().run();
        break;
      case MenuItem.Strike:
        this.editor.chain().focus().toggleStrike().run();
        break;
      case MenuItem.Code:
        this.editor.chain().focus().toggleCode().run();
        break;
      case MenuItem.Paragraph:
        this.editor.chain().focus().setParagraph().run();
        break;
      case MenuItem.BulletList:
        this.editor.chain().focus().toggleBulletList().run();
        break;
      case MenuItem.OrderedList:
        this.editor.chain().focus().toggleOrderedList().run();
        break;
      case MenuItem.CodeBlock:
        this.editor.chain().focus().toggleCodeBlock().run();
        break;
      case MenuItem.BlockQuote:
        this.editor.chain().focus().toggleBlockquote().run();
        break;
      case MenuItem.HorizonalRule:
        this.editor.chain().focus().setHorizontalRule().run();
        break;
      case MenuItem.HardBreak:
        this.editor.chain().focus().setHardBreak().run();
        break;
      case MenuItem.Undo:
        this.editor.chain().focus().undo().run();
        break;
      case MenuItem.Redo:
        this.editor.chain().focus().redo().run();
        break;
      case MenuItem.Highlight:
        this.editor.chain().focus().toggleHighlight().run();
        break;
      case MenuItem.Underline:
        this.editor.chain().focus().toggleUnderline().run();
        break;
      case MenuItem.TextAlignLeft:
        this.editor.chain().focus().setTextAlign('left').run();
        break;
      case MenuItem.TextAlignCenter:
        this.editor.chain().focus().setTextAlign('center').run();
        break;
      case MenuItem.TextAlignRight:
        this.editor.chain().focus().setTextAlign('right').run();
        break;
      case MenuItem.TextAlignJusitfy:
        this.editor.chain().focus().setTextAlign('justify').run();
        break;
      case MenuItem.Link:
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: '', target: '_blank' })
          .run();
        break;
      default:
        break;
    }
  }

  isActiveCheck() {
    let condition = '';

    switch (this.action) {
      case MenuItem.Bold:
        condition = 'bold';
        break;
      case MenuItem.Italic:
        condition = 'italic';
        break;
      case MenuItem.Strike:
        condition = 'strike';
        break;
      case MenuItem.Code:
        condition = 'code';
        break;
      case MenuItem.Paragraph:
        condition = 'paragraph';
        break;
      case MenuItem.BulletList:
        condition = 'bulletList';
        break;
      case MenuItem.OrderedList:
        condition = 'orderedList';
        break;
      case MenuItem.CodeBlock:
        condition = 'codeBlock';
        break;
      case MenuItem.BlockQuote:
        condition = 'blockquote';
        break;
      case MenuItem.Highlight:
        condition = 'highlight';
        break;
      case MenuItem.Underline:
        condition = 'underline';
        break;
      case MenuItem.Link:
        condition = 'link';
        break;
      case MenuItem.TextAlignLeft:
        return this.editor.isActive({ textAlign: 'left' });
      case MenuItem.TextAlignCenter:
        return this.editor.isActive({ textAlign: 'center' });
      case MenuItem.TextAlignRight:
        return this.editor.isActive({ textAlign: 'right' });
      case MenuItem.TextAlignJusitfy:
        return this.editor.isActive({ textAlign: 'justify' });
      default:
        return false;
    }

    return this.editor.isActive(condition);
  }

  getEditorItem(): EditorItem {
    return {
      label: this.getLabel(),
      iconName: this.getIconName(),
      isActive: this.isActiveCheck(),
      action: this.executeAction.bind(this, this.action),
    };
  }

  getMenuItemUI(editor: Editor, uniqueKey?: string) {
    switch (this.action) {
      case MenuItem.DividerLine:
        return (
          <Divider
            key={this.getEditorItem().label}
            flexItem
            variant="fullWidth"
            orientation="vertical"
            sx={{
              display: 'inline',
              borderBottomWidth: 6,
              borderTopWidth: 6,
              borderColor: '#E5E8FF',
            }}
          />
        );
      case MenuItem.Image:
        return <ImageUploadMenuButton editor={editor} uniqueKey={uniqueKey} />;
      case MenuItem.FontFamily:
        return <FontSelect editor={editor} />;
      case MenuItem.TextStyle:
        return <HeadingSelect editor={editor} />;
      case MenuItem.Embed:
        return <EmbedMenuButton editor={editor} />;
      case MenuItem.Table:
        return <TableMenuButton editor={editor} />;
      default:
        return <MenuButton editorItem={this.getEditorItem()} />;
    }
  }
}
