import { Stack } from '@mui/material';
import CustomInput from '../../../../../../components/custom-input/CustomInput';

const CompanyPhone = ({ isLoading }: { isLoading?: boolean }) => (
  <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
    <CustomInput
      input={<CustomInput.Text />}
      name="companyName"
      type="text"
      label="* Company name"
      placeholder="Enter your company name"
      required
      disabled={isLoading}
      sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}
    />
    <CustomInput
      input={<CustomInput.Text />}
      name="phone"
      type="text"
      label="Whatsapp/Telegram"
      placeholder="Enter your account"
      disabled={isLoading}
      sx={{ width: { xs: 'none', sm: '50%' }, backgroundColor: '#fff' }}
    />
  </Stack>
);
export default CompanyPhone;
