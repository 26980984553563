import { FormControlLabel, Switch } from '@mui/material';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import { JSXElementConstructor, ReactElement } from 'react';
import { SwitchProps } from '@mui/material/Switch/Switch';
 
type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label?: string | number | ReactElement<any, string | JSXElementConstructor<any>>;
} & SwitchProps;

const defaultStyles = {

  width: 30,
  height: 18,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    margin: '3px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: 'secondaryGreen.100',

      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'secondaryGreen.700',
      },
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
    backgroundColor: 'primaryDark.500',

  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'primaryDark.300',
    opacity: 1,

  },
};

const ControlledSwitchInput = ({
  name,
  required,
  disabled,
  label,
  ...props
}: Props) => {
  const { control, getValues } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <FormControlLabel
          sx={{
            margin: 0,
          }}
          control={(
            <Switch
              {...field}
              checked={getValues(name)}
              sx={defaultStyles}
              disableRipple
              {...props}
            />
          )}
          disabled={disabled}
          label={label ?? ''}
        />
      )}
    />
  );
};

export default ControlledSwitchInput;
