import { useState } from 'react';
import { Stack } from '@mui/system';
import { BuyerPricingType } from '../../interface';
import CustomButton from '../../../../../components/button/CustomButton';
import LAYOUT_DEFAULTS from '../../../../../layout/utils';
import LogoSection from '../LogoSection';
import PricingFaq from '../PricingFaq';
import GetDemoSection from '../GetDemoSection/GetDemoSection';
import PricingCard from '../PricingCard';
import BuyerBasicPlan from './components/BuyerBasicPlan';
import BuyerStandardPlan from './components/BuyerStandardPlan';
import BuyerPremiumPlan from './components/BuyerPremiumPlan';
import BuyerSelfServicePlan from './components/BuyerSelfServicePlan';
import BuyerSponaConnectPlan from './components/BuyerSponaConnectPlan';

const BuyerPricingContent = ({ type }: { type: BuyerPricingType }) => {
  const [activeBtn, setActiveBtn] = useState<BuyerPricingType>(type);

  const handleTabVariants = (btnName: string) =>
    activeBtn === btnName ? 'primary' : 'primaryLight';

  return (
    <>
      <Stack direction="row">
        <CustomButton
          sx={{ width: { lg: '15rem' }, borderRadius: '20px 0px 0px 20px' }}
          variant={handleTabVariants('hire')}
          onClick={() => setActiveBtn('hire')}
        >
          Hire and manage
        </CustomButton>
        <CustomButton
          sx={{ width: { lg: '15rem' }, borderRadius: '0px 20px 20px 0px' }}
          variant={handleTabVariants('manage')}
          onClick={() => setActiveBtn('manage')}
        >
          Manage
        </CustomButton>
      </Stack>
      <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
        {activeBtn === 'manage' ? (
          <Stack
            direction={{ lg: 'row' }}
            alignItems="center"
            width="100%"
            minHeight="100%"
            maxWidth="1310px"
            my={8}
            gap={{ xs: 5, lg: 0 }}
          >
            <PricingCard
              purple
              sx={{
                mr: { lg: '-32px' },
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
              }}
            >
              <BuyerBasicPlan />
            </PricingCard>

            <PricingCard
              sx={{
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
                zIndex: 4,
              }}
            >
              <BuyerStandardPlan />
            </PricingCard>

            <PricingCard
              purple
              sx={{
                ml: { lg: '-32px' },
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
              }}
            >
              <BuyerPremiumPlan />
            </PricingCard>
          </Stack>
        ) : (
          <Stack
            direction={{ md: 'row' }}
            alignItems={{ xs: 'center', md: 'stretch' }}
            width={{ lg: '100%' }}
            minHeight="100%"
            maxWidth="932px"
            my={8}
            gap={{ xs: 5, md: 0 }}
            sx={{ mr: { md: '-32px' } }}
          >
            <PricingCard
              sx={{ mr: { md: '-32px' }, width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}
            >
              <BuyerSelfServicePlan />
            </PricingCard>
            <PricingCard purple sx={{ width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}>
              <BuyerSponaConnectPlan />
            </PricingCard>
          </Stack>
        )}
        <LogoSection />
        <PricingFaq />
      </Stack>
      <GetDemoSection type={activeBtn} />
    </>
  );
};

export default BuyerPricingContent;
