import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { ReactNode } from 'react';
import eyeShow from '../../assets/icons/eye-fill.svg';
import eyeHide from '../../assets/icons/eye-slash-fill.svg';

type ControlledPasswordInputProps = {
  name: string;
  required?: boolean;
  startAdornment?: ReactNode;
  startAdornmentOnClick?: () => void;
  showStartAdornment?: boolean;
  endAdornment?: ReactNode;
  endAdornmentOnClick?: () => void;
  showEndAdornment?: boolean;
  readOnly?: boolean;
  sx?: SxProps<Theme> | undefined;
  showPassword?: boolean;
  outerLabel?: string;
  customValidation?: JSX.Element;
} & TextFieldProps;

// TODO: delete this component and use CustomTextInput component with the type=password
const ControlledPasswordInput = ({
  outerLabel,
  sx,
  showPassword,
  name,
  required,
  startAdornment,
  startAdornmentOnClick,
  showStartAdornment = false,
  endAdornment,
  endAdornmentOnClick,
  showEndAdornment = true,
  readOnly,
  customValidation,
  ...props
}: ControlledPasswordInputProps) => {
  const {
    register,
    formState: { errors },
    getFieldState,
    trigger,
  } = useFormContext();

  const fieldState = getFieldState(name);

  const showError = fieldState.error;

  return (
    <Stack spacing={1}>
      {outerLabel && (
        <Typography variant="heading5" color="primaryDark.500">
          {outerLabel}
        </Typography>
      )}{' '}
      <TextField
        {...register(
          name,
          required
            ? { required: 'Too short! Use 8 or more characters', minLength: 8 }
            : { required: false },
        )}
        fullWidth
        type="string"
        sx={{
          '.MuiOutlinedInput-input': {
            backgroundColor: 'common.white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'secondaryBlue.100',
            },
            '&:hover fieldset': {
              borderColor: 'secondaryBlue.100',
            },
            '&.Mui-focused': {
              boxShadow: '6px 6px 15px rgba(56, 69, 114, 0.1)',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'secondaryBlue.100',
            },
          },
          ...sx,
        }}
        variant="standard"
        helperText={
          customValidation || (
            <>
              {showError ? (
                <Typography color="secondaryPink.500" variant="body4" sx={{ position: 'absolute' }}>
                  {fieldState?.error?.message?.length
                    ? fieldState?.error?.message
                    : 'This field is required'}
                </Typography>
              ) : (
                ''
              )}
            </>
          )
        }
        onBlur={() => trigger(name)}
        InputProps={{
          readOnly: readOnly || false,
          endAdornment: showEndAdornment ? (
            <InputAdornment position="end" sx={{ width: '3em', justifyContent: 'center' }}>
              {endAdornment || (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    endAdornmentOnClick?.();
                  }}
                >
                  {showPassword ? (
                    <Box component="img" src={eyeShow} height="1em" />
                  ) : (
                    <Box component="img" src={eyeHide} height="1em" />
                  )}
                </IconButton>
              )}
            </InputAdornment>
          ) : null,
        }}
        {...props}
      />
    </Stack>
  );
};

export default ControlledPasswordInput;
