import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { UseFormReturn, FormProvider, FieldValues, SubmitHandler } from 'react-hook-form';
import { Divider, SxProps, Theme } from '@mui/material';
import { secondaryBlue, secondaryGreen, primaryDark } from '../../color';
import { disableBackBtn } from './multistep.utils';
import { IStep, IStatus, TStepAction } from './multistep.interface';
import CustomButton from '../button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/project-icons/ArrowRightIcon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/layout-icons/ArrowLeftShortFill.svg';

const GRADIENT_BG = 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)';
const LIGHT_BG = '#F6F7FF';

interface IProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  steps: IStep[];
  currentStep: number;
  onChange: (action: TStepAction) => void;
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
  // this prop is used when you want to display elements differently;
  // e.g. on the preview step save draft buttons and back and submit buttons
  // are to the right of the children, so in component call,
  // pass 'row' for that case
  layoutOrientation?: 'column' | 'row';
  // this rightColumnRenderProp is element you want to render **between**
  // save draft button and back and submit buttons
  // when layoutOrientation === 'row'
  rightColumnRenderProp?: () => ReactNode;
  helperText?: string | null;
  onCancelClick?: () => void;
  onSaveAsDraft?: (data: FieldValues) => void;
  disableCancelButton?: boolean;
  isSubmitLoading?: boolean;
}

const MultiStep = <T extends FieldValues>({
  form,
  steps,
  currentStep,
  onChange,
  onSubmit,
  children,
  layoutOrientation = 'column',
  rightColumnRenderProp,
  helperText,
  onCancelClick,
  onSaveAsDraft,
  disableCancelButton = false,
  isSubmitLoading = false,
}: IProps<T>) => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
  } = form;

  const SaveDraftButton = ({ style }: { style?: React.CSSProperties | undefined }) => (
    <Button
      sx={draftButtons}
      onClick={() => onSaveAsDraft?.(form.getValues())}
      style={{ ...style }}
      startIcon={<Box sx={draftIcon} id="draftIcon" />}
    >
      Save draft
    </Button>
  );

  const BackAndNextOrSubmitButtonsStack = ({
    buttonSx,
  }: {
    buttonSx?: SxProps<Theme> | undefined;
  }) => (
    <Stack spacing={2} direction="row" sx={{ mb: '40px !important' }}>
      <CustomButton
        variant="secondary"
        withBackArrow
        onClick={() => onChange('decrement')}
        disabled={disableBackBtn(currentStep)}
        sx={{ ...buttonSx }}
      >
        Back
      </CustomButton>

      {currentStep < steps.length ? (
        <CustomButton
          variant="primary"
          endIcon={<ArrowRightIcon fill="#fff" />}
          onClick={() => onChange('increment')}
          sx={{ ...buttonSx }}
        >
          Next
        </CustomButton>
      ) : (
        <CustomButton
          key="submit" // key prevents triggering the 'onSubmit' on render
          variant="primary"
          // type="submit"
          // For some reasone this type submit wasnt triggering functions so I have contacted useForm
          // and this is what they suggested to be even better for complex forms
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitLoading}
          sx={{ ...buttonSx }}
        >
          Submit
        </CustomButton>
      )}
    </Stack>
  );

  return (
    <>
      {!disableCancelButton && (
        <CustomButton
          variant="primaryText"
          sx={cancelButton}
          startIcon={<ArrowLeftIcon fill={primaryDark['500']} />}
          onClick={() => {
            navigate('../');
            if (onCancelClick) onCancelClick();
          }}
        >
          Cancel
        </CustomButton>
      )}

      {/* main stack */}
      <Stack spacing={5}>
        <FormProvider {...form}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* steps & children */}
            <Stack spacing={5}>
              {/* steps */}
              <Stack direction="row" spacing={2}>
                {steps.map(({ status, label, position }) => (
                  <Box sx={step({ status })} key={`${position}${status}`}>
                    <Box sx={count({ status })}>{position}</Box>
                    <Typography
                      variant="heading4"
                      color={
                        status === 'success'
                          ? 'secondaryGreen.700'
                          : status === 'active'
                          ? 'primaryDark.600'
                          : 'primaryDark.400'
                      }
                    >
                      {label}
                    </Typography>
                  </Box>
                ))}
              </Stack>
              {helperText && (
                <Stack
                  alignItems="center"
                  justifyContent="flex-start"
                  direction="row"
                  sx={{
                    bgcolor: 'primaryLight.50',
                    p: 2,
                    borderRadius: '4px',
                  }}
                >
                  <Typography color="primaryDark.600">{helperText}</Typography>
                </Stack>
              )}
              {layoutOrientation === 'column' && <Box sx={{ width: '100%' }}>{children}</Box>}
            </Stack>

            {/* row of children & buttons (renderProp() is between save draft & buttons) */}
            {layoutOrientation === 'row' && (
              <Stack spacing={4} direction="row" sx={{ mt: 4 }}>
                <Stack spacing={5} sx={{ width: '70%' }}>
                  {children}

                  <Divider sx={{ borderColor: 'secondaryBlue.100' }} />
                </Stack>

                <Stack spacing={4.5} width="28%">
                  {/* if no onSaveAsDraft func than dont render sav as draft btn */}
                  {/* empty div is set to align back and next btn to end */}
                  {onSaveAsDraft ? <SaveDraftButton style={{ width: 'fit-content' }} /> : <></>}

                  {rightColumnRenderProp?.()}

                  {BackAndNextOrSubmitButtonsStack({ buttonSx: { width: '50%' } })}
                </Stack>
              </Stack>
            )}
          </form>
        </FormProvider>

        {layoutOrientation === 'column' && <Divider sx={{ borderColor: 'secondaryBlue.100' }} />}

        {layoutOrientation === 'column' && (
          <Box sx={buttons}>
            {/* if no onSaveAsDraft func than dont render sav as draft btn */}
            {/* empty div is set to align back and next btn to end */}
            {onSaveAsDraft ? <SaveDraftButton /> : <div />}

            {BackAndNextOrSubmitButtonsStack({})}
          </Box>
        )}
      </Stack>
    </>
  );
};

const step = ({ status }: IStatus) => ({
  padding: '16px',
  borderRadius: '6px',
  background:
    status === 'success'
      ? secondaryGreen['100']
      : status === 'active'
      ? GRADIENT_BG
      : primaryDark['100'],
});

const count = ({ status }: IStatus) => ({
  width: '36px',
  height: '36px',
  padding: '10px 13px',
  margin: '0 0 10px 0',
  fontSize: '16px',
  fontWeight: 600,
  color:
    status === 'success'
      ? secondaryGreen['700']
      : status === 'active'
      ? secondaryBlue['700']
      : primaryDark['300'],
  background:
    status === 'success'
      ? secondaryGreen['200']
      : status === 'active'
      ? LIGHT_BG
      : primaryDark['100'],
  borderRadius: '2px',
});

const buttons = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const draftIcon = {
  position: 'relative',
  width: '12.5px',
  height: '12.5px',
  borderRadius: '50%',
  background: primaryDark['300'],
  '&:before': {
    position: 'absolute',
    left: '2px',
    top: '2px',
    content: "''",
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: primaryDark['500'],
  },
};

const draftButtons = {
  padding: '6px 12px',
  fontSize: '11px',
  fontWeight: 600,
  color: primaryDark['500'],
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  lineHeight: '16px',
  borderRadius: '3px',
  background: primaryDark['200'],
  '&:hover #draftIcon': {
    background: secondaryGreen['200'],
    '&:before': {
      background: secondaryGreen['500'],
    },
  },
};

const cancelButton = {
  '.MuiButton-startIcon': {
    border: `1px solid ${primaryDark['300']}`,
    borderRadius: '50%',
    padding: '14px 12px',
  },
  margin: '0 0 40px 0',
  padding: '2px',
  justifyContent: 'flex-start',
  color: primaryDark['500'],
};

export default MultiStep;
