import { Stack } from '@mui/material';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { CaseStudyEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';

const ContentStep = () => (
  <Stack direction="column" spacing={5}>
    <ControlledTextEditor
      label="Project goals"
      name="description.goals"
      items={CaseStudyEditorMenu}
      required
      key="goals"
    />
    <ControlledTextEditor
      label="Challenges"
      name="description.challenges"
      items={CaseStudyEditorMenu}
      required
      key="challenges"
    />
    <ControlledTextEditor
      label="solution"
      name="description.solutions"
      items={CaseStudyEditorMenu}
      required
      key="solutions"
    />
    <ControlledTextEditor
      label="Results"
      name="description.results"
      items={CaseStudyEditorMenu}
      required
      key="results"
    />
  </Stack>
);

export default ContentStep;
