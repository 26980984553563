import { TabPanel } from '@mui/lab';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { SxProps } from '@mui/system';
import { LinearProgress, Theme } from '@mui/material';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import CustomNoRowsOverlay from '../custom-no-rows-overlay/CustomNoRowsOverlay';
import { primaryDark } from '../../color';
import { useEffect } from 'react';

type CustomTablePanelsProps<TData, TError> = {
  resource: string;
  sx?: SxProps<Theme>;
  value: string;
  searchParams?: Record<string, any>;
  onLoadCount: (count: number) => void;
  disableNoRecords?: boolean;
  onDataLoaded?: (data: TData[]) => void;
  height?: string;
} & Omit<DataGridProps, 'rows'>;

const CustomTablePanel = <TData, TError>({
  sx,
  value,
  searchParams,
  resource,
  onLoadCount,
  disableNoRecords = false,
  onDataLoaded,
  height,
  components,
  hideFooter = true,
  ...props
}: CustomTablePanelsProps<TData, TError>) => {
  const filters = new URLSearchParams(searchParams).toString();
  const apiUrl = filters ? `${resource}?${filters}` : `${resource}`;

  const { data, isLoading } = useGetMany<TData>({
    resource: apiUrl,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      onLoadCount(data?.data.count);

      if (data) {
        const {
          data: { count },
        } = data;
        const {
          data: { results },
        } = data;

        onLoadCount(count);

        if (onDataLoaded) {
          onDataLoaded(results);
        }
      }
    },
  });

  return (
    <TabPanel
      value={value}
      sx={{
        overflow: 'hidden',
        bgcolor: 'white',
        p: 0,
        position: 'relative',
      }}
    >
      <DataGrid
        rows={(data?.data?.results as any[]) ?? []}
        getRowId={(params) => params.id}
        loading={isLoading}
        hideFooter={components?.Pagination ? false : hideFooter}
        components={{
          LoadingOverlay: () => (
            <LinearProgress
              sx={{
                backgroundColor: 'secondaryBlue.200',
                '& .MuiLinearProgress-bar': { backgroundColor: 'secondaryBlue.100' },
              }}
            />
          ),
          ...components,
        }}
        sx={{
          border: 'none',
          '& .MuiDataGrid-main': {
            height,
          },
          '.withDashboardCard & .MuiDataGrid-main': {
            borderBottom: `1px solid ${primaryDark[250]} !important`,
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',
          },
          ...sx,
        }}
        {...props}
      />

      {isLoading
        ? null
        : !disableNoRecords && !data?.data.results?.length && <CustomNoRowsOverlay type="list" />}
    </TabPanel>
  );
};
export default CustomTablePanel;
