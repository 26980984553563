import { ClickAwayListener, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { CompanyRole, EnumCaseStudyStatus } from 'tdc-web-backend/enums/enums';
import { ReactComponent as PenIcon } from '../../../../assets/icons/project-icons/PenIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { ReactComponent as CancelCircleIcon } from '../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { APPBAR_HEIGHT } from '../../../../layout/dashboard-navigation/NavigationDashboard';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';
import { primaryDark } from '../../../../color';
import { formatDateFns } from '../../../../utils/helpers';
import CustomButton from '../../../../components/button/CustomButton';
import DeleteCaseStudyModal from '../CaseStudiesList/DeleteCaseStudyModal';
import CaseStudiesContentDetails from './CaseStudiesContentDetails';
import CaseStudiesReviewForm from './CaseStudiesReviewForm';
import useDetermineCompanyRoles from '../../../../utils/hooks/useDetermineCompanyRoles';

type CaseStudiesDetailsDrawerProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  showReviewForm: boolean;
  setShowReviewForm: Dispatch<SetStateAction<boolean>>;
  item: BaseCaseStudy;
  drawerWidth?: string;
};

const CaseStudiesDetailsDrawer = ({
  open,
  setIsOpen,
  item,
  showReviewForm,
  setShowReviewForm,
  drawerWidth = '40%',
}: CaseStudiesDetailsDrawerProps) => {
  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { data } = useReferenceOne<CaseStudyContentDto>({
    id: item.content,
    resource: 'case-studies-content',
    enabled: !!item,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      disableScrollLock
      onClose={() => {
        setIsOpen(false);
        setShowReviewForm(false);
      }}
      hideBackdrop
      elevation={4}
      PaperProps={{
        sx: {
          boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
          width: drawerWidth,
          mt: `${APPBAR_HEIGHT}px`,
          height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
        },
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false);
          setShowReviewForm(false);
        }}
      >
        <Stack p={3}>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <Typography variant="heading3" color="primaryDark.600">
              {data?.title}
            </Typography>
            <IconButton onClick={() => setIsOpen(false)} sx={{ p: 0, height: 'fit-content' }}>
              <CancelCircleIcon fill={primaryDark[500]} />
            </IconButton>
          </Stack>
          <Typography variant="body3" pt={4} color="primaryDark.500">
            {formatDateFns(data?.published, '---')}
          </Typography>

          {!isUserBuyer && (
            <Stack direction="row" pt={3} alignItems="center" justifyContent="space-between">
              {!showReviewForm && (
                <>
                  <Stack direction="row" gap={4} alignItems="center">
                    {/* <CustomButton variant="secondary" onClick={() => setIsOpenDeleteModal(true)}>
                      Delete
                    </CustomButton> */}
                    <CustomButton
                      disabled={data?.status === EnumCaseStudyStatus.Pending}
                      variant="primaryText"
                      endIcon={<PenIcon fill={primaryDark[500]} />}
                      sx={{ color: 'primaryDark.500' }}
                      href={`./edit/${data?.caseStudy}`}
                    >
                      edit
                    </CustomButton>
                  </Stack>
                  {item.status === EnumCaseStudyStatus.Published && (
                    <CustomButton
                      onClick={() => setShowReviewForm(true)}
                      endIcon={<ArrowRightIcon fill="white" />}
                    >
                      request review
                    </CustomButton>
                  )}
                </>
              )}
            </Stack>
          )}

          <Divider sx={{ mt: 5 }} />

          {!showReviewForm && data && (
            <CaseStudiesContentDetails data={data as CaseStudyContentDto} />
          )}
          {showReviewForm && (
            <CaseStudiesReviewForm
              data={data as CaseStudyContentDto}
              setShowReviewForm={setShowReviewForm}
            />
          )}
        </Stack>
      </ClickAwayListener>
      <DeleteCaseStudyModal
        setIsOpen={setIsOpenDeleteModal}
        isOpen={isOpenDeleteModal}
        id={item.id}
      />
    </Drawer>
  );
};

export default CaseStudiesDetailsDrawer;
