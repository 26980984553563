/* eslint-disable import/no-unresolved */
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { EnumService } from 'tdc-web-backend/enums/enums';
import { FeaturedCaseStudiesDto } from 'tdc-web-backend/featured-case-studies/schemas';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import CustomButton from '../../../../components/button/CustomButton';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { urlSearchParamsStringMapper } from '../../../../utils/helpers';
import CaseStudyCard from './CaseStudyCard';

const CaseStudiesPerCategory = ({
  data,
  sectionTitle,
  bgcolor,
}: {
  data: FeaturedCaseStudiesDto | undefined;
  sectionTitle: string;
  bgcolor?: string;
}) => {
  const navigate = useNavigate();

  const queryParam =
    data?.linkedServices &&
    urlSearchParamsStringMapper(data?.linkedServices as EnumService[], 'service');

  return (
    <Stack bgcolor={bgcolor} sx={{ position: 'relative' }}>
      <Stack
        direction="column"
        alignItems="center"
        alignContent="flex-start"
        py={10}
        gap={5}
        sx={{ mx: { xs: '1.5rem', gr: 'auto' }, width: { gr: '80rem' } }}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Typography variant="publicBody1">{sectionTitle}</Typography>
          <CustomButton
            variant="primaryText"
            onClick={() => navigate(`/case-study-subarchive?${queryParam}`)}
          >
            View all
          </CustomButton>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            maxWidth: { xs: 'none', bg: '30rem', lg: 'none' },
          }}
          direction={{ xs: 'column', bg: 'row' }}
          spacing={3}
          gap={0}
          justifyContent="center"
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            gap={3}
            sx={{
              maxWidth: { xs: 'none', bg: '30rem', lg: 'none' },
              width: '100%',
            }}
          >
            {data?.caseStudies?.slice(0, 2).map((caseStudy: Partial<BaseCaseStudy>) => (
              <CustomReferenceField
                resource="case-studies"
                key={caseStudy.id}
                displayName={caseStudy.title as string}
                id={caseStudy.id as string}
                renderComponent={(item) => <CaseStudyCard data={item} />}
              />
            ))}
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            gap={3}
            sx={{
              width: '100%',
              maxWidth: { xs: 'none', bg: '30rem', lg: 'none' },
            }}
          >
            {data?.caseStudies?.slice(2, 4).map((caseStudy: Partial<BaseCaseStudy>) => (
              <CustomReferenceField
                resource="case-studies"
                key={caseStudy.id}
                displayName={caseStudy.title as string}
                id={caseStudy.id as string}
                renderComponent={(item) => <CaseStudyCard data={item} />}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CaseStudiesPerCategory;
