import { Typography } from '@mui/material';
import { Step } from 'react-joyride';

const joyrideConfig : Partial<Step> = {
  disableBeacon: true,
  hideCloseButton: false,
  hideFooter: false,
  placement: 'auto',
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};

const ContractsDetailScreenJoyride = (userBuyer: boolean): Step[] => [
  {
    content: (
      <Typography>
        Here you can view and filter your contracts across all statuses.
        For example, to view only active contracts, navigate to Active and click it.
        Let's go through all statuses in more detail.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-1-contracts',
    title: 'Contracts status',
  },
  {
    content: (
      <Typography>
        Contracts in Active status have been activated by
        TDA admins. This means that all
        terms have been finalized and you can start working on the project.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-2-contracts',
    title: 'Active status',
  },
  {
    content: (
      <Typography>
        Contracts in Negotiation status are still in the editing process.
        Once all terms have been agreed upon, the next step is to sign your contract.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-3-contracts',
    title: 'Negotiation status',
  },
  {
    content: (
      <Typography>
        Contracts in Accepted status have been successfully submitted by both parties.
        They need to be signed through DocuSign and activated by TDA admins.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-4-contracts',
    title: 'Accepted status',
  },
  {
    content: (
      <Typography>
        Contracts in Canceled status have been terminated and are no longer ongoing on the platform.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-5-contracts',
    title: 'Canceled status',
  },
  {
    content: (
      <Typography>
        You can sort your contracts by date created, date signed or by alphabetical order.
      </Typography>
    ),
    ...joyrideConfig,
    disableScrolling: true,
    target: '.react-joyride-step-6-contracts',
    title: 'Sort By',
  },
  {
    content: (
      <Typography>
        Your contract folder will give you an
        overview of all documentation created during a project.
        To view all contracts and annexes in more detail, click a project folder.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-7-contracts',
    title: 'Project documentation',
  },
];

export const ContractsDetailDrawerJoyride = (userBuyer: boolean): Step[] => [
  {
    content: (
      <Typography>
        You can click on the project shortcut to enter your project screen.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-1-contract-drawer',
    title: 'Project shortcut',
  },
  {
    content: (
      <Typography>
        By editing your contract terms, you will automatically create a
        contract annex that needs to be accepted and signed by both parties.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-2-contract-drawer',
    title: 'Edit contract',
  },
  {
    content: (
      <Typography>
        Deleting your contract will stop all your current project progress on the platform.
        Your deletion request will also need to be verified by the other party and by TDA.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-3-contract-drawer',
    title: 'Delete contract',
  },
  {
    content: (
      <Typography>
        The original version of your contract will be available for download here.
        Simply click the icon to receive your contract in pdf format.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-4-contract-drawer',
    title: 'Download contract',
  },
  {
    content: (
      <Typography>
        All of your contract annexes will be listed here,
        along with their current status and date signed overviews.
        Once they have been signed by both parties, they will also be available for download.
      </Typography>
    ),
    ...joyrideConfig,
    target: '.react-joyride-step-5-contract-drawer',
    title: 'Annexes',
  },
];

export default ContractsDetailScreenJoyride;
