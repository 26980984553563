import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { EnumIndustry, mapIndustry } from 'tdc-web-backend/enums/enums';
import { Box } from '@mui/system';
import ConfirmModal from '../../../../../../../components/confirm-modal/ConfirmModal';
import { useSellerHomescreenContext } from '../../../SellerHomescreenContext';
import useUpdate from '../../../../../../../utils/hooks/crud-hooks/useUpdate';
import { useRefresh } from '../../../../../../../utils/hooks/crud-hooks/useRefresh';
import { primaryLight } from '../../../../../../../color';
import { ReactComponent as SuitcaseCheckIcon } from '../../../../../../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import CustomInput from '../../../../../../../components/custom-input/CustomInput';

interface CompanyTargetIndustriesEditModalProps {
  isEditModalOpen: boolean;
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanyTargetIndustriesEditModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
}: CompanyTargetIndustriesEditModalProps) => {
  const { companyData } = useSellerHomescreenContext();
  const refresh = useRefresh();

  const methods = useForm<Pick<CompanyDto, 'targetIndustries'>>({
    mode: 'onChange',
    defaultValues: {
      targetIndustries: companyData?.targetIndustries ?? ([] as EnumIndustry[]),
    },
  });

  const { mutate: update, isLoading } = useUpdate<CompanyDto, Partial<CompanyDto>>({
    resource: 'companies',
  });

  const onSubmit = (data: Pick<CompanyDto, 'targetIndustries'>) => {
    update(
      { id: companyData?.id as string, data },
      {
        onSuccess: () => {
          refresh();
          setIsEditModalOpen(false);
        },
      },
    );
  };

  return (
    <ConfirmModal
      width="40%"
      title="Target Industries"
      isModalOpen={isEditModalOpen}
      setIsModalOpen={setIsEditModalOpen}
      onCancel={() => setIsEditModalOpen(false)}
      onConfirm={methods.handleSubmit(onSubmit)}
      confirmButtonText="Save"
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      iconProps={{
        icon: <SuitcaseCheckIcon fill={primaryLight[500]} />,
        wrapperBackgroundColor: primaryLight[100],
      }}
      message={
        <FormProvider {...methods}>
          <Box component="form">
            <CustomInput
              required
              input={<CustomInput.Autocomplete />}
              choices={mapIndustry}
              name="targetIndustries"
              label="Target client industries"
              multiple
              placeholder="Select target industries"
              sx={{
                width: '100%',
              }}
            />
          </Box>
        </FormProvider>
      }
    />
  );
};

export default CompanyTargetIndustriesEditModal;
