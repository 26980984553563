import { Avatar, Badge, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ReactComponent as PenIcon } from '../../../../assets/icons/pencil-2.svg';
import { primaryLight } from '../../../../color';
import AvatarEditorModal from './AvatarEditorModal';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import useReferenceOne from '../../../../utils/hooks/crud-hooks/useReferenceOne';

const SidebarProfileBanner = () => {
  const [open, setOpen] = useState(false);

  const userData = useGetLocalStorageUserData();
  const { data: companyData } = useReferenceOne<CompanyDto>({
    resource: 'companies',
    id: userData?.membership?.company,
    retry: 1,
    enabled: !!userData,
  });

  return (
    <Stack direction="column" gap={1} justifyContent="center" alignItems="center">
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              width: '25px',
              height: '25px',
              bgcolor: 'primaryLight.50',
              borderRadius: '4px',
              padding: '2px',
              '&:hover': {
                bgcolor: 'primaryLight.100',
              },
            }}
          >
            <PenIcon fill={primaryLight[500]} />
          </IconButton>
        }
      >
        <Avatar
          alt={companyData?.name}
          src={companyData?.avatar}
          sx={{
            height: '5rem',
            width: '5rem',
            fontSize: 40,
            backgroundColor: companyData?.avatar ? 'white' : 'primaryDark.400',
          }}
        />
      </Badge>
      <Typography variant="headline4">{companyData?.name}</Typography>
      <AvatarEditorModal open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default SidebarProfileBanner;
