import { Box, Link, Popover, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import { FormProvider, useForm } from 'react-hook-form';
import CustomButton from '../../../components/button/CustomButton';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import { ReactComponent as FilesIcon } from '../../../assets/icons/chat-icons/files.svg';
import ControlledTextInput from '../../../components/custom-inputs/ControlledTextInput';
import { formatBytes, iconUrlForFile } from '../../../utils/helpers';
import { useChatStore } from '../useChatStore';

export default ({ channelId }: { channelId: string }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAttachment, selectAttachment] = useChatStore((state) => [
    state.selectedAttachment,
    state.selectAttachment,
  ]);

  const open = Boolean(anchorEl);
  const id = open ? 'files-popover' : undefined;

  const form = useForm({ defaultValues: { search: '' } });
  const search = form.watch('search');

  const attachmentResponse = useGetMany<AttachmentDto>({
    resource: `attachments?channel=${channelId}&search=${search}`,
  });
  const attachments = attachmentResponse.data?.data.results ?? [];

  const handleClick = (event: any) => {
    attachmentResponse.refetch();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    form.reset();
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        variant="secondary"
        onClick={handleClick}
        startIcon={<FilesIcon />}
        sx={{ minWidth: 0 }}
      >
        Files
      </CustomButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Stack sx={{ borderRadius: '12px', width: '300px', height: '500px' }}>
          <FormProvider {...form}>
            <Box p={1.5}>
              <ControlledTextInput name="search" fullWidth placeholder="Search files" />
            </Box>
          </FormProvider>
          <Stack direction="column" overflow="scroll">
            {attachments.map((attachment) => (
              <Link
                href="#"
                underline="none"
                onClick={() => {
                  selectAttachment(attachment);
                  handleClose();
                }}
              >
                <Box
                  px={2.5}
                  py={2}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'secondaryBlue.50',
                    },
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Box
                      height="40px"
                      width="40px"
                      sx={{ position: 'relative', display: 'inline-flex' }}
                    >
                      <Box
                        component="img"
                        sx={{ width: 30, height: 40, objectFit: 'cover' }}
                        src={iconUrlForFile(attachment.fileName)}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography
                        variant="bodySemiBold3"
                        color="primaryDark.600"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {attachment.fileName}
                      </Typography>
                      <Typography variant="body4" color="primaryDark.500">
                        {formatBytes(attachment.size)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Link>
            ))}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
