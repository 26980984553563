import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '../button/Button';

export interface SuccessScreenProps {
  heading: string;
  subheading: string;
  buttonText: string;
  buttonLinkTo: string;
}

const SuccessScreen = ({
  heading, subheading, buttonText, buttonLinkTo,
}: SuccessScreenProps) => {
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{ width: '100%', height: '100%' }}
    >
      <Typography variant="h2">
        {heading}
      </Typography>

      <Typography sx={{ maxWidth: '68%', textAlign: 'center' }}>
        {subheading}
      </Typography>

      <Button
        linkTo={buttonLinkTo}
        onButtonClick={(buttonLinkTo?: string | null) => {
          navigate(buttonLinkTo ?? '/');
        }}
        setBorderRadius
        fontWeight="bold"
        variant="contained"
        color="secondary"
        setUpperCase
        minWidth={220}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default SuccessScreen;
