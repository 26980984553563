import CustomChip from '../../../../../../../components/custom-chip/CustomChip';

type BlogCategoryChipProps = {
  label: string;
  active?: boolean;
  onClick?: () => void;
};

const BlogCategoryChip = ({ label, active = false, onClick }: BlogCategoryChipProps) => (
  <CustomChip
    styleVariant="rounded"
    label={label}
    sx={{
      backgroundColor: active ? 'primaryLight.500' : '#FFF',
      borderColor: 'primaryLight.50',
      color: active ? 'primaryLight.50' : 'primaryLight.500',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: active ? 'primaryLight.500' : '#FFF',
      },
      textTransform: 'none',
      fontSize: 14,
      lineHeight: '21px',
      fontFamily: 'Gellix-SemiBold',
      py: '8px',
    }}
    onClick={onClick}
  />
);
export default BlogCategoryChip;
