import { Typography } from '@mui/material';

const Header = () => (
  <>
    <Typography variant="publicHeading2" align="center" color="primaryDark.900">
      Plans & Pricing
    </Typography>
    <Typography
      variant="publicBody5Regular"
      width={{ lg: '30%' }}
      align="center"
      color="primaryDark.900"
    >
      A plan for every company's project needs. Get started for free.
    </Typography>
  </>
);

export default Header;
