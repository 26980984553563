/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
import { Box, ClickAwayListener, Drawer, IconButton, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Stack, textTransform } from '@mui/system';
import { Close } from '@mui/icons-material';
import { mapCountry, mapIndustry, mapProjectSize, mapService } from 'tdc-web-backend/enums/enums';
import { FormProvider, useForm } from 'react-hook-form';
import useQueryState from '../../../../utils/hooks/useQueryState';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import CustomInput from '../../../../components/custom-input/CustomInput';
import CustomButton from '../../../../components/button/CustomButton';
import { mapTechLanguage, mapTechPlatform, mapTechTool } from '../../../../enums';

type Props = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const FilterDrawer = ({ open, setIsOpen }: Props) => {
  const [, setQueryState, removeQueryState, searchParams] = useQueryState();

  const formMethods = useForm({
    defaultValues: {
      orderBy: '-published',
      service: [],
      targetIndustry: [],
      targetMarket: [],
      platform: [],
      language: [],
      averageProjectSize: '',
      tool: [],
    },
  });

  useEffect(() => {
    formMethods.reset({
      orderBy: (searchParams.get('orderBy') as never) || '-published',
      service: (searchParams.getAll('service') as never[]) || [],
      targetIndustry: (searchParams.getAll('targetIndustry') as never[]) || [],
      targetMarket: (searchParams.getAll('targetMarket') as never[]) || [],
      platform: (searchParams.getAll('platform') as never[]) || [],
      tool: (searchParams.getAll('tool') as never[]) || [],
      language: (searchParams.getAll('language') as never[]) || [],
      averageProjectSize: (searchParams.get('averageProjectSize') as never) || '',
    });
  }, [open]);

  const onSubmit = (data: any) => {
    setQueryState(data);
    setIsOpen(false);
  };
  return (
    <Drawer
      disablePortal
      anchor="left"
      open={open}
      onClose={() => setIsOpen(false)}
      hideBackdrop
      elevation={4}
      PaperProps={{
        sx: {
          mt: `${PUBLIC_APPBAR_HEIGHT + 2}px`,
          borderTopRightRadius: '0.25rem',
        },
      }}
    >
      <FormProvider {...formMethods}>
        <ClickAwayListener onClickAway={(e) => setIsOpen(false)}>
          <Box
            component="form"
            sx={{
              width: '100%',
              height: '100%',
            }}
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <Stack
              width={{ md: '35vw', lg: '25vw' }}
              direction="column"
              alignItems="end"
              p={2}
              pt={3}
            >
              <Stack gap={1} pt={4} bgcolor="white" width="100%">
                <Stack
                  width="100%"
                  mb={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="publicHeading4" color="primaryDark.900">
                    All filters
                  </Typography>
                  <IconButton onClick={() => setIsOpen(!open)}>
                    <Close sx={{ color: 'primaryDark.500' }} />
                  </IconButton>
                </Stack>
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="service"
                  placeholder="Category "
                  choices={mapService}
                  multiple
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="targetIndustry"
                  placeholder="Buyer Industry"
                  choices={mapIndustry}
                  multiple
                  withChips={false}
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="targetMarket"
                  placeholder="Target market"
                  choices={mapCountry}
                  multiple
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="averageProjectSize"
                  placeholder="Project Budget"
                  choices={mapProjectSize}
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="platform"
                  placeholder="Tech platforms"
                  choices={mapTechPlatform}
                  multiple
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="tool"
                  placeholder="Tech tools"
                  choices={mapTechTool}
                  multiple
                />
                <CustomInput
                  sx={{
                    minHeight: 10,
                    minWidth: 'fit-content',
                  }}
                  input={<CustomInput.SearchSelect />}
                  name="language"
                  placeholder="Programming languages"
                  choices={mapTechLanguage}
                  multiple
                />
                <Stack direction="row" gap={2} mt={2}>
                  <CustomButton
                    variant="primaryLight"
                    onClick={() => {
                      formMethods.reset({
                        orderBy: '-published',
                        service: [],
                        targetIndustry: [],
                        targetMarket: [],
                        platform: [],
                        language: [],
                        averageProjectSize: '',
                        tool: [],
                      });
                      removeQueryState('orderBy');
                      removeQueryState('service');
                      removeQueryState('targetIndustry');
                      removeQueryState('targetMarket');
                      removeQueryState('platform');
                      removeQueryState('language');
                      removeQueryState('averageProjectSize');
                      removeQueryState('tool');

                      setIsOpen(false);
                    }}
                  >
                    Clear filters
                  </CustomButton>
                  <CustomButton type="submit">Apply all</CustomButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </ClickAwayListener>
      </FormProvider>
    </Drawer>
  );
};

export default FilterDrawer;
