import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { useMutation } from 'react-query';
import Button from '../../../../../components/button/Button';
import axios from '../../../../../api/axios';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';

export interface CancelProjectModalProps {
  initialContract: ContractDto;
}

const StartProjectModal = ({ initialContract }: CancelProjectModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const refresh = useRefresh();
  const { projectId } = useParams();

  const { mutate: start } = useMutation({
    mutationFn: () => axios.patch(`/projects/${projectId}/start`),
    onSuccess: () => refresh(),
  });

  const handleStart = () => {
    start();
    setIsOpen(false);
  };

  const isBtnDisabled =
    !!initialContract?.signed ||
    !(initialContract?.buyerApproved && initialContract?.sellerApproved);

  return (
    <>
      <Button
        color="secondary"
        sx={{
          width: '90%',
          fontWeight: 500,
        }}
        setBorderRadius
        variant="contained"
        disabled={isBtnDisabled}
        size="large"
        onButtonClick={() => setIsOpen(true)}
      >
        Start
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Stack
          sx={{
            width: '40%',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Paper
            sx={{
              padding: '2em',
            }}
          >
            <Stack spacing={2}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '1.25em',
                    fontWeight: 600,
                    mt: 3,
                    mb: 2,
                  }}
                >
                  Start project
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    fontSize: '1em',
                    fontWeight: 500,
                    mt: 3,
                  }}
                >
                  Contract is signed by both parties. Project is ready to start.
                </Typography>
              </Stack>
              <Divider />
              <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
                <Button
                  variant="text"
                  onButtonClick={() => setIsOpen(false)}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onButtonClick={() => handleStart()}
                  sx={{
                    borderRadius: '100px',
                    fontWeight: 500,
                  }}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Modal>
    </>
  );
};
export default StartProjectModal;
