import { Avatar, Stack, SxProps, Theme } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { ReactComponent as IconMedium } from '../../../../assets/img/public-pages/medium-icon.svg';
import { ReactComponent as IconGithub } from '../../../../assets/img/public-pages/github-icon.svg';
import { ReactComponent as IconDribbble } from '../../../../assets/img/public-pages/dribbble-icon-seller-social.svg';
import { ReactComponent as IconFacebook } from '../../../../assets/img/public-pages/facebook-icon-seller-social.svg';
import { ReactComponent as IconLinkedin } from '../../../../assets/img/public-pages/linkedin-icon-seller-social.svg';
import { ReactComponent as IconTwitter } from '../../../../assets/img/public-pages/x-icon-seller-social.svg';
import { ReactComponent as IconInsta } from '../../../../assets/img/public-pages/instagram-icon-seller-social.svg';

export type SocialIconMapping = {
  [key: string]: React.ComponentType;
};

const socialIcons: SocialIconMapping = {
  medium: IconMedium,
  dribbble: IconDribbble,
  facebook: IconFacebook,
  github: IconGithub,
  instagram: IconInsta,
  linkedin: IconLinkedin,
  twitter: IconTwitter,
};

const companyProfileMediaLinks = ({
  company,
  sx,
}: {
  company: CompanyDto | undefined;
  sx?: SxProps<Theme>;
}) => (
  <Stack direction="row" alignItems="center" justifyContent="center" gap={2.5} sx={{ ...sx }}>
    {company?.socialMediaLinks &&
      Object.entries(company.socialMediaLinks).map(([platform, link]) => {
        if (!link) {
          return null;
        }
        const IconComponent = socialIcons[platform.toLowerCase()];

        return (
          <a href={link as string} key={platform} target="_blank" rel="noopener noreferrer">
            {IconComponent && (
              <Avatar sx={{ width: '24px', height: '24px' }}>
                <IconComponent />
              </Avatar>
            )}
          </a>
        );
      })}
  </Stack>
);

export default companyProfileMediaLinks;
