import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AttachmentDto } from 'tdc-web-backend/attachments/schemas';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/chat-icons/download.svg';
import { formatBytes, iconUrlForFile } from '../../../utils/helpers';
import { useChatStore } from '../useChatStore';

type ChatMessageAttachmentProps = {
  attachment: AttachmentDto;
};

export default ({ attachment }: ChatMessageAttachmentProps) => {
  const selectAttachment = useChatStore((state) => state.selectAttachment);

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Box
        height="40px"
        width="40px"
        sx={{
          position: 'relative',
          display: 'inline-flex',
        }}
      >
        <Box
          component="img"
          sx={{
            width: 30,
            height: 40,
            objectFit: 'cover',
          }}
          src={iconUrlForFile(attachment.fileName)}
        />
      </Box>
      <Stack direction="column">
        <Typography variant="bodySemiBold3" color="primaryDark.600">
          <Link href="#" underline="hover" onClick={() => selectAttachment(attachment)}>
            {attachment.fileName}
          </Link>
        </Typography>
        <Typography variant="body4" color="primaryDark.500">
          {formatBytes(attachment.size)}
        </Typography>
      </Stack>
      <Link sx={{ p: 1, marginLeft: 'auto' }} href={attachment.file} download target="_blank">
        <DownloadIcon />
      </Link>
    </Stack>
  );
};
