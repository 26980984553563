import { Box, Stack, Typography } from '@mui/material';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { HeroProps } from '../interface';

const LaptopSection = ({ imgUrl, link, title, description, linkText, location }: HeroProps) => (
  <Box
    sx={{
      mx: { xs: '0.5rem', sm: '1.5rem', gr: 'auto' },
      maxWidth: '80rem',
      width: { gr: '100%' },
      mt: { xs: '1.5rem', sm: '3rem' },
    }}
  >
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        borderRadius: '1.25rem',
        bgcolor: 'secondaryOrange.401',
        mr: { xs: '0', md: '2%', bg: '16%', lg: '15%', gr: '15.5%', xxl: '15%' },
        height: '30.5rem',
      }}
      position="relative"
      spacing={{ xs: location === 'seller-archive' ? 4 : 7, sm: 1 }}
    >
      <Stack
        spacing={2}
        sx={{
          ml: { md: '1.5rem', lg: '1.75rem', gr: '2rem' },
          px: { xs: '1.5rem', md: '0' },
          pb: { xs: '2.5rem', md: '0' },
        }}
      >
        <Typography
          whiteSpace="pre-line"
          sx={{
            color: '#FFFFFF',
            typography: { xs: 'publicHeading4', md: 'publicHeading3' },
            maxWidth:
              location === 'seller-archive'
                ? { xs: '100%', md: '34rem', lg: '100%' }
                : { xs: '100%', md: '100%' },
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            typography: { xs: 'publicBody5Regular', sm: 'publicBody4Light' },
            color: '#FFFFFF',
            maxWidth: { xs: '100%', md: '28rem' },
            zIndex: 10,
          }}
        >
          {description}
        </Typography>

        <CustomButton
          href={link}
          variant="primaryText"
          endIcon={<ArrowRightIcon fill="white" />}
          sx={{ fontSize: '1rem', color: 'white' }}
        >
          {linkText}
        </CustomButton>
      </Stack>
      <Box
        component="img"
        src={imgUrl}
        sx={{
          width: {
            xs: '20.8125rem',
            sm: '28.6875rem',
            md: '37rem',
            bg: '44.8125rem',
            lg: '45.25rem',
          },
          position: { xs: 'unset', md: 'absolute' },
          right: { xs: '0', md: '-2%', bg: '-19%', lg: '-18%', gr: '-20%', xxl: '-19%' },
        }}
      />
    </Stack>
  </Box>
);

export default LaptopSection;
