import {
  Stack, SxProps, Theme,
} from '@mui/material';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { EnumProjectTaskStatus } from 'tdc-web-backend/enums/enums';
import { Dispatch, SetStateAction } from 'react';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import CircularProgressWithLabel
  from '../../../../components/review-score/CircularProgressWithLabel';
import Button from '../../../../components/button/Button';

export interface TaskStatusCardProps {
  taskData: TaskDto;
  taskStatus: EnumProjectTaskStatus;
  isTaskFlagged: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps<Theme> | undefined;
}

const TaskStatusCard = ({
  taskData,
  taskStatus,
  isTaskFlagged,
  setIsModalOpen,
  sx,
}: TaskStatusCardProps) => {
  const score = Math.ceil((taskData?.subtasksCompletedCount / taskData?.subtasksCount) * 100);

  return (
    <Stack spacing={4}>
      <Stack direction="row" sx={{ justifyContent: 'space-around' }}>
        <CircularProgressWithLabel
          displayScore
          size={10}
          truePercentage={isNaN(score) ? 0 : score}
        />
      </Stack>

      <Button
        color="secondary"
        className="react-joyride-step-2-task"
        variant="outlined"
        setBorderRadius
        disabled={
          (() => {
            // if task status is pending, disable the button
            if (taskStatus === EnumProjectTaskStatus.Pending) {
              return true;
            }

            // every else status enable the button so it can be flagged
            return false;
          })()
        }
        onButtonClick={() => setIsModalOpen(true)}
        startIcon={<AssistantPhotoOutlinedIcon />}
        size="large"
      >
        {isTaskFlagged ? 'Un-Flag task' : 'Flag task'}
      </Button>
    </Stack>
  );
};

export default TaskStatusCard;
