import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ErrorAlertIcon } from '../../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { secondaryBlue, secondaryPink } from '../../../../../../color';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check.svg';
import ConfirmModal from '../../../../../../components/confirm-modal/ConfirmModal';

const modalsContent = {
  success: {
    title: 'Successfully submitted',
    text: (
      <>
        Thank you for submitting your form! <br /> <br />
        We appreciate your interest in Spona Media. Our team will reach out shortly with more
        information on how we can get started together.
      </>
    ),
    icon: <CheckIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryBlue[100],
  },
  error: {
    title: 'Error',
    text: <>Something went wrong.Please try again later.</>,
    icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
    wrapperBackgroundColor: secondaryPink[100],
  },
};

type ModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: 'success' | 'error';
  onConfirm?: () => void;
};

const OnSubmitModal = ({ isOpen, setIsOpen, type, onConfirm }: ModalProps) => (
  <ConfirmModal
    isModalOpen={isOpen}
    setIsModalOpen={setIsOpen}
    title={modalsContent[type].title}
    width="100%"
    maxWidth="650px"
    message={
      <Typography color="primaryDark.500" lineHeight="21px">
        {modalsContent[type].text}
      </Typography>
    }
    confirmButtonText="OK"
    onConfirm={onConfirm}
    iconProps={{
      icon: modalsContent[type].icon,
      wrapperBackgroundColor: modalsContent[type].wrapperBackgroundColor,
    }}
    showCancelButton={false}
  />
);
export default OnSubmitModal;
