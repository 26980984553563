/* eslint-disable import/no-unresolved */
import { Avatar, Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import {
  EnumCompanySize,
  EnumCountry,
  EnumProjectHourlyRate,
  EnumProjectSize,
  mapCompanySize,
  mapCountry,
  mapHourlyRate,
  mapProjectSize,
  mapService,
} from 'tdc-web-backend/enums/enums';
import { ReactComponent as Tag } from '../../assets/img/public-pages/tag.svg';
import { ReactComponent as Pin } from '../../assets/img/public-pages/pin.svg';
import { ReactComponent as Clock } from '../../assets/img/public-pages/clock.svg';
import { ReactComponent as ThreeUsers } from '../../assets/img/public-pages/threeUsers.svg';
import { primaryDark } from '../../color';
import CustomButton from '../button/CustomButton';
import CustomChip from '../custom-chip/CustomChip';
import CustomTooltip from '../tooltip/CustomTooltip';
import { truncate } from '../../utils/helpers';

export interface ComapnyCardProps {
  companyData: CompanyDto;
  showFocusArea?: boolean;
  carouselItem?: boolean;
}

const CompanyCard = ({
  companyData,
  showFocusArea = false,
  carouselItem = false,
}: ComapnyCardProps) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMD = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLG = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  let charLimit;

  if (isXS) {
    charLimit = 15;
  } else if (isSM) {
    charLimit = 25;
  } else if (isMD) {
    charLimit = 25;
  } else if (isLG) {
    charLimit = 20;
  } else if (isXL) {
    charLimit = 35;
  } else {
    charLimit = 30;
  }

  const headquartersLocation = companyData?.headquartersLocation
    ? `${companyData?.headquartersLocation?.city}, ${mapCountry.get(
        companyData?.headquartersLocation?.country as EnumCountry,
      )}`
    : '---';
  const useTooltipForHeadquarters = headquartersLocation.length > charLimit;

  return (
    <Box
      sx={{
        py: 2.5,
        p: 1.5,
        width: '100%',
        borderRadius: '0.8rem',
        boxShadow: '  6px 6px 17px 0px #3845721A',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        minHeight: '100%',
        height: showFocusArea ? '' : '416px',
      }}
    >
      <Stack
        flexDirection="row"
        maxHeight="fit-content"
        width="100%"
        alignItems="center"
        p={2}
        px={0}
        gap={2}
      >
        <Avatar
          alt={companyData.name}
          src={companyData?.avatar}
          sx={{
            width: '60px',
            height: '60px',
            bgcolor: 'primaryLight.400',
          }}
        >
          {companyData.name?.[0]}
        </Avatar>
        <CustomTooltip title={companyData.name.length > 35 ? companyData.name : ''} placement="top">
          <Typography
            variant="publicBody3"
            lineHeight="normal"
            color="primaryDark.600"
            whiteSpace="initial"
            sx={{ lineBreak: 'anywhere' }}
          >
            {truncate(companyData?.name, carouselItem ? 20 : 35)}
          </Typography>
        </CustomTooltip>
      </Stack>
      <Divider sx={{ borderColor: '#E5E8FF', width: '100%' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          minHeight: showFocusArea
            ? { xs: '400px', sm: '330px', md: '440px', lg: '380px', xl: '320px' }
            : 'fit-content',
        }}
      >
        <Stack gap={2} py={showFocusArea ? 2 : 4} width="100%">
          <Typography
            variant="publicBody5Regular"
            color="primaryDark.500"
            sx={{ fontWeight: 600, lineHeight: '18px' }}
          >
            DETAILS
          </Typography>
          <Stack direction="row" pl={1} gap={1.5} justifyContent="start" alignItems="center">
            <Pin fill={primaryDark[500]} height={16} />
            <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 450 }}>
              {useTooltipForHeadquarters ? (
                <CustomTooltip title={headquartersLocation}>
                  <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 450 }}>
                    {truncate(headquartersLocation, charLimit)}
                  </Typography>
                </CustomTooltip>
              ) : (
                <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 500 }}>
                  {headquartersLocation}
                </Typography>
              )}
            </Typography>
          </Stack>
          <Stack direction="row" pl={1} gap={1.5} justifyContent="start" alignItems="center">
            <Clock fill={primaryDark[500]} height={16} />
            <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 500 }}>
              {companyData.hourlyRate
                ? mapHourlyRate.get(companyData.hourlyRate as EnumProjectHourlyRate)
                : '---'}
            </Typography>
          </Stack>
          <Stack direction="row" pl={1} gap={1.5} justifyContent="start" alignItems="center">
            <ThreeUsers fill={primaryDark[500]} height={16} />
            <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 500 }}>
              {companyData.companySize
                ? mapCompanySize.get(companyData.companySize as EnumCompanySize)
                : '---'}
            </Typography>
          </Stack>
          <Stack direction="row" pl={1} gap={1.5} justifyContent="start" alignItems="center">
            <Tag fill={primaryDark[500]} height={16} />
            <Typography color="primaryDark.500" variant="body3" sx={{ fontWeight: 450 }}>
              {companyData.averageProjectSize
                ? mapProjectSize.get(companyData.averageProjectSize as EnumProjectSize)
                : '---'}
            </Typography>
          </Stack>
        </Stack>
        {showFocusArea && (
          <>
            <Typography
              sx={{ fontWeight: 600, lineHeight: '18px' }}
              variant="publicBody5Regular"
              color="primaryDark.500"
            >
              FOCUS AREA
            </Typography>
            <Stack
              direction="row"
              pt={1}
              pl={1}
              gap={1.5}
              justifyContent="start"
              alignItems="center"
              flexWrap="wrap"
            >
              {companyData.services?.slice(0, 5)?.map((item: any) => (
                <CustomChip label={mapService.get(item)} />
              ))}
            </Stack>
          </>
        )}
      </Box>
      <CustomButton
        sx={{ p: '0', mt: 1, mb: 2, justifySelf: 'end' }}
        href={`/company/${companyData.id}`}
      >
        view profile
      </CustomButton>
    </Box>
  );
};

export default CompanyCard;
