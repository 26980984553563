import { useNavigate } from 'react-router-dom';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { format } from 'date-fns';
import { Box, Stack, SxProps, Theme } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomChip from '../../../../components/custom-chip/CustomChip';
import { truncate } from '../../../../utils/helpers';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';

type BlogCardProps =
  | {
      blogData: BlogDto;
      featured?: false | undefined;
      orientation?: 'horizontal' | 'vertical';
    }
  | {
      blogData: BlogDto;
      featured: true;
      orientation: 'horizontal' | 'vertical';
    };

const BlogCard = ({ blogData, orientation, featured }: BlogCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const headlineTruncateNo = 90;
  const leadTextTruncateNo = 140;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        // maxWidth: 410,
        boxShadow: 2,
        borderRadius: 2,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        m: 1,
        bgcolor: 'background.paper',
        cursor: 'pointer',
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/blog/${blogData?.slug}`);
      }}
    >
      <Stack
        direction={orientation === 'horizontal' ? 'row' : 'column'}
        spacing={featured ? 1.5 : 2}
      >
        <Box
          component="img"
          sx={{
            // height: 207,
            height: featured && orientation === 'vertical' ? 232 : 205,
            width: '100%',
            objectFit: 'cover',
            maxWidth: featured && orientation === 'horizontal' ? '55%' : 'none',
            minWidth: featured && orientation === 'horizontal' ? '55%' : 'none',
          }}
          src={blogData.file}
          alt="Image description"
        />
        <Stack
          direction="column"
          px={orientation === 'horizontal' ? 0 : 1.5}
          pr={1.5}
          spacing={featured && orientation !== 'vertical' ? 1.5 : 2}
          pb={featured && orientation === 'horizontal' ? 2 : 3}
          pt={featured && orientation === 'horizontal' ? 2 : 0}
        >
          {!featured && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body3" color="primaryDark.500" sx={{ lineHeight: '1.25rem' }}>
                {blogData?.published ? format(new Date(blogData?.published), 'MMMM dd, yyyy') : ''}
              </Typography>
            </Stack>
          )}
          <CustomTooltip
            title={blogData.headline.length > headlineTruncateNo ? blogData.headline : ''}
            placement="top"
          >
            <Typography
              variant="bodySemiBold2"
              color="primaryDark.900"
              sx={{
                lineHeight: '1.5rem',
                minHeight: '48px',
                maxHeight: '48px',
                overflow: 'hidden',
              }}
            >
              {truncate(blogData.headline ?? '', headlineTruncateNo)}
            </Typography>
          </CustomTooltip>
          <CustomTooltip
            title={blogData.leadText.length > leadTextTruncateNo ? blogData.leadText : ''}
            placement="top"
          >
            <Typography
              variant="body3"
              color="primaryDark.900"
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                minHeight: '60px',
                maxHeight: '60px',
                overflow: 'hidden',
              }}
            >
              {truncate(blogData.leadText ?? '', leadTextTruncateNo)}
            </Typography>
          </CustomTooltip>
          <CustomTooltip
            placement="top"
            title={blogData?.categories?.map((item: any) => item.name).join(', ')}
          >
            <Stack
              direction="row"
              // p={2}
              gap={1.5}
              justifyContent="start"
              alignItems="center"
              flexWrap="wrap"
              sx={{ minHeight: '34px', maxHeight: '34px', overflow: 'hidden' }}
            >
              {blogData?.categories?.slice(0, 3).map((item: any) => (
                <CustomChip label={item.name} key={item.id} />
              ))}
            </Stack>
          </CustomTooltip>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BlogCard;
