import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material';
import { ReactNode } from 'react';
import CustomNoRowsOverlay from '../../custom-no-rows-overlay/CustomNoRowsOverlay';

export type ColumnDefs<T> = {
  field: string;
  flex?: number;
  columnsStyle?: TypographyProps;
  valueFormatter?: (params: T) => string | number | undefined;
  icon?: JSX.Element;
  renderColumn?: (params: T) => ReactNode;
  secondaryField?: string | number | JSX.Element | ((params: T) => ReactNode);
  iconStyle?: SxProps<Theme>;
  align?: 'start' | 'center' | 'end';
};

interface CustomListProps<T> {
  items: T[];
  columns: ColumnDefs<T>[];
  onClick?: (data: T) => void;
  sx?: SxProps<Theme>;
}

const CustomList = <T,>({ items, columns, onClick, sx }: CustomListProps<T>) => (
  <List
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      py: 0,
      position: 'relative',
      gap: 1.5,
      ...sx,
    }}
  >
    {items.map((item) => (
      <ListItem sx={{ p: 0 }} disablePadding>
        <ListItemButton
          // if onClick gets omitted, ripple effect is disabled
          // and onClick does nothing
          disableRipple={!onClick}
          onClick={() => (onClick ? onClick(item) : {})}
          sx={{ borderRadius: 2, p: 0, py: 0 }}
        >
          <Stack direction="row" width="100%" alignItems="center" spacing={1} sx={{ px: 1 }}>
            {columns.map(
              ({
                field,
                columnsStyle,
                icon,
                renderColumn,
                secondaryField,
                iconStyle,
                align = 'center',
                valueFormatter = (params) => params[field as keyof T],
                flex = 1,
              }) => {
                const secondaryFieldCondition =
                  typeof secondaryField === 'function' ? secondaryField(item) : secondaryField;
                return renderColumn ? (
                  <Stack
                    flex={flex}
                    alignItems="center"
                    direction="row"
                    justifyContent={align}
                    sx={{ p: 0 }}
                  >
                    {renderColumn(item)}
                  </Stack>
                ) : (
                  <Stack flex={flex} alignItems="center" direction="row" justifyContent={align}>
                    <>
                      {icon && (
                        <ListItemIcon sx={{ minWidth: 'unset', pr: 1, ...iconStyle }}>
                          {icon}
                        </ListItemIcon>
                      )}

                      <ListItemText
                        primary={valueFormatter(item)}
                        secondary={secondaryFieldCondition}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: '500',
                          color: 'primaryDark.600',
                          ...columnsStyle,
                        }}
                      />
                    </>
                  </Stack>
                );
              },
            )}
          </Stack>
        </ListItemButton>
      </ListItem>
    ))}
    {!items.length && <CustomNoRowsOverlay />}
  </List>
);

export default CustomList;
