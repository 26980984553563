import { ReactNode, useState } from 'react';
import { BlogDto } from 'tdc-web-backend/blogs/schemas';
import { EnumBlogStatus } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomDropdown from '../../../../components/custom-dropdown/CustomDropdown';

const ActionMenu = ({ blog }: { blog: BlogDto }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const menu: { key: string; text: ReactNode; onClick: () => void }[] = [
    {
      key: 'edit',
      text: 'Edit',
      onClick: () => navigate('../edit', { relative: 'path' }),
    },
  ];
  if (blog?.status === EnumBlogStatus.Published && blog.visibility) {
    menu.push({
      key: 'view',
      text: (
        <Link
          target="_blank"
          href={`${window.location.origin}/blog/${blog.slug}`}
          underline="none"
          sx={{
            color: 'primaryDark.500',
          }}
        >
          Go to public version
        </Link>
      ),
      onClick: () => {},
    });
  }

  return (
    <CustomDropdown
      menuItemsContents={menu}
      renderDropdownButtonElement={({ onClick }) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            onClick(e);
            setIsOpen(!isOpen);
          }}
          endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{ borderRadius: '0.25rem', color: 'primaryDark.600' }}
        >
          Editing
        </Button>
      )}
      onClickAway={() => setIsOpen(false)}
      dropdownSx={{
        width: 'max-content',
      }}
    />
  );
};

export default ActionMenu;
