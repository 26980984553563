import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { mapIndustry, mapCountry } from 'tdc-web-backend/enums/enums';
import ControlledTextEditor from '../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../components/editor/menu-option-lists/menu-item-lists';
import Competitors from './Competitors';
import CustomInput from '../../../../components/custom-input/CustomInput';

const About = () => (
  <Stack direction="column" spacing={5}>
    <ControlledTextEditor
      label="project goals"
      name="about.goals"
      items={TimedProjectEditorMenu}
      required
    />

    <Box sx={{ display: 'flex' }}>
      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ flex: 1, alignSelf: 'flex-start' }}
        label="target market"
        placeholder="Select Market"
        name="about.targetMarkets"
        choices={mapCountry}
        multiple
        withChips
        fullWidth
        required
      />

      <Divider orientation="vertical" sx={{ borderColor: '#E5E8FF', margin: '0 24px' }} flexItem />

      <CustomInput
        input={<CustomInput.Autocomplete />}
        sx={{ flex: 1, alignSelf: 'flex-start' }}
        label="industry"
        placeholder="Select Industry"
        name="about.industries"
        choices={mapIndustry}
        multiple
        withChips
        fullWidth
        required
      />
    </Box>

    <ControlledTextEditor
      label="target audience"
      name="about.audience"
      items={TimedProjectEditorMenu}
    />

    <Competitors />
  </Stack>
);

export default About;
