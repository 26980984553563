import { Typography } from '@mui/material';
import { Box } from '@mui/system';

interface TabChipProps {
    value: string
    selectedTab: string
    label: string
    resultCount:number
}

const TabChip = ({
  value, selectedTab, label, resultCount,
}: TabChipProps) => {
  const isSelectedTab = value === selectedTab;

  const chipStyle = {
    cursor: 'pointer',
    borderRadius: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    bgcolor: isSelectedTab ? 'primaryDark.600' : '#FFF',
    border: isSelectedTab ? 'none' : '1px solid #BFC6D7',
    gap: '8px',
    px: '12px',
    color: isSelectedTab ? '#FFF' : 'primaryDark.500',
    textTransform: 'uppercase',
    fontSize: '11px !important',
    fontWeight: 600,
  };

  return (
    <Box
      component="button"
      sx={chipStyle}
    >
      <Typography variant="heading5">
        {label}
      </Typography>
      {' '}
      <Typography
        color={isSelectedTab ? 'common.white' : 'primaryDark.500'}
        variant="heading5"
      >
        {resultCount}
      </Typography>
    </Box>
  );
};

export default TabChip;
