import { Dispatch, SetStateAction } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { TableProps } from './interface';
import BaseTable from './BaseTable';
import CustomChip from '../custom-chip/CustomChip';
import { ChipFilter } from './useTable';

export type TableWithChipFiltersProps = {
  chipFilters: ChipFilter[];
  activeChip: number;
  setActiveChip: Dispatch<SetStateAction<number>>;
};

const typographyChipStyles = {
  lineHeight: 1,
  fontWeight: 600,
  fontFamily: 'Gellix-SemiBold',
  fontSize: '11px',
};

const TableWithChipFilters = ({
  chipFilters,
  ...props
}: TableProps & TableWithChipFiltersProps) => {
  const { rows, setActiveChip, activeChip } = props;

  const isChipActive = (index: number) => activeChip === index;

  return (
    <Stack>
      <BaseTable
        {...props}
        rows={rows}
        withElements={{
          ...props.withElements,
          withTabs: (
            <Stack spacing={1} direction="row" sx={{ my: 2 }}>
              {chipFilters.map((chip, index: number) => (
                <CustomChip
                  styleVariant="rounded"
                  onClick={() => setActiveChip(index)}
                  sx={{
                    position: 'relative',
                    width: 'fitContent',
                    bgcolor: isChipActive(index) ? 'primaryDark.600' : 'transparent',
                    color: isChipActive(index) ? 'common.white' : 'primaryDark.500',
                    border: isChipActive(index) ? 'none' : '1px solid',
                    '&:hover': {
                      bgcolor: isChipActive(index) ? 'primaryDark.600' : 'primaryDark.100',
                    },
                  }}
                  label={
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography sx={{ ...typographyChipStyles }}>{chip.label}</Typography>

                      {chip.isLoading ? (
                        <CircularProgress
                          size={12}
                          sx={{ color: isChipActive(index) ? 'common.white' : 'primaryDark.500' }}
                        />
                      ) : (
                        <Typography sx={{ ...typographyChipStyles }}>{chip.count}</Typography>
                      )}
                    </Stack>
                  }
                />
              ))}
            </Stack>
          ),
        }}
      />
    </Stack>
  );
};

export default TableWithChipFilters;
