import { Stack, Typography } from '@mui/material';
import { EventProps } from 'react-big-calendar';
import { EnumEventType } from 'tdc-web-backend/enums/enums';
import { CalendarEventDto } from 'tdc-web-backend/events/schemas';
import { convertDateToTime } from '../../utils/helpers';

const CalendarCustomDayEvent = (weekEvent: EventProps<CalendarEventDto>) => {
  const { event } = weekEvent;
  const isEvent = event.type === EnumEventType.Event;

  return (
    <Stack
      sx={{
        background: 'none',
        minWidth: '20%',
        p: 1,
      }}
      direction="column"
    >
      <Typography variant="heading5" color="primaryDark.600">
        {isEvent ? `${convertDateToTime(event.start)} - ${convertDateToTime(event.end)}` : convertDateToTime(event.end)}
      </Typography>
      <Typography variant="body4" color="primaryDark.600">
        {event.name}
      </Typography>
    </Stack>
  );
};

export default CalendarCustomDayEvent;
