import { Box, Stack } from '@mui/material';
import BlogArchiveHeader from './components/BlogArhiveHeader';
import BlogArchiveGrid from './components/BlogArchiveGrid/BlogArchiveGrid';
import SubscribeToNewsletter from '../../../../components/subscribe-to-newsletter/SubscribeToNewsletter';
import BlogArchiveFeatured from './components/BlogArchiveFeatured';

const BlogArchive = () => (
  <>
    <Box sx={{ backgroundColor: 'primaryDark.100' }}>
      <Stack
        direction="column"
        pt={20}
        pb={10}
        mx={{ xs: 2, gr: 10, xxl: 'auto' }}
        maxWidth={{ xs: '100%', xxl: '1280px' }}
        spacing={12.5}
      >
        <BlogArchiveHeader />
        <BlogArchiveFeatured />
        <BlogArchiveGrid />
      </Stack>
    </Box>
    <Box sx={{ backgroundColor: 'primaryLight.50' }}>
      <Stack
        spacing={10}
        pt={10}
        pb={12.5}
        mx={{ xs: 3, lg: 5, gr: 10, xxl: 'auto' }}
        maxWidth={{ xs: '100%', xxl: '1280px' }}
      >
        <SubscribeToNewsletter />
      </Stack>
    </Box>
  </>
);

export default BlogArchive;
