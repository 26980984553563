import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../custom-input/CustomInput';
import CustomButton from '../button/CustomButton';
import useCreate from '../../utils/hooks/crud-hooks/useCreate';
import BaseInputLabel from '../custom-inputs/BaseInputLabel';
import SubscribeModal from './components/SubscribeModal';

const SubscribeToNewsletter = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErroModalOpen, setIsErrorModalOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useCreate({ resource: 'newsletter/subscribe' });

  const onSubmit = () => {
    const eventHandlers = {
      onSuccess: (e: any) => {
        setIsSuccessModalOpen(true);
        form.reset();
      },
      onError: (err: any) => {
        setIsErrorModalOpen(true);
        form.reset();
      },
    };
    mutate({ email: form.getValues('email') }, eventHandlers);
  };

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Stack direction="column" spacing={3} alignItems="flex-start">
            <Typography variant="publicHeading3" color="secondaryGray.900">
              Subscribe to our newsletter
            </Typography>
            <Typography
              variant="publicBody3Regular"
              color="secondaryGray.900"
              sx={{ maxWidth: '718px' }}
            >
              Subscribe to our newsletter and receive the latest trends, updates and exclusive
              insights into the world of digital services straight to your inbox.
            </Typography>
            <Stack
              spacing={3}
              direction={{ xs: 'column', md: 'row' }}
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ width: '100%', maxWidth: '845px' }}
            >
              <Stack
                direction="column"
                spacing={0.5}
                alignItems="flex-start"
                sx={{ width: '100%' }}
              >
                <BaseInputLabel text="Email" sx={{ mb: 0 }} />
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    width: '100%',
                    maxWidth: { xs: '718px', md: 'none' },
                  }}
                >
                  <CustomInput
                    input={<CustomInput.Text />}
                    name="email"
                    type="email"
                    placeholder="Enter your e-mail"
                    // label="Email"
                    required
                    disabled={isLoading}
                    validationRegEx={{
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address',
                    }}
                    sx={{ width: '100%', backgroundColor: '#fff' }}
                  />
                </Box>
              </Stack>
              <CustomButton
                variant="primary"
                sx={{
                  height: '2.8125rem',
                  width: '100%',
                  maxWidth: '120px',
                  marginTop: '22px !important',
                }}
                onClick={form.handleSubmit(onSubmit)}
                loading={isLoading}
              >
                Subscribe
              </CustomButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      <SubscribeModal
        type="success"
        isOpen={isSuccessModalOpen}
        onConfirm={() => setIsSuccessModalOpen(false)}
        setIsOpen={setIsSuccessModalOpen}
      />
      <SubscribeModal
        type="error"
        isOpen={isErroModalOpen}
        onConfirm={() => setIsErrorModalOpen(false)}
        setIsOpen={setIsErrorModalOpen}
      />
    </>
  );
};

export default SubscribeToNewsletter;
