import { Select, MenuItem, Typography, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { useCustomInputContext } from '../CustomInputContext';
import { primaryDark } from '../../../color';
import CustomChip from '../../custom-chip/CustomChip';

// TODO: edge cases, e.g.: choices instanceof Array
// TODO: add transformLabelFn prop, etc.
const CustomInputSelect = () => {
  const { field, placeholder, useFormContext, choices, multiple, name, withChips, disabled } =
    useCustomInputContext();

  const { getValues, setValue, trigger } = useFormContext();

  const fieldValue = getValues(name);

  return (
    <Stack>
      <Select
        {...field}
        // TODO: add labelId & id
        displayEmpty
        disabled={disabled}
        multiple={multiple}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          //
          // styling the menu here is something that we didn't want to
          // have here explicitly, but rather in the CustomInput;
          // but if using this component inside a modal, with the disablePortal: true,
          // it breaks the position of the menu;
          // a fix for that is not to use disablePortal: true and along with that
          // override the styles here unfortunately
          sx: {
            maxHeight: 225,
            '.MuiMenuItem-root,.MuiAutocomplete-option,.MuiAutocomplete-noOptions': {
              height: 37,
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              lineHeight: 0,
              color: 'primaryDark.500',
              fontFamily: 'Gellix-Regular',
              padding: '8px 16px',
              '&:hover': {
                bgcolor: 'primaryLight.50',
              },

              '&:not(.Mui-selected)': {
                '& .MenuItemCheckedIcon': {
                  display: 'none',
                },
              },

              '&.Mui-selected': {
                backgroundColor: 'primaryLight.50',
                color: 'primaryDark.600',
                '&:hover': {
                  bgcolor: 'primaryLight.50',
                },
                '& .MenuItemCheckedIcon': {
                  // show check icon
                  display: 'block',
                },
                '& .MenuItemUncheckedIcon': {
                  display: 'none',
                },
              },
            },
          },
          disableScrollLock: true,
          keepMounted: true,
        }}
        renderValue={(selected) => {
          // placeholder
          if ((!selected && placeholder) || (!selected?.length && placeholder)) {
            return (
              <Typography
                className="placeholder-select"
                fontSize="14px"
                sx={{ color: primaryDark[400] }}
              >
                {placeholder}
              </Typography>
            );
          }

          if (selected && placeholder && withChips && multiple) {
            return (
              <Typography
                className="placeholder-select"
                fontSize="14px"
                sx={{ color: primaryDark[400] }}
              >
                {placeholder}
              </Typography>
            );
          }

          if (choices instanceof Map) {
            if (multiple) {
              return selected.map((item: any) => choices.get(item)).join(', ');
            }

            return choices.get(selected);
          }
        }}
      >
        {(() => {
          if (choices instanceof Map) {
            return Array.from(choices, ([key, value]: [string | number, string]) => (
              <MenuItem
                key={key}
                value={key}
                sx={{ justifyContent: 'flex-start !important', alignItems: 'center' }}
              >
                {multiple && (
                  <>
                    <CheckBoxOutlinedIcon
                      className="MenuItemCheckedIcon"
                      sx={{ color: 'primaryLight.500', width: 22, height: 22, mr: 0.85 }}
                    />

                    <CheckBoxOutlineBlankOutlinedIcon
                      className="MenuItemUncheckedIcon"
                      sx={{ color: 'primaryDark.300', width: 22, height: 22, mr: 0.85 }}
                    />
                  </>
                )}
                {value}
              </MenuItem>
            ));
          }

          return null;
        })()}
      </Select>

      {withChips && fieldValue?.length ? (
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
          {fieldValue.map((val: string) => {
            const chipLabel = choices instanceof Map ? choices.get(val) : '';
            return (
              <CustomChip
                key={val}
                label={chipLabel}
                onDelete={() => {
                  setValue(
                    name,
                    fieldValue.filter((item: string) => item !== val),
                  );
                  trigger(name);
                }}
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CustomInputSelect;
