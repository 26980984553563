import { Stack, Tabs } from '@mui/material';
import { useState } from 'react';
import { TabContext } from '@mui/lab';
import { Box } from '@mui/system';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { useNavigate } from 'react-router-dom';
import {
  EnumCaseStudyStatus,
  EnumNotificationSubcategory,
  EnumScreenList,
} from 'tdc-web-backend/enums/enums';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import CaseStudiesTabPanel, {
  CaseStudiesTab,
} from './CaseStudiesList/CaseStudiesTabularComponents';
import { ReactComponent as PlusIcon } from '../../../assets/icons/button-icons/PlusIcon.svg';
import CustomButton from '../../../components/button/CustomButton';
import CustomSortingMenu from '../../../components/custom-sorting-menu/CustomSortingMenu';
import BannerNotificationMapper from '../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import CaseStudiesDetailsDrawer from './CaseStudiesDetails/CaseStudiesDetailsDrawer';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';

const sortingMap = new Map([
  ['-created', 'Date created - Last'],
  ['created', 'Date created - First'],
  ['title', 'Alphabetical A-Z'],
  ['-title', 'Alphabetical Z-A'],
  ['published', 'Last published - First'],
  ['-published', 'Last published - Last'],
]);

const firstSortingOption = sortingMap.keys().next().value;

const CaseStudies = () => {
  const userData = useGetLocalStorageUserData();
  const [selectedTab, setSelectedTab] = useState('-1');
  const [sort, setSort] = useState<string>(firstSortingOption);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const navigate = useNavigate();
  const [caseStudyNotificationId, setCaseStudyNotificationId] = useState<string>('');

  const { data: caseStudiesAllResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
  });

  const { data: caseStudyItem } = useReferenceOne<BaseCaseStudy>({
    resource: 'case-studies',
    id: caseStudyNotificationId,
    enabled: !!caseStudyNotificationId.length,
    onSuccess: () => {
      setShowReviewForm(true);
      setIsDrawerOpen(true);
    },
  });

  // console.log(caseStudyNotificationId, isDrawerOpen, showReviewForm);

  const { data: caseStudiesPublishedResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?status=${EnumCaseStudyStatus.Published}&orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: caseStudiesPendingResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?status=${EnumCaseStudyStatus.Pending}&orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: caseStudiesDraftResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?status=${EnumCaseStudyStatus.Draft}&orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const { data: caseStudiesUnderReviewResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?status=${EnumCaseStudyStatus.UnderReview}&orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: caseStudiesVerifiedResponse } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?status=${EnumCaseStudyStatus.Verified}&orderBy=${sort}&company=${userData?.membership?.company}`,
    enabled: !!userData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  return (
    <>
      <Stack spacing={2}>
        <BannerNotificationMapper
          screen={EnumScreenList.CaseStudyScreen}
          customAction={{
            action: (notification) => {
              setCaseStudyNotificationId(notification?.data?.ids?.caseStudyId as string);
            },
            subcategory: EnumNotificationSubcategory.BannerCaseStudyApproved,
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <CustomButton
            onClick={() => navigate('./create')}
            variant="primaryLight"
            sx={{ height: '3rem' }}
            startIcon={<PlusIcon width="20px" height="20px" />}
          >
            new case study
          </CustomButton>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', height: 'fit-content', position: 'relative' }}>
              <TabContext value={selectedTab}>
                <Stack sx={{ width: '100%' }}>
                  <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="secondary tabs example"
                      sx={{
                        mt: 2,
                        p: 0,
                        zIndex: 1,
                      }}
                      TabIndicatorProps={{
                        style: { display: 'none' },
                      }}
                    >
                      <CaseStudiesTab
                        resultCount={caseStudiesAllResponse?.data.count as number}
                        value="-1"
                        selectedTab={selectedTab}
                        label="All"
                      />
                      <CaseStudiesTab
                        resultCount={caseStudiesVerifiedResponse?.data.count as number}
                        value="-5"
                        selectedTab={selectedTab}
                        label="Verified"
                      />
                      <CaseStudiesTab
                        resultCount={caseStudiesUnderReviewResponse?.data.count as number}
                        value="-6"
                        selectedTab={selectedTab}
                        label="Under review"
                      />
                      <CaseStudiesTab
                        resultCount={caseStudiesPublishedResponse?.data.count as number}
                        value="-2"
                        selectedTab={selectedTab}
                        label="Published"
                      />
                      <CaseStudiesTab
                        resultCount={caseStudiesPendingResponse?.data.count as number}
                        value="-3"
                        selectedTab={selectedTab}
                        label="Pending"
                      />
                      <CaseStudiesTab
                        resultCount={caseStudiesDraftResponse?.data.count as number}
                        value="-4"
                        selectedTab={selectedTab}
                        label="Draft"
                      />
                    </Tabs>
                    <CustomSortingMenu setSort={setSort} sort={sort} sortingMap={sortingMap} />
                  </Stack>
                  <CaseStudiesTabPanel value="-1" data={caseStudiesAllResponse?.data.results} />

                  <CaseStudiesTabPanel
                    data={caseStudiesPublishedResponse?.data.results}
                    value="-2"
                  />

                  <CaseStudiesTabPanel data={caseStudiesPendingResponse?.data.results} value="-3" />

                  <CaseStudiesTabPanel data={caseStudiesDraftResponse?.data.results} value="-4" />

                  <CaseStudiesTabPanel
                    data={caseStudiesUnderReviewResponse?.data.results}
                    value="-6"
                  />
                  <CaseStudiesTabPanel
                    data={caseStudiesVerifiedResponse?.data.results}
                    value="-5"
                  />
                </Stack>
              </TabContext>
            </Box>
          </Stack>
        </Stack>
        {caseStudyItem && (
          <CaseStudiesDetailsDrawer
            setShowReviewForm={setShowReviewForm}
            showReviewForm={showReviewForm}
            setIsOpen={setIsDrawerOpen}
            open={isDrawerOpen}
            item={caseStudyItem}
          />
        )}
      </Stack>
    </>
  );
};

export default CaseStudies;
